import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { KeyValue } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { AddUsersToOrganigrammaService } from 'src/app/services/operations/add-users-to-organigramma.service';
import { DeleteUsersService } from 'src/app/services/operations/delete-users.service';
import { MoveUsersService } from 'src/app/services/operations/move-users.service';
import { SuspendUsersService } from 'src/app/services/operations/suspend-users.service';
import { UpdateAccountDomainService } from 'src/app/services/operations/update-account-domain.service';
import { UpdatePasswordService } from 'src/app/services/operations/update-password.service';
import { ToastService } from 'src/app/services/toast.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { ReactivateSuspendedUsersService } from 'src/app/services/operations/reactivate-suspended-users.service';
import { DeleteGroupsService } from 'src/app/services/operations/groups/delete-groups.service';
import { RemoveAllMembersFromGroupsService } from 'src/app/services/operations/groups/remove-all-members-from-groups.service';
import { UpdateSettingGroupsService } from 'src/app/services/operations/groups/update-setting-groups.service';
import { RipristinoUsersService } from 'src/app/services/operations/ripristino-users.service';
import { SyncUsersFromGoogleService } from 'src/app/services/operations/sync-users-from-google.service';
import { UserService } from 'src/app/services/general/user.service';
import { RemoveUsersFromOrganigrammaService } from 'src/app/services/operations/remove-users-from-organigramma.service';
import { RemoveMembersFromGroupsService } from 'src/app/services/operations/groups/remove-members-from-groups.service';
import { UpdateMembersFromGroupsService } from 'src/app/services/operations/groups/update-members-from-groups.service';
import { CreateClassroomService } from 'src/app/services/operations/classroom/create-classroom.service';
import { CourseState } from 'src/app/services/google/google-classroom.service';
import { ActiveClassroomService } from 'src/app/services/operations/classroom/active-classroom.service';
import { SuspendClassroomService } from 'src/app/services/operations/classroom/suspend-classroom.service';
import { ArchiveClassroomService } from 'src/app/services/operations/classroom/archive-classroom.service';
import { DeleteClassroomService } from 'src/app/services/operations/classroom/delete-classroom.service';
import { CreateSingleClassroomService } from 'src/app/services/operations/classroom/create-single-classroom.service';
import { RemoveMembersFromClassroomsService } from 'src/app/services/operations/classroom/remove-members-from-classrooms.service';
import { AddMembersToClassroomService } from 'src/app/services/operations/classroom/add-members-to-classroom.service';
import { RemoveMembersFromClasseService } from 'src/app/services/operations/remove-members-from-classe.service';
import { saveAs } from 'file-saver';
import { ProvisionClassroomService } from 'src/app/services/operations/classroom/provision-classroom.service';
import { DeclineClassroomService } from 'src/app/services/operations/classroom/decline-classroom.service';
import { AccountService } from 'src/app/services/account.service';
import { ParametriService } from 'src/app/services/general/parametri.service';

export enum MenuItemsType {
  Users = "users",
  UsersSuspended = "users-suspended",
  UsersNotSync = "users-not-sync",
  UsersDeleted = "users-deleted",
  UsersOrganigramma = "users-organigramma",
  UsersClasse = "users-classe",
  Studenti = "studenti",
  Docenti = "docenti",
  DocentiClasse = "docenti-classe",
  DocentiPlesso = "docenti-plesso",
  UsersOrgUnit = "users-org-unit",
  Materie = "materie",
  Materia = "materia",
  GroupMembers = "group-members",
  GroupMembersUsers = "group-members-users",
  Groups = "groups",
  Organigrammi = "organigrammi",
  Domini = "domini",
  Classi = "classi",
  Classroom = "classroom",
  ClassroomCourseActive = "classroom-course-active",
  ClassroomCourseArchived = "classroom-course-archived",
  ClassroomCourseSuspended = "classroom-course-suspended",
  ClassroomCourseProvisioned = "classroom-course-provisioned",
  ClassroomCourseDeclined = "classroom-course-declined",
  ClassroomStudenti = "classroom-studenti",
  ClassroomDocenti = "classroom-docenti",
  ClassroomCourseWork = "classroom-course-work",
  ClassroomCourseWorkMaterials = "classroom-course-work-materials",
  ClassroomCourseAnnouncements = "classroom-course-announcements"
}
@Component({
  selector: 'app-card-datatable-ng',
  templateUrl: './card-datatable-ng.component.html',
  styleUrls: ['./card-datatable-ng.component.scss']
})
export class CardDatatableNgComponent implements OnInit {
  // Text Alert Message
  alertMessages = [];

  @Output() rowClickEvent = new EventEmitter<any>();
  @Output() addClickEvent = new EventEmitter<any>();
  @Output() deleteClickEvent = new EventEmitter<any>();
  @Output() primaryClickEvent = new EventEmitter<any>();

  @Input() title: string;
  @Input() subTitle: string;
  // Id principale per le operazioni, Id Utente, Id Organigramma, Id Classe da Gestire
  @Input() idRootData: any;
  // Dati Effettivi caricati
  @Input() rowData: any;
  // Dati Parserizzati
  @Input() data: any;
  _data: any;

  @Input() error: any;
  @Input() insideCard: boolean = true;
  @Input() isLoadingData: boolean;
  @Input() showAvatarOnFirstColumn: boolean = false;

  menuItems: MenuItem[];
  @Input() menuType: any;

  // Button
  @Input() labelDeleteButton: string = "Cancella";
  @Input() iconDeleteButton: string = "trash";
  @Input() showDeleteButton: boolean = false;

  @Input() labelPrimaryButton: string = "Aggiorna";
  @Input() iconPrimaryButton: string = "check-square";
  @Input() showPrimaryButton: boolean = false;
  @Input() showExportButton: boolean = false;

  @Input() showAddButton: boolean = false;
  @Input() showCheckBox: boolean = true;
  @Input() rowExpansion: boolean = false;

  // Paging
  @Input() itemsPerPage: any = 10;

  selectedItems: any = [];

  headerTableData: any;
  bodyTableData: any;
  footerTableData: any;
  dtPageInfo: any;

  // Ordinamento Oggetto keyvalue
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  countSelectedRow = 0;

  searchString: string;

  timeoutLoadingTableData: any;

  private _activeRouteSubscription: Subscription;

  constructor(
    private googleDirectoryService: GoogleDirectoryService,
    private activeRoute: ActivatedRoute,
    private translate: TranslateService,
    private moveUsersService: MoveUsersService,
    private updatePasswordService: UpdatePasswordService,
    private updateAccountDomainService: UpdateAccountDomainService,
    private createSingleClassroomService: CreateSingleClassroomService,
    private addUsersToOrganigrammaService: AddUsersToOrganigrammaService,
    private deleteUsersService: DeleteUsersService,
    private suspendUsersService: SuspendUsersService,
    private deleteGroupsService: DeleteGroupsService,
    private removeAllMembersFromGroupsService: RemoveAllMembersFromGroupsService,
    private ripristinoUsersService: RipristinoUsersService,
    private syncUsersFromGoogleService: SyncUsersFromGoogleService,
    private removeMembersFromGroupsService: RemoveMembersFromGroupsService,
    private removeMembersFromClassroomsService: RemoveMembersFromClassroomsService,
    private removeMembersFromClasseService: RemoveMembersFromClasseService,
    private addMembersToClassroomService: AddMembersToClassroomService,
    private reactivateSuspendedUsersService: ReactivateSuspendedUsersService,
    private removeUsersFromOrganigrammaService: RemoveUsersFromOrganigrammaService,
    private updateSettingGroupsService: UpdateSettingGroupsService,
    private updateMembersFromGroupsService: UpdateMembersFromGroupsService,
    private createClassroomService: CreateClassroomService,
    private activeClassroomService: ActiveClassroomService,
    private suspendClassroomService: SuspendClassroomService,
    private provisionClassroomService: ProvisionClassroomService,
    private declineClassroomService: DeclineClassroomService,
    private archiveClassroomService: ArchiveClassroomService,
    private deleteClassroomService: DeleteClassroomService,
    private accountService: AccountService,
    private parametriService: ParametriService,
    private userService: UserService,
    private router: Router,
    private toast: ToastService) { }

  ngOnInit() {

    this.initTable();
    this.initMenuItems();

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.rowChecked([]);
    });
  }

  initMenuItems() {

    if (this.menuType) {
      this.menuItems = [
        {
          label: 'Operazioni massive',
          icon: 'fas fa-edit',
          items: []
        }
      ];

      let menuTypesList = this.menuType.split("|");

      menuTypesList.forEach(element => {
        switch (element) {
          case MenuItemsType.Users:
            this.menuItems[0].items = this.menuItems[0].items.concat(
              [
                {
                  label: 'Aggiorna password', command: (event) => {
                    this.updatePasswordCommand();
                  }
                },
                {
                  label: 'Aggiorna dominio', command: (event) => {
                    this.updateAccountDomainCommand("USER");
                  }
                },
                {
                  label: 'Aggiungi in organigramma', command: (event) => {
                    this.addUsersToOrganigrammaCommand();
                  }
                },
                {
                  label: 'Aggiungi o sposta in una classe', command: (event) => {
                    this.moveUserCommand();
                  }
                },
                {
                  label: 'Sospendi utenti', command: (event) => {
                    this.suspendUsersCommand();
                  }
                },
                {
                  label: 'Elimina utenti', command: (event) => {
                    this.deleteUsersCommand();
                  }
                },
                {
                  label: 'Aggiungi docente in nuova classroom', command: (event) => {
                    this.addUserToCreateSingleClassroomCommand("google-teacher");
                  }
                },
                {
                  label: 'Aggiungi studente in nuova classroom', command: (event) => {
                    this.addUserToCreateSingleClassroomCommand("google-student");
                  }
                },
                {
                  label: 'Aggiungi in classroom esistente', command: (event) => {
                    this.addMembersToClassroomCommand();
                  }
                }
              ]);
            break;
          case MenuItemsType.Studenti:
            this.menuItems[0].items = this.menuItems[0].items.concat(
              [
                {
                  label: 'Rimuovi membri selezionati', command: (event) => {
                    this.removeMembersFromClasseCommand("google-student");
                  }
                }
              ]);
            break;
          case MenuItemsType.DocentiClasse:
            this.menuItems[0].items = this.menuItems[0].items.concat(
              [
                {
                  label: 'Rimuovi membri selezionati', command: (event) => {
                    this.removeMembersFromClasseCommand("google-teacher");
                  }
                }
              ]);
            break;
          case MenuItemsType.DocentiPlesso:
            this.menuItems[0].items = this.menuItems[0].items.concat(
              [
                {
                  label: 'Rimuovi membri selezionati dalle classi', command: (event) => {
                    this.removeMembersFromPlessoCommand("google-teacher");
                  }
                }
              ]);
            break;
          case MenuItemsType.UsersSuspended:
            this.menuItems[0].items = this.menuItems[0].items.concat(
              [
                {
                  label: 'Riattiva utenti', command: (event) => {
                    this.reactivateSuspendedUsersCommand();
                  }
                },
                {
                  label: 'Elimina utenti', command: (event) => {
                    this.deleteUsersCommand();
                  }
                }
              ]
            );
            break;
          case MenuItemsType.UsersNotSync:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Importa in Geniusuite', command: (event) => {
                  this.syncUsersCommand();
                }
              },
              {
                label: 'Elimina utenti', command: (event) => {
                  this.deleteUsersCommand();
                }
              },
              {
                label: 'Aggiorna dominio', command: (event) => {
                  this.updateAccountDomainCommand("USER_NOT_SYNC");
                }
              },
            ]);
            break;
          case MenuItemsType.UsersDeleted:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Ripristina come docenti', command: (event) => {
                  this.riprisitnoUsersCommand("google-teacher");
                }
              },
              {
                label: 'Ripristina come studenti', command: (event) => {
                  this.riprisitnoUsersCommand("google-student");
                }
              },
              {
                label: 'Ripristina come altro', command: (event) => {
                  this.riprisitnoUsersCommand("google-worker");
                }
              }
            ]);
            break;
          case MenuItemsType.GroupMembersUsers:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Importa in Geniusuite', command: (event) => {
                  this.syncUsersCommand();
                }
              }
            ]);
            break;
          case MenuItemsType.GroupMembers:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Rimuovi membri selezionati', command: (event) => {
                  this.removeMembersFromGroupsCommand()
                }
              },
              {
                label: 'Modifica ruolo membri selezionati', command: (event) => {
                  this.updateMembersFromGroupsCommand()
                }
              }
            ]);
            break;
          case MenuItemsType.Groups:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Aggiorna impostazioni gruppo', command: (event) => {
                  this.updateSettingGroupsCommand();
                }
              },
              {
                label: 'Aggiorna dominio', command: (event) => {
                  this.updateAccountDomainCommand("GROUP");
                }
              },
              {
                label: 'Elimina gruppi', command: (event) => {
                  this.deleteGroupsCommand();
                }
              },
              {
                label: 'Rimuovi tutti i membri dai gruppi', command: (event) => {
                  this.removeAllMembersFromGroupsCommand();
                }
              }
            ]);
            break;
          case MenuItemsType.Organigrammi:
            this.menuItems[0].items = this.menuItems[0].items.concat([]);
            break;
          case MenuItemsType.UsersOrganigramma:
            this.menuItems[0].items = this.menuItems[0].items.concat([{
              label: "Rimuovi dall'Organigramma", command: (event) => {
                this.removeUsersFromOrganigrammaCommand();
              }
            }]);
            break;
          case MenuItemsType.UsersOrgUnit:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Importa in Geniusuite', command: (event) => {
                  this.syncUsersCommand();
                }
              }
            ]);
            break;
          case MenuItemsType.Classi:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Crea classroom', command: (event) => {
                  this.createClassroomSmartCommand();
                }
              },
            ]);
            break;
          case MenuItemsType.ClassroomStudenti:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Rimuovi membri selezionati', command: (event) => {
                  this.removeMembersFromClassroomsCommand("google-student")
                }
              }
            ]);
            break;
          case MenuItemsType.ClassroomDocenti:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Rimuovi membri selezionati', command: (event) => {
                  this.removeMembersFromClassroomsCommand("google-teacher")
                }
              }
            ]);
            break;
          case MenuItemsType.ClassroomCourseActive:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Sospendi classroom', command: (event) => {
                  this.changeStateClassroomCommand(CourseState.SUSPENDED);
                }
              },
              {
                label: 'Archivia classroom', command: (event) => {
                  this.changeStateClassroomCommand(CourseState.ARCHIVED);
                }
              },
              {
                label: 'Elimina classroom', command: (event) => {
                  this.deleteClassroomCommand();
                }
              }
            ]);
            break;
          case MenuItemsType.ClassroomCourseSuspended:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Attiva classroom', command: (event) => {
                  this.changeStateClassroomCommand(CourseState.ACTIVE);
                }
              },
              {
                label: 'Archivia classroom', command: (event) => {
                  this.changeStateClassroomCommand(CourseState.ARCHIVED);
                }
              },
              {
                label: 'Elimina classroom', command: (event) => {
                  this.deleteClassroomCommand();
                }
              }
            ]);
            break;
          case MenuItemsType.ClassroomCourseProvisioned:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Attiva classroom', command: (event) => {
                  this.changeStateClassroomCommand(CourseState.ACTIVE);
                }
              },
              {
                label: 'Rifiuta classroom', command: (event) => {
                  this.changeStateClassroomCommand(CourseState.DECLINED);
                }
              },
              {
                label: 'Elimina classroom', command: (event) => {
                  this.deleteClassroomCommand();
                }
              }
            ]);
            break;
          case MenuItemsType.ClassroomCourseDeclined:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Classroom da confermare', command: (event) => {
                  this.changeStateClassroomCommand(CourseState.PROVISIONED);
                }
              },
              {
                label: 'Elimina classroom', command: (event) => {
                  this.deleteClassroomCommand();
                }
              }
            ]);
            break;
          case MenuItemsType.ClassroomCourseArchived:
            this.menuItems[0].items = this.menuItems[0].items.concat([
              {
                label: 'Attiva classroom', command: (event) => {
                  this.changeStateClassroomCommand(CourseState.ACTIVE);
                }
              },
              {
                label: 'Sospendi classroom', command: (event) => {
                  this.changeStateClassroomCommand(CourseState.ACTIVE);
                }
              },
              {
                label: 'Elimina classroom', command: (event) => {
                  this.deleteClassroomCommand();
                }
              }
            ]);
            break;
        }
      });
    }

    if (!this.accountService.isGoogleWorkspaceAccount() || !this.parametriService.checkParametriConfigurazione) {
      if (this.menuItems != null && this.menuItems.length == 1 && this.menuItems[0].items != null && this.menuItems[0].items.length >= 0) {
        this.menuItems[0].items = [];
      }
    }

    if (this.showExportButton && (this.menuItems == null || this.menuItems.length == 0)) {
      this.menuItems = [
        {
          label: 'Operazioni massive',
          icon: 'fas fa-edit',
          items: [{
            id: "export",
            label: 'Esporta dati selezionati', command: (event) => {
              this.exportDataSelectedCommand();
            }
          }]
        }
      ];
    }

    if (this.showExportButton && this.menuItems != null && this.menuItems.length == 1 && this.menuItems[0].items != null && this.menuItems[0].items.length >= 0) {
      if (this.menuItems[0].items.findIndex(x => x.id == "export") == -1)
        this.menuItems[0].items.push({
          id: "export",
          label: 'Esporta dati selezionati', command: (event) => {
            this.exportDataSelectedCommand();
          }
        });
    }

    // abilito o disabilito le voci del menu
    if (this.menuItems && this.menuItems[0] && this.menuItems[0].items) {
      this.menuItems[0].items.forEach(item => {
        item.disabled = (this.countSelectedRow <= 0);
      });
    }
  }

  ngOnDestroy() {

    if (this._activeRouteSubscription) {
      this._activeRouteSubscription.unsubscribe();
    }
  }

  addAlertClearFilterUpdateTable() {
    let alert = { severity: 'warn', type: 'filter', reload_datatable: true, summary: this.translate.instant('TOAST.warn.datatable_selected_filter_items.title'), detail: this.translate.instant('TOAST.warn.datatable_selected_filter_items.msg') };
    let element = this.alertMessages.find((e) => e.type === alert.type);

    if (!element) {
      this.alertMessages.push(alert);
    }
  }

  addAlertUpdateTableSelection() {
    let alert = { severity: 'warn', type: 'update', reload_datatable: false, summary: this.translate.instant('TOAST.warn.datatable_selected_items.title'), detail: this.translate.instant('TOAST.warn.datatable_selected_items.msg') };
    let element = this.alertMessages.find((e) => e.type === alert.type);

    if (!element) {
      this.alertMessages.push(alert);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && changes.data.currentValue && changes.data.previousValue === undefined) {
      this.initTable();
    }
    else if (changes && changes.data && changes.data.currentValue && changes.data.previousValue) {
      this.updateTable();
    }
  }

  private initTable() {
    this.parseHeaderTable();
    this.parseBodyTable();
    this.parseDataTable();
  }

  private updateTable() {
    this.initTable();

    if (this.searchString && this.searchString.length > 0) {
      this.addAlertClearFilterUpdateTable();
    }

    if (this.countSelectedRow > 0) {
      this.addAlertUpdateTableSelection();
    }
  }

  clearSearchFilterRealoadTable(type) {
    this.searchString = "";
    this.updateTable();
    this.removeAlertMessages(type);
  }

  private parseHeaderTable() {

    this.headerTableData = [];

    if (this.data) {
      let first = this.data[0];

      for (let columnName in first) {
        // Le colonne che iniziano con "_" sono private e non devono essere visualizzati
        if (columnName[0] != "_")
          this.headerTableData.push(columnName);
      }
    }
  }

  private parseDataTable() {

    this._data = [];

    if (this.data) {
      for (let index = 0; index < this.data.length; index++) {
        this._data.push({
          ...this.data[index],
          index_col: index
        });
      }
    }
  }

  private parseBodyTable() {

    this.bodyTableData = [];
    if (this.data) {
      for (let row of this.data) {
        this.bodyTableData.push(Object.keys(row).map(index => row[index]));
      }
    }
  }

  updatePageInfo(info) {
    this.dtPageInfo = info;
  }

  rowChecked(info) {
    this.selectedItems = info;
    this.countSelectedRow = this.selectedItems.length || 0;

    // abilito o disabilito le voci del menu
    if (this.menuItems && this.menuItems[0] && this.menuItems[0].items) {
      this.menuItems[0].items.forEach(item => {
        item.disabled = (this.countSelectedRow <= 0);
      });
    }
  }

  getRowIndexChecked() {
    return this.selectedItems.map(x => x.index_col);
  }

  getRowChecked() {
    return this.selectedItems;
  }

  rowClicked(info) {
    this.rowClickEvent.emit(info);
  }

  addClicked(info) {
    this.addClickEvent.emit(info);
  }

  deleteSelected() {
    let selected = this.getRowIndexChecked();
    this.rowChecked([]);
    this.deleteClickEvent.emit(selected);
  }

  primarySelected() {
    let selected = this.getRowIndexChecked();
    this.primaryClickEvent.emit(selected);
  }

  removeAlertMessages(type) {
    this.alertMessages = this.alertMessages.filter((msg) => msg.type !== type);
  }

  clear(table: Table) {
    table.clear();
  }

  // #region Metodi per le operazioni Massive
  updatePasswordCommand() {
    let indexSelected = this.getRowIndexChecked();
    let dataSelected = [];

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.updatePasswordService.addUsers(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/update-password']);
  }

  updateAccountDomainCommand(type) {
    let indexSelected = null;
    let dataSelected = [];

    switch (type) {
      case "USER":
        indexSelected = this.getRowIndexChecked();
        indexSelected.forEach(idx => {
          let userGet = this.rowData[idx];
          if (userGet.google_user)
            dataSelected.push(userGet.google_user);
        });
        break;
      case "USER_NOT_SYNC":
        indexSelected = this.getRowIndexChecked();
        indexSelected.forEach(idx => {
          let userGet = this.rowData[idx];
          dataSelected.push(userGet);
        });
        break;
      case "GROUP":
        indexSelected = this.getRowIndexChecked();
        indexSelected.forEach(idx => {
          dataSelected.push(this.rowData[idx]);
        });
        break;
    }

    this.updateAccountDomainService.addAccount(dataSelected, type);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/update-account-domain']);
  }

  addUserToCreateSingleClassroomCommand(type) {

    let indexSelected = null;
    let dataSelected = [];

    switch (type) {
      case "google-teacher":
        indexSelected = this.getRowIndexChecked();
        indexSelected.forEach(idx => {

          let userGet = this.rowData[idx];

          if (!userGet.google_user) {
            userGet.google_user = this.googleDirectoryService.getUser(userGet.google_id);
          }

          if (userGet.google_user)
            dataSelected.push(userGet.google_user);
        });
        break;
      case "google-student":
        indexSelected = this.getRowIndexChecked();
        indexSelected.forEach(idx => {

          let userGet = this.rowData[idx];

          if (!userGet.google_user) {
            userGet.google_user = this.googleDirectoryService.getUser(userGet.google_id);
          }

          if (userGet.google_user)
            dataSelected.push(userGet.google_user);
        });
        break;
    }

    this.createSingleClassroomService.addItems(dataSelected, type);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/create-classroom/single']);
  }

  addUsersToOrganigrammaCommand() {

    let indexSelected = this.getRowIndexChecked();
    let dataSelected = [];

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.addUsersToOrganigrammaService.addUsers(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/add-users-to-organigramma']);
  }

  moveUserCommand() {
    let indexSelected = this.getRowIndexChecked();
    let dataSelected = [];

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.moveUsersService.addUsers(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/move-users']);
  }


  suspendUsersCommand() {

    let indexSelected = this.getRowIndexChecked();
    let dataSelected = [];

    indexSelected.forEach(idx => {
      let userSelected = this.rowData[idx];
      // se è già disponibile il google_user sull'utente lo uso direttamente
      if (userSelected.google_user && userSelected.google_user.suspended == false) {
        dataSelected.push(userSelected.google_user);
      }
      // provo a recuperare il google_user tramite google_id perché evidentemente capita che non sono ancora disponibili i dati
      else if (userSelected.google_id) {
        let google_user = this.googleDirectoryService.getUser(userSelected.google_id);
        if (google_user && google_user.suspended == false) {
          dataSelected.push(google_user);
        }
      }
    });

    this.suspendUsersService.addUsers(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/suspend-users']);
  }


  deleteUsersCommand() {

    let indexSelected = this.getRowIndexChecked();
    let dataSelected = [];

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.deleteUsersService.addUsers(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/delete-users']);
  }

  exportDataSelectedCommand() {
    if (this.selectedItems && this.selectedItems.length > 0) {
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.selectedItems.map(x => x._export_data));
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "geniusuite");
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {

    var isFileSaverSupported = false;

    try {
      var isFileSaverSupported = !!new Blob;
    } catch (e) { }

    if (isFileSaverSupported) {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
  }

  reactivateSuspendedUsersCommand() {
    let indexSelected = this.getRowIndexChecked();
    let dataSelected = [];

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.reactivateSuspendedUsersService.addUsers(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/reactivate-suspended-users']);
  }

  syncUsersCommand() {
    let indexSelected = this.getRowIndexChecked();
    let dataSelected = [];

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.syncUsersFromGoogleService.addUsers(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/sync-users-from-google']);
  }

  riprisitnoUsersCommand(typeUser: string) {

    let indexSelected = this.getRowIndexChecked();
    let dataSelected = [];

    indexSelected.forEach(idx => {
      dataSelected.push({ ...this.rowData[idx], typeUser: typeUser });
    });

    this.ripristinoUsersService.addUsers(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/ripristino-users']);
  }


  updateSettingGroupsCommand() {

    let indexSelected = this.getRowIndexChecked();
    let dataSelected = [];

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.updateSettingGroupsService.addGroups(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/update-setting-groups']);
  }


  deleteGroupsCommand() {

    let indexSelected = this.getRowIndexChecked();
    let dataSelected = [];

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.deleteGroupsService.addGroups(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/delete-groups']);
  }

  removeAllMembersFromGroupsCommand() {

    let indexSelected = this.getRowIndexChecked();
    let dataSelected = [];

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.removeAllMembersFromGroupsService.addGroups(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/remove-all-members-from-groups']);
  }

  removeUsersFromOrganigrammaCommand() {
    let indexSelected = this.getRowIndexChecked();
    let dataSelected = [];

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.removeUsersFromOrganigrammaService.addUsers(dataSelected, this.idRootData);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/remove-users-from-organigramma']);
  }

  removeMembersFromGroupsCommand() {
    let indexSelected = null;
    let dataSelected = [];

    indexSelected = this.getRowIndexChecked();

    indexSelected.forEach(idx => {
      dataSelected.push({ ...this.rowData[idx], groupKeyRemove: this.idRootData });
    });

    this.removeMembersFromGroupsService.addMembers(dataSelected, this.idRootData);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/remove-members-from-groups']);
  }

  removeMembersFromClassroomsCommand(ruolo) {
    let indexSelected = null;
    let dataSelected = [];

    indexSelected = this.getRowIndexChecked();

    indexSelected.forEach(idx => {
      dataSelected.push({ ...this.rowData[idx], courseId: this.idRootData });
    });

    this.removeMembersFromClassroomsService.addMembers(dataSelected, this.idRootData, ruolo);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/remove-members-from-classrooms']);
  }

  removeMembersFromClasseCommand(ruolo) {
    let indexSelected = null;
    let dataSelected = [];

    indexSelected = this.getRowIndexChecked();

    indexSelected.forEach(idx => {
      dataSelected.push({ ...this.rowData[idx], classeId: this.idRootData });
    });

    this.removeMembersFromClasseService.addMembers(dataSelected, this.idRootData, ruolo);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/remove-members-from-classe']);
  }

  removeMembersFromPlessoCommand(ruolo) {
    let indexSelected = null;
    let dataSelected = [];

    indexSelected = this.getRowIndexChecked();

    indexSelected.forEach(idx => {
      dataSelected.push({ ...this.rowData[idx], plessoId: this.idRootData });
    });

    this.removeMembersFromClasseService.addMembersFromPlesso(dataSelected, this.idRootData, ruolo);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/remove-members-from-classe']);
  }

  addMembersToClassroomCommand() {
    let indexSelected = null;
    let dataSelected = [];

    indexSelected = this.getRowIndexChecked();

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.addMembersToClassroomService.addMembers(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/add-members-to-classroom']);
  }

  updateMembersFromGroupsCommand() {
    let indexSelected = null;
    let dataSelected = [];

    indexSelected = this.getRowIndexChecked();

    indexSelected.forEach(idx => {
      dataSelected.push({ ...this.rowData[idx], groupKeyUpdate: this.idRootData });
    });

    this.updateMembersFromGroupsService.addMembers(dataSelected, this.idRootData);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/update-members-from-groups']);
  }

  createClassroomSmartCommand() {

    let indexSelected = null;
    let dataSelected = [];

    indexSelected = this.getRowIndexChecked();

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.createClassroomService.addItems(dataSelected);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/create-classroom']);
  }

  changeStateClassroomCommand(typeDestination) {
    let indexSelected = null;
    let dataSelected = [];

    indexSelected = this.getRowIndexChecked();

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    switch (typeDestination) {
      case CourseState.ACTIVE:
        this.activeClassroomService.addItems(dataSelected);
        this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
        this.router.navigate(['operation/active-classroom']);
        break;
      case CourseState.SUSPENDED:
        this.suspendClassroomService.addItems(dataSelected);
        this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
        this.router.navigate(['operation/suspend-classroom']);
        break;
      case CourseState.ARCHIVED:
        this.archiveClassroomService.addItems(dataSelected);
        this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
        this.router.navigate(['operation/archive-classroom']);
        break;
      case CourseState.PROVISIONED:
        this.provisionClassroomService.addItems(dataSelected);
        this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
        this.router.navigate(['operation/provision-classroom']);
        break;
      case CourseState.DECLINED:
        this.declineClassroomService.addItems(dataSelected);
        this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
        this.router.navigate(['operation/decline-classroom']);
        break;
    }
  }

  deleteClassroomCommand() {
    let indexSelected = null;
    let dataSelected = [];

    indexSelected = this.getRowIndexChecked();

    indexSelected.forEach(idx => {
      dataSelected.push(this.rowData[idx]);
    });

    this.deleteClassroomService.addItems(dataSelected);
    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/delete-classroom']);
  }

  // #endregion Metodi per le operazioni Massive

}
