<!-- Step -->
<ul class="step step-md mb-0 step-centered">
  <li class="step-item" *ngFor="let item of steps" (click)="goToStep(item)">
    <div class="step-content-wrapper" [ngClass]="{'pointer': currentStep != null && item < currentStep}">
      <span class="step-icon"
        [ngClass]="{'step-icon-soft-primary': currentStep == null || item > currentStep, 'step-icon-success': currentStep != null && item < currentStep, 'step-icon-primary': currentStep != null && currentStep == item}">
        {{item}}
      </span>
      <div class="step-content">
        <h4 class="step-title" [ngClass]="{'text-muted': currentStep != null && item != currentStep}">{{'SETUP.steps.titolo_'+item | translate}}</h4>
        <p class="text-body">{{'SETUP.steps.text_'+item | translate}}</p>
      </div>
    </div>
  </li>
</ul>
<!-- End Step -->
