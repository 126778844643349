import { Component, Input, NgZone, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import {
  ParametriService,
  ParamType,
} from 'src/app/services/general/parametri.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { ToastService } from 'src/app/services/toast.service';
import { UtilityService } from 'src/app/services/utility.service';
import { PageService } from 'src/app/services/page.service';
import { RelationInfo } from 'src/app/services/general/relation.service';
import { SyncdataService } from 'src/app/services/syncdata.service';
import { MateriaService } from 'src/app/services/general/materia.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-user-form-delete',
  templateUrl: './user-form-delete.component.html',
  styleUrls: ['./user-form-delete.component.scss']
})
export class UserFormDeleteComponent implements OnInit {
  regEx = ConfigService.regExDelete;
  private _loadingBackendUsers: boolean = false;

  get loadingBackend(): boolean {
    return (
      this._loadingBackendUsers
    );
  }

  _loadingGoogleUsersData: boolean = false;
  get loadingGoogle(): boolean {
    return (
      this._loadingGoogleUsersData
    );
  }

  _loadingUser: boolean = false;
  get loadingUser(): boolean {
    return (
      this._loadingUser
    );
  }
  @Input() id: string;
  data: any;
  user: any;
  error: any;

  // Dati Google
  orgUnits: any;
  groups: any;

  // Organigramma
  organigrammi: any;

  // Loading Pagina
  loadingPlesso: boolean;
  loadingOrganigrammi: boolean;
  loadingGoogleOrgUnits: boolean;
  loadingGoogleGroups: boolean;
  loadingParameterService: boolean;
  formLoading: boolean;
  parameterLoaded: boolean;

  private _backendUserServiceSubscription: Subscription;

  constructor(
    private userService: UserService,
    private router: Router,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    //  rimuovo tutti gli eventi
    this.unsubscibeAll();

    this._backendUserServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError();
      else
        this.usersUpdated(result);
    });

    this.getBackendUsersData();


    if (this.id && this.id.length > 0) {
      //  recupero le informazioni dell'utente google
      this.getSingleUserData();
    }
  }

  /**
     * Recupera i dati degli utenti dal Backend
     */
  getBackendUsersData() {
    this._loadingBackendUsers = true;
    this.userService.all();
  }
  usersUpdated(result) {

    this._loadingBackendUsers = false;

    if (this.id && this.id.length > 0)
      this.getSingleUserData();
  }


  getSingleUserData() {
    this._loadingUser = true;

    this.userService.get(
      this.id,
      (result) => {
        this._loadingUser = false;
        this.user = result;
      },
      () => {
        this._loadingUser = false;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  unsubscibeAll() {
    if (this._backendUserServiceSubscription)
      this._backendUserServiceSubscription.unsubscribe();
  }

  backendError() {
    this.error = "backend";
    this.formLoading = false;
    this.loadingPlesso = false;
  }

  googleError() {
    this.error = "google";
    this.formLoading = false;
    this.loadingGoogleOrgUnits = false;
    this.loadingGoogleGroups = false;
  }

  retryAfterLoadingError() {
    this.ngOnInit();
  }

  onSubmit(myForm: NgForm) {
    this.formLoading = true;

    // Delete
    this.userService.delete(this.id, true, (result) => {
      // TODO_ZORAN Definire una volta cancellato un plesso dove portare l'utente
      this.ngZone.run(() => {
        this.router.navigate(["/dashboard"]);
      });
      this.formLoading = false;
    }, (error) => this.backendError())
  }

  filterGroupsByType(google_group, relation_info) {
    return google_group.filter(x => x.relation_info == relation_info);
  }
}
