
<header id="header" class="navbar navbar-expand-lg navbar-fixed-lg navbar-container navbar-light" *ngIf="getAccountService().isBootstrapCompleted()"></header>

<!-- ========== MAIN CONTENT ========== -->
<main id="content" role="main" class="main">
  <!-- Page Header -->
  <app-page-header></app-page-header>
  <!-- End Page Header -->

  <!-- Content -->
  <div class="content container-fluid" style="margin-top: -17rem;">

    <app-card-loading-error *ngIf="showPageError" (retryClicked)="retryClicked()"></app-card-loading-error>

    <!-- Navbar Vertical -->
    <div class="navbar-expand-lg" *ngIf="getAccountService().isBootstrapCompleted()">

      <button type="button" class="navbar-toggler btn btn-block btn-white mb-3" id="button-menu-toggler" (click)="showSidebar=!showSidebar" [attr.aria-expanded]="showSidebar">
        <span class="d-flex justify-content-between align-items-center">
          <span class="h5 mb-0">Menu</span>

          <span class="navbar-toggle-default">
            <i class="tio-menu-hamburger"></i>
          </span>

          <span class="navbar-toggle-toggled">
            <i class="tio-clear"></i>
          </span>
        </span>
      </button>

      <aside id="navbarVerticalNavMenu" [ngClass]="{'show': showSidebar === true, 'aside-mini-mode': menuMini === true }"
        class="navbar navbar-vertical navbar-vertical-absolute navbar-vertical-detached navbar-shadow navbar-collapse collapse rounded-lg bg-light">
      </aside>

    </div>
    <!-- End Navbar Vertical -->

    <!-- Sidebar Detached Content -->

    <div class="mt-3 mt-lg-0" [ngClass]="{'sidebar-detached-content': getAccountService().isBootstrapCompleted(), 'aside-mini-mode': menuMini === true}">
      <app-card-important-activity></app-card-important-activity>
    </div>

    <div class="mt-3 mt-lg-0" [ngClass]="{'sidebar-detached-content': getAccountService().isBootstrapCompleted(), 'aside-mini-mode': menuMini === true}">
      <router-outlet></router-outlet>
    </div>
    <!-- End Sidebar Detached Content -->
  </div>
  <!-- End Content -->
</main>
<!-- ========== END MAIN CONTENT ========== -->

<app-activity-sidebar *ngIf="getAccountService().isBootstrapCompleted()"></app-activity-sidebar>
<app-help-sidebar *ngIf="getAccountService().isBootstrapCompleted()"></app-help-sidebar>

<!-- Custom Notification Controller -->
<toast-notification></toast-notification>
