import { Injectable, NgZone } from '@angular/core';
import { GoogleApiService } from 'ng-gapi';
import { AccountService } from '../account.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleDriveService {

  picker: any;
  image: any;

  constructor(
    private accountService: AccountService,
    private gapiService: GoogleApiService,
    private ngZone: NgZone
  ) { }

  init() {

    gapi.client.load('drive', 'v3', () => {
      // console.log("Google drive client load drive");
      gapi.load('picker', () => {
        // console.log("Google drive picker caricato");
      });
    });


  }

  showPicker(callback) {

    this.picker = new google.picker.PickerBuilder().
      addView(new google.picker.DocsView(google.picker.ViewId.DOCS_IMAGES).setIncludeFolders(true)).
      enableFeature(google.picker.Feature.MULTISELECT_ENABLED).
      setAppId(this.gapiService.getConfig().getClientConfig().client_id).
      setOAuthToken(this.accountService.data.google_access_token.token).
      setCallback(callback).
      setTitle('Google Drive - Geniusuite').
      build().
      setVisible(true);
  }

  /**
   * APPUNTI:
   *
   * per far funzionare il picker bisogna aggiungere questi SCOPE
   * https://www.googleapis.com/auth/drive.file,https://www.googleapis.com/auth/drive.metadata.readonly
   * e forse altri
   *
   * Per visualizzarlo in HTML serve questo link
   * "https://drive.google.com/uc?export=view&id=" + id
   * con l'id dell'immagine
   *
   * serve anche che l'immagine sia condivisa pubblicamente, quindi è da decidere se automatizzare questo processo e volendo si può fare
   * ma va avvisato l'utente che sta facendo questa cosa e da capire anche come poter tornare indietro se dovesse sbagliare a selezionare cartella o immagini
   *
   * si possono controllare ed eventualmente abilitare solo le immagini in drive e non tutti i documenti
   *
   * @param data
   * @returns
   */

  parsePickedData(data) {

    if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {

      let documents = data[google.picker.Response.DOCUMENTS];
      return documents;
    }

    return [];
  }

  getFileInfo(fileId, callback, error) {

    gapi.client.request({
      path: "https://www.googleapis.com/drive/v3/files/" + fileId,
      method: "GET",
      params: {
        'fileId': fileId,
        'fields': '*',
      },
    }).then((response) => {
      this.ngZone.run(() => {
        if (response && response.status == 200 && response.result) {
          callback(response.result);
        }
        else {
          error(response);
        }
      });
    }, (response) => {
      this.ngZone.run(() => {
        error(response);
      });
    });
  }


}
