import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { UserService } from 'src/app/services/general/user.service';
import { SuspendUsersService } from 'src/app/services/operations/suspend-users.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-suspend-users',
  templateUrl: './suspend-users.component.html',
  styleUrls: ['./suspend-users.component.scss']
})
export class SuspendUsersComponent implements OnInit {
  regEx = ConfigService.regExSuspend;
  error: any;
  _loadingData = {
    plessi: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  data: any = [];
  users: any;
  model: any;

  operationInProgress: boolean = false;
  errorAdminSelected: boolean = false;

  private _suspendUsersServiceSubscription: Subscription;
  private _userServiceSubscription: Subscription;


  constructor(
    private suspendUsersService: SuspendUsersService,
    private userService: UserService,
    private syncdataService: SyncdataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initForm();
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.users = this.suspendUsersService.items;
    this.data = this.userService.getTable(this.users);

    this.users.forEach(element => {
      if(element.isAdmin){
        this.errorAdminSelected = true;
      }
    });
  }

  deleteEvent(rows) {
    this.suspendUsersService.deleteItemsAtIndex(rows);
  }

  onSubmit(myForm: NgForm) {

    if (myForm.valid) {

      this.operationInProgress = true;

      this.suspendUsersService.execOperation((error) => {
        if (!error) {
          this.initForm();
        }
      });
    }
  }

  unsubscibeAll() {


    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._suspendUsersServiceSubscription)
      this._suspendUsersServiceSubscription.unsubscribe();



    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
  }

  initForm() {
    this.model = {
      plesso: null,
      classe: null
    }
  }

  init() {

    this._suspendUsersServiceSubscription = this.suspendUsersService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.dataUpdated();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  resetOperation() {
    this.suspendUsersService.reset();
  }
}

