<ng-container *ngIf="syncIntegrityErrors && syncIntegrityErrors.length > 0">
  <div class="card card-hover-shadow mb-3">
    <!-- Header -->
    <a class="card-header alert-danger card-btn btn-block">
      <h4 class="card-header-title text-white">Errori in Google Workspace</h4>
    </a>
    <div class="card-body" [collapse]="syncIntegrityErrorsIsCollapsed" [isAnimated]="true">
      <div class="row">
        <div class="col">
          <div class="alert alert-soft-info" role="alert" [innerHTML]="'STR.integrity_errors_card_alert_obbligo_controllo' | translate">
          </div>
          <a class="btn btn-primary" [routerLink]="['/workspace-control-system']" routerLinkActive="router-link-active" ><i
              class="fad fa-solar-system mr-2"></i>Controlla la Workspace</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-card-loading *ngIf="checkInProgress" [message]="'PASSAGGIOANNO.start.loading_message' | translate"></app-card-loading>

<div class="card card-hover-shadow">
  <div class="card-body">
    <div class="row justify-content-sm-center text-center py-5">
      <div class="col-sm-7 col-md-5">
        <h1><i class="fad fa-user-graduate mr-2"></i> {{'PASSAGGIOANNO.start.title' | translate}}</h1>
        <p>{{'PASSAGGIOANNO.start.sottotitolo' | translate}}</p>

        <button [disabled]="(syncIntegrityErrors && syncIntegrityErrors.length > 0) || checkInProgress" (click)="next()" type="button" class="btn btn-primary">
          {{'PASSAGGIOANNO.start.btn' | translate}} <i class="far fa-chevron-right ml-1"></i>
        </button>
      </div>
    </div>

    <div class="row m-3">
      <div class="col">
        <app-passaggio-anno-wizard-steps></app-passaggio-anno-wizard-steps>
      </div>
    </div>
  </div>
</div>
