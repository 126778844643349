import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { AccountService } from 'src/app/services/account.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-geniusuite-settings',
  templateUrl: './geniusuite-settings.component.html',
  styleUrls: ['./geniusuite-settings.component.scss']
})
export class GeniusuiteSettingsComponent implements OnInit {

  logoutUrl = environment.logoutUrl;
  version = environment.version;
  account: any;

  isBetaVersion: boolean = false;
  authenticationLogs: any;

  private _accountServiceSubscription: Subscription;

  constructor(private accountService: AccountService, private router: Router) { }

  ngOnInit() {

    this.account = this.accountService.data;

    this._accountServiceSubscription = this.accountService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.accountUpdated(result);
    });

    if (window.location.origin.startsWith(environment.betaUrl)) {
      this.isBetaVersion = true;
    }

    this.accountService.getAuthenticationLogs((logs) => {
      this.authenticationLogs = logs;
    });
  }

  accountUpdated(result) {
    this.account = result;
  }

  ngOnDestroy() {

    if (this._accountServiceSubscription)
      this._accountServiceSubscription.unsubscribe();
  }

  logout() {

    this.accountService.logout(() => {
      this.router.navigate(['/login']);
    });
  }

  betaVersion() {
    window.location.href = environment.betaUrl;
  }

  adminVersion() {
    window.location.href = environment.adminUrl;
  }

}
