<!-- Account -->
<div dropdown #dropdown="bs-dropdown" [isAnimated]="false">
  <a class="navbar-dropdown-account-wrapper" id="accountNavbarButton" dropdownToggle
    aria-controls="accountNavbarDropdown">
    <div class="avatar avatar-sm avatar-soft-primary avatar-circle">
      <span class="avatar-initials">{{ account.givenName | slice:0:1 }}{{ account.familyName | slice:0:1 }}</span>
      <!-- <img class="avatar-img" src="{{ account.google_access_token.avatar }}"> -->
      <!-- <span class="avatar-status avatar-sm-status avatar-status-success"></span> -->
    </div>
  </a>

  <div id="accountNavbarDropdown" *dropdownMenu aria-labelledby="accountNavbarButton" role="menu"
    class="dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account"
    style="width: 16rem; margin-right: 1rem;">
    <div class="dropdown-item disabled">
      <div class="media align-items-center">
        <!-- <div class="avatar avatar-soft-success avatar-sm avatar-circle mr-2">
          <span class="avatar-initials">{{ account.givenName | slice:0:1 }}{{ account.familyName | slice:0:1 }}</span>
        </div> -->
        <div class="media-body text-truncate">
          <span class="card-title h5 text-truncate">{{ account.givenName }} {{ account.familyName }}</span>
          <span class="card-text text-truncate">{{ account.email }}</span>
        </div>
      </div>
    </div>

    <div class="dropdown-divider"></div>

    <a class="dropdown-item" [routerLink]="['/ente']">
      <h6 class="text-truncate pr-2 mb-2" title="Ente">Ente: {{ account.ente.name }}</h6>
      <div class="pr-2 mb-0 small">
        Licenza attiva fino al <b>{{ account.ente.subscription_end | date:'mediumDate' }}</b>
      </div>
    </a>

    <div class="dropdown-divider"></div>

    <a class="dropdown-item" routerLink="/settings">
      <span class="text-truncate pr-2" title="Impostazioni">Licenza e Account</span>
    </a>

    <a class="dropdown-item" href="https://admin.google.com/" target="_blank" *ngIf="getAccountService().isGoogleWorkspaceAccount()">
      <span class="text-truncate pr-2" title="Console G-Suite">Vai alla console Google</span>
    </a>

    <a class="dropdown-item" href="https://geniusuite.com/guide-tutorial-geniusuite" target="_blank">
      <span class="text-truncate pr-2" title="Guida e Tutorial">Guida e Tutorial</span>
    </a>

    <a class="dropdown-item"
      href="https://geniusuite.com/assistenza-geniusuite/?utm_source=geniusuite&utm_medium=link&utm_campaign=assistenza_geniusuite"
      target="_blank">
      <span class="text-truncate pr-2" title="Contatti e assistenza">Contatti e assistenza</span>
    </a>

    <div class="dropdown-divider"></div>

    <a class="dropdown-item" (click)="logout()">
      <span class="text-truncate pr-2" title="Esci da Geniusuite">Esci da Geniusuite</span>
    </a>

    <a *ngIf="!isBetaVersion" class="dropdown-item" (click)="betaVersion()">
      <span class="text-truncate pr-2" title="Versione beta"><i class="fas fa-monkey mr-2"></i>Prova la versione
        BETA</span>
    </a>

    <a *ngIf="isBetaVersion" class="dropdown-item" (click)="adminVersion()">
      <span class="text-truncate pr-2" title="Versione beta"><i class="fas fa-monkey mr-2"></i>Torna alla versione
        normale</span>
    </a>

    <a class="dropdown-item disabled" (click)="checkForUpdate()">
      <span class="text-muted pr-2">versione {{version}}</span>
    </a>
  </div>
</div>
<!-- End Account -->
