import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})

export class AccountService {

  data: any;

  bootstrapCompleted: boolean;

  authenticationLogs: any;

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  constructor(private backend: BackendService) { }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  isLoggedIn() {
    return (this.data && this.data.id);
  }

  isGoogleWorkspaceAccount() {
    if (this.data && this.data.google_access_token && this.data.google_access_token.token && this.data.google_access_token.token.length > 0) {
      return true;
    }
    return false;
  }

  setBootstrapComplete() {
    this.bootstrapCompleted = true;
  }

  isBootstrapCompleted() {
    return this.bootstrapCompleted;
  }

  isProfileCompleted() {

    if (this.data.ente.addresses && this.data.ente.addresses.length > 0) {
      let address = this.data.ente.addresses[0];
      if (address.citta && address.indirizzo && address.telefono && address.email ) {
        return true;
      }
    }

    return false;
  }

  getData() {
    return this.data;
  }

  getUserData(success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    this.backend.get("csrf-cookie").subscribe(
      (response) => {

        this.backend.get("user").subscribe(
          (response) => {

            this.data = response;

            if (this.data.ente) {

              this.data.ente.subscription_expired = false;
              this.data.ente.subscription_expiring = 365 * 60 * 60 * 24;

              // elaboro le info della subscription
              if (this.data.ente.subscription_end) {
                this.data.ente.subscription_expiring = ((new Date(this.data.ente.subscription_end).getTime() - (new Date()).getTime())) / 1000;
                this.data.ente.subscription_expired = (new Date(this.data.ente.subscription_end).getTime() < (new Date()).getTime());

              }
            }

            this._subjectToUpdate.next(this.data);
            success_callback(this.data);

          }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
          }
        );

      }, (error) => {
        // this.backend.showErrors(error);
        this._subjectToUpdate.next(null);
        error_callback(error);
      }
    );
  }

  refreshUserData(success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    this.backend.get("user/refreshToken").subscribe(
      (response) => {
        this.data = response;
        this._subjectToUpdate.next(this.data);
        success_callback(this.data);
      }, (error) => {
        this.backend.showErrors(error);
        this._subjectToUpdate.next(null);
        error_callback(error);
      }
    );
  }

  logout(success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    this.backend.get("logout").subscribe(
      (response) => {
        this.bootstrapCompleted = false;
        this.data = null;
        this._subjectToUpdate.next(this.data);
        success_callback(this.data);
      }, (error) => {
        this.backend.showErrors(error);
        this._subjectToUpdate.next(null);
        error_callback(error);
      }
    );
  }

  refreshEnteData(body, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.backend.put("ente/update", body).subscribe(
      (response) => {
        this.getUserData(success_callback, error_callback);
      }, (error) => {
        this.getUserData(success_callback, error_callback);
      }
    );
  }

  getLinkAttivazioneAccount() {

    let linkAttivazione = null;

    if (this.data && this.data.ente && this.data.ente.links) {

      this.data.ente.links.forEach(link => {
        if (link.type == 'attivazione-account-geniusuite') {
          linkAttivazione = link.link;
        }
      });

    }

    return linkAttivazione;
  }

  getAuthenticationLogs(success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    this.backend.get("user/log/auth").subscribe(
      (response) => {
        this.authenticationLogs = response;
        success_callback(response);
      }, (error) => {
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

}
