<div class="container py-5 py-sm-7">
  <a class="d-flex justify-content-center mb-5">
    <img class="z-index-2" src="./assets/theme/svg/logos/geniusuite_partner.svg" alt="Geniusuite" style="width: 30rem;">
  </a>

  <div class="row justify-content-center">
    <div class="col-md-7 col-lg-7">

      <div class="card card-hover-shadow mb-3">
        <a class="card-header alert-danger">
          <h4 class="card-header-title text-white" [innerHTML]="'STR.workspace_autorizzazioni_title' | translate">
          </h4>
        </a>
        <div class="card-body">
          <div class="alert alert-soft-danger" role="alert"
            [innerHTML]="'STR.workspace_autorizzazioni_msg' | translate">
          </div>

          <button class="btn btn-primary" (click)="logout()">Effettua nuovamente l'accesso<i class="fas fa-sign-out ml-2"></i></button>
        </div>
      </div>

      <p class="d-flex justify-content-center"><a
          href="https://geniusuite.com/assistenza-geniusuite/?utm_source=geniusuite&utm_medium=link&utm_campaign=assistenza_geniusuite"
          target="_blank" class="btn btn-xs btn-outline-info ml-3">Se il problema persiste contatta l'assistenza <i
            class="fa-solid fa-headphones ml-2"></i></a></p>

    </div>
  </div>
</div>
