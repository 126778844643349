<div [formGroup]="formGroup">
  <div formGroupName="setting">
    <input type="hidden" [id]="id" [formControlName]="id">
  </div>
</div>

<table class="table">
  <thead class="thead-light">
    <tr>
      <th scope="col" style="width: 20%;">Parametri di accesso</th>
      <th scope="col" style="width: 16%;">Proprietario</th>
      <th scope="col" style="width: 16%;">Manager</th>
      <th scope="col" style="width: 16%;">Membri</th>
      <th scope="col" style="width: 16%;">Intera organizzazione</th>
      <th scope="col" style="width: 16%;">Esterni</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Contatta i propretari</th>
      <td>
        <app-switch-button id="switch_button_0_0" [disabled]="true" [(value)]="data[0][0]"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_0_1" [(value)]="data[0][1]"
          (valueChanged)="changeTableParametriAccesso(0,1,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_0_2" [(value)]="data[0][2]"
          (valueChanged)="changeTableParametriAccesso(0,2,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_0_3" [(value)]="data[0][3]"
          (valueChanged)="changeTableParametriAccesso(0,3,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_0_4" [(value)]="data[0][4]"
          (valueChanged)="changeTableParametriAccesso(0,4,$event)"></app-switch-button>
      </td>
    </tr>
    <tr>
      <th scope="row">Visualizzare i membri</th>
      <td>
        <app-switch-button id="switch_button_1_0" [disabled]="true" [(value)]="data[1][0]"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_1_1" [(value)]="data[1][1]"
          (valueChanged)="changeTableParametriAccesso(1,1,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_1_2" [(value)]="data[1][2]"
          (valueChanged)="changeTableParametriAccesso(1,2,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_1_3" [(value)]="data[1][3]"
          (valueChanged)="changeTableParametriAccesso(1,3,$event)"></app-switch-button>
      </td>
    </tr>
    <tr>
      <th scope="row">Visualizzare le conversazioni</th>
      <td>
        <app-switch-button id="switch_button_2_0" [disabled]="true" [(value)]="data[2][0]"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_2_1" [(value)]="data[2][1]"
          (valueChanged)="changeTableParametriAccesso(2,1,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_2_2" [(value)]="data[2][2]"
          (valueChanged)="changeTableParametriAccesso(2,2,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_2_3" [(value)]="data[2][3]"
          (valueChanged)="changeTableParametriAccesso(2,3,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_2_4" [(value)]="data[2][4]"
          (valueChanged)="changeTableParametriAccesso(2,4,$event)"></app-switch-button>
      </td>
    </tr>
    <tr>
      <th scope="row">Pubblicare post</th>
      <td>
        <app-switch-button id="switch_button_3_0" [(value)]="data[3][0]"
          (valueChanged)="changeTableParametriAccesso(3,0,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_3_1" [(value)]="data[3][1]"
          (valueChanged)="changeTableParametriAccesso(3,1,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_3_2" [(value)]="data[3][2]"
          (valueChanged)="changeTableParametriAccesso(3,2,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_3_3" [(value)]="data[3][3]"
          (valueChanged)="changeTableParametriAccesso(3,3,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_3_4" [(value)]="data[3][4]"
          (valueChanged)="changeTableParametriAccesso(3,4,$event)"></app-switch-button>
      </td>
    </tr>
  </tbody>
</table>

<table class="table">
  <thead class="thead-light">
    <tr>
      <th scope="col" style="width: 20%;">Impostazioni</th>
      <th scope="col" style="width: 16%;">Proprietario</th>
      <th scope="col" style="width: 16%;">Manager</th>
      <th scope="col" style="width: 16%;">Membri</th>
      <th scope="col" style="width: 16%;"></th>
      <th scope="col" style="width: 16%;"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Gestisci membri<br><small>Aggiungi, invita, approva</small></th>
      <td>
        <app-switch-button id="switch_button_4_0" [(value)]="data[4][0]"
          (valueChanged)="changeTableParametriAccesso(4,0,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_4_1" [(value)]="data[4][1]"
          (valueChanged)="changeTableParametriAccesso(4,1,$event)"></app-switch-button>
      </td>
      <td>
        <app-switch-button id="switch_button_4_2" [(value)]="data[4][2]"
          (valueChanged)="changeTableParametriAccesso(4,2,$event)"></app-switch-button>
      </td>
      <td></td>
      <td></td>
    </tr>
  </tbody>
</table>

<div class="form-group row">
  <div class="col-6">
    <label class="input-label">Chi può unirsi al gruppo</label>
    <app-select (valueChange)="whoCanJoinOnChange($event)" [id]="'select-whoCanJoin'"
      placeholder="Chi può unirsi al gruppo" [data]="whoCanJoinValues" [showValue]="'label'"
      [resultAttribute]="'value'" [(value)]="data[5][0]" [optionAttributeShow]="'label'" [showClear]="false">
    </app-select>
  </div>
</div>

<div class="row">
  <div class="col-6">
    <app-switch [(value)]="data[6][0]" (valueChange)="allowExternalMembersOnChange()" [id]="'switch_button_6_0'"
      labelText="Consenti membri esterni alla tua organizzazione">
    </app-switch>
  </div>
</div>