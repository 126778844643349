import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { ConfigService } from '../config.service';
import { UserService } from '../general/user.service';
import { GoogleDirectoryService } from '../google/google-directory.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class SyncUsersFromGoogleService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  items: any[];

  constructor(
    private backend: BackendService,
    private userService: UserService,
    private toast: ToastService,
    private directoryService: GoogleDirectoryService
  ) {
    this.reset();
  }

  reset() {
    this.items = [];
    this._subjectToUpdate.next(this.items);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  addUsers(newItems) {

    newItems.forEach(item => {

      let itemFound = this.items.find((element) => element?.id == item?.id);

      if (!itemFound) {
        this.items.push(item);
      }
    });

    this._subjectToUpdate.next(this.items);
  }

  deleteItemsAtIndex(indexArray) {

    indexArray.forEach(idx => {
      this.items[idx] = null;
    });

    this.items = this.items.filter((el) => { return el != null });

    this._subjectToUpdate.next(this.items);
  }

  execOperation(body, callback = (error) => { }) {

    let users = [];

    this.items.forEach(user => {
      // Check se esiste su Geniusuite
      let userGeniusuite = this.userService.getByGoogleId(user.id);

      if (!userGeniusuite) {

        if (!user.name || !user.primaryEmail) {
          // potrebbe venire da un gruppo quindi provo a recuperare l'utente
          let google_user = this.directoryService.getUser(user.id);

          if (google_user) {
            user = google_user;
          }
        }

        users.push({
          id: user.id,
          givenName: user.name?.givenName,
          familyName: user.name?.familyName,
          email: user.primaryEmail,
          position: body
        });
      }
    });

    this.backend.post("op/sync-users-from-google", { users: users }).subscribe((result) => {
      this.reset();
      this.toast.success('Sincronizzazione Utenti avviato');
      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }
}
