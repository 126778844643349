import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { PageService } from 'src/app/services/page.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-classe-profile',
  templateUrl: './classe-profile.component.html',
  styleUrls: ['./classe-profile.component.scss']
})
export class ClasseProfileComponent implements OnInit, OnDestroy {

  @Input() id_plesso: any;
  @Input() id_classe: any;
  showForm: boolean;
  showDelete: boolean;

  private _activeRouteSubscription: Subscription;
  private _plessoServiceSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private utilityService: UtilityService,
    private activeRoute: ActivatedRoute,
    private plessoService: PlessoService,
    private pageService: PageService
  ) { }

  ngOnInit() {

    this.unsubscribeAll();

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id_plesso = routeParams.id_plesso;
      this.id_classe = routeParams.id_classe;
      this.init();
    });

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.updateData();
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();

    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();
  }

  init() {
    this.showForm = false;
    this.showDelete = false;

    if (this.id_classe && this.id_classe > 0) {
      this.showForm = this.utilityService.checkRouteActionType(this.route, "edit");
      this.showDelete = this.utilityService.checkRouteActionType(this.route, "delete");
      this.updateData();
    }
    else {
      this.showForm = true;
    }
  }

  updateData() {
    if (this.id_classe && this.id_plesso && this.id_plesso > 0 && this.id_classe > 0) {
      this.plessoService.getClasse(this.id_plesso, this.id_classe, (data) => {
        this.pageService.updatePageTitle({ classe: data.name });
      }, (error) => {
        this.plessoService.all();
      });
      this.plessoService.get(this.id_plesso, (data) => {
        this.pageService.updatePageTitle({ plesso: data.name });
      });
    }
  }

}
