import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { GoogleDriveService } from 'src/app/services/google/google-drive.service';
import { DeleteUsersService } from 'src/app/services/operations/delete-users.service';
import { AddMembersToGroupsService } from 'src/app/services/operations/groups/add-members-to-groups.service';
import { SyncdataService } from 'src/app/services/syncdata.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-workspace-control-system',
  templateUrl: './workspace-control-system.component.html',
  styleUrls: ['./workspace-control-system.component.scss']
})
export class WorkspaceControlSystemComponent implements OnInit {

  syncIntegrityErrors: any;
  syncIntegrityErrorsDeleteUsers: any;
  syncIntegrityErrorsIsCollapsed: boolean = false;
  checkInProgressErrors: boolean = false;

  groupsIntegrityErrors: any;
  groupsIntegrityErrorsIsCollapsed: boolean = false;
  groupsIntegrityProgress: number = null;

  classroomIntegrityErrors: any;
  classroomIntegrityErrorsIsCollapsed: boolean = false;
  classroomIntegrityProgress: number = null;

  workspaceIntegrityErrors: any;
  workspaceIntegrityErrorsIsCollapsed: boolean = false;
  workspaceIntegrityProgress: number = null;

  private _syncdataServiceSubscription: Subscription;
  private _syncdataServiceGroupsSubscription: Subscription;
  private _syncdataServiceClassroomSubscription: Subscription;

  constructor(
    private syncdataService: SyncdataService,
    private deleteUsersService: DeleteUsersService,
    private addMembersToGroupsService: AddMembersToGroupsService,
    private toast: ToastService,
    private router: Router
  ) { }

  ngOnInit() {

    this._syncdataServiceSubscription = this.syncdataService.getSubjectToUpdateObservableSyncIntegrity().subscribe((errors) => {
      this.updateSyncIntegrityErrors(errors);
    });

    this._syncdataServiceGroupsSubscription = this.syncdataService.getSubjectToUpdateObservableGroupsIntegrity().subscribe((data) => {
      this.updateGroupsIntegrity(data);
    });

    this._syncdataServiceClassroomSubscription = this.syncdataService.getSubjectToUpdateObservableClassroomIntegrity().subscribe((data) => {
      this.updateClassroomIntegrity(data);
    });

    this.parseSyncIntegrityErrors(this.syncdataService.getAllSyncIntegrityErrors());

    // Attenzione questa operazione serve per preparare anche i dati per le altre operazioni
    // se si vuole disattivare dall'avvio del componente bisogna modifcare le altre funzioni di controllo
    this.updateSystemDataAndCheckIntegrity();
  }

  ngOnDestroy() {

    if (this._syncdataServiceSubscription) {
      this._syncdataServiceSubscription.unsubscribe();
    }

    if (this._syncdataServiceGroupsSubscription) {
      this._syncdataServiceGroupsSubscription.unsubscribe();
    }

    if (this._syncdataServiceClassroomSubscription) {
      this._syncdataServiceClassroomSubscription.unsubscribe();
    }

  }

  checkGroupsIntegrity() {
    this.groupsIntegrityProgress = 0;
    this.syncdataService.checkGroupsIntegrity();
  }

  updateGroupsIntegrity(data) {
    this.groupsIntegrityProgress = data.progress;
    this.groupsIntegrityErrors = data.errors;

    if (this.groupsIntegrityProgress == 100) {
      setTimeout(() => {
        this.groupsIntegrityProgress = null;
      }, 2000);
    }
  }

  checkClassroomIntegrity() {
    this.classroomIntegrityProgress = 0;
    this.syncdataService.checkClassroomIntegrity();
  }

  updateClassroomIntegrity(data) {
    this.classroomIntegrityProgress = data.progress;
    this.classroomIntegrityErrors = data.errors;

    if (this.classroomIntegrityProgress == 100) {
      setTimeout(() => {
        this.classroomIntegrityProgress = null;
      }, 2000);
    }
  }

  updateSystemDataAndCheckIntegrity() {
    this.checkInProgressErrors = true;
    this.syncdataService.updateSystemDataAndCheckIntegrity();
  }

  updateSyncIntegrityErrors(errors) {
    this.checkInProgressErrors = false;
    this.parseSyncIntegrityErrors(errors);
  }

  parseSyncIntegrityErrors(errors) {

    this.syncIntegrityErrors = [];
    this.syncIntegrityErrorsDeleteUsers = [];

    errors.forEach(error => {
      if (error.type == 'user' && error.error.includes('missing_google_user')) {
        this.syncIntegrityErrorsDeleteUsers.push(error);
      }
      else {
        this.syncIntegrityErrors.push(error);
      }
    });
  }

  fixSyncIntegrityErrors(error) {

    switch (error.type) {
      case 'user':
        if (error.error.includes('missing_google_user')) {
          this.router.navigate(['/user/' + error.entity.user_id + '/delete']);
        }
        else {
          this.router.navigate(['/user/' + error.entity.user_id]);
        }
        break;
      case 'plesso':
        this.router.navigate(['/plesso/' + error.entity.id]);
        break;
      case 'classe':
        this.router.navigate(['/plesso/' + error.entity.plesso_id + '/classe/' + error.entity.id]);
        break;
      case 'organigramma':
        this.router.navigate(['/organigramma/' + error.entity.id]);
        break;

      default:
        break;
    }

  }

  fixGroupsIntegrityErrors(error) {

    switch (error.type) {
      case 'classe':
        this.router.navigate(['/plesso/' + error.entity.plesso_id + '/classe/' + error.entity.id]);
        break;
      case 'organigramma':
        this.router.navigate(['/organigramma/' + error.entity.id]);
        break;

      default:
        break;
    }
  }

  deleteUsersCommand() {

    this.syncIntegrityErrorsIsCollapsed = true;

    let dataSelected = [];

    this.syncIntegrityErrorsDeleteUsers.forEach(error => {
      if (error.type == 'user' && error.error.includes('missing_google_user')) {
        dataSelected.push(error.entity);
      }
    });

    this.deleteUsersService.addUsers(dataSelected);
    this.syncIntegrityErrorsDeleteUsers = [];

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/delete-users']);
  }

  fixGroupsIntegrityCommand() {
    let dataSelected = [];

    this.groupsIntegrityErrors.forEach(error => {
      dataSelected.push({
        id: error.user.google_id,
        email: error.user?.email ? error.user.email : error.user.google_user.primaryEmail,
        user_id: error.user.user_id,
        type: "USER",
        groupKey: error.group.groupKey
      });
    });

    this.addMembersToGroupsService.addMembers(dataSelected);
    this.groupsIntegrityErrors = [];

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/add-members-to-groups']);
  }

  fixClassroomIntegrityErrors(error) {

    switch (error.type) {
      case 'classe':
        this.router.navigate(['/plesso/' + error.entity.plesso_id + '/classe/' + error.entity.id]);
        break;
      case 'classroom':
        this.router.navigate(['/classroom/' + error.classroom.courseId]);
        break;

      default:
        break;
    }
  }

  checkWorkspaceIntegrity() {

  }

}
