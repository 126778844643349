import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MonitorService } from 'src/app/services/general/monitor.service';
import { PageService } from 'src/app/services/page.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-alfred-widget',
  templateUrl: './alfred-widget.component.html',
  styleUrls: ['./alfred-widget.component.scss']
})
export class AlfredWidgetComponent implements OnInit {

  alfredUrl = environment.alfredUrl;

  monitors: any;
  monitorsData: any;
  monitorsError: any;
  isLoadingMonitors: boolean;
  checkDasboardMonitors: boolean = true;

  private _monitorServiceSubscription: Subscription;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private monitorService: MonitorService,
    private utilityService: UtilityService,
    private pageService: PageService
  ) { }

  ngOnInit() {
    this.isLoadingMonitors = false;

    this._monitorServiceSubscription = this.monitorService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {

        this.monitors = result;

        // Se non hai monitor attivi ti mando alla pagina di richiesta
        if (!this.monitors || this.monitors.length < 1) {
          return;
        }

        this.dataUpdated(result);
      }
    });

    this.init();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._monitorServiceSubscription)
      this._monitorServiceSubscription.unsubscribe();
  }

  init() {

    this.isLoadingMonitors = true;

    this.monitorService.all((result) => {
      this.monitors = result;
      this.dataUpdated(result);
    });
  }

  dataUpdated(result) {
    this.monitorsData = this.parseResult(result);
    this.isLoadingMonitors = false;
  }

  parseResult(result) {

    let table = [];

    result.forEach(element => {

      table.push(
        {
          "nome": [element.name, element.description],
          "code": [element.code],
          "status": [element.active ? '<span class="badge badge-success">Attivo</span>' : '<span class="badge badge-danger">Non attivo</span>'],
          "link": ['<a target="_blank" href="' + this.alfredUrl + element.code + '">' + this.alfredUrl + element.code + '</a>'],
          "_export_data": {
            name: element.name,
            code: element.code,
            description: element.description,
            status: element.active,
            link: this.alfredUrl + element.code
          },
        });
    });

    return table;
  }

  rowClick(data) {
    let monitor = this.monitors[data.index];
    this.router.navigate(['/monitor/' + monitor.id]);
  }

}
