<div class="card card-hover-shadow">
  <!-- Header -->
  <div class="card-header">
    <h5 class="card-header-title">Struttura scolastica di Plessi e Classi</h5>
    <div class="d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" [routerLink]="['/plesso/create']">
        <i class="fas fa-plus mr-2"></i>Aggiungi un nuovo plesso
      </button>
    </div>
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div class="card-body" style="overflow-y: auto;">

    <!-- <div class="row">
      <div class="col">

      </div>
    </div> -->

    <p-organizationChart [value]="plessoTree" selectionMode="single" [(selection)]="selectedNode"
      (onNodeSelect)="onNodeSelect($event)">
      <ng-template let-node pTemplate="default">
        <div class="text-truncate" style="max-width: 150px;" [pTooltip]="node.label">
          <i *ngIf="node.icon" class="fal fa-{{node.icon}} mr-2"></i>{{node.label}}
        </div>
      </ng-template>
      <ng-template let-node pTemplate="classi">
        <h5 class="text-truncate" style="max-width: 150px;">{{node.label}}</h5>
        <button *ngFor="let classe of node.classi" type="button" class="btn btn-outline-primary m-2"
          routerLink="/plesso/{{node.plesso.id}}/classe/{{classe.id}}"><i *ngIf="node.icon"
            class="fas fa-{{node.icon}} mr-2"></i>{{ classe.name }}</button>
        <button type="button" class="btn btn-sm btn-outline-primary m-2"
          routerLink="/plesso/{{node.plesso.id}}/classe"><i class="fas fa-plus mr-2"></i>Aggiungi una classe</button>
      </ng-template>
    </p-organizationChart>
  </div>
  <!-- End Body -->
</div>