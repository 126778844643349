import { Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { OrderTypeList, UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-plesso-form-delete',
  templateUrl: './plesso-form-delete.component.html',
  styleUrls: ['./plesso-form-delete.component.scss']
})
export class PlessoFormDeleteComponent implements OnInit, OnDestroy, OnChanges {
  regEx = ConfigService.regExDelete;
  @Input() id: string;
  data: any;
  plesso: any;
  error: any;

  // Dati Google e Stuttura di Preview
  orgUnits: any;
  orgUnitPlessoId: any;
  orgUnitsTreeView: any;
  orgUnitsPreview: any;
  groups: any;
  groupsPreview: any;

  // Organigramma Destination Update User
  organigrammiTreeView: any;

  // Configurazione TreeView
  configTreeView: any = {
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: true
  };

  // Visualizzazione Setting Advanced
  showAdvancedSettings: boolean = false;

  // Loading Pagina
  loadingPlesso: boolean;
  loadingOrganigrammi: boolean;
  loadingGoogleOrgUnits: boolean;
  loadingGoogleGroups: boolean;
  loadingParameterService: boolean;
  formLoading: boolean;
  parameterLoaded: boolean;

  private _plessoServiceSubscription: Subscription;
  private _organigrammaServiceSubscription: Subscription;
  private _googleServiceOrgUnitsSubscription: Subscription;
  private _googleServiceGroupsSubscription: Subscription;

  constructor(
    private utilityService: UtilityService,
    private plessiService: PlessoService,
    private organigrammaService: OrganigrammaService,
    private googleService: GoogleDirectoryService,
    private ngZone: NgZone,
    private router: Router) { }

  ngOnInit() {
    this.unsubscibeAll();

    // Set Default Check
    this.data = {
      deleteStudenti: false,
      deleteUser: false,
      deleteOrgUnit: false,
      organigrammaStudenti: {
        id: null,
        orgUnitPath: null
      },
      organigrammaUser: {
        id: null,
        orgUnitPath: null
      }
    };

    this.loadingPlesso = false;
    this.loadingParameterService = false;
    this.loadingGoogleOrgUnits = false;
    this.loadingGoogleGroups = false;
    this.formLoading = false;
    this.error = null;
    this.parameterLoaded = false;

    this.orgUnitsPreview = null;
    this.groupsPreview = null;
    this.showAdvancedSettings = false;

    if (!(this.id && this.id.length > 0)) {
      this.backendError();
    }

    // Dati Plesso
    this._plessoServiceSubscription = this.plessiService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError();
      else
        this.plessoUpdated();
    })

    this.plessoUpdated();

    // Dati Google
    this._googleServiceOrgUnitsSubscription = this.googleService.getSubjectToUpdateOrgUnitsObservable().subscribe((result) => {
      if (!result)
        this.googleError();
      else
        this.orgUnitsUpdated(result);
    });

    this._googleServiceGroupsSubscription = this.googleService.getSubjectToUpdateGroupsObservable().subscribe((result) => {
      if (!result)
        this.googleError();
      else
        this.groupsUpdated(result);
    });

    this.getGoogleData();

    // Organigrammi
    this._organigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError();
      else
        this.organigrammiUpdate(result);
    })

    this.loadingOrganigrammi = true;
    this.organigrammaService.all();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  plessoUpdated() {
    this.loadingPlesso = true;

    this.plessiService.get(this.id, (result) => {

      this.plesso = result;

      if (this.plesso.google_org_unit) {
        // TODO_ZORAN Preview OrgUnit Delete
        this.orgUnitPlessoId = this.plesso.google_org_unit
      }

      if (this.plesso.google_group && this.plesso.google_group.length > 0) {
        // TODO_ZORAN Preview Google Group Delete

      }

      this.loadingPlesso = false;
    }, (error) => {
      this.plessiService.all();
      this.backendError();
    })
  }

  organigrammiUpdate(result) {
    this.loadingOrganigrammi = true;

    if (result) {
      let filtered = result.filter(x => x.google_org_unit && x.google_org_unit.orgUnitId);
      this.organigrammiTreeView = this.utilityService.covertListToTreeViewItems(filtered, "id", "parent_id", "name", "id", null, OrderTypeList.Ascending, "name");
    }

    this.loadingOrganigrammi = false;
  }

  getGoogleData() {
    this.loadingGoogleOrgUnits = true;
    this.loadingGoogleGroups = true;

    this.googleService.orgunitsList();
    this.googleService.groupsList();
  }

  unsubscibeAll() {
    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();

    if (this._googleServiceOrgUnitsSubscription)
      this._googleServiceOrgUnitsSubscription.unsubscribe();

    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();
  }

  orgUnitsUpdated(result) {
    this.orgUnits = result;
    this.loadingGoogleOrgUnits = false;
    // TODO_ZORAN OrgUnits Preview

    this.orgUnitsTreeView = this.utilityService.covertListToTreeViewItems(this.orgUnits, "orgUnitPath", "parentOrgUnitPath", "name", "orgUnitId", "/", OrderTypeList.Ascending, "name");

    if (this.plesso && this.plesso.google_org_unit && this.plesso.google_org_unit.orgUnitId)
      this.orgUnitsTreeView = this.orgUnitsTreeView.filter(x => x["key"] == this.plesso.google_org_unit.orgUnitId);
  }

  groupsUpdated(result) {
    this.groups = result;

    if (this.groups && this.groups.length > 0 && this.plesso) {
      // TODO_ZORAN Gruppi Preview
    }

    this.loadingGoogleGroups = false;
  }

  backendError() {
    this.error = "backend";
    this.formLoading = false;
    this.loadingPlesso = false;
  }

  googleError() {
    this.error = "google";
    this.formLoading = false;
    this.loadingGoogleOrgUnits = false;
    this.loadingGoogleGroups = false;
  }

  retryAfterLoadingError() {
    this.ngOnInit();
  }

  changeStatusCollapse() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  onSubmit(myForm: NgForm) {
    this.formLoading = true;

    // Copio i dati dell'oggetto senza riferimento
    let reqBody = JSON.parse(JSON.stringify(myForm.form.value));

    // Delete
    this.plessiService.delete(this.id, reqBody, true, (result) => {
      // TODO_ZORAN Definire una volta cancellato un plesso dove portare l'utente
      this.ngZone.run(() => {
        this.router.navigate(["/dashboard"]);
      });
      this.formLoading = false;
    }, (error) => this.backendError())
  }

  onSelectedRelationInfo(value, position: string) {
    if (value) {
      this.data[position].id = value;

      this.organigrammaService.get(this.data[position].id, (data) => {
        this.data[position].name = data.name;
        this.data[position].orgUnitPath = data.google_org_unit.orgUnitPath;
      }, (error) => {
        this.data[position] = {
          id: null,
          orgUnitPath: null
        }
      });
    }
  }

  filterGroupsByType(google_group, relation_info) {
    return google_group.filter(x => x.relation_info == relation_info);
  }
}
