import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { TreeNode } from 'primeng/api';
import { AccountService } from 'src/app/services/account.service';
import { OrderTypeList, UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-organigramma-profile-tree-card',
  templateUrl: './organigramma-profile-tree-card.component.html',
  styleUrls: ['./organigramma-profile-tree-card.component.scss']
})
export class OrganigrammaProfileTreeCardComponent implements OnInit {

  private _organigrammaServiceSubscription: Subscription;

  organigrammaTree: TreeNode[];

  selectedNode: TreeNode;

  constructor(
    private router: Router,
    private organigrammaService: OrganigrammaService,
    private utilityService: UtilityService,
    private accountService: AccountService,
  ) { }

  ngOnInit() {

    this._organigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.organigrammaUpdated(result);
    });

    this.organigrammaService.all();
  }

  ngOnDestroy() {

    if (this._organigrammaServiceSubscription)
      this._organigrammaServiceSubscription.unsubscribe();
  }

  organigrammaUpdated(result) {
    this.organigrammaTree = [{
      label: this.accountService.data.ente.name,
      children: this.parseOrganigrammaTreeMenu(result),
      expanded: true,
      icon: "university"
    }]
  }

  parseOrganigrammaTreeMenu(list: any): TreeNode[] {
    let menu = [];

    list.forEach(organigramma => {
      let menu_item = {
        id: organigramma.id,
        parent_id: organigramma.parent_id,
        label: organigramma.name,
        children: [],
        expanded: true,
        organigramma: organigramma,
        icon: "sitemap"
      };

      menu.push(menu_item);
    });

    return this.utilityService.convertListToTree(menu, "id", "parent_id", OrderTypeList.Ascending, "label");
  }

  onNodeSelect(event) {
    // this.messageService.add({ severity: 'success', summary: 'Node Selected', detail: event.node.label });
    let organigramma = event.node.organigramma;
    this.router.navigate(["/organigramma/" + organigramma.id]);
  }

}
