<!-- Card -->
<div class="card">
  <!-- Header -->
  <div class="card-header p-1">
    <div class="row justify-content-between align-items-center flex-grow-1">
      <div class="col">
        <tabset [justified]="true" type="pills">
          <tab #tab1="tab" (selectTab)="selectTab(1)">
            <ng-template tabHeading>
              <i class="fas fa-user-friends nav-icon"></i> {{ 'STR.Utenti' | translate }}
            </ng-template>
          </tab>
          <tab #tab6="tab" (selectTab)="selectTab(6)" [disabled]="!isGoogleWorkspaceAccount">
            <ng-template tabHeading>
              <i class="fas fa-user-friends nav-icon"></i> {{ 'STR.utenti_sospesi' | translate }}
            </ng-template>
          </tab>
          <tab #tab4="tab" (selectTab)="selectTab(4)" [disabled]="!isGoogleWorkspaceAccount">
            <ng-template tabHeading>
              <i class="fas fa-user-friends nav-icon"></i> {{ 'STR.utenti_da_ripristinare' | translate }}
            </ng-template>
          </tab>
          <tab #tab5="tab" (selectTab)="selectTab(5)" [disabled]="!isGoogleWorkspaceAccount">
            <ng-template tabHeading>
              <i class="fas fa-user-friends nav-icon"></i> {{ 'STR.utenti_da_sincronizzare' | translate }}
            </ng-template>
          </tab>
          <tab #tab2="tab" (selectTab)="selectTab(2)" [disabled]="!isGoogleWorkspaceAccount">
            <ng-template tabHeading>
              <i class="fas fa-users nav-icon"></i> {{ 'STR.gruppi' | translate }}
            </ng-template>
          </tab>
          <tab #tab3="tab" (selectTab)="selectTab(3)" [disabled]="!isGoogleWorkspaceAccount">
            <ng-template tabHeading>
              <i class="fas fa-at nav-icon"></i> {{ 'STR.Domini' | translate }}
            </ng-template>
          </tab>
        </tabset>
      </div>
    </div>
  </div>

  <app-card-datatable-ng *ngIf="tab1.active" title="{{ 'STR.Utenti' | translate }}" [rowData]="usersData" [data]="users"
    [menuType]="'users'" [idRootData]="ente.id" [isLoadingData]="isLoadingUsers" [showAvatarOnFirstColumn]="true"
    [error]="usersError" (rowClickEvent)="rowClickUser($event)" (addClickEvent)="addClickUser()" [showAddButton]="true"
    [showExportButton]="true"></app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab2.active" title="{{ 'STR.gruppi' | translate }}" [data]="groups"
    [rowData]="groupsData" [menuType]="'groups'" [idRootData]="ente.id" (rowClickEvent)="rowClickGroup($event)"
    [isLoadingData]="isLoadingGroups" [error]="groupsError" [showExportButton]="true"></app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab3.active" title="{{ 'STR.Domini' | translate }}" [data]="domains"
    [rowData]="domainsData" [menuType]="'domini'" [idRootData]="ente.id" [isLoadingData]="isLoadingDomains"
    [error]="domainsError" [showExportButton]="true"></app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab4.active" (rowClickEvent)="rowClickUserDeleted($event)" title="{{ 'STR.utenti_da_ripristinare' | translate }}"
    [data]="usersDeleted" [rowData]="usersDataDeleted" [menuType]="'users-deleted'" [idRootData]="ente.id"
    [isLoadingData]="isLoadingUsersDeleted" [showAvatarOnFirstColumn]="true" [error]="usersDeletedError"
    [showExportButton]="true">
  </app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab5.active" (rowClickEvent)="rowClickUserGoogle($event)"
    title="{{ 'STR.utenti_da_sincronizzare' | translate }}" [data]="usersNotSync" [rowData]="usersDataNotSync"
    [menuType]="'users-not-sync'" [idRootData]="ente.id" [isLoadingData]="isLoadingUsersNotSync"
    [showAvatarOnFirstColumn]="true" [error]="usersNotSyncError" [showExportButton]="true">
  </app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab6.active" (rowClickEvent)="rowClickUserSuspended($event)"
    title="{{ 'STR.utenti_sospesi' | translate }}" [data]="usersSuspended" [rowData]="usersDataSuspended"
    [menuType]="'users-suspended'" [idRootData]="ente.id" [isLoadingData]="isLoadingUsersSuspended"
    [showAvatarOnFirstColumn]="true" [error]="usersSuspendedError" [showExportButton]="true">
  </app-card-datatable-ng>
</div>
<!-- End Card -->
