<!-- Card -->
<div class="card">
    <!-- Header -->
    <div class="card-header p-1">
        <div class="row justify-content-between align-items-center flex-grow-1">
            <div class="col">
                <tabset [justified]="true" type="pills">

                    <tab #tab1="tab" (selectTab)="selectTab(1)">
                        <ng-template tabHeading>
                            <i class="fas fa-chalkboard-teacher nav-icon"></i>{{ 'STR.docenti' | translate }}
                        </ng-template>
                    </tab>

                    <tab #tab2="tab" (selectTab)="selectTab(2)">
                      <ng-template tabHeading>
                          <i class="fas fa-user-graduate nav-icon"></i>{{ 'STR.studenti' | translate }}
                      </ng-template>
                  </tab>

                </tabset>
            </div>
        </div>
    </div>
    <!-- End Header -->

    <app-card-datatable-ng *ngIf="tab1.active" title="{{ 'STR.docenti' | translate }}" [data]="docenti" [rowData]="docentiData" [idRootData]="id" [menuType]="'users|materia'" [isLoadingData]="isLoading.docenti" [showAvatarOnFirstColumn]="true"
      [error]="docentiError" (rowClickEvent)="rowClickDocente($event)" (addClickEvent)="addClickUser('docente')" [showAddButton]="true" [showExportButton]="true"></app-card-datatable-ng>

    <app-card-datatable-ng *ngIf="tab2.active" title="{{ 'STR.studenti' | translate }}" [data]="studenti" [rowData]="studentiData" [idRootData]="id" [menuType]="'users|materia'" [isLoadingData]="isLoading.studenti" [showAvatarOnFirstColumn]="true"
      [error]="studentiError" (rowClickEvent)="rowClickStudente($event)" (addClickEvent)="addClickUser('studente')" [showAddButton]="true" [showExportButton]="true">
    </app-card-datatable-ng>
</div>
<!-- End Card -->
