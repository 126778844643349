import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-organigramma-profile-info-card',
  templateUrl: './organigramma-profile-info-card.component.html',
  styleUrls: ['./organigramma-profile-info-card.component.scss']
})
export class OrganigrammaProfileInfoCardComponent implements OnInit, OnChanges, OnDestroy {

  @Input() id: any;

  data: any;
  countUtenti = 0;
  showInfo = false;

  private _oganigrammaServiceSubscription: Subscription;

  constructor(private organigrammaService: OrganigrammaService, private pageService: PageService) { }

  ngOnInit() {

    this.unsubscribeAll();

    this._oganigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.updateData();
    });

    if (this.id) {
      this.updateData();
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  updateData() {
    if (this.id && this.id > 0) {
      this.organigrammaService.getWithUsers(this.id, (data) => {
        this.data = data;
        if (data.users)
          this.countUtenti = data.users.length;
        this.pageService.updatePageTitle({ organigramma: this.data.name});
      }, (error) => {
        this.organigrammaService.all();
      });
    }
  }

  unsubscribeAll() {
    if (this._oganigrammaServiceSubscription)
      this._oganigrammaServiceSubscription.unsubscribe();
  }

}
