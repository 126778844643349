import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PassaggioAnnoService } from 'src/app/services/operations/passaggio-anno.service';

@Component({
  selector: 'app-passaggio-anno-wizard-end',
  templateUrl: './passaggio-anno-wizard-end.component.html',
  styleUrls: ['./passaggio-anno-wizard-end.component.scss']
})
export class PassaggioAnnoWizardEndComponent implements OnInit {

  constructor(private router: Router, private passaggioAnnoService: PassaggioAnnoService) { }

  ngOnInit(): void {

    this.passaggioAnnoService.checkActivity();

    if (this.passaggioAnnoService.getActivityExist())
      if (this.passaggioAnnoService.getActivityInProgress())
        this.router.navigate(['/passaggio-anno/wait']);
  }

  nuovoPassaggio() {

    this.passaggioAnnoService.forzaNuovoPassaggioAnno();
    this.router.navigate(['/passaggio-anno']);
  }
}
