import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor() { }

  //	Array dei Tag da Associare
  public static tagsType = [
    {
      value: 'coordinatori',
      name: 'Coordinatori di classe',
    },
    {
      value: 'rappresentanti',
      name: 'Rappresentanti di classe',
    },
    {
      value: 'amministratori',
      name: 'Amministratori',
    }
  ];

  //	Array con i nomi delle aziende che operano sul territorio
  private static _userType = [
    {
      key: 'docente',
      role: 'google-teacher',
      value: 'Docente',
      relation_info: 'docenti',
    },
    {
      key: 'studente',
      role: 'google-student',
      value: 'Studente',
      relation_info: 'studenti',
    },
    {
      key: 'worker',
      role: 'google-worker',
      value: 'Altro',
      relation_info: 'workers',
    },
  ];

  static get userTypeSelect() {
    return this.sliceAndSortArray(this._userType);
  }

  public static get regExDelete() {
    return /\belimina\b/i;
  }

  public static get regExSuspend() {
    return /\bsospendi\b/i;
  }

  public static get regExProvision() {
    return /\bconfermare\b/i;
  }

  public static get regExDecline() {
    return /\brifiuta\b/i;
  }

  public static get regExRemove() {
    return /\brimuovi\b/i;
  }

  public static get regExReactive() {
    return /\briattiva\b/i;
  }

  public static get regExUpdate() {
    return /\bmodifica\b/i;
  }

  public static get regExConfirm() {
    return /\bconferma\b/i;
  }

  static getUserTypeObjectByKey(key: any) {
    return this.getObjectByParam(this._userType, key);
  }

  static getUserTypeValueByKey(key: any) {
    return this.getValueByParam(this._userType, key);
  }

  static getUserTypeKeyByRole(role: string) {
    return this.getValueByParam(this._userType, role, 'role', 'key');
  }

  static getUserTypeRelationInfoByKey(key: string) {
    return this.getValueByParam(this._userType, key, 'key', 'relation_info');
  }

  static isGoogleAdminRole(role) {
    if (role == 'google-admin')
      return true;
    return false;
  }

  /**
   * Rimuove inutili elementi e li ordina
   * @param container
   */
  static sliceAndSortArray(container) {
    return container.slice().sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });
  }


  /**
   * Cerca un valore
   * @param container lista di riferimento per la ricerca
   * @param param parametro da cercare
   * @param key chiave nella quale fare la ricerca (opzionle)
   * @param returnValue valore da restituire (opzionale)
   */
  static getValueByParam(container, param, key = 'key', returnValue = 'value') {
    let found = '';

    if (key != null && typeof key != 'undefined') {
      container.forEach((e) => {

        if (e[key].toString().toLowerCase() == param.toString().toLowerCase())
          return (found = e[returnValue]);
      });
    }

    return found;
  }

  static getObjectByParam(container, param, key = 'key') {
    let found = '';

    if (key != null && typeof key != 'undefined') {
      container.forEach((e) => {

        if (e[key].toString().toLowerCase() == param.toString().toLowerCase())
          return (found = e);
      });
    }

    return found;
  }

}
