import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-card-operation-activity',
  templateUrl: './card-operation-activity.component.html',
  styleUrls: ['./card-operation-activity.component.scss']
})
export class CardOperationActivityComponent implements OnInit {

  activityData: any;

  @Input() filterActivity: any = [];

  _activityServiceEachJobSubscription: Subscription;
  _activityServiceSubscription: Subscription;

  constructor(
    private activity: ActivityService,
    private changeDetector: ChangeDetectorRef,
    private accountService: AccountService,
  ) { }

  ngOnInit() {

    this._activityServiceEachJobSubscription = this.activity.getSubjectToUpdateObservableForEachJob().subscribe((result) => {
      if (result)
        this.updateActivityData(result);
    });

    this._activityServiceSubscription = this.activity.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.updateActivityData(result);
    });

    this.activity.updateActivityData();
  }

  ngOnDestroy() {

    if (this._activityServiceEachJobSubscription) {
      this._activityServiceEachJobSubscription.unsubscribe();
    }

    if (this._activityServiceSubscription) {
      this._activityServiceSubscription.unsubscribe();
    }

  }

  updateActivityData(activity) {

    this.activityData = [];

    activity.forEach(element => {

      // mostro solo quelle in progress
      if (element.status == "progress") {
        // mostro solo quelle più importanti
        if (this.filterActivity && this.filterActivity.includes(element.job_batch_name)) {
          element.isCollapsed = false;
          this.activityData.push(element);
        }
      }
    });

    this.changeDetector.detectChanges();
  }

  showActivitySidebar() {
    this.activity.showSidebar = true;
  }

  getAccountService() {
    return this.accountService;
  }

}
