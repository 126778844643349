import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class RemoveUsersFromOrganigrammaService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  items: any[];

  constructor(private backend: BackendService, private toast: ToastService, private utilityService: UtilityService) {
    this.reset();
  }

  reset() {
    this.items = [];
    this._subjectToUpdate.next(this.items);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  addUsers(newItems, organigramma_id) {

    newItems.forEach(item => {
      let itemFound = this.items.findIndex((element) => element.user?.id == item.user?.id);

      if (itemFound == -1) {
        this.items.push({
          ...item,
          organigrammi_id: [organigramma_id]
        });
      } else if (this.items[itemFound].organigrammi_id.findIndex((x) => x == organigramma_id) == -1) {
        this.items[itemFound].organigrammi_id.push(organigramma_id);
      }
    });

    this._subjectToUpdate.next(this.items);
  }

  deleteItemsAtIndex(indexArray) {

    indexArray.forEach(idx => {
      this.items[idx] = null;
    });

    this.items = this.items.filter((el) => { return el != null });

    this._subjectToUpdate.next(this.items);
  }

  execOperation(callback = (error) => { }) {

    let users = [];

    this.items.forEach(user => {

      // aggiungo solo quelli che hanno un google user altrimenti il job potrebbe andare in errore
      if (user.google_user) {
        users.push({
          id: user.user_id,
          organigrammi_id: user.organigrammi_id
        });
      }
    });

    this.backend.post("op/remove-users-from-organigramma", { users: users }).subscribe((result) => {
      this.reset();
      this.toast.success('Rimozione Utenti Organigrammi avviato');
      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }
}
