import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MateriaService } from 'src/app/services/general/materia.service';
import { PlessoService } from 'src/app/services/general/plesso.service';

@Component({
  selector: 'app-gestione-scolastica-user',
  templateUrl: './gestione-scolastica-user.component.html',
  styleUrls: ['./gestione-scolastica-user.component.scss']
})
export class GestioneScolasticaUserComponent implements OnInit {

  // Input Dati Preselezionati per Update
  @Input() idUser: any;
  @Input() multiSelectionClassi: boolean;
  @Input() materieSelectionClassi: boolean;
  @Input() multiInsert: boolean;
  @Input() idPlessiToExclude: any;
  @Input() idPlessoPreSelected: any;
  @Input() materieClassiPreSelected: any;
  @Input() classiPreSelected: any;
  @Input() border: any = true;
  @Input() disablePlessoSelection: any = false;

  // Output Json Dati selezionati 
  @Output() onSave = new EventEmitter();
  @Output() onChange = new EventEmitter();

  // Dati Gestione 
  plessoSelected: any;
  plessoClassiSelected: any;
  _plessoClassiSelected: any;

  plessoMaterieClassiSelected: any;
  _plessoMaterieClassiSelected: any;

  _updateMode: boolean;
  _error: any;
  _plessi: any;
  _classi: any;
  _materie: any;
  _loadingData = {
    plessi: false,
    materie: false,
    user: false,
    all: true
  };

  _loadingDataSectionClassi = false;

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  _paramMaterie: boolean;
  _labelText: string = "";

  // Serivce
  private _backendPlessiServiceDataSubscription: Subscription;
  private _backendMaterieServiceDataSubscription: Subscription;

  constructor(
    private plessoService: PlessoService,
    private route: ActivatedRoute,
    private materiaService: MateriaService
  ) { }

  ngOnInit(): void {
    this.unsubscibeAll();
    this.clearSelection();

    if (this.idUser)
      this._updateMode = true;

    this.initData();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  /**
   * Clear Selection Data
   */
  clearSelection() {
    this._loadingData.all = true;
    this.multiSelectionClassi = (this.multiSelectionClassi || false);
    this.materieSelectionClassi = (this.materieSelectionClassi || false);
    this.multiInsert = (this.multiInsert || false);

    this.plessoSelected = null;
    this._classi = null;
    this.plessoClassiSelected = null;
    this._plessoClassiSelected = null;

    this._error = null;

    this._paramMaterie = false;

    this._labelText = this.multiSelectionClassi ? "STR.Seleziona_Classi" : "STR.Seleziona_Classe";

    this._plessoMaterieClassiSelected = [];
    this.plessoMaterieClassiSelected = [];

    setTimeout(() => {
      Object.keys(this._loadingData).forEach(element => {
        this._loadingData[element] = false; // loadingDataType false
      });
    }, 350);
  }

  /**
   * Init Data
   */
  initData() {
    this._backendMaterieServiceDataSubscription = this.materiaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError('materie');
      else
        this.materieDataUpdated(result);
    });

    this.getBackendMaterieData();

    this._backendPlessiServiceDataSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError('plessi');
      else
        this.plessiDataUpdated(result);
    });

    this.getBackendPlessiData();
  }

  unsubscibeAll() {
    if (this._backendPlessiServiceDataSubscription)
      this._backendPlessiServiceDataSubscription.unsubscribe();

    if (this._backendMaterieServiceDataSubscription)
      this._backendMaterieServiceDataSubscription.unsubscribe();
  }

  /**
   * OnUpdate Data
   */
  plessiDataUpdated(result) {
    this._plessi = result;

    if (this.idPlessiToExclude && this.idPlessiToExclude.length > 0) {
      this._plessi = result.filter((el) => {
        if (this.idPlessoPreSelected)
          return this.idPlessoPreSelected == el.id || !this.idPlessiToExclude.includes(el.id)
        else
          return !this.idPlessiToExclude.includes(el.id);
      });
    }

    this._loadingData.plessi = false;

    if (this.idUser)
      this.setUserPlessoPreSelected();
    else if (this.idPlessoPreSelected) {
      this.setDataPlessoPreSelected(this.idPlessoPreSelected);
    }
  }

  materieDataUpdated(result) {
    this._materie = result;
    this._loadingData.materie = false;
  }

  /**
   * Set Preselect Data
   */
  setUserPlessoPreSelected() {
    // Recupera sorgente di provenneienza se è presente l'id del plesso da preselezionarlo per l'utente selezionato se è selezionato
    let userPlessoSelected = this.route.snapshot.paramMap.get('id_plesso');

    if (userPlessoSelected) {
      this.setDataPlessoPreSelected(userPlessoSelected);
    }
  }

  setDataPlessoPreSelected(id_plesso, materieClassi = null, classi = null) {
    let pre = this._plessi.find(x => x.id == id_plesso)
    this.plessoOnSelect(pre);
  }

  /**
   * Load Backend Data
   */
  getBackendPlessiData() {
    this._loadingData.plessi = true;
    this.plessoService.all();
  }

  getBackendMaterieData() {
    this._loadingData.materie = true;
    this.materiaService.all();
  }

  backendError(type) {
    this._error = 'backend';
    this._loadingData[type] = false;
  }

  /**
   * OnSelect Action
   */
  plessoOnSelect(plessoSelected) {

    // Selezionamento Plesso e caricamento varie classi
    if (!plessoSelected) {
      this.clearSelection();
      this.onChange.emit(this._emitData());
      return;
    }

    this._loadingDataSectionClassi = true;

    this.plessoSelected = plessoSelected;
    this._classi = this.plessoSelected.classi;
    this.plessoClassiSelected = null;

    if (this.classiPreSelected && this.classiPreSelected.length > 0) {

      this.plessoClassiSelected = this._classi.filter(element => {
        return this.classiPreSelected.find(x => x.id == element.id);
      });
      this.classiPlessoOnSelect(this.plessoClassiSelected);
    } else if (this.materieClassiPreSelected && this.materieClassiPreSelected.length > 0) {
      this._paramMaterie = true;
    }

    this.onChange.emit(this._emitData());

    setTimeout(() => {
      this._loadingDataSectionClassi = false;
    }, 100);
  }

  paramMateriaOnSelect() {
    this.onChange.emit(this._emitData());
  }

  classiMateriaOnSelect($event, idMateria) {
    let classi_id = null;

    if ($event)
      classi_id = $event.map(x => x.id);

    if (idMateria) {
      let index = this.plessoMaterieClassiSelected.findIndex(x => x.materia_id == idMateria);
      if (index !== (-1)) {
        if (!classi_id || classi_id.length == 0)
          this.plessoMaterieClassiSelected.splice(index, 1);
        else
          this.plessoMaterieClassiSelected[index].classi_id = classi_id;
      } else {
        this.plessoMaterieClassiSelected.push({
          materia_id: idMateria,
          classi_id: classi_id,
        });
      }
    }

    this.onChange.emit(this._emitData());
  }

  classiPlessoOnSelect($event) {
    if (Array.isArray($event) && !this.multiSelectionClassi)
      this._plessoClassiSelected = $event[0];
    else
      this._plessoClassiSelected = $event;

    this.plessoClassiSelected = this._plessoClassiSelected;

    this.plessoMaterieClassiSelected = [];
    this._plessoMaterieClassiSelected = [];

    this._materie?.slice().forEach(element => {
      let classiSelected = [];
      if (this.materieClassiPreSelected && this.materieClassiPreSelected.length > 0) {
        classiSelected = this.plessoClassiSelected.filter(x => {
          return this.materieClassiPreSelected.find(f => f.materia.id == element.id)?.classi.map(m => m.id).includes(x.id);
        });
      }

      this._plessoMaterieClassiSelected.push({
        materia_id: element.id,
        materia_name: element.name,
        classi: this.plessoClassiSelected,
        classiSelected: classiSelected
      });
    });

    if (this.materieClassiPreSelected && this.materieClassiPreSelected.length > 0) {
      this.materieClassiPreSelected.forEach(element => {
        this.plessoMaterieClassiSelected.push({
          materia_id: element.materia.id,
          classi_id: element.classi?.map(x => x.id),
        });
      });
    }

    this.onChange.emit(this._emitData());
  }

  /**
  * OnClick Action
  */
  addOnClick() {
    this.onSave.emit(this._emitData());

    // ReInit per aggiungere un altra struttura
    this.ngOnInit();
  }

  /**
  * Emit Data Action
  */
  _emitData() {
    let objRes = {
      plesso: this.plessoSelected,
      classi: [],
      materieClassi: []
    }

    if (Array.isArray(this.plessoClassiSelected))
      objRes.classi = objRes.classi.concat(this.plessoClassiSelected)
    else if (this.plessoClassiSelected)
      objRes.classi.push(this.plessoClassiSelected)

    if (this._paramMaterie) {
      this.plessoMaterieClassiSelected.forEach(element => {
        objRes.materieClassi.push({
          materia: this._materie.find(x => x.id == element.materia_id),
          classi: this._classi.filter(x => element.classi_id.includes(x.id))
        })
      });
    }

    return objRes;
  }
}