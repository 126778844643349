import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { UserService } from 'src/app/services/general/user.service';
import { ReactivateSuspendedUsersService } from 'src/app/services/operations/reactivate-suspended-users.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-reactivate-suspended-users',
  templateUrl: './reactivate-suspended-users.component.html',
  styleUrls: ['./reactivate-suspended-users.component.scss']
})
export class ReactivateSuspendedUsersComponent implements OnInit {
  regEx = ConfigService.regExReactive;
  error: any;
  _loadingData = {
    plessi: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  data: any = [];
  users: any;
  model: any;

  operationInProgress: boolean = false;

  private _reactivateSuspendedUsersServiceSubscription: Subscription;
  private _userServiceSubscription: Subscription;


  constructor(
    private reactivateSuspendedUsersService: ReactivateSuspendedUsersService,
    private userService: UserService,
    private syncdataService: SyncdataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initForm();
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.users = this.reactivateSuspendedUsersService.items;
    this.data = this.userService.getTable(this.users, "orgunit");
  }

  deleteEvent(rows) {
    this.reactivateSuspendedUsersService.deleteItemsAtIndex(rows);
  }

  onSubmit(myForm: NgForm) {

    if (myForm.valid) {

      this.operationInProgress = true;

      this.reactivateSuspendedUsersService.execOperation((error) => {
        if (!error) {
          this.initForm();
        }
      });
    }
  }

  unsubscibeAll() {


    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._reactivateSuspendedUsersServiceSubscription)
      this._reactivateSuspendedUsersServiceSubscription.unsubscribe();



    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
  }

  initForm() {
    this.model = {
      plesso: null,
      classe: null
    }
  }

  init() {

    this._reactivateSuspendedUsersServiceSubscription = this.reactivateSuspendedUsersService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.dataUpdated();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }
}

