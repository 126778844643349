<div class="container py-5 py-sm-7">
  <a class="d-flex justify-content-center mb-5">
    <img class="z-index-2" src="./assets/theme/svg/logos/geniusuite_partner.svg" alt="Geniusuite" style="width: 30rem;">
  </a>

  <div class="row justify-content-center">
    <div class="col-md-7 col-lg-5">
      <!-- Card -->
      <div class="card card-lg mb-5">
        <div class="card-body text-center">
          <img class="img-fluid mb-5" src="./assets/theme/svg/illustrations/yelling-reverse.svg" style="max-width: 15rem;">
          <h1>{{'STR.manutenzione_title' | translate}}</h1>
          <p [innerHtml]="'STR.manutenzione_msg' | translate"></p>
          <p *ngIf="manutenzione_fine">Fine manutenzione<br><b>{{ manutenzione_fine | date:'long'}}</b></p>
          <button class="btn btn-outline-primary" (click)="ricarica()">Ricarica Geniusuite</button>
        </div>
      </div>
      <a class="btn btn-primary btn-block" href="https://geniusuite.com/assistenza-geniusuite/?utm_source=geniusuite&utm_medium=link&utm_campaign=assistenza_geniusuite" target="_blank"><i class="fa-solid fa-headphones mr-2"></i>Se vuoi, puoi contattare l'assistenza</a>
      <!-- End Card -->
    </div>
  </div>
</div>
