import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-tagsinput',
  templateUrl: './tagsinput.component.html',
  styleUrls: ['./tagsinput.component.scss']
})
export class TagsinputComponent implements OnInit {
  // Id del Tag
  @Input() id;
  // Valore di Default del Tag
  @Input() defaultValue;
  // Variabili da aggiungere
  @Input() buttonInput: any = [];
  // Variabili da aggiungere con la gestione del select
  _buttonSelected = [];
  // Evento mandato al Parent quando viene modificato il testo
  @Output() onInputText = new EventEmitter();
  // Ignore Trim
  @Input() ignoreTrim = false;
  // Stato del form valid o invalid
  stateForm: any = null;

  // Eventi da gestire mandati dal Parent
  private eventsSubscription: Subscription;
  @Input() events: Observable<any>;

  private validateEventSubscription: Subscription;
  @Input() validateEvent: Observable<any>;

  private setValueEventSubscription: Subscription;
  @Input() setValueEvent: Observable<any>;

  constructor() { }

  ngOnInit(): void {
    // Trasformo le variabili in oggetti per gestire se esiste già selezionato un button
    this.buttonInput.forEach(element => {
      this._buttonSelected.push({
        name: element,
        selected: false
      })
    });

    // Evento scatenato dal Parent per aggiungere gli elementi da button
    this.eventsSubscription = this.events.subscribe(
      (event) => {
        if (event.tagId == this.id)
          this.addElement(event.name);
      }
    );

    // Evento scatenato dal Parent per assegnare la validità del form
    this.validateEventSubscription = this.validateEvent.subscribe(
      (event) => {
        if (event.tagId == this.id)
          this.stateForm = event.value ? true : false;
      }
    );

    // Evento scatenato dal Parent per assegnare il valore del form
    this.setValueEventSubscription = this.setValueEvent.subscribe(
      (event) => {
        if (event.tagId == this.id) {
          this.defaultValue = event.value;
          document.getElementById(this.id).innerText = this.defaultValue;
          this.replaceAllOccurences();
        }
      }
    );
  }

  /**
    * Evento che una volta init il DOM assegna il valore di Default da visualizzare
    */
  ngAfterViewInit() {
    if (this.defaultValue) {
      document.getElementById(this.id).innerText = this.defaultValue;
      this.replaceAllOccurences();
    }
  }

  ngOnDestroy() {
    if (this.eventsSubscription)
      this.eventsSubscription.unsubscribe();

    if (this.validateEventSubscription)
      this.validateEventSubscription.unsubscribe();

    if (this.setValueEventSubscription)
      this.setValueEventSubscription.unsubscribe();
  }

  /**
    * Prende il caret(cursore) in uno specifico punto nell'elemento del DOM
    * @param elemId Id dell'elemento da gestire
    */
  getCaretIndex(elemId) {
    let element = document.getElementById(elemId);

    let position = 0;
    const isSupported = typeof window.getSelection !== "undefined";
    if (isSupported) {
      const selection = window.getSelection();
      if (selection.rangeCount !== 0) {
        const range = window.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        position = preCaretRange.toString().length;
      }
    }
    return position;
  }

  /**
    * Sposta il caret(cursore) in uno specifico punto nell'elemento del DOM
    * @param el Elemento da gestire
    * @param pos Index della posizione del cursore
    */
  setCaretIndex(el, pos) {
    for (var node of el.childNodes) {
      if (node.nodeType == 3) {
        if (node.length >= pos) {
          var range = document.createRange(),
            sel = window.getSelection();
          range.setStart(node, pos);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
          return -1;
        } else {
          pos -= node.length;
        }
      } else {
        pos = this.setCaretIndex(node, pos);
        if (pos == -1) {
          return -1;
        }
      }
    }
    return pos;
  }

  /**
    * Evento al click che assegna il tag nella posizione del cursore
    * @param name Variabile da aggiungere. Esempio $name_plesso$, $nome$, $cognome$,...
    */
  addElement(name) {
    let index = this._buttonSelected.findIndex(x => x.name == name);
    if (index != -1 && !this._buttonSelected[index].selected) {
      this._buttonSelected[index].selected = true;
      // document.getElementById(this.id).innerText.replace("&nbsp;", " ");
      let postition = this.getCaretIndex(this.id);
      document.getElementById(this.id).innerHTML = !this.ignoreTrim ? document.getElementById(this.id).innerText.trim() : document.getElementById(this.id).innerText.trimStart();
      let html = document.getElementById(this.id).innerHTML;
      let valueRep = html.slice(0, postition) + name + html.slice(postition);
      document.getElementById(this.id).innerHTML = valueRep;
      this.setCaretIndex(document.getElementById(this.id), postition + (name.length));
      this.replaceAllOccurences();
    }
  }

  /**
    * Evento onKeyUp quando viene cambiato il testo e fatto il replace di tutte le riccorrenze
    */
  onKey($event) {
    this.replaceAllOccurences();
  }

  /**
   * Metodo che fa il replace di tutte le riccorennze nel tag
   */
  replaceAllOccurences() {
    let html: any = !this.ignoreTrim ? document.getElementById(this.id).innerText.trim() : document.getElementById(this.id).innerText.trimStart();

    this._buttonSelected.forEach(element => {
      if (html.includes(element.name)) {
        element.selected = true;
      } else {
        element.selected = false;
      }

      html = html.replaceAll(element.name, this.returnTag(element.name));
    });

    let postition = this.getCaretIndex(this.id);
    document.getElementById(this.id).innerHTML = html + " ";
    this.setCaretIndex(document.getElementById(this.id), postition);

    this.onInputText.emit({
      tagId: this.id,
      value: !this.ignoreTrim ? document.getElementById(this.id).innerText.trim() : document.getElementById(this.id).innerText.trimStart()
    });
  }

  /**
    * Metodo che ritorna il tag html da aggiungere
    * @param name Variabile da aggiungere. Esempio $name_plesso$, $nome$, $cognome$,...
    */
  returnTag(name) {
    return '<span contenteditable="false" class="badge badge-primary">' + name + '</span>';
  }

}
