<app-card-loading *ngIf="loadingGoogle || loadingBackend || loadingUser"></app-card-loading>

<app-card-loading-error *ngIf="!(loadingBackend || loadingGoogle || loadingUser) && error" [error]="error"
    (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card card-hover-shadow" *ngIf="!(loadingBackend || loadingGoogle || loadingUser)">
    <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>

    <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" [ngClass]="{'card-is-loading': formLoading}">
        <div class="card-body">

            <div class="row">
                <!-- Left -->
                <div class="col">

                    <!-- Titlte -->
                    <div class="row form-group">
                        <div class="col-12">
                            <h3>Utente</h3>
                            <small>Informazioni dell'Utente che verrà eliminato</small>
                        </div>
                    </div>

                    <!-- Info Card -->
                    <div class="row form-group" *ngIf="user">
                        <div class="col-12">
                            <ul class="list-unstyled list-unstyled-py-3 text-dark mb-3">
                                <li class="py-0">
                                    <small class="card-subtitle">Nome</small>
                                </li>

                                <li *ngIf="user.user.givenName">
                                    {{ user.google_user?.name?.givenName || user.user.givenName }}
                                </li>

                                <li class="py-0">
                                    <small class="card-subtitle">Cognome</small>
                                </li>

                                <li *ngIf="user.user.familyName">
                                    {{ user.google_user?.name?.familyName || user.user?.familyName }}
                                </li>

                                <li class="py-0">
                                    <small class="card-subtitle">Email</small>
                                </li>

                                <li *ngIf="user.google_user">
                                    <span *ngIf="user.google_user.primaryEmail">{{user.google_user.primaryEmail}}</span>
                                </li>

                                <li *ngIf="!user.google_user">
                                    Utente non trovato su Google Workspace
                                </li>

                                <li class="py-0">
                                    <small class="card-subtitle">Organizzazione</small>
                                </li>

                                <li *ngIf="user.google_user">
                                    <span *ngIf="user.google_user.orgUnitPath">{{user.google_user.orgUnitPath}}</span>
                                </li>

                                <li *ngIf="!user.google_user">
                                    Utente non trovato su Google Workspace
                                </li>

                                <li class="py-0">
                                    <small class="card-subtitle">Creato il</small>
                                </li>

                                <li>
                                    {{user.user.created_at | date:"medium" }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- Right -->
                <div class="col-5 p-3 m-3 bg-light rounded-lg">
                    <!-- Titlte -->
                    <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Sincronizzazione con Google Workspace</h4>


                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-soft-danger" role="alert">
                                L'Utente verrà eliminato <b>definitivamente</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="card-footer">
            <div class="row justify-content-between align-items-center">
                <div class="col-auto py-1">
                    <button [routerLink]="['/user/'+ id]" type="button" class="btn btn-white">
                        <i class="far fa-chevron-left mr-1"></i> Annulla
                    </button>
                </div>
                <div class="col-6 py-1">
                    <div class="row justify-content-end">
                        <div class="col-auto">
                            <input autocomplete="off" type="text" id="confirmation_delete" name="confirmation_delete"
                                #confirmation_delete="ngModel" [ngModel]="''" class="form-control"
                                [pattern]="regEx" required>
                            <small class="d-block text-muted">Per eliminare, digita
                                <span class="confirmation-phrase">Elimina</span></small>
                        </div>
                        <div class="col-auto">
                            <button type="submit" class="btn"
                                [disabled]="!myForm.valid"
                                [ngClass]="(myForm.dirty || myForm.touched)? 'btn-danger' : 'btn-outline-danger' ">
                                Elimina <i class="far fa-chevron-right ml-1"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
