import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-activity-page',
  templateUrl: './activity-page.component.html',
  styleUrls: ['./activity-page.component.scss']
})
export class ActivityPageComponent implements OnInit {

  name: string;
  description: string;
  batch_id: any;
  activity: any;
  failed_jobs: any;
  failed_jobs_reasons: any;
  allJobNotCollapsed: boolean;

  private _activeRouteSubscription: Subscription;
  private _activityUpdateForEachJobSubscription: Subscription;

  constructor(
    private activityService: ActivityService,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.batch_id = routeParams.id_activity;
      this.updateData();
    });

    this._activityUpdateForEachJobSubscription = this.activityService.getSubjectToUpdateObservableForEachJob().subscribe((result) => {
      if (result)
        this.updateData();
    });
  }

  updateData() {

    this.activity = null;
    this.failed_jobs = null;
    this.failed_jobs_reasons = null;

    if (this.batch_id) {
      this.activity = this.activityService.findBatch(this.batch_id);
      this.updateFailedJobs();
    }
  }

  updateFailedJobs() {
    this.activityService.findFailedJobs(this.activity, (result) => {

      this.failed_jobs = result;
      this.failed_jobs_reasons = [];

      // analizzo le cause principali di errore dei job e li raggruppo
      this.failed_jobs.forEach(job => {

        if (job.batch_activities_errors) {

          job.batch_activities_errors.forEach(error => {

            if (error.message && error.message.length > 0) {

              let keyfound = -1;

              this.failed_jobs_reasons.forEach((reason, key) => {
                if (reason.text == error.message) {
                  keyfound = key;
                }
              });

              if (keyfound < 0) {
                this.failed_jobs_reasons.push({
                  "text": error.message,
                  "counter": 1
                });
              }
              else {
                this.failed_jobs_reasons[keyfound].counter += 1;
              }
            }
          });
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.updateData();
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();

    if (this._activityUpdateForEachJobSubscription)
      this._activityUpdateForEachJobSubscription.unsubscribe();
  }

  toggleAllJopCollapsed() {
    this.allJobNotCollapsed = !this.allJobNotCollapsed;

    this.failed_jobs.forEach(job => {
      job.notCollapsed = this.allJobNotCollapsed;
    });
  }

}
