<ng-container *ngIf="id">
  <ng-container *ngIf="!showForm; else elseBlock">
    <div class="row">
      <div class="col-md-12 col-sm mb-1">
        <div class="mb-3">
          <app-classroom-profile-info-card [id]="id"></app-classroom-profile-info-card>
        </div>
      </div>

      <div class="col-md-12 col-sm">
        <app-classroom-profile-datatable-card [id]="id"></app-classroom-profile-datatable-card>
      </div>
    </div>
  </ng-container>

  <ng-template #elseBlock>
    <app-classroom-form [id]="id"></app-classroom-form>
  </ng-template>
</ng-container>

<ng-container *ngIf="!id">

  <app-card-loading *ngIf="loading"></app-card-loading>

  <app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
  </app-card-loading-error>


  <div class="card mb-3" *ngIf="!(loading) && !error">
    <!-- Header -->
    <div class="card-header">
      <h5 class="card-header-title"><i class="fas fa-users-class blue mr-2"></i>Classroom</h5>
      <div class="d-flex justify-content-end align-items-center">
        <button type="button" class="btn btn-outline-primary btn-sm mr-2" routerLink="/operation/create-classroom">
          <i class="fas fa-plus mr-2"></i>{{ 'BTN.crea_classroom' | translate }}
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="aggiornaDati()">
          <i class="fas fa-sync-alt mr-2"></i>{{ 'BTN.aggiorna' | translate }}
        </button>
      </div>
    </div>
    <!-- End Header -->

    <!-- Footer -->
    <div class="card-footer text-center">
      <!-- Stats -->
      <div class="row">
        <div class="col">
          <span class="h4" *ngIf="classroomsFilteredData.active">{{ classroomsFilteredData.active.length }}</span>
          <span class="h4" *ngIf="!classroomsFilteredData.active">0</span>
          <span class="d-block font-size-sm">{{ 'STR.course_state_active' | translate }}</span>
        </div>

        <div class="col column-divider">
          <span class="h4" *ngIf="classroomsFilteredData.archived">{{ classroomsFilteredData.archived.length }}</span>
          <span class="h4" *ngIf="!classroomsFilteredData.archived">0</span>
          <span class="d-block font-size-sm">{{ 'STR.course_state_archived' | translate }}</span>
        </div>

        <div class="col column-divider">
          <span class="h4" *ngIf="classroomsFilteredData.suspended">{{ classroomsFilteredData.suspended.length }}</span>
          <span class="h4" *ngIf="!classroomsFilteredData.suspended">0</span>
          <span class="d-block font-size-sm">{{ 'STR.course_state_suspended' | translate }}</span>
        </div>

        <div class="col column-divider">
          <span class="h4" *ngIf="classroomsFilteredData.provisioned">{{ classroomsFilteredData.provisioned.length }}</span>
          <span class="h4" *ngIf="!classroomsFilteredData.provisioned">0</span>
          <span class="d-block font-size-sm">{{ 'STR.course_state_provisioned' | translate }}</span>
        </div>

        <div class="col column-divider">
          <span class="h4" *ngIf="classroomsFilteredData.declined">{{ classroomsFilteredData.declined.length }}</span>
          <span class="h4" *ngIf="!classroomsFilteredData.declined">0</span>
          <span class="d-block font-size-sm">{{ 'STR.course_state_declined' | translate }}</span>
        </div>
      </div>
      <!-- End Stats -->
    </div>
    <!-- End Footer -->
  </div>

  <div *ngIf="!loading && !error">
    <div class="card">
      <div class="card-header p-1">
        <div class="row justify-content-between align-items-center flex-grow-1">
          <div class="col">
            <tabset [justified]="true" type="pills">
              <tab #tab1="tab" (selectTab)="selectTab(1)">
                <ng-template tabHeading>
                  <i class="fas fa-user-graduate nav-icon"></i>{{ 'STR.course_state_active' | translate }}
                </ng-template>
              </tab>
              <tab #tab2="tab" (selectTab)="selectTab(2)">
                <ng-template tabHeading>
                  <i class="fas fa-chalkboard-teacher nav-icon"></i>{{ 'STR.course_state_archived' |
                  translate }}
                </ng-template>
              </tab>
              <tab #tab3="tab" (selectTab)="selectTab(3)">
                <ng-template tabHeading>
                  <i class="fas fa-backpack nav-icon"></i> {{ 'STR.course_state_suspended' | translate }}
                </ng-template>
              </tab>
              <tab #tab4="tab" (selectTab)="selectTab(4)">
                <ng-template tabHeading>
                  <i class="fas fa-backpack nav-icon"></i> {{ 'STR.course_state_provisioned' | translate }}
                </ng-template>
              </tab>
              <tab #tab5="tab" (selectTab)="selectTab(5)">
                <ng-template tabHeading>
                  <i class="fas fa-backpack nav-icon"></i> {{ 'STR.course_state_declined' | translate }}
                </ng-template>
              </tab>
            </tabset>
          </div>
        </div>
      </div>

      <app-card-datatable-ng #activeClassroomCardDatatable *ngIf="tab1.active"
        title="{{ 'STR.course_active' | translate }}" subTitle="{{ 'STR.course_active_subtitle' | translate }}" [data]="classroomsFilteredData.active"
        [data]="classroomsFilteredData.active" [rowData]="classroomsFiltered.active" [idRootData]="id"
        [menuType]="'classroom|classroom-course-active'" [isLoadingData]="isLoading.active"
        [showAvatarOnFirstColumn]="false" [error]="dataError.active" (rowClickEvent)="rowClickCourse($event, 'active')"
        [showAddButton]="false" [showExportButton]="true">
      </app-card-datatable-ng>

      <app-card-datatable-ng #archivedClassroomCardDatatable *ngIf="tab2.active"
        title="{{ 'STR.course_archived' | translate }}" subTitle="{{ 'STR.course_archived_subtitle' | translate }}" [data]="classroomsFilteredData.archived"
        [rowData]="classroomsFiltered.archived" [idRootData]="id" [menuType]="'classroom|classroom-course-archived'"
        [isLoadingData]="isLoading.archived" [showAvatarOnFirstColumn]="false" [error]="dataError.archived"
        (rowClickEvent)="rowClickCourse($event, 'archived')" [showAddButton]="false" [showExportButton]="true">
      </app-card-datatable-ng>

      <app-card-datatable-ng #suspendedClassroomCardDatatable *ngIf="tab3.active"
        title="{{ 'STR.course_suspended' | translate }}" subTitle="{{ 'STR.course_suspended_subtitle' | translate }}" [data]="classroomsFilteredData.suspended"
        [rowData]="classroomsFiltered.suspended" [idRootData]="id" [menuType]="'classroom|classroom-course-suspended'"
        [isLoadingData]="isLoading.suspended" [showAvatarOnFirstColumn]="false" [error]="dataError.suspended"
        (rowClickEvent)="rowClickCourse($event, 'suspended')" [showAddButton]="false" [showExportButton]="true">
      </app-card-datatable-ng>

      <app-card-datatable-ng #provisionedClassroomCardDatatable *ngIf="tab4.active"
        title="{{ 'STR.course_provisioned' | translate }}" subTitle="{{ 'STR.course_provisioned_subtitle' | translate }}" [data]="classroomsFilteredData.provisioned"
        [rowData]="classroomsFiltered.provisioned" [idRootData]="id" [menuType]="'classroom|classroom-course-provisioned'"
        [isLoadingData]="isLoading.provisioned" [showAvatarOnFirstColumn]="false" [error]="dataError.provisioned"
        (rowClickEvent)="rowClickCourse($event, 'provisioned')" [showAddButton]="false" [showExportButton]="true">
      </app-card-datatable-ng>

      <app-card-datatable-ng #declinedClassroomCardDatatable *ngIf="tab5.active"
        title="{{ 'STR.course_declined' | translate }}" subTitle="{{ 'STR.course_declined_subtitle' | translate }}" [data]="classroomsFilteredData.declined"
        [rowData]="classroomsFiltered.declined" [idRootData]="id" [menuType]="'classroom|classroom-course-declined'"
        [isLoadingData]="isLoading.declined" [showAvatarOnFirstColumn]="false" [error]="dataError.declined"
        (rowClickEvent)="rowClickCourse($event, 'declined')" [showAddButton]="false" [showExportButton]="true">
      </app-card-datatable-ng>
    </div>
  </div>
</ng-container>
