<div class="row mb-5">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body m-2">
        <app-setup-wizard-steps [currentStep]="4"></app-setup-wizard-steps>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <!-- Card -->
    <div class="card card-hover-shadow">
      <div class="card-body">

        <div class="row" *ngIf="fileName && uploadedFileCompleted">
          <div class="col">
            <ul class="list-group">
              <!-- List Item -->
              <li class="list-group-item">
                <div class="row align-items-center gx-2">
                  <div class="col-auto">
                    <img class="avatar avatar-xs avatar-4by3" src="./assets/theme/svg/brands/google-sheets.svg">
                  </div>

                  <div class="col">
                    <h5 class="mb-0">
                      <a class="text-dark" href="#">{{ fileName }}</a>
                    </h5>
                    <ul class="list-inline list-separator small">
                      <li class="list-inline-item">Ultima modifica: {{ fileLastModified | date }}</li>
                      <li class="list-inline-item">Dimensione file: {{ fileSize }}</li>
                    </ul>
                  </div>

                  <div class="col-auto">
                    <button class="btn btn-sm btn-outline-danger" (click)="removedFile()">
                      <i class="far fa-trash mr-2"></i>{{ 'BTN.Cancella' | translate }}
                    </button>
                  </div>
                </div>
                <!-- End Row -->
              </li>
              <!-- End List Item -->
            </ul>
          </div>
        </div>

        <div class="row my-5">
          <div class="col">
            <h2>{{'SETUP.users.titolo' | translate}}</h2>
          </div>
        </div>

        <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" validate>

          <!-- Se la tipologia è già impostata allora la mostro in sola visualizzazione -->
          <!-- Altrimenti se la tipologia non è impostata mostro la select per scegliere la tipologia -->
          <div class="form-group row mt-5">
            <div class="col" *ngIf="userTypeSelectedValue">
              <label class="input-label">{{'SETUP.users.label_tipologia_selected' | translate}}</label>
              <h4>{{userTypeSelectedValue}}</h4>
            </div>
            <div class="col" *ngIf="!userTypeSelectedValue">
              <label class="input-label">{{'SETUP.users.label_tipologia' | translate}}</label>
              <select #userType="ngModel" id="userType" name="userType" class="form-control custom-select"
                [(ngModel)]="userTypeSelected" required [ngClass]="userTypeSelected ? 'is-valid': 'is-invalid'">
                <option *ngFor="let val of userTypeSelect; let i = index;" [value]="val.key">{{val.value}}</option>
              </select>
              <div class="valid-feedback" *ngIf="userTypeSelected">Valido</div>
              <div class="text-muted font-size-1 mt-1">{{'SETUP.users.msg_tipologia' | translate}}</div>
            </div>
            <div class="col-7">
              <div class="alert alert-soft-info" role="alert">
                <b class="text-cap">Info</b>
                <span *ngIf="!userTypeSelectedValue" [innerHTML]="'SETUP.users.msg_info_tipologia' | translate"></span>
                <span *ngIf="userTypeSelectedValue"
                  [innerHTML]="'SETUP.users.msg_info_tipologia_selected' | translate"></span>
              </div>
            </div>
          </div>

          <div class="form-group row mt-5">
            <div class="col">
              <label class="input-label" for="selectNameCol">{{'SETUP.users.label_nome' | translate}}</label>
              <select id="selectNameCol" name="selectNameCol" class="form-control custom-select"
                [(ngModel)]="givenNameColIndex" #nameCol="ngModel" [ngClass]="nameCol.valid ? 'is-valid': 'is-invalid'"
                required>
                <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
              </select>
              <div class="valid-feedback" *ngIf="nameCol.valid && (nameCol.dirty || nameCol.touched)">Valido</div>
              <div class="text-muted font-size-1 mt-1">{{'SETUP.users.msg_nome' | translate}}</div>
            </div>
            <div class="col-7">
              <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex" [colIndex]="givenNameColIndex">
              </app-preview-column-data>
            </div>
          </div>

          <div class="form-group row mt-5">
            <div class="col">
              <label class="input-label" for="selectSurnameCol">{{'SETUP.users.label_cognome' | translate}}</label>
              <select id="selectSurnameCol" name="selectSurnameCol" class="form-control custom-select"
                [(ngModel)]="familyNameColIndex" #surnameCol="ngModel"
                [ngClass]="surnameCol.valid ? 'is-valid': 'is-invalid'" required>
                <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
              </select>
              <div class="valid-feedback" *ngIf="surnameCol.valid && (surnameCol.dirty || surnameCol.touched)">Valido
              </div>
              <div class="text-muted font-size-1 mt-1">{{'SETUP.users.msg_cognome' | translate}}</div>
            </div>
            <div class="col-7">
              <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex" [colIndex]="familyNameColIndex">
              </app-preview-column-data>
            </div>
          </div>

          <div class="form-group row mt-5">
            <div class="col">
              <label class="input-label" for="selectEmailCol">{{'SETUP.users.label_email' | translate}} <span
                  class="input-label-secondary">(Account Google Workspace)</span> <button
                  *ngIf="emailColIndex !== null && emailColIndex >= 0"
                  class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1" (click)="emailColIndex = null;"><i
                    class="fas fa-backspace"></i></button></label>
              <select id="selectEmailCol" name="selectEmailCol" class="form-control custom-select"
                [(ngModel)]="emailColIndex" #emailCol="ngModel"
                [ngClass]="emailCol.value ? (emailCol.valid ? 'is-valid': 'is-invalid') : 'untouched'">
                <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
              </select>
              <div class="valid-feedback" *ngIf="emailCol.valid && (emailCol.dirty || emailCol.touched)">Valido</div>
              <div class="text-muted font-size-1 mt-1">{{'SETUP.users.msg_email' | translate}}</div>
            </div>
            <div class="col-7">
              <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex" [colIndex]="emailColIndex">
              </app-preview-column-data>
            </div>
          </div>

          <div class="form-group row mt-5">
            <div class="col">
              <label class="input-label" for="selectEmailRecoveryCol">{{'SETUP.users.label_email_recovery' | translate}}
                <span class="input-label-secondary">(Email recupero password)</span> <button
                  *ngIf="emailRecoveryColIndex !== null && emailRecoveryColIndex >= 0"
                  class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1" (click)="emailRecoveryColIndex = null"><i
                    class="fas fa-backspace"></i></button></label>
              <select id="selectEmailRecoveryCol" name="selectEmailRecoveryCol" class="form-control custom-select"
                [(ngModel)]="emailRecoveryColIndex" #emailRecoveryCol="ngModel"
                [ngClass]="emailRecoveryCol.value ? (emailRecoveryCol.valid ? 'is-valid': 'is-invalid') : 'untouched'">
                <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
              </select>
              <div class="valid-feedback"
                *ngIf="emailRecoveryCol.valid && (emailRecoveryCol.dirty || emailRecoveryCol.touched)">Valido</div>
              <div class="text-muted font-size-1 mt-1">{{'SETUP.users.msg_email_recovery' | translate}}</div>
              <label *ngIf="emailRecoveryColIndex" class="mt-3 toggle-switch d-flex" for="paramOvewriteRecoveryEmailSwitch">
                <input id="paramOvewriteRecoveryEmailSwitch" [(ngModel)]="paramOvewriteRecoveryEmail" name="paramOvewriteRecoveryEmailSwitch"
                  type="checkbox" class="toggle-switch-input" (change)="onSelectparamOvewriteRecoveryEmail()">
                <span class="toggle-switch-label">
                  <span class="toggle-switch-indicator"></span>
                </span>
                <span class="toggle-switch-content">
                  <span class="d-block">{{'STR.overwrite_value' | translate}}</span>
                </span>
              </label>
              <div *ngIf="emailRecoveryColIndex" class="row mt-2">
                <div class="col">
                  <small [innerHtml]="'SETUP.users.overwrite_value_recovery_email_info' | translate">
                  </small>
                </div>
              </div>
            </div>
            <div class="col-7">
              <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex"
                [colIndex]="emailRecoveryColIndex"></app-preview-column-data>
            </div>
          </div>

          <div class="form-group row mt-5">
            <div class="col">
              <label class="input-label" for="selectCFCol">{{'SETUP.users.label_cf' | translate}} <button
                  *ngIf="cfColIndex !== null && cfColIndex >= 0"
                  class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1" (click)="cfColIndex = null"><i
                    class="fas fa-backspace"></i></button></label>
              <select id="selectCFCol" name="selectCFCol" class="form-control custom-select" [(ngModel)]="cfColIndex"
                #cfCol="ngModel" [ngClass]="cfCol.value ? (cfCol.valid ? 'is-valid': 'is-invalid') : 'untouched'">
                <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
              </select>
              <div class="valid-feedback" *ngIf="cfCol.valid && (cfCol.dirty || cfCol.touched)">Valido</div>
              <div class="text-muted font-size-1 mt-1">{{'SETUP.users.msg_cf' | translate}}</div>
              <label *ngIf="cfColIndex" class="mt-3 toggle-switch d-flex" for="paramOvewriteCfSwitch">
                <input id="paramOvewriteCfSwitch" [(ngModel)]="paramOvewriteCf" name="paramOvewriteCfSwitch"
                  type="checkbox" class="toggle-switch-input" (change)="onSelectparamOvewriteCf()">
                <span class="toggle-switch-label">
                  <span class="toggle-switch-indicator"></span>
                </span>
                <span class="toggle-switch-content">
                  <span class="d-block">{{'STR.overwrite_value' | translate}}</span>
                </span>
              </label>
              <div *ngIf="cfColIndex" class="row mt-2">
                <div class="col">
                  <small [innerHtml]="'SETUP.users.overwrite_value_cf_info' | translate">
                  </small>
                </div>
              </div>
            </div>
            <div class="col-7">
              <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex" [colIndex]="cfColIndex">
              </app-preview-column-data>
            </div>
          </div>

          <div class="form-group row mt-5">
            <div class="col">
              <label class="input-label" for="selectPasswordCol">{{'SETUP.users.label_password' | translate}} <button
                  *ngIf="passwordColIndex !== null && passwordColIndex >= 0"
                  class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1" (click)="passwordColIndex = null"><i
                    class="fas fa-backspace"></i></button></label>
              <select id="selectPasswordCol" name="selectPasswordCol" class="form-control custom-select"
                [(ngModel)]="passwordColIndex" #passwordCol="ngModel"
                [ngClass]="passwordCol.value ? (passwordCol.valid ? 'is-valid': 'is-invalid') : 'untouched'">
                <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
              </select>
              <div class="valid-feedback" *ngIf="passwordCol.valid && (passwordCol.dirty || passwordCol.touched)">Valido
              </div>
              <div class="text-muted font-size-1 mt-1"></div>
            </div>
            <div class="col-7">
              <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex" [colIndex]="passwordColIndex">
              </app-preview-column-data>
            </div>
          </div>

        </form>

        <div class="row" *ngIf="!emailColIndex && !cfColIndex">
          <div class="col">
            <div class="alert alert-danger" role="alert"
              [innerHTML]="'SETUP.users.error_no_col_unique_info' | translate">
            </div>
          </div>
        </div>

      </div>
      <div class="card-footer">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto py-1">
            <button (click)="back()" type="button" class="btn btn-white">
              <i class="far fa-chevron-left mr-1"></i> Indietro
            </button>
          </div>
          <div class="col-auto py-1">
            <button [disabled]="!myForm.valid" (click)="next()" type="button" class="btn btn-primary">
              Avanti <i class="far fa-chevron-right ml-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Card -->
  </div>
</div>
