import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivityService } from 'src/app/services/activity.service';
import { RequestChangePasswordService } from 'src/app/services/general/request-change-password.service';

@Component({
  selector: 'app-reset-password-widget',
  templateUrl: './reset-password-widget.component.html',
  styleUrls: ['./reset-password-widget.component.scss']
})
export class ResetPasswordWidgetComponent implements OnInit {

  private _serviceSubscription: Subscription;

  users: any;
  richieste: any;
  last_update: Date;
  link: any;

  private _activitySubscription: Subscription;

  constructor(
    private activityService: ActivityService,
    private requestChangePasswordService: RequestChangePasswordService
  ) { }

  ngOnInit() {

    this._serviceSubscription = this.requestChangePasswordService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.updateUser(result);
    });

    this._activitySubscription = this.activityService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result && this.activityService.isActivityExist("operation.users.update-password"))
        if (!this.activityService.isActivityInProgress("operation.users.update-password")) {
          this.requestChangePasswordService.all();
        }
    });

    this.requestChangePasswordService.linkForm((data) => {
      if (data)
        this.link = data;
    });

    this.requestChangePasswordService.all();
  }

  ngOnDestroy() {
    if (this._activitySubscription)
      this._activitySubscription.unsubscribe();

    if (this._serviceSubscription)
      this._serviceSubscription.unsubscribe();
  }

  updateUser(result) {
    this.richieste = result;
    this.last_update = this.requestChangePasswordService.last_update;
    this.users = result.reverse().slice(0, 5);
  }

}
