import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MateriaService } from 'src/app/services/general/materia.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { PageService } from 'src/app/services/page.service';
import { UtilityService } from 'src/app/services/utility.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-materia-profile',
  templateUrl: './materia-profile.component.html',
  styleUrls: ['./materia-profile.component.scss'],
})
export class MateriaProfileComponent implements OnInit {

  @Input() id: any;

  showForm: boolean;

  materie: any;
  materieData: any;
  materieError: any;
  isLoadingMaterie: boolean;
  showOnlyCreate: boolean;
  checkDasboardMaterie: boolean = true;

  private _activeRouteSubscription: Subscription;
  private _materiaServiceSubscription: Subscription;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private materiaService: MateriaService,
    private utilityService: UtilityService,
    private pageService: PageService
  ) { }

  ngOnInit() {
    this.isLoadingMaterie = false;

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_materia;
      this.init();
    });

    this._materiaServiceSubscription = this.materiaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.materie = result;
        this.materieUpdated(result);
        if (this.id && this.id.length > 0)
          this.updateData();
      }
    });

    this.showOnlyCreate = this.utilityService.checkRouteActionType(this.activeRoute, "create");
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();

    if (this._materiaServiceSubscription)
      this._materiaServiceSubscription.unsubscribe();
  }

  init() {

    this.showForm = false;
    this.isLoadingMaterie = true;

    this.materiaService.all((result)=>{
      this.materie = result;
      this.materieUpdated(result);
    });

    if (this.id && this.id > 0) {
      this.updateData();
    }
    else {
      this.showForm = true;
    }
  }

  updateData() {
    if (this.id && this.id > 0) {
      this.materiaService.get(this.id, (data) => {
        this.pageService.updatePageTitle({ materia: data.name });
      }, (error) => {
        this.materiaService.all();
      });
    }
  }

  materieUpdated(result) {
    this.materieData = this.parseMaterieResult(result);
    this.isLoadingMaterie = false;
  }

  parseMaterieResult(result) {

    let table = [];

    result.forEach(element => {

      table.push(
        {
          "nome": [element.name],
          "_export_data": {
            name: element.name
          },
        });
    });

    return table;
  }

  rowClickMateria(data) {
    let materia = this.materie[data.index];
    this.router.navigate(['/materia/' + materia.id]);
  }

  addMateriaClick() {
    this.router.navigate(['/materia/create']);
  }

  closeFormAdd() {
    this.router.navigate(['/materia']);
  }
}
