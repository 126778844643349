import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview-column-data',
  templateUrl: './preview-column-data.component.html',
  styleUrls: ['./preview-column-data.component.scss']
})
export class PreviewColumnDataComponent implements OnInit {

  @Input() data;
  @Input() colIndex;
  @Input() headerRowIndex;

  @Input() split: boolean = false;
  @Input() splitChar;
  @Input() splitPos: number;

  constructor() { }

  ngOnInit() {
  }

}
