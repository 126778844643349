<div class="card">
  <!-- Header -->
  <div class="card-header">
    <h5 class="card-header-title">
      <i class="fad fa-user nav-icon red mr-1"></i> Utente: {{data?.name?.familyName}}
      {{data?.name?.givenName}}
      <span *ngIf="data?.suspended" class="ml-2 badge badge-soft-warning"><i class="fas fa-exclamation-circle"></i>
        Attenzione, l'utente risulta sospeso</span>
      <span *ngIf="data?.archived" class="ml-2 badge badge-soft-warning"><i class="fas fa-exclamation-circle"></i>
        Attenzione, l'utente risulta archiviato</span>
    </h5>
    <div class="d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="showInfo = !showInfo">
        <i class="fas fa-info mr-2"></i>{{ 'BTN.Informazioni' | translate }}
      </button>
      <button *ngIf="!user" type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="syncUsersCommand()">
        <i class="fas fa-sync mr-2"></i>{{ 'BTN.sync' | translate }}
      </button>
    </div>
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div *ngIf="showInfo" class="card-body">

    <div class="row mb-3">
      <div class="col">
        <small class="card-subtitle">Email Google</small>
        <p>{{ data?.primaryEmail || 'Nessuna email'}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Organizzazione</small>
        <p>{{ data?.orgUnitPath || "Nessuna organizzazione associata"}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Creato il</small>
        <p>{{ data?.creationTime | date:'medium' }}</p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <small class="card-subtitle">Amministratore</small>
        <p>{{ data?.isAdmin || "Informazione non disponibile"}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Sospeso</small>
        <p>{{data?.suspended ? "SI" : "NO"}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Archiviato</small>
        <p>{{data?.archived ? "SI" : "NO"}}</p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <small class="card-subtitle">Email di recupero</small>
        <p>{{ data?.recoveryEmail || "Nessuna email" }}</p>
      </div>
      <div class="col"></div>
      <div class="col">
        <small class="card-subtitle">Ultimo Accesso</small>
        <p>{{data?.lastLoginTime | date:'medium'}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <small class="card-subtitle">Email di contatto</small>
        <ng-container *ngFor="let email of data?.emails">
          <p *ngIf="email.address != data?.primaryEmail">{{ email.address }} <span *ngIf="email.type">({{ email.type
              }})</span></p>
        </ng-container>
      </div>
      <div class="col">
        <small class="card-subtitle">Numeri di telefono</small>
        <ng-container *ngFor="let tel of data?.phones">
          <p>{{ tel.value }} ({{ tel.type }})</p>
        </ng-container>
      </div>
      <div class="col">
        <small class="card-subtitle">Indirizzi</small>
        <ng-container *ngFor="let indirizzo of data?.addresses">
          <p>{{ indirizzo.formatted }} ({{ indirizzo.type }})</p>
        </ng-container>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <small class="card-subtitle">Codice Identificativo</small>
        <ng-container *ngFor="let externalId of data?.externalIds">
          <p *ngIf="externalId.type == 'organization'">{{ externalId.value }}</p>
        </ng-container>
      </div>
      <div class="col">
        <small class="card-subtitle">Id Esterni</small>
        <ng-container *ngFor="let externalId of data?.externalIds">
          <p *ngIf="externalId.type == 'organization'">{{ externalId.value }}</p>
        </ng-container>
      </div>
      <div class="col">
      </div>
    </div>

  </div>
  <!-- End Body -->

  <!-- Footer -->
  <div class="card-footer text-center">
    <div class="row">
      <div class="col">
        <span class="h4">{{ data?.primaryEmail || 'Nessuna email'}}</span>
        <span class="d-block font-size-sm">Email</span>
      </div>
    </div>
  </div>
  <!-- End Footer -->
</div>
