<app-card-loading *ngIf="loading">
</app-card-loading>

<app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">
  <div class="row mb-5">
    <div class="col">
      <div class="card card-hover-shadow">
        <div class="card-body m-2">
          <app-create-classroom-wizard-steps [currentStep]="2"></app-create-classroom-wizard-steps>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">

      <!-- Card -->
      <div class="card card-hover-shadow">
        <div class="card-body">

          <h1 class="card-title">{{'CLASSROOM.create.title_preview' | translate}}</h1>
          <p class="card-text" [innerHTML]="'CLASSROOM.create.subtitle_preview' | translate"></p>

          <div class="alert alert-soft-info" *ngIf="model && model.creationType == 2">
            <b>Attenzione</b> i docenti senza materie non avranno Classroom associate, assicurati che tutti i docenti
            delle classi abbiano una Materia associata.
          </div>

          <div class="alert alert-soft-warning" *ngIf="struttura == null || struttura?.length == 0">
            <b>Attenzione</b> non è stato possibile generare delle Classroom, controlla le classi selezionate e il
            metodo che hai scelto per automatizzare la creazione.<br>
          </div>
        </div>
      </div>

      <!-- Errore -->
      <div class="row mt-5" *ngIf="strutturaErrors != null && strutturaErrors.length > 0">
        <div class="col">
          <div class="card card-hover-shadow">
            <div class="card-body">

              <h2>
                {{'CLASSROOM.create.errori_titolo' | translate}}
              </h2>

              <p [innerHTML]="'CLASSROOM.create.errori_sottotitolo' | translate"></p>

              <div class="alert alert-soft-info">
                Se l'errore dipende da un numero elevato di docenti o la mancanza di un proprietario, puoi provare a modifcare la classroom in basso.
              </div>

              <ul class="list-group list-group-lg w-100">
                <ng-container *ngFor="let element_classroom of strutturaErrors; let index_utente = index;">
                  <li class="list-group-item">
                    <ng-container *ngTemplateOutlet="classroomInfoTemplate;context:{classroom: element_classroom}">
                    </ng-container>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Anteprima -->
      <div>
        <ng-container *ngFor="let element_struttura of struttura; let index_elemento_struttura = index;">
          <!--
            .########..##.......########..######...######...#######.....########.....######..##..........###.....######...######..####
            .##.....##.##.......##.......##....##.##....##.##.....##....##..........##....##.##.........##.##...##....##.##....##..##.
            .##.....##.##.......##.......##.......##.......##.....##....##..........##.......##........##...##..##.......##........##.
            .########..##.......######....######...######..##.....##....######......##.......##.......##.....##..######...######...##.
            .##........##.......##.............##.......##.##.....##....##..........##.......##.......#########.......##.......##..##.
            .##........##.......##.......##....##.##....##.##.....##....##..........##....##.##.......##.....##.##....##.##....##..##.
            .##........########.########..######...######...#######.....########.....######..########.##.....##..######...######..####
            -->
          <div class="row mt-5" *ngIf="element_struttura.plesso">
            <div class="col">
              <div class="card card-hover-shadow">
                <div class="card-header card-btn btn-block pointer"
                  [ngClass]="{'collapsed': element_struttura.isCollapsed}"
                  (click)="element_struttura.isCollapsed = !element_struttura.isCollapsed">
                  <h2 class="card-header-title">
                    <i class="fas fa-school mr-2"></i>{{element_struttura.plesso.name}} <span
                      *ngIf="element_struttura.classrooms"
                      class="badge badge-success ml-2">{{element_struttura.classrooms.length}}</span>
                    <a *ngIf="element_struttura.plesso.id" class="ml-2 btn-xs btn-icon btn-ghost-primary"
                      routerLink="/plesso/{{element_struttura.plesso.id}}">
                      <i class="far fa-external-link"></i>
                    </a>
                    <h5 class="mt-2">Abbreviazione "{{element_struttura.plesso.short_name}}"</h5>
                  </h2>
                  <span class="card-btn-toggle" [attr.aria-expanded]="!element_struttura.isCollapsed"
                    aria-controls="collapsePlesso">
                    <span class="btn btn-xs btn-ghost-primary card-btn-toggle-default">
                      <i class="fas fa-chalkboard mr-1"></i>
                      Mostra le Classroom
                    </span>
                    <span class="btn btn-xs btn-outline-primary card-btn-toggle-active">
                      <i class="fas fa-chalkboard mr-1"></i>
                      Nascondi le Classroom
                    </span>
                  </span>
                </div>
                <div class="card-body" id="collapsePlesso">
                  <span [collapse]="element_struttura.isCollapsed" [isAnimated]="true">
                    <ng-container>

                      <ul class="list-group list-group-lg">
                        <li class="list-group-item">
                          <ng-container
                            *ngFor="let element_classroom of element_struttura.classrooms; let index_classroom = index;">
                            <div class="row align-items-center my-3 pointer"
                              (click)="element_classroom.isCollapsed = !element_classroom.isCollapsed">
                              <div class="col-3">
                                <small class="text-cap">Classe: {{element_classroom.classe.name}}
                                  <a *ngIf="element_struttura.plesso.id && element_classroom.classe.id"
                                    class="ml-2 btn-xs btn-icon btn-ghost-primary"
                                    routerLink="/plesso/{{element_struttura.plesso.id}}/classe/{{element_classroom.classe.id}}">
                                    <i class="far fa-external-link"></i>
                                  </a>
                                </small>
                                <small class="text-cap" *ngIf="element_classroom.materia">Materia:
                                  {{element_classroom.materia.materia_name}}</small>
                                <span class="font-weight-bold text-dark lead"><i
                                    class="fas fa-users-class mr-2"></i>{{element_classroom.name}}</span>
                                <span *ngIf="element_classroom.error" class="badge badge-danger ml-2">Errori</span>
                                <span *ngIf="element_classroom.warning" class="badge badge-danger ml-2">Attenzione</span>
                              </div>
                              <div class="col-3 align-self-start">
                                <small class="text-cap">Descrizione</small>
                                <span class="font-weight-bold text-dark lead">{{element_classroom.description}}</span>
                              </div>
                              <div class="col-2 align-self-start">
                                <small class="text-cap">{{ 'STR.docenti' | translate }}</small>
                                <span
                                  class="font-weight-bold text-dark lead">{{element_classroom.docenti.length}}</span>
                              </div>
                              <div class="col-2 align-self-start">
                                <small class="text-cap">{{ 'STR.studenti' | translate }}</small>
                                <span
                                  class="font-weight-bold text-dark lead">{{element_classroom.studenti.length}}</span>
                              </div>
                              <div class="col text-right">

                                <button type="button" class="btn btn-xs btn-outline-primary"
                                  [disabled]="(element_classroom.studenti == null || element_classroom.studenti.length == 0) && (element_classroom.docenti == null || element_classroom.docenti.length == 0)">
                                  <ng-template *ngIf="element_classroom.isCollapsed; then mostra else nascondi">
                                  </ng-template>
                                  <ng-template #mostra>
                                    <i class="fas fa-users mr-1"></i>
                                    Mostra Utenti
                                  </ng-template>
                                  <ng-template #nascondi>
                                    <i class="fas fa-users-slash mr-1"></i>
                                    Nascondi Utenti
                                  </ng-template>
                                </button>
                              </div>
                            </div>

                            <span [collapse]="element_classroom.isCollapsed" [isAnimated]="true">

                              <div class="row">
                                <div class="col-6">
                                  <span class="d-block h4"><i class="fas fa-chalkboard-teacher mr-2"></i>Docenti</span>
                                  <div class="alert alert-soft-danger" *ngIf="element_classroom.docenti?.length == 0">
                                    <b>Attenzione</b> non sono presenti docenti in questa classroom.<br>
                                    Questa classroom non verrà creata.
                                  </div>
                                  <div class="alert alert-soft-danger" *ngIf="element_classroom.docenti?.length > 20">
                                    <b>Attenzione</b> non possono essere presenti più di 20 docenti in una Classroom, rimuoverli per procedere alla creazione.
                                  </div>
                                  <ul class="list-group list-group-lg w-100"
                                    *ngIf="element_classroom.docenti?.length > 0">
                                    <ng-container
                                      *ngFor="let element_user of element_classroom.docenti; let index_utente = index;">
                                      <li class="list-group-item"
                                        [ngClass]="{'alert-soft-danger': element_user.errors?.length > 0}">
                                        <ng-container>
                                          <div class="row">
                                            <div class="col-12 align-self-start">
                                              <small class="text-cap"
                                                [title]="element_user.familyName+' '+element_user.givenName">{{element_user.familyName}}
                                                {{element_user.givenName}}</small>
                                              <div [title]="element_user.email" class="text-truncate">
                                                {{element_user.email}}</div>
                                            </div>
                                            <div class="col d-flex justify-content-end align-items-center">
                                              <button type="button" class="btn btn-xs mt-2"
                                                [ngClass]="{'btn-outline-primary': element_classroom.ownerId != element_user.google_user.google_id, 'btn-primary': element_classroom.ownerId == element_user.google_user.google_id}"
                                                (click)="setUserAsOwner(element_classroom, element_user);">
                                                <ng-template
                                                  *ngIf="element_classroom.ownerId == element_user.google_user.google_id; then isOwner else isNotOwner">
                                                </ng-template>
                                                <ng-template #isOwner>
                                                  <i class="fas fa-crown mr-1"></i>
                                                  Proprietario
                                                </ng-template>
                                                <ng-template #isNotOwner>
                                                  <i class="fal fa-crown mr-1"></i>
                                                  Imposta come Proprietario
                                                </ng-template>
                                              </button>

                                              <button type="button" class="ml-2 btn btn-xs mt-2 btn-outline-danger"
                                                [disabled]="element_classroom.ownerId == element_user.google_user.google_id"
                                                (click)="removeUserFromClassroom(element_classroom, element_user);">
                                                <i class="fas fa-trash mr-1"></i>
                                                Rimuovi
                                              </button>
                                            </div>
                                          </div>
                                        </ng-container>
                                      </li>
                                    </ng-container>
                                  </ul>
                                </div>

                                <div class="col-6">
                                  <span class="d-block h4"><i class="fas fa-user-graduate mr-2"></i>Studenti</span>
                                  <div class="alert alert-soft-danger" *ngIf="element_classroom.studenti?.length == 0">
                                    <b>Attenzione</b> non sono presenti studenti in questa classroom.
                                  </div>
                                  <ul class="list-group list-group-lg w-100"
                                    *ngIf="element_classroom.studenti?.length > 0">
                                    <ng-container
                                      *ngFor="let element_user of element_classroom.studenti; let index_utente = index;">
                                      <li class="list-group-item"
                                        [ngClass]="{'alert-soft-danger': element_user.errors?.length > 0}">
                                        <ng-container>
                                          <div class="row">
                                            <div class="col-8 align-self-start">
                                              <small class="text-cap"
                                                [title]="element_user.familyName+' '+element_user.givenName">{{element_user.familyName}}
                                                {{element_user.givenName}}</small>
                                              <div [title]="element_user.email" class="text-truncate">
                                                {{element_user.email}}</div>
                                            </div>
                                            <div class="col align-self-start">
                                              <small class="text-cap">Cod. Id.</small>
                                              <div [title]="element_user.cf" class="text-truncate">{{element_user.cf
                                                || "-"}}
                                              </div>
                                            </div>
                                          </div>
                                        </ng-container>
                                      </li>
                                    </ng-container>
                                  </ul>
                                </div>
                              </div>
                            </span>

                          </ng-container>
                        </li>
                      </ul>
                    </ng-container>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- Anteprima -->


      <div class="row mt-5">
        <div class="col">
          <div class="card card-hover-shadow">
            <div class="card-body">
              <div class="alert alert-soft-danger" *ngIf="strutturaErrors != null && strutturaErrors?.length > 0">
                <b>Attenzione</b> sono presenti delle incongruenze.<br>
                Correggere le incongruenze in base ai suggerimenti per poter continuare.
              </div>

              <div class="row justify-content-between align-items-top">
                <div class="col-6 py-1">
                  <button (click)="back()" type="button" class="btn btn-white">
                    <i class="far fa-chevron-left mr-1"></i> {{'PASSAGGIOANNO.btn.indietro' | translate}}
                  </button>
                  <p class="mt-2">
                    <small><b>Attenzione:</b> tornando indietro verranno perse le modifiche ai PROPRIETARI delle
                      classroom</small>
                  </p>
                </div>

                <div class="col-6 py-1">
                  <div class="row justify-content-end">
                    <div class="col-auto">
                      <button type="submit" class="btn"
                        [disabled]="(strutturaErrors != null && strutturaErrors.length != 0) || struttura == null || struttura.length == 0"
                        [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' "
                        (click)="onSubmit()">
                        Crea Classroom <i class="far fa-chevron-right ml-1"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      <!-- End Card -->
    </div>
  </div>
</ng-container>

<ng-template #classroomInfoTemplate let-classroom="classroom">
  <div class="alert alert-soft-danger">
    <b>Classe: {{classroom.classe.name}}</b> {{ classroom.error }}.<br>
    <small class="text-cap" *ngIf="classroom.materia">Materia: {{classroom.materia.materia_name}}</small>
  </div>
  <div class="row">
    <div class="col">
      <button class="btn btn-block btn-outline-danger btn-xs" (click)="escludiClassroom(classroom)">
        Escludi
      </button>
    </div>
    <div class="col">
      <button class="btn btn-block btn-outline-success btn-xs"
        routerLink="/plesso/{{classroom.classe.plesso.id}}/classe/{{classroom.classe.id}}">
        <i class="fas fa-hammer mr-2"></i> Modifica la classe
      </button>
    </div>
  </div>

</ng-template>
