<app-card-loading *ngIf="loading">
</app-card-loading>

<app-card-loading-error *ngIf="!(loading) && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<form #myForm="ngForm" (ngSubmit)="onSubmit()" validate>

  <div class="card card-hover-shadow" *ngIf="!(loading) && !error">

    <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>

    <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">
      <div class="row">
        <!-- Left -->
        <div class="col">

          <!-- Titlte -->
          <div class="row form-group">
            <div class="col-12">
              <h3>Modifica Ente</h3>
            </div>
          </div>

          <!-- Nome -->
          <div class="row form-group">
            <div class="col-12">
              <label class="input-label" for="name">Nome</label>
              <input id="name" name="name" #name="ngModel" class="form-control" [(ngModel)]="data.name" required
                [ngClass]="(name.dirty || name.touched)? (name.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                type="text" required maxlength="255">

              <div class="invalid-feedback" *ngIf="name.invalid && (name.dirty || name.touched)">
                <span>Non valido</span>
              </div>
              <span class="valid-feedback" *ngIf="name.valid && (name.dirty || name.touched)">Valido</span>
            </div>
          </div>

          <!-- Tipo -->
          <div class="form-group row">
            <div class="col-12">
              <label class="input-label">Tipologia</label>
              <input type="hidden" id="type" name="type" #type="ngModel" [(ngModel)]="data.type"
                [disabled]="editMode || disableUserTypeSelect">
              <app-select [id]="'select-type'" [placeholder]="'STR.Seleziona_Tipologia'" [data]="selectEnteTypes"
                [showValue]="'name'" [resultAttribute]="'id'" [(value)]="data.type" [optionAttributeShow]="'name'"
                [showClear]="false"></app-select>
            </div>
          </div>

        </div>

        <!-- Right Edit -->
        <div class="col-5 p-3 m-3 bg-light rounded-lg">
          <!-- Titlte -->
          <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Sincronizzazione con Google Workspace</h4>

          <div class="row">
            <div class="col-12">
              <div class="alert alert-soft-warning" role="alert">
                Le informazioni non sono sincronizzate con Google Workspace, vengono aggiornate solo all'interno di
                Geniusuite.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer border-0 mx-3">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto py-1">
          <button *ngIf="id" [routerLink]="['/ente']" type="button" class="btn btn-white">
            <i class="far fa-chevron-left mr-1"></i> Annulla
          </button>
        </div>
        <div class="col-6 py-1">
          <div class="row justify-content-end">
            <button type="submit" class="btn" [disabled]="!myForm.form.valid"
              [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
              <span>Aggiorna</span>
              <i class="far fa-chevron-right ml-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngFor="let address of data.addresses; let i = index">

    <div class="card card-hover-shadow mt-3" *ngIf="!(loading) && !error">

      <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>

      <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">
        <div class="row">
          <!-- Left -->
          <div class="col">

            <div class="row form-group">
              <div class="col-12">
                <h3>Indirizzo della scuola</h3>
              </div>
            </div>


            <!-- Indirizzo -->
            <div class="row form-group">
              <div class="col-12">
                <label class="input-label" for="indirizzo">Indirizzo</label>
                <input id="indirizzo{{i}}" name="indirizzo{{i}}" #indirizzo{{i}}="ngModel" class="form-control" required
                  [(ngModel)]="address.indirizzo"
                  [ngClass]="('indirizzo{{i}}'.dirty || 'indirizzo{{i}}'.touched)? ('indirizzo{{i}}'.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                  type="text" maxlength="255">

                <div class="invalid-feedback"
                  *ngIf="'indirizzo{{i}}'.invalid && ('indirizzo{{i}}'.dirty || 'indirizzo{{i}}'.touched)">
                  <span>Non valido</span>
                </div>
                <span class="valid-feedback"
                  *ngIf="'indirizzo{{i}}'.valid && ('indirizzo{{i}}'.dirty || 'indirizzo{{i}}'.touched)">Valido</span>
              </div>
            </div>

            <!-- citta -->
            <div class="row form-group">
              <div class="col-12">
                <label class="input-label" for="citta{{i}}">Città</label>
                <input id="citta{{i}}" name="citta{{i}}" #citta{{i}}="ngModel" class="form-control" required
                  [(ngModel)]="address.citta"
                  [ngClass]="('citta{{i}}'.dirty || 'citta{{i}}'.touched)? ('citta{{i}}'.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                  type="text" maxlength="255">

                <div class="invalid-feedback"
                  *ngIf="'citta{{i}}'.invalid && ('citta{{i}}'.dirty || 'citta{{i}}'.touched)">
                  <span>Non valido</span>
                </div>
                <span class="valid-feedback"
                  *ngIf="'citta{{i}}'.valid && ('citta{{i}}'.dirty || 'citta{{i}}'.touched)">Valido</span>
              </div>
            </div>

            <!-- nazione -->
            <div class="row form-group">
              <div class="col-12">
                <label class="input-label" for="nazione{{i}}">Nazione</label>
                <input id="nazione{{i}}" name="nazione{{i}}" #nazione{{i}}="ngModel" class="form-control"
                  [(ngModel)]="address.nazione"
                  [ngClass]="('nazione{{i}}'.dirty || 'nazione{{i}}'.touched)? ('nazione{{i}}'.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                  type="text" maxlength="255">

                <div class="invalid-feedback"
                  *ngIf="'nazione{{i}}'.invalid && ('nazione{{i}}'.dirty || 'nazione{{i}}'.touched)">
                  <span>Non valido</span>
                </div>
                <span class="valid-feedback"
                  *ngIf="'nazione{{i}}'.valid && ('nazione{{i}}'.dirty || 'nazione{{i}}'.touched)">Valido</span>
              </div>
            </div>

            <!-- telefono -->
            <div class="row form-group">
              <div class="col-12">
                <label class="input-label" for="telefono{{i}}">Telefono</label>
                <input id="telefono{{i}}" name="telefono{{i}}" #telefono{{i}}="ngModel" class="form-control" required
                  [(ngModel)]="address.telefono"
                  [ngClass]="('telefono{{i}}'.dirty || 'telefono{{i}}'.touched)? ('telefono{{i}}'.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                  type="text" maxlength="255">

                <div class="invalid-feedback"
                  *ngIf="'telefono{{i}}'.invalid && ('telefono{{i}}'.dirty || 'telefono{{i}}'.touched)">
                  <span>Non valido</span>
                </div>
                <span class="valid-feedback"
                  *ngIf="'telefono{{i}}'.valid && ('telefono{{i}}'.dirty || 'telefono{{i}}'.touched)">Valido</span>
              </div>
            </div>

            <!-- email -->
            <div class="row form-group">
              <div class="col-12">
                <label class="input-label" for="email{{i}}">email</label>
                <input id="email{{i}}" name="email{{i}}" #email{{i}}="ngModel" class="form-control" required
                  [(ngModel)]="address.email"
                  [ngClass]="('email{{i}}'.dirty || 'email{{i}}'.touched)? ('email{{i}}'.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                  type="text" maxlength="255">

                <div class="invalid-feedback"
                  *ngIf="'email{{i}}'.invalid && ('email{{i}}'.dirty || 'email{{i}}'.touched)">
                  <span>Non valido</span>
                </div>
                <span class="valid-feedback"
                  *ngIf="'email{{i}}'.valid && ('email{{i}}'.dirty || 'email{{i}}'.touched)">Valido</span>
              </div>
            </div>
          </div>

          <!-- Right Edit -->
          <div class="col-5 p-3 m-3 bg-light rounded-lg">
            <!-- Titlte -->
            <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Sincronizzazione con Google Workspace</h4>

            <div class="row">
              <div class="col-12">
                <div class="alert alert-soft-warning" role="alert">
                  Le informazioni non sono sincronizzate con Google Workspace, vengono aggiornate solo all'interno di
                  Geniusuite.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer border-0 mx-3">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto py-1">
            <button *ngIf="id" [routerLink]="['/ente']" type="button" class="btn btn-white">
              <i class="far fa-chevron-left mr-1"></i> Annulla
            </button>
          </div>
          <div class="col-6 py-1">
            <div class="row justify-content-end">
              <button type="submit" class="btn" [disabled]="!myForm.form.valid"
                [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                <span>Aggiorna</span>
                <i class="far fa-chevron-right ml-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</form>
