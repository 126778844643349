import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleClassroomService } from 'src/app/services/google/google-classroom.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';

@Component({
  selector: 'app-user-profile-datatable-card',
  templateUrl: './user-profile-datatable-card.component.html',
  styleUrls: ['./user-profile-datatable-card.component.scss']
})
export class UserProfileDatatableCardComponent implements OnInit {

  @Input() id: any;
  @Input() id_google: any;

  data: any;

  classiData: any;
  classi: any;
  classiError: any;

  groupsData: any;
  groups: any;
  groupsError: any;

  courseClassroomTeacher: any;
  courseClassroomStudent: any;
  courseClassroomData: any;
  courseClassroom: any;
  courseClassroomError: any;

  isLoading = {
    classi: true,
    groups: true,
    classroom: true,
  }

  showCardDatatable: any = {
    classi: true,
    gruppi: true,
    classroom: true,
  };

  private _userServiceSubscription: Subscription;

  private _googleServiceUserSubscription: Subscription;

  constructor(
    private classroomService: GoogleClassroomService,
    private googleDirectoryService: GoogleDirectoryService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {

    this.unsubscribeAll();

    this.showCardDatatable = {
      classi: false,
      gruppi: true,
      classroom: true
    };

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });

    this._googleServiceUserSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });

    this.updateData();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  unsubscribeAll() {
    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleServiceUserSubscription)
      this._googleServiceUserSubscription.unsubscribe();


  }

  updateData() {
    if (this.id && this.id > 0) {
      this.isLoading.classi = true;
      this.showCardDatatable.classi = true;
      this.userService.get(this.id, (data) => {
        this.data = data;

        if (!this.data.google_user) {
          this.googleDirectoryService.usersGet(this.data.google_id);
        }

        this.classiUpdated(this.data.classi);
      });
    } else if (this.id_google && this.id_google > 0) {
      this.isLoading.classi = false;
      this.showCardDatatable.classi = false;
      this.data = {
        google_id: this.id_google,
        google_user: this.googleDirectoryService.getUser(this.id_google)
      }
      this.selectTab(2);
    }
  }

  updateGroupsData() {
    if (!this.data || !this.data.google_id) {
      this.groupsUpdated([]);
      return;
    }

    this.googleDirectoryService.userGroupsList(this.data.google_id, (data) => {
      this.groupsData = data;
      this.groupsUpdated(this.groupsData);
    })
  }

  updateCourseClassroomData() {
    this.courseClassroomStudent = [];
    this.courseClassroomTeacher = [];

    if (!this.data || !this.data.google_id) {
      this.courseClassroomUpdated();
      return;
    }

    this.classroomService.coursesListWithQuery({ teacherId: this.data.google_id }, (result) => {
      this.courseClassroomTeacher = result;
      this.classroomService.coursesListWithQuery({ studentId: this.data.google_id }, (result) => {
        this.courseClassroomStudent = result;
        this.courseClassroomUpdated();
      });
    });
  }

  setLoading(loading = false) {
    // Loading All
    Object.keys(this.isLoading).forEach(element => {
      this.isLoading[element] = loading;
    });
  }

  selectTab(index) {
    switch (index) {
      case 1:
        this.isLoading.classi = true;
        this.updateData();
        break;
      case 2:
        this.isLoading.groups = true;
        this.updateGroupsData();
        break;
      case 3:
        this.isLoading.classroom = true;
        this.updateCourseClassroomData();
        break;
    }
  }

  classiUpdated(result) {
    this.classiData = result;
    this.classi = this.parseClassiResult(result);
    this.isLoading.classi = false;
  }

  groupsUpdated(result) {
    this.groups = this.parseGroupsResult(result);
    this.isLoading.groups = false;
  }

  courseClassroomUpdated() {
    this.courseClassroomData = this.courseClassroomStudent.concat(this.courseClassroomTeacher);
    this.courseClassroom = this.parseCourseClassroomResult(this.courseClassroomData);
    this.isLoading.classroom = false;
  }

  parseClassiResult(result) {

    let table = [];



    result.forEach(element => {

      let materie = null;

      if (this.data.user && this.data.user.materie && this.data.user.materie.length > 0 && element.classe && this.data.user.materie.some(el => el.classe_id === element.classe.id)) {
        materie = (element.classe && this.data.user.materie && this.data.user.materie.length > 0) ? this.data.user.materie.filter(el => el.classe_id === element.classe.id).map(x => x.materia_name).join(", ") : null;
      }

      table.push(
        {
          "nome": [element.classe.name, element.classe.anno],
          "plesso": [element.classe.plesso.name, element.classe.plesso.description],
          "Materie": [materie],
          "_export_data": {
            plesso: element.classe.plesso.name,
            classe: element.classe.name,
            anno: element.classe.anno
          },
        });
    });

    return table;
  }

  parseCourseClassroomResult(result) {

    let table = [];

    result.forEach(element => {
      table.push(
        {
          "nome": [element.name, element.section],
          "description": [element.descriptionHeading]
        });
    });

    return table;
  }

  rowClickCourse(data) {
    let course = this.courseClassroomData[data.index];
    this.router.navigate(['/classroom/' + course.id]);
  }

  parseGroupsResult(result) {

    let table = [];

    result.forEach(element => {
      table.push({
        "nome": [element.name, element.description],
        "email": [element.email],
        "membri": [element.directMembersCount],
        "_export_data": {
          name: element.name,
          description: element.description,
          email: element.email,
        },
      })
    });

    return table;
  }

  rowClickClasse(data) {
    let classe = this.data.classi[data.index];
    this.router.navigate(['/plesso/' + classe.classe.plesso.id + '/classe/' + classe.classe_id]);
  }

  rowClickGroup(data) {
    let group = this.groupsData[data.index];
    this.router.navigate(['group/' + group.id]);
  }

}
