import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';

export enum EnteTypes {
  Ente = "1",
  Università = "2",
  Scuola = "3",
  NoProfit = "4",
  Altro = "5",
}

@Component({
  selector: 'app-ente-form',
  templateUrl: './ente-form.component.html',
  styleUrls: ['./ente-form.component.scss']
})
export class EnteFormComponent implements OnInit {
  loading = false;
  error = null;

  data: any;

  selectEnteTypes = [
    {
      id: EnteTypes.Ente,
      name: "Ente"
    },
    {
      id: EnteTypes.Università,
      name: "Università"
    },
    {
      id: EnteTypes.Scuola,
      name: "Scuola"
    }, {
      id: EnteTypes.NoProfit,
      name: "Associazione No-profit"
    },
    {
      id: EnteTypes.Altro,
      name: "Altro"
    }
  ]

  private _accountServiceSubscription: Subscription;

  @ViewChild('myForm') myForm: NgForm;

  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    this.data = this.accountService.data.ente;

    if (!this.data.addresses || this.data.addresses.length == 0) {
      this.data.addresses = [{
        indirizzo: null,
        citta: null,
        nazione: null,
        telefono: null,
        email: null
      }]
    }

    this._accountServiceSubscription = this.accountService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.accountUpdated(result);
    });
  }

  ngOnDestroy() {
    if (this._accountServiceSubscription)
      this._accountServiceSubscription.unsubscribe();
  }

  accountUpdated(result) {
    this.data = result.ente;
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  onSubmit() {

    if (this.myForm.valid) {

      this.loading = true;

      // Copio i dati dell'oggetto senza riferimento
      let reqBody = JSON.parse(JSON.stringify(this.data));

      this.accountService.refreshEnteData(reqBody, (result) => {
        this.loading = false;
      }, (error) => {
        this.loading = false;
        this.error = "Errore";
      });
    }
  }
}
