import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { paramsValidator } from 'src/app/forms/helpers/params.validator';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { CreateClassroomService } from 'src/app/services/operations/classroom/create-classroom.service';

@Component({
  selector: 'app-create-classroom-preview',
  templateUrl: './create-classroom-preview.component.html',
  styleUrls: ['./create-classroom-preview.component.scss']
})
export class CreateClassroomPreviewComponent implements OnInit {

  showAllSetting = false;
  error: any;
  classiSelected: any;

  struttura: any;
  strutturaErrors: any;

  classi: any = [];
  classiSelect: any = [];
  classiPreSelect: any = [];

  _loadingData = {
    plessi: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  myForm: FormGroup = new FormGroup({});
  private _plessoServiceSubscription: Subscription;
  private _createClassroomServiceSubscription: Subscription;
  validateEventSubject: Subject<any> = new Subject<any>();
  setValueEventSubject: Subject<any> = new Subject<any>();
  eventsSubject: Subject<any> = new Subject<any>();

  model: any;

  constructor(
    private router: Router,
    private createClassroomService: CreateClassroomService,
  ) { }

  ngOnInit(): void {
    this.ngOnDestroy();
    this.loadStruttura();
  }

  loadStruttura(){
    if (this.createClassroomService.items == null || this.createClassroomService.items.length == 0) {
      this.back();
      return;
    }

    this.struttura = this.createClassroomService.getStruttura();
    this.strutturaErrors = this.createClassroomService.errors;
    this.model = this.createClassroomService.parameters;
  }

  ngOnDestroy() {
    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();

    if (this._createClassroomServiceSubscription)
      this._createClassroomServiceSubscription.unsubscribe();
  }

  escludiClassroom(classroom) {
    this.createClassroomService.deleteItemAtIndex(classroom.classe.id, true, true, true, classroom.classe.plesso.id);
    this.loadStruttura();
  }

  setUserAsOwner(classroom, user) {
    let ownerId = user.google_user.google_id;
    this.createClassroomService.changeOwnerClassroom(ownerId, classroom.classe.plesso.id,classroom.tempId);
    this.createClassroomService.checkStruttura();
    this.loadStruttura();
  }

  removeUserFromClassroom(classroom, user) {
    let userId = user.google_user.google_id;
    this.createClassroomService.removeUserFromClassroom(userId, classroom.classe.plesso.id,classroom.tempId);
    this.createClassroomService.checkStruttura();
    this.loadStruttura();
  }

  onSubmit() {
    this.createClassroomService.execOperation((error) => {
      if (!error) {
        this.createClassroomService.reset();
        this.router.navigate(['/operation/create-classroom']);
      }
    });
  }

  back() {
    this.router.navigate(['/operation/create-classroom']);
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  backendError() {
    this.error = "backend";
  }
}

