<app-card-loading *ngIf="loading">
</app-card-loading>

<app-card-loading-error *ngIf="!(loading) && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="row">
  <div class="col-6">
    <div class="alert alert-soft-info">
      <strong>Hai bisogno di aiuto?</strong><br>
      Guarda tutti i nostri video tutorial su Alfred per capire come scegliere il layout adatto, come configurare i widget e come pubblicare le informazioni.<br>
      <a class="btn btn-xs btn-primary mt-2" href="https://www.youtube.com/watch?v=KhmLCf0t9zI&list=PLCt3ItsLXF5zF1o_2w8vqFY1H90OFDHxH" target="_blank"><i
          class="fab fa-youtube mr-1"></i>Guarda i video ora!</a>
    </div>
  </div>
  <div class="col-6">
    <div class="alert alert-soft-info">
      <strong>Qualcosa non ti è chiaro?</strong><br>
      È il tuo primo accesso ad Alfred o c'è qualcosa che ti risulta poco chiaro nell'uso quotidiano, fissa una call di 30 minuti per risolvere ogni dubbio.<br>
      <a class="btn btn-xs btn-primary mt-2" href="https://zcal.co/danielebiancardi" target="_blank"><i
          class="far fa-calendar-clock mr-1"></i>Prenota ora il tuo meet</a>
    </div>
  </div>
</div>

<form #myForm="ngForm" validate>

  <ng-container
    *ngFor="let configuration of configurations; let index_configuration = index; let first_configuration = first;">

    <div class="card" *ngIf="!(loading) && !error">
      <!-- Header -->
      <div class="card-header p-1">
        <div class="row justify-content-between align-items-center flex-grow-1">
          <div class="col">
            <tabset [justified]="true" type="pills">
              <tab #tab1="tab">
                <ng-template tabHeading>
                  <i class="fas fa-table-layout nav-icon"></i> {{ 'MONITOR.monitor.tab_griglia' | translate }}
                </ng-template>
              </tab>
              <tab #tab2="tab" [disabled]="!configuration.layout">
                <ng-template tabHeading>
                  <i class="fas fa-puzzle nav-icon"></i> {{ 'MONITOR.monitor.tab_widget' | translate }}
                </ng-template>
              </tab>
              <tab #tab3="tab" [disabled]="!configuration.layout">
                <ng-template tabHeading>
                  <i class="far fa-square-sliders-vertical nav-icon"></i> {{ 'MONITOR.monitor.tab_settings' | translate
                  }}
                </ng-template>
              </tab>
              <!-- <tab #tab4="tab">
                <ng-template tabHeading>
                  <i class="fas fa-calendar-clock nav-icon"></i> {{ 'MONITOR.monitor.tab_schedule' | translate }}
                </ng-template>
              </tab> -->
            </tabset>
          </div>
        </div>
      </div>

      <!--
      .##..........###....##....##..#######..##.....##.########
      .##.........##.##....##..##..##.....##.##.....##....##...
      .##........##...##....####...##.....##.##.....##....##...
      .##.......##.....##....##....##.....##.##.....##....##...
      .##.......#########....##....##.....##.##.....##....##...
      .##.......##.....##....##....##.....##.##.....##....##...
      .########.##.....##....##.....#######...#######.....##...
      -->
      <div class="card-body" *ngIf="tab1.active">
        <div class="row">
          <div class="col-12">
            <h3>Seleziona la griglia</h3>
            <p>Scegli uno dei layout disponibile e definisci in che modo visualizzerai le informazioni sui monitor della tua scuola.<br>
              Se hai problemi a farlo <a href="https://youtu.be/ZAZrEbIrV3U" target="_blank">guarda il tutorial</a> che spiega come farlo.</p>
          </div>
        </div>
        <div class="row mt-3">
          <ng-container *ngFor="let layout of monitorLayouts">
            <div class="col-auto" (click)="selectLayout(configuration, layout)">
              <div class="card monitor-grid-wrapper" [ngStyle]="layout.style"
                [ngClass]="{'selected': layout.id == configuration.layout.id}">
                <ng-container *ngFor="let item of layout.widgets">
                  <div class="card monitor-grid-item" [ngStyle]="item.style">{{item.id}}</div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!--
      .##......##.####.########...######...########.########
      .##..##..##..##..##.....##.##....##..##..........##...
      .##..##..##..##..##.....##.##........##..........##...
      .##..##..##..##..##.....##.##...####.######......##...
      .##..##..##..##..##.....##.##....##..##..........##...
      .##..##..##..##..##.....##.##....##..##..........##...
      ..###..###..####.########...######...########....##...
      -->
      <div class="card-body" *ngIf="tab2.active">
        <div class="row">
          <div class="col-12">
            <h3>Aggiungi o modifica un widget</h3>
            <p>Seleziona l'area della griglia di tuo interesse con un clic ed inserisci un widget che hai scelto, o modificane uno già presente.<br>
              Se hai problemi a farlo guarda il tutorial per settare uno dei widget di Alfred: <a href="https://youtu.be/h0dXP7ARQFw" target="_blank">Youtube</a>, <a href="https://youtu.be/6aDt9FMmwO0" target="_blank">Playlist Youtube</a>, <a href="https://youtu.be/Hp5JDAqI6Y4" target="_blank">Google Presentazioni</a>, <a href="https://youtu.be/vHu13ojvKLQ" target="_blank">Testo</a>, <a href="https://youtu.be/EdOzgopOvvI" target="_blank">Google Calendar</a>, <a href="https://youtu.be/E6A80U33w3o" target="_blank">Feed Rss</a>, <a href="https://youtu.be/hkbqpJ8Xo64" target="_blank">Presentazione Canva</a>.</p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-auto">
            <div class="card monitor-grid-wrapper-large" [ngStyle]="configuration.layout.style">
              <ng-container *ngFor="let item of configuration.layout.widgets; let index = index;">

                <ng-container
                  *ngIf="configuration.widgets[index] && configuration.widgets[index].config; else emptyWidgetPosition">
                  <div class="card monitor-grid-item-large"
                    (click)="configureWidgetPosition(configuration, configuration.widgets[index], index)"
                    [ngStyle]="configuration.widgets[index].style">
                    <!-- <div class="{{configuration.widgets[index].class}}"
                      [innerHTML]="configuration.widgets[index].config?.embedTrustHtml"></div> -->
                  </div>
                </ng-container>
                <ng-template #emptyWidgetPosition>
                  <div class="card monitor-grid-item-large"
                    (click)="configureEmptyWidgetPosition(configuration, item, index)" [ngStyle]="item.style">
                    {{item.id}}</div>
                </ng-template>

              </ng-container>
            </div>
          </div>
        </div>

      </div>

      <!--
      ..######..########.########.########.####.##....##..######....######.
      .##....##.##..........##.......##.....##..###...##.##....##..##....##
      .##.......##..........##.......##.....##..####..##.##........##......
      ..######..######......##.......##.....##..##.##.##.##...####..######.
      .......##.##..........##.......##.....##..##..####.##....##........##
      .##....##.##..........##.......##.....##..##...###.##....##..##....##
      ..######..########....##.......##....####.##....##..######....######.
      -->

      <div class="card-body" *ngIf="tab3.active">
        <div class="row">
          <div class="col-12">
            <h3>Impostazioni avanzate</h3>
          </div>
        </div>
        <!-- <div class="row mt-3">
          <div class="col-12">
            <label class="input-label" for="bar_message">Messaggio su barra</label>
            <input type="text" placeholder="Scrivi un messaggio da mostrare sulla barra" name="bar_message" class="form-control"
              [(ngModel)]="configuration.options.bar_message">
          </div>
        </div> -->

        <div class="row mt-3">
          <div class="col-12">
            <label class="toggle-switch d-flex" for="showBar">
              <input id="showBar" [(ngModel)]="configuration.options.bar_show" name="showBar" type="checkbox"
                class="toggle-switch-input">
              <span class="toggle-switch-label">
                <span class="toggle-switch-indicator"></span>
              </span>
              <span class="toggle-switch-content">
                <span class="d-block">Mostra la barra di stato</span>
                <small class="d-block text-muted">In basso viene mostrata una barra con logo, nome del monitor e
                  orologio.</small>
              </span>
            </label>
          </div>
        </div>

      </div>

      <!--
      ..######...######..##.....##.########.########..##.....##.##.......####.##....##..######..
      .##....##.##....##.##.....##.##.......##.....##.##.....##.##........##..###...##.##....##.
      .##.......##.......##.....##.##.......##.....##.##.....##.##........##..####..##.##.......
      ..######..##.......#########.######...##.....##.##.....##.##........##..##.##.##.##...####
      .......##.##.......##.....##.##.......##.....##.##.....##.##........##..##..####.##....##.
      .##....##.##....##.##.....##.##.......##.....##.##.....##.##........##..##...###.##....##.
      ..######...######..##.....##.########.########...#######..########.####.##....##..######..
      -->
      <!-- <div class="card-body" *ngIf="tab4.active">

        <div class="row">
          <div class="col-12">
            <h3>Programma quando visualizzare questa configurazione</h3>
            <small>Se ci dovessero essere sovrapposizioni, verrà scelta una configurazione casuale</small>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <label class="input-label">Giorno della settimana</label>
            <p-selectButton name="days{{index_configuration}}" [options]="configDays" [(ngModel)]="configuration.days"
              [multiple]="true" optionLabel="name"></p-selectButton>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-6">
            <label class="input-label">Ora inizio</label>
            <p-calendar name="startTime{{index_configuration}}" [(ngModel)]="configuration.startTime"
              [defaultDate]="defaultStartTime" [showTime]="true" [timeOnly]="true" [inline]="false"
              [maxDate]="configuration.endTime" [hourFormat]="24">
            </p-calendar>
          </div>

          <div class="col-6">
            <label class="input-label">Ora fine</label>
            <p-calendar name="endTime{{index_configuration}}" [(ngModel)]="configuration.endTime"
              [defaultDate]="defaultEndTime" [showTime]="true" [timeOnly]="true" [inline]="false"
              [minDate]="configuration.startTime" [hourFormat]="24"></p-calendar>
          </div>
        </div>

      </div> -->

      <div class="card-footer border-0 mx-3 pt-0">
        <div class="row" *ngIf="configuration.changed == true">
          <div class="col-12">
            <div class="alert alert-warning">
              <strong>Configurazione modificata</strong><br>
              Premi il tasto aggiorna per applicare ed aggiornare tutti i monitor automaticamente.
            </div>
          </div>
        </div>
        <div class="row justify-content-between align-items-center">
          <div class="col-auto">
            <button *ngIf="id" [routerLink]="['/monitor']" type="button" class="btn btn-white mr-3">
              <i class="far fa-chevron-left mr-1"></i> Annulla
            </button>
            <button (click)="resetConfiguration(index_configuration)" type="button" class="btn btn-outline-danger">
              <i class="fas fa-delete-left mr-1"></i> Riparti da zero
            </button>
            <button *ngIf="!first_configuration" (click)="removeConfiguration(index_configuration)" type="button"
              class="btn btn-danger">
              <i class="fas fa-trash mr-1"></i> Rimuovi
            </button>
          </div>
          <div class="col-6 py-1">
            <div class="row justify-content-end">
              <button type="button" (click)="onSubmit()" class="btn" [disabled]="!myForm.form.valid"
                [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                <span *ngIf="id">Aggiorna</span>
                <span *ngIf="!id">Salva</span>
                <i class="far fa-chevron-right ml-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>

  <!-- <a *ngIf="!(loading) && !error" class="card card-hover-shadow card-dashed h-100 mt-5"
    (click)="aggiungiConfigurazione()">
    <div class="card-body card-body-centered card-dashed-body">
      <i class="far fa-table-layout mb-2 blue" style="font-size: 4rem;"></i>
      <span class="text-primary text-hover-primary">
        <i class="fa-solid fa-plus mr-1"></i>Aggiungi una nuova configurazione
      </span>
    </div>
  </a> -->


  <!--
  .####.##....##.########..#######..########..##.....##....###....########.####..#######..##....##.####
  ..##..###...##.##.......##.....##.##.....##.###...###...##.##........##...##..##.....##.###...##..##.
  ..##..####..##.##.......##.....##.##.....##.####.####..##...##......##....##..##.....##.####..##..##.
  ..##..##.##.##.######...##.....##.########..##.###.##.##.....##....##.....##..##.....##.##.##.##..##.
  ..##..##..####.##.......##.....##.##...##...##.....##.#########...##......##..##.....##.##..####..##.
  ..##..##...###.##.......##.....##.##....##..##.....##.##.....##..##.......##..##.....##.##...###..##.
  .####.##....##.##........#######..##.....##.##.....##.##.....##.########.####..#######..##....##.####
  -->

  <div class="card mt-3" *ngIf="!(loading) && !error">
    <!-- Header -->
    <div class="card-header">
      <h5 class="card-header-title">
        <i class="fad fa-tv-retro nav-icon blue mr-1"></i>{{ 'MONITOR.monitor.tab_info' | translate }}
      </h5>
    </div>

    <div class="card-body">

      <div class="row">

        <!-- Nome -->
        <div class="col-6">
          <label class="input-label" for="name">Nome</label>
          <input id="name" name="name" #name="ngModel" class="form-control" [(ngModel)]="data.name" required
            [ngClass]="(name.dirty || name.touched) ? (name.valid ? 'is-valid': 'is-invalid') : 'untouched'" type="text"
            required maxlength="255">

          <div class="invalid-feedback" *ngIf="name.invalid && (name.dirty || name.touched)">
            <span>Non valido</span>
          </div>
          <span class="valid-feedback" *ngIf="name.valid && (name.dirty || name.touched)">Valido</span>
          <small class="d-block text-muted mt-2">Il nome viene visualizzato sulla barra di stato</small>
        </div>

        <!-- Descrizione -->
        <div class="col-6">
          <label class="input-label" for="name">Descrizione</label>
          <input id="description" name="description" #description="ngModel" class="form-control"
            [(ngModel)]="data.description"
            [ngClass]="(description.dirty || description.touched) ? (description.valid ? 'is-valid': 'is-invalid') : 'untouched'"
            type="text" maxlength="255">

          <div class="invalid-feedback" *ngIf="description.invalid && (description.dirty || description.touched)">
            <span>Non valido</span>
          </div>
          <span class="valid-feedback"
            *ngIf="description.valid && (description.dirty || description.touched)">Valido</span>
            <small class="d-block text-muted mt-2">La descrizione è utilizzata internamente per distinguere i vari monitor se ne hai più di uno.</small>
        </div>
      </div>

    </div>

    <div class="card-footer border-0 mx-3">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 py-1">
          <div class="row justify-content-end">
            <button type="button" (click)="onSubmit()" class="btn" [disabled]="!myForm.form.valid"
              [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
              <span *ngIf="id">Aggiorna</span>
              <span *ngIf="!id">Salva</span>
              <i class="far fa-chevron-right ml-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</form>

<!--
        .##.....##..#######..########.....###....##.......########....##......##.####.########...######...########.########
        .###...###.##.....##.##.....##...##.##...##.......##..........##..##..##..##..##.....##.##....##..##..........##...
        .####.####.##.....##.##.....##..##...##..##.......##..........##..##..##..##..##.....##.##........##..........##...
        .##.###.##.##.....##.##.....##.##.....##.##.......######......##..##..##..##..##.....##.##...####.######......##...
        .##.....##.##.....##.##.....##.#########.##.......##..........##..##..##..##..##.....##.##....##..##..........##...
        .##.....##.##.....##.##.....##.##.....##.##.......##..........##..##..##..##..##.....##.##....##..##..........##...
        .##.....##..#######..########..##.....##.########.########.....###..###..####.########...######...########....##...
        -->
<p-dialog [(visible)]="showModalAddWidget" [modal]="true" [style]="{'min-width': '50vw', 'max-width': '90vw'}">
  <ng-template pTemplate="header">
    Seleziona il widget da aggiungere
  </ng-template>

  <div class="row">
    <ng-container *ngFor="let widget of widgetsList">
      <div class="col-4">
        <div class="card mb-3">
          <div class="row no-gutters">
            <div class="col">
              <div class="rounded" style="min-width: 100%; min-height: 100%;" [ngStyle]="widget.style"></div>
            </div>
            <div class="col-8">
              <div class="card-body">
                <h5 class="card-title" [innerHTML]="'MONITOR.widgets.'+widget.id+'.title' | translate"></h5>
                <p class="card-text" [innerHTML]="'MONITOR.widgets.'+widget.id+'.description' | translate"></p>
                <button type="button" class="btn btn-primary btn-block"
                  (click)="configureWidgetPosition(selectedConfiguration, widget, selectedWidgetPosition)">Usa il
                  widget</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</p-dialog>

<!--
        .##.....##..#######..########.....###....##.......########.....######...#######..##....##.########.####..######..
        .###...###.##.....##.##.....##...##.##...##.......##..........##....##.##.....##.###...##.##........##..##....##.
        .####.####.##.....##.##.....##..##...##..##.......##..........##.......##.....##.####..##.##........##..##.......
        .##.###.##.##.....##.##.....##.##.....##.##.......######......##.......##.....##.##.##.##.######....##..##...####
        .##.....##.##.....##.##.....##.#########.##.......##..........##.......##.....##.##..####.##........##..##....##.
        .##.....##.##.....##.##.....##.##.....##.##.......##..........##....##.##.....##.##...###.##........##..##....##.
        .##.....##..#######..########..##.....##.########.########.....######...#######..##....##.##.......####..######..
        -->
<p-dialog [(visible)]="showModalConfigureWidget" [modal]="true" [style]="{'min-width': '50vw', 'max-width': '90vw'}">
  <ng-template pTemplate="header">
    Configura il widget
  </ng-template>

  <ng-container *ngIf="selectedWidgetPositionConfiguration">

    <div class="row" [ngSwitch]="selectedConfiguration.widgets[selectedWidgetPosition].id">

      <div class="col-12" *ngSwitchCase="'youtube-single-video'">
        <label class="input-label" for="url">Youtube Video URL</label>
        <input type="text" placeholder="https://www.youtube.com/" name="url" class="form-control"
          [(ngModel)]="selectedWidgetPositionConfiguration.url">
      </div>

      <div class="col-12" *ngSwitchCase="'youtube-playlist-video'">
        <label class="input-label" for="url">Youtube Playlist URL</label>
        <input type="text" placeholder="https://www.youtube.com/" name="url" class="form-control"
          [(ngModel)]="selectedWidgetPositionConfiguration.url">
      </div>

      <div class="col-12" *ngSwitchCase="'google-slide'">
        <label class="input-label" for="url">Google Slide URL</label>
        <input type="text" placeholder="https://docs.google.com/presentation/" name="url" class="form-control"
          [(ngModel)]="selectedWidgetPositionConfiguration.url">
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="url">Intervallo tra una slide e l'altra (secondi)</label>
            <input type="number" placeholder="1" name="interval" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.interval">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="refresh_after_hours">Forza aggiornamento widget</label>
            <input type="number" placeholder="numero di ore" name="refresh_after_hours" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.refresh_after_hours">
            <p class="small text-muted mt-2">Scatena l'aggiornamento del widget dopo un certo numero di ore da quando è
              stato caricato la prima volta.</p>
          </div>
        </div>
      </div>
      <div class="col-12" *ngSwitchCase="'calendario'">
        <div class="row">
          <div class="col">
            <label class="input-label" for="url">ID Calendario Google</label>
            <input type="text" placeholder="@group.calendar.google.com" name="url" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.url">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="display_max_num">Numero di eventi da mostrare (dal più recente)</label>
            <input type="number" placeholder="1" name="display_max_num" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.display_max_num">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="zoom">Zoom del widget (%)</label>
            <div class="row align-items-center">
              <div class="col-10 px-5"><p-slider [(ngModel)]="selectedWidgetPositionConfiguration.options.zoom" [step]="10" [min]="50"
                  [max]="500"></p-slider></div>
              <div class="col"><input type="number" [readOnly]="true" placeholder="100" name="zoom" class="form-control"
                  [(ngModel)]="selectedWidgetPositionConfiguration.options.zoom"></div>
            </div>
            <p class="small text-muted mt-2">Utilizzare lo zoom su schermi con risoluzioni molto grandi (TV 4k), dove la
              lettura risulta difficile da certe distanze. Il valore standard è 100%.</p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="refresh_after_hours">Forza aggiornamento widget</label>
            <input type="number" placeholder="numero di ore" name="refresh_after_hours" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.refresh_after_hours">
            <p class="small text-muted mt-2">Scatena l'aggiornamento del widget dopo un certo numero di ore da quando è
              stato caricato la prima volta.</p>
          </div>
        </div>
      </div>
      <div class="col-12" *ngSwitchCase="'feed'">
        <div class="row">
          <div class="col">
            <label class="input-label" for="url">Feed RSS URL</label>
            <input type="text" placeholder="https://www.tuosito.com/rss" name="url" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.url">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="url">Numero di news da mostrare (dalla più recente)</label>
            <input type="number" placeholder="1" name="display_max_num" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.display_max_num">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="zoom">Zoom del widget (%)</label>
            <div class="row align-items-center">
              <div class="col-10 px-5"><p-slider [(ngModel)]="selectedWidgetPositionConfiguration.options.zoom" [step]="10" [min]="50"
                  [max]="500"></p-slider></div>
              <div class="col"><input type="number" [readOnly]="true" placeholder="100" name="zoom" class="form-control"
                  [(ngModel)]="selectedWidgetPositionConfiguration.options.zoom"></div>
            </div>
            <p class="small text-muted mt-2">Utilizzare lo zoom su schermi con risoluzioni molto grandi (TV 4k), dove la
              lettura risulta difficile da certe distanze. Il valore standard è 100%.</p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="refresh_after_hours">Forza aggiornamento widget</label>
            <input type="number" placeholder="numero di ore" name="refresh_after_hours" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.refresh_after_hours">
            <p class="small text-muted mt-2">Scatena l'aggiornamento del widget dopo un certo numero di ore da quando è
              stato caricato la prima volta.</p>
          </div>
        </div>
      </div>

      <div class="col-12" *ngSwitchCase="'canva'">
        <label class="input-label" for="url">Canva incorpora URL</label>
        <input type="text" placeholder="https://www.canva.com/design/" name="url" class="form-control"
          [(ngModel)]="selectedWidgetPositionConfiguration.url">
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="url">Intervallo tra una slide e l'altra (secondi)</label>
            <input type="number" placeholder="1" name="interval" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.interval">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="url">Numero di slide da visualizzare</label>
            <input type="number" placeholder="1" name="interval" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.max_slide">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="refresh_after_hours">Forza aggiornamento widget</label>
            <input type="number" placeholder="numero di ore" name="refresh_after_hours" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.refresh_after_hours">
            <p class="small text-muted mt-2">Scatena l'aggiornamento del widget dopo un certo numero di ore da quando è
              stato caricato la prima volta.</p>
          </div>
        </div>
      </div>

      <div class="col-12" *ngSwitchCase="'testo'">
        <label class="input-label" for="url">Testo</label>
        <ng-container *ngFor="let line of selectedWidgetPositionConfiguration.lines; let index = index;">
          <div class="row align-items-center mt-2">
            <div class="col">
              <textarea rows="1" placeholder="Scrivi il testo da visualizzare" name="line" class="form-control"
                [(ngModel)]="line.text"></textarea>
            </div>
            <div class="col-auto pl-0">
              <button (click)="configWidgetTestoRemoveLinea(selectedWidgetPositionConfiguration.lines, index)"
                type="button" class="btn btn-outline-danger">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
        </ng-container>
        <button (click)="configWidgetTestoAggiungiLinea(selectedWidgetPositionConfiguration.lines)"
          class="btn btn-block btn-sm btn-outline-primary mt-2">Aggiungi testo</button>
        <div class="row mt-3" *ngIf="selectedWidgetPositionConfiguration.lines.length > 1">
          <div class="col">
            <label class="input-label" for="url">Intervallo tra un testo e l'altro (secondi)</label>
            <input type="number" placeholder="1" name="interval" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.interval">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="zoom">Zoom del widget (%)</label>
            <div class="row align-items-center">
              <div class="col-10 px-5"><p-slider [(ngModel)]="selectedWidgetPositionConfiguration.options.zoom" [step]="10" [min]="50"
                  [max]="500"></p-slider></div>
              <div class="col"><input type="number" [readOnly]="true" placeholder="100" name="zoom" class="form-control"
                  [(ngModel)]="selectedWidgetPositionConfiguration.options.zoom"></div>
            </div>
            <p class="small text-muted mt-2">Utilizzare lo zoom su schermi con risoluzioni molto grandi (TV 4k), dove la
              lettura risulta difficile da certe distanze. Il valore standard è 100%.</p>
          </div>
        </div>
      </div>

      <div class="col-12" *ngSwitchCase="'immagine'">
        <label class="input-label" for="url">Immagine URL</label>
        <ng-container *ngFor="let image of selectedWidgetPositionConfiguration.images; let index = index;">
          <div class="row align-items-center mt-2">
            <div class="col" *ngIf="image.type == 'href'">
              <textarea rows="1" placeholder="https://picsum.photos/2000" name="image" class="form-control"
                [(ngModel)]="image.url"></textarea>
            </div>
            <div class="col" *ngIf="image.type == 'google-drive'">
              <b class="d-block"><i *ngIf="!image.loading" class="fa-brands fa-google-drive mr-1"></i>
                <div *ngIf="image.loading" class="spinner spinner-sm mr-2 text-warning" role="status"><span
                    class="sr-only">Loading...</span></div>{{image.name}}
              </b>
              <small *ngIf="image.doc">{{ image.doc.type }} {{(image.doc.sizeBytes / 1024) | number:'1.2-2' }}Kb <span
                  *ngIf="image.info?.imageMediaMetadata">{{image.info.imageMediaMetadata.width}}x{{image.info.imageMediaMetadata.height}}px</span></small>
              <small *ngIf="image.error" class="text-danger d-block"> {{image.error}}</small>
            </div>
            <div class="col-auto pl-0">
              <button (click)="configWidgetImmagineRemoveLinea(selectedWidgetPositionConfiguration.images, index)"
                type="button" class="btn btn-outline-danger">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
        </ng-container>
        <div class="row align-items-center mt-2">
          <div class="col">
            <button (click)="configWidgetImmagineAggiungiLinea(selectedWidgetPositionConfiguration.images)"
              class="btn btn-block btn-sm btn-outline-primary"><i class="fa-solid fa-image mr-2"></i>Aggiungi immagine
              da URL</button>
          </div>
          <div class="col">
            <button (click)="configWidgetImmagineAggiungiLineaGoogleDrive(selectedWidgetPositionConfiguration.images)"
              class="btn btn-block btn-sm btn-outline-primary"><i class="fa-brands fa-google-drive mr-2"></i>Aggiungi
              immagine da Google Drive</button>
          </div>
        </div>

        <div class="row mt-3" *ngIf="selectedWidgetPositionConfiguration.images.length > 1">
          <div class="col">
            <label class="input-label" for="url">Intervallo tra un'immagine e l'altra (secondi)</label>
            <input type="number" placeholder="1" name="interval" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.interval">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="refresh_after_hours">Forza aggiornamento widget</label>
            <input type="number" placeholder="numero di ore" name="refresh_after_hours" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.refresh_after_hours">
            <p class="small text-muted mt-2">Scatena l'aggiornamento del widget dopo un certo numero di ore da quando è
              stato caricato la prima volta.</p>
          </div>
        </div>
      </div>

      <div class="col-12" *ngSwitchCase="'powerpoint'">
        <label class="input-label" for="url">Presentazione PowerPoint</label>
        <textarea type="text" placeholder="Codice di incorporamento" rows="3" name="embedText" class="form-control"
          [(ngModel)]="selectedWidgetPositionConfiguration.embedText"></textarea>
        <div class="row mt-3">
          <div class="col">
            <label class="input-label" for="refresh_after_hours">Forza aggiornamento widget</label>
            <input type="number" placeholder="numero di ore" name="refresh_after_hours" class="form-control"
              [(ngModel)]="selectedWidgetPositionConfiguration.options.refresh_after_hours">
            <p class="small text-muted mt-2">Scatena l'aggiornamento del widget dopo un certo numero di ore da quando è
              stato caricato la prima volta.</p>
          </div>
        </div>
      </div>

      <div class="col-12" *ngSwitchDefault>
        <label class="input-label" for="url">Link/Url (accessibile pubblicamente)</label>
        <input type="text" name="url" placeholder="https://..." class="form-control"
          [(ngModel)]="selectedWidgetPositionConfiguration.url">
      </div>
    </div>

    <!-- Usata per Testing
    <div class="row mt-3">
      <div class="col-12">
        <label class="input-label" for="embed">Embed</label>
        <textarea name="embed" class="form-control"
          [(ngModel)]="selectedWidgetPositionConfiguration.embedText"></textarea>
      </div>
    </div> -->

  </ng-container>

  <div class="row mt-3">
    <div class="col-12">
      <div class="alert alert-soft-info">
        <strong>Hai bisogno di aiuto?</strong><br>
        Guarda tutti i nostri video tutorial su Alfred per capire come scegliere il layout adatto, come configurare i widget e come pubblicare le informazioni.<br>
        <a class="btn btn-xs btn-primary mt-2" href="https://www.youtube.com/watch?v=KhmLCf0t9zI&list=PLCt3ItsLXF5zF1o_2w8vqFY1H90OFDHxH" target="_blank"><i
            class="fab fa-youtube mr-1"></i>Guarda i video ora!</a>
      </div>
    </div>
  </div>


  <ng-template pTemplate="footer">
    <div class="row justify-content-between align-items-center">
      <div class="col-auto">
        <button (click)="annullaModalConfigureWidget()" type="button" class="btn btn-white">
          <i class="far fa-chevron-left mr-1"></i> Annulla
        </button>
        <button (click)="replaceModalConfigureWidget(selectedConfiguration)" type="button" class="btn btn-white">
          <i class="fas fa-repeat mr-1"></i> Sostituisci
        </button>
        <button (click)="removeModalConfigureWidget(selectedConfiguration)" type="button"
          class="btn btn-outline-danger">
          <i class="fas fa-trash mr-1"></i> Rimuovi
        </button>
      </div>
      <div class="col-6 py-1">
        <div class="row justify-content-end">
          <button (click)="salvaModalConfigureWidget(selectedConfiguration)" type="button" class="btn btn-primary">
            Salva<i class="far fa-chevron-right ml-1"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>
