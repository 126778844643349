import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { PageService } from 'src/app/services/page.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-plesso-profile',
  templateUrl: './plesso-profile.component.html',
  styleUrls: ['./plesso-profile.component.scss']
})
export class PlessoProfileComponent implements OnInit {

  @Input() id: any;
  showForm: boolean;
  showDelete: boolean;
  showMappa: boolean;
  showData: boolean;

  private _activeRouteSubscription: Subscription;
  private _plessoServiceSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private utilityService: UtilityService,
    private activeRoute: ActivatedRoute,
    private plessoService: PlessoService,
    private pageService: PageService
    ) { }

  ngOnInit() {

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_plesso;
      this.init();
    });

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.updateData();
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();

    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();
  }

  init() {

    this.showForm = false;
    this.showDelete = false;
    this.showMappa = false;
    this.showData = false;

    if (this.id && this.id > 0) {

      this.showForm = this.utilityService.checkRouteActionType(this.route, "edit");
      this.showDelete = this.utilityService.checkRouteActionType(this.route, "delete");

      if (!this.showForm && !this.showDelete)
        this.showData = true;

      this.updateData();
    }
    else {

      this.showMappa = this.utilityService.checkRouteActionType(this.route, "mappa");
      this.showForm = this.utilityService.checkRouteActionType(this.route, "create");;
    }
  }

  updateData() {
    if (this.id && this.id > 0) {
      this.plessoService.get(this.id, (data) => {
        this.pageService.updatePageTitle({ plesso: data.name });
      }, (error) => {
        this.plessoService.all();
      });
    }
  }
}
