import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-card-loading-error',
  templateUrl: './card-loading-error.component.html',
  styleUrls: ['./card-loading-error.component.scss']
})
export class CardLoadingErrorComponent implements OnInit {

  @Input() error;
  @Output() retryClicked: EventEmitter<any> = new EventEmitter();

  constructor(private accountService: AccountService, private router: Router) { }

  ngOnInit() {

  }

  retry() {
    this.retryClicked.emit();
  }

  logout() {

    this.accountService.logout(() => {
      this.router.navigate(['/login']);
    });
  }

}
