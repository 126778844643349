import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { RemoveMembersFromClasseService } from 'src/app/services/operations/remove-members-from-classe.service';

@Component({
  selector: 'app-remove-members-from-classe',
  templateUrl: './remove-members-from-classe.component.html',
  styleUrls: ['./remove-members-from-classe.component.scss']
})
export class RemoveMembersFromClasseComponent implements OnInit {
  regEx = ConfigService.regExRemove;
  error: any;
  _loadingData = {
    members: false,
    memberUser: false,
    memberGroup: false
  };

  formLoading: boolean = false;

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  plessi: any = [];
  data: any = [];
  members: any = [];
  model: any;

  operationInProgress: boolean = false;
  errorNoGoogleAccount: boolean = false;

  private _removeMembersFromClasseSubscription: Subscription;
  private _plessoServiceSubscription: Subscription;
  private _userServiceSubscription: Subscription;
  private _googleUserServiceSubscription: Subscription;
  private _googleGroupServiceSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private removeMembersFromClasseService: RemoveMembersFromClasseService,
    private plessoService: PlessoService,
    private userService: UserService,
    private googleService: GoogleDirectoryService,
  ) { }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.members = this.removeMembersFromClasseService.items ? this.removeMembersFromClasseService.items : [];
    this.data = this.parseMembersResult(this.members);
  }

  parseUsersResult(result) {
    let table = [];
    if (result && result.length > 0)
      result.forEach(element => {
        let user = this.getInfoUser(element);

        let classeInfo = this.getInfoClasse({ id: element.classeId })

        table.push({
          "nome": [user.fullname, user.email],
          "ruoli": user.role,
          "classe": [classeInfo.name, classeInfo.plesso?.name],
          "_export_data": {
            name: user.fullname,
            email: user.email
          },
        });

      });

    this._loadingData.memberUser = false;

    return table;
  }

  parseMembersResult(result) {
    let memberUserData = this.parseUsersResult(result);
    return memberUserData;
  }

  deleteEvent(rows) {
    this.removeMembersFromClasseService.deleteItemsAtIndex(rows);
  }

  onSubmit() {
    this.formLoading = true;

    this.operationInProgress = true;

    this.removeMembersFromClasseService.execOperation((error) => {
      this.formLoading = false;
    });
  }

  resetOperation() {
    this.removeMembersFromClasseService.reset();
  }

  unsubscibeAll() {

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleUserServiceSubscription)
      this._googleUserServiceSubscription.unsubscribe();

    if (this._removeMembersFromClasseSubscription)
      this._removeMembersFromClasseSubscription.unsubscribe();

    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();
  }

  init() {
    this.unsubscibeAll();

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.plessi = result;
        this.dataUpdated();
      }
    });

    this._removeMembersFromClasseSubscription = this.removeMembersFromClasseService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.userService.all();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.plessoService.all();
      }
    });

    this._googleUserServiceSubscription = this.googleService.getSubjectToUpdateUsersObservable().subscribe((result) => {
      if (result) {
        this.userService.all();
      }
    });

    this.googleService.usersList();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  getInfoUser(userInfo) {
    let resUser = {
      userId: null,
      googleId: null,
      fullname: null,
      email: userInfo?.email,
      is_google_user: false,
      is_geniusuite_user: false,
      role: userInfo?.role ? userInfo?.role : [],
      status: userInfo?.status,
      type: userInfo?.type
    };

    let googleUser = this.googleService.getUserByEmail(resUser.email);

    // Controllo più dettagliato prima che l'email dell'utente sia stata modificata
    let user = googleUser ? this.userService.getByGoogleId(googleUser.id) : this.userService.getByEmail(resUser.email);

    if (user) {
      // Sincronizzato con Geniusuite
      resUser.userId = user.user.id;
      resUser.fullname = user.user.familyName + " " + user.user.givenName;
      resUser.email = user.user.email;
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = true;

      if (user.google_user) {
        resUser.googleId = user.google_user.id;

        if (user.google_user.name) {
          resUser.fullname = user.google_user.name?.familyName + " " + user.google_user.name?.givenName;
        }

        resUser.email = user.google_user.primaryEmail
        resUser.is_google_user = true;
      }

      if (user.user.roles) {
        resUser.role = [];
        user.user.roles.forEach(ruolo => {
          resUser.role.push(this.translate.instant('STR.' + ruolo.name));
        });
      }

    } else if (googleUser) {
      // Non sincronizzato con Geniusuite ma non ha già i dati caricati
      resUser.fullname = "-";
      resUser.googleId = googleUser.id;

      if (googleUser.name) {
        resUser.fullname = googleUser.name?.familyName + " " + googleUser.name?.givenName;
      }

      resUser.email = googleUser.primaryEmail;
      resUser.is_google_user = true;
      resUser.is_geniusuite_user = false;
    } else {
      resUser.fullname = "Utente Esterno";
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = false;
    }

    return resUser;
  }

  getInfoClasse(userInfo) {
    let res = {
      id: userInfo?.id,
      classe: null,
      name: null,
      plesso: null
    };

    for (let i = 0; i < this.plessi.length; i++) {
      for (let j = 0; j < this.plessi[i].classi.length; j++) {
        if (userInfo.id == this.plessi[i].classi[j].id) {
          res.plesso = this.plessi[i];
          res.classe = this.plessi[i].classi[j];
          res.name = res.classe.name;
          break;
        }
      }
    }

    return res;
  }
}
