import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class MoveUsersService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  items: any[];

  constructor(private backend: BackendService, private toast: ToastService, private utilityService: UtilityService) {
    this.reset();
  }

  reset() {
    this.items = [];
    this._subjectToUpdate.next(this.items);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  addUsers(newItems) {

    newItems.forEach(item => {
      let itemFound = this.items.find((element) => element.user?.id == item.user?.id);

      if (!itemFound) {
        this.items.push(item);
      }
    });

    this._subjectToUpdate.next(this.items);
  }

  deleteItemsAtIndex(indexArray) {

    indexArray.forEach(idx => {
      this.items[idx] = null;
    });

    this.items = this.items.filter((el) => { return el != null });

    this._subjectToUpdate.next(this.items);
  }

  execOperation(classe_id, callback = (error) => { }) {

    let users = [];

    this.items.forEach(user => {

      // aggiungo solo quelli che hanno un google user altrimenti il job potrebbe andare in errore
      // aggiungo solo quelli che hanno un ruolo scolastico
      if (user.google_user && user.user.roles) {
        let findRole = user.user.roles.findIndex(x => x.name == "google-teacher" || x.name == "google-student");

        if (findRole != -1) {
          users.push({
            id: user.user_id,
            classi_id: [
              {
                classe_id: classe_id,
                materie_id: []
              }
            ]
          });
        }
      }
    });
    
    this.backend.post("op/move-users", { users: users }).subscribe((result) => {
      this.reset();
      this.toast.success('Spostamento Utenti avviato');
      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }
}
