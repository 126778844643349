import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alfred-config',
  templateUrl: './alfred-config.component.html',
  styleUrls: ['./alfred-config.component.scss']
})
export class AlfredConfigComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
