<app-card-loading
  *ngIf="loadingOrganigramma || loadingGoogleOrgUnits || loadingGoogleGroups || loadingOrganigrammaService || loadingParameterService">
</app-card-loading>

<app-card-loading-error
  *ngIf="!(loadingOrganigramma || loadingGoogleOrgUnits || loadingGoogleGroups || loadingOrganigrammaService || loadingParameterService) && error"
  [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card card-hover-shadow"
  *ngIf="!(loadingOrganigramma || loadingGoogleOrgUnits || loadingGoogleGroups || loadingOrganigrammaService || loadingParameterService) && !error">

  <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>

  <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" validate>

    <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">


      <div class="row">
        <!-- Left -->
        <div class="col">

          <!-- Titlte -->
          <div class="row form-group">
            <div class="col-12">
              <h3>Organigramma</h3>
              <small>Informazioni dell'Organigramma che verrà eliminato</small>
            </div>
          </div>


          <!-- Info Card -->
          <div class="row form-group">
            <div class="col-sm-12">
              <ul class="list-unstyled list-unstyled-py-3 text-dark mb-3">
                <li class="py-0">
                  <small class="card-subtitle">Nome</small>
                </li>

                <li *ngIf="organigramma.name">
                  {{organigramma.name }}
                </li>

                <li class="py-0">
                  <small class="card-subtitle">Descrizione</small>
                </li>

                <li *ngIf="organigramma.description">
                  {{organigramma.description }}
                </li>

                <li *ngIf="!organigramma.description">
                  Nessuna descrizione
                </li>

                <li class="py-0">
                  <small class="card-subtitle">Unità Organizzativa</small>
                </li>

                <li *ngIf="organigramma.google_org_unit">
                  <span *ngIf="!organigramma.google_org_unit.not_found">{{organigramma.google_org_unit.name}}</span>
                  <span *ngIf="organigramma.google_org_unit.not_found">Non trovata su Google Workspace</span>
                </li>

                <li *ngIf="!organigramma.google_org_unit">
                  Nessuna Organizzazione Associata
                </li>

                <li class="py-0">
                  <small class="card-subtitle">Creato il</small>
                </li>

                <li>
                  {{organigramma.created_at | date:"medium" }}
                </li>
              </ul>
            </div>
          </div>


          <div class="row form-group">
            <div class="col-12">
              <button class="btn btn-outline-primary" type="button" (click)="changeStatusGroupGest();"
                [attr.aria-expanded]="showAdvancedSettings" aria-controls="collapseAdvancedSettings">
                Modifica impostazioni standard
                <span class="ml-2" [ngSwitch]="showAdvancedSettings">
                  <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                  <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                </span>
              </button>
            </div>
          </div>

          <div class="row form-group" id="collapseAdvancedSettings" [collapse]="!showAdvancedSettings"
            [isAnimated]="true">
            <div class="col-12">

              <!-- OrgUnit -->
              <div class="row form-group">
                <div class="col-12">
                  <h4>Unità organizzativa
                    <span class="badge badge-soft-warning"
                      *ngIf="!organigramma.google_org_unit || organigramma.google_org_unit.not_found">
                      Nessuna Unità Organizzativa Associta
                    </span>
                  </h4>
                  <label class="toggle-switch d-flex" for="dataDeleteOrgUnit">
                    <input id="dataDeleteOrgUnit" [ngModel]="dataDelete.orgUnit" name="dataDeleteOrgUnit"
                      #dataDeleteOrgUnit="ngModel" type="checkbox" class="toggle-switch-input"
                      (change)="changeDataValue('orgUnit')"
                      [disabled]="!organigramma.google_org_unit || organigramma.google_org_unit.not_found">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">Cancella Unità Organizzativa</span>
                    </span>
                  </label>
                </div>
              </div>

              <!-- User OrgUnit -->
              <ng-container *ngIf="dataDelete.orgUnit">
                <div class="row form-group">
                  <div class="col-12">
                    <label class="toggle-switch d-flex" for="dataDeleteOrgUnitUser">
                      <input id="dataDeleteOrgUnitUser" [ngModel]="dataDelete.orgUnitUser" name="dataDeleteOrgUnitUser"
                        #dataDeleteOrgUnitUser="ngModel" type="checkbox" class="toggle-switch-input"
                        (change)="changeDataValue('orgUnitUser')">
                      <span class="toggle-switch-label">
                        <span class="toggle-switch-indicator"></span>
                      </span>
                      <span class="toggle-switch-content">
                        <span class="d-block">Cancella Utenti sotto l'Unità Organizzativa</span>
                        <small class="d-block text-muted">Se è no seleziono Organigramma destinazione degli utenti
                        </small>
                      </span>
                    </label>
                  </div>
                </div>

                <div *ngIf="!dataDelete.orgUnitUser" class="row form-group">
                  <div class="col-sm-12">
                    <label class="input-label" for="organigrammaUserDestination">Sposta Utenti in un Organigramma
                      specifico</label>

                    <app-custom-dropdown-treeview [items]="organigrammiTreeView"
                      [(value)]="dataDelete.organigrammaUserDestination.id"
                      (selectedChange)="onSelectedChangeRelationInfo($event)" [configs]="configTreeView">
                    </app-custom-dropdown-treeview>
                    <input type="hidden" id="organigrammaUserDestination" name="organigrammaUserDestination"
                      [(ngModel)]="dataDelete.organigrammaUserDestination" required>

                    <span class="text-muted font-size-sm">
                      Scegli l'Organigramma in cui spostare gli Utenti
                    </span>
                  </div>
                </div>
              </ng-container>

              <!-- Organigramma Figli -->
              <div class="row form-group" *ngIf="organigramma.children && organigramma.children.length > 0">
                <div class="col-12">
                  <h4>Cancella gli Organigramma Figli</h4>
                  <label class="toggle-switch d-flex" for="dataDeleteOrganigrammaChildren">
                    <input id="dataDeleteOrganigrammaChildren" [ngModel]="dataDelete.subOrganigramma"
                      name="dataDeleteOrganigrammaChildren" #dataDeleteOrganigrammaChildren="ngModel" type="checkbox"
                      class="toggle-switch-input" (change)="changeDataValue('subOrganigramma')">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">Cancella gli Organigramma Figli</span>
                    </span>
                  </label>
                </div>
              </div>

              <!-- Gruppi -->
              <div class="row form-group">
                <div class="col-12">
                  <h4>Gruppi
                    <span class="badge badge-soft-warning"
                      *ngIf="!organigramma.google_group || organigramma.google_group.length == 0">
                      Nessun Gruppo Associto
                    </span>
                  </h4>
                  <label class="toggle-switch d-flex" for="dataDeleteGroup">
                    <input id="dataDeleteGroup" [ngModel]="dataDelete.group" name="dataDeleteGroup"
                      #dataDeleteGroup="ngModel" type="checkbox" class="toggle-switch-input"
                      (change)="changeDataValue('group')"
                      [disabled]="!organigramma.google_group || organigramma.google_group.length == 0">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">Cancella Gruppi</span>
                    </span>
                  </label>
                </div>
              </div>

              <!-- Classroom -->
              <div class="row form-group">
                <div class="col-12">
                  <h4>Classroom
                    <span class="badge badge-soft-warning"
                      *ngIf="!organigramma.google_classroom || organigramma.google_classroom.length == 0">
                      Nessuna Classroom Associta
                    </span>
                  </h4>
                  <label class="toggle-switch d-flex" for="dataDeleteClassroom">
                    <input id="dataDeleteClassroom" [ngModel]="dataDelete.classroom" name="dataDeleteClassroom"
                      #dataDeleteClassroom="ngModel" type="checkbox" class="toggle-switch-input"
                      (change)="changeDataValue('classroom')"
                      [disabled]="!organigramma.google_classroom || organigramma.google_classroom.length == 0">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">Cancella Classroom</span>
                    </span>
                  </label>
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- Right -->

        <div class="col-5 p-3 m-3 bg-light rounded-lg">
          <!-- Titlte -->
          <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Sincronizzazione con Google Workspace</h4>

          <div class="row">
            <div class="col-12">
              <div class="alert alert-soft-danger" role="alert">
                L'organigramma <b class="text-cap">{{organigramma?.name}}</b> verrà eliminato <b>definitivamente</b>
              </div>
              <div *ngIf="dataDelete.orgUnit && organigramma.google_org_unit && !organigramma.google_org_unit.not_found"
                class="alert alert-soft-danger" role="alert">
                Verrà cancellata l'Unità organizzativa <b class="text-cap">{{organigramma.google_org_unit.name}}</b>
                <span *ngIf="dataDelete.orgUnitUser"> e gli <b>Utenti</b> al suo interno verranno cancellati</span>
                <span *ngIf="!dataDelete.orgUnitUser && dataDelete.organigrammaUserDestination.id"> e gli <b>Utenti</b> verranno spostati dentro l'Organigramma
                  <b class="text-cap">{{dataDelete.organigrammaUserDestination.name}}</b>
                </span>
              </div>

              <div class="alert alert-soft-danger" role="alert"
                *ngIf="!dataDelete.subOrganigramma && organigramma.children && organigramma.children.length > 0">
                Gli Organigramma <b>figli</b> verrà spostati sotto l'Organigramma
                <b class="text-cap" *ngIf="organigrammaParent">{{organigrammaParent.name}}</b>
                <b class="text-cap" *ngIf="!organigrammaParent">Principale</b>
              </div>

              <div class="alert alert-soft-danger" role="alert" *ngIf="dataDelete.subOrganigramma">
                Gli Organigramma <b>figli</b> verranno eliminati <b>definitivamente</b>
              </div>

              <div *ngIf="dataDelete.orgUnit && dataDelete.subOrganigramma"
                class="alert alert-soft-danger" role="alert">
                Verranno cancellate le varie Unità organizzative collegate agli <b class="text-cap">Organigrammi figli</b>
                <span *ngIf="dataDelete.orgUnitUser"> e gli <b>Utenti</b> al suo interno verranno cancellati</span>
                <span *ngIf="!dataDelete.orgUnitUser && dataDelete.organigrammaUserDestination.id"> e gli <b>Utenti</b> verranno spostati dentro l'Organigramma
                  <b class="text-cap">{{dataDelete.organigrammaUserDestination.name}}</b>
                </span>
              </div>


              <div class="alert alert-soft-danger" role="alert"
                *ngIf="dataDelete.group && organigramma.google_group && organigramma.google_group.length > 0">
                I gruppi verranno eliminati <b>definitivamente</b>
              </div>

              <div class="alert alert-soft-danger" role="alert"
                *ngIf="dataDelete.classroom && organigramma.google_classroom && organigramma.google_classroom.length > 0">
                Le classroom verranno eliminati <b>definitivamente</b>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="card-footer border-0 mx-3">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto py-1">
          <button *ngIf="id" [routerLink]="['/organigramma/'+id]" type="button" class="btn btn-white">
            <i class="far fa-chevron-left mr-1"></i> Annulla
          </button>
        </div>

        <div class="col-6 py-1">
          <div class="row justify-content-end">
            <div class="col-auto">
              <input autocomplete="off" type="text" id="confirmation_delete" name="confirmation_delete" #confirmation_delete="ngModel"
                [ngModel]="''" class="form-control" [pattern]="regEx" required>
              <small class="d-block text-muted">Per eliminare, digita
                <span class="confirmation-phrase">Elimina</span></small>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn"
                [disabled]="!myForm.valid"
                [ngClass]="(myForm.dirty || myForm.touched)? 'btn-danger' : 'btn-outline-danger' ">
                Elimina <i class="far fa-chevron-right ml-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>
