<ng-container *ngFor="let activity of activityData">
  <div class="card card-hover-shadow mb-3 border-0">
    <!-- Header -->
    <div class="card-header bg-card-header">
      <h4 class="card-header-title text-white">
        <i class="far fa-clipboard-list-check mr-2"></i>
        {{ 'ACTIVITY.' + activity.job_batch_name + '.description' | translate }}
      </h4>
      <small class="text-white">{{ activity.perc_overall }}%</small>
    </div>
    <!-- End Header -->

    <!-- Body -->
    <div class="card-body">

      <p-progressBar [value]="activity.perc_overall" [showValue]="false"  [style]="{'height': '8px'}"></p-progressBar>

      <div class="alert alert-info mt-3 mb-0"
        [innerHTML]="'ACTIVITY.notifications.progress.msg_operation_activity' | translate"></div>

    </div>
    <!-- End Body -->

    <div class="card-footer">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <small class="text-body">Ultimo aggiornamento: {{ activity.last_update | date:"medium" }}</small>
        </div>

        <div class="col-auto">
          <button class="btn btn-xs btn-outline-primary" (click)="showActivitySidebar()">
            <i class="far fa-clipboard-list-check mr-2"></i>Mostra tutte le attività
          </button>
        </div>
      </div>
    </div>

  </div>
</ng-container>
