<div class="container py-5 py-sm-7">
  <a class="d-flex justify-content-center mb-5" [routerLink]="['']">
    <img class="z-index-2" src="./assets/theme/svg/logos/geniusuite_partner.svg" alt="Geniusuite" style="width: 30rem;">
  </a>

  <div class="row justify-content-center">
    <div class="col-md-8 col-lg-6">
      <div class="alert alert-warning" role="alert">
        <b class="text-cap">APP TERZE PARTI - APPROVAZIONE ENTRO IL 23/10 </b>
        <p>Anche GeniuSuite rientra nelle APP da approvare, se non lo hai già fatto, procedi per evitare problemi
          all'accesso. <a href="https://geniusuite.com/come-confermare-app-terze-parti-google-workspace-for-education/" target="_blank"><u>Segui il nostro Tutorial.</u></a></p>
      </div>
    </div>
  </div>

  <div class="row justify-content-center mb-5">
    <div class="col-md-8 col-lg-6">
      <div class="card card-lg">
        <div class="card-body">
          <form class="js-validate" novalidate="novalidate">
            <div class="text-center">
              <div class="mb-5">
                <h1 class="display-4">Accedi</h1>
                <p>Non hai ancora un account? <a href="https://www.geniusuite.com">Richiedi un accesso</a></p>
              </div>

              <a class="btn btn-lg btn-block btn-primary mb-5" [href]="loginUrlGoogle">
                <span class="d-flex justify-content-center align-items-center">
                  <img class="avatar avatar-xs mr-2" src="./assets/theme/svg/brands/google-white.svg"
                    alt="Accedi con Google">
                  Accedi con Google Workspace
                </span>
              </a>

              <div>
                <p>Se non usi Geniusuite con Google Workspace <a [href]="loginUrlNotGoogle">accedi da qui.</a></p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <br>

  <div class="row justify-content-center mb-5 px-md-5">
    <div class="col-md-3 mb-5">
      <div class="card">
        <a href="https://geniusuite.com/codici-mepa-matrice-pnrr-azione-1-2/">
          <img class="card-img card-img-top" src="https://geniusuite.com/wp-content/uploads/2023/02/3.png">
        </a>
      </div>
    </div>
    <div class="col-md-3 mb-5">
      <div class="card">
        <a href="https://geniusuite.com/codici-mepa-matrice-pnrr-azione-1-2/">
          <img class="card-img card-img-top" src="https://geniusuite.com/wp-content/uploads/2023/02/4.png">
        </a>
      </div>
    </div>
  </div>

  <br>

  <div class="row justify-content-center mb-5 px-md-5">
    <div class="col-md-3 mb-5">
      <div class="text-center">
        <a href="https://bit.ly/3NsDay8">
          <i aria-hidden="true" class="fas fa-blog mb-3"></i>
          <h2 class="text-primary">Lasciati ispirare</h2>
          <p class="text-muted">Nel nostro blog tanti contenuti per una Didattica Innovativa</p>
        </a>
      </div>
    </div>
    <div class="col-md-3 mb-5">
      <div class="text-center">
        <a href="https://bit.ly/35g5ORA">
          <i aria-hidden="true" class="fas fa-hands-helping mb-3"></i>
          <h2 class="text-primary">Risorse GeniuSuite</h2>
          <p class="text-muted">Guide e Tutorial per diventare cintura nera nell'uso di GeniuSuite</p>
        </a>
      </div>
    </div>
    <div class="col-md-3 mb-5">
      <div class="text-center">
        <a href="https://bit.ly/3IMJ89a">
          <i aria-hidden="true" class="fab fa-rocketchat mb-3"></i>
          <h2 class="text-primary">Prenota una Call</h2>
          <p class="text-muted">Prenota una call con Monica per un'approfondimento GeniuSuite</p>
        </a>
      </div>
    </div>
    <div class="col-md-3 mb-5">
      <div class="text-center">
        <a href="https://bit.ly/35kyJ7g">
          <i aria-hidden="true" class="fas fa-question mb-3"></i>
          <h2 class="text-primary">Lascia un Feedback</h2>
          <p class="text-muted">Poche domande per continuare a crescere e migliorare</p>
        </a>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-md-7 col-lg-5">
      <div class="text-center">
        <h2 class="mb-4">Seguici sui nostri canali</h2>

        <div class="w-85 mx-auto">
          <div class="row justify-content-between">
            <div class="col">
              <a href="https://bit.ly/3KSlCJG" target="_blank"><i class="fab fa-youtube"
                  style="color:#FF0000; font-size: 4em;"></i></a>
            </div>
            <div class="col">
              <a href="https://t.me/geniusuite" target="_blank"><i class="fab fa-telegram"
                  style="color:#37AEE2; font-size: 4em;"></i></a>
            </div>
            <div class="col">
              <a href="https://www.facebook.com/groups/231291057947835" target="_blank"><i
                  class="fab fa-facebook-square" style="color:#1877F2; font-size: 4em;"></i></a>
            </div>
            <div class="col">
              <a href="https://geniusuite.com/assistenza-geniusuite/?utm_source=geniusuite&utm_medium=link&utm_campaign=assistenza_geniusuite"
                target="_blank"><i class="fa-solid fa-headphones" style="font-size: 3.3em; margin-top: 6px;"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
