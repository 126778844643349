import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../../backend.service';
import { PlessoService } from '../../general/plesso.service';
import { UserService } from '../../general/user.service';
import { ToastService } from '../../toast.service';
import { UtilityService } from '../../utility.service';

export enum CreationClassroomTypes {
  ClasseClassroom = 1,
  ClasseMateriaClassroom = 2,
  ClasseDocentiClassroom = 3,
}
@Injectable({
  providedIn: 'root'
})
export class CreateClassroomService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  cacheValidStruttura: boolean = false;
  struttura: any = [];

  parameters: any = {
    creationType: null,
    classroomName: "",
    classroomSection: null,
    classroomDescription: null,
    classroomRoom: null,
    allOwnerId: null,
    ownerData: null,
    classi: null,
    classroomName_error_ignore: false
  };

  items: any[]; // Classi Selezionate

  errors: any[];

  constructor(
    private userService: UserService,
    private backend: BackendService,
    private toast: ToastService,
    private utilityService: UtilityService,
    private plessoService: PlessoService
  ) {
    this.reset();
  }

  reset() {
    this.items = [];
    this._subjectToUpdate.next(this.items);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  getItems() {
    this._subjectToUpdate.next(this.items ? this.items : []);
    return;
  }

  updateItems(updItems) {
    let classiForAdd = this.utilityService.getDiffByTwoArray(updItems, this.items);
    let classiForRemove = this.utilityService.getDiffByTwoArray(this.items, updItems);
    let idClassiForRemove = classiForRemove.map(x => x.id);

    let indexClassiForRemove = [];

    for (let i = 0; i < this.items.length; i++) {
      if (idClassiForRemove.includes(this.items[i].id))
        indexClassiForRemove.push(i);
    }

    this.addItems(classiForAdd, false);
    this.deleteItemsAtIndex(indexClassiForRemove, false);

    this._subjectToUpdate.next(this.items);
  }

  addItems(newItems, updSubject = true) {
    newItems.forEach(item => {

      let itemFound = this.items.find((element) => element?.id == item?.id);

      if (!itemFound) {
        let addItem = this.utilityService.cloneData(item);
        this.items.push(addItem);
      }
    });

    if (updSubject)
      this._subjectToUpdate.next(this.items);

    this.cacheValidStruttura = false;
  }

  deleteItemsAtIndex(indexArray, updSubject = true) {

    indexArray.forEach(idx => {
      this.items[idx] = null;
    });

    this.items = this.items.filter((el) => { return el != null });

    if (updSubject)
      this._subjectToUpdate.next(this.items);

    this.cacheValidStruttura = false;
  }

  deleteItemAtIndex(classeId, updSubject = true, statusCache = false, removeFromStuttura = false, plessoId = null) {
    let idx = this.items.findIndex(x => x.id == classeId);
    this.items[idx] = null;

    this.items = this.items.filter((el) => { return el != null });

    if (this.struttura != null && this.struttura.length > 0 && removeFromStuttura && plessoId) {
      let strutturaIndexGest = this.struttura.findIndex(x => x.plesso.id == plessoId);
      let classroomIndexGest = this.struttura[strutturaIndexGest].classrooms.findIndex(x => x.classe.id == classeId);
      let classroom = this.struttura[strutturaIndexGest].classrooms[classroomIndexGest];

      if (this.errors != null && this.errors.length > 0) {
        let indexError = this.errors.findIndex(x => x.tempId == classroom.tempId);
        if (indexError != -1) {
          this.errors[indexError] = null;
          this.errors = this.errors.filter((el) => { return el != null });
        }
      }

      this.struttura[strutturaIndexGest].classrooms[classroomIndexGest] = null;
      this.struttura[strutturaIndexGest].classrooms = this.struttura[strutturaIndexGest].classrooms.filter((el) => { return el != null });
    }

    if (updSubject)
      this._subjectToUpdate.next(this.items);

    this.cacheValidStruttura = statusCache;
  }

  execOperation(callback = (error) => { }) {
    let courses = [];

    courses = this.getStrutturaJson();

    this.backend.post("op/create-classroom", { courses: courses }).subscribe((result) => {
      this.reset();
      this.toast.success('Creazione Classroom avviato');
      callback(null);
    }, (error) => {
      callback(error);
    })
  }

  getStrutturaJson() {
    let dirtyPlessoStruttura = this.struttura.map(x => x.classrooms);
    let clearStruttura = [];

    dirtyPlessoStruttura.forEach(element => {
      element.forEach(elementDirty => {
        let students = [];
        let teachers = [];

        if (elementDirty.studenti != null && elementDirty.studenti.length > 0) {
          elementDirty.studenti.forEach(user => {
            students.push({
              id: user.id,
              google_id: user.google_user.google_id
            })
          });
        }

        if (elementDirty.docenti != null && elementDirty.docenti.length > 0) {
          elementDirty.docenti.forEach(user => {
            teachers.push({
              id: user.id,
              google_id: user.google_user.google_id
            })
          });
        }

        let objCourse = {
          course: {
            ownerId: elementDirty.ownerId,
            name: elementDirty.name,
            description: elementDirty.description,
            room: elementDirty.room,
            section: elementDirty.section,
            students: students,
            teachers: teachers
          },
          holderInfo: {
            classe_id: elementDirty.classe.id,
            parameters: JSON.stringify({
              name: this.parameters.classroomName,
              section: this.parameters.classroomSection,
              description: this.parameters.classroomDescription,
              room: this.parameters.classroomRoom,
              type: this.parameters.creationType
            }),
            user_id: elementDirty.user_id,
            materia_id: elementDirty.materia_id
          }
        }

        clearStruttura.push(objCourse);
      });
    });


    return clearStruttura;
  }

  updateParameter(_parameters) {
    this.parameters = _parameters;
    this.cacheValidStruttura = false;
  }

  getStruttura() {

    if (this.cacheValidStruttura) {
      this.checkStruttura();
      return this.struttura;
    }

    // Genero la struttura di visualizzazione
    this.struttura = [];

    switch (this.parameters.creationType) {
      case CreationClassroomTypes.ClasseClassroom:
        this.struttura = this.getTypesClasseClassroom();
        break;
      case CreationClassroomTypes.ClasseDocentiClassroom:
        this.struttura = this.getTypesClasseDocentiClassroom();
        break;
      case CreationClassroomTypes.ClasseMateriaClassroom:
        this.struttura = this.getTypesClasseMateriaClassroom();
        break;
    }

    this.cacheValidStruttura = true;
    this.checkStruttura();
    return this.struttura;
  }

  changeOwnerClassroom(ownerId, plessoId, classroomTempId) {
    let strutturaIndexGest = this.struttura.findIndex(x => x.plesso.id == plessoId);
    let classroomIndexGest = this.struttura[strutturaIndexGest].classrooms.findIndex(x => x.tempId == classroomTempId);
    this.struttura[strutturaIndexGest].classrooms[classroomIndexGest].ownerId = ownerId;
    let classroom = this.struttura[strutturaIndexGest].classrooms[classroomIndexGest];
    this.struttura[strutturaIndexGest].classrooms[classroomIndexGest] = this.generateNamesClassroom(classroom, classroom.classe);
  }

  removeUserFromClassroom(google_id, plessoId, classroomTempId) {
    let strutturaIndexGest = this.struttura.findIndex(x => x.plesso.id == plessoId);
    let classroomIndexGest = this.struttura[strutturaIndexGest].classrooms.findIndex(x => x.tempId == classroomTempId);

    let docenti = this.struttura[strutturaIndexGest].classrooms[classroomIndexGest].docenti;

    if (docenti != null && docenti.length > 0) {
      let indexCheck = docenti.findIndex(x => x.google_user.google_id == google_id);
      if (indexCheck != -1) {
        this.struttura[strutturaIndexGest].classrooms[classroomIndexGest].docenti[indexCheck] = null;
        this.struttura[strutturaIndexGest].classrooms[classroomIndexGest].docenti = this.struttura[strutturaIndexGest].classrooms[classroomIndexGest].docenti.filter((el) => { return el != null });
      }
    }
  }

  generateNamesClassroom(objClassroom, element) {
    let proprietario = "";

    if (objClassroom.docenti != null && objClassroom.docenti.length > 0) {
      let ownerUser = objClassroom.docenti.find(x => x.google_user.google_id == objClassroom.ownerId);
      proprietario = ownerUser.familyName + " " + ownerUser.givenName;
    }

    objClassroom.name = this.replaceDataNameClassroomGenerated({
      name_plesso: element.plesso.name,
      short_name_plesso: element.plesso.short_name,
      name_classe: element.name,
      anno_classe: element.anno,
      proprietario: proprietario,
      name_materia: objClassroom.materia ? objClassroom.materia.materia_name : ''
    }, this.parameters.classroomName);

    objClassroom.section = this.replaceDataNameClassroomGenerated({
      name_plesso: element.plesso.name,
      short_name_plesso: element.plesso.short_name,
      name_classe: element.name,
      anno_classe: element.anno,
      name_materia: objClassroom.materia ? objClassroom.materia.materia_name : ''
    }, this.parameters.classroomSection);

    objClassroom.description = this.replaceDataNameClassroomGenerated({
      name_plesso: element.plesso.name,
      short_name_plesso: element.plesso.short_name,
      name_classe: element.name,
      anno_classe: element.anno,
      proprietario: proprietario,
      name_materia: objClassroom.materia ? objClassroom.materia.materia_name : ''
    }, this.parameters.classroomDescription);

    objClassroom.room = this.replaceDataNameClassroomGenerated({
      name_plesso: element.plesso.name,
      short_name_plesso: element.plesso.short_name,
      name_classe: element.name,
      anno_classe: element.anno,
      name_materia: objClassroom.materia ? objClassroom.materia.materia_name : ''
    }, this.parameters.classroomRoom);

    return objClassroom;
  }

  getTypesClasseClassroom() {
    let _struttura = [];

    // Ciclo sulle classi e creo struttura plesso -> classrooms
    this.items.forEach(element => {

      if (!element.plesso && element.plesso_id) {
        element.plesso = this.plessoService.get(element.plesso_id);
      }

      let objClassroom = {
        tempId: Math.random().toString(36).slice(2),
        creationType: CreationClassroomTypes.ClasseClassroom,
        name: null,
        section: null,
        description: null,
        room: null,
        studenti: element.studenti,
        docenti: element.docenti,
        classe: element,
        isCollapsed: true,
        ownerId: element.docenti != null && element.docenti.length > 0 ? element.docenti[0].google_user.google_id : null,
        error: null,
        warning: null,
        user_id: null,
        materia_id: null
      }

      if (this.parameters.ownerData && this.parameters.allOwnerId) {
        objClassroom.ownerId = this.parameters.ownerData.google_id;

        if (objClassroom.docenti == null || objClassroom.docenti.length == 0) {
          objClassroom.docenti = [];
        }

        let existUserOnListIndex = objClassroom.docenti.findIndex(x => x.id == this.parameters.ownerData.user.id);

        if (existUserOnListIndex == -1)
          objClassroom.docenti.unshift({ ...this.parameters.ownerData, ...this.parameters.ownerData.user });
      }

      objClassroom = this.generateNamesClassroom(objClassroom, element);

      let indexExistPlesso = _struttura.findIndex(x => x.plesso.id == element.plesso.id);

      if (indexExistPlesso != -1) {
        _struttura[indexExistPlesso].classrooms.push(objClassroom);
      } else {
        _struttura.push({
          plesso: element.plesso,
          isCollapsed: true,
          classrooms: [objClassroom]
        });
      }
    });
    return _struttura;
  }

  checkError(objClassroom) {

    if (objClassroom.ownerId == null) {
      return "Manca un proprietario della classroom";
    } else if (objClassroom.docenti == null || objClassroom.docenti.length == 0) {
      return "Mancano docenti nella classroom";
    } else if (objClassroom.docenti && objClassroom.docenti.length > 20) {
      return "Non può avere più di 20 docenti, vanno rimossi!";
    }

    return null;
  }

  checkWarning(objClassroom) {

    return null;
  }

  checkStruttura() {

    this.errors = [];

    this.struttura.forEach(plesso => {
      plesso.classrooms.forEach(classroom => {
        classroom.error = this.checkError(classroom);
        classroom.warning = this.checkWarning(classroom);

        if (classroom.error) {
          this.errors.push(classroom);
        }
      });
    });
  }

  getTypesClasseDocentiClassroom() {
    let _struttura = [];

    // Ciclo sulle classi e creo struttura plesso -> classrooms
    this.items.forEach(element => {

      if (!element.plesso && element.plesso_id) {
        element.plesso = this.plessoService.get(element.plesso_id);
      }

      if (element.docenti != null && element.docenti.length > 0) {

        element.docenti.forEach(docente => {

          let objClassroom = {
            tempId: Math.random().toString(36).slice(2),
            creationType: CreationClassroomTypes.ClasseDocentiClassroom,
            name: null,
            section: null,
            description: null,
            room: null,
            studenti: element.studenti,
            docenti: [docente],
            classe: element,
            isCollapsed: true,
            error: null,
            warning: null,
            ownerId: docente.google_user.google_id,
            user_id: docente.id,
            materia_id: null
          }

          if (this.parameters.ownerData && this.parameters.allOwnerId) {
            objClassroom.ownerId = this.parameters.ownerData.google_id;

            if (objClassroom.docenti == null || objClassroom.docenti.length == 0) {
              objClassroom.docenti = [];
            }

            let existUserOnListIndex = objClassroom.docenti.findIndex(x => x.id == this.parameters.ownerData.user.id);

            if (existUserOnListIndex == -1)
              objClassroom.docenti.unshift({ ...this.parameters.ownerData, ...this.parameters.ownerData.user });
          }

          objClassroom = this.generateNamesClassroom(objClassroom, element);
          let indexExistPlesso = _struttura.findIndex(x => x.plesso.id == element.plesso.id);

          if (indexExistPlesso != -1) {
            _struttura[indexExistPlesso].classrooms.push(objClassroom);
          } else {
            _struttura.push({
              plesso: element.plesso,
              isCollapsed: true,
              classrooms: [objClassroom]
            });
          }
        });
      }
    });

    return _struttura;
  }

  getTypesClasseMateriaClassroom() {
    let _struttura = [];

    // Ciclo sulle classi e creo struttura plesso -> classrooms
    this.items.forEach(classe => {

      if (classe.docenti != null && classe.docenti.length > 0) {

        classe.docenti.forEach(docente => {

          let userData = this.userService.get(docente.id);
          let materieClasse = null;

          // Controllo se in materie esistete
          if (userData?.user.materie && userData?.user.materie.length > 0) {
            materieClasse = userData?.user.materie.filter(x => x.classe_id == classe.id);
          }

          if (materieClasse != null && materieClasse.length > 0) {

            if (!classe.plesso && classe.plesso_id) {
              classe.plesso = this.plessoService.get(classe.plesso_id);
            }

            materieClasse.forEach(materia => {
              let objClassroom = {
                tempId: Math.random().toString(36).slice(2),
                creationType: CreationClassroomTypes.ClasseDocentiClassroom,
                name: null,
                section: null,
                description: null,
                room: null,
                studenti: classe.studenti,
                docenti: [docente],
                classe: classe,
                materia: materia,
                isCollapsed: true,
                error: null,
                warning: null,
                ownerId: docente.google_user.google_id,
                user_id: docente.id,
                materia_id: materia.materia_id
              }

              if (this.parameters.ownerData && this.parameters.allOwnerId) {
                objClassroom.ownerId = this.parameters.ownerData.google_id;

                if (objClassroom.docenti == null || objClassroom.docenti.length == 0) {
                  objClassroom.docenti = [];
                }

                let existUserOnListIndex = objClassroom.docenti.findIndex(x => x.id == this.parameters.ownerData.user.id);

                if (existUserOnListIndex == -1)
                  objClassroom.docenti.unshift({ ...this.parameters.ownerData, ...this.parameters.ownerData.user });
              }

              objClassroom = this.generateNamesClassroom(objClassroom, classe);
              let indexExistPlesso = _struttura.findIndex(x => x.plesso.id == classe.plesso.id);

              if (indexExistPlesso != -1) {
                _struttura[indexExistPlesso].classrooms.push(objClassroom);
              } else {
                _struttura.push({
                  plesso: classe.plesso,
                  isCollapsed: true,
                  classrooms: [objClassroom]
                });
              }
            });
          }
        });
      }
    });
    return _struttura;
  }

  replaceDataNameClassroomGenerated(objectToDecode: any, stringForm: any) {

    if (!stringForm)
      return null;

    let name_plesso = objectToDecode.name_plesso ? this.utilityService.sostituisciCaratteriAccentati(String(objectToDecode.name_plesso)) : '';
    let short_name_plesso = objectToDecode.short_name_plesso ? this.utilityService.sostituisciCaratteriAccentati(String(objectToDecode.short_name_plesso)) : '';
    let name_classe = objectToDecode.name_classe ? this.utilityService.sostituisciCaratteriAccentati(String(objectToDecode.name_classe)) : '';
    let anno_classe = objectToDecode.anno_classe ? this.utilityService.sostituisciCaratteriAccentati(String(objectToDecode.anno_classe)) : '';
    let name_materia = objectToDecode.name_materia ? this.utilityService.sostituisciCaratteriAccentati(String(objectToDecode.name_materia)) : '';
    let proprietario = objectToDecode.proprietario ? this.utilityService.sostituisciCaratteriAccentati(String(objectToDecode.proprietario)) : '';

    let result = "";
    result = stringForm.replace(
      /\$name_plesso\$|\$short_name_plesso\$|\$name_materia\$|\$proprietario\$|\$name_classe\$|\$anno_classe\$/gi,
      function (matched) {
        return {
          $name_plesso$: name_plesso,
          $short_name_plesso$: short_name_plesso,
          $name_classe$: name_classe,
          $anno_classe$: anno_classe,
          $name_materia$: name_materia,
          $proprietario$: proprietario,
        }[matched];
      }
    );

    return result;
  }
}
