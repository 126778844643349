<div class="card">
  <!-- Header -->
  <div class="card-header">
    <h5 class="card-header-title">
      <i class="fad fa-user nav-icon red mr-1"></i> Utente: {{data?.user.familyName}} {{data?.user.givenName}}
      <span *ngIf="!data?.google_user" class="ml-2 badge badge-soft-warning"><i class="fas fa-exclamation-circle"></i>
        Attenzione, l'utente non risulta presente sulla G-Suite</span>
      <span *ngIf="data?.google_user?.suspended" class="ml-2 badge badge-soft-warning"><i class="fas fa-exclamation-circle"></i>
        Attenzione, l'utente risulta sospeso</span>
      <span *ngIf="data?.google_user?.archived" class="ml-2 badge badge-soft-warning"><i class="fas fa-exclamation-circle"></i>
        Attenzione, l'utente risulta archiviato</span>
    </h5>
    <div class="d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="showInfo = !showInfo">
        <i class="fas fa-info mr-2"></i>{{ 'BTN.Informazioni' | translate }}
      </button>
      <button type="button" *ngIf="!disableButton && data?.google_user" class="btn btn-outline-primary btn-sm mr-2"
        [routerLink]="['edit']">
        <i class="fas fa-edit mr-2"></i>{{ 'BTN.Modifica' | translate }}
      </button>
      <button *ngIf="!disableButton" type="button" class="btn btn-outline-danger btn-sm" [routerLink]="['delete']">
        <i class="far fa-trash mr-2"></i>{{ 'BTN.cancella_utente' | translate }}
      </button>
    </div>
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div *ngIf="showInfo" class="card-body">

    <div class="row mb-3">
      <div class="col">
        <small class="card-subtitle">Email Geniusuite</small>
        <p>{{ data?.user.email || 'Nessuna email'}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Organizzazione</small>
        <p>{{ data?.google_user?.orgUnitPath || "Nessuna organizzazione associata"}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Creato il</small>
        <p>{{ data?.google_user?.creationTime | date:'medium' }}</p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <small class="card-subtitle">Email primaria Google</small>
        <p>{{ data?.google_user?.primaryEmail || 'Nessuna email'}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Ruolo</small>
        <p>
          <ng-container *ngFor="let ruolo of ruoli; let last = last;">
            {{ ruolo | translate }}<ng-container *ngIf="!last">, </ng-container>
          </ng-container>
        </p>
      </div>
      <div class="col">
        <small class="card-subtitle">Ultimo Accesso</small>
        <p *ngIf="data?.google_user?.lastLoginTime != '1970-01-01T00:00:00.000Z'">{{data?.google_user?.lastLoginTime |
          date:'medium'}}</p>
        <p *ngIf="data?.google_user?.lastLoginTime == '1970-01-01T00:00:00.000Z'">Mai connesso</p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <small class="card-subtitle">Organigramma</small>
        <p>{{ organigramma?.name }}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Plesso e Classe</small>
        <ng-container *ngFor="let classe of classi">
          <p><b>{{ classe.classe.plesso.name }}</b><br>{{ classe.classe.name }}</p>
        </ng-container>
      </div>
      <div class="col">
        <small class="card-subtitle">Email di recupero</small>
        <p>{{ data?.google_user?.recoveryEmail || "Nessuna email" }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <small class="card-subtitle">Email di contatto</small>
        <ng-container *ngFor="let email of data?.google_user?.emails">
          <p *ngIf="email.address != data?.google_user?.primaryEmail">{{ email.address }} ({{ email.type }})</p>
        </ng-container>
      </div>
      <div class="col">
        <small class="card-subtitle">Numeri di telefono</small>
        <ng-container *ngFor="let tel of data?.google_user?.phones">
          <p>{{ tel.value }} ({{ tel.type }})</p>
        </ng-container>
      </div>
      <div class="col">
        <small class="card-subtitle">Indirizzi</small>
        <ng-container *ngFor="let indirizzo of data?.google_user?.addresses">
          <p>{{ indirizzo.formatted }} ({{ indirizzo.type }})</p>
        </ng-container>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <small class="card-subtitle">Codice Identificativo</small>
        <ng-container *ngFor="let externalId of data?.google_user?.externalIds">
          <p *ngIf="externalId.type == 'organization'">{{ externalId.value }}</p>
        </ng-container>
      </div>
      <div class="col">
        <small class="card-subtitle">ID Esterni</small>
        <ng-container *ngFor="let externalId of data?.google_user?.externalIds">
          <p *ngIf="externalId.type != 'organization'">{{ externalId.value }}</p>
        </ng-container>
      </div>
      <div class="col">
      </div>
    </div>

  </div>
  <!-- End Body -->

  <!-- Footer -->
  <div class="card-footer text-center">
    <!-- Stats -->
    <div class="row">
      <div class="col">
        <span class="h4">{{data?.user.organigrammi?.length}}</span>
        <span class="d-block font-size-sm">Organigrammi</span>
      </div>

      <div class="col column-divider">
        <span class="h4">{{data?.classi?.length}}</span>
        <span class="d-block font-size-sm">Classi</span>
      </div>

      <!-- <div class="col column-divider">
              <span class="h4">10</span>
              <span class="d-block font-size-sm">Meet</span>
            </div> -->
    </div>
    <!-- End Stats -->
  </div>
  <!-- End Footer -->
</div>

<ng-container *ngIf="showInfoClassi">
  <div class="card mt-3">
    <div class="card-body" style="overflow-y: auto;">
      <h3 class="card-title">Struttura scolastica associata all'utente</h3>
      <div class="d-flex justify-content-center mt-5" *ngIf="plessiTree && plessiTree.length > 0; else elseBlock">
        <ng-container *ngFor="let plessoTree of plessiTree">
          <p-organizationChart [value]="plessoTree.treeNode" [(selection)]="selectedTreeClasse" class="custom-p-tree"
            expanded="true">
            <ng-template let-node pTemplate="default">
              <div class="text-truncate" style="max-width: 150px;" [pTooltip]="node.label">{{node.label}}</div>
            </ng-template>
          </p-organizationChart>
        </ng-container>
      </div>
      <ng-template #elseBlock>
        <div class="row">
          <div class="col-12">
            <p>Non è presente nessuna associazione</p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
