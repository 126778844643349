<app-card-loading *ngIf="loading"></app-card-loading>

<div *ngIf="!loading" class="card shadow-none mb-2" [ngClass]="{'border-0': !border}">
    <div class="card-body" [ngClass]="{'border-0': !border}">

        <h3 class="card-title" *ngIf="!materieSelectionClassi">Seleziona un plesso e le classi da associare</h3>
        <h3 class="card-title" *ngIf="materieSelectionClassi">Seleziona un plesso, le classi e le materie da associare</h3>

        <p class="card-text">
          <b>Attenzione:</b> Questa funzionalità va utilizzata solo per aggiungere un docente ad una classe e modificare le materie associate. Se si vuole rimuove il docente da una classe o da un plesso bisogna cercare l'utente nella barra di ricerca in alto e modificarlo dalla sua scheda profilo.
        </p>

        <!-- Seleziona Plesso -->
        <div class="row form-group">
            <div class="col-sm-12">
                <label class="input-label" for="select-plessoSelected">{{"STR.Seleziona_Plesso" |
                    translate}}</label>
                <app-select (valueChange)="plessoOnSelect($event)" [id]="'select-plessoSelected'" [showClear]="true"
                    [filterBy]="'name'" [placeholder]="'STR.Seleziona_Plesso'" [data]="_plessi" [showValue]="'name'"
                    [(value)]="plessoSelected" [optionAttributeShow]="'name'" [disabled]="disablePlessoSelection">
                </app-select>
            </div>
        </div>
        <div class="alert alert-soft-warning media" role="alert"
            *ngIf="(plessoSelected?.classi || !plessoSelected?.classi) && plessoSelected?.classi.length == 0">
            <i class="tio-warning mt-1 mr-1"></i>
            <div class="media-body" role="alert">
                Seleziona un plesso che abbia delle classi associate
            </div>
        </div>

        <ng-container *ngIf="!_loadingDataSectionClassi">
            <!-- Seleziona Classi/Classe -->
            <div class="row form-group"
                *ngIf="plessoSelected && (plessoSelected?.classi || !plessoSelected?.classi) && plessoSelected?.classi.length > 0">
                <div class="col-sm-12">
                    <label class="input-label" for="userPlessoSelected">{{_labelText | translate}}</label>
                    <input type="hidden" id="userPlessoSelected" name="userPlessoSelected" #userPlessoSelected="ngModel"
                        [(ngModel)]="plessoSelected">

                    <app-select (valueChange)="classiPlessoOnSelect($event)" [id]="'select-classi-plesso'"
                        [showClear]="true" [filterBy]="'name'" [placeholder]="_labelText"
                        [multiple]="multiSelectionClassi" [data]="_classi" [showValue]="'name'"
                        [optionAttributeShow]="'name'" [(value)]="_plessoClassiSelected" [showToggleAll]="true">
                    </app-select>
                </div>
            </div>

            <!-- Switch Aggiungere Materie -->
            <div class="row form-group" *ngIf="materieSelectionClassi">
                <div class="col-sm-12">
                    <h4>Materie
                        <span class="badge badge-soft-warning" *ngIf="!_materie || _materie.length == 0">
                            Nessuna Materia Creata
                        </span>
                    </h4>
                    <label class="toggle-switch d-flex">
                        <input [(ngModel)]="_paramMaterie" type="checkbox" class="toggle-switch-input"
                            (change)="paramMateriaOnSelect()"
                            [disabled]="(!_materie || _materie.length == 0) || (!plessoSelected || plessoSelected?.classi == 0)">
                        <span class="toggle-switch-label">
                            <span class="toggle-switch-indicator"></span>
                        </span>
                        <span class="toggle-switch-content">
                            <span class="d-block">Associa le materie all'utente
                            </span>
                            <small class="d-block text-muted">Associa classi e materie all'utente</small>
                        </span>
                    </label>
                </div>
            </div>

            <!-- Seleziona Materie Classi -->
            <div class="row form-group"
                *ngIf="plessoSelected && _paramMaterie && materieSelectionClassi && _materie && _materie.length > 0">
                <div class="col-sm-12">

                    <ul class="list-group list-group-flush list-group-no-gutters">
                        <li class="list-group-item" *ngFor="let detailMat of _plessoMaterieClassiSelected">
                            <div class="row">
                                <div class="col-6 center-data">
                                    {{detailMat.materia_name}}
                                </div>
                                <div class="col-6 center-data">

                                    <app-select (valueChange)="classiMateriaOnSelect($event, detailMat.materia_id)"
                                        [id]="'select-classi-'.concat(detailMat.materia_id)" [showClear]="true"
                                        [filterBy]="'name'" [placeholder]="'STR.Seleziona_Classi'" [multiple]="true"
                                        [data]="detailMat.classi" [showValue]="'name'" [optionAttributeShow]="'name'"
                                        [(value)]="detailMat.classiSelected" [showToggleAll]="true">
                                    </app-select>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </ng-container>

        <div [ngClass]="{'p-4': !border}" *ngIf="_loadingDataSectionClassi">
            <app-card-loading></app-card-loading>
        </div>
    </div>
</div>

<!-- <div class="row justify-content-end align-items-center" *ngIf="multiInsert">
    <div class="col-auto py-1">
        <button [disabled]="!plessoSelected" type="button" class="btn btn-primary" (click)="addOnClick()">
            Aggiungi<i class="far fa-chevron-right ml-1"></i>
        </button>
    </div>
</div> -->
