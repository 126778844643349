import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-ente-profile-info-card',
  templateUrl: './ente-profile-info-card.component.html',
  styleUrls: ['./ente-profile-info-card.component.scss']
})
export class EnteProfileInfoCardComponent implements OnInit, OnDestroy {

  ente: any;
  domains: any;
  utentiCount: 0;
  domainsCount: 0;
  showInfo = false;

  private _accountServiceSubscription: Subscription;
  private _googleServiceDomainsSubscription: Subscription;
  private _userServiceSubscription: Subscription;

  constructor(
    private accountService: AccountService,
    private googleDirectoryService: GoogleDirectoryService,
    private pageService: PageService,
    private userService: UserService
    ) { }

  ngOnInit() {

    this.ente = this.accountService.data.ente;

    this.pageService.updatePageTitle({ ente: this.ente.name });

    this._accountServiceSubscription = this.accountService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.accountUpdated(result);
    });

    this._googleServiceDomainsSubscription = this.googleDirectoryService.getSubjectToUpdateDomainsObservable().subscribe((result) => {
      if (result)
        this.domainsUpdated(result);
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.utentiCount = result.length;
      }
    });

    this._googleServiceDomainsSubscription = this.googleDirectoryService.getSubjectToUpdateDomainsObservable().subscribe(
      (result) => {
        if (result)
          this.domainsCount = result.length;
      });

    this.googleDirectoryService.domainsList();
    this.userService.all();
  }

  ngOnDestroy() {

    if (this._accountServiceSubscription)
      this._accountServiceSubscription.unsubscribe();

    if (this._googleServiceDomainsSubscription)
      this._googleServiceDomainsSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

  }

  accountUpdated(result) {
    this.ente = result.ente;
  }

  domainsUpdated(result) {
    this.domains = result;
  }

}
