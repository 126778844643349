<div class="row">
  <ng-container *ngIf="id">
    <div class="col-md-12 col-sm mb-1">
      <div class="mb-3">
        <app-monitor-profile-info-card [id]="id"></app-monitor-profile-info-card>
      </div>
    </div>

    <div class="col-md-12 col-sm">
      <app-monitor-form [id]="id"></app-monitor-form>
    </div>

  </ng-container>

  <ng-container *ngIf="!id">
    <div class="col-md-12 col-sm" *ngIf="!showOnlyCreate">
      <app-card-datatable-ng title="Alfred" [data]="monitorsData" [isLoadingData]="isLoadingMonitors" [error]="monitorsError"
        (addClickEvent)="addClick()" (rowClickEvent)="rowClick($event)" [showDeleteButton]="false"
        [showAddButton]="false" [showCheckBox]="true" [showExportButton]="true"></app-card-datatable-ng>
    </div>
  </ng-container>
</div>
