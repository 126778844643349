<div class="row mb-5">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body m-2">
        <app-setup-wizard-steps [currentStep]="5"></app-setup-wizard-steps>
      </div>
    </div>
  </div>
</div>

<div class="row mb-5">
  <div class="col">
    <div class="card" *ngIf="isLoading">
      <div class="card-body">
        <h2>Caricamento in corso...</h2>
        <p-progressBar mode="indeterminate" [style]="{'height': '20px'}"></p-progressBar>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body">

        <h1>{{'SETUP.preview_users.titolo' | translate}}</h1>
        <p [innerHTML]="'SETUP.preview_users.sottotitolo' | translate"></p>

        <div class="alert alert-soft-danger" *ngIf="strutturaErrori?.length > 0 || strutturaOmonimie?.length > 0"
          [innerHTML]="'SETUP.preview_users.errori_presenti' | translate"></div>

      </div>

      <div class="card-footer text-center">
        <div class="row">
          <div class="col">
            <span class="h4">{{ countUtenti }}</span>
            <span class="d-block font-size-sm">{{ 'STR.Utenti' | translate }}</span>
          </div>

          <div class="col column-divider">
            <span class="h4">{{ countUtentiNuovi }}</span>
            <span class="d-block font-size-sm">{{ 'STR.utenti_new' | translate }}</span>
          </div>

          <div class="col column-divider">
            <span class="h4">{{ countUtentiUpdate }}</span>
            <span class="d-block font-size-sm">{{ 'STR.utenti_update' | translate }}</span>
          </div>

          <div class="col column-divider">
            <span class="h4">{{ countErrors }}</span>
            <span class="d-block font-size-sm">{{ 'STR.errors' | translate }}</span>
          </div>

          <div class="col column-divider">
            <span class="h4">{{ countOmonimie }}</span>
            <span class="d-block font-size-sm">{{ 'STR.omonimie' | translate }}</span>
          </div>

          <div class="col column-divider">
            <span class="h4">{{ countWarnings }}</span>
            <span class="d-block font-size-sm">{{ 'STR.incongruenze' | translate }}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #userInfoTemplate let-user="user">
  <div class="row">
    <div class="col-8">
      <h3 class="card-header-title">
        <ng-container *ngIf="user.plesso_obj && user.plesso_obj.plesso">
          <i class="fas fa-school mr-2"></i>{{user.plesso_obj.plesso.name}} <small>(abbreviazione
            "{{user.plesso_obj.plesso.short_name}})"</small>
          <span *ngIf="!user.plesso_obj.plesso.id" class="badge badge-soft-primary ml-2">Nuovo</span>
          <span *ngIf="user.plesso_obj.plesso.id" class="badge badge-soft-success ml-2">Esistente</span>
        </ng-container>
        <ng-container *ngIf="user.organigramma_obj">
          <i class="fas fa-sitemap mr-2"></i>{{user.organigramma_obj.name}}
        </ng-container>
      </h3>
    </div>
    <div class="col">
      <h3 *ngIf="user.classe_obj && user.classe_obj.classe">
        <i class="fas fa-chalkboard mr-2"></i>{{user.classe_obj.classe.name}}
        <span *ngIf="!user.classe_obj.classe.id" class="badge badge-soft-primary">Nuova</span>
        <span *ngIf="user.classe_obj.classe.id" class="badge badge-soft-success">Esistente</span>
      </h3>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-4">
      <h4>{{ user.familyName}} {{ user.givenName}}</h4>
      <span *ngIf="user.omonimie.length > 0" class="badge badge-warning mr-2">Omonimia</span>
      <span *ngIf="user.user_geniusuite && user.users_google?.length > 1" class="badge badge-danger mr-2">Possibile
        conflitto</span>
      <span *ngIf="user.errors?.length > 0" class="badge badge-danger mr-2">Errori
        presenti</span>
    </div>
    <div class="col-4 align-self-start">
      <small class="text-cap">Email</small>
      <div [title]="user.email" class="text-truncate">{{user.email}}</div>
      <small *ngIf="user.email_recovery" [title]="user.email_recovery"
        class="text-truncate">{{user.email_recovery}}<span *ngIf="user.new_email_recovery"
          class="badge badge-soft-warning ml-2">Aggiornamento</span></small>
    </div>
    <div class="col-2 align-self-start">
      <small class="text-cap">Cod. Id.</small>
      <div [title]="user.cf" class="text-truncate">{{user.cf || "-"}}</div>
    </div>
    <div class="col-2 align-self-center">
      <button class="btn btn-block btn-outline-danger btn-xs" (click)="deselectUserFromOmonimie($event, user)">
        <i class="far fa-user-slash mr-2"></i> {{'SETUP.preview_users.btn_escludi' | translate}}
      </button>
    </div>
  </div>
</ng-template>


<!--
.########.########..########...#######..########..####
.##.......##.....##.##.....##.##.....##.##.....##..##.
.##.......##.....##.##.....##.##.....##.##.....##..##.
.######...########..########..##.....##.########...##.
.##.......##...##...##...##...##.....##.##...##....##.
.##.......##....##..##....##..##.....##.##....##...##.
.########.##.....##.##.....##..#######..##.....##.####
-->
<div class="row mt-5" *ngIf="strutturaErrori?.length > 0">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body">

        <h2>
          {{'SETUP.preview_users.errori_titolo' | translate}}
        </h2>

        <p [innerHTML]="'SETUP.preview_users.errori_sottotitolo' | translate">

        </p>

        <ul class="list-group list-group-lg mb-3 w-100">
          <ng-container *ngFor="let element_user of strutturaErrori; let index_utente = index;">
            <li class="list-group-item alert-soft-danger">
              <ng-container>

                <ng-container *ngTemplateOutlet="userInfoTemplate;context:{user: element_user}"></ng-container>

                <div class="alert alert-danger mt-3 mb-0" *ngFor="let err of element_user.errors">
                  <span class="text-cap d-inline">Errore</span> {{ err.type | translate:err.data }}
                </div>
              </ng-container>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>

<!--
.##......##....###....########..##....##.####.##....##..######..
.##..##..##...##.##...##.....##.###...##..##..###...##.##....##.
.##..##..##..##...##..##.....##.####..##..##..####..##.##.......
.##..##..##.##.....##.########..##.##.##..##..##.##.##.##...####
.##..##..##.#########.##...##...##..####..##..##..####.##....##.
.##..##..##.##.....##.##....##..##...###..##..##...###.##....##.
..###..###..##.....##.##.....##.##....##.####.##....##..######..
-->

<div class="row mt-5" *ngIf="strutturaWarning?.length > 0">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body">

        <h2>
          {{'SETUP.preview_users.warning_titolo' | translate}}
        </h2>

        <p [innerHTML]="'SETUP.preview_users.warning_sottotitolo' | translate"></p>

        <ng-container *ngIf="strutturaWarning?.length > 2">

          <p [innerHTML]="'SETUP.preview_users.warning_sottotitolo_change_all' | translate"></p>

          <div class="row mb-5">
            <div class="col">
              <button class="btn btn-block btn-outline-primary btn-xs" (click)="changeUserEmailWithParametersAll()">
                <i class="fas fa-edit mr-2"></i>{{'SETUP.preview_users.btn_modifica_email_all' | translate}}
              </button>
            </div>
            <div class="col">
              <button class="btn btn-block btn-outline-primary btn-xs" (click)="deleteWarningsAll()">
                <i class="fas fa-check mr-2"></i>{{'SETUP.preview_users.btn_non_cambiare_email_all' | translate}}
              </button>
            </div>
            <div class="col">
              <button class="btn btn-block btn-outline-danger btn-xs" (click)="deselectAllUserFromWarnings()">
                <i class="far fa-user-slash mr-2"></i> {{'SETUP.preview_users.btn_escludi_all' | translate}}
              </button>
            </div>
          </div>
        </ng-container>

        <ul class="list-group list-group-lg mb-3 w-100">
          <ng-container *ngFor="let element_user of strutturaWarning; let index_utente = index;">
            <li class="list-group-item">
              <ng-container>

                <ng-container *ngTemplateOutlet="userInfoTemplate;context:{user: element_user}"></ng-container>

                <div class="row mt-2">
                  <div class="col">
                    <button class="btn btn-block btn-outline-primary"
                      (click)="changeUserEmailWithParameters($event, element_user)">
                      <i class="fas fa-edit mr-2"></i>{{'SETUP.preview_users.btn_modifica_email' | translate}}
                      <!-- {{element_user.email_generated_from_parameters}} -->
                    </button>
                  </div>
                  <div class="col-4">
                    <button class="btn btn-block btn-outline-primary" (click)="deleteWarnings($event, element_user)">
                      <i class="fas fa-check mr-2"></i>{{'SETUP.preview_users.btn_non_cambiare_email' | translate}}
                    </button>
                  </div>
                </div>

                <div class="alert alert-warning mt-3 mb-0" *ngFor="let warn of element_user.warnings">
                  <span class="text-cap d-inline">Attenzione</span> <span
                    [innerHTML]="warn.type | translate:warn.data"></span>
                </div>
              </ng-container>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>



<!--
..#######..##.....##..#######..##....##.####.##.....##.####.########
.##.....##.###...###.##.....##.###...##..##..###...###..##..##......
.##.....##.####.####.##.....##.####..##..##..####.####..##..##......
.##.....##.##.###.##.##.....##.##.##.##..##..##.###.##..##..######..
.##.....##.##.....##.##.....##.##..####..##..##.....##..##..##......
.##.....##.##.....##.##.....##.##...###..##..##.....##..##..##......
..#######..##.....##..#######..##....##.####.##.....##.####.########
-->

<div class="row mt-5" *ngIf="strutturaOmonimie?.length > 0">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body">

        <h2>
          {{'SETUP.preview_users.omonimie_titolo' | translate}}
        </h2>

        <p>{{'SETUP.preview_users.omonimie_sottotitolo' | translate}}</p>

        <ng-container *ngFor="let element_user of strutturaOmonimie; let index_utente = index; let first = first;">
          <ul class="list-group list-group-lg mb-3 w-100" [ngClass]="{'mt-3': !first}">
            <li class="list-group-item">
              <ng-container>
                <ng-container *ngTemplateOutlet="userInfoTemplate;context:{user: element_user}"></ng-container>
              </ng-container>
            </li>

            <!-- Omonimie -->
            <li class="list-group-item" *ngFor="let element_user_omonimo of element_user.omonimie">
              <div class="row" *ngIf="!element_user_omonimo.plesso_obj && !element_user_omonimo.classe_obj">
                <div class="col-8" *ngIf="element_user_omonimo.plesso_obj">
                  <i class="fas fa-school mr-2"></i>{{element_user_omonimo.plesso_obj.plesso.name}}
                </div>
                <div class="col" *ngIf="element_user_omonimo.classe_obj">
                  <i class="fas fa-chalkboard mr-2"></i>{{element_user_omonimo.classe_obj.classe.name}}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4">
                  {{ element_user_omonimo.familyName}} {{ element_user_omonimo.givenName}}
                </div>
                <div class="col-4 align-self-start">
                  <small class="text-cap">Email</small>
                  <div [title]="element_user_omonimo.email" class="text-truncate">{{element_user_omonimo.email}}</div>
                </div>
                <div class="col-2 align-self-start">
                  <small class="text-cap">Cod. Id.</small>
                  <div [title]="element_user_omonimo.cf" class="text-truncate">{{element_user_omonimo.cf}}</div>
                </div>
                <div class="col-2 align-self-center">
                  <button class="btn btn-block btn-outline-danger btn-xs"
                    (click)="deselectUserFromOmonimie($event, element_user, element_user_omonimo)">
                    <i class="far fa-user-slash mr-2"></i> {{'SETUP.preview_users.btn_escludi' | translate}}
                  </button>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <button class="btn btn-block btn-outline-primary"
                    (click)="linkUserToUser($event, element_user, element_user_omonimo)">
                    <i class="fas fa-link mr-2"></i>
                    {{'SETUP.preview_users.omonimie_btn_unisci' | translate}}
                  </button>
                </div>
                <div class="col" *ngIf="element_user_omonimo.email_fromparams">
                  <button class="btn btn-block btn-outline-primary"
                    (click)="changeUserEmailForOmonimia($event, element_user, element_user_omonimo)">
                    <i class="fas fa-not-equal mr-2"></i>
                    {{'SETUP.preview_users.omonimie_btn_separa' | translate}}
                  </button>
                </div>
              </div>
              <div *ngIf="!element_user_omonimo.email_fromparams" class="alert alert-warning mt-2 mb-0">
                <span class="text-cap d-inline">Attenzione</span> {{'SETUP.preview_users.omonimie_avviso' | translate}}
              </div>
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<ng-container *ngFor="let element_struttura of struttura; let index_elemento_struttura = index;">


  <!--
  .########..##.......########..######...######...#######.....########.....######..##..........###.....######...######..####
  .##.....##.##.......##.......##....##.##....##.##.....##....##..........##....##.##.........##.##...##....##.##....##..##.
  .##.....##.##.......##.......##.......##.......##.....##....##..........##.......##........##...##..##.......##........##.
  .########..##.......######....######...######..##.....##....######......##.......##.......##.....##..######...######...##.
  .##........##.......##.............##.......##.##.....##....##..........##.......##.......#########.......##.......##..##.
  .##........##.......##.......##....##.##....##.##.....##....##..........##....##.##.......##.....##.##....##.##....##..##.
  .##........########.########..######...######...#######.....########.....######..########.##.....##..######...######..####
  -->
  <div class="row mt-5" *ngIf="element_struttura.plesso">
    <div class="col">
      <div class="card card-hover-shadow">
        <div class="card-header card-btn btn-block border-bottom-0 pointer"
          [ngClass]="{'collapsed': element_struttura.isCollapsed}"
          (click)="element_struttura.isCollapsed = !element_struttura.isCollapsed">
          <h2 class="card-header-title">
            <i class="fas fa-school mr-2"></i>{{element_struttura.plesso.name}}
            <span *ngIf="!element_struttura.plesso.id" class="badge badge-soft-primary ml-2">Nuovo</span>
            <span *ngIf="element_struttura.plesso.id" class="badge badge-soft-success ml-2">Esistente</span>
            <a *ngIf="element_struttura.plesso.id" class="ml-2 btn-xs btn-icon btn-ghost-primary"
              routerLink="/plesso/{{element_struttura.plesso.id}}">
              <i class="far fa-external-link"></i>
            </a>
            <h5 class="mt-2">{{ 'STR.org_unit' | translate }}
              <ng-container *ngIf="element_struttura.plesso.google_org_unit_new">
                {{element_struttura.plesso.google_org_unit_new.orgUnitPath}}
                <span *ngIf="!element_struttura.plesso.google_org_unit_new.orgUnitId"
                  class="badge badge-soft-primary ml-2">Nuova</span>
                <span *ngIf="element_struttura.plesso.google_org_unit_new.orgUnitId"
                  class="badge badge-soft-success ml-2">Esistente</span>
              </ng-container>
              <ng-container *ngIf="element_struttura.plesso.google_org_unit">
                {{element_struttura.plesso.google_org_unit.orgUnitPath}}
                <span *ngIf="!element_struttura.plesso.google_org_unit.orgUnitId"
                  class="badge badge-soft-primary ml-2">Nuova</span>
                <span *ngIf="element_struttura.plesso.google_org_unit.orgUnitId"
                  class="badge badge-soft-success ml-2">Esistente</span>
              </ng-container>
            </h5>
            <h5 class="mt-2">Abbreviazione "{{element_struttura.plesso.short_name}}"</h5>
          </h2>
          <span class="card-btn-toggle" [attr.aria-expanded]="!element_struttura.isCollapsed"
            aria-controls="collapsePlesso">
            <span class="btn btn-xs btn-ghost-primary card-btn-toggle-default">
              <i class="fas fa-chalkboard mr-1"></i>
              Mostra le classi
            </span>
            <span class="btn btn-xs btn-ghost-primary card-btn-toggle-active">
              <i class="fas fa-chalkboard mr-1"></i>
              Nascondi le classi
            </span>
          </span>
        </div>
        <div class="card-body" id="collapsePlesso" [collapse]="element_struttura.isCollapsed" [isAnimated]="true">

          <ng-container>

            <div class="row justify-content-between mb-2">
              <div class="col text-right">
                <button class="btn btn-xs btn-ghost-primary"
                  (click)="element_struttura.showGroups = !element_struttura.showGroups">
                  <ng-template *ngIf="element_struttura.showGroups; then nascondi else mostra"></ng-template>
                  <ng-template #mostra>
                    <i class="fas fa-eye mr-1"></i>
                    Mostra i gruppi
                  </ng-template>
                  <ng-template #nascondi>
                    <i class="fas fa-eye-slash mr-1"></i>
                    Nascondi i gruppi
                  </ng-template>
                </button>
              </div>
            </div>

            <!-- Gruppi plesso -->
            <div class="row mb-3" *ngIf="element_struttura.showGroups">
              <ng-container *ngFor="let group of element_struttura.plesso.google_group">
                <div class="col-4" *ngIf="group.email">
                  <small class="text-cap">Gruppo {{group.relation_info}}
                    <span *ngIf="!group.groupKey && !group.id && group.email"
                      class="badge badge-soft-primary ml-2">Nuovo</span>
                    <span *ngIf="group.groupKey || group.id" class="badge badge-soft-success ml-2">Esistente</span>
                  </small>
                  <div [title]="group.email" class="text-truncate">
                    {{group.email}}</div>
                </div>
              </ng-container>
            </div>


            <ul class="list-group list-group-lg mb-3">
              <li class="list-group-item">
                <ng-container *ngFor="let element_classe of element_struttura.classi; let index_classe = index;">
                  <div class="row align-items-center my-3 pointer" *ngIf="element_classe.classe.selected"
                    (click)="element_classe.isCollapsed = !element_classe.isCollapsed">
                    <div class="col-2">
                      <span class="font-weight-bold text-dark lead"><i
                          class="fas fa-chalkboard mr-2"></i>{{element_classe.classe.name}}</span><br>
                      <span *ngIf="!element_classe.classe.id" class="badge badge-soft-primary">Nuova</span>
                      <span *ngIf="element_classe.classe.id" class="badge badge-soft-success">Esistente</span>
                      <a *ngIf="element_struttura.plesso.id && element_classe.classe.id"
                        class="ml-2 btn-xs btn-icon btn-ghost-primary"
                        routerLink="/plesso/{{element_struttura.plesso.id}}/classe/{{element_classe.classe.id}}">
                        <i class="far fa-external-link"></i>
                      </a>
                    </div>
                    <div class="col-2 align-self-start">
                      <small class="text-cap">{{ 'STR.Utenti' | translate }}</small>
                      <span class="font-weight-bold text-dark lead">{{element_classe.countSelected}}</span>
                    </div>
                    <div class="col-3 align-self-start">
                      <small class="text-cap">{{ 'STR.org_unit' | translate }}
                        <span *ngIf="element_classe.classe.google_org_unit_new"
                          class="badge badge-soft-primary">Nuova</span>
                        <span *ngIf="element_classe.classe.google_org_unit"
                          class="badge badge-soft-success">Esistente</span>
                      </small>
                      <div class="text-truncate">
                        {{element_classe.classe.google_org_unit_new?.orgUnitPath ||
                        element_classe.classe.google_org_unit?.orgUnitPath}}
                      </div>
                    </div>
                    <div class="col text-right">
                      <button class="btn btn-xs btn-ghost-primary">
                        <ng-template *ngIf="element_classe.isCollapsed; then mostra else nascondi"></ng-template>
                        <ng-template #mostra>
                          <i class="fas fa-users mr-1"></i>
                          Mostra utenti
                        </ng-template>
                        <ng-template #nascondi>
                          <i class="fas fa-users-slash mr-1"></i>
                          Nascondi utenti
                        </ng-template>
                      </button>
                    </div>
                  </div>

                  <!-- Gruppi classe -->
                  <div class="row align-items-center my-3"
                    *ngIf="element_classe.classe.selected && element_struttura.showGroups">
                    <div class="col-2"></div>
                    <ng-container *ngFor="let group of element_classe.classe.google_group">
                      <div class="col-3" *ngIf="group.relation_info">
                        <small class="text-cap">Gruppo {{group.relation_info}}
                          <span *ngIf="!group.groupKey && !group.id && group.email"
                            class="badge badge-soft-primary ml-2">Nuovo</span>
                          <span *ngIf="group.groupKey || group.id"
                            class="badge badge-soft-success ml-2">Esistente</span>
                        </small>
                        <div [title]="group.email" class="text-truncate">
                          {{group.email || "Non gestito in questa importazione"}}</div>
                      </div>
                    </ng-container>
                  </div>

                  <!--
                    .##.....##..######..########.########.
                    .##.....##.##....##.##.......##.....##
                    .##.....##.##.......##.......##.....##
                    .##.....##..######..######...########.
                    .##.....##.......##.##.......##...##..
                    .##.....##.##....##.##.......##....##.
                    ..#######...######..########.##.....##
                    -->
                  <div class="row mb-5" id="collapseClasse" [collapse]="element_classe.isCollapsed" [isAnimated]="true">
                    <ul class="list-group list-group-lg mb-3 w-100">
                      <ng-container *ngFor="let element_user of element_classe.users; let index_utente = index;">
                        <li class="list-group-item" [ngClass]="{'alert-soft-danger': element_user.errors?.length > 0}">
                          <ng-container>
                            <div class="row">
                              <div class="col-4">
                                <div class="row">
                                  <div class="col-auto">
                                    <div class="form-group mb-0">
                                      <div class="custom-control custom-checkbox">
                                        <input type="checkbox"
                                          id="checkboxSelectPlesso{{index_elemento_struttura}}Classe{{index_classe}}Utente{{index_utente}}"
                                          class="custom-control-input" [checked]="element_user.selected"
                                          [disabled]="element_user.disabled"
                                          (change)="OnCheckboxUserChanged($event, element_struttura, element_classe, element_user)">
                                        <label class="custom-control-label"
                                          for="checkboxSelectPlesso{{index_elemento_struttura}}Classe{{index_classe}}Utente{{index_utente}}"></label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col">
                                    {{ element_user.familyName}} {{ element_user.givenName}}<br>
                                    <ng-container *ngIf="element_user.selected">
                                      <span *ngIf="!element_user.user_geniusuite && !element_user.google_user"
                                        class="badge badge-soft-primary mr-2">Nuovo</span>
                                      <span *ngIf="!element_user.user_geniusuite && element_user.google_user"
                                        class="badge badge-soft-warning mr-2">Importazione in Geniusuite</span>
                                    </ng-container>
                                    <span *ngIf="element_user.omonimie.length > 0"
                                      class="badge badge-warning mr-2">Omonimia</span>
                                    <span *ngIf="element_user.user_geniusuite && element_user.users_google?.length > 1"
                                      class="badge badge-danger mr-2">Conflitto</span>
                                    <span *ngIf="element_user.errors?.length > 0"
                                      class="badge badge-danger mr-2">Errori</span>
                                    <span *ngIf="element_user.warnings?.length > 0"
                                      class="badge badge-danger mr-2">Incongruenze</span>
                                    <span *ngIf="element_user.disabled"
                                      class="badge badge-secondary mr-2">Disabilitato</span>
                                    <div *ngIf="element_user.materie && element_user.materie.length > 0">
                                      <i class="fas fa-books mr-2"></i>
                                      <ul class="d-inline list-inline list-separator">
                                        <li class="list-inline-item"
                                          *ngFor="let element_user_materia of element_user.materie">
                                          <small>{{element_user_materia.name}}</small>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-4 align-self-start">
                                <small class="text-cap">Email</small>
                                <div [title]="element_user.email" class="text-truncate">{{element_user.email}}</div>
                                <small *ngIf="element_user.password">
                                  <b>Password:</b>&nbsp;{{element_user.password}}<br>
                                </small>
                                <small
                                  *ngIf="element_user.new_password && element_user.new_password != element_user.password">
                                  <b>Password:</b>&nbsp;{{element_user.new_password}} <span
                                    class="badge badge-soft-warning ml-2">Aggiornamento</span><br>
                                </small>
                                <small *ngIf="element_user.email_recovery" [title]="element_user.email_recovery"
                                  class="text-truncate"><b>Recovery email:</b>&nbsp;{{element_user.email_recovery}}<span
                                    *ngIf="element_user.new_email_recovery"
                                    class="badge badge-soft-warning ml-2">Aggiornamento</span></small>
                              </div>
                              <div class="col-3 align-self-start">
                                <small class="text-cap">Cod. Id.</small>
                                <div [title]="element_user.cf" class="text-truncate">{{element_user.cf || "-"}}<span
                                    *ngIf="element_user.new_cf"
                                    class="badge badge-soft-warning ml-2">Aggiornamento</span>
                                </div>
                              </div>
                              <div class="col-1 align-self-center">
                                <i *ngIf="element_user.errors?.length > 0 || element_user.omonimie?.length > 0 || element_user.warnings?.length > 0"
                                  class="fas fa-exclamation-circle text-danger"></i>
                              </div>
                            </div>
                            <div class="row"
                              *ngIf="element_user.userTypeSelected == 'studente' && element_user.user_geniusuite && element_user.user_geniusuite?.google_user?.orgUnitPath != (element_classe.classe.google_org_unit_new?.orgUnitPath || element_classe.classe.google_org_unit?.orgUnitPath)">
                              <div class="col offset-8">
                                <small class="text-cap mr-2">Unità organizzativa</small>
                                {{element_user.user_geniusuite?.google_user?.orgUnitPath}}
                                <i *ngIf="element_user.user_geniusuite?.google_user?.orgUnitPath"
                                  class="fas fa-arrow-alt-right mx-3"></i>
                                {{element_classe.classe.google_org_unit_new?.orgUnitPath ||
                                element_classe.classe.google_org_unit?.orgUnitPath}}
                              </div>
                            </div>
                            <div class="alert alert-soft-info mt-3 mb-0" *ngFor="let info of element_user.info">
                              <span class="text-cap d-inline mr-2">Attenzione</span><span
                                [innerHTML]="info.type | translate:info.data"></span>
                            </div>
                          </ng-container>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </ng-container>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!--
  ..#######..########...######......###....##....##.####..######...########.....###....##.....##.##.....##....###...
  .##.....##.##.....##.##....##....##.##...###...##..##..##....##..##.....##...##.##...###...###.###...###...##.##..
  .##.....##.##.....##.##.........##...##..####..##..##..##........##.....##..##...##..####.####.####.####..##...##.
  .##.....##.########..##...####.##.....##.##.##.##..##..##...####.########..##.....##.##.###.##.##.###.##.##.....##
  .##.....##.##...##...##....##..#########.##..####..##..##....##..##...##...#########.##.....##.##.....##.#########
  .##.....##.##....##..##....##..##.....##.##...###..##..##....##..##....##..##.....##.##.....##.##.....##.##.....##
  ..#######..##.....##..######...##.....##.##....##.####..######...##.....##.##.....##.##.....##.##.....##.##.....##
  -->
  <div class="row mt-5" *ngIf="element_struttura.organigramma">
    <div class="col">
      <div class="card card-hover-shadow">
        <div class="card-header card-btn btn-block border-bottom-0 pointer"
          [ngClass]="{'collapsed': element_struttura.isCollapsed}"
          (click)="element_struttura.isCollapsed = !element_struttura.isCollapsed">
          <h2 class="card-header-title">
            <i class="fas fa-sitemap mr-2"></i>{{element_struttura.organigramma.name}}
            <span *ngIf="!element_struttura.organigramma.id" class="badge badge-soft-primary ml-2">Nuovo</span>
            <span *ngIf="element_struttura.organigramma.id" class="badge badge-soft-success ml-2">Esistente</span>
            <a *ngIf="element_struttura.organigramma.id" class="ml-2 btn-xs btn-icon btn-ghost-primary"
              routerLink="/organigramma/{{element_struttura.organigramma.id}}">
              <i class="far fa-external-link"></i>
            </a>
            <h5 class="mt-2">{{ 'STR.org_unit' | translate }}
              <ng-container *ngIf="element_struttura.organigramma.google_org_unit">
                {{element_struttura.organigramma.google_org_unit.orgUnitPath}}
                <span *ngIf="!element_struttura.organigramma.google_org_unit.orgUnitId"
                  class="badge badge-soft-primary ml-2">Nuova</span>
                <span *ngIf="element_struttura.organigramma.google_org_unit.orgUnitId"
                  class="badge badge-soft-success ml-2">Esistente</span>
              </ng-container>
            </h5>
          </h2>
          <span class="card-btn-toggle" [attr.aria-expanded]="!element_struttura.isCollapsed"
            aria-controls="collapseOrganigramma">
            <span class="btn btn-xs btn-ghost-primary card-btn-toggle-default">
              <i class="fas fa-users mr-1"></i>
              Mostra gli utenti
            </span>
            <span class="btn btn-xs btn-ghost-primary card-btn-toggle-active">
              <i class="fas fa-users mr-1"></i>
              Nascondi gli utenti
            </span>
          </span>
        </div>
        <div class="card-body" id="collapseOrganigramma">
          <span [collapse]="element_struttura.isCollapsed" [isAnimated]="true">
            <ng-container>

              <div class="row justify-content-between mb-2">
                <div class="col text-right">
                  <button class="btn btn-xs btn-ghost-primary"
                    (click)="element_struttura.showGroups = !element_struttura.showGroups">
                    <ng-template *ngIf="element_struttura.showGroups; then nascondi else mostra"></ng-template>
                    <ng-template #mostra>
                      <i class="fas fa-eye mr-1"></i>
                      Mostra i gruppi
                    </ng-template>
                    <ng-template #nascondi>
                      <i class="fas fa-eye-slash mr-1"></i>
                      Nascondi i gruppi
                    </ng-template>
                  </button>
                </div>
              </div>

              <!-- Gruppi organigramma -->
              <div class="row mb-3" *ngIf="element_struttura.showGroups">
                <ng-container *ngFor="let group of element_struttura.organigramma.google_group">
                  <div class="col-4" *ngIf="group.email">
                    <small class="text-cap">Gruppo {{group.relation_info}}
                      <span *ngIf="!group.groupKey && !group.id && group.email"
                        class="badge badge-soft-primary ml-2">Nuovo</span>
                      <span *ngIf="group.groupKey || group.id" class="badge badge-soft-success ml-2">Esistente</span>
                    </small>
                    <div [title]="group.email" class="text-truncate">
                      {{group.email}}</div>
                  </div>
                </ng-container>
              </div>

              <!--
              .##.....##..######..########.########.
              .##.....##.##....##.##.......##.....##
              .##.....##.##.......##.......##.....##
              .##.....##..######..######...########.
              .##.....##.......##.##.......##...##..
              .##.....##.##....##.##.......##....##.
              ..#######...######..########.##.....##
              -->
              <ul class="list-group list-group-lg mb-3 w-100">
                <ng-container *ngFor="let element_user of element_struttura.users; let index_utente = index;">
                  <li class="list-group-item" [ngClass]="{'alert-soft-danger': element_user.errors?.length > 0}">
                    <ng-container>
                      <div class="row">
                        <div class="col-4">
                          <div class="row">
                            <div class="col-auto">
                              <div class="form-group mb-0">
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox"
                                    id="checkboxSelectPlesso{{index_elemento_struttura}}Classe{{index_classe}}Utente{{index_utente}}"
                                    class="custom-control-input" [checked]="element_user.selected"
                                    [disabled]="element_user.disabled"
                                    (change)="OnCheckboxUserChanged($event, element_struttura, element_struttura, element_user)">
                                  <label class="custom-control-label"
                                    for="checkboxSelectPlesso{{index_elemento_struttura}}Classe{{index_classe}}Utente{{index_utente}}"></label>
                                </div>
                              </div>
                            </div>
                            <div class="col">
                              {{ element_user.familyName}} {{ element_user.givenName}}<br>
                              <ng-container *ngIf="element_user.selected">
                                <span *ngIf="!element_user.user_geniusuite && !element_user.google_user"
                                  class="badge badge-soft-primary mr-2">Nuovo</span>
                                <span *ngIf="!element_user.user_geniusuite && element_user.google_user"
                                  class="badge badge-soft-warning mr-2">Importazione in Geniusuite</span>
                              </ng-container>
                              <span *ngIf="element_user.omonimie.length > 0"
                                class="badge badge-warning mr-2">Omonimia</span>
                              <span *ngIf="element_user.user_geniusuite && element_user.users_google?.length > 1"
                                class="badge badge-danger mr-2">Conflitto</span>
                              <span *ngIf="element_user.errors?.length > 0"
                                class="badge badge-danger mr-2">Errori</span>
                              <span *ngIf="element_user.warnings?.length > 0"
                                class="badge badge-danger mr-2">Incongruenze</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-4 align-self-start">
                          <small class="text-cap">Email</small>
                          <div [title]="element_user.email" class="text-truncate">{{element_user.email}}</div>
                          <small *ngIf="element_user.password"><b>Password:</b>&nbsp;{{element_user.password}}</small>
                          <small
                            *ngIf="element_user.new_password && element_user.new_password != element_user.password">
                            <b>Password:</b>&nbsp;{{element_user.new_password}} <span
                              class="badge badge-soft-warning ml-2">Aggiornamento</span>
                          </small>
                          <small *ngIf="element_user.email_recovery" [title]="element_user.email_recovery"
                            class="text-truncate"><b>Recovery email:</b>&nbsp;{{element_user.email_recovery}}<span
                              *ngIf="element_user.new_email_recovery"
                              class="badge badge-soft-warning ml-2">Aggiornamento</span></small>
                        </div>
                        <div class="col-3 align-self-start">
                          <small class="text-cap">Cod. Id.</small>
                          <div [title]="element_user.cf" class="text-truncate">{{element_user.cf || "-"}}<span
                              *ngIf="element_user.new_cf" class="badge badge-soft-warning ml-2">Aggiornamento</span>
                          </div>
                        </div>
                        <div class="col-1 align-self-center">
                          <i *ngIf="element_user.errors?.length > 0 || element_user.omonimie?.length > 0 || element_user.warnings?.length > 0"
                            class="fas fa-exclamation-circle text-danger"></i>
                        </div>
                      </div>
                      <div class="row" *ngIf="element_struttura.organigramma.google_org_unit">
                        <div class="col offset-8"
                          *ngIf="element_user.google_user && element_user.google_user.orgUnitPath != element_struttura.organigramma.google_org_unit.orgUnitPath">
                          <small class="text-cap mr-2">Unità organizzativa</small>
                          {{element_user.google_user?.orgUnitPath}}
                          <ng-container *ngIf="paramSpostaInOrgUnitOrganigramma">
                            <i class="fas fa-arrow-alt-right mx-3"></i>
                            {{element_struttura.organigramma.google_org_unit.orgUnitPath}}
                          </ng-container>
                        </div>
                      </div>
                      <div class="alert alert-soft-info mt-3 mb-0" *ngFor="let info of element_user.info">
                        <span class="text-cap d-inline mr-2">Attenzione</span><span
                          [innerHTML]="info.type | translate:info.data"></span>
                      </div>
                    </ng-container>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!--
.########..#######...#######..########.########.########.
.##.......##.....##.##.....##....##....##.......##.....##
.##.......##.....##.##.....##....##....##.......##.....##
.######...##.....##.##.....##....##....######...########.
.##.......##.....##.##.....##....##....##.......##...##..
.##.......##.....##.##.....##....##....##.......##....##.
.##........#######...#######.....##....########.##.....##
-->

<div class="row mt-5">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body">
        <div class="alert alert-soft-danger"
          *ngIf="strutturaErrori?.length > 0 || strutturaOmonimie?.length > 0 || strutturaWarning?.length > 0"
          [innerHTML]="'SETUP.preview_users.errori_presenti' | translate"></div>
        <div class="row justify-content-between align-items-center">
          <div class="col-auto py-1">
            <button (click)="back()" type="button" class="btn btn-white">
              <i class="far fa-chevron-left mr-1"></i> Indietro
            </button>
          </div>
          <div class="col-auto py-1">
            <button (click)="next()" type="button" class="btn btn-primary"
              [disabled]="!strutturaErrori || strutturaErrori?.length > 0 || !strutturaOmonimie || strutturaOmonimie?.length > 0 || strutturaWarning?.length > 0 || !struttura || struttura.length == 0 || countUtenti == 0 || importStarted">
              Avanti <i class="far fa-chevron-right ml-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
