import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { forbiddenStringValidator } from 'src/app/forms/helpers/name.validator';
import { ParamsStringValidator } from 'src/app/forms/helpers/params.directive';
import { paramsValidator } from 'src/app/forms/helpers/params.validator';
import { ParametriService, ParamType } from 'src/app/services/general/parametri.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-gsuite-parameters',
  templateUrl: './gsuite-parameters.component.html',
  styleUrls: ['./gsuite-parameters.component.scss'],
})
export class GsuiteParametersComponent implements OnInit {

  // TODO Default Value Paramtri in base alla lingua selzionata
  data: any = {
    docente_email: '$nome$.$cognome$',
    docente_email_error_ignore: false,
    studente_email: '$nome$.$cognome$',
    studente_email_error_ignore: false,
    workers_email: '$nome$.$cognome$',
    workers_email_error_ignore: false,
    groups_email: '$name_group$',
    groups_email_error_ignore: false,
    gest_group_plesso: false,
    group_plesso_email: '$short_name_plesso$',
    group_plesso_email_error_ignore: false,
    group_plesso_docente_email: 'docenti.$short_name_plesso$',
    group_plesso_docente_email_error_ignore: false,
    group_plesso_studente_email: 'studenti.$short_name_plesso$',
    group_plesso_studente_email_error_ignore: false,
    gest_group_classe: false,
    group_classe_email: 'classe.$short_name_plesso$.$name_classe$',
    group_classe_email_error_ignore: false,
    group_classe_docente_email: 'docenti.$short_name_plesso$.$name_classe$',
    group_classe_docente_email_error_ignore: false,
    group_classe_studente_email: '$short_name_plesso$.$name_classe$',
    group_classe_studente_email_error_ignore: false,
    group_organigramma_email: '$name_organigramma$',
    group_organigramma_email_error_ignore: false,
  };

  domains: any = [];
  error: any;
  loadingBackend: boolean;
  loadingGoogle: boolean;
  formLoading: boolean = false;

  groupSettingCollapse: any = {
    organigramma: false,
    plesso: false,
    plesso_docente: false,
    plesso_studente: false,
    classi: false,
    classi_docente: false,
    classi_studente: false,
    gruppi_generici: false
  };

  formTest: any;

  myForm: FormGroup = new FormGroup({});
  eventsSubject: Subject<any> = new Subject<any>();
  validateEventSubject: Subject<any> = new Subject<any>();
  setValueEventSubject: Subject<any> = new Subject<any>();

  constructor(
    private google: GoogleDirectoryService,
    private paramService: ParametriService,
    private toast: ToastService
  ) {

  }

  ngOnInit() {
    this.getGoogleData();
  }

  // Init Form Data
  initForm() {
    this.myForm = new FormGroup({
      utenti: new FormGroup({
        docente_email: new FormControl(this.data.docente_email, [Validators.required, paramsValidator('$nome$|$nome:1$|$cf$,$cognome$|$cognome:1$|$cf$')]),
        docente_email_error_ignore: new FormControl(this.data.docente_email_error_ignore),
        docente_domain: new FormControl(this.data.docente_domain, [Validators.required]),
        docente_email_accento: new FormControl((this.data.docente_email_accento || 0), [Validators.required]),
        studente_email: new FormControl(this.data.studente_email, [Validators.required, paramsValidator('$nome$|$nome:1$|$cf$,$cognome$|$cognome:1$|$cf$')]),
        studente_email_error_ignore: new FormControl(this.data.studente_email_error_ignore),
        studente_domain: new FormControl(this.data.studente_domain, [Validators.required]),
        studente_email_accento: new FormControl((this.data.studente_email_accento || 0), [Validators.required]),
        workers_email: new FormControl(this.data.workers_email, [Validators.required, paramsValidator('$nome$|$nome:1$|$cf$,$cognome$|$cognome:1$|$cf$')]),
        workers_email_error_ignore: new FormControl(this.data.workers_email_error_ignore),
        workers_domain: new FormControl(this.data.workers_domain, [Validators.required]),
        workers_email_accento: new FormControl((this.data.workers_email_accento || 0), [Validators.required]),
      }),
      organigramma: new FormGroup({
        group_organigramma: new FormControl(this.data.group_organigramma_email, [Validators.required]),
        group_organigramma_email: new FormControl(this.data.group_organigramma_email, [Validators.required, paramsValidator('$name_organigramma$')]),
        group_organigramma_email_error_ignore: new FormControl(this.data.group_organigramma_email_error_ignore),
        group_organigramma_domain: new FormControl(this.data.group_organigramma_domain, [Validators.required]),
        group_organigramma_email_accento: new FormControl((this.data.group_organigramma_email_accento || 0), [Validators.required]),
      }),
      plesso: new FormGroup({
        group_plesso_email: new FormControl(this.data.group_plesso_email, [Validators.required, paramsValidator('$name_plesso$|$short_name_plesso$')]),
        group_plesso_email_error_ignore: new FormControl(this.data.group_plesso_email_error_ignore),
        group_plesso_domain: new FormControl(this.data.group_plesso_domain, [Validators.required]),
        group_plesso_email_accento: new FormControl((this.data.group_plesso_email_accento || 0), [Validators.required]),
        gest_group_plesso: new FormControl((this.data.gest_group_plesso || 0), [Validators.required]),
        group_plesso_docente_email: new FormControl(this.data.group_plesso_docente_email, [Validators.required, paramsValidator('$name_plesso$|$short_name_plesso$')]),
        group_plesso_docente_email_error_ignore: new FormControl(this.data.group_plesso_docente_email_error_ignore),
        group_plesso_docente_domain: new FormControl(this.data.group_plesso_docente_domain, [Validators.required]),
        group_plesso_docente_email_accento: new FormControl((this.data.group_plesso_docente_email_accento || 0), [Validators.required]),
        group_plesso_studente_email: new FormControl(this.data.group_plesso_studente_email, [Validators.required, paramsValidator('$name_plesso$|$short_name_plesso$')]),
        group_plesso_studente_email_error_ignore: new FormControl(this.data.group_plesso_studente_email_error_ignore),
        group_plesso_studente_domain: new FormControl(this.data.group_plesso_studente_domain, [Validators.required]),
        group_plesso_studente_email_accento: new FormControl((this.data.group_plesso_studente_email_accento || 0), [Validators.required])
      }),
      classi: new FormGroup({
        group_classe_email: new FormControl(this.data.group_classe_email, [Validators.required, paramsValidator('$name_classe$')]),
        group_classe_email_error_ignore: new FormControl(this.data.group_classe_email_error_ignore),
        group_classe_domain: new FormControl(this.data.group_classe_domain, [Validators.required]),
        group_classe_email_accento: new FormControl((this.data.group_classe_email_accento || 0), [Validators.required]),
        gest_group_classe: new FormControl((this.data.gest_group_classe || 0), [Validators.required]),
        group_classe_docente_email: new FormControl(this.data.group_classe_docente_email, [Validators.required, paramsValidator('$name_classe$')]),
        group_classe_docente_email_error_ignore: new FormControl(this.data.group_classe_docente_email_error_ignore),
        group_classe_docente_domain: new FormControl(this.data.group_classe_docente_domain, [Validators.required]),
        group_classe_docente_email_accento: new FormControl((this.data.group_classe_docente_email_accento || 0), [Validators.required]),
        group_classe_studente_email: new FormControl(this.data.group_classe_studente_email, [Validators.required, paramsValidator('$name_classe$')]),
        group_classe_studente_email_error_ignore: new FormControl(this.data.group_classe_studente_email_error_ignore),
        group_classe_studente_domain: new FormControl(this.data.group_classe_studente_domain, [Validators.required]),
        group_classe_studente_email_accento: new FormControl((this.data.group_classe_studente_email_accento || 0), [Validators.required]),

      }),
      gruppi_generici: new FormGroup({
        groups_email: new FormControl(this.data.groups_email, [Validators.required, paramsValidator('$name_group$')]),
        groups_email_error_ignore: new FormControl(this.data.groups_email_error_ignore),
        groups_domain: new FormControl(this.data.groups_domain, [Validators.required]),
        groups_email_accento: new FormControl((this.data.groups_email_accento || 0), [Validators.required]),
      }),
      setting: new FormGroup({
        group_generic: new FormControl(this.data.group_generic),
        group_organigramma: new FormControl(this.data.group_organigramma),
        group_classe: new FormControl(this.data.group_generic),
        group_classe_docente: new FormControl(this.data.group_generic),
        group_classe_studente: new FormControl(this.data.group_generic),
        group_plesso: new FormControl(this.data.group_generic),
        group_plesso_docente: new FormControl(this.data.group_generic),
        group_plesso_studente: new FormControl(this.data.group_generic),
      })
    });


    // Per la gestione
    let checkIgnoreError = [
      "utenti.docente_email",
      "utenti.studente_email",
      "utenti.workers_email",
      "organigramma.group_organigramma_email",
      "plesso.group_plesso_email",
      "plesso.group_plesso_docente_email",
      "plesso.group_plesso_studente_email",
      "classi.group_classe_email",
      "classi.group_classe_docente_email",
      "classi.group_classe_studente_email",
      "gruppi_generici.groups_email"
    ];

    checkIgnoreError.forEach(element => {
      this.data[element.split(".")[1] + "_error_ignore"] = !this.myForm.get(element).valid;
      this.myForm.get(element + "_error_ignore").setValue(!this.myForm.get(element).valid);
      this.changeIgnoreError(element, element.split(".")[1] + "_tag");
    });
  }

  /**
   * Setta i domini in base a quello verificato e pimario, se no al primo della lista
   */
  setDomains() {
    if (this.domains.length > 0) {
      let domainNameDefault = (this.domains.find(x => x.isPrimary == true && x.verified == true) || (this.domains.find(x => x.verified == true) || this.domains[0])).domainName;

      this.data.docente_domain = domainNameDefault;
      this.data.studente_domain = domainNameDefault;
      this.data.workers_domain = domainNameDefault;
      this.data.groups_domain = domainNameDefault;
      this.data.group_plesso_domain = domainNameDefault;
      this.data.group_plesso_docente_domain = domainNameDefault;
      this.data.group_plesso_studente_domain = domainNameDefault;
      this.data.group_classe_domain = domainNameDefault;
      this.data.group_classe_docente_domain = domainNameDefault;
      this.data.group_classe_studente_domain = domainNameDefault;
      this.data.group_organigramma_domain = domainNameDefault;
    }
  }

  getBackendData() {
    this.loadingBackend = true;

    this.paramService.all(
      (response) => {
        if (response != null)
          this.data = { ...this.data, ...response };

        this.initForm();
        this.loadingBackend = false;
      },
      (error) => {
        this.error = error;
        this.loadingBackend = false;
      }
    );
  }

  getGoogleData() {
    this.loadingGoogle = true;

    this.google.domainsList(
      (result) => {
        this.domains = result;
        this.setDomains();
        this.loadingGoogle = false;
        this.getBackendData();
      },
      (error) => {
        this.loadingGoogle = false;
      }
    );
  }

  /**
   * Aggiunge una string ad un determinato parametro
   * @param stringToAdd
   * @param param
   */
  addParamTo(stringToAdd: string, tagsIdAdd) {
    // Evento mandato al Child per l'aggiunta del tag, valorizzazione esterna al child ma tramite parent
    this.eventsSubject.next({
      name: stringToAdd,
      tagId: tagsIdAdd
    });
  }

  onInputTagInput($event, param: FormControl, checkUnique = null) {
    param.setValue($event.value);
    if (checkUnique) {
      this.checkAllUnique(checkUnique);
    }

    // Evento mandato al Child per la Validazione
    this.validateEventSubject.next({
      value: param.valid,
      tagId: $event.tagId
    });
  }

  setGroupSettingCollapse(collapseId) {
    Object.keys(this.groupSettingCollapse).forEach(v => {
      if (v == collapseId) {
        this.groupSettingCollapse[collapseId] = !this.groupSettingCollapse[collapseId];
      } else {
        this.groupSettingCollapse[v] = false;
      }
    })
  }

  onSubmit() {
    let resReq = { ...this.data, ...this.myForm.get('utenti').value, ...this.myForm.get('plesso').value, ...this.myForm.get('classi').value, ...this.myForm.get('organigramma').value, ...this.myForm.get('gruppi_generici').value, ...this.myForm.get('setting').value };
    if (this.myForm.valid) {
      //  controllo che tutti i parametri siano diversi
      if (!this.checkAllParametersUnique(resReq)) {
        this.toast.error(
          'Attenzione, alcuni gruppi hanno parametri uguali',
          "Controlla i parametri dei gruppi"
        );
        return;
      }
      this.formLoading = true;

      this.paramService.update(
        resReq,
        (response) => {
          this.data = { ...this.data, ...response };
          this.initForm();
          this.formLoading = false;
        },
        (error) => {
          this.error = error;
          this.formLoading = false;
        }
      );
    }
  }


  /* Controlla che i parametri dei gruppi siano unici */
  checkAllParametersUnique(params: any) {

    let arrayOfGroupParamsPlesso = [
      params.group_plesso_email,
      params.group_plesso_docente_email,
      params.group_plesso_studente_email
    ];
    let arrayOfGroupParamsClasse = [
      params.group_classe_email,
      params.group_classe_docente_email,
      params.group_classe_studente_email
    ];

    if ([...new Set(arrayOfGroupParamsPlesso)].length != arrayOfGroupParamsPlesso.length)
      return false;

    if ([...new Set(arrayOfGroupParamsClasse)].length != arrayOfGroupParamsClasse.length)
      return false;

    return true;
  }

  /* Controlla che i parametri dei gruppi siano unici */
  checkAllUnique(position: string) {
    let arrControlKey = this.getArrControlKey(position);
    this._setUniqueError(arrControlKey);
  }

  private getArrControlKey(position) {
    let arrControlKey = [];

    switch (position) {
      case "plesso":
        arrControlKey = [
          {
            key: "plesso.group_plesso_email",
            tagId: "group_plesso_email_tag",
            value: this.myForm.get("plesso.group_plesso_email").value
          },
          {
            key: "plesso.group_plesso_docente_email",
            tagId: "group_plesso_docente_email_tag",
            value: this.myForm.get("plesso.group_plesso_docente_email").value
          }, {
            key: "plesso.group_plesso_studente_email",
            tagId: "group_plesso_studente_email_tag",
            value: this.myForm.get("plesso.group_plesso_studente_email").value
          }
        ];
        break;
      case "classi":
        arrControlKey = [
          {
            key: "classi.group_classe_email",
            tagId: "group_classe_email_tag",
            value: this.myForm.get("classi.group_classe_email").value
          },
          {
            key: "classi.group_classe_docente_email",
            tagId: "group_classe_docente_email_tag",
            value: this.myForm.get("classi.group_classe_docente_email").value
          }, {
            key: "classi.group_classe_studente_email",
            tagId: "group_classe_studente_email_tag",
            value: this.myForm.get("classi.group_classe_studente_email").value
          }
        ];
        break;
    }

    return arrControlKey;
  }

  private _setUniqueError(arrControlKey) {
    let getDuplicates = function (array) {
      var duplicates = {};
      for (var i = 0; i < array.length; i++) {
        if (duplicates.hasOwnProperty(array[i])) {
          duplicates[array[i]].push(i);
        } else if (array.lastIndexOf(array[i]) !== i) {
          duplicates[array[i]] = [i];
        }
      }

      // Return Index Duplicate Value
      let arrValueIndex = [];
      for (var key in duplicates) {
        arrValueIndex = [...arrValueIndex, ...Object.values(duplicates[key])];
      }
      return arrValueIndex;
    };

    let structIndexDuplicateValue = getDuplicates(arrControlKey.map(x => x.value));

    for (let index = 0; index < arrControlKey.length; index++) {
      if (structIndexDuplicateValue.includes(index)) {
        this.myForm.get(arrControlKey[index].key).setErrors({
          'same': true
        });
      } else {
        if (this.myForm.get(arrControlKey[index].key).hasError('same')) {
          this.myForm.get(arrControlKey[index].key).setErrors(null);
        }
      }

      this.validateEventSubject.next({
        value: this.myForm.get(arrControlKey[index].key).valid,
        tagId: arrControlKey[index].tagId
      });
    }
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  selectTab(index) {
    switch (index) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        break;
    }
  }

  changeValueSwitch(category, position, value) {
    // Assegnamento numerico perchè si scazza il routing per controllo se ci sono tutti parametri
    let concatPosition = category + "." + position;
    this.myForm.get(concatPosition).setValue(value ? 1 : 0);
    this.data[position] = value ? 1 : 0;
  }

  loadPreviewParameter(parType: ParamType) {
    let resReq = { ...this.data, ...this.myForm.get('utenti').value, ...this.myForm.get('plesso').value, ...this.myForm.get('classi').value, ...this.myForm.get('organigramma').value, ...this.myForm.get('gruppi_generici').value };

    let objParametro = {
      givenName: "Mario'",
      familyName: "Rossi'",
      cf: "RSSMRA05A01C816Y",
      name: "Istituto di Istruzione Superiore",
      short_name: "IIS'",
      name_plesso: "Istituto di Istruzione Superiore",
      short_name_plesso: "IIS'",
      name_classe: "1A",
      anno_classe: 2021,
      group_name: "Gruppo Generico",
      organigramma_name: "Docenti di Matematica"
    }

    return this.paramService.getEmailParamsForPreview(parType, objParametro, resReq);
  }

  clearInput(position, tagId) {
    this.myForm.get(position).setValue("");
    this.setValueEventSubject.next({
      value: "",
      tagId: tagId
    });
  }

  getValidForm(param: FormControl) {
    return param.valid;
  }

  changeIgnoreError(inputId, tagId) {
    let inputCheckBox = this.myForm.get(inputId + "_error_ignore");

    if (inputCheckBox.value) {
      this.myForm.get(inputId).clearValidators();
      this.myForm.get(inputId).updateValueAndValidity();
      this.myForm.get(inputId).setValidators([Validators.required]);
      this.myForm.get(inputId).updateValueAndValidity();
    } else {
      this.myForm.get(inputId).clearValidators();
      this.myForm.get(inputId).updateValueAndValidity();
      this.myForm.get(inputId).setValidators([Validators.required, paramsValidator(this.getPatternValidator(inputId))]);
      this.myForm.get(inputId).updateValueAndValidity();
    }

    this.validateEventSubject.next({
      value: this.myForm.get(inputId).valid,
      tagId: tagId
    });
  }

  getPatternValidator(inputId) {
    let typeFormGroup = inputId.split(".")[0];
    let pattern = "";
    switch (typeFormGroup) {
      case "utenti":
        pattern = '$nome$|$nome:1$|$cf$,$cognome$|$cognome:1$|$cf$';
        break;
      case "organigramma":
        pattern = '$name_organigramma$';
        break;
      case "plesso":
        pattern = '$name_plesso$|$short_name_plesso$';
        break;
      case "classi":
        pattern = '$name_classe$';
        break;
      case "gruppi_generici":
        pattern = '$name_group$';
        break;
    }

    return pattern;
  }

}