import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';

@Component({
  selector: 'app-group-profile-datatable-card',
  templateUrl: './group-profile-datatable-card.component.html',
  styleUrls: ['./group-profile-datatable-card.component.scss']
})
export class GroupProfileDatatableCardComponent implements OnInit {
  @Input() id: any;
  members: any;
  memberUser: any;
  memberGroup: any;

  data = {
    users: [],
    groups: [],
    memberUser: [],
    memberGroup: []
  };

  dataError = {
    memberUser: null,
    memberGroup: null
  };

  error: any;

  showInfo = false;
  tabSelected = 1;

  _loadingData = {
    memberUser: false,
    memberGroup: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  private _userServiceSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private googleDirectoryService: GoogleDirectoryService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.unsubscibeAll();
    this.error = null;
    this._loadingData.memberUser = true;
    this._loadingData.memberGroup = true;

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.init();
      }
    });

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe((result) => {
      if (result) {
        this.userService.all();
      }
    });

    this.googleDirectoryService.usersList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  unsubscibeAll() {
    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();
  }

  init() {
    if (this.id) {
      this.getData();
    } else {
      this.genericError("group");
    }
  }

  selectTab(index) {
    this.tabSelected = index;
  }

  getData() {
    this._loadingData.memberGroup = true;
    this._loadingData.memberUser = true;
    this.data.groups = [];
    this.data.users = [];
    this.data.memberUser = [];
    this.data.memberGroup = [];

    this.memberUser = [];
    this.memberGroup = [];
    this.members = [];

    this.googleDirectoryService.groupMembersList(this.id, (result) => {
      if (result) {
        this.members = result;
        this.filterMemeberType();
      }

      this.memberUpdated();
    }, () => {
      this.genericError("member");
    });
  }

  genericError(typeError) {
    this.error = typeError;
    this._loadingData[typeError] = false;
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  rowClickUser(data) {
    
    let member = this.memberUser[data.index];

    let user = this.userService.getByGoogleId(member.id);

    if (user && user.is_geniusuite_user && user.userId) {
      this.rowClickUserGeniusuite(user.userId);
      return;
    }

    if (user && user.is_google_user && user.googleId) {
      this.rowClickUserGoogle(user.googleId);
      return;
    }

    this.rowClickUserGoogle(member.id);
  }

  rowClickGroup(data) {
    let group = this.data.groups[data.index];
    this.router.navigate(['group/' + group.id]);
  }

  rowClickUserGeniusuite(id) {
    this.router.navigate(['user/' + id]);
  }

  rowClickUserGoogle(id) {
    this.router.navigate(['user/google/' + id]);
  }

  filterMemeberType() {
    if (this.members && this.members.length > 0) {
      this.memberUser = this.members.filter(x => x.type == "USER");
      this.memberGroup = this.members.filter(x => x.type == "GROUP");
    }
  }

  memberUpdated() {
    this._loadingData.memberUser = true;
    this.data.memberUser = this.userService.getTable(this.memberUser, "gruppo");
    this._loadingData.memberUser = false;

    this._loadingData.memberGroup = true;
    this.data.memberGroup = this.parseGroupsResult(this.memberGroup, "memberGroup");
    this._loadingData.memberGroup = false;
  }

  parseGroupsResult(result, position) {

    let table = [];

    if (result && result.length > 0) {

      result.forEach(element => {
        let group = this.getInfoGroup(element);
        this.data.groups.push(group);

        table.push({
          "nome": [group.name, group.description],
          "email": [group.email],
          "type": [this.translate.instant('GROUP.' + group.type)],
          "membri": [group.directMembersCount],
          "_export_data": {
            name: group.name,
            description: group.description,
            email: group.email
          },
        })
      });
    }

    this._loadingData[position] = false;

    return table;
  }

  getInfoGroup(userInfo) {
    let resUser = {
      id: null,
      name: null,
      description: null,
      email: userInfo.email,
      directMembersCount: 0,
      role: userInfo.role,
      status: userInfo.status,
      type: userInfo.type
    };


    let googleGroup = this.googleDirectoryService.getGroupByEmail(resUser.email);
    if (googleGroup) {
      // Non sincronizzato con Geniusuite ma non ha già i dati caricati
      resUser.id = googleGroup.id ? googleGroup.id : null;
      resUser.name = googleGroup.name ? googleGroup.name : "-";
      resUser.directMembersCount = googleGroup.directMembersCount ? googleGroup.directMembersCount : 0;
      resUser.description = googleGroup.description ? googleGroup.description : "-";
      resUser.email = googleGroup.email;
    } else {
      resUser.name = "Gruppo Esterno";
    }

    return resUser;
  }
}
