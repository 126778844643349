<!-- Step -->
<ul class="step step-md mb-0 step-centered">
    <li class="step-item" *ngFor="let item of steps">
      <div class="step-content-wrapper">
        <span class="step-icon"
          [ngClass]="{'step-icon-soft-primary': currentStep == null || item > currentStep, 'step-icon-success': currentStep != null && item < currentStep, 'step-icon-primary': currentStep != null && currentStep == item}">
          {{item}}
        </span>
        <div class="step-content">
          <h4 class="step-title" [ngClass]="{'text-muted': currentStep != null && item != currentStep}">{{'PASSAGGIOANNO.steps.titolo_'+item | translate}}</h4>
          <p class="text-body">{{'PASSAGGIOANNO.steps.text_'+item | translate}}</p>
        </div>
      </div>
    </li>
  </ul>
  <!-- End Step -->
  