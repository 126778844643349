import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/general/user.service';
import { UpdatePasswordService } from 'src/app/services/operations/update-password.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  data: any = [];
  users: any;
  model: any;

  operationInProgress: boolean = false;
  showDownloadButton: boolean = false;
  errorNoGoogleAccount: boolean = false;

  private _updatePasswordServiceSubscription: Subscription;
  private _userServiceSubscription: Subscription;

  constructor(
    private updatePasswordService: UpdatePasswordService,
    private userService: UserService,
    private utilityService: UtilityService,
    private router: Router,
  ) { }

  ngOnInit() {

    this.model = {
      generatePassword: true,
      changePasswordAtNextLogin: true,
      gestPassword: false,
      password: this.utilityService.generateRandomPassword(12)
    }

    this._updatePasswordServiceSubscription = this.updatePasswordService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.dataUpdated();
  }

  ngOnDestroy() {

    if (this._updatePasswordServiceSubscription)
      this._updatePasswordServiceSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();


  }

  dataUpdated() {

    this.users = this.updatePasswordService.items;

    this.errorNoGoogleAccount = false;

    this.users.forEach(user => {
      if (!user.google_id) {
        this.errorNoGoogleAccount = true;
      }
    });

    this.data = this.userService.getTable(this.users);
  }

  deleteEvent(rows) {
    this.updatePasswordService.deleteItemsAtIndex(rows);
  }

  generatePasswordOnChange() {
    if (this.model.generatePassword == true)
      this.model.password = this.utilityService.generateRandomPassword(12);
  }

  onSubmit(myForm: NgForm) {

    if (myForm.valid) {

      this.operationInProgress = true;

      this.updatePasswordService.execOperation(this.model, (error) => {

        if (!error) {
          this.showDownloadButton = true;
        }

        this.operationInProgress = false;

      });
    }
  }

  resetOperation() {
    this.updatePasswordService.reset();
  }

  saveFile() {
    this.updatePasswordService.downloadLastItems();
  }

  rowClickUser(data) {
    let user = this.users[data.index];
    this.router.navigate(['user/' + user.user_id]);
  }

}
