import { Component, OnInit } from '@angular/core';
import { CheckVersionService } from 'src/app/services/check-version.service';

@Component({
  selector: 'app-manutenzione',
  templateUrl: './manutenzione.component.html',
  styleUrls: ['./manutenzione.component.scss']
})
export class ManutenzioneComponent implements OnInit {

  manutenzione_fine: Date;

  constructor(
    private checkVersionService: CheckVersionService
  ) { }

  ngOnInit() {

    this.manutenzione_fine = this.checkVersionService.manutenzione_fine;

    setInterval(() => {
      this.checkStatus();
    }, 1000 * 60);

    // se il sistema è in manutenzione aumento la frequenza di controllo, tanto dopo verrà ricaricata l'applicazione
    this.checkVersionService.initVersionCheck(1000 * 60); // 1 minuto
  }

  checkStatus() {

    this.manutenzione_fine = this.checkVersionService.manutenzione_fine;

    if (!this.checkVersionService.manutenzione) {
      location.href = '/';
    }
  }

  ricarica() {
    location.href = '/';
  }

}
