import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { AccountService } from 'src/app/services/account.service';
import { environment } from '../../../environments/environment';

export function getProgressbarConfig(): ProgressbarConfig {
  return Object.assign(new ProgressbarConfig(), { animate: true, striped: true, max: 150 });
}

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  providers: [{ provide: ProgressbarConfig, useFactory: getProgressbarConfig }]
})
export class LoginPageComponent implements OnInit {

  returnUrl: any;
  loginUrlGoogle = environment.loginUrlGoogle;
  loginUrlNotGoogle = environment.loginUrlNotGoogle;

  constructor(private router: Router, private accountService: AccountService, private route: ActivatedRoute,) { }

  ngOnInit() {

    this.returnUrl = this.route.snapshot.queryParams.returnUrl;

    if (this.accountService.data && this.accountService.data.google_access_token) {
      this.router.navigate([''], { queryParams: { returnUrl: this.returnUrl } });
    }
  }
}
