import { Component, Input, OnInit } from '@angular/core';

// Cancellati gli attrubuti deprecati https://developers.google.com/admin-sdk/groups-settings/v1/reference/groups#resource-representations
export class GroupSetting {
  whoCanJoin: string;
  whoCanViewMembership: string;
  whoCanViewGroup: string;
  whoCanInvite: string;
  whoCanAdd: string;
  allowExternalMembers: string;
  whoCanPostMessage: string;
  allowWebPosting: string;
  isArchived: string;
  archiveOnly: string;
  messageModerationLevel: string;
  spamModerationLevel: string;
  replyTo: string;
  customReplyTo: string;
  includeCustomFooter: string;
  customFooterText: string;
  sendMessageDenyNotification: string;
  defaultMessageDenyNotificationText: string;
  membersCanPostAsTheGroup: string;
  includeInGlobalAddressList: string;
  whoCanLeaveGroup: string;
  whoCanContactOwner: string;
  favoriteRepliesOnTop: string;
  whoCanModerateMembers: string;
  whoCanModerateContent: string;
  whoCanAssistContent: string;
  customRolesEnabledForSettingsToBeMerged: string;
  enableCollaborativeInbox: string;
  whoCanDiscoverGroup: string;
}

@Component({
  selector: 'app-google-group-settings',
  templateUrl: './google-group-settings.component.html',
  styleUrls: ['./google-group-settings.component.scss']
})
export class GoogleGroupSettingsComponent implements OnInit {
  @Input() id;
  @Input() formGroup;
  @Input() json;

  whoCanJoinValues = [
    {
      id: 1,
      value: "ANYONE_CAN_JOIN",
      label: "Chiunque può accedere"
    },
    {
      id: 2,
      value: "ALL_IN_DOMAIN_CAN_JOIN",
      label: "Chiunque nell'organizzazione può partecipare"
    },
    {
      id: 3,
      value: "CAN_REQUEST_TO_JOIN",
      label: "Chiunque nell'organizzazione può chiedere"
    },
    {
      id: 4,
      value: "INVITED_CAN_JOIN",
      label: "Solo gli utenti invitati"
    }
  ]

  data: any[][];

  data2: string;

  constructor() { }

  ngOnInit() {
    if (this.json) {
      this.convertJsonToDataStruct();
    } else {
      this.data = [
        [true, true, true, false, false],
        [true, true, true, true],
        [true, true, true, false, false],
        [true, true, true, false, false],
        [true, true, true],
        ["INVITED_CAN_JOIN"],
        [true],
      ];
    }
  }

  changeTableParametriAccesso(rowIndex, colIndex, newValue) {

    // prima verifico eventuali valori da settare a true
    for (let i = 0; i < this.data[rowIndex].length; i++) {
      if (newValue === true && i < colIndex) {
        this.data[rowIndex][i] = true;
      }
      else if (newValue === false && i > colIndex) {
        this.data[rowIndex][i] = false;
      }
    };


    this.convertDataToJsonStruct();
  }

  convertDataToJsonStruct() {
    let jsonData: GroupSetting = new GroupSetting();
    jsonData.whoCanContactOwner = this.getCanContact(0);
    jsonData.whoCanViewMembership = this.getCanView(1);
    jsonData.whoCanViewGroup = this.getCanView(2);
    jsonData.whoCanPostMessage = this.getCanPost(3);
    jsonData.whoCanModerateMembers = this.getCanModerate(4);
    jsonData.whoCanJoin = this.data[5][0];
    jsonData.allowExternalMembers = this.data[6][0];


    // Default da Default non gestiti dal Pannello
    jsonData.allowWebPosting = "false";
    jsonData.customFooterText = "";
    jsonData.customReplyTo = "";
    jsonData.customRolesEnabledForSettingsToBeMerged = "false";
    jsonData.defaultMessageDenyNotificationText = "";
    jsonData.enableCollaborativeInbox = "false";
    jsonData.favoriteRepliesOnTop = "true";
    jsonData.includeCustomFooter = "false";
    jsonData.includeInGlobalAddressList = "true";
    jsonData.isArchived = "false";
    jsonData.membersCanPostAsTheGroup = "false";
    jsonData.messageModerationLevel = "MODERATE_NONE";
    jsonData.replyTo = "REPLY_TO_IGNORE";
    jsonData.sendMessageDenyNotification = "false";
    jsonData.spamModerationLevel = "MODERATE";
    jsonData.whoCanAdd = "NONE_CAN_ADD";
    jsonData.whoCanAssistContent = "NONE";
    jsonData.whoCanDiscoverGroup = "ANYONE_CAN_DISCOVER";
    jsonData.whoCanInvite = "NONE_CAN_INVITE";
    jsonData.whoCanLeaveGroup = "ALL_MEMBERS_CAN_LEAVE";
    jsonData.whoCanModerateContent = "OWNERS_AND_MANAGERS";
    jsonData.archiveOnly = "false";

    this.formGroup.get('setting.' + this.id).setValue(JSON.stringify(jsonData));
  }

  convertJsonToDataStruct(){
    let jsonData: GroupSetting = JSON.parse(this.json);

    this.data = [
      [true, false, false, false, false],
      [true, false, false, false],
      [true, false, false, false, false],
      [false, false, false, false, false],
      [false, false, false],
      [jsonData.whoCanJoin],
      [jsonData.allowExternalMembers]
    ];

    this.setCanContact(0, jsonData.whoCanContactOwner);
    this.setCanView(1, jsonData.whoCanViewMembership);
    this.setCanView(2, jsonData.whoCanViewGroup);
    this.setCanPost(3, jsonData.whoCanPostMessage);
    this.setCanModerate(4, jsonData.whoCanModerateMembers);
  }

  allowExternalMembersOnChange() {
    this.convertDataToJsonStruct();
  }

  getCanView(rowIndex) {
    let lenghRow = this.data[rowIndex].length;
    let index = lenghRow - 1;
    let result = "";

    do {
      if (this.data[rowIndex][index]) {
        switch (index) {
          case 4:
            result = "ANYONE_CAN_VIEW";
            break;
          case 3:
            result = "ALL_IN_DOMAIN_CAN_VIEW";
            break;
          case 2:
            result = "ALL_MEMBERS_CAN_VIEW";
            break;
          case 1:
            result = "ALL_MANAGERS_CAN_VIEW";
            break;
          case 0:
            result = "";
            break;
        }
      }

      index--;
    } while (index >= 0 && !result);

    return result;
  }

  setCanView(rowIndex, valueJson) {
    switch (valueJson) {
      case "ANYONE_CAN_VIEW":
        this.changeTableParametriAccesso(rowIndex, 5, true);
        break;
      case "ALL_IN_DOMAIN_CAN_VIEW":
        this.changeTableParametriAccesso(rowIndex, 4, true);
        break;
      case "ALL_MEMBERS_CAN_VIEW":
        this.changeTableParametriAccesso(rowIndex, 3, true);
        break;
      case "ALL_MANAGERS_CAN_VIEW":
        this.changeTableParametriAccesso(rowIndex, 2, true);
        break;
    }
  }

  getCanModerate(rowIndex) {
    let lenghRow = this.data[rowIndex].length;
    let index = lenghRow - 1;
    let result = "";

    do {
      if (this.data[rowIndex][index]) {
        switch (index) {
          case 2:
            result = "ALL_MEMBERS";
            break;
          case 1:
            result = "OWNERS_AND_MANAGERS";
            break;
          case 0:
            result = "OWNERS_ONLY";
            break;
        }
      }

      index--;
    } while (index >= 0 && !result);

    return !result ? "NONE" : result;
  }

  setCanModerate(rowIndex, valueJson) {
    switch (valueJson) {
      case "ALL_MEMBERS":
        this.changeTableParametriAccesso(rowIndex, 3, true);
        break;
      case "OWNERS_AND_MANAGERS":
        this.changeTableParametriAccesso(rowIndex, 2, true);
        break;
      case "OWNERS_ONLY":
        this.changeTableParametriAccesso(rowIndex, 1, true);
        break;
    }
  }

  getCanContact(rowIndex) {
    let lenghRow = this.data[rowIndex].length;
    let index = lenghRow - 1;
    let result = "";

    do {
      if (this.data[rowIndex][index]) {
        switch (index) {
          case 4:
            result = "ANYONE_CAN_CONTACT";
            break;
          case 3:
            result = "ALL_IN_DOMAIN_CAN_CONTACT";
            break;
          case 2:
            result = "ALL_MEMBERS_CAN_CONTACT";
            break;
          case 1:
            result = "ALL_MANAGERS_CAN_CONTACT";
            break;
          case 0:
            result = "";
            break;
        }
      }

      index--;
    } while (index >= 0 && !result);

    return result;
  }

  setCanContact(rowIndex, valueJson) {
    switch (valueJson) {
      case "ANYONE_CAN_CONTACT":
        this.changeTableParametriAccesso(rowIndex, 5, true);
        break;
      case "ALL_IN_DOMAIN_CAN_CONTACT":
        this.changeTableParametriAccesso(rowIndex, 4, true);
        break;
      case "ALL_MEMBERS_CAN_CONTACT":
        this.changeTableParametriAccesso(rowIndex, 3, true);
        break;
      case "ALL_MANAGERS_CAN_CONTACT":
        this.changeTableParametriAccesso(rowIndex, 2, true);
        break;
    }
  }

  getCanPost(rowIndex) {
    let lenghRow = this.data[rowIndex].length;
    let index = lenghRow - 1;
    let result = "";

    do {
      if (this.data[rowIndex][index]) {
        switch (index) {
          case 4:
            result = "ANYONE_CAN_POST";
            break;
          case 3:
            result = "ALL_IN_DOMAIN_CAN_POST";
            break;
          case 2:
            result = "ALL_MEMBERS_CAN_POST";
            break;
          case 1:
            result = "ALL_MANAGERS_CAN_POST";
            break;
          case 0:
            result = "ALL_OWNERS_CAN_POST";
            break;
        }
      }

      index--;
    } while (index >= 0 && !result);

    return result;
  }

  setCanPost(rowIndex, valueJson) {
    switch (valueJson) {
      case "ANYONE_CAN_POST":
        this.changeTableParametriAccesso(rowIndex, 5, true);
        break;
      case "ALL_IN_DOMAIN_CAN_POST":
        this.changeTableParametriAccesso(rowIndex, 4, true);
        break;
      case "ALL_MEMBERS_CAN_POST":
        this.changeTableParametriAccesso(rowIndex, 3, true);
        break;
      case "ALL_MANAGERS_CAN_POST":
        this.changeTableParametriAccesso(rowIndex, 2, true);
        break;
      case "ALL_OWNERS_CAN_POST":
        this.changeTableParametriAccesso(rowIndex, 1, true);
        break;
    }
  }

  whoCanJoinOnChange($event) {
    this.data[5][0] = $event;
    this.convertDataToJsonStruct();
  }
}
