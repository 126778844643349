import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { ParametriService } from 'src/app/services/general/parametri.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { PageService } from 'src/app/services/page.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-user-form-rework',
  templateUrl: './user-form-rework.component.html',
  styleUrls: ['./user-form-rework.component.scss']
})
export class UserFormReworkComponent implements OnInit {

  @Input() id: any;
  @ViewChild('myForm') myForm: NgForm;

  error: any;
  editMode: boolean = false;
  rolesSelect: any = [];
  domains: any = [];
  googleUsers: any = [];
  idPlessiToExclude: any = [];

  _loadingData = {
    profile: false,
    users: false,
    plessi: false,
    domains: false,
    google_users: false,
  };

  existData = {
    email: null
  }

  tempData = {
    plesso: null,
    classi: null,
    materieClassi: null
  }

  formEnable = {
    plesso: false
  }

  modelPreEdit = null;

  dataChange = {
    primaryEmail: false,
    givenName: false,
    familyName: false,
    codice_fiscale: false,
    recoveryEmail: false,
    recoveryPhone: false,
    email: false,
  };

  get dataIsChanged(): boolean {
    return Object.values(this.dataChange).some(x => x == true);
  }

  emailGenerated = null;

  create_add_to_organigramma: null;

  model = {
    id: null,
    user_id: null,
    primaryEmail: null,
    usernameEmail: null,
    domainEmail: null,
    plessi: [],
    secondaryEmail: null,
    secondaryPhone: null,
    address: null,
    name: {
      givenName: null,
      familyName: null
    },
    role: null,
    suspended: false,
    password: null,
    hashFunction: "SHA-1",
    changePasswordAtNextLogin: false,
    ipWhitelisted: false,
    isAdmin: false,
    emails: [],
    addresses: [],
    externalIds: [
      {
        value: null,
        type: "organization"
      }
    ],
    relations: null,
    organizations: [],
    phones: [],
    orgUnitPath: "/",
    includeInGlobalAddressList: true,
    recoveryEmail: null,
    recoveryPhone: null,
    overwritePrimaryEmail: false,
    suggestedPrimaryEmail: false,
    create_add_to_organigramma: null,
    create_add_to_organigramma_aggiungi_gerarchia: false,
  };

  isCollapseInformazioniSicurezza: boolean = true;
  isCollapseInformazioniContatto: boolean = true;

  _loading = {
    form: false,
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  private _googleServiceDomainsSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;
  private _userServiceSubscription: Subscription;
  private _plessoServiceSubscription: Subscription;
  private _queryParamsSubscription: Subscription;

  constructor(
    private googleDirectoryService: GoogleDirectoryService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private plessoService: PlessoService,
    private parametriService: ParametriService,
    private utilityService: UtilityService,
    private pageService: PageService,
    private organigrammaService: OrganigrammaService
  ) { }

  ngOnInit(): void {
    this.unsubscibeAll();
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  init() {
    this._loadingData = {
      profile: false,
      users: false,
      plessi: false,
      domains: false,
      google_users: false,
    };

    this.existData = {
      email: null
    }

    this.tempData = {
      plesso: null,
      classi: null,
      materieClassi: null
    }

    this.formEnable = {
      plesso: false,
    }

    this.modelPreEdit = null;

    this.dataChange = {
      primaryEmail: false,
      givenName: false,
      familyName: false,
      codice_fiscale: false,
      recoveryEmail: false,
      recoveryPhone: false,
      email: false,
    };

    this.model = {
      id: null,
      user_id: null,
      primaryEmail: null,
      usernameEmail: null,
      domainEmail: null,
      plessi: [],
      secondaryEmail: null,
      secondaryPhone: null,
      address: null,
      name: {
        givenName: null,
        familyName: null
      },
      role: null,
      suspended: false,
      password: null,
      hashFunction: "SHA-1",
      changePasswordAtNextLogin: false,
      ipWhitelisted: false,
      isAdmin: false,
      emails: [],
      addresses: [],
      externalIds: [
        {
          value: null,
          type: "organization"
        }
      ],
      relations: null,
      organizations: [{
        isCollapsed: true,
        title: null,
        name: null,
        location: null,
        department: null,
        description: null
      }],
      phones: [],
      orgUnitPath: "/",
      includeInGlobalAddressList: true,
      recoveryEmail: null,
      recoveryPhone: null,
      overwritePrimaryEmail: false,
      suggestedPrimaryEmail: false,
      create_add_to_organigramma: null,
      create_add_to_organigramma_aggiungi_gerarchia: false,
    };

    this.generateSecurePassword();

    this.isCollapseInformazioniSicurezza = true;
    this.isCollapseInformazioniContatto = true;

    this._loading = {
      form: false,
    };

    if (this.id && this.id > 0) {
      this.editMode = true;
    } else {
      this.editMode = false;
    }

    //  inizializzo le tipologie di utente disponibili
    this.rolesSelect = ConfigService.userTypeSelect;

    this.model.role = this.route.snapshot.paramMap.get('user_role');
    this.model.create_add_to_organigramma = this.route.snapshot.paramMap.get('id_organigramma');

    if (this.model.create_add_to_organigramma) {
      this.create_add_to_organigramma = this.organigrammaService.get(this.model.create_add_to_organigramma);
    }

    this._googleServiceDomainsSubscription = this.googleDirectoryService.getSubjectToUpdateDomainsObservable().subscribe((result) => {
      if (result)
        this.domains = result;

      this._loadingData.domains = false;
    });

    this._loadingData.domains = true;

    this.googleDirectoryService.domainsList();

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (this.editMode)
        this.modelPopulate();

      this._loadingData.users = false;
    });

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe(
      (result) => {
        if (result)
          this.userService.all();

        this._loadingData.google_users = false;
      });

    this._loadingData.google_users = true;
    this._loadingData.users = true;
    this.googleDirectoryService.usersList();

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe(
      (result) => {
        this._loadingData.plessi = false;
      });

    this._loadingData.plessi = true;

    this.plessoService.all(() => {
      this.setUserPlessoClasseFromParameter();
    });
  }

  modelPopulate() {
    this.modelPreEdit = null;
    this._loadingData.profile = true;
    this.userService.get(
      this.id,
      (userData) => {
        if (userData.google_id) {
          let googleUser = this.googleDirectoryService.getUser(userData.google_id);

          this.model = {
            id: googleUser.id,
            user_id: this.id,
            primaryEmail: googleUser.primaryEmail,
            usernameEmail: googleUser.primaryEmail.split('@')[0],
            domainEmail: googleUser.primaryEmail.split('@')[1],
            plessi: [],
            secondaryEmail: null,
            secondaryPhone: null,
            address: null,
            name: {
              givenName: googleUser.name.givenName,
              familyName: googleUser.name.familyName,
            },
            role: null,
            suspended: googleUser?.suspended,
            password: null,
            hashFunction: "SHA-1",
            changePasswordAtNextLogin: false,
            ipWhitelisted: googleUser?.ipWhitelisted,
            isAdmin: googleUser?.isAdmin,
            emails: googleUser.emails ? googleUser.emails : [],
            addresses: googleUser.addresses ? googleUser.addresses : [],
            externalIds: googleUser.externalIds ? googleUser.externalIds : [
              {
                value: null,
                type: "organization"
              }
            ],
            relations: googleUser.relations,
            organizations: googleUser.organizations ? googleUser.organizations : [
              {
                isCollapsed: true,
                title: null,
                name: null,
                location: null,
                department: null,
                description: null
              }
            ],
            phones: googleUser.phones ? googleUser.phones : [],
            orgUnitPath: googleUser.orgUnitPath,
            includeInGlobalAddressList: true,
            recoveryEmail: googleUser.recoveryEmail,
            recoveryPhone: googleUser.recoveryPhone,
            overwritePrimaryEmail: true,
            suggestedPrimaryEmail: false,
            create_add_to_organigramma: null,
            create_add_to_organigramma_aggiungi_gerarchia: null,
          };

          this.model.organizations?.forEach(element => {
            element.isCollapsed = true;
          });

          // se esiste già un'organizzazione ne aggiungo un'altra vuota
          if (googleUser.organizations && googleUser.organizations.length > 0) {
            this.model.organizations.push({
              isCollapsed: true,
              title: null,
              name: null,
              location: null,
              department: null,
              description: null
            });
          }

          // Setto Ruolo
          if (userData?.user?.roles.length > 0) {
            userData?.user?.roles.forEach(role => {
              if (ConfigService.isGoogleAdminRole(role.name)) {
                this.model.isAdmin = true;
              }
              else {
                this.model.role = ConfigService.getUserTypeKeyByRole(role.name);
              }
            });
          }

          // Nel caso in cui un utente esistente non ha ruolo per abilitare il form lo imposto come altro e verra aggiornato appena viene salvato
          // questo potrebbe capitare quando sono solo admin creati da backend
          if (!this.model.role) {
            this.model.role = "worker";
          }

          // Setto Struttura Plessi
          if (userData.classi && userData.classi.length > 0) {
            userData.classi.forEach(classe => {
              let materie = [];

              // Controllo se in materie esistete
              if (userData?.user.materie && userData?.user.materie.length > 0) {
                materie = userData?.user.materie.filter(x => x.classe_id == classe.classe.id);
              }

              let objPlesso = {
                plesso: null,
                classi: [],
                materieClassi: []
              };

              let index = this.model.plessi.findIndex(x => x.plesso.id == classe.classe.plesso.id);

              if (index !== (-1)) {
                // Plesso Presente
                objPlesso = this.model.plessi[index];
                if (materie && materie.length > 0) {
                  materie.forEach(element => {
                    let indexMat = objPlesso.materieClassi.findIndex(x => x.materia.id == element.materia_id);

                    if (indexMat !== (-1)) {
                      // Materia Presente
                      objPlesso.materieClassi[indexMat].classi.push({
                        ...classe.classe
                      });
                    } else {
                      // Materia Non Presente
                      objPlesso.materieClassi.push({
                        materia: { id: element.materia_id, name: element.materia_name },
                        classi: [{
                          ...classe.classe
                        }]
                      })
                    }
                  });
                } else {
                  objPlesso.classi.push({
                    ...classe.classe
                  });
                }
              } else {
                // Plesso non esiste lo aggiungo
                objPlesso.plesso = classe.classe.plesso;

                if (materie && materie.length > 0) {
                  objPlesso.materieClassi = [];
                  materie.forEach(element => {
                    objPlesso.materieClassi.push({
                      materia: { id: element.materia_id, name: element.materia_name },
                      classi: [{
                        ...classe.classe
                      }]
                    })
                  });
                } else {
                  objPlesso.classi.push({
                    ...classe.classe
                  });
                }

                this.model.plessi.push(objPlesso);
              }
            });
          }

          this.modelPreEdit = this.utilityService.cloneData(this.model);
        }

        this._loadingData.profile = false;
      },
      () => {
        this._loadingData.profile = false;
      }
    );
  }

  unsubscibeAll() {
    if (this._googleServiceDomainsSubscription)
      this._googleServiceDomainsSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();

    if (this._queryParamsSubscription)
      this._queryParamsSubscription.unsubscribe();
  }

  suggestPrimaryEmail() {

    if (this.model.role) {

      let objectToDecode = {
        givenName: this.model.name.givenName,
        familyName: this.model.name.familyName,
        cf: this.model.externalIds[0].value
      }

      this.emailGenerated = this.parametriService.getEmailParamsForUser(
        this.model.role,
        objectToDecode,
      );

      let usernameEmail = this.emailGenerated.split('@')[0];
      let domainEmail = this.emailGenerated.split('@')[1];

      if (this.googleDirectoryService.checkUserExistByEmail(this.emailGenerated, this.model.id)
        || this.userService.checkUserExistByEmail(this.emailGenerated)) {

        do {
          this.emailGenerated = usernameEmail + Math.floor(Math.random() * 100) + "@" + domainEmail;
        } while (this.googleDirectoryService.checkUserExistByEmail(this.emailGenerated, this.model.id));
      }

      // capire se è stata modificata manualmente l'email e in alternativa sovrascrivere il valore di default
      if (!this.model.overwritePrimaryEmail) {
        this.setGeneratedEmail();
      }
    }
  }

  setGeneratedEmail() {

    if (this.existData.email) {
      this.model.usernameEmail = this.existData.email.split('@')[0];
    }

    this.model.usernameEmail = this.emailGenerated.split('@')[0];
    this.model.domainEmail = this.emailGenerated.split('@')[1];
    this.model.primaryEmail = this.model.usernameEmail + "@" + this.model.domainEmail;

    this.existData.email = null;

    this.model.overwritePrimaryEmail = false;
    this.model.suggestedPrimaryEmail = true;

    this.editDataInputChange();
  }

  overwritePrimaryEmail() {

    let email = this.model.usernameEmail?.toLowerCase().replace(/\s/g, '');
    let domain = this.model.domainEmail?.toLowerCase().replace(/\s/g, '');

    this.model.usernameEmail = email;
    this.model.domainEmail = domain;
    this.model.primaryEmail = this.model.usernameEmail + "@" + this.model.domainEmail;

    if (this.googleDirectoryService.checkUserExistByEmail(this.model.primaryEmail, this.model.id)
      || this.userService.checkUserExistByEmail(this.model.primaryEmail)) {
      this.existData.email = null;
      this.model.overwritePrimaryEmail = false;

      if (!this.modelPreEdit || this.model.primaryEmail === this.modelPreEdit.primaryEmail) {
        this.myForm.controls.usernameEmail?.setErrors({
          'invalid': true
        });
      }

    } else {
      this.model.overwritePrimaryEmail = true;
      this.existData.email = null;
    }

    this.editDataInputChange();
  }

  editDataInputChange() {
    if (this.editMode)
      this.editDataChange();
  }


  editDataChange() {
    // Controllo quali dati sono cambiati
    Object.keys(this.model).forEach(key => {
      switch (key) {
        case 'name':
          this.dataChange["familyName"] = this.model[key]["familyName"] !== this.modelPreEdit[key]["familyName"] ? true : false;
          this.dataChange["givenName"] = this.model[key]["givenName"] !== this.modelPreEdit[key]["givenName"] ? true : false;
          break;
        case 'recoveryEmail':
        case 'recoveryPhone':
          this.dataChange[key] = this.model[key] !== this.modelPreEdit[key] ? true : false;
          break;
        case 'externalIds':
        case 'codice_fiscale':
          this.dataChange['codice_fiscale'] = this.model.externalIds[0].value !== this.modelPreEdit.externalIds[0].value ? true : false;
          break;
        case 'usernameEmail':
        case 'domainEmail':
          this.dataChange['email'] = this.model.primaryEmail !== this.modelPreEdit.primaryEmail ? true : false;
          break;
        default:
          break;
      }
    });
  }

  generateSecurePassword() {
    this.model.password = this.utilityService.generateRandomPassword(12);
  }

  retryAfterLoadingError() {

    this.error = null;
    this.ngOnInit();
  }

  onSubmit() {
    if (this.myForm.valid) {
      let modelClear = this.clearDataSubmit();
      if (!modelClear) return;

      this._loading.form = true;

      if (this.model.user_id) {
        this.userService.update(
          this.model.user_id,
          modelClear,
          true,
          () => {
            this._loading.form = false;
            this.navigateAfterSubmit();
          },
          (error) => {
            this._loading.form = false;
          }
        );
      } else {
        this.userService.create(
          modelClear,
          true,
          () => {
            this._loading.form = false;
            this.navigateAfterSubmit();
          },
          (error) => {
            this._loading.form = false;
          }
        );
      }
    }
  }

  navigateAfterSubmit() {
    if (this.editMode) {
      this.router.navigate([`/user/${this.id}`]);
    }
    else {
      this.myForm.resetForm();
      this.ngOnInit();
    }
  }

  back() {
    this.unsubscibeAll();
    this.init();
    this.pageService.back();
  }

  onSecondaryEmailOnSelect() {
    if (this.myForm.controls.secondaryEmail.valid && this.myForm.controls.secondaryEmail.value) {
      this.model.emails.push({ address: this.myForm.controls.secondaryEmail.value, type: 'home', customType: null, primary: false })
      this.model.secondaryEmail = null;
    }
  }

  removeSecondaryEmail(index) {
    this.model.emails.splice(index, 1);
  }

  onSecondaryAddressOnSelect() {
    if (this.myForm.controls.address.valid && this.myForm.controls.address.value) {
      this.model.addresses.push({ formatted: this.myForm.controls.address.value, type: "home" })
      this.model.address = null;
    }
  }

  removeSecondaryAddress(index) {
    this.model.addresses.splice(index, 1);
  }

  onSecondaryPhoneOnSelect() {
    if (this.myForm.controls.secondaryPhone.valid && this.myForm.controls.secondaryPhone.value) {
      this.model.phones.push({
        type: "home",
        value: this.myForm.controls.secondaryPhone.value
      })
      this.model.secondaryPhone = null;
    }
  }

  removeSecondaryPhone(index) {
    this.model.phones.splice(index, 1);
  }

  removeOrganization(index) {
    this.model.organizations.splice(index, 1);
  }

  enableFormPlesso() {
    this.formEnable.plesso = true;

    // this.idPlessiToExclude.push(this.model.userPlessoTemp.plesso.id);
    this.idPlessiToExclude = (this.model.plessi?.map(x => x.plesso.id) || []);
  }

  editModeFormPlesso(idPlesso) {
    this.formEnable.plesso = false;
    let index = this.model.plessi.findIndex(x => x.plesso.id == idPlesso);

    if (index != -1) {
      this.tempData = {
        plesso: this.utilityService.cloneData(this.model.plessi[index].plesso),
        classi: this.utilityService.cloneData(this.model.plessi[index].classi),
        materieClassi: this.utilityService.cloneData(this.model.plessi[index].materieClassi)
      }
    }

    setTimeout(() => {
      this.formEnable.plesso = true;
    }, 300);
  }

  removePlessoStruct(idPlesso) {
    let index = this.model.plessi.findIndex(x => x.plesso.id == idPlesso);
    if (index != -1) {
      this.model.plessi[index] = null;
      this.model.plessi = this.model.plessi.filter((el) => { return el != null });
    }
  }

  annullaFormPlesso() {

    this.tempData = {
      plesso: null,
      classi: null,
      materieClassi: null
    }

    this.formEnable.plesso = false;
  }

  setUserPlessoClasseFromParameter() {
    if (!this.editMode) {
      this.formEnable.plesso = false;

      let id_plesso = this.route.snapshot.paramMap.get('id_plesso');
      let id_classe = this.route.snapshot.paramMap.get('id_classe');

      if (id_plesso) {
        let plessoPreSelected = null;
        let classePreSelected = null;

        this.plessoService.get(id_plesso, (data) => {
          plessoPreSelected = data;

          if (id_classe) {
            classePreSelected = plessoPreSelected?.classi.find(x => x.id == id_classe);
            classePreSelected = classePreSelected ? [classePreSelected] : [];
          }
        });

        this.tempData = {
          plesso: this.utilityService.cloneData(plessoPreSelected),
          classi: this.utilityService.cloneData(classePreSelected),
          materieClassi: []
        }

        setTimeout(() => {
          this.formEnable.plesso = true;
        }, 300);
      }
    }
  }

  onChangePlessoClassiSelectedData(data) {
    this.tempData.plesso = data.plesso;
    this.tempData.classi = data.classi;
    this.tempData.materieClassi = data.materieClassi;
  }

  _mapAndJoinView(data, attribute) {
    if (data && data.length > 0)
      return data.map(x => x[attribute]).sort().join(',');
    return;
  }

  _mapAndJoinViewMaterie(data, attribute) {
    if (data)
      return data.materia.name + " (" + this._mapAndJoinView(data.classi, attribute) + ")";
    return;
  }

  savePlesso() {
    
    if (!this.model.plessi) {
      this.model.plessi = [];
    }

    // se sono uno studente svuoto sempre l'array dei plessi in modo da farlo capitare sempre in una sola classe
    if (this.model.role == "studente") {
      this.model.plessi = [];
    }

    let index = this.model.plessi.findIndex(x => x.plesso.id == this.tempData.plesso.id);

    if (index != -1) {

      this.model.plessi[index] = {
        plesso: this.tempData.plesso,
        classi: this.tempData.classi,
        materieClassi: this.tempData.materieClassi
      };

    } else {

      this.model.plessi.push({
        plesso: this.tempData.plesso,
        classi: this.tempData.classi,
        materieClassi: this.tempData.materieClassi
      });
    }

    this.tempData = {
      plesso: null,
      classi: null,
      materieClassi: null
    }

    this.formEnable.plesso = false;
  }

  clearDataSubmit() {
    // pulizia dati prima di submit

    let clearModel = this.utilityService.cloneData(this.model);
    clearModel.userKey = clearModel.id;
    clearModel.familyName = clearModel.name.familyName;
    clearModel.givenName = clearModel.name.givenName;

    clearModel.plessi = [];

    this.model.plessi.forEach(plesso => {

      let struct = {
        plesso_id: plesso.plesso.id,
        classi_id: []
      };

      if (plesso.classi && plesso.classi.length > 0) {
        struct.classi_id = plesso.classi.map(x => {
          return {
            classe_id: x.id,
            materie_id: []
          }
        });
      }

      if (plesso.materieClassi && plesso.materieClassi.length > 0) {
        plesso.materieClassi.forEach(detailMat => {

          // Ciclo sulle classi associate alle materie
          detailMat.classi.forEach(classe => {
            // controllo se la classe è già presente nella lista
            let index = struct.classi_id.findIndex(x => x.classe_id == classe.id);
            if (index !== (-1)) {
              // se esiste allora controlla anche se non esiste già la materia aggiunta
              let indexMat = struct.classi_id[index].materie_id.findIndex(x => x == detailMat.materia.id);

              if (indexMat === (-1)) {
                // aggiungo materia dentro la classe
                struct.classi_id[index].materie_id.push(detailMat.materia.id);
              }
            } else {
              // aggiungo materia e classe se non esiste nessuna delle due
              struct.classi_id.push({
                classe_id: classe.id,
                materie_id: [detailMat.materia.id]
              });
            }
          });
        });
      }

      if (struct.classi_id && struct.classi_id.length > 0)
        clearModel.plessi.push(struct);
    });

    return clearModel;
  }
}
