<div class="row">
  <ng-container *ngIf="id">
    <div class="col-md-12 col-sm mb-1">
      <div class="mb-3">
        <app-materia-profile-info-card [id]="id"></app-materia-profile-info-card>
      </div>
    </div>

    <div class="col-md-12 col-sm">
      <app-materia-profile-datatable-card [id]="id"></app-materia-profile-datatable-card>
    </div>
  </ng-container>

  <ng-container *ngIf="!id">
    <div class="col-md-12 col-sm" *ngIf="!showOnlyCreate">
      <app-card-datatable-ng title="Materie" [data]="materieData" [isLoadingData]="isLoadingMaterie" [error]="materieError"
        (addClickEvent)="addMateriaClick()" (rowClickEvent)="rowClickMateria($event)" [showDeleteButton]="false"
        [showAddButton]="true" [showCheckBox]="false" [showExportButton]="true"></app-card-datatable-ng>
    </div>

    <div *ngIf="showOnlyCreate" class="col-auto col-sm">
      <app-materie-form (onSubmitForm)="closeFormAdd()"></app-materie-form>
    </div>
  </ng-container>
</div>