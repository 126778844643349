import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { UserService } from 'src/app/services/general/user.service';
import { CourseState, GoogleClassroomService } from 'src/app/services/google/google-classroom.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { AddMembersToClassroomService } from 'src/app/services/operations/classroom/add-members-to-classroom.service';

@Component({
  selector: 'app-add-members-to-classroom',
  templateUrl: './add-members-to-classroom.component.html',
  styleUrls: ['./add-members-to-classroom.component.scss']
})
export class AddMembersToClassroomComponent implements OnInit {
  regEx = ConfigService.regExConfirm;
  errors: any;
  error: any = false;
  _loadingData = {
    classrooms: false,
    users: false
  };

  formLoading: boolean = false;

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  data: any = [];
  members: any = [];
  model: any;
  classrooms: any = [];
  courseId: any = null;
  userTypeSelect: any = [];
  operationInProgress: boolean = false;
  errorNoGoogleAccount: boolean = false;

  private _addMembersToClassroomServiceSubscription: Subscription;
  private _userServiceSubscription: Subscription;
  private _googleUserServiceSubscription: Subscription;
  private _googleClassroomServiceSubscription: Subscription;
  private _errorSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private addMembersToClassroomService: AddMembersToClassroomService,
    private userService: UserService,
    private googleService: GoogleDirectoryService,
    private googleClassroomService: GoogleClassroomService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.members = this.addMembersToClassroomService.items;
    this.data = this.userService.getTable(this.members);
    this._loadingData.users = false;
  }

  deleteEvent(rows) {
    this.addMembersToClassroomService.deleteItemsAtIndex(rows);
  }

  onSubmit() {

    this.formLoading = true;
    this.operationInProgress = true;

    this.addMembersToClassroomService.execOperation(this.members, (error) => {
      this.formLoading = false;
      this.operationInProgress = false;
      if (!error) {
        this.resetOperation();
        this.router.navigate(['/classroom']);
      }
    });
  }

  resetOperation() {
    this.model.classrooms = [];
    this.model.userType = null;
    this.addMembersToClassroomService.reset();
  }

  unsubscibeAll() {
    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
    if (this._googleClassroomServiceSubscription)
      this._googleClassroomServiceSubscription.unsubscribe();
    if (this._googleUserServiceSubscription)
      this._googleUserServiceSubscription.unsubscribe();
    if (this._addMembersToClassroomServiceSubscription)
      this._addMembersToClassroomServiceSubscription.unsubscribe();
    if (this._errorSubscription)
      this._errorSubscription.unsubscribe();
  }

  init() {
    //  inizializzo le tipologie di utente disponibili
    this.userTypeSelect = ConfigService.userTypeSelect;

    this.userTypeSelect = this.userTypeSelect.filter(x => x.key == "docente" || x.key == "studente");

    this.model = {
      classroom: null,
      userType: null
    };

    this._addMembersToClassroomServiceSubscription = this.addMembersToClassroomService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.userService.all();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.googleClassroomService.coursesList();
      }
    });

    this._googleUserServiceSubscription = this.googleService.getSubjectToUpdateUsersObservable().subscribe((result) => {
      if (result) {
        this.userService.all();
      }
    });

    this._googleClassroomServiceSubscription = this.googleClassroomService.getSubjectToUpdateCoursesObservable().subscribe((result) => {
      if (result) {

        // filtro solo le classroom attive
        if (result) {
          this.classrooms = result.filter(x => x.courseState == CourseState.ACTIVE);;
        }

        this._loadingData.classrooms = false;
        this.dataUpdated();
      }
    });

    this._errorSubscription = this.addMembersToClassroomService.getSubjectErrorsObservable().subscribe((result) => {
      if (result) {
        this.errors = result;
      }
    });

    this._loadingData.classrooms = true;
    this.googleClassroomService.coursesList();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  getInfoUser(userInfo) {
    let resUser = {
      userId: userInfo?.user_id,
      googleId: userInfo?.google_id,
      fullname: null,
      email: null,
      is_google_user: false,
      is_geniusuite_user: false,
      role: null,
      status: null,
      type: null,
      roles: [],
    };

    let googleUser = this.googleService.getUser(resUser.googleId);

    // Controllo più dettagliato prima che l'email dell'utente sia stata modificata
    let user = googleUser ? this.userService.getByGoogleId(googleUser.id) : this.userService.get(resUser.userId);

    if (user.user.roles) {
      user.user.roles.forEach(ruolo => {
        resUser.roles.push(this.translate.instant('STR.' + ruolo.name));
      });
    }

    if (user) {
      // Sincronizzato con Geniusuite
      resUser.userId = user.user.id;
      resUser.fullname = user.user.familyName + " " + user.user.givenName;
      resUser.email = user.user.email;
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = true;

      if (user.google_user) {
        resUser.googleId = user.google_user.id;

        if (user.google_user.name) {
          resUser.fullname = user.google_user.name?.familyName + " " + user.google_user.name?.givenName;
        }

        resUser.email = user.google_user.primaryEmail
        resUser.is_google_user = true;
      }
    } else if (googleUser) {
      // Non sincronizzato con Geniusuite ma non ha già i dati caricati
      resUser.fullname = "-";
      resUser.googleId = googleUser.id;

      if (googleUser.name) {
        resUser.fullname = googleUser.name?.familyName + " " + googleUser.name?.givenName;
      }

      resUser.email = googleUser.primaryEmail;
      resUser.is_google_user = true;
      resUser.is_geniusuite_user = false;
    } else {
      resUser.fullname = "Utente Esterno";
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = false;
    }

    return resUser;
  }

  classroomOnSelect($event) {
    this.model.classrooms = $event;
    this.addMembersToClassroomService.selectClassroom(this.model.classrooms);
  }

  userTypeOnChange($event) {
    this.model.userType = $event;
    this.addMembersToClassroomService.selectUserType(this.model.userType);

    if (this.model.classrooms)
      this.addMembersToClassroomService.selectClassroom(this.model.classrooms);
  }
}
