import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { CacheService, CacheType } from '../cache.service';
import { GoogleDirectoryService } from '../google/google-directory.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PlessoService {

  data: any;
  indexSelected: any;

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  constructor(private backend: BackendService, private googleDirectoryService: GoogleDirectoryService, private userService: UserService, private toast: ToastService, private cacheService: CacheService, private utility: UtilityService) { }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  /**
  * Recupero i plessi
  */
  all(success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    if (this.cacheService.isCacheValid(CacheType.Plesso)) {
      let dataCloned = this.utility.cloneData(this.data);
      this._subjectToUpdate.next(dataCloned);
      success_callback(dataCloned);
      return;
    }

    this.indexSelected = null;

    this.backend.paginate(`plesso/all`,
      (result) => {
        this.data = result;

        if(this.data){
          this.data.forEach(plesso => {
            if(plesso.classi){
              plesso.classi.sort((data1, data2) => {
                let a = data1.name;
                let b = data2.name;
                let sortRes = 0;

                if (a == null && b != null)
                  sortRes = -1;
                else if (a != null && b == null)
                  sortRes = 1;
                else if (a == null && b == null)
                  sortRes = 0;
                else if (typeof a === 'string' && typeof b === 'string')
                  sortRes = a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
                else
                  sortRes = (a < b) ? -1 : (a > b) ? 1 : 0;

                return sortRes;
              });
            }
          });
        }

        this.cacheService.updateCacheStatus(CacheType.Plesso, true);
        let dataCloned = this.utility.cloneData(this.data);
        this._subjectToUpdate.next(dataCloned);
        success_callback(dataCloned);
      }, (error) => {
        this.backend.showErrors(error);
        this._subjectToUpdate.next(null);
        error_callback(error);
      }
    );
  }

  /**
  * Create Plesso
  * @param plessoInfo Informazioni del Plesso
  * @param toastNotification Notifica tramite toast
  */
  create(plessoInfo, toastNotification = false, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.backend.post("plesso", plessoInfo).subscribe((result) => {

      if (toastNotification)
        this.toast.success('Plesso Aggiunto');

      success_callback(result);
    }, (error) => {
      this._subjectToUpdate.next(null);
      error_callback(error);
    })
  }

  /**
  * Update Plesso
  * @param plessoId Id del Plesso
  * @param plessoInfo Informazioni del Plesso
  * @param toastNotification Notifica tramite toast
  */
  update(plessoId, plessoInfo, toastNotification = false, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.backend.put(`plesso/${plessoId}`, plessoInfo).subscribe((result) => {

      if (toastNotification)
        this.toast.success('Plesso Modificato');

      success_callback(result);
    }, (error) => {
      this._subjectToUpdate.next(null);
      error_callback(error);
    })
  }

  /**
    * Delete Plesso
    * @param plessoId Id del Plesso
    * @param deleteInfo Informazioni di Delete del Plesso
    * @param toastNotification Notifica tramite toast
    */
  delete(plessoId, deleteInfo, toastNotification = false, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.backend.delete(`plesso/${plessoId}/delete`, deleteInfo).subscribe((result) => {

      if (toastNotification)
        this.toast.success('Plesso Eliminato');

      success_callback(result);
    }, (error) => {
      this._subjectToUpdate.next(null);
      error_callback(error);
    })
  }

  /**
  * Recupero il plesso
  * @param plessoId Id del Plesso
  */
  get(plessoId, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.indexSelected = null;

    if (this.data) {
      this.indexSelected = plessoId;
      let plesso = this.data.find(x => x.id == plessoId);

      if (!plesso) {
        error_callback(null);
        return;
      }

      if (plesso.google_org_unit) {

        let orgUnit = this.googleDirectoryService.getOrgUnit(plesso.google_org_unit.orgUnitId);

        if (orgUnit) {
          plesso.google_org_unit.not_found = false;
          plesso.google_org_unit = {
            ...orgUnit,
            ...plesso.google_org_unit
          }
        } else {
          plesso.google_org_unit.not_found = true;
        }
      }

      if (plesso.google_group && plesso.google_group.length > 0) {
        for (let index = 0; index < plesso.google_group.length; index++) {
          let element = plesso.google_group[index];
          let group = this.googleDirectoryService.getGroupByGroupKey(element.groupKey);

          if (group) {
            element.not_found = false;
          } else {
            element.not_found = true;
          }

          plesso.google_group[index] = {
            ...group,
            ...element
          };
        }
      }

      if (plesso.classi) {

        plesso.classi.forEach(classe => {

          // Recupero dati Organizzazioni
          if (classe.google_org_unit) {

            let orgUnit = this.googleDirectoryService.getOrgUnit(classe.google_org_unit.orgUnitId);

            if (orgUnit) {
              classe.google_org_unit.not_found = false;
              classe.google_org_unit = {
                ...orgUnit,
                ...classe.google_org_unit
              }
            } else {
              classe.google_org_unit.not_found = true;
            }
          }

          // Recupero dati Gruppi
          if (classe.google_group && classe.google_group.length > 0) {
            for (let index = 0; index < classe.google_group.length; index++) {
              let element = classe.google_group[index];
              let group = this.googleDirectoryService.getGroupByGroupKey(element.groupKey);

              if (group) {
                element.not_found = false;
              } else {
                element.not_found = true;
              }

              classe.google_group[index] = {
                ...group,
                ...element
              };
            }
          }

          // Recupero dati Classroom
          if (classe.google_classroom && classe.google_classroom.length > 0) {
            // TODO Merge Dati con Google, simile a Gruppi
          }

          // Recupero Docenti Classi
          if (classe.docenti && classe.docenti.length > 0) {
            // Merge Dati
            for (let index = 0; index < classe.docenti.length; index++) {
              let docente = classe.docenti[index];
              let userCheckGoogle = null;

              this.userService.get(docente.id, (user) => {
                userCheckGoogle = user;
              });

              classe.docenti[index] = {
                ...userCheckGoogle,
                ...docente
              };
            }
          }

          // Recupero Studenti Classi
          if (classe.studenti && classe.studenti.length > 0) {
            // Merge Dati per View
            for (let index = 0; index < classe.studenti.length; index++) {
              let studente = classe.studenti[index];
              let userCheckGoogle = null;

              this.userService.get(studente.id, (user) => {
                userCheckGoogle = user;
              });

              classe.studenti[index] = {
                ...userCheckGoogle,
                ...studente
              };
            }
          }
        });

        plesso.classi.sort((data1, data2) => {
          let a = data1.name;
          let b = data2.name;
          let sortRes = 0;

          if (a == null && b != null)
            sortRes = -1;
          else if (a != null && b == null)
            sortRes = 1;
          else if (a == null && b == null)
            sortRes = 0;
          else if (typeof a === 'string' && typeof b === 'string')
            sortRes = a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
          else
            sortRes = (a < b) ? -1 : (a > b) ? 1 : 0;

          return sortRes;
        });
      }

      if (plesso) {
        let dataCloned = this.utility.cloneData(plesso);
        success_callback(dataCloned);
        return dataCloned;
      } else {
        error_callback(null);
        return null;
      }
    } else {
      error_callback(null);
      return null;
    }
  }

  getPlessoByName(nome_plesso, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    let plesso = this.data.find(x => x.name.toLowerCase() == nome_plesso.toLowerCase());

    if (!plesso) {
      error_callback(null);
      return;
    }

    return this.get(plesso.id, success_callback, error_callback);
  }

  /**
  * Recupero una Classe dal Plesso
  * @param plessoId Id del Plesso
  * @param classeId Id della Classe
  */
  getClasse(plessoId, classeId, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.indexSelected = null;

    if (this.data) {

      let plesso = this.data.find(x => x.id == plessoId);

      if (!plesso || !plesso.classi) {
        error_callback(null);
        return;
      }

      let classe = plesso.classi.find(x => x.id == classeId);

      if (!classe) {
        error_callback(null);
        return;
      }

      if (classe.google_org_unit) {
        // TODO Ipotetico controllo se esiste ancora l'orgunit su Google
        let orgUnit = this.googleDirectoryService.getOrgUnit(classe.google_org_unit.orgUnitId);

        if (orgUnit) {
          classe.google_org_unit = {
            ...orgUnit,
            ...classe.google_org_unit
          }
        }
      }

      if (classe.google_group && classe.google_group.length > 0) {
        for (let index = 0; index < classe.google_group.length; index++) {
          let element = classe.google_group[index];
          let group = this.googleDirectoryService.getGroupByGroupKey(element.groupKey);
          classe.google_group[index] = {
            ...group,
            ...element
          };
        }
      }

      // Recupero dati Classroom
      if (classe.google_classroom && classe.google_classroom.length > 0) {
        // TODO Merge Dati con Google, simile a Gruppi
      }

      // Recupero Docenti Classi
      if (classe.docenti && classe.docenti.length > 0) {
        // Merge Dati
        for (let index = 0; index < classe.docenti.length; index++) {
          let docente = classe.docenti[index];
          let userCheckGoogle = null;

          this.userService.get(docente.id, (user) => {
            userCheckGoogle = user;
          });

          classe.docenti[index] = {
            ...userCheckGoogle,
            ...docente
          };
        }
      }

      // Recupero Studenti Classi
      if (classe.studenti && classe.studenti.length > 0) {
        // Merge Dati per View
        for (let index = 0; index < classe.studenti.length; index++) {
          let studente = classe.studenti[index];
          let userCheckGoogle = null;

          this.userService.get(studente.id, (user) => {
            userCheckGoogle = user;
          });

          classe.studenti[index] = {
            ...userCheckGoogle,
            ...studente
          };
        }
      }

      if (classe) {
        let dataCloned = this.utility.cloneData(classe);
        success_callback(dataCloned);
        return dataCloned;
      }
      else {
        error_callback(null);
        return null;
      }

    } else {
      error_callback(null);
      return null;
    }
  }

  getClasseByName(plessoId, nome_classe, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    let plesso = this.data.find(x => x.id == plessoId);

    if (!plesso || !plesso.classi) {
      error_callback(null);
      return;
    }

    let classe = plesso.classi.find(x => x.name.toLowerCase() == nome_classe.toLowerCase());

    if (!classe) {
      error_callback(null);
      return;
    }

    return this.getClasse(plesso.id, classe.id, success_callback, error_callback);
  }
}
