<div class="card card-hover-shadow">
  <div class="card-body">
    <div class="row justify-content-sm-center text-center py-5">
      <div class="col-sm-12 col-md-8">
        <img class="img-fluid mb-5" src="./assets/theme/svg/illustrations/chat.svg" alt="Image Description"
          style="max-width: 15rem;">
        <h1>{{'SETUP.end.title' | translate}}</h1>
        <p>{{'SETUP.end.sottotitolo' | translate}}</p>
        <button (click)="saveFile()" type="button" class="btn btn-primary">
          <i class="mr-2 fas fa-file-download"></i>{{'SETUP.end.btn' | translate}}
        </button>

        <h1 class="mt-5">{{'SETUP.end.title_reset' | translate}}</h1>
        <p>{{'SETUP.end.sottotitolo_reset' | translate}}</p>
        <button (click)="deleteFile()" type="button" class="btn btn-primary">
          <i class="mr-2 fas fa-magic"></i>{{'SETUP.end.btn_reset' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
