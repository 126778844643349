import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleClassroomService } from 'src/app/services/google/google-classroom.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { ApplicationReportType, GoogleReportService } from 'src/app/services/google/google-report.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-google-classroom-stat-page',
  templateUrl: './google-classroom-stat-page.component.html',
  styleUrls: ['./google-classroom-stat-page.component.scss']
})
export class GoogleClassroomStatPageComponent implements OnInit {

  formLoading: boolean = false;

  rangeDates: any;
  maxDate: any;
  plesso: any;
  classe: any;

  classi: any = [];
  plessi: any = [];

  classroom: any[];
  classroomData: any[];
  classroomError: any;

  studenti: any;
  studentiData: any;
  studentiError: any;

  docenti: any;
  docentiData: any;
  docentiError: any;

  isLoading = {
    widget: false,
    plessi: false,
    users: false,
    utenti: false,
    classroom: false,
    studenti: false,
    docenti: false
  };

  parameterToView = [{
    key: "duration_seconds",
    valueKey: "intValue",
    value: 0,
  }, {
    key: "meeting_code",
    valueKey: "value",
    value: "",
  }, {
    key: "conference_id",
    valueKey: "value",
    value: "",
  }, {
    key: "ip_address",
    valueKey: "value",
    value: "",
  }];

  private _plessoServiceDataSubscription: Subscription;
  private _userServiceSubscription: Subscription;

  private _googleServiceUsersSubscription: Subscription;
  private _googleServiceClassroomSubscription: Subscription;

  constructor(
    private plessoService: PlessoService,
    private userService: UserService,
    private googleDirectoryService: GoogleDirectoryService,
    private classroomService: GoogleClassroomService,
    private router: Router,
    private utility: UtilityService,
  ) { }

  ngOnInit() {
    this.isLoading.widget = true;

    let start = new Date();
    let end = new Date();
    this.maxDate = new Date();
    start.setDate(end.getDate() - 8)
    end.setDate(end.getDate() - 1)
    this.rangeDates = [start, end];
    this.plesso = null;
    this.classe = null;
    this.isLoading.widget = false;

    this._plessoServiceDataSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.plessi = result;

      this.isLoading.plessi = false;
    });
    this.isLoading.plessi = true;
    this.plessoService.all();

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.updateStatistics();
      }
    });

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe(
      (result) => {
        if (result) {
          this.userService.all();
        }
      });

    this.isLoading.classroom = true;
    this.googleDirectoryService.usersList();


  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._plessoServiceDataSubscription)
      this._plessoServiceDataSubscription.unsubscribe();



    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();

    if (this._googleServiceClassroomSubscription)
      this._googleServiceClassroomSubscription.unsubscribe();
  }

  plessoOnSelect(plessoSelected) {
    this.plesso = null;
    this.classe = null;
    this.updateStatistics();

    if (plessoSelected) {
      this.plessoService.get(plessoSelected.id, (plesso) => {
        this.plesso = plesso;
        this.classi = plesso.classi;
      });
    }
  }

  dateRangeOnSelect(dataRange) {
    this.isLoading.widget = true;
    // Se viene selezionato solo una data metto come range fino ad oggi
    if (this.rangeDates && this.rangeDates.length == 2 && this.rangeDates[1] == null) {
      let end = new Date();
      end.setDate(end.getDate() - 1);
      this.rangeDates = [this.rangeDates[0], end];
    }

    this.updateStatistics();
    setTimeout(() => {
      this.isLoading.widget = false;
    }, 300);
  }

  classeOnSelect(classeSelected) {

    if (classeSelected) {
      this.plessoService.getClasse(this.plesso.id, classeSelected.id, (classe) => {
        this.classe = classe ? classe : null;
        this.updateStatistics();
      });
    } else {
      this.classe = null;
      this.updateStatistics();
    }
  }

  selectTab(index) {
    switch (index) {
      case 1:
      case 2:
      case 3:
        this.updateStatistics();
        break;
    }
  }

  updateStatistics() {
    this.isLoading.classroom = true;

    this._googleServiceClassroomSubscription = this.classroomService.getSubjectToUpdateCoursesObservable().subscribe(
      (result) => {
        this.isLoading.classroom = false;
        if (result) {
          this.updateData(result);
        }
      });

    this.classroomService.coursesList();
  }

  updateData(classroom) {
    this.classroom = classroom;
    this.classroomData = this.parseClassroomResult(this.classroom);
  }

  parseClassroomResult(result) {

    let table = [];

    result.forEach(element => {

      let proprietario = []

      let sotto_nome = (element.section ? element.section : "") + " " + (element.room ? "(" + element.room + ")" : "");

      if (element.ownerId) {
        let googleuser_proprietario = this.googleDirectoryService.getUser(element.ownerId);

        if (googleuser_proprietario) {
          proprietario = [
            googleuser_proprietario.name.familyName + " " + googleuser_proprietario.name.givenName,
            googleuser_proprietario.primaryEmail
          ]
        }
      }

      table.push(
        {
          "nome": [element.name, sotto_nome],
          "description": [element.descriptionHeading, element.description],
          "proprietario": proprietario,
          "_export_data": {
            name: element.name,
            section: element.section,
            descriptionHeading: element.descriptionHeading,
            description: element.description,
            room: element.room,
            proprietarioName: proprietario[0] ? proprietario[0] : "",
            proprietarioEmail: proprietario[1] ? proprietario[1] : "",
            ownerId: element.ownerId,
            link: element.alternateLink,
            courseGroupEmail: element.courseGroupEmail,
            teacherGroupEmail: element.teacherGroupEmail,
            creationTime: element.creationTime,
            updateTime: element.updateTime
          },
        });
    });

    return table;
  }

  rowClickCourse(data) {
    let course = this.classroom[data.index];
    this.router.navigate(['/classroom/' + course.id]);
  }

  studentiUpdated() {
    this.isLoading.studenti = true;

    this.studenti = [];
    this.studenti = this.classe.studenti;

    this.studentiData = this.parseUsersResult(this.studenti);
    this.isLoading.studenti = false;

  }

  docentiUpdated() {
    this.isLoading.docenti = true;

    this.docenti = [];
    this.docenti = this.classe.docenti;

    this.docentiData = this.parseUsersResult(this.docenti);
    this.isLoading.docenti = false;

  }

  parseUsersResult(result) {
    let table = [];

    return table;
  }

  parseMeetResult(result) {
    let table = [];

    result.forEach(element => {
      let user = this.getInfoUser(element.google_id);

      let infoParameters = this.getKeyValueStatUser(element);
      let duration_seconds = this.utility.convertMinutesIntoString(infoParameters["duration_seconds"]);

      table.push({
        "nome": [user.fullname, user.email],
        "duration_seconds": duration_seconds,
        "meeting_code": infoParameters["meeting_code"],
        "ip_address": infoParameters["ip_address"],
        "sync": {
          google: user.is_google_user,
          geniusuite: user.is_geniusuite_user
        },
        "_export_data": {
          familyName: user.familyName,
          givenName: user.givenName,
          email: user.email,
          "duration_seconds": duration_seconds,
          "meeting_code": infoParameters["meeting_code"],
          "ip_address": infoParameters["ip_address"],
        },
        "_copyDataKey": "nome",
      });
    });

    return table;
  }

  getKeyValueStatUser(element) {
    let infoParameters = {};

    if (element.parameters && element.parameters.length > 0) {
      this.parameterToView.forEach(parView => {
        let valPar = element.parameters.findIndex(x => x.name == parView.key);
        if (valPar !== -1) {
          infoParameters[parView.key] = element.parameters[valPar][parView.valueKey];
        }
      });
    }

    return infoParameters;
  }

  getParsedHtmlExpandData(infoParameters) {
    let html = '';

    html += `<div class="ml-1 mr-1 mb-2"><div class="card card-material meet"> <div class="row no-gutters"> <div class="col-md-4 card-material-img"> <img class="img-fluid" src="./assets/Google Image/meet.png"> </div> <div class="col-md-8"> <div class="card-body pl-2 pt-2 pb-2"> <h5 class="card-title text-truncate"> ${this.utility.convertMinutesIntoString(infoParameters["duration_seconds"])} </h5> <small class="card-text text-white">${infoParameters["meeting_code"]}</small> </div> </div> </div> </div></div>`

    return html;
  }

  rowClickMeet(data) {
    // this.router.navigate(['user/' + user.id]);
  }

  rowClickStudente(data) {
    let user = this.studenti[data.index];
    this.router.navigate(['user/' + user.id]);
  }

  rowClickDocente(data) {
    let user = this.docenti[data.index];
    this.router.navigate(['user/' + user.id]);
  }


  getInfoUser(googleId) {
    let resUser = {
      googleId: googleId,
      familyName: null,
      givenName: null,
      fullname: null,
      email: null,
      is_google_user: false,
      is_geniusuite_user: false
    };

    if (!googleId) {
      resUser.familyName = "Not found";
      resUser.givenName = "Not found";
      resUser.fullname = "Not found";
      resUser.email = "Not found";
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = false;
      return resUser;
    }

    let user = this.userService.getByGoogleId(googleId);

    if (user) {
      // Sincronizzato con Geniusuite
      resUser.familyName = user.user.familyName;
      resUser.givenName = user.user.givenName;

      resUser.fullname = user.user.familyName + " " + user.user.givenName;
      resUser.email = user.user.email;
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = true;

      if (user.google_user) {
        resUser.googleId = googleId;

        if (user.google_user.name) {
          resUser.familyName = user.google_user.name?.familyName;
          resUser.givenName = user.google_user.name?.givenName;
          resUser.fullname = user.google_user.name?.familyName + " " + user.google_user.name?.givenName;
        }

        resUser.email = user.google_user.primaryEmail
        resUser.is_google_user = true;
      }
    } else {
      let googleUser = this.googleDirectoryService.getUser(googleId);
      if (googleUser) {
        // Non sincronizzato con Geniusuite ma non ha già i dati caricati
        resUser.familyName = "Not found";
        resUser.givenName = "Not found";
        resUser.fullname = "Not found";

        if (googleUser.name) {
          resUser.familyName = googleUser.name?.familyName;
          resUser.givenName = googleUser.name?.givenName;
          resUser.fullname = googleUser.name?.familyName + " " + googleUser.name?.givenName;
        }

        resUser.email = googleUser.primaryEmail;
        resUser.is_google_user = true;
        resUser.is_geniusuite_user = false;
      } else {
        resUser.familyName = "Not found";
        resUser.givenName = "Not found";
        resUser.fullname = "Not found";
        resUser.email = "Not found";
        resUser.is_google_user = false;
        resUser.is_geniusuite_user = false;
      }
    }

    return resUser;
  }

}
