import { Injectable } from '@angular/core';
import { element } from 'protractor';
import { Observable, Subject } from 'rxjs';
import { CacheService, CacheType } from './cache.service';
import { MonitorService } from './general/monitor.service';
import { OrganigrammaService } from './general/organigramma.service';
import { PlessoService } from './general/plesso.service';
import { RequestChangePasswordService } from './general/request-change-password.service';
import { UserService } from './general/user.service';
import { GoogleClassroomService } from './google/google-classroom.service';
import { GoogleDirectoryService } from './google/google-directory.service';

@Injectable({
  providedIn: 'root'
})
export class SyncdataService {

  private _subjectToUpdateSyncIntegrity: Subject<any> = new Subject<any>();
  private _subjectToUpdateGroupsIntegrity: Subject<any> = new Subject<any>();
  private _subjectToUpdateClassroomIntegrity: Subject<any> = new Subject<any>();

  public syncUsersIntegrityErrors: any = [];
  public syncPlessoIntegrityErrors: any = [];
  public syncOrganigrammaIntegrityErrors: any = [];

  public groupsIntegrityErrors: any = [];
  public classroomIntegrityErrors: any = [];

  constructor(
    private cacheService: CacheService,
    private plessoService: PlessoService,
    private organigrammaService: OrganigrammaService,
    private userService: UserService,
    private googleDirectoryService: GoogleDirectoryService,
    private requestChangePasswordService: RequestChangePasswordService,
    private googleClassroom: GoogleClassroomService,
    private monitorService: MonitorService
  ) { }

  // Fatto da Enrico
  // Probailmente non serve più questo Observable perché l'aggiornamento viene centralizzato in questo service invece che delegarlo a tutti i componenti
  // public getSubjectToUpdateObservable(): Observable<any> {
  //   return this._subjectToUpdate.asObservable();
  // }

  public getSubjectToUpdateObservableSyncIntegrity(): Observable<any> {
    return this._subjectToUpdateSyncIntegrity.asObservable();
  }

  public getSubjectToUpdateObservableGroupsIntegrity(): Observable<any> {
    return this._subjectToUpdateGroupsIntegrity.asObservable();
  }

  public getSubjectToUpdateObservableClassroomIntegrity(): Observable<any> {
    return this._subjectToUpdateClassroomIntegrity.asObservable();
  }

  handleBatchStatus(batch) {

    if (!batch) {
      console.error("Batch mancante");
      return
    }

    let name = batch.job_batch_name;

    if (!name || name.length <= 0) {
      console.error("Nome batch mancante, è obbligatorio assegnare un nome ad ogni batch");
      return;
    }

    // Invalido tutte le cache perché ogni operazione si porta con se molti cambiamenti
    // bisogna ripensare a come invalidare la cache in base all'operazione specifica ed eventuali parametri
    // altrimenti ha poco senzo fare una distinzione come sotto perché ogni entità potrebbe, potenzialmente, aggiornare diverse cose
    // this.cacheService.updateAllCacheStatus(false);

    let operations = name.split(".");

    operations.forEach(operation => {

      switch (operation) {
        case "organigramma":
          this.cacheService.updateCacheStatus(CacheType.Organigramma, false);
          break;
        case "materia":
        case "cattedre":
          this.cacheService.updateCacheStatus(CacheType.Materia, false);
          break;
        case "plesso":
          this.cacheService.updateCacheStatus(CacheType.Plesso, false);
          break;
        case "classe":
          this.cacheService.updateCacheStatus(CacheType.Plesso, false);
          break;
        case "classroom":
          this.cacheService.updateCacheStatus(CacheType.GoogleClassroom, false);
          break;
        case "update-password":
          this.cacheService.updateCacheStatus(CacheType.RequestChangePassword, false);
          break;
        case "monitor":
          this.cacheService.updateCacheStatus(CacheType.Monitor, false);
          break;
        case "user":
        case "users":
        case "orgunit":
        case "importazione":
        case "operation":
        default:
          this.cacheService.updateCacheStatus(CacheType.Plesso, false);
          this.cacheService.updateCacheStatus(CacheType.Organigramma, false);
          this.cacheService.updateCacheStatus(CacheType.User, false);
          this.cacheService.updateCacheStatus(CacheType.GoogleUsersDeleted, false);
          this.cacheService.updateCacheStatus(CacheType.GoogleUsers, false);
          break;
      }

      // org units e gruppi possono cambiare sempre, in tutte le operazioni, quindi vanno sempre invalidati
      this.cacheService.updateCacheStatus(CacheType.GoogleOrgUnit, false);
      this.cacheService.updateCacheStatus(CacheType.GoogleGroups, false);
    });

    // Invalido sempre la cache in modo da avere sempre una navigazione aggiornata nella sezioni
    // ma lancio l'aggiornamento dei dati solo quando è completato il batch altrimenti ci sarebbero troppe chiamate
    if (batch.finished_at) {
      // this._subjectToUpdate.next(operations); // Vedi commento sopra del perché non serve più questo Observable
      this.updateServiceData();
    }
  }

  /**
   * Aggiorna i dati dei servizi che hanno la cache scaduta
   */
  private updateServiceData() {

    if (!this.cacheService.isCacheValid(CacheType.Plesso)) {
      this.plessoService.all();
    }

    if (!this.cacheService.isCacheValid(CacheType.Organigramma)) {
      this.organigrammaService.all();
    }

    if (!this.cacheService.isCacheValid(CacheType.User)) {
      this.userService.all();
    }

    if (!this.cacheService.isCacheValid(CacheType.GoogleUsers)) {
      this.googleDirectoryService.usersList();
    }

    if (!this.cacheService.isCacheValid(CacheType.GoogleUsersDeleted)) {
      this.googleDirectoryService.usersDeletedList();
    }

    if (!this.cacheService.isCacheValid(CacheType.GoogleOrgUnit)) {
      this.googleDirectoryService.orgunitsList();
    }

    if (!this.cacheService.isCacheValid(CacheType.GoogleGroups)) {
      this.googleDirectoryService.groupsList();
    }

    if (!this.cacheService.isCacheValid(CacheType.GoogleClassroom)) {
      this.googleClassroom.coursesList();
    }

    if (!this.cacheService.isCacheValid(CacheType.RequestChangePassword)) {
      this.requestChangePasswordService.all();
    }

    if (!this.cacheService.isCacheValid(CacheType.Monitor)) {
      this.monitorService.all();
    }
  }

  public updateSystemDataAndCheckIntegrity() {

    // invalido la cache e forzo l'aggiornamento di tutti i dati
    this.cacheService.updateAllCacheStatus(false);

    this.userService.all((result) => {
      this.plessoService.all((result) => {
        this.organigrammaService.all((result) => {
          this.googleDirectoryService.orgunitsList((result) => {
            this.googleDirectoryService.groupsList((result) => {
              this.googleClassroom.coursesList((result) => {
                this.checkPlessoIntegrity(this.plessoService.data);
                this.checkOrganigrammaIntegrity(this.organigrammaService.data);
                this.checkUserIntegrity(this.userService.data);
              });
            });
          });
        });
      });
    });
  }

  public checkGroupsIntegrity() {

    this.groupsIntegrityErrors = [];

    // raccolgo tutti i gruppi da analizzare con le info che ho bisogno
    let elements = [];

    this.organigrammaService.all((organigrammi) => {

      this.plessoService.all((plessi) => {

        // Cerco in ogni organigramma
        organigrammi.forEach(organigramma => {

          // escludo l'organigramma studenti uscenti
          if (organigramma.relation_info != 'studenti_uscenti') {

            // Se hanno un google group associato
            if (organigramma.google_group) {

              // recupero gli utenti dell'organigramma
              let users_in_organigramma = this.userService.getByOrganigramma(organigramma.id);

              if (users_in_organigramma && users_in_organigramma.length > 0) {

                if (organigramma.google_group) {

                  organigramma.google_group.forEach(group => {
                    elements.push({
                      entity: organigramma, users_in_entity: users_in_organigramma, type: 'organigramma', group: group
                    });
                  });
                }
              }
            }
          }


        });

        plessi.forEach(plesso => {

          // cerco in tutte le classi di un plesso
          plesso.classi.forEach(classe => {

            if (classe.google_group) {

              classe.google_group.forEach(group => {

                if (classe.studenti && classe.studenti.length > 0 && group.relation_info == 'studenti') {

                  elements.push({
                    entity: classe, users_in_entity: classe.studenti, type: 'classe', group: group
                  });
                }

                if (classe.docenti && classe.docenti.length > 0 && group.relation_info == 'docenti') {

                  elements.push({
                    entity: classe, users_in_entity: classe.docenti, type: 'classe', group: group
                  });
                }
              });
            }

          });
        });
      });
    });

    if (elements.length > 0) {
      this.startCheckEntityGroup(0, elements);
    }
    else {
      this._subjectToUpdateGroupsIntegrity.next({ errors: [], progress: 100 });
    }
  }

  private startCheckEntityGroup(index: number, elements: any) {

    let element = elements[index];

    // sono arrivato alla fine
    if (!element) {
      return;
    }

    this.googleDirectoryService.groupMembersList(element.group.groupKey, (members) => {

      element.users_in_entity.forEach(user_in_entity => {

        let found_in_group = false;

        for (let index in members) {
          if (user_in_entity.google_id == members[index].id) {
            found_in_group = true;
            break;
          }
        }

        // se non lo trovo nel gruppo lo segnalo come incongruenza
        if (!found_in_group) {
          let error = { type: element.type, error: 'STR.missing_google_user_in_group', entity: element.entity, user: user_in_entity, group: element.group };
          this.groupsIntegrityErrors.push(error);
        }
      });

      let next = index + 1;
      let progress: number = (next / elements.length) * 100

      this._subjectToUpdateGroupsIntegrity.next({ errors: this.groupsIntegrityErrors, progress: progress });

      this.startCheckEntityGroup(next, elements);
    });

  }

  public checkUserIntegrity(data) {

    this.syncUsersIntegrityErrors = [];

    if (!data)
      return;

    data.forEach(user => {

      // controllo che esista l'utente google collegato
      if (user.google_id) {

        let google_user = this.googleDirectoryService.getUser(user.google_id);

        if (!google_user) {
          let error = { type: 'user', error: 'STR.missing_google_user', entity: user };

          this.syncUsersIntegrityErrors.push(error);
        }
      }
    });

    this._subjectToUpdateSyncIntegrity.next(this.getAllSyncIntegrityErrors());

  }

  public checkPlessoIntegrity(data) {

    this.syncPlessoIntegrityErrors = [];

    if (!data)
      return;

    data.forEach(plesso => {

      this.checkEntityIntegrity('plesso', plesso, this.syncPlessoIntegrityErrors, true);

      // controllo le classi
      if (plesso.classi) {

        plesso.classi.forEach(classe => {

          this.checkEntityIntegrity('classe', classe, this.syncPlessoIntegrityErrors, true);
        });
      }
    });

    this._subjectToUpdateSyncIntegrity.next(this.getAllSyncIntegrityErrors());

  }

  public checkOrganigrammaIntegrity(data) {

    this.syncOrganigrammaIntegrityErrors = [];

    if (!data)
      return;

    data.forEach(organigramma => {

      let orgUnitRequired = false;

      if (organigramma.relation_info == 'studenti' || organigramma.relation_info == 'docenti' || organigramma.relation_info == 'studenti_uscenti') {
        orgUnitRequired = true;
      }

      this.checkEntityIntegrity('organigramma', organigramma, this.syncOrganigrammaIntegrityErrors, orgUnitRequired);
    });

    this._subjectToUpdateSyncIntegrity.next(this.getAllSyncIntegrityErrors());
  }

  private checkEntityIntegrity(type: string, entity: any, errors: any, orgUnitRequired: boolean = false) {

    // controllo che esista unità organizzativa
    if (orgUnitRequired && !entity.google_org_unit) {

      let error = { type: type, error: 'STR.missing_org_unit', entity: entity };
      errors.push(error);
    }

    // se impostata controllo che esista su google
    if (entity.google_org_unit) {

      let org_unit = this.googleDirectoryService.getOrgUnit(entity.google_org_unit.orgUnitId);

      if (!org_unit) {
        let error = { type: type, error: 'STR.missing_google_org_unit', entity: entity };

        errors.push(error);
      }
    }

    // controllo i gruppi ma non sono obbligatori
    if (entity.google_group) {

      entity.google_group.forEach(group => {

        // controllo se la groupkey associata esista ancora
        let google_group = this.googleDirectoryService.getGroupByGroupKey(group.groupKey);

        if (!google_group) {
          let error = { type: type, error: 'STR.missing_google_group', entity: entity };

          errors.push(error);
        }
      });
    }

    // controllo il gruppo dell'organigramma se presente
    if (entity.google_group_organigramma) {

      // controllo se la groupkey associata esista ancora
      let google_group = this.googleDirectoryService.getGroupByGroupKey(entity.google_group_organigramma.groupKey);

      if (!google_group) {
        let error = { type: type, error: 'STR.missing_google_group', entity: entity };

        errors.push(error);
      }
    }
  }

  public checkClassroomIntegrity() {

    this.classroomIntegrityErrors = [];

    // raccolgo tutte le classroom da analizzare con le info che ho bisogno
    let elements = [];

    this.googleClassroom.coursesList((classrooms) => {

      this.plessoService.all((plessi) => {

        plessi.forEach(plesso => {

          // cerco in tutte le classi di un plesso
          plesso.classi.forEach(classe => {

            classe.google_classroom.forEach(classroom => {
              elements.push({
                entity: classe, type: 'classe', classroom: classroom
              });
            });
          });
        });

        if (elements.length > 0) {
          this.startCheckEntityClassroom(0, elements);
        }
        else {
          this._subjectToUpdateClassroomIntegrity.next({ errors: [], progress: 100 });
        }
      });
    });
  }

  private startCheckEntityClassroom(index: number, elements: any) {

    let element = elements[index];

    // sono arrivato alla fine
    if (!element) {
      return;
    }

    let classe = element.entity;
    let classroom = element.classroom;

    this.googleClassroom.courseGet(classroom.courseId, (info) => {

      // recupero tutti i docenti di una classroom
      this.googleClassroom.courseTeacherList(classroom.courseId, (course_teachers) => {

        // recupero tutti gli studenti di una classroom
        this.googleClassroom.courseStudentList(classroom.courseId, (course_students) => {

          // cerco gli studenti presenti in classe ma non in classroom (DA AGGIUNGERE)
          classe.studenti.forEach(student => {

            if (student.google_id) {

              let to_add = true;

              course_students.forEach(student_classroom => {
                if (student_classroom.userId == student.google_id && to_add) {
                  to_add = false;
                }
              });

              if (to_add) {
                let error = { type: 'classe', error: 'STR.student_to_add_in_classroom', entity: element.entity, user: student, classroom: classroom, info: info };
                this.classroomIntegrityErrors.push(error);
              }
            }

          });

          // cerco i docenti presenti in classe ma non in classroom (DA AGGIUNGERE)
          classe.docenti.forEach(teacher => {

            if (teacher.google_id) {

              let to_add = true;

              course_teachers.forEach(teacher_classroom => {
                if (teacher_classroom.userId == teacher.google_id && to_add) {
                  to_add = false;
                }
              });

              if (to_add) {
                let error = { type: 'classe', error: 'STR.teacher_to_add_in_classroom', entity: element.entity, user: teacher, classroom: classroom, info: info };
                this.classroomIntegrityErrors.push(error);
              }
            }

          });

          // cerco i docenti presenti in classroom ma non in classe (DA RIMUOVERE)
          course_teachers.forEach(teacher_classroom => {

            let to_remove = true;

            classe.docenti.forEach(teacher => {
              if (teacher.google_id && teacher_classroom.userId == teacher.google_id && to_remove) {
                to_remove = false;
              }
            });

            if (to_remove && teacher_classroom.userId != info.ownerId) {
              let error = { type: 'classroom', error: 'STR.teacher_to_remove_from_classroom', entity: element.entity, user: teacher_classroom, classroom: classroom, info: info };
              this.classroomIntegrityErrors.push(error);
            }

          });

          // cerco gli studenti presenti in classroom ma non in classe (DA RIMUOVERE)
          course_students.forEach(student_classroom => {

            let to_remove = true;

            classe.studenti.forEach(student => {
              if (student.google_id && student_classroom.userId == student.google_id && to_remove) {
                to_remove = false;
              }
            });

            if (to_remove) {
              let error = { type: 'classroom', error: 'STR.student_to_remove_from_classroom', entity: element.entity, user: student_classroom, classroom: classroom, info: info };
              this.classroomIntegrityErrors.push(error);
            }

          });

          let next = index + 1;
          let progress: number = (next / elements.length) * 100

          this._subjectToUpdateClassroomIntegrity.next({ errors: this.classroomIntegrityErrors, progress: progress });

          this.startCheckEntityClassroom(next, elements);

        });
      });
    });

  }

  getAllSyncIntegrityErrors() {
    return [...this.syncUsersIntegrityErrors, ...this.syncPlessoIntegrityErrors, ...this.syncOrganigrammaIntegrityErrors];
  }

}
