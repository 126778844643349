import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BackendService } from '../backend.service';
import { ToastService } from '../toast.service';
import { ClassiService } from './classi.service';
import { OrganigrammaService } from './organigramma.service';
import { PlessoService } from './plesso.service';

export enum RelationInfo {
  RelationInfoStudenti = "studenti",
  RelationInfoDocenti = "docenti",
  RelationInfoWorkers = "workers",
  RelationInfoTutor = "tutor",
  RelationInfoPlesso = "plesso",
  RelationInfoClasse = "classe",
  RelationInfoMateria = "materia",
  RelationInfoOrganigramma = "organigramma",
  RelationInfoOrganigrammaStudentiUscenti = "studenti_uscenti"
}

@Injectable({
  providedIn: 'root'
})
export class RelationService {

  private google_org_units: any;
  private google_groups: any;

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  constructor(private plessoService: PlessoService, private organigrammaService: OrganigrammaService) { }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  init() {

    this.google_org_units = [];
    this.google_groups = [];

    this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.dataUpdated(result, "classi");
    });

    this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.dataUpdated(result);
    });
  }

  dataUpdated(result, deepAttribute = null) {

    result.forEach(element => {

      if (element.google_org_unit) {

        let index = this.google_org_units.findIndex((row) => row.id == element.google_org_unit.id);

        if (index >= 0) {
          this.google_org_units[index] = element.google_org_unit;
        }
        else {
          this.google_org_units.push(element.google_org_unit);
        }
      }

      if (element.google_group) {

        element.google_group.forEach(group => {
          let index = this.google_groups.findIndex((row) => row.id == group.id);

          if (index >= 0) {
            this.google_groups[index] = group;
          }
          else {
            this.google_groups.push(group);
          }
        });
      }

      if (deepAttribute) {
        let attr = element[deepAttribute];
        this.dataUpdated(attr);
      }
    });

    this._subjectToUpdate.next(null);
  }

  setDisabledOrgUnit(orgUnits: any) {
    if (orgUnits) {
      orgUnits.forEach(element => {
        if (this.google_org_units.find((row) => row.orgUnitId == element.orgUnitId) !== undefined) {
          element.disabled = true;
        } else {
          element.disabled = false;
        }
      });
    }
  }

  setDisabledGroups(group: any) {
    if (group) {
      group.forEach(element => {
        if (this.google_groups.find((row) => row.groupKey == element.groupKey) !== undefined) {
          element.disabled = true;
        } else {
          element.disabled = false;
        }
      });
    }
  }
}
