<app-card-loading *ngIf="_loadingData.domains">
</app-card-loading>

<app-card-loading-error *ngIf="!_loadingData.domains && error" [error]="error"
  (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!_loadingData.domains && !error">
  <div class="row">
    <div class="col-12">
      <app-card-operation-activity [filterActivity]="['operation.users.update-domain']">
      </app-card-operation-activity>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <div class="card card-hover-shadow">
        <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>
        <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
          <input type="hidden" id="domain_group" formControlName="domain_group"
            [required]="groups && groups.length > 0">
          <input type="hidden" id="domain_user" formControlName="domain_user" [required]="users && users.length > 0">

          <!-- Card -->
          <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">
            <h3 class="card-title">Aggiorna Dominio</h3>
            <p class="card-text">Questa operazione consente di modificare il dominio </p>

            <!-- Tipologia di utente -->
            <div class="form-group row">
              <div class="col-12">
                <label class="input-label" for="select-domain_user">{{"STR.Seleziona_Dominio_User" |
                  translate}}</label>
                <app-select (valueChange)="domainOnSelect($event, 'domain_user')" [id]="'select-domain_user'"
                  [showClear]="true" [filterBy]="'domainName'" [placeholder]="'STR.Seleziona_Dominio_User'"
                  [data]="domains" [showValue]="'domainName'" [(value)]="model.domain_user"
                  [optionAttributeShow]="'domainName'">
                </app-select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-12">
                <label class="input-label" for="select-domain_group">{{"STR.Seleziona_Dominio_Group" |
                  translate}}</label>
                <app-select (valueChange)="domainOnSelect($event, 'domain_group')" [id]="'select-domain_group'"
                  [showClear]="true" [filterBy]="'domainName'" [placeholder]="'STR.Seleziona_Dominio_Group'"
                  [data]="domains" [showValue]="'domainName'" [(value)]="model.domain_group"
                  [optionAttributeShow]="'domainName'">
                </app-select>
              </div>
            </div>

            <div *ngIf="errorUserAccount" class="alert alert-warning mt-2 mb-0">
              <span class="text-cap d-inline">Attenzione</span> Sono stati selezionati degli utenti già
              con quel dominio,
              questi utenti non verranno aggiornati
            </div>

            <div *ngIf="errorGroupAccount" class="alert alert-warning mt-2 mb-0">
              <span class="text-cap d-inline">Attenzione</span> Sono stati selezionati dei gruppi già con
              quel dominio,
              questi gruppi non verranno aggiornati
            </div>
          </div>

          <div class="card-footer">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto py-1">
                <button class="btn btn-outline-danger" (click)="resetOperation()" [disabled]="operationInProgress">
                  <i class="far fa-backspace mr-2"></i>
                  <span>{{'STR.reset_operation' | translate}}</span>
                </button>
              </div>

              <div class="col-6 py-1">
                <div class="row justify-content-end">
                  <div class="col-auto">
                    <input autocomplete="off" type="text" id="confirmation_delete" name="confirmation_delete"
                      formControlName="confirmation_delete" class="form-control" [pattern]="regEx" required>
                    <small class="d-block text-muted">Per confermare, digita
                      <span class="confirmation-phrase">Modifica</span></small>
                  </div>
                  <div class="col-auto">
                    <button type="submit" class="btn" [disabled]="!myForm.valid || operationInProgress"
                      [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                      Avvia <i class="far fa-chevron-right ml-1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
      <!-- End Card -->
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-sm">
      <!-- Card -->
      <div class="card">
        <!-- Header -->
        <div class="card-header p-1">
          <div class="row justify-content-between align-items-center flex-grow-1">
            <div class="col">
              <tabset [justified]="true" type="pills">
                <tab #tab1="tab" (selectTab)="selectTab('users')">
                  <ng-template tabHeading>
                    <i class="fas fa-user-friends nav-icon"></i> {{ 'STR.Utenti' | translate }}
                  </ng-template>
                </tab>
                <tab #tab2="tab" (selectTab)="selectTab('groups')">
                  <ng-template tabHeading>
                    <i class="fas fa-user-friends nav-icon"></i> {{ 'STR.gruppi' | translate
                    }}
                  </ng-template>
                </tab>
              </tabset>
            </div>
          </div>
        </div>

        <app-card-datatable-ng *ngIf="tab1.active" title="{{ 'STR.Utenti' | translate }}" [data]="usersData"
          [isLoadingData]="_loadingData.users" [showAvatarOnFirstColumn]="true" [showDeleteButton]="true"
          labelDeleteButton="Rimuovi dall'operazione" iconDeleteButton="user-times"
          (deleteClickEvent)="deleteUserEvent($event)" [showExportButton]="true">
        </app-card-datatable-ng>

        <app-card-datatable-ng *ngIf="tab2.active" title="{{ 'STR.gruppi' | translate }}" [data]="groupsData"
          [isLoadingData]="_loadingData.groups" [showAvatarOnFirstColumn]="true" [showDeleteButton]="true"
          labelDeleteButton="Rimuovi dall'operazione" iconDeleteButton="user-times"
          (deleteClickEvent)="deleteGroupEvent($event)" [showExportButton]="true">
        </app-card-datatable-ng>
      </div>
      <!-- End Card -->
    </div>
  </div>
</ng-container>
