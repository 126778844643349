import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { Subscription } from 'rxjs';
import { ActivityService } from 'src/app/services/activity.service';
import { FileimportService } from 'src/app/services/fileimport.service';
import { MateriaService } from 'src/app/services/general/materia.service';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { OrderTypeList, UtilityService } from 'src/app/services/utility.service';

type AOA = any[][];

@Component({
  selector: 'app-setup-wizard-config-col-plessoclasse',
  templateUrl: './setup-wizard-config-col-plessoclasse.component.html',
  styleUrls: ['./setup-wizard-config-col-plessoclasse.component.scss']
})
export class SetupWizardConfigColPlessoClasseComponent implements OnInit, OnDestroy {

  uploadedFileCompleted: boolean;
  fileSize: string;
  fileName: string;
  fileLastModified: number;

  @ViewChild('tabset') tabset: TabsetComponent;
  @ViewChild('tabPlesso') tabPlesso: TabDirective;
  @ViewChild('tabOrganigramma') tabOrganigramma: TabDirective;

  organigramma: any;
  organigrammiTreeView: any;
  selectedOrganigramma: any;
  selectedOrganigrammaInfo: any;
  paramSpostaInOrgUnitOrganigramma: boolean;

  plessi: any;
  selectedPlesso: any;
  paramCreaPlessoDefault: boolean;

  classi: any;
  selectedClasse: any;
  paramCreaClassiDefault: boolean;

  materie: any;
  selectedMateria: any;

  data: AOA;
  headerRow: any;
  headerRowIndex: number;

  plessoNameColIndex: number;
  plessoShortNameColIndex: number;
  classeNameColIndexArray: any[];
  materiaColIndex: number;
  orgUnitNameColIndex: number;
  gruppoStudentiNameColIndex: number;
  gruppoDocentiNameColIndex: number;
  gruppoStudentiClasseAutomatic: boolean;
  gruppoDocentiClasseAutomatic: boolean;
  userTypeSelected: any;

  enableShortNamePlessoField: boolean;

  paramAssociaMateria: boolean;
  paramAssociaOrgunitDefault: boolean;
  paramAssociaGruppiDefault: boolean;

  paramSplitMateriaCol: boolean;
  paramSplitCharMateriaCol: string;

  paramSplitPlessoCol: boolean;
  paramSplitCharPlessoCol: string;
  paramSplitIndexPlessoCol: number;

  paramSplitClasseCol: boolean;
  paramSplitCharClasseCol: string;
  paramSplitIndexClasseCol: number;

  // Configurazione TreeView
  configTreeView: any = {
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: true
  };

  // Espressioni regolari per match automatico dei nomi delle colonne
  private plessoNameColRegExp = /plesso|sede/i;
  private classeNameColRegExp = /classe|sezione|corso/i;
  private materiaNameColRegExp = /mat/i;
  private orgUnitNameColRegExp = /org|unit/i;
  private gruppiNameColRegExp = /group/i;
  private plessoShortNameColRegExp = /abb/i;

  private _fileimportServiceSubscription: Subscription;
  private _plessoServiceSubscription: Subscription;
  private _oganigrammaServiceSubscription: Subscription;
  private _materiaServiceSubscription: Subscription;

  // Nome, cognome, email, codice fiscale, password, cambia password primo login, ruolo, classe, plesso, organigramma?,
  // codice sidi, data nascita, anno corso, sezione, sesso, indirizzo

  constructor(
    private router: Router,
    private fileimportService: FileimportService,
    private plessoService: PlessoService,
    private materiaService: MateriaService,
    private activity: ActivityService,
    private organigrammaService: OrganigrammaService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {

    if (this.activity.isActivityInProgress("importazione")) {
      this.router.navigate(['/setup']);
      return;
    }

    if (this.fileimportService.hasImportProcessStarted()) {
      this.router.navigate(['/setup']);
      return;
    }

    if (this.fileimportService.hasFile()) {
      this.uploadedFileCompleted = true;
      this.parseFileInfo(this.fileimportService.getFile());
      this.onParseFileCompleted(this.fileimportService.getFilteredData());
    }
    else {
      this.router.navigate(['/setup/config']);
      return;
    }

    if (!this.fileimportService.hasHeaderRow()) {
      this.router.navigate(['/setup/config']);
      return;
    }

    this.headerRow = this.fileimportService.getHeaderRow();
    this.headerRowIndex = this.fileimportService.getHeaderIndex();

    this._fileimportServiceSubscription = this.fileimportService.onParseCompleted().subscribe((data: AOA) => {
      this.onParseFileCompleted(data);
    });

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.onPlessoUpdated(result)
    });

    this._oganigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.onOrganigrammaUpdated(result);
    });

    this._materiaServiceSubscription = this.materiaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.onMateriaUpdated(result)
    });

    this.plessoService.all();
    this.organigrammaService.all();
    this.materiaService.all();

    this.classeNameColIndexArray = [];
    this.addClasseCol();

    this.findAutomaticMatch();

    if (this.fileimportService.selectedOrganigramma) {
      this.selectedOrganigramma = this.fileimportService.selectedOrganigramma;

      if (this.selectedOrganigramma != null) {
        this.organigrammaService.get(this.selectedOrganigramma, (data) => {
          this.onSelectedOrganigrammaChange(data);
        })
      }
      else {
        this.onSelectedOrganigrammaChange(null);
      }
    }

    if (this.fileimportService.paramSpostaInOrgUnitOrganigramma)
      this.paramSpostaInOrgUnitOrganigramma = this.fileimportService.paramSpostaInOrgUnitOrganigramma;

    if (this.fileimportService.selectedPlesso) {
      this.selectedPlesso = this.fileimportService.selectedPlesso;
      this.onSelectedPlessoChange();
    }

    if (this.fileimportService.selectedMateria) {
      this.selectedMateria = this.fileimportService.selectedMateria;
      this.onSelectedMateriaChange();
    }

    if (this.fileimportService.userTypeSelected)
      this.userTypeSelected = this.fileimportService.userTypeSelected;

    if (this.fileimportService.paramCreaPlessoDefault)
      this.paramCreaPlessoDefault = this.fileimportService.paramCreaPlessoDefault;

    if (this.fileimportService.selectedClasse)
      this.selectedClasse = this.fileimportService.selectedClasse;

    if (this.fileimportService.paramCreaClassiDefault)
      this.paramCreaClassiDefault = this.fileimportService.paramCreaClassiDefault;

    if (this.fileimportService.plessoNameColIndex)
      this.plessoNameColIndex = this.fileimportService.plessoNameColIndex;

    if (this.fileimportService.plessoShortNameColIndex) {
      this.plessoShortNameColIndex = this.fileimportService.plessoShortNameColIndex;

      if (this.plessoShortNameColIndex !== null && this.plessoShortNameColIndex >= 0) {
        this.enableShortNamePlessoField = true;
      }
    }

    if (this.fileimportService.classeNameColIndexArray && this.fileimportService.classeNameColIndexArray.length > 0)
      this.classeNameColIndexArray = this.utilityService.cloneData(this.fileimportService.classeNameColIndexArray);

    if (this.fileimportService.paramAssociaMateria)
      this.paramAssociaMateria = this.fileimportService.paramAssociaMateria;

    if (this.fileimportService.materiaColIndex)
      this.materiaColIndex = this.fileimportService.materiaColIndex;

    if (this.fileimportService.paramAssociaOrgunitDefault)
      this.paramAssociaOrgunitDefault = this.fileimportService.paramAssociaOrgunitDefault;

    if (this.fileimportService.orgUnitNameColIndex)
      this.orgUnitNameColIndex = this.fileimportService.orgUnitNameColIndex;

    if (this.fileimportService.paramAssociaGruppiDefault)
      this.paramAssociaGruppiDefault = this.fileimportService.paramAssociaGruppiDefault;

    if (this.fileimportService.gruppoStudentiNameColIndex)
      this.gruppoStudentiNameColIndex = this.fileimportService.gruppoStudentiNameColIndex;

    if (this.fileimportService.gruppoDocentiNameColIndex)
      this.gruppoDocentiNameColIndex = this.fileimportService.gruppoDocentiNameColIndex;

    if (this.fileimportService.gruppoStudentiClasseAutomatic)
      this.gruppoStudentiClasseAutomatic = this.fileimportService.gruppoStudentiClasseAutomatic;

    if (this.fileimportService.gruppoDocentiClasseAutomatic)
      this.gruppoDocentiClasseAutomatic = this.fileimportService.gruppoDocentiClasseAutomatic;

    if (this.fileimportService.paramSplitMateriaCol)
      this.paramSplitMateriaCol = this.fileimportService.paramSplitMateriaCol;

    if (this.fileimportService.paramSplitCharMateriaCol)
      this.paramSplitCharMateriaCol = this.fileimportService.paramSplitCharMateriaCol;

    if (this.fileimportService.paramSplitPlessoCol)
      this.paramSplitPlessoCol = this.fileimportService.paramSplitPlessoCol;

    if (this.fileimportService.paramSplitCharPlessoCol)
      this.paramSplitCharPlessoCol = this.fileimportService.paramSplitCharPlessoCol;

    if (this.fileimportService.paramSplitIndexPlessoCol !== null)
      this.paramSplitIndexPlessoCol = this.fileimportService.paramSplitIndexPlessoCol + 1;

    if (this.fileimportService.paramSplitClasseCol)
      this.paramSplitClasseCol = this.fileimportService.paramSplitClasseCol;

    if (this.fileimportService.paramSplitCharClasseCol)
      this.paramSplitCharClasseCol = this.fileimportService.paramSplitCharClasseCol;

    if (this.fileimportService.paramSplitIndexClasseCol !== null)
      this.paramSplitIndexClasseCol = this.fileimportService.paramSplitIndexClasseCol + 1;

  }

  ngOnDestroy(): void {

    if (this._fileimportServiceSubscription)
      this._fileimportServiceSubscription.unsubscribe();

    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();

    if (this._materiaServiceSubscription)
      this._materiaServiceSubscription.unsubscribe();

    if (this._oganigrammaServiceSubscription)
      this._oganigrammaServiceSubscription.unsubscribe();
  }

  ngAfterViewInit() {

    if (this.selectedOrganigramma != null) {
      setTimeout(() => {
        this.tabOrganigramma.active = true;
      }, 100);
    }
  }

  parseFileInfo(file: File) {
    this.fileName = file.name;
    this.fileSize = Math.round(file.size / 1024) + " Kbyte";
    this.fileLastModified = file.lastModified;
  }

  findAutomaticMatch() {

    // Nome Plesso
    for (let i = 0; i < this.headerRow.length; i++) {
      if (this.plessoNameColRegExp.test(this.headerRow[i])) {
        this.plessoNameColIndex = i;
        break;
      }
    }

    // Short Nome Plesso
    for (let i = 0; i < this.headerRow.length; i++) {
      if (this.plessoShortNameColRegExp.test(this.headerRow[i])) {
        this.plessoShortNameColIndex = i;
        this.enableShortNamePlessoField = true;
        break;
      }
    }

    // Nome Classe
    for (let i = 0; i < this.headerRow.length; i++) {
      if (this.classeNameColRegExp.test(this.headerRow[i])) {
        this.classeNameColIndexArray[0].classeNameColIndex = i;
        break;
      }
    }

    // Materia
    for (let i = 0; i < this.headerRow.length; i++) {
      if (this.materiaNameColRegExp.test(this.headerRow[i])) {
        this.materiaColIndex = i;
        break;
      }
    }

    // OrgUnit
    for (let i = 0; i < this.headerRow.length; i++) {
      if (this.orgUnitNameColRegExp.test(this.headerRow[i])) {
        this.orgUnitNameColIndex = i;
        break;
      }
    }

    // Gruppi
    for (let i = 0; i < this.headerRow.length; i++) {
      if (this.gruppiNameColRegExp.test(this.headerRow[i])) {
        this.gruppoStudentiNameColIndex = i;
        this.gruppoDocentiNameColIndex = i;
        break;
      }
    }
  }

  onSubmit(form: NgForm) {
  }

  onParseFileCompleted(data: AOA) {

    this.data = data;

    if (!this.data && this.fileimportService.hasFile()) {
      this.fileimportService.parseFile();
    }
  }

  onPlessoUpdated(result) {
    this.plessi = result;
  }

  onSelectedPlessoChange() {

    this.resetTabOrganigramma();

    this.plessoService.get(this.selectedPlesso, (result) => {
      this.classi = result.classi;
    });
  }

  onOrganigrammaUpdated(result) {
    this.organigramma = result;
    this.organigrammiTreeView = this.utilityService.convertListToTree(result, "id", "parent_id", OrderTypeList.Ascending, "name", true, "name", "id");
  }

  onMateriaUpdated(result) {
    this.materie = result;
  }

  onSelectedMateriaChange() {

  }

  onSelectedOrganigrammaChange(event) {

    this.resetTabPlessoClasse();

    this.selectedOrganigrammaInfo = event;
  }

  onSelectParamSpostaInOrgUnitOrganigramma() {

  }

  onSelectParamCreaPlessoDefault() {
    this.selectedPlesso = null;
    this.selectedClasse = null;

    this.resetTabOrganigramma();

    if (this.paramCreaPlessoDefault) {
      this.paramCreaClassiDefault = true;
    }
  }

  onSelectParamCreaClassiDefault() {
    this.selectedClasse = null;
  }

  addClasseCol(index = 0) {

    this.classeNameColIndexArray.splice(index + 1, 0, {
      classeNameColIndex: null
    });

    // Nome Sezione solo nella prima aggiunta, le altre le ignoro
    if (this.classeNameColIndexArray.length == 2) {
      for (let i = 0; i < this.headerRow.length; i++) {
        if (this.classeNameColRegExp.test(this.headerRow[i]) && i != this.classeNameColIndexArray[0].classeNameColIndex) {
          this.classeNameColIndexArray[1].classeNameColIndex = i;
          break;
        }
      }
    }

  }

  onSelectAssociaMateria() {
    this.selectedMateria = null;
  }

  onSelectAssociaUnitaOrganizzativa() {

  }

  onSelectAssociaGruppi() {

  }

  removeClasseCol(index) {
    this.classeNameColIndexArray.splice(index, 1);
  }


  removedFile() {
    this.fileimportService.deleteFile();
    this.router.navigate(['/setup/config']);
  }

  resetTabPlessoClasse() {

    this.selectedPlesso = null;
    this.paramCreaPlessoDefault = null;
    this.selectedClasse = null;
    this.paramCreaClassiDefault = null;
    this.plessoNameColIndex = null;
    this.plessoShortNameColIndex = null;
    this.enableShortNamePlessoField = false;
    this.classeNameColIndexArray = [];
    this.paramAssociaOrgunitDefault = null;
    this.orgUnitNameColIndex = null;
    this.paramAssociaGruppiDefault = null;
    this.gruppoStudentiNameColIndex = null;
    this.gruppoDocentiNameColIndex = null;
    this.gruppoStudentiClasseAutomatic = null;
    this.gruppoDocentiClasseAutomatic = null;

    this.paramSplitMateriaCol = null;
    this.paramSplitPlessoCol = null;
    this.paramSplitClasseCol = null;
  }

  resetTabOrganigramma() {

    this.selectedOrganigramma = null;
    this.paramSpostaInOrgUnitOrganigramma = null;
    this.selectedOrganigrammaInfo = null;
  }

  next() {

    this.fileimportService.saveStrutturaColsPreference(
      this.selectedOrganigramma,
      this.paramSpostaInOrgUnitOrganigramma,
      this.selectedPlesso,
      this.paramCreaPlessoDefault,
      this.selectedClasse,
      this.paramCreaClassiDefault,
      this.plessoNameColIndex,
      this.plessoShortNameColIndex,
      this.classeNameColIndexArray,
      this.selectedMateria,
      this.paramAssociaMateria,
      this.materiaColIndex,
      this.paramAssociaOrgunitDefault,
      this.orgUnitNameColIndex,
      this.paramAssociaGruppiDefault,
      this.gruppoStudentiNameColIndex,
      this.gruppoDocentiNameColIndex,
      this.gruppoStudentiClasseAutomatic,
      this.gruppoDocentiClasseAutomatic
    );

    this.fileimportService.saveMateriaSplitColParameters(
      this.paramSplitMateriaCol,
      this.paramSplitCharMateriaCol
    );

    this.fileimportService.savePlessoSplitColParameters(
      this.paramSplitPlessoCol,
      this.paramSplitCharPlessoCol,
      this.paramSplitIndexPlessoCol - 1
    );

    this.fileimportService.saveClasseSplitColParameters(
      this.paramSplitClasseCol,
      this.paramSplitCharClasseCol,
      this.paramSplitIndexClasseCol - 1
    );

    this.router.navigate(['/setup/preview/struttura']);
  }

  back() {
    this.router.navigate(['/setup/config']);
  }

}
