import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleClassroomService } from 'src/app/services/google/google-classroom.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-classroom-form',
  templateUrl: './classroom-form.component.html',
  styleUrls: ['./classroom-form.component.scss']
})
export class ClassroomFormComponent implements OnInit {
  @Input() id: any;
  courses: any[];
  course: any;
  docenti: any[] = [];
  studenti: any[] = [];
  data: any;

  _loadingData = {
    course: false,
    students: false,
    teachers: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  get dataIsChanged(): boolean {
    return Object.values(this.dataChange).some(x => x == true);
  }

  dataChange = {
    name: false,
    description: false,
    room: false,
    section: false,
    ownerId: false,
    students: false,
    teachers: false
  };

  error = null;
  formLoading: boolean;

  @ViewChild('myForm') myForm: NgForm;
  private _googleServiceClassroomSubscription: Subscription;
  private _userServiceSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;

  constructor(
    private router: Router,
    private googleDirectoryService: GoogleDirectoryService,
    private utilityService: UtilityService,
    private userService: UserService,
    private classroomService: GoogleClassroomService) { }

  ngOnInit(): void {
    this.course = {};
    this._googleServiceClassroomSubscription = this.classroomService.getSubjectToUpdateCoursesObservable().subscribe(
      (result) => {
        this._loadingData.course = false;

        if (result)
          this.courses = result;

        this.init();
      });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.init();
      }
    });

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe(
      (result) => {
        if (result) {
          this.userService.all();
        }
      });

    this.init();
  }

  init() {
    this.data = {};

    this.dataChange = {
      name: false,
      description: false,
      room: false,
      section: false,
      ownerId: false,
      students: false,
      teachers: false
    };

    if (this.id && this.id > 0) {
      if (this.courses) {
        this.course = this.courses.find(x => x.id == this.id);
        this.data.courseId = this.id;
        this.data.ownerId = this.course.ownerId;
        this.data.name = this.course.name;
        this.data.section = this.course.section;
        this.data.description = this.course.description;
        this.data.room = this.course.room;

        this._loadingData.students = true;
        this.data.students = [];
        this.course.students = [];
        this.data.studentsToRemove = [];
        this.classroomService.courseStudentList(this.course.id, (result) => {
          if (result)
            this.course.students = result;

          this.studentsUpdated();
        }, () => {
          this.genericError("students");
        });

        this._loadingData.teachers = true;
        this.data.teachers = [];
        this.course.teachers = [];
        this.data.teachersToRemove = [];
        this.classroomService.courseTeacherList(this.course.id, (result) => {
          if (result)
            this.course.teachers = result;

          this.teachersUpdated();
        }, () => {
          this.genericError("teachers");
        });

      } else {
        this.classroomService.coursesList();
      }
    } else {
      this.genericError("course");
    }
  }

  genericError(typeError) {
    this.error = typeError;
    this._loadingData[typeError] = false;
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  unsubscibeAll() {
    if (this._googleServiceClassroomSubscription)
      this._googleServiceClassroomSubscription.unsubscribe();
    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  onSubmit() {
    if (this.myForm.valid) {
      this.formLoading = true;
      let courseToUpdate = this.utilityService.cloneData(this.data);
      delete courseToUpdate.students;
      delete courseToUpdate.teachers;

      if (!this.dataChange.ownerId)
        delete courseToUpdate.ownerId;

      // Update
      this.classroomService.update(this.id, courseToUpdate, true, (result) => {
        this.formLoading = false;
        this.navigateAfterSubmit();
      }, (error) => {
        this.genericError("course");
        this.init();
      })
    }
  }

  editDataChange() {
    // Controllo quali dati sono cambiati
    Object.keys(this.course).forEach(key => {
      switch (key) {
        case 'name':
        case 'section':
        case 'room':
        case 'description':
          this.dataChange[key] = this.data[key] !== this.course[key] ? true : false;
          break;
        default:
          break;
      }
    });
  }

  changeData() {
    this.editDataChange();
  }

  navigateAfterSubmit() {
    if (this.id && this.id.length > 0) {
      this.router.navigate([`/classroom/${this.id}`]);
    }
    else {
      // this.pageService.back();
      this.router.navigate(["/dashboard"]);
    }
  }

  studentsUpdated() {
    this._loadingData.students = true;
    this.data.students = [];
    this.parseUsersResult(this.course.students, "students");
  }

  teachersUpdated() {
    this._loadingData.teachers = true;
    this.data.teachers = [];
    this.parseUsersResult(this.course.teachers, "teachers");
  }

  parseUsersResult(result, type) {

    if (result && result.length > 0) {
      result.forEach(element => {

        let user = this.getInfoUser(element);

        // in caso di utenti cancellati non devo farli comparire nella tabella
        if (!user.email) {
          return;
        }

        this.data[type].push(user);

      });
    }
    this._loadingData[type] = false;
  }

  getInfoUser(infoUserCourse) {
    let resUser = {
      googleId: infoUserCourse.userId,
      familyName: null,
      givenName: null,
      fullname: null,
      email: null,
      is_google_user: false,
      is_geniusuite_user: false
    };
    let user = this.userService.getByGoogleId(infoUserCourse.userId);

    if (user) {
      // Sincronizzato con Geniusuite
      resUser.familyName = user.user.familyName;
      resUser.givenName = user.user.givenName;

      resUser.fullname = user.user.familyName + " " + user.user.givenName;
      resUser.email = user.user.email;
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = true;

      if (user.google_user) {
        resUser.googleId = infoUserCourse.userId;

        if (user.google_user.name) {
          resUser.familyName = user.google_user.name?.familyName;
          resUser.givenName = user.google_user.name?.givenName;
          resUser.fullname = user.google_user.name?.familyName + " " + user.google_user.name?.givenName;
        }

        resUser.email = user.google_user.primaryEmail
        resUser.is_google_user = true;
      }
    } else if (infoUserCourse?.profile && infoUserCourse.profile.emailAddress) {

      // Non sincronizzato con Geniusuite ma ha già i dati
      resUser.familyName = infoUserCourse.profile.name?.familyName;
      resUser.givenName = infoUserCourse.profile.name?.givenName;
      resUser.fullname = resUser.familyName + " " + resUser.givenName;
      resUser.email = infoUserCourse.profile.emailAddress;
      resUser.is_google_user = true;
      resUser.is_geniusuite_user = false;

    } else {

      let googleUser = this.googleDirectoryService.getUser(infoUserCourse.userId);

      if (googleUser) {
        // Non sincronizzato con Geniusuite ma non ha già i dati caricati
        resUser.familyName = "Utente cancellato";
        resUser.givenName = "";
        resUser.fullname = "Utente cancellato";

        if (googleUser.name) {
          resUser.familyName = googleUser.name?.familyName;
          resUser.givenName = googleUser.name?.givenName;
          resUser.fullname = googleUser.name?.familyName + " " + googleUser.name?.givenName;
        }

        resUser.email = googleUser.primaryEmail;
        resUser.is_google_user = true;
        resUser.is_geniusuite_user = false;
      } else {
        resUser.familyName = "Utente cancellato";
        resUser.givenName = "";
        resUser.fullname = "Utente cancellato";
        resUser.email = null;
        resUser.is_google_user = false;
        resUser.is_geniusuite_user = false;
      }
    }

    return resUser;
  }

  removeUserFromClassroom(user, type) {
    let googleId = user.googleId;
    this.data[type + "ToRemove"].push(googleId);
    let index = this.data[type].findIndex(x => x.googleId == googleId);
    this.data[type][index] = null;
    this.data[type] = this.data[type].filter((el) => { return el != null });
    this.dataChange[type] = true;
  }

  setUserAsOwner(user) {
    this.data.ownerId = user.googleId;
    this.dataChange.ownerId = true;
  }
}
