import { Injectable } from '@angular/core';
import { Location } from '@angular/common'
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  private history: string[] = [];

  private _subjectPageTitleToUpdate: Subject<any> = new Subject<any>();

  private _subjectPageError: Subject<any> = new Subject<any>();

  constructor(private router: Router, private location: Location) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
        // faccio lo scroll to top
        window.scrollTo(0, 0);
      }
    })
  }

  public getSubjectPageTitleToUpdateObservable(): Observable<any> {
    return this._subjectPageTitleToUpdate.asObservable();
  }

  public getSubjectPageErrorObservable(): Observable<any> {
    return this._subjectPageError.asObservable();
  }

  public updatePageTitle(title) {
    this._subjectPageTitleToUpdate.next(title);
  }

  public showPageError(msg) {
    this._subjectPageError.next(msg);
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

}
