import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActivityService } from 'src/app/services/activity.service';
import { FileimportService } from 'src/app/services/fileimport.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-setup-wizard-start',
  templateUrl: './setup-wizard-start.component.html',
  styleUrls: ['./setup-wizard-start.component.scss']
})
export class SetupWizardStartComponent implements OnInit {

  activity_in_progress: boolean = false;

  syncIntegrityErrors: any;
  checkInProgress: boolean = true;

  private _syncdataServiceSubscription: Subscription;

  constructor(
    private router: Router,
    private fileimportService: FileimportService,
    private activity: ActivityService,
    private syncdataService: SyncdataService
  ) { }

  ngOnInit() {

    this._syncdataServiceSubscription = this.syncdataService.getSubjectToUpdateObservableSyncIntegrity().subscribe((errors) => {
      this.syncIntegrityErrors = errors;
      this.checkInProgress = false;
    });

    this.syncdataService.updateSystemDataAndCheckIntegrity();

    if (this.activity.isActivityInProgress("importazione")) {

      // Se c'è ancora il file caricato, significa che non ho resettato ancora il componente e quindi mostro la schermata per scaricare il file
      if (this.fileimportService.hasFile()) {
        this.router.navigate(['/setup/finish']);
      }
      else {
        this.activity_in_progress = true;
      }
      return;
    }

    // Se era già partito il processo di importazione con questo file e entro nuovamente nella sezione importazione guidata cancello e resetto tutto
    if (this.fileimportService.hasImportProcessStarted()) {

      // Se c'è ancora il file caricato, significa che non ho resettato ancora il componente
      if (this.fileimportService.hasFile()) {
        this.router.navigate(['/setup/finish']);
      }
      else {
        this.fileimportService.deleteFile();
      }

      return;
    }

    if (this.fileimportService.hasFile()) {
      this.next();
    }
  }

  ngOnDestroy() {

    if (this._syncdataServiceSubscription) {
      this._syncdataServiceSubscription.unsubscribe();
    }
  }

  next() {
    this.router.navigate(['/setup/config']);
  }

  showSidebar() {
    this.activity.showSidebar = true;
  }

}
