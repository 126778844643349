import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { CacheService, CacheType } from '../cache.service';
import { GoogleDirectoryService } from '../google/google-directory.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class OrganigrammaService {
  data: any;
  indexSelected: any;

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  constructor(private backend: BackendService, private cacheService: CacheService, private toast: ToastService, private googleDirectoryService: GoogleDirectoryService, private userService: UserService, private utility: UtilityService) { }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  /**
   *
   * @param userType Restituisce l'organigramma di default pe un tipo di utente
   */
  public getDefaultOrganigrammaForUserType(relation_info: string, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    if (typeof relation_info != "string" || relation_info.length <= 0 || !this.data) {
      error_callback(null);
      return;
    }

    for (let organigramma of this.data) {
      if (organigramma.relation_info == relation_info && organigramma.parent_id == null) {
        return this.get(organigramma.id, success_callback, error_callback);
      }
    }

    error_callback(null);
    return;
  }

  /**
   * Recupero Organigrammi
   */
  all(
    success_callback = (data: any) => { },
    error_callback = (error: any) => { }
  ) {

    if (this.cacheService.isCacheValid(CacheType.Organigramma)) {
      let dataCloned = this.utility.cloneData(this.data);
      this._subjectToUpdate.next(dataCloned);
      success_callback(dataCloned);
      return;
    }

    this.backend.get(`organigramma/all`).subscribe(
      (response) => {
        this.data = response;
        this.cacheService.updateCacheStatus(CacheType.Organigramma, true);
        let dataCloned = this.utility.cloneData(this.data);
        this._subjectToUpdate.next(dataCloned);
        success_callback(dataCloned);
      },
      (error) => {
        this._subjectToUpdate.next(null);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Create Organigramma
   * @param organigrammaInfo Informazioni dell'Organigramma
   * @param toastNotification Notifica tramite toast
   */
  create(
    organigrammaInfo,
    toastNotification = false,
    success_callback = (data: any) => { },
    error_callback = (error: any) => { }
  ) {
    this.backend.post('organigramma', organigrammaInfo).subscribe(
      (result) => {
        if (toastNotification) this.toast.success('Organigramma Aggiunto');
        success_callback(result);
      },
      (error) => {
        error_callback(error);
      }
    );
  }

  /**
   * Update Organigramma
   * @param organigrammaId Id dell'Organigramma
   * @param organigrammaInfo Informazioni dell'Organigramma
   * @param toastNotification Notifica tramite toast
   */
  update(
    organigrammaId,
    organigrammaInfo,
    toastNotification = false,
    success_callback = (data: any) => { },
    error_callback = (error: any) => { }
  ) {
    this.backend
      .put(`organigramma/${organigrammaId}`, organigrammaInfo)
      .subscribe(
        (result) => {
          if (toastNotification) this.toast.success('Organigramma Modificato');
          success_callback(result);
        },
        (error) => {
          error_callback(error);
        }
      );
  }

  /**
      * Delete organigramma
      * @param organigrammaId Id del organigramma
      * @param deleteInfo Informazioni di Delete del organigramma
      * @param toastNotification Notifica tramite toast
      */
  delete(organigrammaId, deleteInfo, toastNotification = false, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.backend.delete(`organigramma/${organigrammaId}/delete`, deleteInfo).subscribe((result) => {

      if (toastNotification)
        this.toast.success('Organigramma Eliminato');

      success_callback(result);
    }, (error) => {
      this._subjectToUpdate.next(null);
      error_callback(error);
    })
  }

  /**
   * Recupero Organigramma
   * @param organigrammaId Id dell'Organigramma
   */
  get(organigrammaId, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    if (this.data) {
      this.indexSelected = organigrammaId;
      let organigramma = this.data.find((x) => x.id == organigrammaId)

      if (!organigramma) {
        error_callback(null);
        return null;
      }

      if (organigramma.google_org_unit) {
        // TODO Ipotetico controllo se esiste ancora l'orgunit su Google
        let orgUnit = this.googleDirectoryService.getOrgUnit(organigramma.google_org_unit.orgUnitId);

        if (orgUnit) {
          organigramma.google_org_unit.not_found = false;
          organigramma.google_org_unit = {
            ...orgUnit,
            ...organigramma.google_org_unit
          }
        } else {
          organigramma.google_org_unit.not_found = true;
        }
      }

      if (organigramma.google_group && organigramma.google_group.length > 0) {
        for (let index = 0; index < organigramma.google_group.length; index++) {
          let element = organigramma.google_group[index];
          let group = this.googleDirectoryService.getGroupByGroupKey(element.groupKey);
          if (group) {
            organigramma.google_group[index] = {
              ...group,
              ...element
            };
          } else {
            organigramma.google_group[index].not_found = true;
          }
        }
      }

      if (organigramma.children) {
        for (let index = 0; index < organigramma.children.length; index++) {
          let element = organigramma.children[index];
          if (element.id != organigrammaId) { // se per caso è stato selezionato stesso il padre come figlio, prevengo chiamate infinite
            let child = this.get(element.id);
            if (child) {
              organigramma.children[index] = { ...child, ...element };
            }
          }
        }
      }

      if (organigramma) {
        let dataCloned = this.utility.cloneData(organigramma);
        success_callback(dataCloned);
        return dataCloned;
      }
      else {
        error_callback(null);
        return null;
      }
    } else {
      error_callback(null);
      return null;
    }
  }

  getWithUsers(organigrammaId, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    this.get(organigrammaId, (data: any) => {

      let organigramma = data;

      organigramma.users = this.userService.getByOrganigramma(organigrammaId);

      success_callback(organigramma);
    }, (error) => {
      error_callback(null);
    })
  }

  getRoot(organigrammaId) {

    let organigramma = this.get(organigrammaId);

    while (organigramma.parent_id) {
      organigramma = this.get(organigramma.parent_id);
    }

    return organigramma;
  }
}
