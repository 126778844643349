<app-card-loading *ngIf="loading">
</app-card-loading>

<app-card-loading-error *ngIf="!(loading) && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card" *ngIf="!(loading) && !error">
  <!-- Header -->
  <div class="card-header">
    <h5 class="card-header-title">
      <i class="fad fa-users-class nav-icon green mr-1"></i> Classroom: {{course.name}}<br>
      <small *ngIf="course?.descriptionHeading">{{ course?.descriptionHeading }}</small>
      <br *ngIf="course?.descriptionHeading && course?.description">
      <small *ngIf="course?.description">{{ course?.description }}</small>
    </h5>
    <div class="d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="showInfo = !showInfo">
        <i class="fas fa-info mr-2"></i>{{ 'BTN.Informazioni' | translate }}
      </button>
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" [routerLink]="['/classroom/'+ id + '/edit']" [disabled]="course?.courseState == 'DECLINED' || course?.courseState == 'SUSPENDED'">
        <i class="fas fa-edit mr-2"></i>{{ 'BTN.Modifica' | translate }}
      </button>
      <!-- <button type="button" class="btn btn-outline-primary btn-sm mr-2" [routerLink]="['/classroom/'+ id + '/edit']">
        <i class="fas fa-edit mr-2"></i>Modifica Propietario
      </button> -->
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="aggiornaDati()">
        <i class="fas fa-sync-alt mr-2"></i>{{ 'BTN.aggiorna' | translate }}
      </button>
    </div>
    <!-- <button type="button" class="btn btn-outline-danger btn-sm"
        [routerLink]="['/classroom/'+ classe.plesso_id +'/classe/'+ classe.id +'/delete']">
        <i class="far fa-trash mr-2"></i>{{ 'BTN.Cancella' | translate }}
      </button> -->
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div *ngIf="showInfo" class="card-body">

    <div class="row mb-3">
      <div class="col-4">
        <small class="card-subtitle">Proprietario</small>
        <a *ngIf="ownerCourse; else ownerCourseNotFound" (click)="goToUserInfo()">
          <div>
            <span class="d-block h5 text-hover-primary mb-0'">
              {{ ownerCourse.fullname || 'Nessun Proprietario'}}
            </span>
            <span class="d-block text-body font-size-sm">
              {{ ownerCourse.email || 'Nessun Proprietario'}}
            </span>
          </div>
        </a>

        <ng-template #ownerCourseNotFound>
          <p>Nessun Proprietario</p>
        </ng-template>
      </div>
      <div class="col-4">
        <small class="card-subtitle">Descrizione</small>
        <p *ngIf="course?.descriptionHeading">{{ course?.descriptionHeading }}</p>
        <p *ngIf="course?.description ">{{ course?.description }}</p>
        <p *ngIf="!course?.description && !course?.descriptionHeading">Nessuna descrizione</p>
      </div>
      <div class="col-4">
        <small class="card-subtitle">Link</small>
        <p>{{ course?.alternateLink || 'Nessun link'}}</p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <small class="card-subtitle">Sezione</small>
        <p>{{ course?.section || 'Nessuna sezione'}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Stanza</small>
        <p>{{ course?.room || 'Nessuna stanza'}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Creato il</small>
        <p>{{ course?.creationTime | date:'medium' || "Errore data" }}</p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-4">
        <small class="card-subtitle">Email gruppo corso</small>
        <p>{{ course?.courseGroupEmail || 'Nessuna email'}}</p>
      </div>
      <div class="col-4">
        <small class="card-subtitle">Email gruppo docenti </small>
        <p>{{ course?.teacherGroupEmail || 'Nessuna email'}}</p>
      </div>
      <div class="col-4">
        <small class="card-subtitle">Aggiornato il</small>
        <p>{{ course?.updateTime | date:'medium' || "Errore data" }}</p>
      </div>
    </div>

  </div>
  <!-- End Body -->

  <!-- Footer -->
  <div class="card-footer text-center">
    <div class="row">

      <div class="col">
        <span class="h4">{{ 'STR.course_state_'+course?.courseState.toLowerCase() | translate }}</span>
        <span class="d-block font-size-sm">Stato</span>
      </div>

      <div class="col column-divider">
        <span class="h4">{{ course?.section || '-'}}</span>
        <span class="d-block font-size-sm">Sezione</span>
      </div>

      <div class="col column-divider">
        <span class="h4">{{ course?.room || '-'}}</span>
        <span class="d-block font-size-sm">Stanza</span>
      </div>
    </div>

    <div class="row" *ngIf="course?.courseState">
      <div class="col-12">
        <div class="alert alert-soft-info mt-3 mb-0">{{ 'STR.course_'+course?.courseState.toLowerCase()+'_subtitle' | translate }}</div>
      </div>
    </div>
  </div>
  <!-- End Footer -->
</div>
