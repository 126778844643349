import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Router } from "@angular/router";

// Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
registerLocaleData(localeIt, 'it', localeItExtra);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?v=' + environment.version);
}

// Sentry
import * as Sentry from "@sentry/angular";

// Services & Guards
import { ConfigService } from './services/config.service';
import { AuthGuard } from './guards/auth.guard';
import { BackendService } from './services/backend.service';
import { AccountService } from './services/account.service';
import { ActivityService } from './services/activity.service';

// Service Operations
import { UpdatePasswordService } from './services/operations/update-password.service';

// Google
import { GoogleApiModule, GoogleApiService, GoogleAuthService, NgGapiClientConfig, NG_GAPI_CONFIG, GoogleApiConfig } from "ng-gapi";

let gapiClientConfig: NgGapiClientConfig = {
  client_id: "911869894626-btclk9v4uuovro7gg2fcrm606qoetdcd.apps.googleusercontent.com",
  discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest", "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
  scope: [].join(" ")
};

// ngx-bootstrap
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

// primeng
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TooltipModule } from 'primeng/tooltip';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { SidebarModule } from 'primeng/sidebar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TreeModule } from 'primeng/tree';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { TagModule } from 'primeng/tag';
import { PanelModule } from 'primeng/panel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DataViewModule } from 'primeng/dataview';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';

// Pipe
import { SplitPipe } from './pipes/split.pipe';

// Component
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SetupWizardStartComponent } from './pages/setup-wizard/setup-wizard-start/setup-wizard-start.component';
import { PageHeaderComponent } from './layout/page-header/page-header.component';
import { SetupWizardConfigComponent } from './pages/setup-wizard/setup-wizard-config/setup-wizard-config.component';
import { SetupWizardConfigColUsersComponent } from './pages/setup-wizard/setup-wizard-config-col-users/setup-wizard-config-col-users.component';
import { GsuiteParametersComponent } from './pages/settings/gsuite-parameters/gsuite-parameters.component';
import { GoogleDirectoryService } from './services/google/google-directory.service';
import { CardLoadingComponent } from './utility/card-loading/card-loading.component';
import { OrganigrammaFormComponent } from './forms/organigramma/organigramma-form/organigramma-form.component';
import { CardLoadingErrorComponent } from './utility/card-loading-error/card-loading-error.component';
import { PlessoFormComponent } from './forms/plesso/plesso-form/plesso-form.component';
import { ForbiddenValidatorDirective } from './forms/helpers/name.directive';
import { ParamsStringValidator } from './forms/helpers/params.directive';
import { ActivitySidebarComponent } from './layout/activity-sidebar/activity-sidebar.component';
import { CardLoadingOverlayComponent } from './utility/card-loading-overlay/card-loading-overlay.component';
import { ToastNotificationComponent } from './utility/toast-notification/toast-notification.component';
import { CustomDropdownTreeViewComponent } from './utility/dropdown-treeview/custom-dropdown-treeview.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { TestThemeElementComponent } from './utility/test-theme-element/test-theme-element.component';
import { PlessoProfileComponent } from './pages/plesso-profile/plesso-profile.component';
import { ClasseProfileComponent } from './pages/classe-profile/classe-profile.component';
import { OrganigrammaProfileComponent } from './pages/organigramma-profile/organigramma-profile.component';
import { AccountPageComponent } from './pages/account-page/account-page.component';
import { GeniusuiteSettingsComponent } from './pages/settings/geniusuite-settings/geniusuite-settings.component';
import { HeaderSearchComponent } from './layout/header-search/header-search.component';
import { HeaderAccountComponent } from './layout/header-account/header-account.component';
import { UserProfileInfoCardComponent } from './cards/user/user-profile-info-card/user-profile-info-card.component';
import { UserProfileDatatableCardComponent } from './cards/user/user-profile-datatable-card/user-profile-datatable-card.component';
import { PlessoProfileInfoCardComponent } from './cards/plesso/plesso-profile-info-card/plesso-profile-info-card.component';
import { PlessoProfileDatatableCardComponent } from './cards/plesso/plesso-profile-datatable-card/plesso-profile-datatable-card.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { Error404Component } from './pages/system/error-404/error-404.component';
import { OrganigrammaProfileDatatableCardComponent } from './cards/organigramma/organigramma-profile-datatable-card/organigramma-profile-datatable-card.component';
import { OrganigrammaProfileInfoCardComponent } from './cards/organigramma/organigramma-profile-info-card/organigramma-profile-info-card.component';
import { EnteProfileInfoCardComponent } from './cards/ente/ente-profile-info-card/ente-profile-info-card.component';
import { EnteProfileDatatableCardComponent } from './cards/ente/ente-profile-datatable-card/ente-profile-datatable-card.component';
import { EnteProfileComponent } from './pages/ente-profile/ente-profile.component';
import { ClasseFormComponent } from './forms/classe/classe-form/classe-form.component';
import { ClasseProfileInfoCardComponent } from './cards/classe/classe-profile-info-card/classe-profile-info-card.component';
import { ClasseProfileDatatableCardComponent } from './cards/classe/classe-profile-datatable-card/classe-profile-datatable-card.component';
import { RelationService } from './services/general/relation.service';
import { PageService } from './services/page.service';
import { SyncdataService } from './services/syncdata.service';
import { CacheService } from './services/cache.service';
import { GoogleCheckValidatorDirective } from './forms/helpers/google.directive';
import { FileimportService } from './services/fileimport.service';
import { SetupWizardConfigColPlessoClasseComponent } from './pages/setup-wizard/setup-wizard-config-col-plessoclasse/setup-wizard-config-col-plessoclasse.component';
import { PlessoFormDeleteComponent } from './forms/plesso/plesso-form-delete/plesso-form-delete.component';
import { SetupWizardPreviewStrutturaComponent } from './pages/setup-wizard/setup-wizard-preview-struttura/setup-wizard-preview-struttura.component';
import { IconSetEducationComponent } from './utility/icon-set-education/icon-set-education.component';
import { BootstrapPageComponent } from './pages/bootstrap-page/bootstrap-page.component';
import { BackButtonDirective } from './helpers/back-button.directive';
import { ClasseFormDeleteComponent } from './forms/classe/classe-form-delete/classe-form-delete.component';
import { SettingsService } from './services/settings.service';
import { SwitchComponent } from './forms/form-elements/switch/switch.component';
import { GoogleGroupSettingsComponent } from './forms/form-elements/google-group-settings/google-group-settings.component';
import { SwitchButtonComponent } from './forms/form-elements/switch-button/switch-button.component';
import { GoogleMeetStatComponent } from './cards/statistics/google-meet-stat/google-meet-stat.component';
import { GoogleClassroomStatComponent } from './cards/statistics/google-classroom-stat/google-classroom-stat.component';
import { GoogleDriveStatComponent } from './cards/statistics/google-drive-stat/google-drive-stat.component';
import { GoogleGmailStatComponent } from './cards/statistics/google-gmail-stat/google-gmail-stat.component';
import { ResetPasswordWidgetComponent } from './cards/widgets/reset-password-widget/reset-password-widget.component';
import { TodoWidgetComponent } from './cards/widgets/todo-widget/todo-widget.component';
import { SelectComponent } from './forms/form-elements/select/select.component';
import { UpdatePasswordComponent } from './pages/operations/update-password/update-password.component';
import { MaterieFormComponent } from './forms/materie/materie-form/materie-form.component';
import { MateriaProfileInfoCardComponent } from './cards/materie/materia-profile-info-card/materia-profile-info-card.component';
import { MateriaProfileDatatableCardComponent } from './cards/materie/materia-profile-datatable-card/materia-profile-datatable-card.component';
import { MateriaProfileComponent } from './pages/materia-profile/materia-profile.component';
import { GoogleClassroomStatPageComponent } from './pages/statistics/google-classroom-stat-page/google-classroom-stat-page.component';
import { OrganigrammaProfileTreeCardComponent } from './cards/organigramma/organigramma-profile-tree-card/organigramma-profile-tree-card.component';
import { PlessoProfileTreeCardComponent } from './cards/plesso/plesso-profile-tree-card/plesso-profile-tree-card.component';
import { SetupWizardPreviewUsersComponent } from './pages/setup-wizard/setup-wizard-preview-users/setup-wizard-preview-users.component';
import { OrganigrammaFormDeleteComponent } from './forms/organigramma/organigramma-form-delete/organigramma-form-delete.component';
import { GestioneScolasticaUserComponent } from './components/gestione-scolastica-user/gestione-scolastica-user.component';
import { SetupWizardStepsComponent } from './components/setup-wizard-steps/setup-wizard-steps.component';
import { SetupWizardEndComponent } from './pages/setup-wizard/setup-wizard-end/setup-wizard-end.component';
import { UserFormDeleteComponent } from './forms/user/user-form-delete/user-form-delete.component';
import { ClassroomProfileComponent } from './pages/classroom-profile/classroom-profile.component';
import { MoveUsersComponent } from './pages/operations/move-users/move-users.component';
import { GestioneCattedreComponent } from './pages/operations/gestione-cattedre/gestione-cattedre.component';
import { RequestChangePasswordService } from './services/general/request-change-password.service';
import { RequestChangePasswordPageComponent } from './pages/request-change-password-page/request-change-password-page.component';
import { ClipboardModule } from 'ngx-clipboard';
import { PassaggioAnnoWizardStartComponent } from './pages/passaggio-anno-wizard/passaggio-anno-wizard-start/passaggio-anno-wizard-start.component';
import { PassaggioAnnoWizardStepsComponent } from './components/passaggio-anno-wizard-steps/passaggio-anno-wizard-steps.component';
import { PassaggioAnnoWizardConfigComponent } from './pages/passaggio-anno-wizard/passaggio-anno-wizard-config/passaggio-anno-wizard-config.component';
import { PassaggioAnnoWizardStrutturaComponent } from './pages/passaggio-anno-wizard/passaggio-anno-wizard-struttura/passaggio-anno-wizard-struttura.component';
import { PassaggioAnnoWizardEndComponent } from './pages/passaggio-anno-wizard/passaggio-anno-wizard-end/passaggio-anno-wizard-end.component';
import { DeleteUsersComponent } from './pages/operations/delete-users/delete-users.component';
import { RipristinoUsersComponent } from './pages/operations/ripristino-users/ripristino-users.component';
import { UpdateSettingGroupsComponent } from './pages/operations/groups/update-setting-groups/update-setting-groups.component';
import { PassaggioAnnoWizardWaitComponent } from './pages/passaggio-anno-wizard/passaggio-anno-wizard-wait/passaggio-anno-wizard-wait.component';
import { PusherService } from './services/pusher.service';
import { CardDatatableNgComponent } from './utility/card-datatable-ng/card-datatable-ng.component';
import { DatatableNgComponent } from './utility/datatable-ng/datatable-ng.component';
import { TagsinputComponent } from './forms/form-elements/tagsinput/tagsinput.component';
import { ActivityPageComponent } from './pages/activity-page/activity-page.component';
import { EnteFormComponent } from './forms/ente/ente-form/ente-form.component';
import { AddUsersToOrganigrammaComponent } from './pages/operations/add-users-to-organigramma/add-users-to-organigramma.component';
import { AnalyticsService } from './services/analytics.service';
import { RemoveUsersFromOrganigrammaComponent } from './pages/operations/remove-users-from-organigramma/remove-users-from-organigramma.component';
import { ClassroomProfileInfoCardComponent } from './cards/classroom/classroom-profile-info-card/classroom-profile-info-card.component';
import { ClassroomProfileDatatableCardComponent } from './cards/classroom/classroom-profile-datatable-card/classroom-profile-datatable-card.component';
import { SearchService } from './services/search.service';
import { SyncUsersFromGoogleComponent } from './pages/operations/sync-users-from-google/sync-users-from-google.component';
import { UserGoogleProfileInfoCardComponent } from './cards/user/user-google-profile-info-card/user-google-profile-info-card.component';
import { GoogleMeetStatPageComponent } from './pages/statistics/google-meet-stat-page/google-meet-stat-page.component';
import { HelpSidebarComponent } from './layout/help-sidebar/help-sidebar.component';
import { ChangelogWidgetComponent } from './cards/widgets/changelog-widget/changelog-widget.component';
import { GroupFormComponent } from './forms/group/group-form/group-form.component';
import { GroupProfileComponent } from './pages/group-profile/group-profile.component';
import { GroupProfileInfoCardComponent } from './cards/group/group-profile-info-card/group-profile-info-card.component';
import { GroupProfileDatatableCardComponent } from './cards/group/group-profile-datatable-card/group-profile-datatable-card.component';
import { RemoveMembersFromGroupsComponent } from './pages/operations/groups/remove-members-from-groups/remove-members-from-groups.component';
import { DeleteGroupsComponent } from './pages/operations/groups/delete-groups/delete-groups.component';
import { UpdateMembersFromGroupsComponent } from './pages/operations/groups/update-members-from-groups/update-members-from-groups.component';
import { ReactivateSuspendedUsersComponent } from './pages/operations/reactivate-suspended-users/reactivate-suspended-users.component';
import { SuspendUsersComponent } from './pages/operations/suspend-users/suspend-users.component';
import { ListOperationComponent } from './utility/list-operation/list-operation.component';
import { CardImportantActivityComponent } from './utility/card-important-activity/card-important-activity.component';
import { PassaggioAnnoBackupComponent } from './pages/passaggio-anno-wizard/passaggio-anno-backup/passaggio-anno-backup.component';
import { UpdateAccountDomainComponent } from './pages/operations/update-account-domain/update-account-domain.component';
import { ClassroomFormComponent } from './forms/classroom/classroom-form/classroom-form.component';
import { SuspendClassroomComponent } from './pages/operations/classroom/suspend-classroom/suspend-classroom.component';
import { ActiveClassroomComponent } from './pages/operations/classroom/active-classroom/active-classroom.component';
import { ArchiveClassroomComponent } from './pages/operations/classroom/archive-classroom/archive-classroom.component';
import { DeleteClassroomComponent } from './pages/operations/classroom/delete-classroom/delete-classroom.component';
import { PreviewColumnDataComponent } from './utility/preview-column-data/preview-column-data.component';
import { CreateClassroomComponent } from './pages/operations/classroom/smart-creation-classroom/create-classroom/create-classroom.component';
import { CreateClassroomWizardStepsComponent } from './components/create-classroom-wizard-steps/create-classroom-wizard-steps.component';
import { RemoveAllMembersFromGroupsComponent } from './pages/operations/groups/remove-all-members-from-groups/remove-all-members-from-groups.component';
import { CardOperationActivityComponent } from './utility/card-operation-activity/card-operation-activity.component';
import { CheckVersionService } from './services/check-version.service';
import { WorkspaceControlSystemComponent } from './pages/workspace-control-system/workspace-control-system.component';
import { ManutenzioneComponent } from './pages/manutenzione/manutenzione.component';
import { CreateClassroomPreviewComponent } from './pages/operations/classroom/smart-creation-classroom/create-classroom-preview/create-classroom-preview.component';
import { AddMembersToGroupsComponent } from './pages/operations/groups/add-members-to-groups/add-members-to-groups.component';
import { environment } from 'src/environments/environment';
import { CreateSingleClassroomComponent } from './pages/operations/classroom/create-single-classroom/create-single-classroom.component';
import { RemoveMembersFromClassroomsComponent } from './pages/operations/classroom/remove-members-from-classrooms/remove-members-from-classrooms.component';
import { AddMembersToClassroomComponent } from './pages/operations/classroom/add-members-to-classroom/add-members-to-classroom.component';
import { RemoveMembersFromClasseComponent } from './pages/operations/remove-members-from-classe/remove-members-from-classe.component';
import { UserFormReworkComponent } from './forms/user/user-form-rework/user-form-rework.component';
import { RinnovoLicenzaComponent } from './pages/rinnovo-licenza/rinnovo-licenza.component';
import { AnnoucementWidgetComponent } from './cards/widgets/announcement-widget/announcement-widget.component';
import { AnnouncementService } from './services/general/announcement.service';
import { AnnouncementPageComponent } from './pages/announcement-page/announcement-page.component';
import { VideoTutorialWidgetComponent } from './cards/widgets/video-tutorial-widget/video-tutorial-widget.component';
import { ParametersGuard } from './guards/parameters.guard';
import { AccessTokenGuard } from './guards/accesstoken.guard';
import { AlfredStartComponent } from './pages/alfred/alfred-start/alfred-start.component';
import { AlfredConfigComponent } from './pages/alfred/alfred-config/alfred-config.component';
import { MonitorProfileComponent } from './pages/monitor-profile/monitor-profile.component';
import { MonitorProfileInfoCardComponent } from './cards/monitor/monitor-profile-info-card/monitor-profile-info-card.component';
import { MonitorFormComponent } from './forms/monitor/monitor-form/monitor-form.component';
import { ProvisionClassroomComponent } from './pages/operations/classroom/provision-classroom/provision-classroom.component';
import { DeclineClassroomComponent } from './pages/operations/classroom/decline-classroom/decline-classroom.component';
import { CardOperationNotAvailableComponent } from './utility/card-operation-not-available/card-operation-not-available.component';
import { AlfredWidgetComponent } from './cards/widgets/alfred-widget/alfred-widget.component';
import { WorkspaceAutorizzazioniComponent } from './pages/workspace-autorizzazioni/workspace-autorizzazioni.component';

@NgModule({
  declarations: [
    AppComponent,
    // System
    LoginPageComponent,
    Error404Component,
    // Laoyout
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    PageHeaderComponent,
    ActivitySidebarComponent,
    HelpSidebarComponent,
    HeaderSearchComponent,
    HeaderAccountComponent,
    // Pages
    BootstrapPageComponent,
    DashboardComponent,
    SetupWizardStartComponent,
    SetupWizardConfigComponent,
    SetupWizardConfigColPlessoClasseComponent,
    SetupWizardPreviewStrutturaComponent,
    SetupWizardConfigColUsersComponent,
    SetupWizardPreviewUsersComponent,
    SetupWizardStepsComponent,
    SetupWizardEndComponent,
    UserProfileComponent,
    PlessoProfileComponent,
    ClasseProfileComponent,
    OrganigrammaProfileComponent,
    AccountPageComponent,
    EnteProfileComponent,
    GoogleClassroomStatPageComponent,
    ActivityPageComponent,
    WorkspaceControlSystemComponent,
    ManutenzioneComponent,
    RinnovoLicenzaComponent,
    WorkspaceAutorizzazioniComponent,
    AnnouncementPageComponent,
    // Settings
    GsuiteParametersComponent,
    GeniusuiteSettingsComponent,
    // Utility
    CardLoadingComponent,
    CardLoadingErrorComponent,
    TestThemeElementComponent,
    CardImportantActivityComponent,
    CardOperationActivityComponent,
    CardOperationNotAvailableComponent,
    PreviewColumnDataComponent,
    // Forms
    OrganigrammaFormComponent,
    PlessoFormComponent,
    PlessoFormDeleteComponent,
    ForbiddenValidatorDirective,
    GoogleCheckValidatorDirective,
    ParamsStringValidator,
    CardLoadingOverlayComponent,
    ToastNotificationComponent,
    CustomDropdownTreeViewComponent,
    ClasseFormComponent,
    ClasseFormDeleteComponent,
    // Forms Element
    SwitchComponent,
    GoogleGroupSettingsComponent,
    SwitchButtonComponent,
    // Cards
    UserProfileInfoCardComponent,
    UserProfileDatatableCardComponent,
    PlessoProfileInfoCardComponent,
    PlessoProfileDatatableCardComponent,
    PlessoProfileTreeCardComponent,
    OrganigrammaProfileInfoCardComponent,
    OrganigrammaProfileDatatableCardComponent,
    OrganigrammaProfileTreeCardComponent,
    EnteProfileInfoCardComponent,
    EnteProfileDatatableCardComponent,
    ClasseProfileInfoCardComponent,
    ClasseProfileDatatableCardComponent,
    IconSetEducationComponent,
    // Direttive
    BackButtonDirective,
    // Statistics
    GoogleMeetStatComponent,
    GoogleClassroomStatComponent,
    GoogleDriveStatComponent,
    GoogleGmailStatComponent,
    ResetPasswordWidgetComponent,
    TodoWidgetComponent,
    ChangelogWidgetComponent,
    SelectComponent,
    AnnoucementWidgetComponent,
    VideoTutorialWidgetComponent,
    AlfredWidgetComponent,
    // Materie
    MaterieFormComponent,
    MateriaProfileInfoCardComponent,
    MateriaProfileDatatableCardComponent,
    MateriaProfileComponent,
    // Monitor
    MonitorFormComponent,
    MonitorProfileInfoCardComponent,
    MonitorProfileComponent,
    // Operations
    UpdatePasswordComponent,
    OrganigrammaFormDeleteComponent,
    GestioneScolasticaUserComponent,
    UserFormDeleteComponent,
    ClassroomProfileComponent,
    MoveUsersComponent,
    GestioneCattedreComponent,
    // Tool
    RequestChangePasswordPageComponent,
    PassaggioAnnoWizardStartComponent,
    PassaggioAnnoWizardStepsComponent,
    PassaggioAnnoBackupComponent,
    PassaggioAnnoWizardConfigComponent,
    PassaggioAnnoWizardStrutturaComponent,
    PassaggioAnnoWizardEndComponent,
    DeleteUsersComponent,
    RipristinoUsersComponent,
    UpdateSettingGroupsComponent,
    PassaggioAnnoWizardWaitComponent,
    SplitPipe,
    CardDatatableNgComponent,
    DatatableNgComponent,
    TagsinputComponent,
    EnteFormComponent,
    AddUsersToOrganigrammaComponent,
    RemoveUsersFromOrganigrammaComponent,
    ClassroomProfileInfoCardComponent,
    ClassroomProfileDatatableCardComponent,
    SyncUsersFromGoogleComponent,
    UserGoogleProfileInfoCardComponent,
    GoogleMeetStatPageComponent,
    GroupFormComponent,
    GroupProfileComponent,
    GroupProfileInfoCardComponent,
    GroupProfileDatatableCardComponent,
    RemoveMembersFromGroupsComponent,
    DeleteGroupsComponent,
    UpdateMembersFromGroupsComponent,
    ReactivateSuspendedUsersComponent,
    SuspendUsersComponent,
    ListOperationComponent,
    UpdateAccountDomainComponent,
    ClassroomFormComponent,
    SuspendClassroomComponent,
    ProvisionClassroomComponent,
    DeclineClassroomComponent,
    ActiveClassroomComponent,
    ArchiveClassroomComponent,
    DeleteClassroomComponent,
    CreateClassroomComponent,
    CreateClassroomWizardStepsComponent,
    RemoveAllMembersFromGroupsComponent,
    CreateClassroomPreviewComponent,
    AddMembersToGroupsComponent,
    CreateSingleClassroomComponent,
    RemoveMembersFromClassroomsComponent,
    AddMembersToClassroomComponent,
    RemoveMembersFromClasseComponent,
    UserFormReworkComponent,
    AlfredStartComponent,
    AlfredConfigComponent,
    WorkspaceAutorizzazioniComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ClipboardModule,
    TranslateModule.forRoot({
      defaultLanguage: 'it',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SimpleNotificationsModule.forRoot(),
    PaginationModule.forRoot(),
    GoogleApiModule.forRoot({ provide: NG_GAPI_CONFIG, useValue: gapiClientConfig }),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    DropdownModule,
    MultiSelectModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    TooltipModule,
    RippleModule,
    TableModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    SidebarModule,
    ContextMenuModule,
    TieredMenuModule,
    TreeModule,
    OverlayPanelModule,
    OrganizationChartModule,
    TagModule,
    PanelModule,
    AutoCompleteModule,
    DataViewModule,
    FileUploadModule,
    DialogModule,
    SelectButtonModule,
    SliderModule,
  ],
  providers: [
    AuthGuard,
    ParametersGuard,
    AccessTokenGuard,
    BackendService,
    PusherService,
    AccountService,
    ActivityService,
    SyncdataService,
    SettingsService,
    CacheService,
    RelationService,
    FileimportService,
    GoogleDirectoryService,
    PageService,
    ConfigService,
    AnalyticsService,
    SearchService,
    CheckVersionService,
    AnnouncementService,
    { provide: LOCALE_ID, useValue: "it" },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true },

    // Tool
    RequestChangePasswordService,

    // Operations
    UpdatePasswordService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
