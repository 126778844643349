<!-- Card -->
<div class="card">
  <ng-container *ngIf="showCardDatatable.classi">

    <div class="card-header p-1">
      <div class="row justify-content-between align-items-center flex-grow-1">
        <div class="col">
          <tabset [justified]="true" type="pills">

            <tab #tab1="tab" (selectTab)="selectTab(1)">
              <ng-template tabHeading>
                <i class="fas fa-chalkboard nav-icon"></i> Classi
              </ng-template>
            </tab>

            <tab #tab2="tab" (selectTab)="selectTab(2)">
              <ng-template tabHeading>
                <i class="fas fa-users nav-icon"></i> {{ 'STR.gruppi' | translate }}
              </ng-template>
            </tab>

            <tab #tab3="tab" (selectTab)="selectTab(3)">
              <ng-template tabHeading>
                <i class="fas fa-users nav-icon"></i> {{ 'STR.classroom' | translate }}
              </ng-template>
            </tab>
          </tabset>
        </div>
      </div>
    </div>

    <app-card-datatable-ng *ngIf="tab2.active" title="{{ 'STR.gruppi' | translate }}" [data]="groups"
    [rowData]="groupsData" [idRootData]="id" [menuType]="'groups'"
      [isLoadingData]="isLoading.groups" [error]="groupsError" (rowClickEvent)="rowClickGroup($event)" [showExportButton]="true">
    </app-card-datatable-ng>

    <app-card-datatable-ng *ngIf="tab3.active" title="Classroom" [data]="courseClassroom" [rowData]="courseClassroomData" [idRootData]="id_google" [menuType]="'classroom'"
      [isLoadingData]="isLoading.classroom" [error]="courseClassroomError" (rowClickEvent)="rowClickCourse($event)"
      [showCheckBox]="false" [showExportButton]="true">
    </app-card-datatable-ng>

    <app-card-datatable-ng *ngIf="tab1.active" title="Classi" [data]="classi" [rowData]="classiData" [idRootData]="id" [menuType]="'classi'" [isLoadingData]="isLoading.classi"
      [error]="classiError" (rowClickEvent)="rowClickClasse($event)" [showExportButton]="true">
    </app-card-datatable-ng>

  </ng-container>

  <ng-container *ngIf="!showCardDatatable.classi">
    <div class="card-header p-1">
      <div class="row justify-content-between align-items-center flex-grow-1">
        <div class="col">
          <tabset [justified]="true" type="pills">

            <tab #tab2="tab" (selectTab)="selectTab(2)">
              <ng-template tabHeading>
                <i class="fas fa-users nav-icon"></i> {{ 'STR.gruppi' | translate }}
              </ng-template>
            </tab>

            <tab #tab3="tab" (selectTab)="selectTab(3)">
              <ng-template tabHeading>
                <i class="fas fa-users nav-icon"></i> {{ 'STR.classroom' | translate }}
              </ng-template>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
    <app-card-datatable-ng *ngIf="tab2.active" title="{{ 'STR.gruppi' | translate }}" [data]="groups" [rowData]="groupsData" [idRootData]="id_google" [menuType]="'groups'"
      [isLoadingData]="isLoading.groups" [error]="groupsError" (rowClickEvent)="rowClickGroup($event)" [showExportButton]="true">
    </app-card-datatable-ng>

    <app-card-datatable-ng *ngIf="tab3.active" title="Classroom" [data]="courseClassroom" [rowData]="courseClassroomData" [idRootData]="id_google" [menuType]="'classroom'"
      [isLoadingData]="isLoading.classroom" [error]="courseClassroomError" (rowClickEvent)="rowClickCourse($event)"
      [showCheckBox]="false" [showExportButton]="true">
    </app-card-datatable-ng>

  </ng-container>
</div>