<!-- Card -->
<div class="card">
  <!-- Header -->
  <div class="card-header p-1">
    <div class="row justify-content-between align-items-center flex-grow-1">
      <div class="col">
        <tabset [justified]="true" type="pills">
          <tab #tab1="tab" (selectTab)="selectTab(1)">
            <ng-template tabHeading>
              <i class="fas fa-chalkboard nav-icon"></i> Classi
            </ng-template>
          </tab>
          <tab #tab2="tab" (selectTab)="selectTab(2)">
            <ng-template tabHeading>
              <i class="fas fa-users nav-icon"></i> {{ 'STR.gruppi' | translate }}
            </ng-template>
          </tab>
          <!-- <tab #tab3="tab" (selectTab)="selectTab(3)">
            <ng-template tabHeading>
                <i class="tio-format-points nav-icon"></i> Classroom
            </ng-template>
          </tab> -->
          <tab #tab4="tab" (selectTab)="selectTab(4)">
            <ng-template tabHeading>
              <i class="fas fa-user-graduate nav-icon"></i>{{ 'STR.studenti' | translate }}
            </ng-template>
          </tab>
          <tab #tab5="tab" (selectTab)="selectTab(5)">
            <ng-template tabHeading>
              <i class="fas fa-chalkboard-teacher nav-icon"></i>{{ 'STR.docenti' | translate }}
            </ng-template>
          </tab>
          <tab #tab6="tab" (selectTab)="selectTab(6)">
            <ng-template tabHeading>
              <i class="fas fa-user-friends nav-icon"></i> {{ 'STR.org_unit' | translate }}
            </ng-template>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
  <!-- End Header -->
  <!-- <div class="card-body">

  </div> -->

  <app-card-datatable-ng *ngIf="tab1.active" title="Classi" [data]="classi" [rowData]="classiData" [idRootData]="id"
    [menuType]="'classi'" [isLoadingData]="isLoading.classi" [error]="classiError"
    (rowClickEvent)="rowClickClasse($event)" (addClickEvent)="addClickClass()" [showAddButton]="true"
    [showExportButton]="true"></app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab2.active" title="{{ 'STR.gruppi' | translate }}" [data]="groups" [idRootData]="id"
    [rowData]="groupsData" [isLoadingData]="isLoading.groups" [menuType]="'groups'" [error]="groupsError"
    (rowClickEvent)="rowClickGroup($event)" (addClickEvent)="addClickGroup()" [showAddButton]="false"
    [showExportButton]="true"></app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab4.active" title="{{ 'STR.studenti' | translate }}" [data]="studenti"
    [rowData]="studentiData" [idRootData]="id" [menuType]="'users|studenti'" [isLoadingData]="isLoading.studenti"
    [showAvatarOnFirstColumn]="true" [error]="studentiError" (rowClickEvent)="rowClickStudente($event)"
    (addClickEvent)="addClickUser('studente')" [showAddButton]="true" [showExportButton]="true">
  </app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab5.active" title="{{ 'STR.docenti' | translate }}" [data]="docenti"
    [rowData]="docentiData" [idRootData]="id" [menuType]="'users|docenti-plesso'" [isLoadingData]="isLoading.docenti"
    [showAvatarOnFirstColumn]="true" [error]="docentiError" (rowClickEvent)="rowClickDocente($event)"
    (addClickEvent)="addClickUser('docente')" [showAddButton]="true" [showExportButton]="true"></app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab6.active" title="{{ 'STR.org_unit' | translate }}" [data]="usersOrgUnit"
    [rowData]="usersOrgUnitData" [idRootData]="id" [menuType]="'users-org-unit'"
    (rowClickEvent)="rowClickUserOrgUnit($event)" [isLoadingData]="isLoading.usersOrgUnit" [error]="usersOrgUnitError"
    [showExportButton]="true">
  </app-card-datatable-ng>
</div>
<!-- End Card -->