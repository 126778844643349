<div class="card card-hover-shadow">
  <!-- Header -->
  <div class="card-header">
    <h5 class="card-header-title">Organigrammi</h5>
    <div class="d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" [routerLink]="['/organigramma/create']">
        <i class="fas fa-plus mr-2"></i>Aggiungi nuovo
      </button>
    </div>
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div class="card-body" style="overflow-y: auto;">

    <!-- <div class="row">
      <div class="col">

      </div>
    </div> -->

    <p-organizationChart [value]="organigrammaTree" selectionMode="single" [(selection)]="selectedNode"
      (onNodeSelect)="onNodeSelect($event)">
      <ng-template let-node pTemplate="default">
        <i *ngIf="node.icon" class="fal fa-{{node.icon}} mr-2"></i>
        <div class="text-truncate" style="max-width: 150px;" [pTooltip]="node.label">{{node.label}}</div>
      </ng-template>
    </p-organizationChart>

  </div>
  <!-- End Body -->
</div>