import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivityService } from 'src/app/services/activity.service';
import { FileimportService } from 'src/app/services/fileimport.service';
import { ParametriService } from 'src/app/services/general/parametri.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';

@Component({
  selector: 'app-setup-wizard-preview-users',
  templateUrl: './setup-wizard-preview-users.component.html',
  styleUrls: ['./setup-wizard-preview-users.component.scss']
})
export class SetupWizardPreviewUsersComponent implements OnInit {

  struttura: any;
  strutturaOmonimie: any;
  strutturaErrori: any;
  strutturaWarning: any;

  paramSpostaInOrgUnitOrganigramma: boolean;

  countUtenti: number = 0;
  countUtentiNuovi: number = 0;
  countUtentiUpdate: number = 0;
  countErrors: number = 0;
  countWarnings: number = 0;
  countOmonimie: number = 0;

  importStarted: boolean = false;

  isLoading: boolean = true;

  constructor(
    private router: Router,
    private fileimportService: FileimportService,
    private directoryService: GoogleDirectoryService,
    private userService: UserService,
    private activity: ActivityService
  ) { }

  ngOnInit() {

    if (this.activity.isActivityInProgress("importazione")) {
      this.router.navigate(['/setup']);
      return;
    }

    if (this.fileimportService.hasImportProcessStarted()){
      this.router.navigate(['/setup']);
      return;
    }

    if (!this.fileimportService.hasFile()) {
      this.router.navigate(['/setup/config']);
      return;
    }

    if (!this.fileimportService.hasHeaderRow()) {
      this.router.navigate(['/setup/config']);
      return;
    }

    if (this.fileimportService.paramSpostaInOrgUnitOrganigramma)
      this.paramSpostaInOrgUnitOrganigramma = this.fileimportService.paramSpostaInOrgUnitOrganigramma;

    this.directoryService.usersList(() => {
      this.userService.all((data) => {
        this.fileimportService.getStrutturaWithUsers((data) => {

          if (!data || data.length <= 0) {
            this.router.navigate(['/setup/config']);
            return;
          }

          this.parseStruttura(data);
        });
      });
    });
  }

  parseStruttura(data) {

    this.struttura = data;

    this.strutturaOmonimie = [];
    this.strutturaErrori = [];
    this.strutturaWarning = [];
    this.countUtenti = 0;
    this.countUtentiNuovi = 0;
    this.countUtentiUpdate = 0;
    this.countErrors = 0;
    this.countWarnings = 0;

    data.forEach(elemento_struttura_obj => {

      if (typeof elemento_struttura_obj.isCollapsed == "undefined")
        elemento_struttura_obj.isCollapsed = true;

      if (typeof elemento_struttura_obj.showGroups == "undefined")
        elemento_struttura_obj.showGroups = false;

      if (elemento_struttura_obj.classi) {

        elemento_struttura_obj.classi.forEach(classe_obj => {

          if (typeof classe_obj.isCollapsed == "undefined")
            classe_obj.isCollapsed = true;

          this.parseStrutturaWithUsers(classe_obj, elemento_struttura_obj);
        });
      }
      else if (elemento_struttura_obj.users) {

        this.parseStrutturaWithUsers(elemento_struttura_obj, elemento_struttura_obj);
      }
    });

    this.isLoading = false;
  }

  parseStrutturaWithUsers(struttura_with_users, parent_obj) {

    struttura_with_users.countSelected = 0;

    struttura_with_users.users?.forEach(user => {

      if (user.selected) {

        this.countUtenti++;

        if (!user.user_geniusuite) {
          this.countUtentiNuovi++;
        }
        else {
          this.countUtentiUpdate++;
        }

        struttura_with_users.countSelected++;

        // Se ci sono errori gestisco prima quello perché sono bloccanti e va aggiornato il file di partenza
        if (user.errors.length > 0) {
          this.addToStrutturaErrori(parent_obj, struttura_with_users, user);
        }
        else
          // Se ci sono omonimie le conservono in un array separato
          // Se ho rilevato un utente google e non esiste l'utente di geniusuite allora lo aggiungo tra le omonimie
          // RIMOSSA gestione di omonimie google || (!user.user_geniusuite && !user.google_user && user.users_google.length > 1)
          if (user.omonimie.length > 0) {
            this.addToStrutturaOmonimia(user);
          }

        // Se ci sono warning te li mostro in tutti i casi
        if (user.warnings.length > 0) {
          this.addToStrutturaWarning(parent_obj, struttura_with_users, user);
        }
      }

    });

    // ordino gli utenti in base al cognome
    struttura_with_users.users?.sort(function (a, b) {
      if (a.familyName < b.familyName)
        return -1;
      if (a.familyName > b.familyName)
        return 1;
      return 0;
    });
  }

  OnCheckboxUserChanged(event, element_plesso, element_classe, element_user) {

    // Quando deseleziono o seleziono un utente non devo agire su errori o omonimie, quelle vanno gestite dell'apposito box in alto nella pagina
    element_user.selected = !element_user.selected;
    this.parseStruttura(this.struttura);
  }

  deselectUserFromOmonimie(event, element_user_with_omonimie, omonimia_to_remove = null) {

    if (!omonimia_to_remove) {
      // Se sto rimuovendo l'utente di partenza devo eliminare questo utente da tutte le omonimie delle sue omonimie :)
      element_user_with_omonimie.selected = false;
      element_user_with_omonimie.omonimie.forEach(element => {
        element.omonimie.forEach((el2, index) => {
          if (el2 == element_user_with_omonimie) {
            element.omonimie.splice(index, 1);
          }
        });
      });
    }
    else {
      // se sto eliminando l'omonimia selezionata dall'utente di partenza devo rimuoverlo solo dall'omonimia dell'utente principale
      omonimia_to_remove.selected = false;
      element_user_with_omonimie.omonimie.forEach((element, index) => {
        if (element == omonimia_to_remove) {
          element_user_with_omonimie.omonimie.splice(index, 1);
        }
      });
    }

    this.parseStruttura(this.struttura);
  }

  deselectAllUserFromWarnings(event) {

    this.strutturaWarning.forEach(element_user => {
      // Se sto rimuovendo l'utente di partenza devo eliminare questo utente da tutte le omonimie delle sue omonimie :)
      element_user.selected = false;
      element_user.omonimie.forEach(element => {
        element.omonimie.forEach((el2, index) => {
          if (el2 == element_user) {
            element.omonimie.splice(index, 1);
          }
        });
      });
    });


    this.parseStruttura(this.struttura);
  }

  linkUserToUser(event, element_user_with_omonimie, omonimia_to_link) {
    element_user_with_omonimie.omonimie = [];
    omonimia_to_link.omonimie = [];
    this.parseStruttura(this.struttura);
  }

  changeUserEmailForOmonimia(event, element_user_with_omonimie, omonimia_to_change_email) {
    element_user_with_omonimie.omonimie = [];
    omonimia_to_change_email.omonimie = [];
    let email_parts = omonimia_to_change_email.email.split('@');
    omonimia_to_change_email.email = email_parts[0] + Math.floor(Math.random() * 100) + "@" + email_parts[1];
    omonimia_to_change_email.email_generated_from_parameters = omonimia_to_change_email.email; // sovrascrivo l'email generata con i parametri perché altrimenti da il warning
    omonimia_to_change_email = this.fileimportService.checkUserInfoAndUpdate(omonimia_to_change_email);
    this.parseStruttura(this.struttura);
  }

  deselectGoogleUserFromUser(event, element_user_with_google_users, google_user_to_remove) {

    element_user_with_google_users.users_google.forEach((element, index) => {
      if (element == google_user_to_remove) {
        element_user_with_google_users.users_google.splice(index, 1);
      }
    });

    this.parseStruttura(this.struttura);
  }

  linkGoogleUserToUser(event, element_user_with_google_users, google_user_to_link) {
    element_user_with_google_users.google_user = google_user_to_link;
    this.parseStruttura(this.struttura);
  }

  changeUserEmailWithParameters(event, element_user) {
    element_user.email = element_user.email_generated_from_parameters;
    element_user.warnings = [];
    element_user = this.fileimportService.checkUserInfoAndUpdate(element_user);
    this.parseStruttura(this.struttura);
  }

  changeUserEmailWithParametersAll() {
    this.strutturaWarning.forEach(element_user => {
      element_user.email = element_user.email_generated_from_parameters;
      element_user.warnings = [];
      element_user = this.fileimportService.checkUserInfoAndUpdate(element_user);
    });

    this.parseStruttura(this.struttura);
  }

  deleteWarnings(event, element_user) {
    element_user.warnings = [];
    this.parseStruttura(this.struttura);
  }

  deleteWarningsAll() {
    this.strutturaWarning.forEach(element_user => {
      element_user.warnings = [];
    });

    this.parseStruttura(this.struttura);
  }

  addToStrutturaOmonimia(user) {
    this.countOmonimie++;

    // dopo averla conteggiata, controllo che l'utente non sia presente già tra le amonimie degli utenti già inseriti,
    // in quel caso lo salto perché verrà già gestito sull'altro utente
    let found = false;

    this.strutturaOmonimie.forEach(item => {
      item.omonimie.forEach(omonimia => {
        if (omonimia == user) {
          found = true;
        }
      });
    });

    if (!found)
      this.strutturaOmonimie.push(user);
  }

  addToStrutturaErrori(plesso_obj, classe_obj, user) {
    this.countErrors++;
    user.plesso_obj = plesso_obj;
    user.classe_obj = classe_obj;
    this.strutturaErrori.push(user);
  }

  addToStrutturaWarning(plesso_obj, classe_obj, user) {
    this.countWarnings++;
    user.plesso_obj = plesso_obj;
    user.classe_obj = classe_obj;
    this.strutturaWarning.push(user);
  }

  next() {

    if (this.importStarted)
      return;

    this.fileimportService.saveStrutturaWithUsers(this.struttura);
    this.importStarted = true;
    this.fileimportService.startImportProcess((error) => {
      if (error) {
        this.importStarted = false;
      }
      else {
        this.router.navigate(['/setup/finish']);
      }
    });
  }

  back() {
    this.router.navigate(['/setup/config/users']);
  }

}
