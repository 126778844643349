import { Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { ParametriService, ParamType } from 'src/app/services/general/parametri.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { RelationInfo, RelationService } from 'src/app/services/general/relation.service';
import { OrderTypeList, UtilityService } from 'src/app/services/utility.service';
import { PageService } from 'src/app/services/page.service';
import { SyncdataService } from 'src/app/services/syncdata.service';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';

@Component({
  selector: 'app-plesso-form',
  templateUrl: './plesso-form.component.html',
  styleUrls: ['./plesso-form.component.scss']
})
export class PlessoFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() id: string;

  @ViewChild('myForm') myForm: NgForm;

  plesso: any;
  listPlessiFilteredName: any;
  listPlessiFilteredShortName: any;
  listOrgUnitFilteredName: any;
  data: any;
  dataChange = {
    name: false,
    short_name: false,
    description: false,
    org_unit: false,
    groupKey_all: false,
    groupKey_docenti: false,
    groupKey_studenti: false
  };

  formErrors = {
    forbiddenString: null,
    forbiddenGoogle: null
  }

  get dataIsChanged(): boolean {
    return Object.values(this.dataChange).some(x => x == true);
  }

  error: any;

  // Visualizzazione Setting Advanced
  showGroupSettings: boolean;
  showAdvancedSettings: boolean;
  defaultCheckGroup: any;
  existErrorGroupPreview: any;
  defaultCheckOrgUnit: any;

  // Dati Google e Stuttura di View (TreeView e Preview)
  orgUnits: any;
  orgUnitsTreeView: any;
  controlNameOrgUnit: string;
  controlNameOrgUnitParent: string;
  groups: any;
  groupsPreview: any;
  groupNotExist: any;
  organigrammi: any;

  // Loading Pagina
  loadingPlesso: boolean;
  loadingPlessoService: boolean;
  loadingGoogleOrgUnits: boolean;
  loadingGoogleGroups: boolean;
  loadingParameterService: boolean;
  loadingOrganigrammi: boolean;
  formLoading: boolean;
  edit: boolean;
  parameterLoaded: boolean;
  parameter: any;

  // Configurazione TreeView
  configTreeView: any = {
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: true
  };

  private _googleServiceOrgUnitsSubscription: Subscription;
  private _googleServiceGroupsSubscription: Subscription;
  private _plessoServiceSubscription: Subscription;
  private _parameterServiceSubscription: Subscription;
  private _relationServiceSubscription: Subscription;

  private _backendOrganigrammiServiceDataSubscription: Subscription;

  constructor(
    private utilityService: UtilityService,
    private relationService: RelationService,
    private plessoService: PlessoService,
    private parametriService: ParametriService,
    private googleService: GoogleDirectoryService,
    private router: Router,
    private syncdataService: SyncdataService,
    private organigrammiService: OrganigrammaService
  ) { }

  ngOnInit() {

    this.unsubscibeAll();

    this.data = {};
    this.defaultCheckGroup = {
      all: true,
      docenti: true,
      studenti: true
    };

    this.existErrorGroupPreview = {
      all: false,
      docenti: false,
      studenti: false
    };

    Object.keys(this.dataChange).forEach(element => {
      this.dataChange[element] = false;
    });

    this.defaultCheckOrgUnit = {
      radice: false,
      crea: true
    };

    this.loadingPlesso = false;
    this.loadingPlessoService = false;
    this.loadingParameterService = false;
    this.loadingGoogleOrgUnits = false;
    this.loadingGoogleGroups = false;
    this.formLoading = false;
    this.loadingOrganigrammi = false;
    this.edit = false;
    this.parameterLoaded = false;
    this.showGroupSettings = true;
    this.showAdvancedSettings = false;
    this.controlNameOrgUnit = "";
    this.controlNameOrgUnitParent = "";

    this.groupNotExist = {
      groupKey_all: false,
      groupKey_docenti: false,
      groupKey_studenti: false
    };

    if (this.id && this.id.length > 0) {
      this.edit = true;
      this.defaultCheckGroup = {
        all: false,
        docenti: false,
        studenti: false
      };

      this.existErrorGroupPreview = {
        all: false,
        docenti: false,
        studenti: false
      };

      this.defaultCheckOrgUnit = {
        radice: false,
        crea: false
      };

      this.getBackendData();
    }

    this._backendOrganigrammiServiceDataSubscription = this.organigrammiService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError("Organigrammi");
      else
        this.organigrammiDataUpdated(result);
    });

    this.getBackendOrganigrammiData();

    this._googleServiceOrgUnitsSubscription = this.googleService.getSubjectToUpdateOrgUnitsObservable().subscribe((result) => {
      if (!result)
        this.googleError();
      else
        this.orgUnitsUpdated(result);
    });

    this._googleServiceGroupsSubscription = this.googleService.getSubjectToUpdateGroupsObservable().subscribe((result) => {
      if (!result)
        this.googleError();
      else
        this.groupsUpdated(result);
    });

    this.getGoogleData();

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError("Plesso Service");
      else
        this.plessoServiceUpdated(result);
    })

    this.loadingPlessoService = true;
    this.plessoService.all();

    this._parameterServiceSubscription = this.parametriService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError("Parameter Service");
      else
        this.parametriServiceUpdated(result);
    })

    this.loadingParameterService = true;
    this.parametriService.all();

    this._relationServiceSubscription = this.relationService.getSubjectToUpdateObservable().subscribe(() => {
      this.relationServiceUpdated();
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  orgUnitsUpdated(result) {
    this.orgUnits = result;
    this.orgUnitsTreeView = this.utilityService.covertListToTreeViewItems(this.orgUnits, "orgUnitPath", "parentOrgUnitPath", "name", "orgUnitId", "/", OrderTypeList.Ascending, "name");
    this.loadingGoogleOrgUnits = false;
  }

  groupsUpdated(result) {
    this.groups = result;

    if (this.edit && this.groups && this.groups.length > 0 && this.data && this.plesso) {
      let groupKey_studenti = this.plesso.google_group.find(
        (x) => x.relation_info == RelationInfo.RelationInfoStudenti
      )?.groupKey;

      let groupKey_docenti = this.plesso.google_group.find(
        (x) => x.relation_info == RelationInfo.RelationInfoDocenti
      )?.groupKey;

      let groupKey_all = this.plesso.google_group.find(
        (x) => x.relation_info == RelationInfo.RelationInfoPlesso
      )?.groupKey;

      this.data.groupKey_studenti = (this.groups.find((x) => x.id == groupKey_studenti)?.id || '');

      this.data.groupKey_docenti = (this.groups.find((x) => x.id == groupKey_docenti)?.id || '');

      this.data.groupKey_all = (this.groups.find((x) => x.id == groupKey_all)?.id || '');
    }

    this.loadingGoogleGroups = false;
  }

  parametriServiceUpdated(result) {
    this.parameter = result;
    this.loadingParameterService = false;
    this.parameterLoaded = true;
  }

  relationServiceUpdated() {
    this.relationService.setDisabledOrgUnit(this.orgUnits);
    this.relationService.setDisabledGroups(this.groups)
  }

  plessoServiceUpdated(plessi) {
    this.loadingPlessoService = false;

    if (!plessi) {
      this.listPlessiFilteredName = [''];
      this.listPlessiFilteredShortName = [''];
    } else {
      if (!this.edit) {
        this.listPlessiFilteredName = plessi.map(x => x.name);
        this.listPlessiFilteredShortName = plessi.map(x => x.short_name);
      }
      else {
        this.listPlessiFilteredName = plessi.filter(x => x.name.toLowerCase().trim() != this.data.name?.toLowerCase().trim()).map(x => x.name);
        this.listPlessiFilteredShortName = plessi.filter(x => x.short_name.toLowerCase().trim() != this.data.short_name?.toLowerCase().trim()).map(x => x.short_name);
      }
    }
  }

  unsubscibeAll() {
    if (this._googleServiceOrgUnitsSubscription)
      this._googleServiceOrgUnitsSubscription.unsubscribe();

    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();

    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();

    if (this._parameterServiceSubscription)
      this._parameterServiceSubscription.unsubscribe();

    if (this._relationServiceSubscription)
      this._relationServiceSubscription.unsubscribe();



    if (this._backendOrganigrammiServiceDataSubscription)
      this._backendOrganigrammiServiceDataSubscription.unsubscribe();
  }

  getFilterListPlessi(campo: string) {
    if (!this.plessoService.data)
      return [''];

    if (!this.edit)
      return this.plessoService.data.map(x => x[campo]);
    else {
      let filtered = this.plessoService.data.filter(x => x[campo].toLowerCase().trim() !== this.data[campo].toLowerCase().trim());
      return filtered.map(x => x[campo]);
    }
  }

  changeStatusGroupGest() {
    this.showGroupSettings = !this.showGroupSettings;
  }

  changeStatusCollapse() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  getFilterOrgUnitName() {
    if (!this.orgUnits || this.data.orgUnitId)
      this.listOrgUnitFilteredName = [''];

    if (!this.data.orgUnitId) {
      let listFilted = this.orgUnits.filter(x => x.parentOrgUnitPath == "/");

      if (this.controlNameOrgUnit) {
        listFilted = listFilted.filter(x => x.name.toLowerCase().trim() !== this.controlNameOrgUnit.toLowerCase().trim());
      }

      if (!this.edit)
        this.listOrgUnitFilteredName = listFilted.map(x => x.name);
      else
        this.listOrgUnitFilteredName = listFilted.filter(x => x.orgUnitId != this.plesso.google_org_unit?.orgUnitId).map(x => x.name);
    }
  }

  getBackendOrganigrammiData() {
    this.loadingOrganigrammi = true;
    this.organigrammiService.all();
  }

  loadGroupsPreview(myForm: NgForm) {
    this.existErrorGroupPreview.studenti = false;
    this.existErrorGroupPreview.docenti = false;
    this.existErrorGroupPreview.all = false;

    if (this.showGroupSettings) {
      let objParametro = {
        name: myForm.controls?.name.value,
        short_name: myForm.controls?.short_name.value
      }

      this.groupsPreview = {
        all: this.parametriService.getEmailParamsFor(ParamType.PlessoGruppo, objParametro),
        docenti: this.parametriService.getEmailParamsFor(ParamType.PlessoDocente, objParametro),
        studenti: this.parametriService.getEmailParamsFor(ParamType.PlessoStudente, objParametro)
      }
    }

    if (this.edit)
      this.editDataChange();
  }

  checkGroupSetOnFocusOut() {
    // Faccio il check se esiste il gruppo e lo preseleziono se defaultCheckGroup.type è true
    if (this.defaultCheckGroup.studenti) {
      this.data.groupKey_studenti = (this.groups.find((x) => x.email == this.groupsPreview.studenti)?.id || '');

      if (this.data.groupKey_studenti) {
        // Errore, esiste già gruppo
        this.existErrorGroupPreview.studenti = false;
        this.defaultCheckGroup.studenti = false;
      }
    }

    if (this.defaultCheckGroup.docenti) {
      this.data.groupKey_docenti = (this.groups.find((x) => x.email == this.groupsPreview.docenti)?.id || '');

      if (this.data.groupKey_docenti) {
        // Errore, esiste già gruppo
        this.existErrorGroupPreview.docenti = false;
        this.defaultCheckGroup.docenti = false;
      }
    }

    if (this.defaultCheckGroup.all && (this.parameter && this.parameter.gest_group_plesso)) {
      this.data.groupKey_all = (this.groups.find((x) => x.email == this.groupsPreview.all)?.id || '');

      if (this.data.groupKey_all) {
        // Errore, esiste già gruppo
        this.existErrorGroupPreview.all = false;
        this.defaultCheckGroup.all = false;
      }
    }
  }

  organigrammiDataUpdated(result) {
    this.organigrammi = result;
    this.setDefaultOrganigrammaStudentiOrgUnitParent();
    this.loadingOrganigrammi = false;
  }

  selectGroupOnChange($event) {
    if (this.edit)
      this.editDataChange();
  }

  onInputDescription() {
    if (this.edit)
      this.editDataChange();
  }

  editDataChange() {
    // Controllo quali dati sono cambiati
    Object.keys(this.data).forEach(key => {
      let groupKey = null;
      switch (key) {
        case 'name':
        case 'short_name':
        case 'description':
          this.dataChange[key] = this.data[key] !== this.plesso[key] ? true : false;
          break;
        case 'orgUnitId':
          this.dataChange.org_unit = this.data.orgUnitId !== this.plesso?.google_org_unit?.orgUnitId ? true : false;
          break;
        case 'groupKey_all':
          groupKey = this.plesso.google_group.find(
            (x) => x.relation_info == RelationInfo.RelationInfoPlesso
          )?.groupKey;

          this.dataChange[key] = this.data[key] !== groupKey ? true : false;
          break;
        case 'groupKey_docenti':
          groupKey = this.plesso.google_group.find(
            (x) => x.relation_info == RelationInfo.RelationInfoDocenti
          )?.groupKey;

          this.dataChange[key] = this.data[key] !== groupKey ? true : false;
          break;
        case 'groupKey_studenti':
          groupKey = this.plesso.google_group.find(
            (x) => x.relation_info == RelationInfo.RelationInfoStudenti
          )?.groupKey;

          this.dataChange[key] = this.data[key] !== groupKey ? true : false;
          break;
        default:
          break;
      }
    });
  }

  getBackendData() {
    this.loadingPlesso = true;

    this.plessoService.get(this.id, (result) => {

      this.plesso = result;
      this.data.name = this.plesso.name;
      this.data.short_name = this.plesso.short_name;
      this.data.description = this.plesso.description;
      this.showAdvancedSettings = true;

      if (this.plesso.google_org_unit) {
        this.data.orgUnitId = this.plesso.google_org_unit.orgUnitId;
      }

      if (this.plesso.google_group && this.plesso.google_group.length > 0) {
        this.showGroupSettings = true;
        this.defaultCheckGroup.studenti = false;
        this.defaultCheckGroup.docenti = false;
        this.existErrorGroupPreview.all = false;
      }
      else {
        this.showGroupSettings = false;
        this.defaultCheckGroup.studenti = true;
        this.defaultCheckGroup.docenti = true;
        this.existErrorGroupPreview.all = true;
      }

      this.getGoogleData();

      this.loadingPlesso = false;
    }, (error) => {
      this.plessoService.all();
      this.backendError("Get All Plesso");
    })
  }

  getGoogleData() {
    this.loadingGoogleOrgUnits = true;
    this.loadingGoogleGroups = true;

    this.googleService.orgunitsList();
    this.googleService.groupsList();
  }

  googleError() {
    this.error = "google";
    this.formLoading = false;
    this.loadingGoogleOrgUnits = false;
    this.loadingGoogleGroups = false;
  }

  backendError(position) {
    this.error = "backend " + position;
    this.formLoading = false;
    this.loadingPlesso = false;
  }

  setDefaultOrganigrammaStudentiOrgUnitParent() {
    if (!this.defaultCheckOrgUnit.radice) {
      // Recupero OrgUnit dell'Organigramma Studenti
      this.organigrammiService.getDefaultOrganigrammaForUserType(
        RelationInfo.RelationInfoStudenti,
        (org) => {

          if (org.google_org_unit && !org.google_org_unit.not_found) {
            this.onSelectedParentOrgUnitId(org.google_org_unit);
          }
        }
      );
    }
  }

  onSubmit(myForm: NgForm) {

    if (myForm.valid) {
      this.formLoading = true;

      // Copio i dati dell'oggetto senza riferimento
      let reqBody = JSON.parse(JSON.stringify(myForm.form.value));

      // Gruppo All
      let creatGroup_All = false;
      let groupKey_all_Exist = null;
      let groupKey_all_Value = null;

      if (myForm.form.value.groupKey_all) {

        groupKey_all_Exist = this.googleService.getGroupByGroupKey(myForm.form.value.groupKey_all);

        if (groupKey_all_Exist) {
          creatGroup_All = false;
          groupKey_all_Value = groupKey_all_Exist.id;
        }
        else {
          creatGroup_All = true;
          groupKey_all_Value = myForm.form.value.groupKey_all;
        }
      } else {
        groupKey_all_Exist = null;
        groupKey_all_Value = null;
        creatGroup_All = (myForm.form.value.newGroupStandard_A || false);
      }

      reqBody.groupKey_all = {};
      reqBody.groupKey_all.groupValue = groupKey_all_Value;
      reqBody.groupKey_all.create = creatGroup_All;

      // Gruppo Docenti
      let existGroup_Docenti = this.googleService.getGroupByGroupKey(myForm.form.value.groupKey_docenti);
      reqBody.groupKey_docenti = {};
      reqBody.groupKey_docenti.groupValue = existGroup_Docenti ? existGroup_Docenti.id : myForm.form.value.groupKey_docenti;
      reqBody.groupKey_docenti.create = (existGroup_Docenti || !this.defaultCheckGroup.docenti) ? false : true;

      // Gruppo Studenti
      let existGroup_Studenti = this.googleService.getGroupByGroupKey(myForm.form.value.groupKey_studenti);
      reqBody.groupKey_studenti = {};
      reqBody.groupKey_studenti.groupValue = existGroup_Studenti ? existGroup_Studenti.id : myForm.form.value.groupKey_studenti;
      reqBody.groupKey_studenti.create = (existGroup_Studenti || !this.defaultCheckGroup.studenti) ? false : true;

      if (this.id && this.id.length > 0) {
        // Update
        this.plessoService.update(this.id, reqBody, true, (result) => {
          this.formLoading = false;
          this.navigateAfterSubmit();
        }, (error) => {
          this.backendError("Update");
        })
      } else {
        // Create
        this.plessoService.create(reqBody, true, (result) => {
          this.formLoading = false;
          this.navigateAfterSubmit();
        }, (error) => {
          this.backendError("Create");
        })
      }
    }
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  onSelectedParentOrgUnitId(value) {
    this.data.parentOrgUnitId = (value?.orgUnitId || null);
    this.controlNameOrgUnitParent = (value?.name || "");
  }

  onValueChangeOrgUnit(value) {
    this.data.orgUnitId = value;

    if (this.edit)
      this.editDataChange();

    this.orgUnitErrorCheckForm();
  }

  onSelectedOrgUnitId(value) {
    this.controlNameOrgUnit = (value?.name || "");
    if (!this.edit && !this.data.name) {
      this.data.name = (value?.name || "");
    }

    this.selectGroupOnChange(null);
  }

  noGroupExist(value, name) {
    this.groupNotExist[name] = value;
  }

  navigateAfterSubmit() {
    if (this.id && this.id.length > 0) {
      this.router.navigate([`/plesso/${this.id}`]);
    }
    else {
      // this.pageService.back();
      this.router.navigate(["/dashboard"]);
    }
  }

  previewEmailSelectedGroup(groupKey) {
    return (this.groups.find((x) => x.id == groupKey)?.email || null);
  }

  inputName() {
    let errorGoogle = this.myForm.controls.name.getError("forbiddenGoogle");
    let errorString = this.myForm.controls.name.getError("forbiddenString");

    this.formErrors.forbiddenGoogle = errorGoogle ? errorGoogle : null;
    this.formErrors.forbiddenString = errorString ? errorString : null;
    this.getFilterOrgUnitName();
    this.loadGroupsPreview(this.myForm);
  }

  changeCreateOrgUnit() {
    if (this.defaultCheckOrgUnit.crea) {
      this.myForm.controls.name.setErrors(null);

      if (this.formErrors.forbiddenGoogle)
        this.myForm.controls.name.setErrors({
          'forbiddenGoogle': true
        });


      if (this.formErrors.forbiddenString)
        this.myForm.controls.name.setErrors({
          'forbiddenString': true
        });
    }
  }

  orgUnitErrorCheckForm() {
    this.myForm.controls.name.setErrors(null);

    if (this.formErrors.forbiddenGoogle && !this.data.orgUnitId)
      this.myForm.controls.name.setErrors({
        'forbiddenGoogle': true
      });


    if (this.formErrors.forbiddenString)
      this.myForm.controls.name.setErrors({
        'forbiddenString': true
      });
  }
}
