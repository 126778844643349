import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-activity-sidebar',
  templateUrl: './activity-sidebar.component.html',
  styleUrls: ['./activity-sidebar.component.scss']
})
export class ActivitySidebarComponent implements OnInit {

  data: any;
  error: any;

  constructor(
    private activity: ActivityService,
    private changeDetector: ChangeDetectorRef,
    private router: Router
    ) { }

  ngOnInit() {

    this.activity.getSubjectToUpdateObservableForEachJob().subscribe((result) => {
      if (result)
        this.updateData(result);
    });

    this.activity.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.updateData(result);
    });

    this.activity.updateActivityData();
  }

  updateData(activity) {
    this.data = activity;
    this.changeDetector.detectChanges();
  }

  refresh() {
    this.activity.updateActivityData();
  }

  getActivityService() {
    return this.activity;
  }

  closeSidebar() {
    this.activity.showSidebar = false;
  }

  cancelActivity(activity) {
    this.activity.cancelActivity(activity);
  }

  showActivity(activity) {
    this.router.navigate(['/activity', activity.batch_id]);
    this.closeSidebar();
  }

}
