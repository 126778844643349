import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ParametriService } from 'src/app/services/general/parametri.service';
import { SearchResult, SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss']
})
export class HeaderSearchComponent implements OnInit {

  @ViewChild('searchDropdownMenu') searchDropdownMenu: ElementRef;
  @ViewChild('headerSearchInput') headerSearchInput: ElementRef;

  showSearchDropdownMenu: boolean = false;

  searchResult: SearchResult[];
  ricercheRecenti: any;

  _insideClick: boolean;

  constructor(
    private searchService: SearchService,
    private accountService: AccountService,
    private parametriService: ParametriService,
    ) { }

  ngOnInit() {

  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.chiudiPopup();
  }

  @HostListener('click') clickInside() {
    this._insideClick = true;
  }

  @HostListener('document:click') clickDocument() {
    if (!this._insideClick) {
      this.chiudiPopup();
    }
    this._insideClick = false;
  }

  searchFocus() {
    this.searchService.buildIndexData();
    this.apriPopup();
  }

  search(stringa: string) {

    if (stringa && stringa.length > 1) {
      this.searchResult = this.searchService.search(stringa);
    }
    else if (stringa.trim() == '') {
      this.cancellaRicerca();
    }
  }

  openSearchResult(searchResult: SearchResult) {
    this.searchService.openSearchResult(searchResult);
    this.chiudiPopup();
  }

  cancellaRicerca() {
    this.headerSearchInput.nativeElement.value = '';
    this.ricercheRecenti = this.searchService.getLatestSearchTerms();
    this.searchResult = null;
  }

  apriPopup() {
    this.showSearchDropdownMenu = true;
    this.ricercheRecenti = this.searchService.getLatestSearchTerms();
  }

  chiudiPopup() {
    this.showSearchDropdownMenu = false;
  }

  ricercaRecenteClick(stringa) {

    this.headerSearchInput.nativeElement.value = stringa;
    this.search(stringa);
  }

  getParametriService() {
    return this.parametriService;
  }

  getAccountService() {
    return this.accountService;
  }

}
