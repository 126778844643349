import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActivityService } from 'src/app/services/activity.service';
import { PassaggioAnnoService } from 'src/app/services/operations/passaggio-anno.service';

@Component({
  selector: 'app-passaggio-anno-wizard-wait',
  templateUrl: './passaggio-anno-wizard-wait.component.html',
  styleUrls: ['./passaggio-anno-wizard-wait.component.scss']
})
export class PassaggioAnnoWizardWaitComponent implements OnInit {

  showNextStep: boolean = false;
  private _activitySubscription: Subscription;

  constructor(private passaggioAnnoService: PassaggioAnnoService, private router: Router, private activityService: ActivityService) { }

  ngOnInit(): void {

    this.initServicePassaggioAnno();

    this._activitySubscription = this.activityService.getSubjectToUpdateObservable().subscribe((result) => {
      this.initServicePassaggioAnno(false);
    });

    this.activityService.updateActivityData();
  }

  initServicePassaggioAnno(changeRoot = true) {

    this.passaggioAnnoService.checkActivity();

    if (this.passaggioAnnoService.getActivityExist()) {
      if (!this.passaggioAnnoService.getActivityInProgress()) {
        this.showNextStep = true;

        if (changeRoot)
          this.router.navigate(['/passaggio-anno/finish']);
      } else {
        this.showNextStep = false;
      }
    }
  }

  ngOnDestroy() {
    if (this._activitySubscription)
      this._activitySubscription.unsubscribe();
  }

  next() {
    this.router.navigate(['/passaggio-anno/finish']);
  }
}
