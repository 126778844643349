<app-card-loading *ngIf="loading">
</app-card-loading>

<app-card-loading-error *ngIf="!(loading) && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card" *ngIf="!(loading) && !error">

  <div class="card-header">
    <h5 class="card-header-title"><i class="fad fa-tv-retro nav-icon blue mr-1"></i>{{monitor.name}}</h5>
    <div class="d-flex justify-content-end align-items-center">
      <!-- <button type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="showInfo = !showInfo">
        <i class="fas fa-info mr-2"></i>{{ 'BTN.Informazioni' | translate }}
      </button> -->
    </div>
  </div>

  <div *ngIf="showInfo || true" class="card-body">

    <div class="row">
      <div class="col">
        <small class="card-subtitle">Descrizione</small>
        <p>{{ monitor?.description || 'Nessuna descrizione'}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Stato</small>
        <p>
          <span *ngIf="monitor?.active" class="badge badge-success">Attivo</span>
          <span *ngIf="!monitor?.active" class="badge badge-danger">Non attivo</span>
        </p>
      </div>
      <div class="col">
        <small class="card-subtitle">Codice</small>
        <p>{{ monitor?.code || 'Nessun codice'}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Link anteprima</small>
        <p><a target="_blank" href="{{alfredUrl}}{{monitor.code}}">{{alfredUrl}}{{monitor.code}}</a></p>
      </div>
    </div>

  </div>
</div>
