import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivityService } from 'src/app/services/activity.service';
import { FileimportService } from 'src/app/services/fileimport.service';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';

@Component({
  selector: 'app-setup-wizard-preview-struttura',
  templateUrl: './setup-wizard-preview-struttura.component.html',
  styleUrls: ['./setup-wizard-preview-struttura.component.scss']
})
export class SetupWizardPreviewStrutturaComponent implements OnInit {

  struttura: any;
  overwriteDataPlesso: any = {};

  constructor(
    private router: Router,
    private fileimportService: FileimportService,
    private directoryService: GoogleDirectoryService,
    private organigrammaService: OrganigrammaService,
    private activity: ActivityService
  ) { }

  ngOnInit() {

    if (this.activity.isActivityInProgress("importazione")) {
      this.router.navigate(['/setup']);
      return;
    }

    if (this.fileimportService.hasImportProcessStarted()){
      this.router.navigate(['/setup']);
      return;
    }

    if (!this.fileimportService.hasFile()) {
      this.router.navigate(['/setup/config']);
      return;
    }

    if (!this.fileimportService.hasHeaderRow()) {
      this.router.navigate(['/setup/config']);
      return;
    }

    // Potrebbe non essere necessario questo passaggio,
    // ma è meglio aggiornare tutte le componenti che in questo processo di preview sono necessarie
    // Per questo motivo, in cascata, aggiorno tutto quello che serve
    this.directoryService.groupsList(() => {
      this.directoryService.orgunitsList(() => {
        this.organigrammaService.all((data) => {
          this.fileimportService.getStruttura((data) => {

            if (!data || data.length <= 0) {
              this.router.navigate(['/setup/config']);
              return;
            }

            // se la struttura non ha plesso e classi, ma ha un organigramma vado avanti automaticamente
            if (data[0]['organigramma']) {
              this.router.navigate(['/setup/config/users']);
              return;
            }

            this.struttura = this.parseStruttura(data);
          })
        });
      });
    });

    if (this.fileimportService.overwriteDataPlesso)
      this.overwriteDataPlesso = this.fileimportService.overwriteDataPlesso;
  }

  parseStruttura(struttura) {

    struttura.forEach(element => {
      // if (element.isCollapsed === null)
        element.isCollapsed = true;

      // if (element.shorGroups === null)
        element.showGroups = false;
    });

    // ordino le classi della struttura in base al nome per ogni plesso
    struttura.forEach(plesso => {
      plesso.classi.sort(function (a, b) {
        if (a.classe.name < b.classe.name)
          return -1;
        if (a.classe.name > b.classe.name)
          return 1;
        return 0;
      })
    });

    return struttura;
  }

  OnCheckboxPlessoChanged(event, element_plesso) {

    element_plesso.plesso.selected = !element_plesso.plesso.selected;

    element_plesso.classi.forEach(element_classe => {
      if (!element_classe.errors || element_classe.errors.length == 0) {
        element_classe.classe.selected = element_plesso.plesso.selected;
      }
    });
  }

  OnCheckboxClasseChanged(event, element_plesso, element_classe) {
    element_classe.classe.selected = !element_classe.classe.selected;

    if (!element_classe.classe.selected) {
      element_plesso.plesso.selected = false;
    }
    else {
      // verifico se tutte le classi sono selezionate riabilito il checkbox sul plesso
      let value = true;
      element_plesso.classi.forEach(element_classe => {
        if (!element_classe.classe.selected)
          value = false;
      });
      element_plesso.plesso.selected = value;
    }
  }

  OnChangeShortNamePlesso(event, element_plesso) {

    let nome_plesso = element_plesso.plesso.name;
    let short_name = element_plesso.plesso.short_name;

    if (!this.overwriteDataPlesso.hasOwnProperty(nome_plesso)) {
      this.overwriteDataPlesso[nome_plesso] = {
        short_name_plesso: short_name
      }
    }

    this.overwriteDataPlesso[nome_plesso]['short_name_plesso'] = short_name;

    this.fileimportService.saveOverwriteDataPlesso(this.overwriteDataPlesso);

    this.fileimportService.getStruttura((data) => {
      this.struttura = this.parseStruttura(data);
    })
  }

  next() {

    this.fileimportService.saveStruttura(this.struttura);

    this.router.navigate(['/setup/config/users']);
  }

  back() {
    this.router.navigate(['/setup/config/struttura']);
  }

  onSubmit(form: NgForm) {
  }

}
