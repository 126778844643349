import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-help-sidebar',
  templateUrl: './help-sidebar.component.html',
  styleUrls: ['./help-sidebar.component.scss']
})
export class HelpSidebarComponent implements OnInit {

  helpUrl: string = "https://geniusuite.com/?noheader";

  constructor(private utility: UtilityService) { }

  ngOnInit() {
    // this.utility.showHelpSidebar = true;
  }

  closeSidebar() {
    this.utility.showHelpSidebar = false;
  }

  getUtilityService() {
    return this.utility;
  }

}
