import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-gestione-cattedre',
  templateUrl: './gestione-cattedre.component.html',
  styleUrls: ['./gestione-cattedre.component.scss']
})
export class GestioneCattedreComponent implements OnInit {
  error: any;
  _loadingData = {
    users: false,
    google_users: false
  };

  _loadingPlesso = false;
  _loadingChangeSelectionUser = false;
  _loadingSubmit = false;

  situationPlesso: any = [];
  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }
  userStructSchool: any = [];
  userStructSchoolNew: any = [];
  userSelected: any = undefined;
  userIdSelected: any = null;
  users: any = [];
  usersData: any = [];
  resultsTags: string[];
  classiPreSelected: any = [];
  idPlessoPreSelected: any;
  plessoPreSelected: any;
  materieClassiPreSelected: any = [];

  private _userServiceSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;

  constructor(private toast: ToastService, private googleDirectoryService: GoogleDirectoryService, private backend: BackendService, private userService: UserService) { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.userSelected = undefined;
    this.userIdSelected = null;
    this._loadingData.users = true;
    this._loadingData.google_users = true;
    this._loadingPlesso = false;
    this._loadingChangeSelectionUser = false;
    this._loadingSubmit = false;

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      let data = [];
      if (result)
        data = this.userService.getByRole("google-teacher");

      this.parseUserData(data);
    });

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe(
      (result) => {
        if (result) {
          this.userService.all();
        }
        this._loadingData.google_users = false;
      });

    this.googleDirectoryService.usersList();
  }

  parseUserData(result) {
    this.usersData = [];
    this.users = result;
    result.forEach(element => {
      let user = this.userService.get(element.user_id);

      let userDataView = {
        id: element.user_id,
        google_id: null,
        fullname: user?.user.givenName + " " + user?.user.familyName,
        email: null,
        avatar: null,
        initials: user?.user.givenName[0] + user?.user.familyName,
      };

      if (user?.google_user) {
        userDataView.fullname = user.google_user.name?.givenName + " " + user.google_user.name?.familyName;
        userDataView.initials = user.google_user.name?.givenName[0] + user.google_user.name?.familyName[0];
        userDataView.email = user.google_user.primaryEmail;
        userDataView.google_id = user.google_user.id;
        userDataView.avatar = user.google_user?.thumbnailPhotoUrl;
      }

      if (userDataView.email)
        this.usersData.push(userDataView);
    });

    this.resultsTags = this.usersData;

    this._loadingData.users = false;
  }

  unsubscibeAll() {
    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  tagsFiltered($event) {
    if ($event) {
      this.resultsTags = this.usersData.filter(function (x) {
        return x.email.toLowerCase().trim().includes($event.query.toLowerCase().trim()) || x.fullname.toLowerCase().trim().includes($event.query.toLowerCase().trim());
      });
    } else {
      this.resultsTags = this.usersData;
    }
  }

  selectedUser($event) {
    this._loadingChangeSelectionUser = true;
    this.userSelected = $event;
    this.userIdSelected = $event.id;

    this.userStructSchool = [];
    this.userStructSchoolNew = [];

    this.parseStrutPreSelected();
    this._loadingPlesso = false;

    this.plessoPreSelected = null;
    this.idPlessoPreSelected = null;
    this.classiPreSelected = [];
    this.materieClassiPreSelected = [];

    setTimeout(() => {
      this._loadingChangeSelectionUser = false;
    }, 100);
  }

  clearSelection($event) {
    this.userIdSelected = null;
    this.userSelected = undefined;
    this.userStructSchool = [];
    this.userStructSchoolNew = [];

    this.plessoPreSelected = null;
    this.idPlessoPreSelected = null;
    this.classiPreSelected = [];
    this.materieClassiPreSelected = [];
    this.materieClassiPreSelected = [];
  }

  onChangePlessoClassiSelectedData($event) {
    if ($event && $event?.plesso?.id == this.idPlessoPreSelected) {
      let index = this.userStructSchoolNew.findIndex(x => x.plesso.id == this.idPlessoPreSelected);

      if (index !== (-1)) {
        this.userStructSchoolNew[index].plesso = $event.plesso;
        this.userStructSchoolNew[index].classi = $event.classi;
        this.userStructSchoolNew[index].materieClassi = $event.materieClassi;
      } else {
        this.userStructSchoolNew.push($event);
      }

      return;
    }

    this._loadingPlesso = true;

    if ($event && $event?.plesso) {
      this.plessoPreSelected = $event.plesso;
      this.idPlessoPreSelected = this.plessoPreSelected.id;
    } else {
      this.plessoPreSelected = null;
      this.idPlessoPreSelected = null;
    }

    this.classiPreSelected = [];
    this.materieClassiPreSelected = [];

    let index = this.userStructSchool.findIndex(x => x.plesso.id == this.idPlessoPreSelected);
    let indexNew = this.userStructSchoolNew.findIndex(x => x.plesso.id == this.idPlessoPreSelected);

    if (index !== (-1)) {
      this.userStructSchoolNew[indexNew] = JSON.parse(JSON.stringify(this.userStructSchool[index]));
      this.classiPreSelected = this.userStructSchool[index].classi;
      this.materieClassiPreSelected = this.userStructSchool[index].materieClassi;
    }

    setTimeout(() => {
      this._loadingPlesso = false;
    }, 50);
  }

  getSelectedStruct() {
    let indexNew = this.userStructSchoolNew.findIndex(x => x.plesso.id == this.idPlessoPreSelected);
    if (indexNew !== (-1))
      return this.userStructSchoolNew[indexNew];
    else
      return null;
  }

  parseStrutPreSelected() {
    this.userStructSchool = [];
    let userData = this.users.find(x => x.user_id == this.userIdSelected);

    //  setto il plesso e la classe dell'utente
    if (userData?.classi.length > 0) {
      this.userStructSchool = [];

      //[{ index: 0, edit: true, plessoInfoSelect: null }]
      userData.classi.forEach(classe => {
        let materie = [];

        // Controllo se in materie esistete
        if (userData?.user.materie && userData?.user.materie.length > 0) {
          materie = userData?.user.materie.filter(x => x.classe_id == classe.classe.id);
        }

        let objPlesso = {
          plesso: null,
          classi: [],
          materieClassi: []
        };

        let index = this.userStructSchool.findIndex(x => x.plesso.id == classe.classe.plesso.id);

        if (index !== (-1)) {
          // Plesso Presente
          objPlesso = this.userStructSchool[index];
          if (materie && materie.length > 0) {
            materie.forEach(element => {
              let indexMat = objPlesso.materieClassi.findIndex(x => x.materia.id == element.materia_id);

              if (indexMat !== (-1)) {
                // Materia Presente
                objPlesso.materieClassi[indexMat].classi.push({
                  ...classe.classe
                });
              } else {
                // Materia Non Presente
                objPlesso.materieClassi.push({
                  materia: { id: element.materia_id, name: element.materia_name },
                  classi: [{
                    ...classe.classe
                  }]
                })
              }
            });
          } else {
            objPlesso.classi.push({
              ...classe.classe
            });
          }
        } else {
          // Plesso non esiste lo aggiungo
          objPlesso.plesso = classe.classe.plesso;

          if (materie && materie.length > 0) {
            objPlesso.materieClassi = [];
            materie.forEach(element => {
              objPlesso.materieClassi.push({
                materia: { id: element.materia_id, name: element.materia_name },
                classi: [{
                  ...classe.classe
                }]
              })
            });
          } else {
            objPlesso.classi.push({
              ...classe.classe
            });
          }

          this.userStructSchool.push(objPlesso);
        }
      });

      this.userStructSchoolNew = JSON.parse(JSON.stringify(this.userStructSchool));
    }
  }

  saveStruct() {
    let index = this.userStructSchoolNew.findIndex(x => x.plesso.id == this.idPlessoPreSelected);
    if (index !== (-1)) {
      this._loadingSubmit = true;
      let element = this.userStructSchoolNew[index];

      let struct = {
        user_id: this.userIdSelected,
        plesso_id: element.plesso.id,
        classi_id: []
      };

      if (element.classi && element.classi.length > 0) {
        struct.classi_id = element.classi.map(x => {
          return {
            classe_id: x.id,
            materie_id: []
          }
        });
      }

      if (element.materieClassi && element.materieClassi.length > 0) {
        element.materieClassi.forEach(detailMat => {

          // Ciclo sulle classi associate alle materie
          detailMat.classi.forEach(classe => {
            // controllo se la classe è già presente nella lista
            let index = struct.classi_id.findIndex(x => x.classe_id == classe.id);
            if (index !== (-1)) {
              // se esiste allora controlla anche se non esiste già la materia aggiunta
              let indexMat = struct.classi_id[index].materie_id.findIndex(x => x == detailMat.materia.id);

              if (indexMat === (-1)) {
                // aggiungo materia dentro la classe
                struct.classi_id[index].materie_id.push(detailMat.materia.id);
              }
            } else {
              // aggiungo materia e classe se non esiste nessuna delle due
              struct.classi_id.push({
                classe_id: classe.id,
                materie_id: [detailMat.materia.id]
              });
            }
          });
        });
      }

      this.backend.post("op/cattedre", struct).subscribe((result) => {
        this.toast.success('Gestione cattedra avviata');
        setTimeout(() => {
          this._loadingSubmit = false;
          this.clearSelection(null);
        }, 150);
      }, (error) => {
        this.backend.showErrors(error);
        this._loadingSubmit = false;
      })
    } else {
      // TODO_ZORAN Gestire Errore
    }
  }

  _mapAndJoinView(data, attribute) {
    if (data && data.length > 0)
      return data.map(x => x[attribute]).sort().join(',');
    return;
  }

  _mapAndJoinViewMaterie(data, attribute) {
    if (data)
      return data.materia.name + " (" + this._mapAndJoinView(data.classi, attribute) + ")";
    return;
  }
}
