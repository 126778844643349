<app-card-loading *ngIf="loadingMateria">
</app-card-loading>

<app-card-loading-error *ngIf="!(loadingMateria) && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card" *ngIf="!(loadingMateria) && !error">
  <!-- Header -->
  <div class="card-header" *ngIf="id && materia; else elseBlock">
    <h5 class="card-header-title" *ngIf="!edit && !delete">
      <i class="fad fa-books nav-icon orange mr-1"></i>Materia: {{materia.name}}
    </h5>

    <h5 class="card-header-title" *ngIf="edit && !delete" style="margin-bottom: -3px;">
      <form [formGroup]="editForm">
        <div class="p-mt-4">
          <input formControlName="name" id="name" class="form-control form-control-sm"
            [ngClass]="(editForm.get('name').dirty || editForm.get('name').touched)? (editForm.get('name').valid ? 'is-valid': 'is-invalid') : 'untouched'"
            type="text" required maxlength="255" [forbiddenString]="getFilterData('name')" (keyup.enter)="saveEdit()"
            autocomplete="none">

          <div style="position: absolute;margin-top: -1px;" class="invalid-feedback"
            *ngIf="editForm.get('name').invalid && (editForm.get('name').dirty || editForm.get('name').touched)">
            <span>Non valido</span>
            <span *ngIf="editForm.get('name').errors.forbiddenString">, Materia già esistente</span>
          </div>

          <span style="position: absolute;margin-top: -1px;" class="valid-feedback"
            *ngIf="editForm.get('name').valid && (editForm.get('name').dirty || editForm.get('name').touched)">Valido</span>
        </div>
      </form>
    </h5>

    <h5 class="card-header-title" *ngIf="!edit && delete" style="margin-bottom: -3px;">
      <form [formGroup]="deleteForm">
        <div class="p-mt-4">
          <input autocomplete="off" formControlName="confirmation_delete" id="confirmation_delete" class="form-control form-control-sm"
            type="text" [pattern]="regEx" required autocomplete="none">

          <div style="position: absolute;margin-top: -1px;">
            <small class="d-block text-muted">Per eliminare, digita
              <span class="confirmation-phrase">Elimina</span></small>
          </div>
        </div>
      </form>
    </h5>


    <div class="d-flex justify-content-end align-items-center">
      <!-- Button Interazione -->
      <ng-container *ngIf="!edit && !delete">
        <button type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="onClickEdit()">
          <i class="fas fa-edit mr-2"></i>{{ 'BTN.Modifica' | translate }}
        </button>
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="onClickDelete()">
          <i class="far fa-trash mr-2"></i>{{ 'BTN.cancella_materia' | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="edit && !delete">
        <button type="button" class="btn btn-sm mr-2" (click)="saveEdit()" [disabled]="!editForm.valid"
          [ngClass]="(editForm.dirty || editForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
          <i class="fas fa-edit mr-2"></i>{{ 'BTN.Salva' | translate }}
        </button>
        <button type="button" class="btn btn-white btn-sm" (click)="onClickEdit()">
          <i class="far fa-times mr-2"></i>Annulla
        </button>
      </ng-container>

      <ng-container *ngIf="!edit && delete">
        <button type="button" class="btn btn-sm mr-2" (click)="saveDelete()" [disabled]="!deleteForm.valid"
          [ngClass]="(deleteForm.dirty || deleteForm.touched)? 'btn-danger' : 'btn-outline-danger' ">
          <i class="far fa-trash mr-2"></i>{{ 'BTN.cancella_materia' | translate }}
        </button>
        <button type="button" class="btn btn-white btn-sm" (click)="onClickDelete()">
          <i class="far fa-times mr-2"></i>Annulla
        </button>
      </ng-container>

    </div>
  </div>

  <ng-template #elseBlock>
    <div class="card-header">
      <h5 class="card-header-title">{{ 'STR.Materie' | translate }}</h5>
    </div>
  </ng-template>
  <!-- End Header -->

  <!-- Body -->
  <div *ngIf="showInfo" class="card-body">
    <div class="row mb-3">
      <div class="col">
        <small class="card-subtitle">Creato il</small>
        <p>{{ materia?.created_at | date:'medium' }}</p>
      </div>
    </div>
  </div>
  <!-- End Body -->

  <!-- Footer -->
  <div class="card-footer text-center">
    <div class="row">
      <div class="col">
        <span class="h4">{{studentiCount}}</span>
        <span class="d-block font-size-sm">{{ 'STR.studenti' | translate }}</span>
      </div>
      <div class="col">
        <span class="h4">{{docentiCount}}</span>
        <span class="d-block font-size-sm">{{ 'STR.docenti' | translate }}</span>
      </div>
    </div>
  </div>
  <!-- End Footer -->
</div>
