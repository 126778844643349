import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-user-profile-info-card',
  templateUrl: './user-profile-info-card.component.html',
  styleUrls: ['./user-profile-info-card.component.scss']
})
export class UserProfileInfoCardComponent implements OnInit {

  @Input() id: any;
  @Input() disableButton = false;
  @Input() showInfoClassi = false;
  selectedTreeClasse: TreeNode;
  classiTree: TreeNode[];
  plessiTree: any;

  data: any;
  ruoli: any = [];
  organigramma: any;
  classi: any = [];

  showInfo = false;

  private _userServiceSubscription: Subscription;
  private _googleServiceUserSubscription: Subscription;



  constructor(
    private syncdataService: SyncdataService,
    private userService: UserService,
    private googleDirectoryService: GoogleDirectoryService,
  ) { }

  ngOnInit() {

    this.unsubscribeAll();

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });

    this._googleServiceUserSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });

    this.updateData();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  unsubscribeAll() {
    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleServiceUserSubscription)
      this._googleServiceUserSubscription.unsubscribe();


  }

  updateData() {

    this.userService.get(this.id, (data) => {

      this.data = data;

      if (!this.data.google_user) {

        this.googleDirectoryService.usersGet(this.data.google_id, () => {
          this.updateData();
        });
      }

      this.ruoli = [];

      if (this.data.google_user) {
        if (this.data.google_user.isAdmin) {
          this.ruoli.push("Amministratore")
        }
        if (this.data.google_user.isDelegatedAdmin) {
          this.ruoli.push("Amministratore delegato")
        }
      }

      if (this.data.user.roles) {
        this.data.user.roles.forEach(ruolo => {
          this.ruoli.push('STR.' + ruolo.name);
        });
      }

      if (this.data.user.organigrammi && this.data.user.organigrammi.length > 0) {
        this.organigramma = this.data.user.organigrammi[0];
      }

      this.classi = this.data.classi;

      if (this.showInfoClassi) {

        this.classiTree = [];
        this.plessiTree = [];

        this.classi.forEach(element => {
          let indexPlesso = this.plessiTree.findIndex(x => x.plesso_id == element.classe.plesso.id);
          if (indexPlesso == -1) {
            this.plessiTree.push({
              plesso_id: element.classe.plesso.id,
              treeNode: []
            });
            indexPlesso = (this.plessiTree.length - 1);
          }

          let index = this.plessiTree[indexPlesso].treeNode.findIndex(x => x.data.plesso_id == element.classe.plesso.id);

          let materiaCheck = null;

          if (this.data.user.materie && this.data.user.materie.length > 0) {
            let listMaterie = this.data.user.materie.filter(x => x.classe_id == element.classe.id);
            materiaCheck = (listMaterie && listMaterie.length > 0) ? listMaterie.map(matElement => {
              return {
                label: matElement.materia_name,
                data: { materia_id: matElement.materia_id },
                children: null
              };
            }) : null;
          }

          if (index == -1) {
            this.plessiTree[indexPlesso].treeNode.push({
              label: element.classe.plesso.name,
              expanded: true,
              data: { plesso_id: element.classe.plesso.id },
              children: [{
                label: element.classe.name,
                data: { classe_id: element.classe.id },
                children: materiaCheck
              }]
            });
          } else {
            let indexClasse = this.plessiTree[indexPlesso].treeNode[index].children.findIndex(x => x.data.classe_id == element.classe.id);

            if (indexClasse == -1) {
              this.plessiTree[indexPlesso].treeNode[index].children.push({
                label: element.classe.name,
                data: { classe_id: element.classe.id },
                children: materiaCheck
              })
            }
          }
        });
      }
    });
  }

}
