<app-card-loading *ngIf="checkInProgress"></app-card-loading>

<div class="card card-hover-shadow" >
  <div class="card-body">
    <div class="row justify-content-sm-center text-center py-5">
      <div class="col-sm-7 col-md-5">
        <i class="fad fa-tv-retro mb-5 block" style="font-size: 200px;"></i>
        <h1>Alfred</h1>
        <p>Trasforma e gestisci i tuoi monitor scolastici.</p>
        <p>Visualizza nel monitor il calendario scolastico, video youtube, presentazioni Google Slide, immagini, notizie del sito scolastico, meteo e tanto altro...</p>
        <a class="btn btn-primary" href="https://geniusuite.com/contatti/" target="_blank">
          Richiedi l'attivazione
        </a>
      </div>
    </div>

    <div class="row m-3">
      <div class="col">

      </div>
    </div>
  </div>
</div>
