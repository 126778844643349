import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MateriaService } from 'src/app/services/general/materia.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';

@Component({
  selector: 'app-materia-profile-datatable-card',
  templateUrl: './materia-profile-datatable-card.component.html',
  styleUrls: ['./materia-profile-datatable-card.component.scss']
})
export class MateriaProfileDatatableCardComponent implements OnInit {

  @Input() id: any;
  @Output() addClick = new EventEmitter();


  reloadData: boolean;

  materia: any;

  studentiData: any;
  studenti: any;
  studentiError: any;

  docentiData: any;
  docenti: any;
  docentiError: any;

  isLoading = {
    studenti: false,
    docenti: false
  }

  private _materiaServiceSubscription: Subscription;
  private _userServiceSubscription: Subscription;

  private _googleServiceUsersSubscription: Subscription;

  constructor(
    private router: Router,
    private googleDirectoryService: GoogleDirectoryService,
    private materiaService: MateriaService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

    this.unsubscribeAll();

    this.init();

    this._materiaServiceSubscription = this.materiaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });

    this.userService.all();

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe(
      (result) => {
        if (result) {
          this.updateData();
        }
      });

    this.googleData();

    this.selectTab(1);
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  googleData() {
    this.googleDirectoryService.usersList();
  }

  setLoading(loading = false) {
    // Loading All
    Object.keys(this.isLoading).forEach(element => {
      this.isLoading[element] = loading;
    });
  }

  init() {
    this.updateData();
  }

  updateData() {
    if (this.id && this.id > 0) {
      this.materiaService.getWithUsers(this.id, (data) => {
        this.materia = data;
        this.studentiUpdated(this.materia);
        this.docentiUpdated(this.materia);
      }, (error) => {
        this.materiaService.all();
      });
    }
  }

  unsubscribeAll() {
    if (this._materiaServiceSubscription)
      this._materiaServiceSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();



    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
  }

  selectTab(index) {
    switch (index) {
      case 1:
      case 2:
        this.setLoading(true);
        this.updateData();
        break;
    }
  }

  studentiUpdated(materia) {
    this.isLoading.studenti = true;

    this.studentiData = [];

    this.studentiData = [...this.studentiData, ...materia.studenti];

    this.studenti = this.userService.getTable(this.studentiData, "materia");
    this.isLoading.studenti = false;
  }

  docentiUpdated(materia) {
    this.isLoading.docenti = true;

    this.docentiData = [];

    materia.docenti.forEach(docente => {

      let nuovo = true;

      this.docentiData.forEach(doc => {
        if (doc.id == docente.id) {
          nuovo = false;
        }
      });

      if (nuovo) {
        this.docentiData.push(docente);
      }
    });

    this.docenti = this.userService.getTable(this.docentiData, "materia");
    this.isLoading.docenti = false;
  }

  rowClickStudente(data) {
    let user = this.studentiData[data.index];
    this.router.navigate(['user/' + user.user_id], { relativeTo: this.route });
  }

  rowClickDocente(data) {
    let user = this.docentiData[data.index];
    this.router.navigate(['user/' + user.user_id], { relativeTo: this.route });
  }

  addClickUser(role: any) {
    this.router.navigate(['user/add/' + role, { role: role }], { relativeTo: this.route });
  }
}
