<label class="toggle-switch d-flex" [for]="id">
  <input [ngModel]="value" (ngModelChange)="valueChange.emit($event)" [id]="id" type="checkbox"
    class="toggle-switch-input">
  <span class="toggle-switch-label">
    <span class="toggle-switch-indicator"></span>
  </span>
  <span class="toggle-switch-content">
    <span class="d-block">{{labelText}}</span>
    <small class="d-block text-muted">{{helpText}}</small>
  </span>
</label>
