import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/general/user.service';
import { RipristinoUsersService } from 'src/app/services/operations/ripristino-users.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-ripristino-users',
  templateUrl: './ripristino-users.component.html',
  styleUrls: ['./ripristino-users.component.scss']
})
export class RipristinoUsersComponent implements OnInit {
  error: any;
  _loadingData = {
    plessi: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  data: any = [];
  users: any;
  model: any;

  operationInProgress: boolean = false;
  errorNoNameAccount: boolean = false;

  private _ripristinoUsersSubscription: Subscription;
  private _userServiceSubscription: Subscription;


  constructor(
    private ripristinoUsers: RipristinoUsersService,
    private userService: UserService,
    private syncdataService: SyncdataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initForm();
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.users = this.ripristinoUsers.items;
    this.data = this.parseUsersResult(this.users);
  }

  parseUsersResult(result) {
    this.errorNoNameAccount = false;
    let table = [];

    result.forEach(element => {

      let ruoli = [];

      if(!element.name || (!element.name?.givenName || !element.name?.familyName)){
        this.errorNoNameAccount = true;
      }

      let name = element.name?.givenName + " " + element.name?.familyName;
      let email = element.primaryEmail;

      if (element.typeUser) {
        ruoli.push(this.translate.instant('STR.' + element.typeUser));
      }

      table.push({
        "nome": [name, email],
        "ruoli": ruoli,
        "_export_data": {
          name: name,
          email: email
        },
      });

    });

    return table;
  }

  deleteEvent(rows) {
    this.ripristinoUsers.deleteItemsAtIndex(rows);
  }

  onSubmit(myForm: NgForm) {

    if (myForm.valid) {

      this.operationInProgress = true;

      this.ripristinoUsers.execOperation((error) => {
        if (!error) {
          this.initForm();
        }
      });
    }
  }

  resetOperation() {
    this.ripristinoUsers.reset();
  }

  unsubscibeAll() {


    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._ripristinoUsersSubscription)
      this._ripristinoUsersSubscription.unsubscribe();



    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
  }

  initForm() {
    this.model = {
      plesso: null,
      classe: null
    }
  }

  init() {

    this._ripristinoUsersSubscription = this.ripristinoUsers.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.dataUpdated();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }
}
