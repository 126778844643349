import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, ActivationStart, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, first } from 'rxjs/operators';
import { PageService } from 'src/app/services/page.service';


@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  private pathBackgroundUrl = "../../assets/img/page-header/";
  breadcrumbsElements: any[] = [];
  breadcrumbsElementsValues: any = {};
  breadcrumbsCustomElements: any[] = [];

  currentBackgroundUrl: string = this.pathBackgroundUrl + "default.svg";
  backgroundOpacity = 0;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pageService: PageService,
    private title: Title,
    private translate: TranslateService
  ) { }

  ngOnInit() {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        var rt = this.getChild(this.activatedRoute);
        rt.data.subscribe(data => {
          this.setBackgroundImage(data);
        });
      });

    this.router.events.pipe(filter(event => event instanceof ActivationStart))
      .subscribe((event: ActivationStart) => {
        this.breadcrumbsElements = []
        this.breadcrumbsElementsValues = {};
        this.createBreadcrumbs(event.snapshot.root);
      });

    this.pageService.getSubjectPageTitleToUpdateObservable().subscribe((element) => {
      this.parseBreadcrumbsCustomElements(element);
    });
  }

  private createBreadcrumbs(route: ActivatedRouteSnapshot, url: string = '') {

    let children: ActivatedRouteSnapshot[] = route.children;

    if (children.length === 0) {
      return;
    }

    for (let child of children) {


      let routeURL: string = child.url.map(segment => segment.path).join('/');

      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      let title = child.data.title;

      if (title && title.length > 0) {

        let label = this.translate.instant(title, this.breadcrumbsElementsValues);

        this.breadcrumbsElements.push({ title, label, url });

        this.createBreadcrumbs(child, url);
      }
      else {
        this.createBreadcrumbs(child, url);
      }
    }

    this.parseBreadcrumbsCustomElements({});
  }

  private parseBreadcrumbsCustomElements(element) {

    this.breadcrumbsElementsValues = { ...this.breadcrumbsElementsValues, ...element};

    this.breadcrumbsElements.forEach(bread => {
      bread.label = this.translate.instant(bread.title, this.breadcrumbsElementsValues);
    });
  }

  setBackgroundImage(data) {
    this.backgroundOpacity = 0;

    let newUrlBackground = this.pathBackgroundUrl + "default.svg";;

    if (data.background && data.background.length > 0) {
      newUrlBackground = this.pathBackgroundUrl + data.background;
    }

    setTimeout(() => {
      this.currentBackgroundUrl = newUrlBackground;
      this.backgroundOpacity = 1;
    }, 250);
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

}
