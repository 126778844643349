<div class="row mb-3">
  <div class="col-12">
    <app-card-operation-activity [filterActivity]="['operation.users.update-password']">
    </app-card-operation-activity>
  </div>
</div>
<div class="row mb-3" *ngIf="!showDownloadButton">
  <div class="col-12">
    <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" validate>
      <!-- Card -->
      <div class="card card-hover-shadow">
        <div class="card-body">

          <h3 class="card-title">Aggiorna la password</h3>
          <p class="card-text">Questa operazione consente di aggiornare in maniera massiva la password a tutti gli
            utenti selezionati.</p>

          <div class="row form-group">
            <div class="col-12">
              <label class="toggle-switch d-flex" for="changePasswordAtNextLogin">
                <input id="changePasswordAtNextLogin" [(ngModel)]="model.changePasswordAtNextLogin"
                  name="changePasswordAtNextLogin" #changePasswordAtNextLogin="ngModel" type="checkbox"
                  class="toggle-switch-input">
                <span class="toggle-switch-label">
                  <span class="toggle-switch-indicator"></span>
                </span>
                <span class="toggle-switch-content">
                  <span class="d-block">Cambia Password al Prossimo Login</span>
                  <small class="d-block text-muted">Attivata questa opzione gli utenti sartannò obbligati a cambiare
                    password al prossimo login</small>
                </span>
              </label>
            </div>
            <div class="col-12 mt-2">
              <label class="toggle-switch d-flex" for="gestPassword">
                <input id="gestPassword" [(ngModel)]="model.gestPassword" name="gestPassword" #gestPassword="ngModel"
                  type="checkbox" class="toggle-switch-input">
                <span class="toggle-switch-label">
                  <span class="toggle-switch-indicator"></span>
                </span>
                <span class="toggle-switch-content">
                  <span class="d-block">Imposta Password</span>
                  <small class="d-block text-muted">Attiva questa opzione per inserire la password
                    manualmente</small>
                </span>
              </label>
            </div>
          </div>

          <div class="row form-group" *ngIf="model.gestPassword">
            <div class="col-6">
              <label class="toggle-switch d-flex" for="generatePassword">
                <input id="generatePassword" [(ngModel)]="model.generatePassword" name="generatePassword"
                  #generatePassword="ngModel" type="checkbox" class="toggle-switch-input"
                  (change)="generatePasswordOnChange()">
                <span class="toggle-switch-label">
                  <span class="toggle-switch-indicator"></span>
                </span>
                <span class="toggle-switch-content">
                  <span class="d-block">Genera password sicure</span>
                  <small class="d-block text-muted">Disattiva questa opzione per inserire la password
                    manualmente</small>
                </span>
              </label>
            </div>
            <div class="col-6" *ngIf="model.generatePassword">
              <span>{{model.password}}</span> <br>
              <span class="text-muted font-size-sm">Questo è un esempio</span>
            </div>

            <div class="col-6" *ngIf="!model.generatePassword">
              <label class="input-label">Password</label>
              <input [(ngModel)]="model.password" class="form-control" name="password" #password="ngModel" type="text"
                [ngClass]="(password.dirty || password.touched)? (password.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                required autocomplete="none" pattern="[A-Za-z0-9@!]{8,16}">
              <span class="invalid-feedback" *ngIf="password.invalid && (password.dirty || password.touched)">Non
                valido</span>
              <span class="valid-feedback" *ngIf="password.valid && (password.dirty || password.touched)">Valido</span>
              <span class="text-muted font-size-sm">La password deve essere lunga tra gli 8 e i 16 caratteri e
                contenere solo lettere, numeri, '@' e '!'</span>
            </div>
          </div>

          <div class="alert alert-soft-info mt-2 mb-0">
            <span class="text-cap d-inline">Info</span>
            <ng-container *ngIf="model.changePasswordAtNextLogin">
              Dopo il primo login <b>verrà</b> richiesto di cambiare la password.
            </ng-container>
            <ng-container *ngIf="!model.changePasswordAtNextLogin">
              Dopo il primo login <b>non verrà</b> richiesto di cambiare la password.
            </ng-container>
            <ng-container *ngIf="model.gestPassword">
              Le password non vengono, in nessun modo, memorizzate da Geniusuite.
            </ng-container>
          </div>

          <div *ngIf="errorNoGoogleAccount" class="alert alert-warning mt-2 mb-0">
            <span class="text-cap d-inline">Attenzione</span> Sono stati selezionati degli utenti senza un account
            google associato, questi utenti non verranno aggiornati
          </div>

          <div class="card-footer">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto py-1">
                <button class="btn btn-outline-danger" (click)="resetOperation()"
                  [disabled]="users.length <= 0 || operationInProgress">
                  <i class="far fa-backspace mr-2"></i>
                  <span>{{'STR.reset_operation' | translate}}</span>
                </button>
              </div>
              <div class="col-6 py-1">
                <div class="row justify-content-end">
                  <button type="submit" class="btn"
                    [disabled]="!myForm.form.valid || users.length <= 0 || operationInProgress"
                    [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                    <span>Avvia l'aggiornamento</span>
                    <i class="far fa-chevron-right ml-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- End Card -->
    </form>
  </div>
</div>

<div class="card card-hover-shadow" *ngIf="showDownloadButton">
  <div class="card-body">
    <div class="row justify-content-sm-center text-center py-5">
      <div class="col-sm-7 col-md-5">
        <img class="img-fluid mb-5" src="./assets/theme/svg/illustrations/hi-five.svg" style="max-width: 15rem;">
        <h1>Operazione avviata</h1>
        <p>Attendi il completamento e salva il file con le password</p>
        <button (click)="saveFile()" type="button" class="btn btn-primary">
          <i class="mr-2 fas fa-file-download"></i>Scarica il file con le password
        </button>
      </div>
    </div>
  </div>
</div>


<div class="row" *ngIf="!showDownloadButton">
  <div class="col-md-12 col-sm">
    <div class="card">
      <app-card-datatable-ng title="{{ 'STR.utenti_selezionati' | translate }}" [data]="data" [showDeleteButton]="true"
        labelDeleteButton="Rimuovi dall'operazione" iconDeleteButton="user-times" (rowClickEvent)="rowClickUser($event)"
        (deleteClickEvent)="deleteEvent($event)" [showExportButton]="true"></app-card-datatable-ng>
    </div>
  </div>
</div>
