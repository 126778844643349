import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { CacheService, CacheType } from '../cache.service';
import { GoogleDirectoryService } from '../google/google-directory.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class MateriaService {

  data: any;
  indexSelected: any;

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  constructor(private backend: BackendService, private cacheService: CacheService, private toast: ToastService, private googleDirectoryService: GoogleDirectoryService, private userService: UserService, private utility: UtilityService) { }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  /**
   * Recupero Materie
   */
  all(
    success_callback = (data: any) => { },
    error_callback = (error: any) => { }
  ) {

    if (this.cacheService.isCacheValid(CacheType.Materia)) {
      let dataCloned = this.utility.cloneData(this.data);
      this._subjectToUpdate.next(dataCloned);
      success_callback(dataCloned);
      return;
    }

    this.backend.get(`materia/all`).subscribe(
      (response) => {
        this.data = response;
        this.cacheService.updateCacheStatus(CacheType.Materia, true);
        let dataCloned = this.utility.cloneData(this.data);
        this._subjectToUpdate.next(dataCloned);
        success_callback(dataCloned);
      },
      (error) => {
        this._subjectToUpdate.next(null);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Create Materia
   * @param materiaInfo Array della Materia da aggiungere [{name: "Nome Materia"}]
   * @param toastNotification Notifica tramite toast
   */
  create(
    materiaInfo,
    toastNotification = false,
    success_callback = (data: any) => { },
    error_callback = (error: any) => { }
  ) {
    this.backend.post('materia', materiaInfo).subscribe(
      (result) => {
        // Invalid Cache
        this.cacheService.updateCacheStatus(CacheType.Materia, false);
        if (toastNotification) this.toast.success('Materia Aggiunto');
        success_callback(result);
      },
      (error) => {
        error_callback(error);
      }
    );
  }

  /**
   * Update Materia
   * @param materiaId Id della Materia
   * @param materiaInfo Informazioni della Materia
   * @param toastNotification Notifica tramite toast
   */
  update(
    materiaId,
    materiaInfo,
    toastNotification = false,
    success_callback = (data: any) => { },
    error_callback = (error: any) => { }
  ) {
    this.backend
      .put(`materia/${materiaId}`, materiaInfo)
      .subscribe(
        (result) => {
          this.cacheService.updateCacheStatus(CacheType.Materia, false);
          if (toastNotification) this.toast.success('Materia Modificato');
          success_callback(result);
        },
        (error) => {
          error_callback(error);
        }
      );
  }

  /**
   * Delete Materia
   * @param materiaId Id della Materia
   * @param toastNotification Notifica tramite toast
   */
  delete(
    materiaId,
    toastNotification = false,
    success_callback = (data: any) => { },
    error_callback = (error: any) => { }
  ) {
    this.backend
      .destroy(`materia/${materiaId}`)
      .subscribe(
        (result) => {
          this.cacheService.updateCacheStatus(CacheType.Materia, false);
          if (toastNotification) this.toast.success('Materia Eliminata');
          success_callback(result);
        },
        (error) => {
          error_callback(error);
        }
      );
  }

  /**
   * Recupero Materia
   * @param materiaId Id della Materia
   */
  get(materiaId, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    if (this.data) {

      let materia = this.data.find((x) => x.id == materiaId);

      if (materia) {
        let dataCloned = this.utility.cloneData(materia);
        success_callback(dataCloned);
        return dataCloned;
      }
      else {
        error_callback(null);
        return null;
      }
    } else {
      error_callback(null);
      return null;
    }
  }

  getByName(materiaName, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    if (this.data) {

      let materia = this.data.find((x) => x.name == materiaName);

      if (materia) {
        let dataCloned = this.utility.cloneData(materia);
        success_callback(dataCloned);
        return dataCloned;
      }
      else {
        error_callback(null);
        return null;
      }
    } else {
      error_callback(null);
      return null;
    }
  }

  getWithUsers(materiaId, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    this.get(materiaId, (data: any) => {

      let materia = data;

      materia.studenti = this.userService.getByMateriaAndRole(materiaId, 'google-student');;
      materia.docenti = this.userService.getByMateriaAndRole(materiaId, 'google-teacher');;

      success_callback(materia);

    }, (error) => {
      error_callback(null);
    })
  }
}
