<ng-container
  *ngIf="this.getAccountService().isGoogleWorkspaceAccount() && this.getParametriService().checkParametriConfigurazione(); else avvisoFunzioneNonDisponibile">

  <div #scrollContainerProfilePage *ngIf="!showForm && !showDelete">

    <div class="row">

      <div class="col-md-12 col-sm mb-1">
        <div class="mb-3" *ngIf="!showGoogleInfoCard">
          <app-user-profile-info-card [id]="id"></app-user-profile-info-card>
        </div>
        <div class="mb-3" *ngIf="showGoogleInfoCard">
          <app-user-google-profile-info-card [id]="id_google"></app-user-google-profile-info-card>
        </div>
      </div>

      <div class="col-md-12 col-sm">
        <app-user-profile-datatable-card [id]="id" [id_google]="id_google"></app-user-profile-datatable-card>
      </div>

    </div>
  </div>

  <div *ngIf="showDelete && !showForm">
    <app-user-form-delete [id]="id"></app-user-form-delete>
  </div>

  <div *ngIf="showForm">
    <app-user-form-rework [id]="id"></app-user-form-rework>
  </div>

</ng-container>

<ng-template #avvisoFunzioneNonDisponibile>
  <app-card-operation-not-available></app-card-operation-not-available>
</ng-template>
