<div class="row mb-5">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body m-2">
        <app-passaggio-anno-wizard-steps [currentStep]="2"></app-passaggio-anno-wizard-steps>
      </div>
    </div>
  </div>
</div>

<div class="row mt-5">
  <div class="col">
    <!-- Card -->
    <div class="card card-hover-shadow">
      <div class="card-body">

        <div class="row">
          <div class="col">
            <h2>{{'PASSAGGIOANNO.config.titolo' | translate}}</h2>
            <span [innerHTML]="'PASSAGGIOANNO.config.sottotitolo' | translate"></span>
          </div>
        </div>

        <form #myForm="ngForm" validate>

          <div class="row form-group mt-5">
            <div class="col-12">
              <button class="btn btn-outline-primary" type="button" (click)="changeStatusCollapse()"
                [attr.aria-expanded]="showAdvancedSettings" aria-controls="collapseAdvancedSettings">
                Modifica impostazioni avanzate
                <span class="ml-2" [ngSwitch]="showAdvancedSettings">
                  <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                  <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                </span>
              </button>
            </div>
          </div>


          <span id="collapseAdvancedSettings" [collapse]="!showAdvancedSettings" [isAnimated]="true">
            <div class="form-group row mt-3">
              <div class="col">
                <h4>{{'PASSAGGIOANNO.config.gruppo_classe_docenti' | translate}}</h4>
                <label class="toggle-switch d-flex" for="group_classe_docente_preserve">
                  <input [(ngModel)]="config.group_classe_docente_preserve" id="group_classe_docente_preserve"
                    name="group_classe_docente_preserve" #group_classe_docente_preserve="ngModel" type="checkbox"
                    class="toggle-switch-input">
                  <span class="toggle-switch-label">
                    <span class="toggle-switch-indicator"></span>
                  </span>
                  <span class="toggle-switch-content">
                    <span class="d-block">{{'PASSAGGIOANNO.config.gruppo_classe_docenti_sub' |
                      translate}}</span>
                  </span>
                </label>
              </div>
            </div>

            <div class="form-group row mt-5">
              <div class="col">
                <h4>{{'PASSAGGIOANNO.config.gruppo_classe_studenti' | translate}}</h4>
                <label class="toggle-switch d-flex" for="group_classe_studente_preserve">
                  <input [(ngModel)]="config.group_classe_studente_preserve" id="group_classe_studente_preserve"
                    name="group_classe_studente_preserve" #group_classe_studente_preserve="ngModel" type="checkbox"
                    class="toggle-switch-input">
                  <span class="toggle-switch-label">
                    <span class="toggle-switch-indicator"></span>
                  </span>
                  <span class="toggle-switch-content">
                    <span class="d-block">{{'PASSAGGIOANNO.config.gruppo_classe_studenti_sub' |
                      translate}}</span>
                  </span>
                </label>
              </div>
            </div>

            <div class="form-group row mt-5">
              <div class="col">
                <h4>{{'PASSAGGIOANNO.config.gruppo_classe' | translate}}</h4>
                <label class="toggle-switch d-flex" for="group_classe_preserve">
                  <input [(ngModel)]="config.group_classe_preserve" id="group_classe_preserve"
                    name="group_classe_preserve" #group_classe_preserve="ngModel" type="checkbox"
                    class="toggle-switch-input">
                  <span class="toggle-switch-label">
                    <span class="toggle-switch-indicator"></span>
                  </span>
                  <span class="toggle-switch-content">
                    <span class="d-block">{{'PASSAGGIOANNO.config.gruppo_classe_sub' |
                      translate}}</span>
                  </span>
                </label>
              </div>
            </div>
          </span>

          <div class="row mt-5">
            <div class="col">

              <div class="alert alert-soft-info" role="alert">
                <div class="row justify-content-between align-items-center">
                  <div class="col-auto py-1">
                    <div [innerHTML]="'PASSAGGIOANNO.config.organigramma_alert' | translate">
                    </div>
                    <blockquote class="blockquote mt-2" *ngIf="organigramma">
                      {{organigramma.name}}
                      <span class="badge badge-soft-primary mr-2"
                        *ngIf="!organigramma.id">{{'PASSAGGIOANNO.config.nuovo' |
                        translate}}</span>

                      <ng-container *ngIf="organigramma.id">
                        <ng-container *ngIf="!organigramma.google_org_unit || organigramma.google_org_unit.not_found">
                          <span class="badge badge-soft-primary mr-2">{{'PASSAGGIOANNO.config.nuova_org_unit'
                            | translate}}</span>
                        </ng-container>
                      </ng-container>

                    </blockquote>
                  </div>
                  <div class="col-auto py-1">
                    <button *ngIf="!organigrammaAllCorrect" class="btn btn-primary"
                      (click)="createOrUpdateOrganigramma()" [disabled]="loadingUpdOrganigramma">
                      <i class="fad fa-spinner-third fa-spin mr-2 font-size-spinner" *ngIf="loadingUpdOrganigramma"></i>
                      <i class="fas fa-edit mr-2 font-size-spinner" *ngIf="!loadingUpdOrganigramma"></i>
                      <span *ngIf="editMode">Aggiorna Organigramma</span>
                      <span *ngIf="!editMode">Crea Organigramma</span>
                    </button>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="row" *ngIf="showIntegrityErrosAlert">
            <div class="col">
              <div class="alert alert-soft-danger" role="alert">
                <div class="row justify-content-between align-items-center">
                  <div class="col-auto py-1">
                    <div [innerHTML]="'PASSAGGIOANNO.config.integrity_errors_alert' | translate">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>

      </div>
    </div>
    <!-- End Card -->
  </div>
</div>

<!-- Footer Button Movimento -->
<div class="row mt-5">
  <div class="col">
    <!-- Card -->
    <div class="card card-hover-shadow">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto py-1">
            <button (click)="back()" type="button" class="btn btn-white">
              <i class="far fa-chevron-left mr-1"></i> {{'PASSAGGIOANNO.btn.indietro' | translate}}
            </button>
          </div>
          <div class="col-auto py-1">
            <button (click)="next()" type="button" class="btn btn-primary"
              [disabled]="!organigrammaAllCorrect || showIntegrityErrosAlert">
              {{'PASSAGGIOANNO.btn.avanti' | translate}} <i class="far fa-chevron-right ml-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Card -->
  </div>
</div>
<!-- /Footer Button Movimento -->
