<div class="card" [ngClass]="{'border-0 mb-0': insideCard, 'mb-5': !insideCard}">

    <!-- Gestione Alert per aggiunta di avvisi ulteriori come nelle operazioni massive -->
    <div *ngIf="alertMessages && alertMessages.length > 0" class="p-2 card-datatable-alert">
        <ng-container *ngFor="let message of alertMessages; let i=index">
            <p-messages [severity]="message.severity">
                <ng-template pTemplate>
                    <span [class]="'p-message-icon pi'" [ngClass]="{'pi-info-circle': message.severity === 'info',
                              'pi-check': message.severity === 'success',
                              'pi-exclamation-triangle': message.severity === 'warn',
                              'pi-times-circle': message.severity === 'error'}"></span>
                    <span class="p-message-summary">{{message.summary}}</span>
                    <span class="p-message-detail">{{message.detail}}</span>

                    <button class="p-message-close p-link" type="button" (click)="removeAlertMessages(message.type)"
                        pTooltip="Chiudi Alert" pRipple>
                        <i class="p-message-close-icon pi pi-times"></i>
                    </button>

                    <button class="p-message-close p-link" *ngIf="message.reload_datatable" type="button"
                        (click)="clearSearchFilterRealoadTable(message.type)"
                        pTooltip="Pulisci il filtro e ricarica la tabella" pRipple>
                        <i class="p-message-close-icon pi pi-refresh"></i>
                    </button>
                </ng-template>
            </p-messages>
        </ng-container>
    </div>

    <app-card-loading-overlay [loading]="isLoadingData"></app-card-loading-overlay>

    <div style="min-height: 200px;" [ngClass]="{'card-is-loading': isLoadingData}">

        <div *ngIf="!data || data.length == 0" class="text-center p-4"><img class="mb-3"
                src="./assets/theme/svg/illustrations/sorry.svg" style="width: 7rem;">
            <p class="mb-0">Non ci sono dati da mostrare</p>
            <div class="row justify-content-sm-center text-center pt-3" *ngIf="showAddButton">
                <button type="button"
                    class="btn btn-outline-primary btn-sm" (click)="addClickEvent.emit()">
                    <i class="mr-1 fas fa-plus"></i>
                    Crea nuovo
                </button>
            </div>
        </div>

        <ng-container *ngIf="_data && headerTableData && bodyTableData && bodyTableData?.length > 0 && !isLoadingData">
            <app-datatable-ng [headerTableData]="headerTableData" [data]="_data" [title]="title" [subTitle]="subTitle" [menuItems]="menuItems"
                [showAvatarOnFirstColumn]="showAvatarOnFirstColumn" (rowCheckedEvent)="rowChecked($event)"
                (rowClickEvent)="rowClicked($event)" (deleteClickEvent)="deleteSelected()" (primaryClickEvent)="primarySelected()"
                (addClickEvent)="addClicked($event)" [showAddButton]="showAddButton"
                [labelPrimaryButton]="labelPrimaryButton" [iconPrimaryButton]="iconPrimaryButton"
                [showPrimaryButton]="showPrimaryButton" [iconDeleteButton]="iconDeleteButton" [labelDeleteButton]="labelDeleteButton"
                [showDeleteButton]="showDeleteButton" [showExportButton]="showExportButton"
                [showCheckBox]="showCheckBox" [rowExpansion]="rowExpansion"></app-datatable-ng>
        </ng-container>
    </div>

</div>
