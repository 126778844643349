import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BackendService } from '../../backend.service';
import { GoogleClassroomService } from '../../google/google-classroom.service';
import { ToastService } from '../../toast.service';
import { UtilityService } from '../../utility.service';

@Injectable({
  providedIn: 'root'
})
export class AddMembersToClassroomService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();
  private _subjectErrors: Subject<any> = new Subject<any>();

  items: any[];
  classrooms: any[];
  userTypeSelect: any = null;

  errors: any = {
    users: [],
    number: false
  };

  constructor(private googleClassroomService: GoogleClassroomService, private backend: BackendService, private toast: ToastService, private utilityService: UtilityService) {
    this.reset();
  }

  reset() {

    this.items = [];
    this.errors = {
      users: [],
      number: false
    };

    this.classrooms = [];
    this.userTypeSelect = null;

    this._subjectToUpdate.next(this.items);
    this._subjectErrors.next(this.errors);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  public getSubjectErrorsObservable(): Observable<any> {
    return this._subjectErrors.asObservable();
  }

  addMembers(newItems) {
    newItems.forEach(item => {
      let itemFound = this.items.find((element) => element?.user_id == item?.user_id);

      if (!itemFound) {
        this.items.push(item);
      }
    });

    this._subjectToUpdate.next(this.items);
  }

  deleteItemsAtIndex(indexArray) {

    indexArray.forEach(idx => {
      this.items[idx] = null;
    });

    this.items = this.items.filter((el) => { return el != null });

    this._subjectToUpdate.next(this.items);
  }

  execOperation(body, callback = (error) => { }) {
    let members = [];
    let type = null;

    if (this.userTypeSelect == "docente")
      type = "google-teacher";

    if (this.userTypeSelect == "studente")
      type = "google-student";

    this.items.forEach(element => {

      let findIndex = this.errors.users.findIndex(x => x.google_id == element.google_id);

      if (findIndex == -1) {
        this.classrooms.forEach(classroom => {
          members.push({
            googleId: element.google_id,
            courseId: classroom.id,
            type: type
          });
        });
      }

    });

    this.backend.post("op/add-members-to-classroom", { members: members }).subscribe((result) => {
      this.reset();
      this.toast.success('Aggiunta Membri avviato');
      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }

  selectClassroom(classrooms) {
    this.classrooms = []

    classrooms.forEach(classroom => {

      let classroom_info = this.googleClassroomService.getCourse(classroom.id);

      if (classroom_info) {

        this.errors.number = false;
        this.errors.users = [];

        // Recupero Studenti
        if (this.userTypeSelect == "studente") {
          classroom_info.students = [];

          this.googleClassroomService.courseStudentList(classroom.id, (result) => {
            if (result)
              classroom_info.students = result;

            let countNum = classroom_info.students.length + this.items.length;
            this.errors.number = countNum > 980 ? true : false;

            classroom_info.students.forEach(element => {
              let findIndex = this.items.findIndex(x => x.google_id == element.userId);

              if (findIndex != -1) {
                this.errors.users.push(this.items[findIndex]);
              }
            });

            this._subjectErrors.next(this.errors);
          });
        }

        // Recupero Docenti
        if (this.userTypeSelect == "docente") {
          classroom_info.teachers = [];

          this.googleClassroomService.courseTeacherList(classroom.id, (result) => {
            if (result)
              classroom_info.teachers = result;

            let countNum = classroom_info.teachers.length + this.items.length;
            this.errors.number = countNum > 20 ? true : false;

            classroom_info.teachers.forEach(element => {
              let findIndex = this.items.findIndex(x => x.google_id == element.userId);

              if (findIndex != -1) {
                this.errors.users.push(this.items[findIndex]);
              }
            });

            this._subjectErrors.next(this.errors);
          });
        }

        this.classrooms.push(classroom_info);
      }


    });

  }

  selectUserType(userType) {
    this.userTypeSelect = userType;
  }
}
