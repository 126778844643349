import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { DeleteUsersService } from 'src/app/services/operations/delete-users.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-delete-users',
  templateUrl: './delete-users.component.html',
  styleUrls: ['./delete-users.component.scss']
})
export class DeleteUsersComponent implements OnInit {
  regEx = ConfigService.regExDelete;
  error: any;
  _loadingData = {
    plessi: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  data: any = [];
  users: any;
  model: any;

  operationInProgress: boolean = false;
  errorNoUserFound: boolean = false;

  private _deleteUsersServiceSubscription: Subscription;
  private _userServiceSubscription: Subscription;


  constructor(
    private deleteUsersService: DeleteUsersService,
    private userService: UserService,
    private syncdataService: SyncdataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initForm();
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.users = this.deleteUsersService.items;
    this.data = this.userService.getTable(this.users);
  }

  deleteEvent(rows) {
    this.deleteUsersService.deleteItemsAtIndex(rows);
  }

  onSubmit(myForm: NgForm) {

    if (myForm.valid) {

      this.operationInProgress = true;

      this.deleteUsersService.execOperation((error) => {
        if (!error) {
          this.initForm();
        }
      });
    }
  }

  resetOperation() {
    this.deleteUsersService.reset();
  }

  unsubscibeAll() {


    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._deleteUsersServiceSubscription)
      this._deleteUsersServiceSubscription.unsubscribe();



    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
  }

  initForm() {
    this.model = {
      plesso: null,
      classe: null
    }
  }

  init() {

    this._deleteUsersServiceSubscription = this.deleteUsersService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.dataUpdated();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }
}
