import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { PlessoService } from '../general/plesso.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class RemoveMembersFromClasseService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  items: any[];

  constructor(private backend: BackendService, private toast: ToastService, private utilityService: UtilityService, private plessoService: PlessoService) {
    this.reset();
  }

  reset() {
    this.items = [];
    this._subjectToUpdate.next(this.items);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  addMembers(newItems, classeId, ruolo) {

    newItems.forEach(item => {

      let itemFound = this.items.find((element) => element?.id == item?.id && element?.classeId == classeId);

      if (!itemFound) {
        this.items.push({
          ...item, ...{
            ruolo: ruolo
          }
        });
      }
    });

    this._subjectToUpdate.next(this.items);
  }

  addMembersFromPlesso(newItems, plessoId, ruolo) {

    newItems.forEach(item => {

      let plesso = this.plessoService.get(plessoId);

      if (plesso && plesso.classi) {

        plesso.classi.forEach(c => {

          // recupero la classe con tutti gli attributi e la lista dei docenti/studenti
          let classe = this.plessoService.getClasse(plessoId, c.id);

          let userFound = false;

          // cerco se è presente tra i docenti/studenti della classe
          classe.docenti.forEach(doc => {
            if (doc.id == item.id) {
              userFound = true;
            }
          });

          if (!userFound) {
            classe.studenti.forEach(doc => {
              if (doc.id == item.id) {
                userFound = true;
              }
            });
          }

          // Solo se l'utente è in quella classe del plesso allora lo aggiungo
          if (userFound) {
            let itemFound = this.items.find((element) => element?.id == item?.id && element?.classeId == classe.id);

            if (!itemFound) {
              this.items.push({
                ...item, ...{
                  ruolo: ruolo,
                  classeId: classe.id
                }
              });
            }
          }

        });
      }
    });

    this._subjectToUpdate.next(this.items);
  }

  deleteItemsAtIndex(indexArray) {

    indexArray.forEach(idx => {
      this.items[idx] = null;
    });

    this.items = this.items.filter((el) => { return el != null });

    this._subjectToUpdate.next(this.items);
  }

  execOperation(callback = (error) => { }) {
    let members = [];

    this.items.forEach(element => {
      let itemFoundIndex = members.findIndex((member) => member?.id == element?.id);

      if (itemFoundIndex == -1) {
        members.push({
          id: element.id,
          classiId: [element.classeId]
        });
      } else {
        members[itemFoundIndex].classiId.push(element.classeId);
      }
    });
    
    this.backend.post("op/remove-members-from-classe", { members: members }).subscribe((result) => {
      this.reset();
      this.toast.success('Rimozione Membri avviato');
      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    });
  }
}