<div class="row mb-5">
    <div class="col">
        <div class="card card-hover-shadow">
            <div class="card-body m-2">
                <app-passaggio-anno-wizard-steps [currentStep]="4"></app-passaggio-anno-wizard-steps>
            </div>
        </div>
    </div>
</div>

<div class="card card-hover-shadow">
    <div class="card-body">
        <div class="row justify-content-sm-center text-center py-5">
            <div class="col-sm-7 col-md-5">
                <img class="img-fluid mb-5" src="./assets/theme/svg/illustrations/chat.svg" style="max-width: 15rem;">
                <h1>{{'PASSAGGIOANNO.start.title_attendi' | translate}}</h1>
                <p>{{'PASSAGGIOANNO.start.sottotitolo_attendi' | translate}}</p>
                <div class="alert alert-soft-info mb-2" role="alert" *ngIf="!showNextStep">
                    {{'PASSAGGIOANNO.wait.procedura_in_corso' | translate}}
                </div>
                <button [disabled]="!showNextStep" (click)="next()" type="button" class="btn btn-primary">
                    {{'PASSAGGIOANNO.wait.btn_avanti' | translate}}
                    <i class="far fa-chevron-right ml-1"></i>
                </button>
            </div>
        </div>
    </div>
</div>
