<ng-container *ngIf="getAccountService().isBootstrapCompleted()">

  <app-announcement-widget showByChannel="public-important"></app-announcement-widget>

  <!--
  .##.......####..######..########.##....##.########....###...
  .##........##..##....##.##.......###...##......##....##.##..
  .##........##..##.......##.......####..##.....##....##...##.
  .##........##..##.......######...##.##.##....##....##.....##
  .##........##..##.......##.......##..####...##.....#########
  .##........##..##....##.##.......##...###..##......##.....##
  .########.####..######..########.##....##.########.##.....##
  -->

  <ng-container
    *ngIf="showSubscriptionWidget && getAccountService().data.ente.subscription_active == 1  && getAccountService().data.ente.subscription_expired">
    <div class="card card-hover-shadow mb-3">
      <a class="card-header alert-danger">
        <h4 class="card-header-title text-white" [innerHTML]="'STR.geniusuite_subscription_expired_title' | translate">
        </h4>
      </a>
      <div class="card-body">
        <p class="card-text"
          [innerHTML]="'STR.geniusuite_subscription_expired_msg' | translate">
        </p>
        <p class="card-text">La licenza è scaduta il {{ getAccountService().data.ente.subscription_end |
          date:'medium' }}</p>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="showSubscriptionWidget && getAccountService().data.ente.subscription_active == 1 && !getAccountService().data.ente.subscription_expired  && getAccountService().data.ente.subscription_expiring < ( 60 * 60 * 24 * 30)">
    <div class="card card-hover-shadow mb-3">
      <a class="card-header alert-warning">
        <h4 class="card-header-title text-white" [innerHTML]="'STR.geniusuite_subscription_expiring_title' | translate">
        </h4>
      </a>
      <div class="card-body">
        <p class="card-text"
          [innerHTML]="'STR.geniusuite_subscription_expiring_msg' | translate">
        </p>
        <p class="card-text">La licenza scadrà il {{ getAccountService().data.ente.subscription_end |
          date:'medium' }}</p>
      </div>
      <div class="card-footer">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto py-1">

          </div>

          <div class="col-auto py-1">
            <button (click)="hideSuibscriptionWidgetForSession()" class="btn btn-xs btn-outline-primary">Ok, ho capito,
              nascondi l'avviso</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showSubscriptionWidget && getAccountService().data.ente.subscription_active == 0">
    <div class="card card-hover-shadow mb-3">
      <a class="card-header alert-danger">
        <h4 class="card-header-title text-white"
          [innerHTML]="'STR.geniusuite_subscription_notactive_title' | translate">
        </h4>
      </a>
      <div class="card-body">
        <div class="alert alert-soft-danger" role="alert"
          [innerHTML]="'STR.geniusuite_subscription_notactive_msg' | translate">
        </div>
      </div>
    </div>
  </ng-container>

  <!--
  ....###....########.########.####.##.....##....###....########.####..#######..##....##.########
  ...##.##......##.......##.....##..##.....##...##.##........##...##..##.....##.###...##.##......
  ..##...##.....##.......##.....##..##.....##..##...##......##....##..##.....##.####..##.##......
  .##.....##....##.......##.....##..##.....##.##.....##....##.....##..##.....##.##.##.##.######..
  .#########....##.......##.....##...##...##..#########...##......##..##.....##.##..####.##......
  .##.....##....##.......##.....##....##.##...##.....##..##.......##..##.....##.##...###.##......
  .##.....##....##.......##....####....###....##.....##.########.####..#######..##....##.########
  -->

  <ng-container *ngIf="geniusuiteNonAttivata">
    <div class="card card-hover-shadow mb-3">
      <a class="card-header alert-danger">
        <h4 class="card-header-title text-white"><i class="fas fa-exclamation-circle mr-2"></i>Geniusuite non è stata
          ancora attivata!</h4>
      </a>
      <div class="card-body">
        <div class="alert alert-soft-danger mb-0" role="alert" [innerHTML]="'STR.geniusuite_non_attiva' | translate">
        </div>
      </div>
    </div>
  </ng-container>

  <!--
  .##.....##.########..########.....###....########.########
  .##.....##.##.....##.##.....##...##.##......##....##......
  .##.....##.##.....##.##.....##..##...##.....##....##......
  .##.....##.########..##.....##.##.....##....##....######..
  .##.....##.##........##.....##.#########....##....##......
  .##.....##.##........##.....##.##.....##....##....##......
  ..#######..##........########..##.....##....##....########
  -->

  <ng-container *ngIf="newVersionAvailable">
    <div class="card card-hover-shadow mb-3">
      <a class="card-header alert-info">
        <h4 class="card-header-title text-white"><i class="fas fa-sync-alt mr-2"></i>Aggiornamento di Geniusuite {{
          newVersion }}</h4>
      </a>
      <div class="card-body">
        <div class="alert alert-soft-info" role="alert">
          <div class="row justify-content-between align-items-center">
            <div class="col-auto py-1">
              <div [innerHTML]="'STR.new_version_card_alert' | translate">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button (click)="reloadPage()" class="btn btn-info"><i class="fas fa-sync-alt mr-2"></i>Premi qui per
              aggiornare la pagina e scaricare la nuova versione di Geniusuite</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>


  <!--
  ....###.....######..########.####.##.....##.####.########.##....##....####.##.....##.########...#######..########..########....###....##....##.########.####
  ...##.##...##....##....##.....##..##.....##..##.....##.....##..##......##..###...###.##.....##.##.....##.##.....##....##......##.##...###...##....##.....##.
  ..##...##..##..........##.....##..##.....##..##.....##......####.......##..####.####.##.....##.##.....##.##.....##....##.....##...##..####..##....##.....##.
  .##.....##.##..........##.....##..##.....##..##.....##.......##........##..##.###.##.########..##.....##.########.....##....##.....##.##.##.##....##.....##.
  .#########.##..........##.....##...##...##...##.....##.......##........##..##.....##.##........##.....##.##...##......##....#########.##..####....##.....##.
  .##.....##.##....##....##.....##....##.##....##.....##.......##........##..##.....##.##........##.....##.##....##.....##....##.....##.##...###....##.....##.
  .##.....##..######.....##....####....###....####....##.......##.......####.##.....##.##.........#######..##.....##....##....##.....##.##....##....##....####
  -->
  <ng-container *ngFor="let activity of activityData">
    <div class="card card-hover-shadow mb-3 border-0">
      <!-- Header -->
      <div class="card-header bg-card-header">
        <h4 class="card-header-title text-white">
          <i class="far fa-clipboard-list-check mr-2"></i>
          {{ 'ACTIVITY.' + activity.job_batch_name + '.description' | translate }}
        </h4>
        <small class="text-white">{{ activity.perc_overall }}%</small>
      </div>
      <!-- End Header -->

      <!-- Body -->
      <div class="card-body">
        <p-progressBar [value]="activity.perc_overall" [showValue]="false" [style]="{'height': '8px'}"></p-progressBar>

        <div class="alert alert-warning mt-3 mb-0"
          [innerHTML]="'ACTIVITY.notifications.progress.msg_important_activity' | translate"></div>

      </div>
      <!-- End Body -->

      <div class="card-footer">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto">
            <small class="text-body">Ultimo aggiornamento: {{ activity.last_update | date:"medium" }}</small>
          </div>

          <div class="col-auto">
            <button class="btn btn-xs btn-outline-primary" (click)="showActivitySidebar()">
              <i class="far fa-clipboard-list-check mr-2"></i>Mostra tutte le attività
            </button>
          </div>
        </div>
      </div>

    </div>
  </ng-container>

  <!--
  ....###....########.########.####.##.....##.####.########.....######...#######..##....##....########.########..########...#######..########..####
  ...##.##......##.......##.....##..##.....##..##.....##.......##....##.##.....##.###...##....##.......##.....##.##.....##.##.....##.##.....##..##.
  ..##...##.....##.......##.....##..##.....##..##.....##.......##.......##.....##.####..##....##.......##.....##.##.....##.##.....##.##.....##..##.
  .##.....##....##.......##.....##..##.....##..##.....##.......##.......##.....##.##.##.##....######...########..########..##.....##.########...##.
  .#########....##.......##.....##...##...##...##.....##.......##.......##.....##.##..####....##.......##...##...##...##...##.....##.##...##....##.
  .##.....##....##.......##.....##....##.##....##.....##.......##....##.##.....##.##...###....##.......##....##..##....##..##.....##.##....##...##.
  .##.....##....##.......##....####....###....####....##........######...#######..##....##....########.##.....##.##.....##..#######..##.....##.####
  -->
  <ng-container *ngFor="let activity of activityDataWithErrors">
    <div class="card card-hover-shadow mb-3 alert-danger">
      <!-- Header -->
      <a class="card-header card-btn btn-block" [ngClass]="{'collapsed': activity.isCollapsed}"
        (click)="activity.isCollapsed = !activity.isCollapsed">
        <h4 class="card-header-title text-danger">Errore attività: {{ 'ACTIVITY.' + activity.job_batch_name +
          '.description' | translate }}</h4>

        <span class="card-btn-toggle" [attr.aria-expanded]="!activity.isCollapsed"
          aria-controls="collapseActivityDataWithErrors">
          <span class="btn btn-xs btn-outline-danger card-btn-toggle-default">
            <i class="far fa-angle-down mr-1"></i>
            Mostra dettagli
          </span>
          <span class="btn btn-xs btn-outline-danger card-btn-toggle-active">
            <i class="far fa-angle-up mr-1"></i>
            Nascondi dettagli
          </span>
        </span>
      </a>
      <div class="card-body" [collapse]="activity.isCollapsed" [isAnimated]="true">

        <p [innerHTML]="'STR.errore_important_actovity_body' | translate"></p>
        <button class="btn btn-xs btn-soft-light" (click)="showActivitySidebar()">
          <i class="far fa-clipboard-list-check mr-2"></i>Mostra tutte le attività
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
