<div class="row mb-5">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body m-2">
        <app-passaggio-anno-wizard-steps [currentStep]="5"></app-passaggio-anno-wizard-steps>
      </div>
    </div>
  </div>
</div>

<div class="card card-hover-shadow">
  <div class="card-body">
    <div class="row justify-content-sm-center text-center py-5">
      <div class="col-8">
        <img class="img-fluid mb-5" src="./assets/theme/svg/illustrations/chat.svg" alt="Image Description"
          style="max-width: 15rem;">
        <h1>{{'PASSAGGIOANNO.end.title' | translate}}</h1>
        <p>{{'PASSAGGIOANNO.end.sottotitolo' | translate}}</p>
        <button [routerLink]="'/setup'" type="button" class="btn btn-primary"> {{'PASSAGGIOANNO.btn.importa_file' |
          translate}}
          <i class="far fa-chevron-right ml-1"></i>
        </button>

        <h3 class="mt-5">{{'PASSAGGIOANNO.end.title_nuovo_passaggio' | translate}}</h3>
        <p><small><i class="fas fa-info-circle mr-2"></i>{{'PASSAGGIOANNO.end.nuovo_passaggio' | translate}}</small></p>

        <div class="form-check form-check-inline">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" ngModel #checkboxNuovoPassaggio1 id="customInlineCheck1" class="custom-control-input">
            <label class="custom-control-label" for="customInlineCheck1">Backup ripristinato</label>
          </div>
        </div>
        <!-- End Form Check -->

        <!-- Form Check -->
        <div class="form-check form-check-inline">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" ngModel #checkboxNuovoPassaggio2 id="customInlineCheck2" class="custom-control-input">
            <label class="custom-control-label" for="customInlineCheck2">Consapevole delle conseguenze</label>
          </div>
        </div>
        <!-- End Form Check -->

        <!-- Form Check -->
        <div class="form-check form-check-inline">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" ngModel #checkboxNuovoPassaggio3 id="customInlineCheck3" class="custom-control-input">
            <label class="custom-control-label" for="customInlineCheck3">Sono sicuro</label>
          </div>
        </div>
        <!-- End Form Check -->

        <br>

        <button *ngIf="checkboxNuovoPassaggio1.checked && checkboxNuovoPassaggio2.checked && checkboxNuovoPassaggio3.checked" (click)="nuovoPassaggio()" type="button" class="btn btn-warning mt-5">Avvia un nuovo passaggio <i class="far fa-chevron-right ml-1"></i></button>

      </div>
    </div>
  </div>
</div>
