<app-card-loading *ngIf="loading">
</app-card-loading>

<app-card-loading-error *ngIf="!(loading) && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">
  <div class="row mb-3">
    <div class="col">
      <div>
        <app-card-loading-overlay [loading]="_loading.form"></app-card-loading-overlay>

        <form #myForm="ngForm" validate onsubmit="return false;">
          <!-- Info Utente Principali -->
          <div class="card card-hover-shadow" [ngClass]="{'card-is-loading': _loading.form}">
            <div class="card-body">
              <!-- Titlte -->
              <div class="row form-group">
                <div class="col-12">
                  <h3>{{ editMode ? "Modifica Utente" : "Nuovo Utente"}}</h3>
                </div>
              </div>

              <div class="row">
                <!-- Left -->
                <div class="col">
                  <!-- Nome e Cognome -->
                  <div class="row">
                    <div class="form-group  col-6">
                      <label class="input-label">Nome</label>
                      <input [(ngModel)]="model.name.givenName" class="form-control" name="givenName"
                        #givenName="ngModel" type="text"
                        [ngClass]="(givenName.dirty || givenName.touched)? (givenName.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                        required (keyup)="suggestPrimaryEmail()" autocomplete="none" (input)="editDataInputChange()">
                      <span class="invalid-feedback"
                        *ngIf="givenName.invalid && (givenName.dirty || givenName.touched)">Non
                        valido</span>
                      <span class="valid-feedback"
                        *ngIf="givenName.valid && (givenName.dirty || givenName.touched)">Valido</span>
                    </div>
                    <div class="form-group  col-6">
                      <label class="input-label">Cognome</label>
                      <input [(ngModel)]="model.name.familyName" class="form-control" name="familyName"
                        #familyName="ngModel" type="text"
                        [ngClass]="(familyName.dirty || familyName.touched)? (familyName.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                        required (keyup)="suggestPrimaryEmail()" autocomplete="none" (input)="editDataInputChange()">
                      <span class="invalid-feedback"
                        *ngIf="familyName.invalid && (familyName.dirty || familyName.touched)">Non
                        valido</span>
                      <span class="valid-feedback"
                        *ngIf="familyName.valid && (familyName.dirty || familyName.touched)">Valido</span>
                    </div>
                  </div>

                  <!-- Tipologia di utente -->
                  <div class="row form-group">
                    <div class="col-12">
                      <label class="input-label">Tipologia di utente</label>
                      <input type="hidden" id="role" name="role" #role="ngModel" [(ngModel)]="model.role" required
                        [disabled]="editMode">
                      <app-select (onChange)="suggestPrimaryEmail();" [id]="'select-userType'"
                        [placeholder]="'STR.Seleziona_Tipologia_Utente'" [data]="rolesSelect" [showValue]="'value'"
                        [resultAttribute]="'key'" [(value)]="model.role" [optionAttributeShow]="'value'"
                        [showClear]="false"></app-select>
                    </div>
                  </div>

                  <ng-container *ngIf="model.role">
                    <!-- Email principale -->
                    <div class="row form-group">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-6">
                            <label class="input-label">Email Principale</label>
                            <input [(ngModel)]="model.usernameEmail" class="form-control" name="usernameEmail"
                              #usernameEmail="ngModel" type="text"
                              [ngClass]="(usernameEmail.dirty || usernameEmail.touched)? (usernameEmail.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                              required autocomplete="none" (keyup)="overwritePrimaryEmail()"
                              (input)="editDataInputChange()">
                            <span class="invalid-feedback"
                              *ngIf="usernameEmail.invalid && (usernameEmail.dirty || usernameEmail.touched)">Non
                              valido</span>
                            <span class="valid-feedback"
                              *ngIf="usernameEmail.valid && (usernameEmail.dirty || usernameEmail.touched)">Valido</span>
                          </div>
                          <div class="col-6">
                            <label class="input-label">Dominio</label>
                            <input type="hidden" id="domainEmail" name="domainEmail" #domainEmail="ngModel"
                              [(ngModel)]="model.domainEmail" required [disabled]="editMode">
                            <app-select [id]="'select-domainEmail'" [placeholder]="'STR.Seleziona_Dominio_Utente'"
                              [data]="domains" [showValue]="'domainName'" [resultAttribute]="'domainName'"
                              [(value)]="model.domainEmail" [optionAttributeShow]="'domainName'"
                              (onChange)="suggestPrimaryEmail()"></app-select>
                            <span class="invalid-feedback"
                              *ngIf="domainEmail.invalid && (domainEmail.dirty || domainEmail.touched)">Non
                              valido</span>
                            <span class="valid-feedback"
                              *ngIf="domainEmail.valid && (domainEmail.dirty || domainEmail.touched)">Valido</span>
                          </div>


                        </div>

                        <div class="row">
                          <div class="col-12 mt-2" *ngIf="existData.email">
                            <div class="alert alert-danger" role="alert">
                              Utente con email {{existData.email}} già esistente
                            </div>
                          </div>
                          <div class="col-12 mt-2" *ngIf="!existData.email && emailGenerated">
                            <div class="alert alert-soft-info" role="alert">
                              Imposta l'email secondo i parametri<br><button type="button" class="btn btn-primary mt-2"
                                (click)="setGeneratedEmail()">{{emailGenerated}}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Codice Fiscale -->
                    <div class="row form-group">
                      <div class="col-12">
                        <label class="input-label">Codice Identificativo</label>
                        <input [(ngModel)]="model.externalIds[0].value" class="form-control" name="codice_fiscale"
                          #codice_fiscale="ngModel" type="text"
                          [ngClass]="(codice_fiscale.dirty || codice_fiscale.touched)? (codice_fiscale.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                          (keyup)="suggestPrimaryEmail()" (input)="editDataInputChange()">
                        <span class="invalid-feedback"
                          *ngIf="codice_fiscale.invalid && (codice_fiscale.dirty || codice_fiscale.touched)">Non
                          valido</span>
                        <span class="valid-feedback"
                          *ngIf="codice_fiscale.valid && (codice_fiscale.dirty || codice_fiscale.touched)">Valido</span>
                      </div>
                    </div>


                    <!-- Creare Password -->
                    <div class="row form-group">
                      <div class="col-12">
                        <label class="input-label">Password</label>
                        <div class="input-group">
                          <input [(ngModel)]="model.password" class="form-control" name="password" #password="ngModel"
                            type="text"
                            [ngClass]="(password.dirty || password.touched)? (password.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                            [required]="!editMode" autocomplete="none" pattern="[A-Za-z0-9@!]{8,16}">
                          <div class="input-group-append">
                            <button type="button" class="btn btn-icon btn-primary" (click)="generateSecurePassword()">
                              <i class="fas fa-user-shield"></i>
                            </button>
                          </div>
                        </div>
                        <span class="invalid-feedback"
                          *ngIf="password.invalid && (password.dirty || password.touched)">Non
                          valido</span>
                        <span class="valid-feedback"
                          *ngIf="password.valid && (password.dirty || password.touched)">Valido</span>
                        <span class="text-muted font-size-sm">La password deve essere lunga
                          tra gli 8 e i 16 caratteri e
                          contenere solo lettere, numeri, '@' e '!'</span>
                      </div>
                    </div>

                    <!-- Cambia Password al prossimo login -->
                    <div class="row form-group">
                      <div class="col-12">
                        <label class="toggle-switch d-flex" for="changePasswordAtNextLogin">
                          <input id="changePasswordAtNextLogin" [(ngModel)]="model.changePasswordAtNextLogin"
                            name="changePasswordAtNextLogin" #changePasswordAtNextLogin="ngModel" type="checkbox"
                            class="toggle-switch-input">
                          <span class="toggle-switch-label">
                            <span class="toggle-switch-indicator"></span>
                          </span>
                          <span class="toggle-switch-content">
                            <span class="d-block">Cambia password al prossimo login</span>
                            <small class="d-block text-muted"></small>
                          </span>
                        </label>
                      </div>
                    </div>

                    <!-- Amministratore -->
                    <div class="row form-group" *ngIf="model.role != 'studente'">
                      <div class="col-12">
                        <h4>Amministratore Geniusuite</h4>
                        <label class="toggle-switch d-flex" for="isAdmin">
                          <input id="isAdmin" [(ngModel)]="model.isAdmin" name="isAdmin" #isAdmin="ngModel"
                            type="checkbox" class="toggle-switch-input">
                          <span class="toggle-switch-label">
                            <span class="toggle-switch-indicator"></span>
                          </span>
                          <span class="toggle-switch-content">
                            <span class="d-block">Abilita l'utente ad amministrare
                              Geniusuite</span>
                            <small class="d-block text-muted">L'utente riceverà i permessi
                              sulla Google Workspace
                              per effettuare tutte le operazioni necessarie.</small>
                          </span>
                        </label>
                      </div>
                    </div>

                  </ng-container>
                </div>

                <!-- Right -->
                <div class="col-5 p-3 bg-light rounded-lg">
                  <!-- Titlte -->
                  <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Dati con Google Workspace</h4>

                  <ng-container *ngIf="!editMode">
                    <!-- Dati Principali -->
                    <div class="row">
                      <div class="col-12">
                        <div class="alert alert-soft-info" role="alert">
                          Verrà creato l'utente
                          <b class="text-cap">{{model.name.givenName || "Nome"}} {{model.name.familyName
                            || "Cognome"}}</b>
                          con l'email
                          <b class="text-cap">{{model.primaryEmail || "Email"}}</b> e con
                          codice fiscale
                          <b class="text-cap">{{model.externalIds[0].value || "codice fiscale"}}</b>
                        </div>
                      </div>
                    </div>

                    <!-- Password -->
                    <div class="row">
                      <div class="col-12">
                        <div class="alert alert-soft-info" role="alert">
                          Verrà assegnata la password
                          <b class="text-cap">{{model.password || "password"}}</b>
                          <span *ngIf="model.changePasswordAtNextLogin">da personalizzare al
                            primo accesso</span>
                          <span *ngIf="!model.changePasswordAtNextLogin">che non dovrà
                            personalizzare al primo
                            accesso</span>
                        </div>
                      </div>
                    </div>

                    <!-- Ruolo e Organigramma -->
                    <div class="row">
                      <div class="col-12">
                        <div class="alert alert-soft-info" role="alert">
                          L'utente avrà il ruolo di
                          <b class="text-cap">{{model.role || "Tipologia di Utente"}}</b> e verrà aggiunto nel relativo
                          organiramma
                        </div>
                      </div>
                    </div>

                    <div class="row" *ngIf="create_add_to_organigramma">
                      <div class="col-12">
                        <div class="alert alert-soft-info" role="alert">
                          L'utente verrà aggiunto nell'organigramma
                          <b class="text-cap">{{create_add_to_organigramma.name}}</b>

                          <label class="toggle-switch d-flex mt-2" for="create_add_to_organigramma_aggiungi_gerarchia">
                            <input id="create_add_to_organigramma_aggiungi_gerarchia"
                              [(ngModel)]="model.create_add_to_organigramma_aggiungi_gerarchia"
                              #create_add_to_organigramma_aggiungi_gerarchia="ngModel"
                              name="create_add_to_organigramma_aggiungi_gerarchia" type="checkbox"
                              class="toggle-switch-input">
                            <span class="toggle-switch-label">
                              <span class="toggle-switch-indicator"></span>
                            </span>
                            <span class="toggle-switch-content">
                              <span class="d-block">Aggiungi anche negli Organigrammi Padre</span>
                              <small class="d-block text-muted">Attiva questa opzione per aggiungere gli utenti anche
                                negli orgniagrammi padre se presenti</small>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <h4 class="mt-3 mb-3" *ngIf="model.isAdmin"><i class="fad fa-sync-alt mr-2"></i>Geniusuite</h4>

                    <!-- Amministratore -->
                    <div class="row" *ngIf="model.isAdmin">
                      <div class="col-12">
                        <div class="alert alert-soft-warning" role="alert">
                          L'utente può <b>AMMINISTRARE</b> Geniusuite, riceverà i permessi
                          sulla Google Workspace per effettuare tutte le operazioni
                          necessarie.
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="editMode">
                    <div class="row">
                      <div class="col-12">
                        <div class="alert alert-soft-info" role="alert" *ngIf="!dataIsChanged; else elseBlock">
                          Non ci sono modifiche
                        </div>
                        <ng-template #elseBlock>
                          <div class="alert alert-soft-info" role="alert"
                            *ngIf="dataChange.familyName || dataChange.givenName">
                            L'Utente verrà rinominato da {{modelPreEdit.name.givenName}}
                            {{modelPreEdit.name.familyName}} in
                            <b>{{model.name.givenName}} {{model.name.familyName}}</b>
                          </div>

                          <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.codice_fiscale">
                            Il Codice Identificativo verrà cambiato <ng-container
                              *ngIf="modelPreEdit.externalIds[0].value">
                              da {{modelPreEdit.externalIds[0].value}}</ng-container> in
                            <b>{{model.externalIds[0].value}}</b>
                          </div>

                          <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.recoveryEmail">
                            L'email di recupero verrà cambiato <ng-container *ngIf="modelPreEdit.recoveryEmail">da
                              {{modelPreEdit.recoveryEmail}}</ng-container> in
                            <b>{{model.recoveryEmail}}</b>
                          </div>

                          <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.recoveryPhone">
                            Il numero telefonico di recupero verrà cambiato <ng-container
                              *ngIf="modelPreEdit.recoveryPhone">da {{modelPreEdit.recoveryPhone}}</ng-container> in
                            <b>{{model.recoveryPhone}}</b>
                          </div>

                          <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.email">
                            L'email verrà cambiato da {{modelPreEdit.primaryEmail}} in
                            <b>{{model.primaryEmail}}</b>
                          </div>
                        </ng-template>
                      </div>
                    </div>

                    <h4 class="mt-3 mb-3" *ngIf="model.isAdmin"><i class="fad fa-sync-alt mr-2"></i>Geniusuite</h4>

                    <!-- Amministratore -->
                    <div class="row" *ngIf="model.isAdmin">
                      <div class="col-12">
                        <div class="alert alert-soft-warning" role="alert">
                          L'utente può <b>AMMINISTRARE</b> Geniusuite, riceverà i permessi sulla Google Workspace per
                          effettuare tutte le operazioni necessarie.
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>


          </div>

          <ng-container *ngIf="model.role">

            <!-- Informazioni di sicurezza -->
            <div class="card card-hover-shadow mt-3" [ngClass]="{'card-is-loading': _loading.form}">
              <div class="card-header card-btn btn-block pointer"
                (click)="isCollapseInformazioniSicurezza = !isCollapseInformazioniSicurezza"
                [ngClass]="{'collapsed': isCollapseInformazioniSicurezza}">
                <h3 class="card-header-title">Informazioni di sicurezza</h3>
                <span class="card-btn-toggle" [attr.aria-expanded]="!isCollapseInformazioniSicurezza"
                  aria-controls="collapseInformazioniSicurezza">
                  <span class="btn btn-xs btn-ghost-primary card-btn-toggle-default">
                    <i class="fas fa-chevron-down mr-1"></i>
                    Mostra
                  </span>
                  <span class="btn btn-xs btn-ghost-primary card-btn-toggle-active">
                    <i class="fas fa-chevron-up mr-1"></i>
                    Nascondi
                  </span>
                </span>
              </div>
              <div class="card-body" id="collapseInformazioniSicurezza" [collapse]="isCollapseInformazioniSicurezza"
                [isAnimated]="true">
                <!-- Recovery Email -->
                <div class="row form-group">
                  <div class="col-6">
                    <label class="input-label"><i class="fal fa-envelope"></i> Email
                      alternativa / di recupero</label>
                    <input [(ngModel)]="model.recoveryEmail" class="form-control" name="recoveryEmail"
                      #recoveryEmail="ngModel" type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      placeholder="example@email.com"
                      [ngClass]="(recoveryEmail.dirty || recoveryEmail.touched)? (recoveryEmail.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                      (input)="editDataInputChange()">
                    <span class="invalid-feedback"
                      *ngIf="recoveryEmail.invalid && (recoveryEmail.dirty || recoveryEmail.touched)">Non
                      valido</span>
                    <span class="valid-feedback"
                      *ngIf="recoveryEmail.valid && (recoveryEmail.dirty || recoveryEmail.touched)">Valido</span>
                  </div>

                  <!-- Recovery Phone -->
                  <div class="col-6">
                    <label class="input-label"><i class="fal fa-phone-alt"></i>
                      Numero di
                      telefono di recupero</label>
                    <input [(ngModel)]="model.recoveryPhone" class="form-control" name="recoveryPhone"
                      #recoveryPhone="ngModel" type="text" pattern="^\+[1-9]\d{7,14}$" placeholder="+34832537237"
                      [ngClass]="(recoveryPhone.dirty || recoveryPhone.touched)? (recoveryPhone.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                      (input)="editDataInputChange()">
                    <span class="invalid-feedback"
                      *ngIf="recoveryPhone.invalid && (recoveryPhone.dirty || recoveryPhone.touched)">Non
                      valido</span>
                    <span class="valid-feedback"
                      *ngIf="recoveryPhone.valid && (recoveryPhone.dirty || recoveryPhone.touched)">Valido</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Informazioni di contatto -->
            <div class="card card-hover-shadow mt-3" [ngClass]="{'card-is-loading': _loading.form}">
              <div class="card-header card-btn btn-block pointer"
                (click)="isCollapseInformazioniContatto = !isCollapseInformazioniContatto"
                [ngClass]="{'collapsed': isCollapseInformazioniContatto}">
                <h3 class="card-header-title">Informazioni di contatto</h3>
                <span class="card-btn-toggle" [attr.aria-expanded]="!isCollapseInformazioniContatto"
                  aria-controls="collapseInformazioniContatto">
                  <span class="btn btn-xs btn-ghost-primary card-btn-toggle-default">
                    <i class="fas fa-chevron-down mr-1"></i>
                    Mostra
                  </span>
                  <span class="btn btn-xs btn-ghost-primary card-btn-toggle-active">
                    <i class="fas fa-chevron-up mr-1"></i>
                    Nascondi
                  </span>
                </span>
              </div>
              <div class="card-body" id="collapseInformazioniContatto" [collapse]="isCollapseInformazioniContatto"
                [isAnimated]="true">
                <!-- Email secondaria -->
                <div class="form-group row">
                  <div class="col-6">
                    <label class="input-label"><i class="fal fa-envelope"></i> Email
                      secondaria</label>
                    <input #secondaryEmail="ngModel" name="secondaryEmail" [(ngModel)]="model.secondaryEmail"
                      placeholder="Email..." class="form-control" autocomplete="none" type="text"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      [ngClass]="(secondaryEmail.dirty || secondaryEmail.touched)? (secondaryEmail.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                      (keydown.enter)="onSecondaryEmailOnSelect()" (blur)="onSecondaryEmailOnSelect()">
                    <span class="invalid-feedback"
                      *ngIf="secondaryEmail.invalid && (secondaryEmail.dirty || secondaryEmail.touched)">Non
                      valido</span>
                    <span class="valid-feedback"
                      *ngIf="secondaryEmail.valid && (secondaryEmail.dirty || secondaryEmail.touched)">Valido</span>

                    <blockquote class="blockquote mt-2 mb-2" *ngIf="model.emails.length > 0">
                      <div class="row pt-2" *ngFor="let email of model.emails; let i = index">
                        <div class="col" style="margin: auto;">{{email.address}}
                        </div>
                        <div class="col-3 text-right" style="margin: auto;">
                          <button type="button" class="btn btn-xs btn-outline-danger" (click)="removeSecondaryEmail(i)">
                            <i class="fas fa-trash mr-1"></i>
                            Rimuovi
                          </button>
                        </div>
                      </div>
                    </blockquote>
                  </div>

                  <!-- Telefono secondario -->
                  <div class="col-6">
                    <label class="input-label"><i class="fal fa-phone-alt"></i>
                      Telefono
                      secondario</label>
                    <input #secondaryPhone="ngModel" name="secondaryPhone" [(ngModel)]="model.secondaryPhone"
                      class="form-control" placeholder="+34832537237" autocomplete="none" type="text"
                      pattern="^\+[1-9]\d{7,14}$"
                      [ngClass]="(secondaryPhone.dirty || secondaryPhone.touched)? (secondaryPhone.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                      (keydown.enter)="onSecondaryPhoneOnSelect()" (blur)="onSecondaryPhoneOnSelect()">
                    <span class="invalid-feedback"
                      *ngIf="secondaryPhone.invalid && (secondaryPhone.dirty || secondaryPhone.touched)">Non
                      valido</span>
                    <span class="valid-feedback"
                      *ngIf="secondaryPhone.valid && (secondaryPhone.dirty || secondaryPhone.touched)">Valido</span>
                    <blockquote class="blockquote mt-2 mb-2" *ngIf="model.phones.length > 0">
                      <div class="row pt-2" *ngFor="let phone of model.phones; let i = index">
                        <div class="col" style="margin: auto;">{{phone.value}}
                        </div>
                        <div class="col-3 text-right" style="margin: auto;">
                          <button type="button" class="btn btn-xs btn-outline-danger" (click)="removeSecondaryPhone(i)">
                            <i class="fas fa-trash mr-1"></i>
                            Rimuovi
                          </button>
                        </div>
                      </div>
                    </blockquote>
                  </div>
                </div>

                <!-- Addresses -->
                <div class="form-group row mb-0">
                  <div class="col-12">
                    <label class="input-label"><i class="fal fa-map-marker-alt"></i>
                      Indirizzi</label>
                    <input #address="ngModel" name="address" [(ngModel)]="model.address" placeholder="Indirizzo..."
                      class="form-control" autocomplete="none" type="text"
                      [ngClass]="(address.dirty || address.touched)? (address.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                      (keydown.enter)="onSecondaryAddressOnSelect()" (blur)="onSecondaryAddressOnSelect()">
                    <span class="invalid-feedback" *ngIf="address.invalid && (address.dirty || address.touched)">Non
                      valido</span>
                    <span class="valid-feedback"
                      *ngIf="address.valid && (address.dirty || address.touched)">Valido</span>
                    <blockquote class="blockquote mt-2 mb-2" *ngIf="model.addresses.length > 0">
                      <div class="row pt-2" *ngFor="let address of model.addresses; let i = index">
                        <div class="col" style="margin: auto;">
                          {{address.formatted}}</div>
                        <div class="col-3 text-right" style="margin: auto;">
                          <button type="button" class="btn btn-xs btn-outline-danger"
                            (click)="removeSecondaryAddress(i)">
                            <i class="fas fa-trash mr-1"></i>
                            Rimuovi
                          </button>
                        </div>
                      </div>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <!-- Informazioni del dipendente -->
            <ng-container *ngFor="let organization of model.organizations; let index = index;">
              <ng-container>
                <div class="card card-hover-shadow mt-3" [ngClass]="{'card-is-loading': _loading.form}">
                  <div class="card-header card-btn btn-block pointer"
                    (click)="organization.isCollapsed = !organization.isCollapsed"
                    [ngClass]="{'collapsed': organization.isCollapsed}">
                    <h3 class="card-header-title">Informazioni dell'organizzazione<br>
                      <small>{{ organization.name || organization.title || organization.location ||
                        organization.department || organization.description || "Aggiungi nuove informazioni"}}</small>
                    </h3>
                    <span class="card-btn-toggle" [attr.aria-expanded]="!organization.isCollapsed"
                      aria-controls="collapseInformazioniContatto">
                      <span class="btn btn-xs btn-ghost-primary card-btn-toggle-default">
                        <i class="fas fa-chevron-down mr-1"></i>
                        Mostra
                      </span>
                      <span class="btn btn-xs btn-ghost-primary card-btn-toggle-active">
                        <i class="fas fa-chevron-up mr-1"></i>
                        Nascondi
                      </span>
                    </span>
                  </div>
                  <div class="card-body mb-0" id="collapseInformazioniContatto" [collapse]="organization.isCollapsed"
                    [isAnimated]="true">

                    <div class="form-group row">
                      <div class="col-6">
                        <label class="input-label"> Nome
                          Dipartimento</label>
                        <input #organizationName="ngModel" name="organizationName_{{index}}"
                          [(ngModel)]="organization.name" placeholder="" class="form-control" autocomplete="none"
                          type="text"
                          [ngClass]="(organizationName.dirty || organizationName.touched)? (organizationName.valid ? 'is-valid': 'is-invalid') : 'untouched'">
                        <span class="invalid-feedback"
                          *ngIf="organizationName.invalid && (organizationName.dirty || organizationName.touched)">Non
                          valido</span>
                        <span class="valid-feedback"
                          *ngIf="organizationName.valid && (organizationName.dirty || organizationName.touched)">Valido</span>
                      </div>
                      <div class="col-6">
                        <label class="input-label">
                          Titolo Dipartimento</label>
                        <input #organizationTitle="ngModel" name="organizationTitle_{{index}}"
                          [(ngModel)]="organization.title" placeholder="" class="form-control" autocomplete="none"
                          type="text"
                          [ngClass]="(organizationTitle.dirty || organizationTitle.touched)? (organizationTitle.valid ? 'is-valid': 'is-invalid') : 'untouched'">
                        <span class="invalid-feedback"
                          *ngIf="organizationTitle.invalid && (organizationTitle.dirty || organizationTitle.touched)">Non
                          valido</span>
                        <span class="valid-feedback"
                          *ngIf="organizationTitle.valid && (organizationTitle.dirty || organizationTitle.touched)">Valido</span>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-6">
                        <label class="input-label">
                          Location Dipartimento</label>
                        <input #organizationLocation="ngModel" name="organizationLocation_{{index}}"
                          [(ngModel)]="organization.location" placeholder="" class="form-control" autocomplete="none"
                          type="text"
                          [ngClass]="(organizationLocation.dirty || organizationLocation.touched)? (organizationLocation.valid ? 'is-valid': 'is-invalid') : 'untouched'">
                        <span class="invalid-feedback"
                          *ngIf="organizationLocation.invalid && (organizationLocation.dirty || organizationLocation.touched)">Non
                          valido</span>
                        <span class="valid-feedback"
                          *ngIf="organizationLocation.valid && (organizationLocation.dirty || organizationLocation.touched)">Valido</span>
                      </div>
                      <div class="col-6">
                        <label class="input-label">
                          Dipartimento</label>
                        <input #organizationDepartment="ngModel" name="organizationDepartment_{{index}}"
                          [(ngModel)]="organization.department" placeholder="" class="form-control" autocomplete="none"
                          type="text"
                          [ngClass]="(organizationDepartment.dirty || organizationDepartment.touched)? (organizationDepartment.valid ? 'is-valid': 'is-invalid') : 'untouched'">
                        <span class="invalid-feedback"
                          *ngIf="organizationDepartment.invalid && (organizationDepartment.dirty || organizationDepartment.touched)">Non
                          valido</span>
                        <span class="valid-feedback"
                          *ngIf="organizationDepartment.valid && (organizationDepartment.dirty || organizationDepartment.touched)">Valido</span>
                      </div>
                    </div>

                    <div class="form-group row mb-0">
                      <div class="col-12">
                        <label class="input-label">
                          Descrizione</label>
                        <input #organizationDescription="ngModel" name="organizationDescription_{{index}}"
                          [(ngModel)]="organization.description" placeholder="" class="form-control" autocomplete="none"
                          type="text"
                          [ngClass]="(organizationDescription.dirty || organizationDescription.touched)? (organizationDescription.valid ? 'is-valid': 'is-invalid') : 'untouched'">
                        <span class="invalid-feedback"
                          *ngIf="organizationDescription.invalid && (organizationDescription.dirty || organizationDescription.touched)">Non
                          valido</span>
                        <span class="valid-feedback"
                          *ngIf="organizationDescription.valid && (organizationDescription.dirty || organizationDescription.touched)">Valido</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>

          </ng-container>

        </form>

        <!-- Se Docente o Studente Struttura Scolastica Utente -->
        <ng-container *ngIf="model.role && model.role != 'worker'">

          <ng-container *ngIf="model.plessi && model.plessi.length > 0 && !formEnable.plesso">
            <ng-container *ngFor="let plesso of model.plessi">
              <div class="card card-hover-shadow mt-5" *ngIf="plesso.plesso.id != tempData?.plesso?.id">
                <div class="card-body">
                  <div class="row justify-content-between align-items-center">
                    <!-- Left -->
                    <div class="col-auto">
                      <h1 class="mb-0">
                        <i class="fad fa-school mr-2 blue"></i>{{plesso.plesso.name}}
                      </h1>
                    </div>
                    <!-- Right -->
                    <div class="col-auto">
                      <button type="button" class="btn btn-outline-primary btn-sm mr-2"
                        (click)="editModeFormPlesso(plesso.plesso.id)">
                        <i class="fas fa-edit mr-2"></i>{{ 'BTN.Modifica' | translate }}
                      </button>

                      <button type="button" class="btn btn-outline-danger btn-sm mr-2"
                        (click)="removePlessoStruct(plesso.plesso.id)">
                        <i class="fas fa-trash mr-2"></i>{{ 'BTN.Cancella' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

          </ng-container>

          <ng-container *ngIf="formEnable.plesso">
            <div class="card card-hover-shadow mt-5">
              <div class="card-body">
                <div class="row">
                  <!-- Left -->
                  <div class="col-7">
                    <div class="row">
                      <div class="col-12">
                        <h1 *ngIf="!tempData.plesso"><i class="fad fa-school mr-2"></i>Seleziona Plesso</h1>
                        <h1 *ngIf="tempData.plesso"><i class="fad fa-school mr-2 blue"></i>{{tempData.plesso.name}}
                        </h1>
                      </div>
                    </div>

                    <!-- Selezionamento Vuoto not Preselected -->
                    <app-gestione-scolastica-user (onChange)="onChangePlessoClassiSelectedData($event)"
                      [materieSelectionClassi]="model.role == 'docente' ? true : false"
                      [multiSelectionClassi]="model.role == 'docente' ? true : false"
                      [multiInsert]="model.role == 'docente' ? true : false" [idPlessiToExclude]="idPlessiToExclude"
                      [classiPreSelected]="tempData?.classi" [idPlessoPreSelected]="tempData?.plesso?.id"
                      [materieClassiPreSelected]="tempData?.materieClassi">
                    </app-gestione-scolastica-user>
                  </div>

                  <!-- Right -->
                  <div class="col-5 p-3 bg-light rounded-lg">
                    <!-- Titlte -->
                    <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Plesso</h4>

                    <div class="row" *ngIf="!tempData.plesso; else elseBlock">
                      <div class="col-12">
                        <div class="alert alert-soft-info" role="alert">
                          Qui visualizzerai la struttura associata
                        </div>
                      </div>
                    </div>
                    <ng-template #elseBlock>
                      <ng-container *ngIf="tempData.plesso">
                        <div class="row">
                          <div class="col-12">
                            <div class="alert alert-soft-info" role="alert"
                              *ngIf="(tempData.classi && tempData.classi.length > 0) || (tempData.materieClassi && tempData.materieClassi.length > 0)">
                              Verrà aggiunto nel Plesso
                              <b class="text-cap"> {{tempData.plesso.name}} </b>
                              <ng-container *ngIf="tempData.classi && tempData.classi.length > 0">
                                <span *ngIf="tempData.classi.length == 1">e nella
                                  Classe</span>
                                <span *ngIf="tempData.classi.length > 1">e nelle
                                  Classi</span>
                                <b class="text-cap"> {{ _mapAndJoinView(tempData.classi,
                                  'name') }} </b>
                              </ng-container>
                              <ng-container *ngIf="tempData.materieClassi && tempData.materieClassi.length > 0">
                                e
                                assegante
                                le
                                Materie nelle relative Classi
                                <b class="text-cap" *ngFor="let strutturaMat of tempData.materieClassi">
                                  {{
                                  _mapAndJoinViewMaterie(strutturaMat, 'name') }} </b>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-template>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <div class="row justify-content-between align-items-center">
                  <div class="col-auto py-1">
                    <button type="button" class="btn btn-outline-danger"
                      (click)="annullaFormPlesso()">
                      <i class="far fa-backspace mr-2"></i>
                      Annulla
                    </button>
                  </div>

                  <div class="col-6 py-1">
                    <div class="row justify-content-end">
                      <div class="col-auto">
                        <button type="button" class="btn btn-primary"
                          [disabled]="!tempData.plesso || !tempData.classi || tempData.classi.length == 0"
                          (click)="savePlesso()">
                          Salva
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <a class="card card-hover-shadow card-dashed h-100 mt-5"
            *ngIf="!formEnable.plesso && ((model.role == 'docente' && !tempData.plesso) || (model.role == 'studente' && !tempData.plesso && (!model.plessi || model.plessi.length == 0)))"
            (click)="enableFormPlesso()">
            <div class="card-body card-body-centered card-dashed-body">
              <i class="fad fa-school mb-2 blue" style="font-size: 4rem;"></i>
              <span class="text-primary text-hover-primary">
                <i class="fa-solid fa-plus mr-1"></i> Associa ad un nuovo Plesso
              </span>
            </div>
          </a>
        </ng-container>

        <!-- Salva e Annulla -->
        <div class="card card-hover-shadow mt-3" [ngClass]="{'card-is-loading': _loading.form}">
          <div class="card-footer">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto py-1">
                <button type="button" class="btn btn-outline-danger" (click)="back()">
                  <i class="far fa-backspace mr-2"></i>
                  Annulla
                </button>
              </div>

              <div class="col-6 py-1">
                <div class="row justify-content-end">
                  <div class="col-auto">
                    <button type="button" class="btn" [disabled]="!myForm.valid" (click)="onSubmit()"
                      [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">Salva
                      <i class="far fa-chevron-right ml-1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


</ng-container>