<div class="row mb-5">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body m-2">
        <app-setup-wizard-steps [currentStep]="2"></app-setup-wizard-steps>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <!-- Card -->
    <div class="card card-hover-shadow">

      <div class="card-header p-1">
        <div class="row justify-content-between align-items-center flex-grow-1">
          <div class="col">
            <tabset #tabset [justified]="true" type="pills">
              <tab #tabPlesso="tab" [disabled]="userTypeSelected == 'worker'">
                <ng-template tabHeading>
                  <i class="fas fa-school nav-icon"></i> Plesso e classe
                </ng-template>
              </tab>
              <tab #tabOrganigramma="tab" [active]="userTypeSelected == 'worker'">
                <ng-template tabHeading>
                  <i class="fas fa-sitemap nav-icon"></i> Organigramma
                </ng-template>
              </tab>
            </tabset>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="row" *ngIf="fileName && uploadedFileCompleted">
          <div class="col">
            <ul class="list-group">
              <!-- List Item -->
              <li class="list-group-item">
                <div class="row align-items-center gx-2">
                  <div class="col-auto">
                    <img class="avatar avatar-xs avatar-4by3" src="./assets/theme/svg/brands/google-sheets.svg">
                  </div>

                  <div class="col">
                    <h5 class="mb-0">
                      <a class="text-dark" href="#">{{ fileName }}</a>
                    </h5>
                    <ul class="list-inline list-separator small">
                      <li class="list-inline-item">Ultima modifica: {{ fileLastModified | date }}</li>
                      <li class="list-inline-item">Dimensione file: {{ fileSize }}</li>
                    </ul>
                  </div>

                  <div class="col-auto">
                    <button class="btn btn-sm btn-outline-danger" (click)="removedFile()">
                      <i class="far fa-trash mr-2"></i>{{ 'BTN.Cancella' | translate }}
                    </button>
                  </div>
                </div>
                <!-- End Row -->
              </li>
              <!-- End List Item -->
            </ul>
          </div>
        </div>

        <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" validate>

          <!--
          .########..##.......########..######...######...#######.
          .##.....##.##.......##.......##....##.##....##.##.....##
          .##.....##.##.......##.......##.......##.......##.....##
          .########..##.......######....######...######..##.....##
          .##........##.......##.............##.......##.##.....##
          .##........##.......##.......##....##.##....##.##.....##
          .##........########.########..######...######...#######.
          -->

          <div class="row mt-5 mb-2" [hidden]="!tabPlesso.active">
            <div class="col-6">
              <h2>{{'SETUP.plessoclasse.titolo_plesso' | translate}}</h2>
            </div>
          </div>

          <div class="row mt-5 mb-2" [hidden]="!tabOrganigramma.active">
            <div class="col-6">
              <h2>{{'SETUP.plessoclasse.titolo_organigramma' | translate}}</h2>
            </div>
          </div>

          <div class="form-group row mb-2" [hidden]="!tabPlesso.active">
            <div class="col">
              <label class="input-label" for="selectExistingPlesso">{{'SETUP.plessoclasse.label_plesso' | translate}}
                <button *ngIf="selectedPlesso !== null && selectedPlesso >= 0"
                  class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1" (click)="selectedPlesso = null"><i
                    class="fas fa-backspace"></i></button>
              </label>
              <select id="selectExistingPlesso" name="selectExistingPlesso" class="form-control custom-select"
                [(ngModel)]="selectedPlesso" #existingPlesso="ngModel" (change)="onSelectedPlessoChange()"
                [disabled]="paramCreaPlessoDefault || selectedOrganigramma"
                [ngClass]="existingPlesso.enabled ? (existingPlesso.valid ? 'is-valid': 'is-invalid') : ''" required>
                <option *ngFor="let val of plessi; let i = index;" [value]="val.id">{{val.name}}</option>
              </select>
              <div class="valid-feedback"
                *ngIf="existingPlesso.valid && (existingPlesso.dirty || existingPlesso.touched)">Valido</div>

              <label class="mt-3 toggle-switch d-flex" for="paramCreaPlessoDefaultSwitch">
                <input id="paramCreaPlessoDefaultSwitch" [(ngModel)]="paramCreaPlessoDefault"
                  [disabled]="selectedOrganigramma" name="paramCreaPlessoDefaultSwitch" type="checkbox"
                  class="toggle-switch-input" (change)="onSelectParamCreaPlessoDefault()">
                <span class="toggle-switch-label">
                  <span class="toggle-switch-indicator"></span>
                </span>
                <span class="toggle-switch-content">
                  <span class="d-block">{{'STR.seleziona_da_file' | translate}}</span>
                </span>
              </label>
            </div>
            <div class="col-7">
              <div class="alert alert-soft-warning" role="alert" *ngIf="selectedOrganigramma">
                <b class="text-cap">Attenzione</b>
                <div [innerHTML]="'SETUP.plessoclasse.msg_warning_plesso_disabilitato' | translate"></div>
                <button class="btn btn-outline-danger btn-xs mt-3" (click)="resetTabOrganigramma()">
                  <i class="fas fa-backspace mr-2"></i>Cancella
                </button>
              </div>
            </div>
          </div>

          <div class="form-group row mb-2" [hidden]="!tabOrganigramma.active">
            <div class="col-6">

              <label class="input-label" for="selectExistingOrganigramma">{{'SETUP.plessoclasse.label_organigramma' |
                translate}}
                <button *ngIf="selectedOrganigramma !== null && selectedOrganigramma >= 0"
                  class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1" (click)="selectedOrganigramma = null"><i
                    class="fas fa-backspace"></i></button>
              </label>

              <input type="hidden" id="selectExistingOrganigramma" name="selectExistingOrganigramma"
                [(ngModel)]="selectedOrganigramma" #existingOrganigramma="ngModel"
                [disabled]="selectedPlesso || paramCreaPlessoDefault" required>

              <app-custom-dropdown-treeview [items]="organigrammiTreeView"
                (selectedChange)="onSelectedOrganigrammaChange($event)" [(value)]="selectedOrganigramma"
                [configs]="configTreeView">
              </app-custom-dropdown-treeview>

              <p *ngIf="selectedOrganigrammaInfo && selectedOrganigrammaInfo.google_org_unit" class="mt-3"
                [innerHtml]="'SETUP.plessoclasse.sposta_in_orgunit_organigramma_info' | translate"></p>

              <p *ngIf="selectedOrganigrammaInfo && selectedOrganigrammaInfo.google_org_unit && userTypeSelected != 'worker'"
                class="mt-3" [innerHtml]="'SETUP.plessoclasse.sposta_in_orgunit_organigramma_warning' | translate"></p>

              <label *ngIf="selectedOrganigrammaInfo && selectedOrganigrammaInfo.google_org_unit"
                class="mt-3 toggle-switch d-flex" for="paramSpostaInOrgUnitOrganigrammaSwitch">
                <input id="paramSpostaInOrgUnitOrganigrammaSwitch" [(ngModel)]="paramSpostaInOrgUnitOrganigramma"
                  name="paramSpostaInOrgUnitOrganigrammaSwitch" type="checkbox" class="toggle-switch-input"
                  (change)="onSelectParamSpostaInOrgUnitOrganigramma()">
                <span class="toggle-switch-label">
                  <span class="toggle-switch-indicator"></span>
                </span>
                <span class="toggle-switch-content">
                  <span class="d-block"
                    [innerHtml]="'SETUP.plessoclasse.sposta_in_orgunit_organigramma' | translate"></span>
                </span>
              </label>

              <div class="valid-feedback" *ngIf="existingOrganigramma.valid">Valido</div>
            </div>
            <div class="col">
              <div class="alert alert-soft-warning" role="alert" *ngIf="selectedPlesso || paramCreaPlessoDefault">
                <b class="text-cap">Attenzione</b>
                <div [innerHTML]="'SETUP.plessoclasse.msg_warning_organigramma_disabilitato' | translate"></div>
              </div>
            </div>
          </div>

          <div class="form-group row mt-5" *ngIf="paramCreaPlessoDefault && tabPlesso.active">
            <div class="col">
              <label class="input-label" for="selectPlessoNameCol">{{'SETUP.plessoclasse.label_colonna_plesso' |
                translate}}
                <button *ngIf="plessoNameColIndex !== null && plessoNameColIndex >= 0"
                  class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1" (click)="plessoNameColIndex = null"><i
                    class="fas fa-backspace"></i></button>
              </label>
              <select id="selectPlessoNameCol" name="selectPlessoNameCol" class="form-control custom-select"
                [(ngModel)]="plessoNameColIndex" #plessoNameCol="ngModel"
                [ngClass]="plessoNameCol.valid ? 'is-valid': 'is-invalid'" required>
                <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
              </select>
              <div class="valid-feedback" *ngIf="plessoNameCol.valid && (plessoNameCol.dirty || plessoNameCol.touched)">
                Valido</div>

              <!-- Split Plesso Col -->
              <div class="row mt-2">
                <div class="col">
                  <label class="mt-5 toggle-switch d-flex" for="paramSplitPlessoColSwitch">
                    <input id="paramSplitPlessoColSwitch" [(ngModel)]="paramSplitPlessoCol"
                      name="paramSplitPlessoColSwitch" type="checkbox" class="toggle-switch-input">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">{{'STR.split' | translate}}</span>
                    </span>
                  </label>
                </div>

                <div class="form-group col" *ngIf="paramSplitPlessoCol">
                  <label class="input-label">Carattere</label>
                  <input [(ngModel)]="paramSplitCharPlessoCol" class="form-control form-control-sm"
                    name="paramSplitCharPlessoColInput" #paramSplitCharPlessoColInput="ngModel" type="text"
                    [ngClass]="paramSplitCharPlessoColInput.valid ? 'is-valid': 'is-invalid'" required
                    autocomplete="none">
                  <span class="invalid-feedback" *ngIf="paramSplitCharPlessoColInput.invalid">Non valido</span>
                  <span class="valid-feedback" *ngIf="paramSplitCharPlessoColInput.valid">Valido</span>
                </div>

                <div class="form-group col" *ngIf="paramSplitPlessoCol">
                  <label class="input-label">Posizione</label>
                  <input [(ngModel)]="paramSplitIndexPlessoCol" class="form-control form-control-sm"
                    name="paramSplitIndexPlessoColInput" #paramSplitIndexPlessoColInput="ngModel" type="number"
                    [ngClass]="paramSplitIndexPlessoColInput.valid ? 'is-valid': 'is-invalid'" required min="0"
                    autocomplete="none">
                  <span class="invalid-feedback" *ngIf="paramSplitIndexPlessoColInput.invalid">Non valido</span>
                  <span class="valid-feedback" *ngIf="paramSplitIndexPlessoColInput.valid">Valido</span>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col">
                  <small [innerHtml]="'SETUP.plessoclasse.split_info' | translate">
                  </small>
                </div>
              </div>

            </div>
            <div class="col-7">
              <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex" [colIndex]="plessoNameColIndex"
                [split]="paramSplitPlessoCol" [splitChar]="paramSplitCharPlessoCol"
                [splitPos]="paramSplitIndexPlessoCol-1"></app-preview-column-data>
            </div>
          </div>

          <div class="form-group row mt-5" *ngIf="paramCreaPlessoDefault && tabPlesso.active">
            <div class="col">


              <label class="input-label" for="selectPlessoShortNameCol">
                <label class="toggle-switch toggle-switch-sm d-flex" for="enableShortNamePlessoFieldSwitch">
                  <input id="enableShortNamePlessoFieldSwitch" [(ngModel)]="enableShortNamePlessoField"
                    name="enableShortNamePlessoFieldSwitch" type="checkbox" class="toggle-switch-input">
                  <span class="toggle-switch-label">
                    <span class="toggle-switch-indicator"></span>
                  </span>
                  <span class="toggle-switch-content">
                    <span class="d-block">{{'SETUP.plessoclasse.label_colonna_plesso_short_name' | translate}}
                      <button *ngIf="plessoShortNameColIndex !== null && plessoShortNameColIndex >= 0"
                        class="ml-1 btn btn-ghost-danger btn-xs float-right" (click)="plessoShortNameColIndex = null"><i
                          class="fas fa-backspace"></i></button>
                    </span>
                  </span>
                </label>
              </label>
              <select [hidden]="!enableShortNamePlessoField" id="selectPlessoShortNameCol"
                name="selectPlessoShortNameCol" class="form-control custom-select" [(ngModel)]="plessoShortNameColIndex"
                #plessoShortNameCol="ngModel" [ngClass]="plessoShortNameColIndex ? 'is-valid': ''">
                <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
              </select>
              <div [hidden]="!enableShortNamePlessoField" class="text-muted font-size-1 mt-2">
                {{'SETUP.plessoclasse.msg_plesso_short_name' | translate}}</div>
              <div class="valid-feedback"
                *ngIf="plessoShortNameColIndex && (plessoShortNameCol.dirty || plessoShortNameCol.touched)">
                Valido</div>
            </div>
            <div class="col-7">
              <ng-container *ngIf="enableShortNamePlessoField">
                <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex"
                  [colIndex]="plessoShortNameColIndex"></app-preview-column-data>
              </ng-container>
            </div>
          </div>

          <!--
          ..######..##..........###.....######...######..########
          .##....##.##.........##.##...##....##.##....##.##......
          .##.......##........##...##..##.......##.......##......
          .##.......##.......##.....##..######...######..######..
          .##.......##.......#########.......##.......##.##......
          .##....##.##.......##.....##.##....##.##....##.##......
          ..######..########.##.....##..######...######..########
          -->

          <ng-container
            *ngIf="(existingPlesso.valid || (paramCreaPlessoDefault && plessoNameColIndex)) && tabPlesso.active">

            <div class="row mt-5 mb-2">
              <div class="col">
                <h2>{{'SETUP.plessoclasse.titolo_classe' | translate}}</h2>
              </div>
            </div>

            <div class="form-group row mb-2">
              <div class="col">
                <label class="input-label" for="selectExistingClasse">{{'SETUP.plessoclasse.label_classe' | translate}}
                  <button *ngIf="selectedClasse !== null && selectedClasse >= 0"
                    class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1" (click)="selectedClasse = null"><i
                      class="fas fa-backspace"></i></button>
                </label>
                <select id="selectExistingClasse" name="selectExistingClasse" class="form-control custom-select"
                  [(ngModel)]="selectedClasse" #existingClasse="ngModel" [disabled]="paramCreaClassiDefault"
                  [ngClass]="existingClasse.enabled ? (existingClasse.valid ? 'is-valid': 'is-invalid') : ''" required>
                  <option *ngFor="let val of classi; let i = index;" [value]="val.id">{{val.name}}</option>
                </select>
                <div class="valid-feedback"
                  *ngIf="existingClasse.valid && (existingClasse.dirty || existingClasse.touched)">Valido</div>

                <label class="mt-3 toggle-switch d-flex" for="paramCreaClassiDefaultSwitch">
                  <input id="paramCreaClassiDefaultSwitch" [disabled]="paramCreaPlessoDefault"
                    [(ngModel)]="paramCreaClassiDefault" name="paramCreaClassiDefaultSwitch" type="checkbox"
                    class="toggle-switch-input" (change)="onSelectParamCreaClassiDefault()">
                  <span class="toggle-switch-label">
                    <span class="toggle-switch-indicator"></span>
                  </span>
                  <span class="toggle-switch-content">
                    <span class="d-block">{{'STR.seleziona_da_file' | translate}}</span>
                  </span>
                </label>
              </div>
              <div class="col-7">
                <!-- <div class="alert alert-soft-info" role="alert">
                  <b class="text-cap">Info</b>
                  Se il file contiene le informazioni sulle classi allora selezione questa opzione per crearle o
                  associarle, nel caso in cui esistano già, automaticamente
                </div> -->
              </div>
            </div>

            <ng-container *ngIf="paramCreaClassiDefault">
              <div class="form-group row mt-5"
                *ngFor="let classeNameColIndexElement of classeNameColIndexArray; let index1 = index; let isFirst = first; let isLast = last;">
                <ng-container>
                  <div class="col">
                    <label class="input-label"
                      for="selectClasseNameCol{{index1}}">{{'SETUP.plessoclasse.label_colonna_classe' | translate}}
                      <button
                        *ngIf="classeNameColIndexElement.classeNameColIndex !== null && classeNameColIndexElement.classeNameColIndex >= 0"
                        class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                        (click)="classeNameColIndexElement.classeNameColIndex = null"><i
                          class="fas fa-backspace"></i></button>
                    </label>
                    <select id="selectClasseNameCol{{index1}}" name="selectClasseNameCol{{index1}}"
                      class="form-control custom-select" [(ngModel)]="classeNameColIndexElement.classeNameColIndex"
                      #classeNameCol="ngModel" [ngClass]="classeNameCol.valid ? 'is-valid': 'is-invalid'" required>
                      <option *ngFor="let val of headerRow; let index2 = index;" [value]="index2">{{val}}</option>
                    </select>
                    <div class="valid-feedback"
                      *ngIf="classeNameCol.valid && (classeNameCol.dirty || classeNameCol.touched)">Valido</div>

                    <!-- Split Classe Col -->
                    <div class="row mt-2" *ngIf="classeNameColIndexArray.length == 1">
                      <div class="col">
                        <label class="mt-5 toggle-switch d-flex" for="paramSplitClasseColSwitch">
                          <input id="paramSplitClasseColSwitch" [(ngModel)]="paramSplitClasseCol"
                            name="paramSplitClasseColSwitch" type="checkbox" class="toggle-switch-input">
                          <span class="toggle-switch-label">
                            <span class="toggle-switch-indicator"></span>
                          </span>
                          <span class="toggle-switch-content">
                            <span class="d-block">{{'STR.split' | translate}}</span>
                          </span>
                        </label>
                      </div>

                      <div class="form-group col" *ngIf="paramSplitClasseCol">
                        <label class="input-label">Carattere</label>
                        <input [(ngModel)]="paramSplitCharClasseCol" class="form-control form-control-sm"
                          name="paramSplitCharClasseColInput" #paramSplitCharClasseColInput="ngModel" type="text"
                          [ngClass]="paramSplitCharClasseColInput.valid ? 'is-valid': 'is-invalid'" required
                          autocomplete="none">
                        <span class="invalid-feedback" *ngIf="paramSplitCharClasseColInput.invalid">Non valido</span>
                        <span class="valid-feedback" *ngIf="paramSplitCharClasseColInput.valid">Valido</span>
                      </div>

                      <div class="form-group col" *ngIf="paramSplitClasseCol">
                        <label class="input-label">Posizione</label>
                        <input [(ngModel)]="paramSplitIndexClasseCol" class="form-control form-control-sm"
                          name="paramSplitIndexClasseColInput" #paramSplitIndexClasseColInput="ngModel" type="number"
                          [ngClass]="paramSplitIndexClasseColInput.valid ? 'is-valid': 'is-invalid'" required min="0"
                          autocomplete="none">
                        <span class="invalid-feedback" *ngIf="paramSplitIndexClasseColInput.invalid">Non valido</span>
                        <span class="valid-feedback" *ngIf="paramSplitIndexClasseColInput.valid">Valido</span>
                      </div>
                    </div>


                    <ng-container *ngIf="!paramSplitClasseCol">
                      <button type="button" class="btn btn-outline-primary btn-xs mt-2 mr-2"
                        (click)="addClasseCol(index1)"><i class="fa-solid fa-plus mr-1"></i>
                        {{'SETUP.plessoclasse.label_add_colonna_sezione' | translate}}</button>
                      <button type="button" *ngIf="!isFirst" class="btn btn-outline-danger btn-xs mt-2"
                        (click)="removeClasseCol(index1)"><i class="tio-backspace"></i>
                        {{'SETUP.plessoclasse.label_rimuovi_colonna' | translate}}</button>
                      <div class="text-muted font-size-1 mt-2">{{'SETUP.plessoclasse.msg_classe_multipla' | translate}}
                      </div>
                    </ng-container>

                  </div>
                  <div class="col-7" *ngIf="classeNameColIndexArray.length == 1">
                    <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex"
                      [colIndex]="classeNameColIndexElement.classeNameColIndex" [split]="paramSplitClasseCol"
                      [splitChar]="paramSplitCharClasseCol" [splitPos]="paramSplitIndexClasseCol-1">
                    </app-preview-column-data>
                  </div>
                  <div class="col-7" *ngIf="classeNameColIndexArray.length != 1">
                    <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex"
                      [colIndex]="classeNameColIndexElement.classeNameColIndex"></app-preview-column-data>
                  </div>
                </ng-container>
              </div>




              <div class="row mt-3 mb-2" [hidden]="!tabPlesso.active">
                <div class="col">
                  <h3>{{'SETUP.plessoclasse.materia' | translate}}</h3>
                </div>
              </div>

              <div class="form-group row mb-2" [hidden]="!tabPlesso.active">
                <div class="col">
                  <label class="input-label" for="selectExistingMateria">{{'SETUP.plessoclasse.label_materia' | translate}}
                    <button *ngIf="selectedMateria !== null && selectedMateria >= 0"
                      class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1" (click)="selectedMateria = null"><i
                        class="fas fa-backspace"></i></button>
                  </label>
                  <select id="selectExistingMateria" name="selectExistingMateria" class="form-control custom-select"
                    [(ngModel)]="selectedMateria" #existingMateria="ngModel" (change)="onSelectedMateriaChange()"
                    [disabled]="paramAssociaMateria || selectedOrganigramma"
                    [ngClass]="existingMateria.enabled && selectedMateria >= 0 ? (existingMateria.valid ? 'is-valid': 'is-invalid') : ''">
                    <option *ngFor="let val of materie; let i = index;" [value]="val.id">{{val.name}}</option>
                  </select>
                  <div class="valid-feedback"
                    *ngIf="existingMateria.valid && (existingMateria.dirty || existingMateria.touched)">Valido</div>
                </div>
                <div class="col-7">
                  <div class="alert alert-soft-warning" role="alert" *ngIf="(selectedMateria !== null && selectedMateria >= 0) || paramAssociaMateria">
                    <b class="text-cap">Attenzione</b>
                    <span [innerHTML]="'SETUP.plessoclasse.materia_msg' | translate"></span>
                  </div>
                </div>
              </div>

              <div class="form-group row mb-2" [hidden]="!tabPlesso.active">
                <div class="col">

                  <label class="mb-3 toggle-switch d-flex" for="paramAssociaMateriaSwitch">
                    <input id="paramAssociaMateriaSwitch" [(ngModel)]="paramAssociaMateria"
                      name="paramAssociaMateriaSwitch" type="checkbox" class="toggle-switch-input"
                      (change)="onSelectAssociaMateria()">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">{{'STR.seleziona_da_file' | translate}}</span>
                    </span>
                  </label>

                  <ng-container *ngIf="paramAssociaMateria">
                    <label class="input-label" for="selectMateriaCol">{{'SETUP.plessoclasse.label_colonna_materia' |
                      translate}}
                      <button *ngIf="materiaColIndex !== null && materiaColIndex >= 0"
                        class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1" (click)="materiaColIndex = null"><i
                          class="fas fa-backspace"></i></button>
                    </label>
                    <select id="selectMateriaCol" name="selectMateriaCol" class="form-control custom-select"
                      [(ngModel)]="materiaColIndex" #materiaCol="ngModel" [ngClass]="materiaColIndex ? 'is-valid': ''">
                      <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
                    </select>
                    <div class="valid-feedback" *ngIf="materiaColIndex && (materiaCol.dirty || materiaCol.touched)">
                      Valido</div>
                  </ng-container>

                  <!-- Split Materia Col -->
                  <div class="row mt-2" [hidden]="!paramAssociaMateria">
                    <div class="col">
                      <label class="mt-5 toggle-switch d-flex" for="paramSplitMateriaColSwitch">
                        <input id="paramSplitMateriaColSwitch" [(ngModel)]="paramSplitMateriaCol"
                          name="paramSplitMateriaColSwitch" type="checkbox" class="toggle-switch-input">
                        <span class="toggle-switch-label">
                          <span class="toggle-switch-indicator"></span>
                        </span>
                        <span class="toggle-switch-content">
                          <span class="d-block">{{'STR.split' | translate}}</span>
                        </span>
                      </label>
                    </div>

                    <div class="form-group col" *ngIf="paramSplitMateriaCol">
                      <label class="input-label">Carattere</label>
                      <input [(ngModel)]="paramSplitCharMateriaCol" class="form-control form-control-sm"
                        name="paramSplitCharMateriaColInput" #paramSplitCharMateriaColInput="ngModel" type="text"
                        [ngClass]="paramSplitCharMateriaColInput.valid ? 'is-valid': 'is-invalid'" required
                        autocomplete="none">
                      <span class="invalid-feedback" *ngIf="paramSplitCharMateriaColInput.invalid">Non valido</span>
                      <span class="valid-feedback" *ngIf="paramSplitCharMateriaColInput.valid">Valido</span>
                    </div>

                    <!-- <div class="form-group col" *ngIf="paramSplitMateriaCol">
                      <label class="input-label">Posizione</label>
                      <input [(ngModel)]="paramSplitIndexMateriaCol" class="form-control form-control-sm"
                        name="paramSplitIndexMateriaColInput" #paramSplitIndexMateriaColInput="ngModel" type="number"
                        [ngClass]="paramSplitIndexMateriaColInput.valid ? 'is-valid': 'is-invalid'" required min="0"
                        autocomplete="none">
                      <span class="invalid-feedback" *ngIf="paramSplitIndexMateriaColInput.invalid">Non valido</span>
                      <span class="valid-feedback" *ngIf="paramSplitIndexMateriaColInput.valid">Valido</span>
                    </div> -->
                  </div>

                  <div class="row mt-2" [hidden]="!paramAssociaMateria">
                    <div class="col">
                      <small [innerHtml]="'SETUP.plessoclasse.split_info_materia' | translate">
                      </small>
                    </div>
                  </div>

                </div>
                <div class="col-7 pt-5 mt-3" *ngIf="paramAssociaMateria">
                  <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex" [colIndex]="materiaColIndex" [split]="paramSplitMateriaCol" [splitChar]="paramSplitCharMateriaCol" [splitPos]="-1">
                  </app-preview-column-data>
                </div>
              </div>



              <!--
            ..#######..########...######...##.....##.##....##.####.########
            .##.....##.##.....##.##....##..##.....##.###...##..##.....##...
            .##.....##.##.....##.##........##.....##.####..##..##.....##...
            .##.....##.########..##...####.##.....##.##.##.##..##.....##...
            .##.....##.##...##...##....##..##.....##.##..####..##.....##...
            .##.....##.##....##..##....##..##.....##.##...###..##.....##...
            ..#######..##.....##..######....#######..##....##.####....##...
            -->

              <div class="row mt-3 mb-2">
                <div class="col">
                  <h3>{{'SETUP.plessoclasse.unita_organizzativa' | translate}}</h3>
                </div>
              </div>

              <div class="form-group row mb-2">
                <div class="col">

                  <label class="mb-3 toggle-switch d-flex" for="paramAssociaUnitaOrganizzativeSwitch">
                    <input id="paramAssociaUnitaOrganizzativeSwitch" [(ngModel)]="paramAssociaOrgunitDefault"
                      name="paramAssociaUnitaOrganizzativeSwitch" type="checkbox" class="toggle-switch-input"
                      (change)="onSelectAssociaUnitaOrganizzativa()">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">{{'STR.seleziona_da_file' | translate}}</span>
                    </span>
                  </label>

                  <ng-container *ngIf="paramAssociaOrgunitDefault">
                    <label class="input-label" for="selectOrgUnitCol">{{'SETUP.plessoclasse.label_colonna_orgunit' |
                      translate}}
                      <button *ngIf="orgUnitNameColIndex !== null && orgUnitNameColIndex >= 0"
                        class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                        (click)="orgUnitNameColIndex = null"><i class="fas fa-backspace"></i></button>
                    </label>
                    <select id="selectOrgUnitCol" name="selectOrgUnitCol" class="form-control custom-select"
                      [(ngModel)]="orgUnitNameColIndex" #orgunitCol="ngModel"
                      [ngClass]="orgUnitNameColIndex ? 'is-valid': ''">
                      <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
                    </select>
                    <div class="valid-feedback" *ngIf="orgUnitNameColIndex && (orgunitCol.dirty || orgunitCol.touched)">
                      Valido</div>
                  </ng-container>
                </div>
                <div class="col-7 pt-5 mt-3" *ngIf="paramAssociaOrgunitDefault">
                  <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex"
                    [colIndex]="orgUnitNameColIndex"></app-preview-column-data>
                </div>
              </div>

              <!--
            ..######...########..##.....##.########..########..####
            .##....##..##.....##.##.....##.##.....##.##.....##..##.
            .##........##.....##.##.....##.##.....##.##.....##..##.
            .##...####.########..##.....##.########..########...##.
            .##....##..##...##...##.....##.##........##.........##.
            .##....##..##....##..##.....##.##........##.........##.
            ..######...##.....##..#######..##........##........####
            -->

              <div class="row mt-3 mb-2">
                <div class="col">
                  <h3>{{'SETUP.plessoclasse.gruppi' | translate}}</h3>
                </div>
              </div>

              <div class="form-group row mb-2">
                <div class="col">

                  <label class="mb-3 toggle-switch d-flex" for="paramAssociaGruppiSwitch">
                    <input id="paramAssociaGruppiSwitch" [(ngModel)]="paramAssociaGruppiDefault"
                      name="paramAssociaGruppiSwitch" type="checkbox" class="toggle-switch-input"
                      (change)="onSelectAssociaGruppi()">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">{{'STR.seleziona_da_file' | translate}}</span>
                    </span>
                  </label>
                </div>
                <div class="col-7">
                </div>
              </div>

              <div class="row" *ngIf="paramAssociaGruppiDefault || userTypeSelected != 'studente'">
                <div class="col">
                  <h4>{{'SETUP.plessoclasse.gruppo_studenti' | translate}}</h4>
                </div>
              </div>

              <div class="form-group row mb-2" *ngIf="userTypeSelected != 'studente'">
                <div class="col">
                  <label class="toggle-switch d-flex" for="paramGruppoStudentiCasseAutomaticSwitch">
                    <input id="paramGruppoStudentiCasseAutomaticSwitch" [(ngModel)]="gruppoStudentiClasseAutomatic"
                      name="paramGruppoStudentiCasseAutomaticSwitch" type="checkbox" class="toggle-switch-input">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">{{'SETUP.plessoclasse.crea_da_parametri_groups' | translate}}</span>
                    </span>
                  </label>
                </div>
                <div class="col-7">
                  <div class="alert alert-soft-info" role="alert" *ngIf="gruppoStudentiClasseAutomatic">
                    <b class="text-cap">Info</b>
                    <span [innerHTML]="'SETUP.plessoclasse.msg_info_crea_da_parametri_groups' | translate"></span>
                  </div>
                  <div class="alert alert-soft-warning" role="alert" *ngIf="!gruppoStudentiClasseAutomatic">
                    <b class="text-cap">Attenzione</b>
                    <span [innerHTML]="'SETUP.plessoclasse.msg_warning_crea_da_parametri_groups' | translate"></span>
                  </div>
                </div>
              </div>

              <div class="form-group row mb-2" *ngIf="paramAssociaGruppiDefault && userTypeSelected == 'studente'">
                <div class="col">
                  <label class="input-label"
                    for="selectGruppoStudentiCol">{{'SETUP.plessoclasse.label_colonna_gruppo_studenti' | translate}}
                    <button *ngIf="gruppoStudentiNameColIndex !== null && gruppoStudentiNameColIndex >= 0"
                      class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                      (click)="gruppoStudentiNameColIndex = null"><i class="fas fa-backspace"></i></button>
                  </label>
                  <select id="selectGruppoStudentiCol" name="selectGruppoStudentiCol" class="form-control custom-select"
                    [(ngModel)]="gruppoStudentiNameColIndex" #gruppoStudentiCol="ngModel"
                    [ngClass]="gruppoStudentiCol.valid ? 'is-valid': 'is-invalid'" required>
                    <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
                  </select>
                  <div class="valid-feedback"
                    *ngIf="gruppoStudentiNameColIndex && (gruppoStudentiCol.dirty || gruppoStudentiCol.touched)">
                    Valido</div>
                </div>
                <div class="col-7">
                  <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex"
                    [colIndex]="gruppoStudentiNameColIndex"></app-preview-column-data>
                </div>
              </div>

              <div class="row" *ngIf="paramAssociaGruppiDefault || userTypeSelected != 'docente'">
                <div class="col">
                  <h4>{{'SETUP.plessoclasse.gruppo_docenti' | translate}}</h4>
                </div>
              </div>

              <div class="form-group row mb-2" *ngIf="userTypeSelected != 'docente'">
                <div class="col">
                  <label class="toggle-switch d-flex" for="paramGruppoDocentiClasseAutomaticSwitch">
                    <input id="paramGruppoDocentiClasseAutomaticSwitch" [(ngModel)]="gruppoDocentiClasseAutomatic"
                      name="paramGruppoDocentiClasseAutomaticSwitch" type="checkbox" class="toggle-switch-input">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">{{'SETUP.plessoclasse.crea_da_parametri_groups' | translate}}</span>
                    </span>
                  </label>
                </div>
                <div class="col-7">
                  <div class="alert alert-soft-info" role="alert" *ngIf="gruppoDocentiClasseAutomatic">
                    <b class="text-cap">Info</b>
                    <span [innerHTML]="'SETUP.plessoclasse.msg_info_crea_da_parametri_groups' | translate"></span>
                  </div>
                  <div class="alert alert-soft-warning" role="alert" *ngIf="!gruppoDocentiClasseAutomatic">
                    <b class="text-cap">Attenzione</b>
                    <span [innerHTML]="'SETUP.plessoclasse.msg_warning_crea_da_parametri_groups' | translate"></span>
                  </div>
                </div>
              </div>

              <div class="form-group row mb-2" *ngIf="paramAssociaGruppiDefault && userTypeSelected == 'docente'">
                <div class="col">
                  <label class="input-label"
                    for="selectGruppoDocentiCol">{{'SETUP.plessoclasse.label_colonna_gruppo_docenti' | translate}}
                    <button *ngIf="gruppoDocentiNameColIndex !== null && gruppoDocentiNameColIndex >= 0"
                      class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                      (click)="gruppoDocentiNameColIndex = null"><i class="fas fa-backspace"></i></button>
                  </label>
                  <select id="selectGruppoDocentiCol" name="selectGruppoDocentiCol" class="form-control custom-select"
                    [(ngModel)]="gruppoDocentiNameColIndex" #gruppoDocentiCol="ngModel"
                    [ngClass]="gruppoDocentiCol.valid ? 'is-valid': 'is-invalid'" required>
                    <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
                  </select>
                  <div class="valid-feedback"
                    *ngIf="gruppoDocentiNameColIndex && (gruppoDocentiCol.dirty || gruppoDocentiCol.touched)">
                    Valido</div>
                </div>
                <div class="col-7">
                  <app-preview-column-data [data]="data" [headerRowIndex]="headerRowIndex"
                    [colIndex]="gruppoDocentiNameColIndex"></app-preview-column-data>
                </div>
              </div>

            </ng-container>

          </ng-container>

        </form>

      </div>

      <div class="card-footer">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto py-1">
            <button (click)="back()" type="button" class="btn btn-white">
              <i class="far fa-chevron-left mr-1"></i> Indietro
            </button>
          </div>
          <div class="col-auto py-1">
            <button [disabled]="!myForm.valid" (click)="next()" type="button" class="btn btn-primary">
              Avanti <i class="far fa-chevron-right ml-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Card -->
  </div>
</div>
