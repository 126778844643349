import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Observable, Subject } from 'rxjs';
import * as XLSX from 'xlsx';
// import * as data_parsed from '../../../mock_data/data-parsed-materie.json'; // solo per test deve essere sempre commentato
import { BackendService } from './backend.service';
import { ConfigService } from './config.service';
import { OrganigrammaService } from './general/organigramma.service';
import { ParametriService, ParamType } from './general/parametri.service';
import { PlessoService } from './general/plesso.service';
import { MateriaService } from './general/materia.service';
import { UserService } from './general/user.service';
import { GoogleDirectoryService } from './google/google-directory.service';
import { ToastService } from './toast.service';
import { UtilityService } from './utility.service';

type AOA = any[][];

@Injectable({
  providedIn: 'root'
})
export class FileimportService {

  importProcessStarted = false;

  private file: File;

  // Riga intestazione file
  private headerIndex: number;

  // Struttura
  selectedOrganigramma: any;
  paramSpostaInOrgUnitOrganigramma: boolean;
  selectedPlesso: any;
  paramCreaPlessoDefault: boolean;
  selectedClasse: any;
  paramCreaClassiDefault: boolean;
  plessoNameColIndex: number;
  plessoShortNameColIndex: number;
  classeNameColIndexArray: any[] = [];
  selectedMateria: any;
  paramAssociaMateria: boolean;
  materiaColIndex: number;
  paramAssociaOrgunitDefault: boolean;
  orgUnitNameColIndex: number;
  paramAssociaGruppiDefault: boolean;
  gruppoStudentiNameColIndex: number;
  gruppoDocentiNameColIndex: number;
  gruppoStudentiClasseAutomatic: boolean;
  gruppoDocentiClasseAutomatic: boolean;

  // Split
  paramSplitMateriaCol: boolean;
  paramSplitCharMateriaCol: any = ',';

  paramSplitPlessoCol: boolean;
  paramSplitCharPlessoCol: any = '/';
  paramSplitIndexPlessoCol: number = 2;

  paramSplitClasseCol: boolean;
  paramSplitCharClasseCol: any = '/';
  paramSplitIndexClasseCol: number = 3;

  // Filtro
  filtroColIndex: number;
  paramFiltroColOptions: any[];
  paramFiltroColOptionsSelected: any[];

  // overwrite info del file
  overwriteDataPlesso: any;

  // Utenti
  givenNameColIndex: number;
  familyNameColIndex: number;
  emailColIndex: number;
  cfColIndex: number;
  passwordColIndex: number;
  userTypeSelected: any;
  emailRecoveryColIndex: number;
  paramOvewriteRecoveryEmail: boolean;
  paramOvewriteCf: boolean;

  // dati struttura
  struttura: any[];
  struttura_with_users: any[];

  // File e dati
  private wb: XLSX.WorkBook;
  private wsindex: number;
  private wsname: string;
  private ws: XLSX.WorkSheet;
  private data: AOA;

  private _subjectParseCompleted: Subject<any> = new Subject<AOA>();

  constructor(
    private plessoService: PlessoService,
    private utilityService: UtilityService,
    private parametriService: ParametriService,
    private userService: UserService,
    private directoryService: GoogleDirectoryService,
    private organigrammaService: OrganigrammaService,
    private materiaService: MateriaService,
    private backend: BackendService,
    private toast: ToastService
  ) {

    this.wsindex = 0;

    // // solo per test deve essere sempre commentato
    // this.file = new File([], "data-parsed.json", { type: "application/json;charset=utf-8" });
    // this.data = (data_parsed as any).default;
    // this.headerIndex = 0;
    // this.userTypeSelected = "docente";
    // // this.selectedOrganigramma = 1;
    // // this.selectedPlesso = "7";
    // // this.selectedClasse = "10";
    // // this.selectedMateria = 1;
    // // this.paramCreaPlessoDefault = true;
    // // this.paramCreaClassiDefault = true;
    // this.plessoNameColIndex = 7;
    // this.materiaColIndex = 4;
    // this.paramSplitMateriaCol = true;
    // this.classeNameColIndexArray = [
    //   { classeNameColIndex: 9 },
    //   { classeNameColIndex: 8 },
    // ];
    // // this.paramSplitPlessoCol = true;
    // // this.paramSplitClasseCol = true;

    // this.givenNameColIndex = 0;
    // this.familyNameColIndex = 1;
    // this.emailColIndex = 2;
    // // this.cfColIndex = 3;
    // // this.passwordColIndex = 3;
    // // this.emailRecoveryColIndex = 2;

    // // this.paramAssociaGruppiDefault = true;
    // this.gruppoStudentiClasseAutomatic = true;
    // this.gruppoDocentiClasseAutomatic = true;
    // // this.gruppoStudentiNameColIndex = 6;
    // // solo per test deve essere sempre commentato

    // Quando si aggiornano i parametri bisogna cancellare la struttura e rifare il parse perché qualcosa potrebbe cambiare
    this.parametriService.getSubjectToUpdateObservableWhenParameterUpdated().subscribe((res) => {
      this.deleteStruttura();
    });
  }

  public onParseCompleted(): Observable<any> {
    return this._subjectParseCompleted.asObservable();
  }

  parseFile() {

    let reader: FileReader = new FileReader();

    reader.onload = (e: any) => {

      let bstr: string = e.target.result;
      this.wb = XLSX.read(bstr, { type: 'binary', raw: true });

      this.wsname = this.wb.SheetNames[this.wsindex];
      this.ws = this.wb.Sheets[this.wsname];

      this.data = <AOA>(XLSX.utils.sheet_to_json(this.ws, { header: 1, blankrows: false }));

      this._subjectParseCompleted.next(this.data);
    };

    reader.readAsBinaryString(this.file);
  }

  setFile(file: File) {
    this.file = file;
    this.parseFile();
  }

  getFile() {
    return this.file;
  }

  getData() {
    return this.data;
  }

  getFilteredData() {

    let dataFiltered = [];

    for (let i = 0; i < this.data.length; i++) {

      // Escludo sempre l'header
      if (i == this.headerIndex) {
        continue;
      }

      // se attivo il filtro sulle righe, controllo ed escludo
      if (this.isRowFiltered(this.data[i])) {
        continue;
      }

      dataFiltered.push(this.data[i]);
    }

    return dataFiltered;
  }

  hasImportProcessStarted() {
    return this.importProcessStarted;
  }

  hasFile() {
    if (this.getFile())
      return true;
    return false;
  }

  hasHeaderRow() {
    if (typeof this.getHeaderIndex() == "number")
      return true;
    return false;
  }

  saveHeaderIndex(index: any) {

    // se l'header viene cambiato devo azzerare tutto perché potrebbero essere cambiate le colonne rilevate
    if (this.headerIndex != parseInt(index)) {
      this.deleteStrutturaColsPreference();
      this.deleteUserColsPreference();
      this.deleteStruttura();
    }

    this.headerIndex = parseInt(index);
  }

  getHeaderIndex() {
    return this.headerIndex;
  }

  getHeaderRow() {

    if (!this.data || !this.hasHeaderRow())
      return null;

    return this.data[this.headerIndex];
  }

  deleteHeaderData() {
    this.headerIndex = null;
  }

  saveUserTypeSelected(userTypeSelected) {

    if (this.userTypeSelected != userTypeSelected) {
      this.deleteStrutturaWithUser();
    }

    this.userTypeSelected = userTypeSelected;

    switch (this.userTypeSelected) {
      case 'studente':
        this.gruppoDocentiClasseAutomatic = true;
        this.gruppoStudentiClasseAutomatic = true;
        break;
      case 'docente':
        this.gruppoDocentiClasseAutomatic = true;
        this.gruppoStudentiClasseAutomatic = false;
        break;
    }
  }

  saveFilterRow(filtroColIndex, paramFiltroColOptions, paramFiltroColOptionsSelected) {

    if (
      this.filtroColIndex != filtroColIndex ||
      JSON.stringify(this.paramFiltroColOptions) != JSON.stringify(paramFiltroColOptions) ||
      JSON.stringify(this.paramFiltroColOptionsSelected) != JSON.stringify(paramFiltroColOptionsSelected)
    ) {
      this.deleteStruttura();
    }

    this.filtroColIndex = filtroColIndex;
    this.paramFiltroColOptions = paramFiltroColOptions;
    this.paramFiltroColOptionsSelected = paramFiltroColOptionsSelected;
  }


  saveStrutturaColsPreference(
    selectedOrganigramma,
    paramSpostaInOrgUnitOrganigramma,
    selectedPlesso,
    paramCreaPlessoDefault,
    selectedClasse,
    paramCreaClassiDefault,
    plessoNameColIndex,
    plessoShortNameColIndex,
    classeNameColIndexArray,
    selectedMateria,
    paramAssociaMateria,
    materiaColIndex,
    paramAssociaOrgunitDefault,
    orgUnitNameColIndex,
    paramAssociaGruppiDefault,
    gruppoStudentiNameColIndex,
    gruppoDocentiNameColIndex,
    gruppoStudentiClasseAutomatic,
    gruppoDocentiClasseAutomatic
  ) {

    // Se cambia qualche parametro scelto nelle colonne cancello la struttura
    if (
      this.selectedOrganigramma != selectedOrganigramma ||
      this.paramSpostaInOrgUnitOrganigramma != paramSpostaInOrgUnitOrganigramma ||
      this.selectedPlesso != selectedPlesso ||
      this.paramCreaPlessoDefault != paramCreaPlessoDefault ||
      this.selectedClasse != selectedClasse ||
      this.paramCreaClassiDefault != paramCreaClassiDefault ||
      this.plessoNameColIndex != plessoNameColIndex ||
      this.plessoShortNameColIndex != plessoShortNameColIndex ||
      (this.classeNameColIndexArray && classeNameColIndexArray && this.classeNameColIndexArray.length != classeNameColIndexArray.length) ||
      this.selectedMateria != selectedMateria ||
      this.materiaColIndex != materiaColIndex ||
      this.orgUnitNameColIndex != orgUnitNameColIndex ||
      this.gruppoStudentiClasseAutomatic != gruppoStudentiClasseAutomatic ||
      this.gruppoDocentiClasseAutomatic != gruppoDocentiClasseAutomatic
    ) {
      this.deleteStruttura();
    }

    // Per le colonne che compongono la classe devo verifica, nel caso la lunghezza è rimasta invariata
    // se i campi scelti sono cambiati
    if (this.classeNameColIndexArray && classeNameColIndexArray && this.classeNameColIndexArray.length == classeNameColIndexArray.length) {
      this.classeNameColIndexArray.forEach((element, index) => {
        if (element.classeNameColIndex != classeNameColIndexArray[index].classeNameColIndex) {
          this.deleteStruttura();
        }
      });
    }

    this.selectedOrganigramma = selectedOrganigramma;
    this.paramSpostaInOrgUnitOrganigramma = paramSpostaInOrgUnitOrganigramma;
    this.selectedPlesso = selectedPlesso;
    this.paramCreaPlessoDefault = paramCreaPlessoDefault;
    this.selectedClasse = selectedClasse;
    this.paramCreaClassiDefault = paramCreaClassiDefault;
    this.plessoNameColIndex = plessoNameColIndex;
    this.plessoShortNameColIndex = plessoShortNameColIndex;
    this.selectedMateria = selectedMateria;
    this.paramAssociaMateria = paramAssociaMateria;
    this.materiaColIndex = materiaColIndex;
    this.paramAssociaOrgunitDefault = paramAssociaOrgunitDefault;
    this.orgUnitNameColIndex = orgUnitNameColIndex;
    this.paramAssociaGruppiDefault = paramAssociaGruppiDefault;
    this.gruppoStudentiNameColIndex = gruppoStudentiNameColIndex;
    this.gruppoDocentiNameColIndex = gruppoDocentiNameColIndex;
    this.classeNameColIndexArray = this.utilityService.cloneData(classeNameColIndexArray);
    this.gruppoStudentiClasseAutomatic = gruppoStudentiClasseAutomatic;
    this.gruppoDocentiClasseAutomatic = gruppoDocentiClasseAutomatic;
  }

  saveMateriaSplitColParameters(paramSplitMateriaCol, paramSplitCharMateriaCol) {

    if (
      this.paramSplitMateriaCol != paramSplitMateriaCol ||
      this.paramSplitCharMateriaCol != paramSplitCharMateriaCol
    ) {
      this.deleteStruttura();
    }

    this.paramSplitMateriaCol = paramSplitMateriaCol;
    this.paramSplitCharMateriaCol = paramSplitCharMateriaCol;
  }

  savePlessoSplitColParameters(paramSplitPlessoCol, paramSplitCharPlessoCol, paramSplitIndexPlessoCol) {

    if (
      this.paramSplitPlessoCol != paramSplitPlessoCol ||
      this.paramSplitCharPlessoCol != paramSplitCharPlessoCol ||
      this.paramSplitIndexPlessoCol != paramSplitIndexPlessoCol
    ) {
      this.deleteStruttura();
    }

    this.paramSplitPlessoCol = paramSplitPlessoCol;
    this.paramSplitCharPlessoCol = paramSplitCharPlessoCol;
    this.paramSplitIndexPlessoCol = paramSplitIndexPlessoCol;
  }

  saveClasseSplitColParameters(paramSplitClasseCol, paramSplitCharClasseCol, paramSplitIndexClasseCol) {

    if (
      this.paramSplitClasseCol != paramSplitClasseCol ||
      this.paramSplitCharClasseCol != paramSplitCharClasseCol ||
      this.paramSplitIndexClasseCol != paramSplitIndexClasseCol
    ) {
      this.deleteStruttura();
    }

    this.paramSplitClasseCol = paramSplitClasseCol;
    this.paramSplitCharClasseCol = paramSplitCharClasseCol;
    this.paramSplitIndexClasseCol = paramSplitIndexClasseCol;
  }

  saveOverwriteDataPlesso(overwriteDataPlesso) {
    this.overwriteDataPlesso = overwriteDataPlesso;
    this.deleteStruttura();
  }

  deleteStrutturaColsPreference() {

    this.selectedOrganigramma = null;
    this.paramSpostaInOrgUnitOrganigramma = null;
    this.selectedPlesso = null;
    this.paramCreaPlessoDefault = null;
    this.selectedClasse = null;
    this.paramCreaClassiDefault = null;
    this.plessoNameColIndex = null;
    this.plessoShortNameColIndex = null;
    this.classeNameColIndexArray = [];
    this.selectedMateria = null;
    this.paramAssociaMateria = null;
    this.materiaColIndex = null;
    this.paramAssociaOrgunitDefault = null;
    this.orgUnitNameColIndex = null;
    this.paramAssociaGruppiDefault = null;
    this.gruppoStudentiNameColIndex = null;
    this.gruppoDocentiNameColIndex = null;
    this.gruppoStudentiClasseAutomatic = true;
    this.gruppoDocentiClasseAutomatic = true;
    this.overwriteDataPlesso = null;
  }

  saveUserColsPreference(
    givenNameColIndex,
    familyNameColIndex,
    emailColIndex,
    paramOvewriteRecoveryEmail,
    cfColIndex,
    paramOvewriteCf,
    passwordColIndex,
    userTypeSelected,
    emailRecoveryColIndex,
  ) {

    if (
      this.givenNameColIndex != givenNameColIndex ||
      this.familyNameColIndex != familyNameColIndex ||
      this.emailColIndex != emailColIndex ||
      this.cfColIndex != cfColIndex ||
      this.passwordColIndex != passwordColIndex ||
      this.userTypeSelected != userTypeSelected ||
      this.emailRecoveryColIndex != emailRecoveryColIndex ||
      this.paramOvewriteRecoveryEmail != paramOvewriteRecoveryEmail ||
      this.paramOvewriteCf != paramOvewriteCf
    ) {
      this.deleteStrutturaWithUser();
    }

    this.givenNameColIndex = givenNameColIndex;
    this.familyNameColIndex = familyNameColIndex;
    this.emailColIndex = emailColIndex;
    this.cfColIndex = cfColIndex;
    this.paramOvewriteRecoveryEmail = paramOvewriteRecoveryEmail;
    this.paramOvewriteCf = paramOvewriteCf;
    this.passwordColIndex = passwordColIndex;
    this.userTypeSelected = userTypeSelected;
    this.emailRecoveryColIndex = emailRecoveryColIndex;
  }

  deleteUserColsPreference() {

    this.givenNameColIndex = null;
    this.familyNameColIndex = null;
    this.emailColIndex = null;
    this.cfColIndex = null;
    this.paramOvewriteRecoveryEmail = null;
    this.paramOvewriteCf = null;
    this.passwordColIndex = null;
    this.userTypeSelected = null;
    this.emailRecoveryColIndex = null;
  }

  saveStruttura(struttura) {
    // Ogni volta che la salvo è meglio cancellarla prima per evitare errori di importare classi selezionate e poi cambiate
    this.deleteStruttura();
    this.struttura = this.utilityService.cloneData(struttura);
  }

  deleteStruttura() {
    this.struttura = null;
    this.deleteStrutturaWithUser();
  }

  deleteStrutturaWithUser() {
    this.struttura_with_users = null;
  }

  getStruttura(success_callback = (data: any) => { }) {

    if (this.struttura) {
      success_callback(this.struttura);
      return;
    }

    this.struttura = [];

    // se è stato selezionato un organigramma
    if (this.selectedOrganigramma) {

      this.organigrammaService.get(this.selectedOrganigramma, (data) => {

        let organigramma = data;

        this.getOrganigrammaStruttura(organigramma, (data) => {
          this.struttura = data;
          success_callback(this.struttura);
        })

      });

      // Se è stato selezionato un plesso devo recuperarlo
    } else if (this.selectedPlesso) {

      this.plessoService.get(this.selectedPlesso, (data) => {

        let plesso = data;

        if (this.selectedClasse) {

          this.plessoService.getClasse(data.id, this.selectedClasse, (data) => {

            let classe = data;

            this.getPlessiClassiFromData(plesso, classe, (data) => {
              this.struttura = data;
              success_callback(this.struttura);
            })
          });

        }
        else {

          this.getPlessiClassiFromData(plesso, null, (data) => {
            this.struttura = data;
            success_callback(this.struttura);
          })
        }

        success_callback(this.struttura);
      });
    }
    else if (this.plessoNameColIndex && this.classeNameColIndexArray && this.classeNameColIndexArray.length > 0) {
      this.getPlessiClassiFromData(null, null, (data) => {
        this.struttura = data;
        success_callback(this.struttura);
      })
    }
    else {
      this.struttura = null;
      success_callback(null);
    }
  }

  /*
  .########..##.......########..######...######...#######.....########.....######..##..........###.....######...######..####
  .##.....##.##.......##.......##....##.##....##.##.....##....##..........##....##.##.........##.##...##....##.##....##..##.
  .##.....##.##.......##.......##.......##.......##.....##....##..........##.......##........##...##..##.......##........##.
  .########..##.......######....######...######..##.....##....######......##.......##.......##.....##..######...######...##.
  .##........##.......##.............##.......##.##.....##....##..........##.......##.......#########.......##.......##..##.
  .##........##.......##.......##....##.##....##.##.....##....##..........##....##.##.......##.....##.##....##.##....##..##.
  .##........########.########..######...######...#######.....########.....######..########.##.....##..######...######..####
  */

  getPlessiClassiFromData(plesso = null, classe = null, success_callback = (data: any) => { }) {

    let colonna_plesso_valore = {};
    let struttura_plessi = [];

    // Cerco i nomi dei plessi nella colonna specificata
    for (let i = 0; i < this.data.length; i++) {

      // Escludo l'header
      if (i <= this.headerIndex)
        continue;

      // se attivo il filtro sulle righe, controllo ed escludo
      if (this.isRowFiltered(this.data[i])) {
        continue;
      }

      let nome_plesso = "";
      let short_nome_plesso = null;

      // Se il plesso è stato specificato allora considero solo quel nome
      if (plesso) {
        nome_plesso = plesso.name;
      }
      // Altrimenti recupero il nome dalla tabella
      else {
        nome_plesso = ("" + this.data[i][this.plessoNameColIndex]).trim();

        // Verifico se attivo lo split
        if (this.paramSplitPlessoCol) {
          nome_plesso = nome_plesso.split(this.paramSplitCharPlessoCol)[this.paramSplitIndexPlessoCol];
        }

        if (nome_plesso == null || nome_plesso.length == 0)
          continue;

        // Se prendo il nome del plesso dal file, verifico se è stata selezionata anche la colonna per l'abbreviazione
        if (this.plessoShortNameColIndex) {
          short_nome_plesso = ("" + this.data[i][this.plessoShortNameColIndex]).trim();
        }
      }

      nome_plesso = nome_plesso.trim();

      if (nome_plesso == '')
        continue;

      // controllo se ho già parsato un plesso con quel nome
      if (!colonna_plesso_valore.hasOwnProperty(nome_plesso)) {

        colonna_plesso_valore[nome_plesso] = {
          classi: {},
          short_name_plesso: short_nome_plesso,
        };

        // controllo se ho impostato una sovrascrittura di dati per questo plesso
        if (this.overwriteDataPlesso && this.overwriteDataPlesso.hasOwnProperty(nome_plesso)) {
          if (this.overwriteDataPlesso[nome_plesso] && this.overwriteDataPlesso[nome_plesso].hasOwnProperty('short_name_plesso')) {
            colonna_plesso_valore[nome_plesso].short_name_plesso = this.overwriteDataPlesso[nome_plesso].short_name_plesso;
          }
        }
      }

      let nome_classe = "";

      // se la classe è stata specificata uso solo quel nome
      if (classe) {
        nome_classe = classe.name;
      }
      // altrimenti recuper il nome dalla tabella andando a concatenare i campi specificati
      else {
        for (let j = 0; j < this.classeNameColIndexArray.length; j++) {
          if (this.data[i][this.classeNameColIndexArray[j].classeNameColIndex])
            nome_classe += ("" + this.data[i][this.classeNameColIndexArray[j].classeNameColIndex]).trim();
        }

        // Verifico se attivo lo split
        if (this.paramSplitClasseCol) {
          nome_classe = nome_classe.split(this.paramSplitCharClasseCol)[this.paramSplitIndexClasseCol];
        }

        if (nome_classe == null || nome_classe.length == 0)
          continue;
      }

      // le classi sempre maiuscolo
      nome_classe = nome_classe.toUpperCase().trim();

      if (nome_classe == '')
        continue;

      // Verifico se mi hai specificato da file unità organizzativa
      let orgunit_da_file = null;
      // verifico se è stata specificata l'opzione di prelevare le informazioni nel file
      if (this.paramAssociaOrgunitDefault && this.orgUnitNameColIndex != null) {

        let name_from_file = ("" + this.data[i][this.orgUnitNameColIndex]).trim();

        // cerco se esiste già un org unit con il path specificato
        let org_unit = this.directoryService.getOrgUnitByPath(name_from_file);

        if (org_unit) {
          orgunit_da_file = org_unit;
        }
      }

      // Verifico se ha specificato da file il gruppo Studenti
      let gruppo_studenti_da_file = null;
      if (this.paramAssociaGruppiDefault) {

        if (this.gruppoStudentiNameColIndex != null) {

          let name_from_file = ("" + this.data[i][this.gruppoStudentiNameColIndex]).trim();

          // controllo che sia un'email valida e che abbia un dominio valido
          if (name_from_file != null && name_from_file.length > 0 && this.directoryService.checkEmailDomains(name_from_file) && this.utilityService.validateEmail(name_from_file)) {

            // cerco se esiste un gruppo
            let gruppo = this.directoryService.getGroupByEmail(name_from_file);

            if (gruppo) {
              gruppo_studenti_da_file = gruppo;
            }
            else {
              gruppo_studenti_da_file = {
                email: name_from_file
              };
            }
          }
        }
      }

      if (!this.gruppoStudentiClasseAutomatic) {
        gruppo_studenti_da_file = { "non_gestire": true };
      }

      // Gruppo docenti
      let gruppo_docenti_da_file = null;
      if (this.paramAssociaGruppiDefault) {

        if (this.gruppoDocentiNameColIndex != null) {

          let name_from_file = ("" + this.data[i][this.gruppoDocentiNameColIndex]).trim();

          // controllo che sia un'email valida e che abbia un dominio valido
          if (name_from_file != null && name_from_file.length > 0 && this.directoryService.checkEmailDomains(name_from_file) && this.utilityService.validateEmail(name_from_file)) {

            // cerco se esiste un gruppo
            let gruppo = this.directoryService.getGroupByEmail(name_from_file);

            if (gruppo) {
              gruppo_docenti_da_file = gruppo;
            }
            else {
              gruppo_docenti_da_file = {
                email: name_from_file
              };
            }
          }
        }
      }

      if (!this.gruppoDocentiClasseAutomatic) {
        gruppo_docenti_da_file = { "non_gestire": true };
      }

      // controllo se ho già parsato una colonna
      if (!colonna_plesso_valore[nome_plesso].classi.hasOwnProperty(nome_classe)) {
        colonna_plesso_valore[nome_plesso].classi[nome_classe] = {
          classe: { name: nome_classe },
          rows: [i],
          orgunit_da_file: orgunit_da_file,
          gruppo_studenti_da_file: gruppo_studenti_da_file,
          gruppo_docenti_da_file: gruppo_docenti_da_file
        };
      }
      // in entrambi i casi aggiungo anche la riga di riferimento dei dati per poter recuperare gli utenti successivamente
      else {
        colonna_plesso_valore[nome_plesso].classi[nome_classe].rows.push(i);
      }
    }

    this.plessoService.all((data) => {

      for (let nome_plesso in colonna_plesso_valore) {

        let plesso = this.plessoService.getPlessoByName(nome_plesso);

        if (!plesso) {

          struttura_plessi.push(this.getNewPlessoStruttura(nome_plesso, colonna_plesso_valore[nome_plesso]));
        }
        else {

          let classi_valore = colonna_plesso_valore[nome_plesso].classi;
          let struttura_classi_plesso = [];

          for (let nome_classe in classi_valore) {

            let classe = this.plessoService.getClasseByName(plesso.id, nome_classe);

            if (!classe) {

              struttura_classi_plesso.push(this.getNewClasseStruttura(nome_classe, plesso, classi_valore[nome_classe]));
            }
            else {

              struttura_classi_plesso.push(this.getExistingClasseStruttura(classe, plesso, classi_valore[nome_classe]));
            }
          }

          // aggiungo il plesso alla selezione
          plesso.selected = true;

          struttura_plessi.push({
            plesso: plesso,
            classi: struttura_classi_plesso,
          });
        }
      }

      this.checkOrgUnitInStruttura(struttura_plessi);

      success_callback(struttura_plessi);
    });
  }

  getNewPlessoStruttura(nome_plesso, plesso_data) {

    let classi = plesso_data.classi;

    let plesso = {
      name: nome_plesso,
      short_name: this.utilityService.getShortName(nome_plesso),
      google_org_unit: null,
    };

    // Sostituisco lo short name generato con quello specificato nel file
    if (plesso_data.short_name_plesso && plesso_data.short_name_plesso.length > 0) {
      plesso.short_name = plesso_data.short_name_plesso.replace(/[^A-Za-z0-9_.-]+/g, "_").toLowerCase();
    }

    let group_docenti = this.getGroupStruttura(ParamType.PlessoDocente, plesso, "docenti");
    let group_studenti = this.getGroupStruttura(ParamType.PlessoStudente, plesso, "studenti");
    let group_plesso = this.getGroupStruttura(ParamType.PlessoGruppo, plesso, "plesso");

    // controllo se ho scelto nei parametri di gestire il gruppo_plesso
    if (!this.parametriService.data.gest_group_plesso) {
      group_plesso = {
        "non_gestire": true,
        "relation_info": "plesso"
      };
    }

    // recupero organigramma studenti e costruisco il path del nuovo plesso
    let relation_info = ConfigService.getUserTypeRelationInfoByKey('studente');
    let organigramma_studenti = this.organigrammaService.getDefaultOrganigrammaForUserType(relation_info);

    let new_org_unit_path = "";

    if (organigramma_studenti && organigramma_studenti.google_org_unit) {
      new_org_unit_path = organigramma_studenti.google_org_unit.orgUnitPath;
    }

    new_org_unit_path += "/" + nome_plesso;

    let plesso_struttura = {
      plesso: {
        selected: true,
        name: nome_plesso,
        short_name: plesso.short_name,
        google_group: [
          group_plesso,
          group_docenti,
          group_studenti,
        ],
        google_org_unit_new: null,
        google_org_unit: null,
      },
      classi: []
    };

    // cerco se esiste già un org unit con il path creato
    let org_unit_new = this.directoryService.getOrgUnitByPath(new_org_unit_path);

    // se non esiste conservo le info per la creazione
    if (!org_unit_new) {
      org_unit_new = {
        orgUnitPath: new_org_unit_path,
        name: nome_plesso,
        parentOrgUnitId: organigramma_studenti.google_org_unit.orgUnitId,
        parentOrgUnitPath: organigramma_studenti.google_org_unit.orgUnitPath,
      }

      // Aggiungo le info dell'org unity come nuove
      plesso_struttura.plesso.google_org_unit_new = org_unit_new;
    }
    else {
      // Aggiungo le info dell'org unit come già esistenti
      plesso_struttura.plesso.google_org_unit = org_unit_new;
    }

    // Aggiungo l'org unit anche alle info del plesso perché serviranno nella creazione della classe
    plesso.google_org_unit = org_unit_new;

    for (let nome_classe in classi) {
      plesso_struttura.classi.push(this.getNewClasseStruttura(nome_classe, plesso, classi[nome_classe]));
    }

    return plesso_struttura;
  }

  getExistingClasseStruttura(classe, plesso, classe_data) {

    // aggiungo la classe comunque alla selezione
    classe.selected = true;

    if (!classe.google_group) {
      classe.google_group = [];
    }

    let classe_obj = {
      name_plesso: plesso.name,
      short_name_plesso: plesso.short_name,
      name_classe: classe.name,
      anno_classe: classe.anno,
      google_org_unit: null,
    };

    let group_docenti = this.getGroupStruttura(ParamType.ClasseDocente, classe_obj, "docenti", classe_data.gruppo_docenti_da_file, classe_data);
    let group_studenti = this.getGroupStruttura(ParamType.ClasseStudente, classe_obj, "studenti", classe_data.gruppo_studenti_da_file, classe_data);

    // aggiungo il gruppo all'array new se non è stato già associato
    if (!group_docenti.holder_id) {

      let found = false;

      // Cerco se non esiste già un gruppo con quella relazione
      classe.google_group.forEach(group => {
        if (group.relation_info == 'docenti') {
          found = true;
        }
      });


      if (!found) {
        classe.google_group.push(group_docenti);
      }
    }

    // aggiungo il gruppo all'array new se non è stato già associato
    if (!group_studenti.holder_id) {

      let found = false;

      // Cerco se non esiste già un gruppo con quella relazione
      classe.google_group.forEach(group => {
        if (group.relation_info == 'studenti') {
          found = true;
        }
      });


      if (!found) {
        classe.google_group.push(group_studenti);
      }
    }

    let struttura_classe = {
      classe: classe,
      rows: classe_data.rows,
    };

    return struttura_classe;
  }

  getNewClasseStruttura(nome_classe, plesso, classe) {

    // per le nuove classi imposto l'anno corrente come anno
    let anno_classe = new Date().getUTCFullYear();

    let classe_obj = {
      name_plesso: plesso.name,
      short_name_plesso: plesso.short_name,
      name_classe: nome_classe,
      anno_classe: anno_classe,
      google_org_unit: null,
    };

    let group_docenti = this.getGroupStruttura(ParamType.ClasseDocente, classe_obj, "docenti", classe.gruppo_docenti_da_file);
    let group_studenti = this.getGroupStruttura(ParamType.ClasseStudente, classe_obj, "studenti", classe.gruppo_studenti_da_file);
    let group_classe = this.getGroupStruttura(ParamType.ClasseGruppo, classe_obj, "classe");

    let group_docenti_email = this.parametriService.getEmailParamsFor(ParamType.ClasseDocente, classe_obj);
    let group_studenti_email = this.parametriService.getEmailParamsFor(ParamType.ClasseStudente, classe_obj);
    let group_classe_email = this.parametriService.getEmailParamsFor(ParamType.ClasseGruppo, classe_obj);

    // controllo se ho scelto nei parametri di gestire il gruppo_plesso
    if (!this.parametriService.data.gest_group_classe) {
      group_classe = {
        "non_gestire": true,
        "relation_info": "classe"
      };
    }

    // partendo dall'org unit del plesso costruisco quella della classe
    let new_org_unit_path = "";

    if (plesso.google_org_unit && plesso.google_org_unit.orgUnitPath)
      new_org_unit_path = plesso.google_org_unit.orgUnitPath;

    new_org_unit_path += "/" + nome_classe;

    let struttura_classe = {
      classe: {
        selected: true,
        name: nome_classe,
        anno: anno_classe,
        google_group: [
          group_classe,
          group_docenti,
          group_studenti,
        ],
        google_org_unit_new: null,
        google_org_unit: null,
      },
      rows: classe.rows
    };

    // cerco se esiste già un org unit con il path creato
    let org_unit_new;

    if (classe.orgunit_da_file) {
      org_unit_new = classe.orgunit_da_file;
    }
    else {
      org_unit_new = this.directoryService.getOrgUnitByPath(new_org_unit_path);
    }

    // se non esiste conservo le info per la creazione
    if (!org_unit_new) {
      org_unit_new = {
        orgUnitPath: new_org_unit_path,
        name: nome_classe
      }

      // Aggiungo alla struttura le info come nuova
      struttura_classe.classe.google_org_unit_new = org_unit_new;
    }
    else {

      // Aggiungo alla struttura le info come esistenti
      struttura_classe.classe.google_org_unit = org_unit_new;
    }

    return struttura_classe
  }

  /*
  ..#######..########...######......###....##....##.####..######...########.....###....##.....##.##.....##....###...
  .##.....##.##.....##.##....##....##.##...###...##..##..##....##..##.....##...##.##...###...###.###...###...##.##..
  .##.....##.##.....##.##.........##...##..####..##..##..##........##.....##..##...##..####.####.####.####..##...##.
  .##.....##.########..##...####.##.....##.##.##.##..##..##...####.########..##.....##.##.###.##.##.###.##.##.....##
  .##.....##.##...##...##....##..#########.##..####..##..##....##..##...##...#########.##.....##.##.....##.#########
  .##.....##.##....##..##....##..##.....##.##...###..##..##....##..##....##..##.....##.##.....##.##.....##.##.....##
  ..#######..##.....##..######...##.....##.##....##.####..######...##.....##.##.....##.##.....##.##.....##.##.....##
  */

  getOrganigrammaStruttura(organigramma, success_callback = (data: any) => { }) {

    let struttura_organigramma = {
      organigramma: organigramma,
      rows: [],
      errors: [],
      warnings: [],
    };

    // aggiungo le opzioni all'organigramma
    struttura_organigramma.organigramma.options = {
      "sposta_in_orgunit": this.paramSpostaInOrgUnitOrganigramma
    };

    // Cerco le righe
    for (let i = 0; i < this.data.length; i++) {

      // Escludo l'header
      if (i <= this.headerIndex)
        continue;

      // se attivo il filtro sulle righe, controllo ed escludo
      if (this.isRowFiltered(this.data[i])) {
        continue;
      }

      struttura_organigramma.rows.push(i);
    }

    success_callback([struttura_organigramma]);
  }

  /*
  .##.....##..######..########.########...######.
  .##.....##.##....##.##.......##.....##.##....##
  .##.....##.##.......##.......##.....##.##......
  .##.....##..######..######...########...######.
  .##.....##.......##.##.......##...##.........##
  .##.....##.##....##.##.......##....##..##....##
  ..#######...######..########.##.....##..######.
  */

  saveStrutturaWithUsers(struttura) {
    // Salvo la struttura senza clonarla perché in questa ci sono deri riferimenti circolari che non possono essere serializzati
    this.struttura_with_users = struttura;
  }

  getStrutturaWithUsers(success_callback = (data: any) => { }) {

    if (this.struttura_with_users) {
      success_callback(this.struttura_with_users);
      return;
    }

    this.struttura_with_users = [];

    this.getStruttura((struttura_without_users) => {

      if (!struttura_without_users || struttura_without_users.length <= 0) {
        this.struttura_with_users = null;
        success_callback(null);
        return;
      }

      // rimuovo i elementi (plessi/organigrammi) con errori
      struttura_without_users.forEach(elemento_struttura_obj => {
        if (elemento_struttura_obj.errors.length == 0) {
          this.struttura_with_users.push(this.utilityService.cloneData(elemento_struttura_obj));
        }
      });

      this.struttura_with_users.forEach(elemento_struttura_obj => {

        if (elemento_struttura_obj.classi) {

          elemento_struttura_obj.classi.forEach(classe_obj => {

            classe_obj.users = [];

            if (classe_obj.classe.selected) {

              classe_obj.rows.forEach(row_index => {

                let row = this.data[row_index];

                let user = this.getUserInfoFromRow(row);

                user.classe_obj = classe_obj;
                user.plesso_obj = elemento_struttura_obj;

                classe_obj.users.push(user);
              });
            }
          });
        }
        else if (elemento_struttura_obj.organigramma) {

          elemento_struttura_obj.users = [];

          elemento_struttura_obj.rows.forEach(row_index => {

            let row = this.data[row_index];

            let user = this.getUserInfoFromRow(row);

            user.organigramma_obj = elemento_struttura_obj.organigramma;

            elemento_struttura_obj.users.push(user);
          });
        }
      });

      // cerco prima omonimie
      this.struttura_with_users = this.findDuplicate(this.struttura_with_users);

      // poi cerco duplicati safe da unire controllando che non siano stati rilevati come omonimie o risolti
      this.struttura_with_users = this.findAndMergeSafeDuplicate(this.struttura_with_users);

      success_callback(this.struttura_with_users);
    });
  }

  getUserInfoFromRow(row) {

    let givenName = this.givenNameColIndex != null && row[this.givenNameColIndex] ? ("" + row[this.givenNameColIndex]).trim() : null;
    let familyName = this.familyNameColIndex != null && row[this.familyNameColIndex] ? ("" + row[this.familyNameColIndex]).trim() : null;
    let email = this.emailColIndex != null && row[this.emailColIndex] ? ("" + row[this.emailColIndex]).trim().toLowerCase() : null;
    let email_recovery = this.emailRecoveryColIndex != null && row[this.emailRecoveryColIndex] ? ("" + row[this.emailRecoveryColIndex]).trim().toLowerCase() : null;
    let cf = this.cfColIndex != null && row[this.cfColIndex] ? ("" + row[this.cfColIndex]).trim() : null;
    let password = this.passwordColIndex != null && row[this.passwordColIndex] ? ("" + row[this.passwordColIndex]).trim() : null

    let materie = [];

    // se la materia è stata specificata uso solo quel nome
    if (this.selectedMateria) {
      materie = [this.materiaService.get(this.selectedMateria)];
    }
    // altrimenti recuper il nome dalla tabella andando a concatenare i campi specificati
    else if (this.materiaColIndex && row[this.materiaColIndex] != null) {

      let riga_materie = ("" + row[this.materiaColIndex]).trim();

      let materie_name = [];

      // Verifico se attivo lo split
      if (this.paramSplitMateriaCol) {

        let riga_materie_split = riga_materie.split(this.paramSplitCharMateriaCol);

        riga_materie_split.forEach(mat_name => {

          // le materie sempre minuscolo
          let nome = mat_name.toLowerCase().trim();

          if (nome && nome.length > 0) {
            materie_name.push(nome);
          }

        });
      }
      else {

        let nome = riga_materie.toLowerCase().trim();

        if (nome && nome.length > 0) {
          materie_name.push(nome);
        }
      }

      materie_name = [...new Set(materie_name)];

      materie_name.forEach(materia_name => {
        let materia = this.materiaService.getByName(materia_name);

        if (materia) {
          materie.push(materia);
        }
        else {
          materie.push({
            name: materia_name
          });
        }
      });
    }

    // controllo che email e email_recovery siano corrette
    if (email != null && email.length > 0) {
      if (!this.utilityService.validateEmail(email)) { email = null; }
    }

    if (email_recovery != null && email_recovery.length > 0) {
      if (!this.utilityService.validateEmail(email_recovery)) { email_recovery = null; }
    }

    // gestisco quando colonna nome e colonna cognome sono la stessa
    // successivamente se trovo un utente google uso nome e cognome di google che può essere più preciso
    if (this.givenNameColIndex == this.familyNameColIndex) {

      let splitName = ("" + row[this.givenNameColIndex]).trim();
      let splitIndex = splitName.indexOf(' ');

      if (splitIndex > 0) {
        givenName = splitName.substr(0, splitIndex);
        familyName = splitName.substr(splitIndex + 1);
      }
    }

    if (this.cfColIndex) {

    }

    let user = {
      id: null,
      givenName: givenName,
      familyName: familyName,
      email: email,
      email_fromparams: email != null ? false : true,
      email_generated_from_parameters: null,
      email_recovery: email_recovery,
      cf: cf,
      password: password,
      password_generated: this.passwordColIndex != null ? false : true,
      userTypeSelected: this.userTypeSelected,
      user_geniusuite: null,
      users_google: [],
      google_user: null,
      omonimie: [],
      errors_checked: false,
      errors: [],
      warnings_checked: false,
      warnings: [],
      info_checked: false,
      info: [],
      selected: true,
      disabled: false,
      plesso_obj: null,
      classe_obj: null,
      organigramma_obj: null,
      new_cf: null,
      new_email_recovery: null,
      new_password: null,
      materie: materie
    }

    user.email_generated_from_parameters = this.parametriService.getEmailParamsForUser(this.userTypeSelected, user);

    user = this.checkUserInfoAndUpdate(user);

    return user;
  }

  checkUserInfoAndUpdate(user) {

    // Cerco prima tramite codice fiscale negli external ids
    // fatta eccezione per quando lo si vuole sovrascrivere, perché si presuppone che sia sbagliato
    if (user.cf && user.cf.length > 0 && !this.paramOvewriteCf) {

      let u = this.directoryService.getUsersByExternalIdValue(user.cf);

      let google_user = null;

      u.forEach(element => {
        google_user = element;
        user.users_google.push(element);
      });

      // se ha trovato un solo utente provo a cercare tramite l'id google un utente Geniusuite mappato
      if (u.length == 1 && google_user && google_user.id) {

        user.google_user = google_user;

        user.user_geniusuite = this.userService.getByGoogleId(google_user.id);

        // Sovrascrivo sempre l'email con il riferimento di google trovato tramite codice fiscale
        if (!user.email) {
          user.email = google_user.primaryEmail;
        }
      }

    }

    // Se ho trovato un utente tramite il Codice Identificativo allora prendo l'email dall'utente esistente, ignorando quella del file
    // Andrebbe segnalata qualche incongruenza?
    if (user.user_geniusuite && user.user_geniusuite.email) {

      // Se per qualche strano motivo l'email era già presente perché specificata nel file ed è diversa da quella trovata attraverso il Codice Identificativo segnalo l'errore
      if (user.email && user.email != user.user_geniusuite.email) {
        if (!user.errors_checked) {
          user.errors.push({ type: "SETUP.preview_users.email_file_diversa_da_utente_codice_identificativo", data: { email_google: user.user_geniusuite.email, email_file: user.email } });
        }
      }

      if (!user.email) {
        user.email = user.user_geniusuite.email;
      }
    }

    // devo trovare un'email per l'utente se non è specificata
    while (!user.email || user.email.length == 0) {

      user.email = user.email_generated_from_parameters;
      user.email_fromparams = true;

      // con l'email dei parametri cerco l'utente geniusuite
      user.user_geniusuite = this.userService.getByEmail(user.email);

      // se lo trovo, controllo l'utente google associato, se presente verifico che il codice identificato corrisponde, se presente
      // non effettuo questa ricerca perché il codice identificativo è sbagliato e deve essere sovrascritto
      if (user.cf && user.user_geniusuite && user.user_geniusuite.google_user && !this.paramOvewriteCf) {

        let google_user_from_cf = this.directoryService.getUsersByExternalIdValue(user.cf);

        // Se ho recuperato, tramite il cf, un utente diverso, allora è uno strano caso di omonimia che va aggiunto un numero casuale all'email perché è un utente non esistente
        // ma tramite i parametri recupera un utente geniusuite sbagliato
        google_user_from_cf.forEach(element => {
          if (user.user_geniusuite && user.user_geniusuite.google_user) {
            if (element.id != user.user_geniusuite.google_user.id) {
              user.email_generated_from_parameters = this.addRandomCharsToEmail(user.email);
              user.email = null;
              user.user_geniusuite = null;
              user.email_fromparams = false;
            }
          }
        });

        // Se l'utente continua ad essere valido e non ci sono strani casi di omonimia, provo a verificare che l'utente geniusuite recuperato tramite email abbia
        // il codice identificativo uguale o assente
        if (user.user_geniusuite && user.user_geniusuite.google_user) {

          let userExternalId = this.directoryService.getUserExternalIdValue(user.user_geniusuite.google_user);

          // Se l'utente scelto attraverso l'email generata dai parametri ha un id diverso da quello specificato nel file
          // si può trattare di un caso di omonimia e quindi va cambiata l'email generata proprio perché non è stato specificato l'account email ma è stato generato tramite parametri
          if (userExternalId && userExternalId.length > 0 && userExternalId != user.cf) {
            user.email_generated_from_parameters = this.addRandomCharsToEmail(user.email);
            user.email = null;
            user.user_geniusuite = null;
            user.email_fromparams = false;
          }
        }
      }

      // Se non hai ne email (perché la stiamo generando con i parametri), ne codice fiscale
      // posso fare alcuni piccoli controlli di omonimie
      if (!user.cf && user.email && user.email_fromparams && user.user_geniusuite && user.givenName && user.familyName) {

        // se nome o cognome sono diversi allora sono due utenti diversi
        if (user.givenName.trim().toLowerCase() != user.user_geniusuite.user.givenName.trim().toLowerCase() || user.familyName.trim().toLowerCase() != user.user_geniusuite.user.familyName.trim().toLowerCase()) {
          user.email_generated_from_parameters = this.addRandomCharsToEmail(user.email);
          user.email = null;
          user.user_geniusuite = null;
          user.email_fromparams = false;
        }
      }

    }

    // Se ho già trovato l'utente non lo cerco anche tramite email, altrimenti faccio al ricerca tramite l'email generata con i parametri o specificata nel file
    if (!user.user_geniusuite) {
      user.user_geniusuite = this.userService.getByEmail(user.email);
    }

    // Se trovo un utente geniusuite conservo l'id
    if (user.user_geniusuite) {
      user.id = user.user_geniusuite.id;
    }
    else {
      user.id = null;
    }

    // Cerco nuovamente utenti google con la stessa email per rilevare eventuali incongruenze nel caso di email generata con parametri o nel file
    // se trovo un nuovo utente con la stessa email verifico che non sia uguale a quello già rilevato
    // verifico però che non abbia il codice fiscale specificato nel file e se lo tiene, verifico che non abbia già recuperato l'utente google tramite codice fiscale, in quel caso non devo cercarne altri
    if (user.email && (!user.cf || !user.google_user)) {

      let u = this.directoryService.getUserByEmail(user.email);

      if (u) {
        let found = false;
        user.users_google.forEach(ug => {
          if (ug.id == u.id) {
            found = true;
          }
        });

        if (!found) {
          user.users_google.push(u);
        }
      }
    }

    // Se ho trovato un solo utente allora lo aggiungo direttamente all'utente senza segnalarlo come omonimia google
    if (!user.google_user && user.users_google.length == 1) {
      user.google_user = user.users_google[0];
      user.users_google = [];
    }

    // Gestisco l'aggiornamento di alcuni campi dell'utente
    // solo se esiste l'utente google perché Codice Identificativo e Recovery email sono salvati li
    if (user.google_user) {

      // Controllo l'aggiornamento del codice identificativo se presente nel file
      if (user.cf && user.cf.length > 0) {
        // Controllo se forzare l'aggiornamento del codice fiscale anche quando è vuoto e già presente
        if (this.paramOvewriteCf || !this.directoryService.checkUserHasExternalIdValue(user.google_user, user.cf)) {
          user.new_cf = user.cf;
        }
      }

      // Controllo l'aggiornamento della recovery email se presente nel file
      if (user.email_recovery && user.email_recovery.length > 0) {
        // se non c'è la aggiorno
        if (!user.google_user.recoveryEmail) {
          user.new_email_recovery = user.email_recovery;
        }
        // se c'è ma è diversa e ho scelto di sovrascriverla l'aggiorno
        else if (user.google_user.recoveryEmail != user.email_recovery && this.paramOvewriteRecoveryEmail) {
          user.new_email_recovery = user.email_recovery;
        }
      }

      // Controllo l'aggiornamento della password se presente nel file
      if (user.password && user.password.length > 0) {
        let regex = new RegExp('[A-Za-z0-9@!]{8,16}');
        if (regex.test(user.password)) {
          // user.new_password = user.password; // Disabilito l'aggiornamento della password per ora ma lascio comunque il test di validità
        }
        else {
          if (!user.info_checked) {
            user.info.push({ type: "SETUP.preview_users.new_password_not_valid", data: user });
            user.info_checked = true;
          }
        }
      }
    }


    // Genero sempre la password se non esiste, ma la cancello dopo se non serve
    if (!user.password) {
      user.password = this.utilityService.generateRandomPassword(12);
      user.password_generated = true;
    }

    if (user.google_user) {

      // Cancello la password quando esiste già l'utente su google perché sarà specificato il campo new_password per aggiornarla
      user.password = null;
      user.password_generated = false;

      // se esiste l'utente su google e l'email non è stata generata dai parametri e durante l'importazione la colonna del nome e del cognome erano le stesse,
      // uso i dati di google per riempire nome e cognome
      if (user.email_fromparams === true && this.givenNameColIndex == this.familyNameColIndex) {

        if (user.google_user.name) {
          user.givenName = user.google_user.name?.givenName
          user.familyName = user.google_user.name?.familyName
        }
      }
    }

    if (!user.errors_checked) {
      // Se per qualche strano motivo esistono più utenti google rilevati, ad esempio uno con stessa mail e uno con stesso codice identificativo segno come errore
      if (user.users_google.length > 1) {
        user.errors.push({ type: "SETUP.preview_users.utenti_google_multipli", data: user });
        user.errors_checked = true;
      }

      // controllo se una mail generata dai parametri è senza nome e cognome
      if (user.email_generated_from_parameters && (!user.givenName || !user.familyName)) {
        user.errors.push({ type: "SETUP.preview_users.utente_senza_nome_cognome", data: user });
        user.errors_checked = true;
      }

      // Se il dominio della mail non è valido allora lo aggiungo tra gli errori
      if (user.email && !this.directoryService.checkEmailDomains(user.email)) {
        user.errors.push({ type: "SETUP.preview_users.utente_email_dominio_non_valido", data: user });
        user.errors_checked = true;
      }

      // controllo se l'utente google risulta sospeso lo segnalo come errore
      if (user.google_user && user.google_user.suspended && user.google_user.suspended == true) {
        user.errors.push({ type: "SETUP.preview_users.utente_google_sospeso", data: user });
        user.errors_checked = true;
      }
    }

    if (!user.warnings_checked) {
      // controllo che l'email sia simile a quella generata con i parametri
      // Se non esiste l'utente su google e quindi lo devo creare
      if (!user.google_user && user.email_generated_from_parameters != user.email) {
        user.warnings.push({ type: "SETUP.preview_users.email_not_match_parameters", data: user });
        user.warnings_checked = true;
      }
    }

    // Se sono presenti degli errori lo disabilito? Forse è meglio di no perché così non può andare avanti
    // if (user.errors.length > 0) {
    //   user.selected = false;
    // }

    return user;
  }

  /*
  .##.....##.########.####.##.......####.########.##....##
  .##.....##....##.....##..##........##.....##.....##..##.
  .##.....##....##.....##..##........##.....##......####..
  .##.....##....##.....##..##........##.....##.......##...
  .##.....##....##.....##..##........##.....##.......##...
  .##.....##....##.....##..##........##.....##.......##...
  ..#######.....##....####.########.####....##.......##...
  */

  isRowFiltered(row) {

    if (this.filtroColIndex != null && this.filtroColIndex >= 0 && this.paramFiltroColOptionsSelected.length > 0) {

      let col_value = ("" + row[this.filtroColIndex]).trim();

      let foundIndex = this.paramFiltroColOptionsSelected.findIndex((el) => {
        return el.name == col_value;
      });

      if (foundIndex == -1)
        return true;
    }

    return false;
  }

  checkOrgUnitInStruttura(struttura) {

    struttura.forEach(plesso_obj => {

      plesso_obj.errors = [];
      plesso_obj.warnings = [];

      // effettuo il controllo solo se il plesso è esistente
      if (plesso_obj.plesso.id) {

        if (!plesso_obj.plesso.google_org_unit || (plesso_obj.plesso.google_org_unit && !plesso_obj.plesso.google_org_unit.orgUnitPath)) {
          plesso_obj.plesso.selected = false;
          plesso_obj.errors.push({ type: "SETUP.preview_struttura.org_unit_mancante" });
        }

        plesso_obj.classi.forEach(classe_obj => {

          classe_obj.errors = [];

          // deseleziono tutte le classi quando ci sono errori nel plesso
          if (plesso_obj.errors.length > 0) {
            classe_obj.classe.selected = false;
          }

          // effettuo il controllo solo per le classi esistenti
          if (classe_obj.classe.id) {
            if (!classe_obj.classe.google_org_unit || (classe_obj.classe.google_org_unit && !classe_obj.classe.google_org_unit.orgUnitPath)) {
              classe_obj.classe.selected = false;
              classe_obj.errors.push({ type: "SETUP.preview_struttura.org_unit_mancante" });

              // Aggiungo nel plesso solo un warning per segnalare che ci sono classi con errori dentro
              if (plesso_obj.warnings.length == 0) {
                plesso_obj.warnings.push({ type: "SETUP.preview_struttura.org_unit_mancante" });
              }
            }
          }
        });
      }
    });

  }

  mergeUsers(user1, user2) {

    if (user1.materie || user2.materie) {
      // unisco le materie dei due utenti
      let materie1 = user1.materie;

      if (!materie1)
        materie1 = [];

      let materie2 = user2.materie;

      if (!materie2)
        materie2 = [];

      user1.materie = materie1.concat(materie2);

      user1.materie = user1.materie.filter((el, index, self) => {
        return index === self.findIndex((t) => (
          t.name == el.name
        ));
      })
    }

    // dopo aver fatto il merge disabilito il secondo utente
    user2.disabled = true;
    user2.selected = false;

    // Aggiungo il messaggio di info al secondo utente del perché è stato disabilitato
    let found = user2.info.find((el) => {
      if (el.type == "SETUP.preview_users.disabled_merged") {
        return true;
      }
      return false;
    })

    if (!found) {
      user2.info.push({ type: "SETUP.preview_users.disabled_merged", data: user2 });
    }
  }

  findAndMergeSafeDuplicate(struttura_with_users) {

    struttura_with_users.forEach(elemento_struttura_obj => {

      // se sto usando plesso e classi cerco duplicati nelle classi
      if (elemento_struttura_obj.classi) {

        elemento_struttura_obj.classi.forEach(classe_obj => {

          classe_obj.users.forEach(user => {

            if (user.omonimie.length == 0 && user.selected) {

              // cerco all'interno della stessa classe se ci sono due volte utenti
              // in tal caso unisco le info e le materie
              classe_obj.users.forEach(user_check => {

                if (user != user_check && user_check.omonimie.length == 0 && user.selected) {

                  // due utenti vengono considerati uguali quando hanno stessa email, stesso nome e cognome, stesso cf se specificato
                  // prima controlliamo se sono mappati, in tal caso basta controllare l'id, altrimenti si controllano i campi
                  if (user.user_geniusuite && user_check.user_geniusuite) {

                    if (user.user_geniusuite.user_id == user_check.user_geniusuite.user_id) {
                      this.mergeUsers(user, user_check);
                    }
                  }
                  else if (user.email == user_check.email && user.cf == user_check.cf) {
                    this.mergeUsers(user, user_check);
                  }
                }
              });
            }
          });
        });
      }
      else if (elemento_struttura_obj.organigramma) {

        // Per il monento non effettuo nessun merge negli organigrammi perché le materie non si possono associare
        // elemento_struttura_obj.users.forEach(user => {

        // });
      }


    });

    return struttura_with_users;
  }

  findDuplicate(struttura_with_users, user_selected = null) {

    struttura_with_users.forEach(elemento_struttura_obj => {

      // se sto usando plesso e classi cerco duplicati nelle classi
      if (elemento_struttura_obj.classi) {

        elemento_struttura_obj.classi.forEach(classe_obj => {

          classe_obj.users.forEach(user => {

            // se ho chiamato la funzione con l'utente selezionato devo cercare l'omonimia con l'utente selezionato
            if (user_selected) {

              // escludo l'utente stesso e se ha già un google_user di sicuro non può essere coinvolto in un omonimia perché la sua email è già esistente e probabilmente è stato recuperato anche tramite codice identificativo
              if (user != user_selected) {
                // Se trovo un utente con la stessa email potrebbe essere un'omonimia
                if (user_selected.email == user.email) {

                  // se non ho un codice identificativo in grado di distinguere i due utenti la considero come omonimia
                  // se hanno il codice identificativo oppure se sono diversi la considero come omonimia
                  if (user_selected.cf == null || user_selected.cf != user.cf) {

                    user.classe_obj = classe_obj;
                    user.plesso_obj = elemento_struttura_obj;
                    user_selected.omonimie.push(user);
                  }
                }
              }

            }
            // altrimenti significa che è stata chiamata per cercare tutte le omonimie
            // escludo da questa ricerca gli utenti che hanno già un utente in geniusuite e che hanno già un corrispettivo su google
            // se sono studenti però faccio sempre la ricerca di omonimie così ho la certezza che lo studente sarà unico
            else if (!user.user_geniusuite) {

              if (this.userTypeSelected == 'docente' && user.email_fromparams === true) {
                struttura_with_users = this.findDuplicate(struttura_with_users, user);
              }
              else if (this.userTypeSelected != 'docente') {
                struttura_with_users = this.findDuplicate(struttura_with_users, user);
              }
            }
          });
        });
      }
      else if (elemento_struttura_obj.organigramma) {

        elemento_struttura_obj.users.forEach(user => {

          // se ho chiamato la funzione con l'utente selezionato devo cercare l'omonimia con l'utente selezionato
          if (user_selected) {

            if (user != user_selected) {
              // Se trovo un utente con la stessa email potrebbe essere un'omonimia
              if (user_selected.email == user.email) {

                // se non ho un codice identificativo in grado di distinguere i due utenti la considero come omonimia
                // se hanno il codice identificativo oppure se sono diversi la considero come omonimia
                if (user_selected.cf == null || user_selected.cf != user.cf) {
                  user_selected.omonimie.push(user);
                }
              }
            }

          }
          // altrimenti significa che è stata chiamata per cercare tutte le omonimie
          // escludo da questa ricerca gli utenti che hanno già un utente in geniusuite e che hanno già un corrispettivo su google
          // se sono studenti però faccio sempre la ricerca di omonimie così ho la certezza che lo studente sarà unico
          else if ((!user.user_geniusuite && !user.google_user) || (this.userTypeSelected != 'docente')) {

            struttura_with_users = this.findDuplicate(struttura_with_users, user);
          }
        });
      }


    });

    return struttura_with_users;
  }

  findUserInStrutturaWithEmail(struttura_with_users: any, email: string) {

    let userFound = null;

    struttura_with_users.forEach(elemento_struttura_obj => {

      // se sto usando plesso e classi
      if (elemento_struttura_obj.classi) {

        elemento_struttura_obj.classi.forEach(classe_obj => {

          classe_obj.users.forEach(user => {

            if (user.email == email) {
              userFound = user;
            }

          });
        });
      }
      else if (elemento_struttura_obj.organigramma) {

        elemento_struttura_obj.users.forEach(user => {

          if (user.email == email) {
            userFound = user;
          }

        });
      }

    });


    return userFound;
  }

  getGroupStruttura(paramType: ParamType, paramData: any, relation_info: string, group_from_file: any = null, existing_obj: any = null) {

    let group_email = this.parametriService.getEmailParamsFor(paramType, paramData);

    let group;

    if (group_from_file) {
      group = group_from_file;
    }

    if (existing_obj && existing_obj.google_group) {
      // controllo se esiste il il gruppo associato con relation_info e gli do' priorità (sovrascrivo) rispetto a quello specificato da file
      existing_obj.google_group.forEach(group => {
        if (group.relation_info == relation_info) {
          group = group;
        }
      });
    }

    if (!group) {
      group = this.directoryService.getGroupByEmail(group_email);
    }

    if (!group) {
      group = {
        email: group_email
      };
    }

    group.relation_info = relation_info;

    return group;
  }

  addRandomCharsToEmail(email: string) {

    let parts = email.split('@');
    let random = Math.floor(Math.random() * 100);
    return parts[0] + random + '@' + parts[1];
  }



  saveFileAsJSON() {

    let dataJSON = JSON.stringify(this.data);
    var file = new File([dataJSON], "data-parsed.json", { type: "application/json;charset=utf-8" });
    FileSaver.saveAs(file);
  }

  startImportProcess(callback = (error) => { }) {

    let body = this.parseStrutturaForApi();

    this.backend.post(`importazione`, body).subscribe((result) => {

      this.importProcessStarted = true;

      this.toast.success('Importazione avviata');

      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }

  parseStrutturaForApi() {

    let struttura_api = [];

    this.struttura_with_users.forEach(element_struttura => {

      if (element_struttura.plesso) {

        let plesso = {
          plesso: this.covertStrutturaInfoDataForApi(element_struttura.plesso),
          classi: []
        };

        element_struttura.classi.forEach(element_classe => {

          if (element_classe.classe.selected) {

            let classe = {
              classe: this.covertStrutturaInfoDataForApi(element_classe.classe),
              users: []
            };

            element_classe.users.forEach(element_user => {

              if (element_user.selected) {

                let materie = [];

                if (element_user.materie) {
                  element_user.materie.forEach(materia => {
                    materie.push(materia.name);
                  });

                  // rendo univoci i nomi delle materie
                  materie = [...new Set(materie)];
                }

                let user = {
                  id: element_user.id,
                  givenName: element_user.givenName,
                  familyName: element_user.familyName,
                  email: element_user.email,
                  email_recovery: element_user.email_recovery,
                  cf: element_user.cf,
                  password: element_user.password,
                  userTypeSelected: element_user.userTypeSelected,
                  id_google: element_user.google_user ? element_user.google_user.id : null,
                  new_cf: element_user.new_cf,
                  new_email_recovery: element_user.new_email_recovery,
                  new_password: element_user.new_password,
                  materie: materie,
                }

                classe.users.push(user);
              }

            });

            plesso.classi.push(classe);
          }
        });

        struttura_api.push(plesso);
      }
      else if (element_struttura.organigramma) {

        let organigramma = {
          organigramma: this.covertStrutturaInfoDataForApi(element_struttura.organigramma),
          users: []
        }

        element_struttura.users.forEach(element_user => {

          if (element_user.selected) {

            let user = {
              id: element_user.id,
              givenName: element_user.givenName,
              familyName: element_user.familyName,
              email: element_user.email,
              email_recovery: element_user.email_recovery,
              cf: element_user.cf,
              password: element_user.password,
              userTypeSelected: element_user.userTypeSelected,
              id_google: element_user.google_user ? element_user.google_user.id : null,
              new_cf: element_user.new_cf,
              new_email_recovery: element_user.new_email_recovery,
              new_password: element_user.new_password,
            }

            organigramma.users.push(user);
          }

        });

        struttura_api.push(organigramma);
      }
    });

    return struttura_api;
  }

  covertStrutturaInfoDataForApi(data) {

    let data_api = {
      google_group: [],
      google_org_unit: null,
      id: data.id,
      name: data.name,
      short_name: data.short_name,
      anno: data.anno,
      options: data.options
    };


    data.google_group.forEach(element_group => {

      let group = {
        groupKey: element_group.id,
        id: element_group.id,
        email: element_group.email,
        relation_info: element_group.relation_info
      };

      // Se esiste la groupKey significa che l'ho recuperato dal nostro db altrimenti google usa il campo id
      if (element_group.groupKey) {
        group.groupKey = element_group.groupKey;
      }

      // Se i due campi sono uguali, significa che è stato recuperato da google e quindi annullo l'id per evitare di farlo confondere con il nostro id
      if (group.id == group.groupKey) {
        group.id = null;
      }

      data_api.google_group.push(group);
    });

    if (data.google_org_unit) {
      data_api.google_org_unit = {
        orgUnitId: data.google_org_unit.orgUnitId,
        orgUnitPath: data.google_org_unit.orgUnitPath,
        name: data.google_org_unit.name,
        parentOrgUnitId: data.google_org_unit.parentOrgUnitId,
        parentOrgUnitPath: data.google_org_unit.parentOrgUnitPath,
      };
    }
    else if (data.google_org_unit_new) {
      data_api.google_org_unit = {
        orgUnitId: null,
        orgUnitPath: data.google_org_unit_new.orgUnitPath,
        name: data.google_org_unit_new.name,
        parentOrgUnitId: data.google_org_unit_new.parentOrgUnitId,
        parentOrgUnitPath: data.google_org_unit_new.parentOrgUnitPath,
      };
    }

    return data_api;
  }

  saveUsersDataFile() {

    this.getStrutturaWithUsers((data) => {

      let users = [];

      if (data) {

        data.forEach(element_struttura => {

          if (element_struttura.plesso) {

            if (element_struttura.classi) {

              element_struttura.classi.forEach(classe => {

                if (classe.classe.selected && classe.users) {

                  classe.users.forEach(user => {

                    if (user.selected) {

                      // recupero l'elenco delle materie
                      let materie = "";
                      if (user.materie && user.materie.length > 0) {
                        user.materie.forEach(element => {
                          materie += "" + element.name + ", ";
                        });
                      }

                      users.push({
                        "Family Name": user.familyName,
                        "Given Name": user.givenName,
                        "Email": user.email,
                        "Recovery Email": user.email_recovery,
                        "Password": user.password ? user.password : user.new_password,
                        "Cod": user.cf,
                        "Plesso": element_struttura.plesso.name,
                        "Classe": classe.classe.name,
                        "Materie": materie
                      });
                    }
                  });
                }

              });
            }
          }

          if (element_struttura.organigramma) {

            element_struttura.users.forEach(user => {

              if (user.selected) {

                users.push({
                  "Family Name": user.familyName,
                  "Given Name": user.givenName,
                  "Email": user.email,
                  "Recovery Email": user.email_recovery,
                  "Password": user.password ? user.password : user.new_password,
                  "Cod": user.cf,
                  "Organigramma": element_struttura.organigramma.name,
                });
              }
            });
          }
        });
      }


      let filename = "import_geniusuite_" + (new Date().getTime());

      let wb = XLSX.utils.book_new();
      let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(users);
      XLSX.utils.book_append_sheet(wb, ws, "Geniusuite");
      XLSX.writeFile(wb, filename + ".xlsx");
    });
  }

  deleteFile() {

    this.file = null;
    this.data = null;
    this.importProcessStarted = false;
    this.deleteHeaderData();
    this.deleteStrutturaColsPreference();
    this.deleteUserColsPreference();
    this.deleteStruttura();
  }

  deleteDumpyData(data) {
    if (data.organigramma_obj)
      data.organigramma_obj = null;
    if (data.plesso_obj)
      data.plesso_obj = null;
    if (data.classe_obj)
      data.classe_obj = null;
    if (data.omonimie)
      data.omonimie = null;
    if (data.users_google)
      data.users_google = null;
    if (data.errors)
      data.errors = null;
    if (data.warnings)
      data.warnings = null;
    if (data.rows)
      data.rows = null;
    if (data.docenti)
      data.docenti = null;
    if (data.studenti)
      data.studenti = null;
    if (data.user_geniusuite)
      data.user_geniusuite = null;
  }

}
