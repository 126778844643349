import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { UserService } from '../general/user.service';
import { ToastService } from '../toast.service';

@Injectable({
  providedIn: 'root'
})
export class DeleteUsersService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  items: any[];

  constructor(private backend: BackendService, private toast: ToastService, private userService: UserService) {
    this.reset();
  }

  reset() {
    this.items = [];
    this._subjectToUpdate.next(this.items);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  addUsers(newItems) {

    newItems.forEach(item => {

      let itemFound = null;

      if (item.user) {
        itemFound = this.items.find((element) => element.user?.id == item.user?.id);
      }
      else if (item.id) {
        itemFound = this.items.find((element) => element.google_user?.id == item.id);
      }

      if (!itemFound) {

        let user = item;

        if (!user.user) {
          user = this.userService.getByGoogleId(item.id);
        }

        if (user) {
          this.items.push(user);
        }
        // non è un utente tracciato
        else {
          this.items.push({ google_user: item });
        }
      }
    });

    this._subjectToUpdate.next(this.items);
  }

  deleteItemsAtIndex(indexArray) {

    indexArray.forEach(idx => {
      this.items[idx] = null;
    });

    this.items = this.items.filter((el) => { return el != null });

    this._subjectToUpdate.next(this.items);
  }

  execOperation(callback = (error) => { }) {
    let users = [];

    this.items.forEach(user => {

      let userAdd = {
        id: null,
        google_id: null
      };

      if (user.user_id) {

        userAdd.id = user.user_id;
      }

      if (user.google_user) {
        userAdd.google_id = user.google_user.id;
      }

      if (userAdd.id || userAdd.google_id) {
        users.push(userAdd);
      }
    });

    this.backend.post("op/delete-users", { users: users }).subscribe((result) => {
      this.reset();
      this.toast.success('Eliminazione Utenti avviato');
      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }
}
