import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { CacheService, CacheType } from '../cache.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  data: any = [];
  link: any;
  last_update: Date;

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  constructor(private backend: BackendService, private toast: ToastService, private cacheService: CacheService, private utility: UtilityService) { }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  all(success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    if (this.cacheService.isCacheValid(CacheType.Announcement)) {
      let dataCloned = this.utility.cloneData(this.data);
      this._subjectToUpdate.next(dataCloned);
      success_callback(dataCloned);
      return;
    }

    this.backend.get(`announcement/all`).subscribe(
      (response) => {
        this.data = response;
        this.last_update = new Date();
        this.cacheService.updateCacheStatus(CacheType.Announcement, true);
        let dataCloned = this.utility.cloneData(this.data);
        this._subjectToUpdate.next(dataCloned);
        success_callback(dataCloned);
      }, (error) => {
        this.backend.showErrors(error);
        this._subjectToUpdate.next(null);
        error_callback(error);
      }
    );
  }

  forceUpdate(success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.cacheService.updateCacheStatus(CacheType.Announcement, false);
    this.all(success_callback, error_callback);
  }

  dismiss(announce) {

    this.data.forEach(element => {
      if (element.id == announce.id) {
        element.dismissed = true;
      }
    });

    let dataCloned = this.utility.cloneData(this.data);
    this._subjectToUpdate.next(dataCloned);
  }

}
