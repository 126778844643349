import { AbstractControl, ValidatorFn } from "@angular/forms";

export function paramsValidator(params: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
       //  recupero il valore del control
       let v = control.value;

       //  se non ci sono valori inseriti per me è il campo è sempre valido
       if (!v) return null;
 
       //  recupero il valore da controllare
       let e = params;
 
       // controllo possibili errori nei valori e divido i valori da cercare
       if (e) {
         //  pulisco la stringa
         e = e.replace(/ /g,'');
         let a = e.split(',');
 
         //  conrollo tutti parametri e se nella string non li trovo tutti allora non valido l'input
         for (let value of a) {
 
           //  controllo sia la condizione di AND che di OR
           let checkCondition = false;
           for (let valueOr of value.split('|')) {
             if (v.includes(valueOr)) {
               checkCondition = true;
             }
           }
 
           //  se non è rispettata la condizione allora non è validato
           if (!checkCondition)
             return { paramValidator: false}
         }
       }
 
       return null;
  };
}