import { Injectable, NgZone } from '@angular/core';
import { BackendService } from '../backend.service';
import { AccountService } from '../account.service';
import { UtilityService } from '../utility.service';
import { CacheService, CacheType } from '../cache.service';
import { Observable, Subject } from 'rxjs';
import { ToastService } from '../toast.service';

export enum CourseState {
  COURSE_STATE_UNSPECIFIED = "COURSE_STATE_UNSPECIFIED",
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  PROVISIONED = "PROVISIONED",
  DECLINED = "DECLINED",
  SUSPENDED = "SUSPENDED",
}

@Injectable({
  providedIn: 'root'
})
export class GoogleClassroomService {

  private baseApiPath = "https://classroom.googleapis.com/v1";
  private courses = [];

  private _subjectToUpdateCourses: Subject<any> = new Subject<any>();

  constructor(private backend: BackendService, private toast: ToastService, private accountService: AccountService, private ngZone: NgZone, private cacheService: CacheService, private utility: UtilityService) { }

  public getSubjectToUpdateCoursesObservable(): Observable<any> {
    return this._subjectToUpdateCourses.asObservable();
  }

  //#region Recupero Classroom
  /**
  * Recupero Lista Classroom
  */
  public coursesList(callbackOk = (result: any) => { }, callbackError = (error: any) => { }, callCallbackOkEachPage: boolean = false) {
    if (this.cacheService.isCacheValid(CacheType.GoogleClassroom)) {
      let dataCloned = this.utility.cloneData(this.courses);
      this._subjectToUpdateCourses.next(dataCloned);
      callbackOk(dataCloned);
      return;
    }

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + "/courses",
      method: "GET",
    };

    this.courses = [];

    this.listPaging(args, "courses", (result) => {
      this.ngZone.run(() => {
        this.courses = result;
        this.cacheService.updateCacheStatus(CacheType.GoogleClassroom, true);
        let dataCloned = this.utility.cloneData(this.courses);
        this._subjectToUpdateCourses.next(dataCloned);
        callbackOk(dataCloned);
      });
    }, (error) => {
      this.ngZone.run(() => {
        this._subjectToUpdateCourses.next(null);
        callbackError(error);
      });
    }, "courses", null, callCallbackOkEachPage);
  }

  //#region Recupero Classroom
  /**
  * Recupero Lista Classroom
  */
  public coursesListWithQuery(query: any, callbackOk = (result: any) => { }, callbackError = (error: any) => { }, callCallbackOkEachPage: boolean = false) {
    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + "/courses",
      method: "GET",
      params: query
    };

    let coursesWithQuery = [];

    this.listPaging(args, "courses", (result) => {
      this.ngZone.run(() => {
        coursesWithQuery = result;
        let dataCloned = this.utility.cloneData(coursesWithQuery);
        callbackOk(dataCloned);
      });
    }, (error) => {
      this.ngZone.run(() => {
        callbackError(error);
      });
    }, "courses", null, callCallbackOkEachPage);
  }

  /**
  * Recupero Annunci della Classroom
  * @param courseId Id della Classroom
  */
  public courseGet(courseId, callbackOk = (result: any) => { }, callbackError = (error: any) => { }) {
    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + `/courses/${courseId}`,
      method: "GET",
      params: {
        id: courseId
      },
    };
    let request = gapi.client.request(args);

    request.execute((response) => {
      this.ngZone.run(() => {
        if (response.error) {
          let res: any = { result: response };
          this.handleError(res, callbackError);
          return;
        }
        callbackOk(response);
      });
    })
  }

  public getCourse(courseId: string) {
    let u = this.courses.find(x => x.id == courseId);
    let dataCloned = this.utility.cloneData(u);
    return dataCloned;
  }
  //#endregion Recupero Classroom

  //#region Argomenti, Lavori, etc della Classroom
  /**
  * Recupero Annunci della Classroom
  * @param courseId Id della Classroom
  */
  public courseAnnouncementsList(courseId, callbackOk = (result: any) => { }, callbackError = (error: any) => { }) {

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + `/courses/${courseId}/announcements`,
      method: "GET",
      params: {
        courseId: courseId
      }
    };

    this.listPaging(args, "announcements", (result) => {
      this.ngZone.run(() => {
        let dataCloned = this.utility.cloneData(result);
        callbackOk(dataCloned);
      });
    }, (error) => {
      this.ngZone.run(() => {
        callbackError(error);
      })
    }, "announcements");
  }

  /**
  * Recupero Elenco Lavori Classroom
  * @param courseId Id della Classroom
  */
  public courseWorkList(courseId, callbackOk = (result: any) => { }, callbackError = (error: any) => { }) {

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + `/courses/${courseId}/courseWork`,
      method: "GET",
      params: {
        courseId: courseId
      }
    };

    this.listPaging(args, "courseWork", (result) => {
      this.ngZone.run(() => {
        callbackOk(result);
      });
    }, (error) => {
      this.ngZone.run(() => {
        callbackError(error);
      })
    }, "courseWork");
  }

  /**
* Recupero Elenco Lavori Classroom
* @param courseId Id della Classroom
*/
  public courseWorkMaterialsList(courseId, callbackOk = (result: any) => { }, callbackError = (error: any) => { }) {

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + `/courses/${courseId}/courseWorkMaterials`,
      method: "GET",
      params: {
        courseId: courseId
      }
    };

    this.listPaging(args, "courseWorkMaterial", (result) => {
      this.ngZone.run(() => {
        callbackOk(result);
      });
    }, (error) => {
      this.ngZone.run(() => {
        callbackError(error);
      })
    }, "courseWorkMaterial");
  }

  /**
  * Update Classroom
  * @param courseId Id del Corso
  * @param courseInfo Informazioni del Corso
  * @param toastNotification Notifica tramite toast
  */
  update(courseId, courseInfo, toastNotification = false, callbackOk = (data: any) => { }, callbackError = (error: any) => { }) {
    this.backend.put(`classroom/${courseId}`, courseInfo).subscribe((result) => {

      if (toastNotification)
        this.toast.success('Classroom Aggiornata');

      callbackOk(result);
    }, (error) => {
      callbackError(error);
    })
  }

  /**
  * Recupero Elenco Lavori Classroom
  * @param courseId Id della Classroom
  * @param workId Id del Lavoro dentro la Classroom
  */
  public courseWorkMaterialsGet(courseId, workId, callbackOk = (result: any) => { }, callbackError = (error: any) => { }) {

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + `/courses/${courseId}/courseWorkMaterials/${workId}`,
      method: "GET",
      params: {
        courseId: courseId,
        id: workId
      }
    };

    this.listPaging(args, "workMaterials", () => {
      this.ngZone.run(() => {
        callbackOk;
      });
    }, (error) => {
      this.ngZone.run(() => {
        callbackError(error);
      })
    }, "workMaterials");
  }



  /**
  * Recupero Argomenti della Classroom
  * @param courseId Id della Classroom
  */
  public courseTopicsList(courseId, callbackOk = (result: any) => { }, callbackError = (error: any) => { }) {

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + `/courses/${courseId}/topics`,
      method: "GET",
      params: {
        courseId: courseId
      }
    };

    this.listPaging(args, "topic", (result) => {
      this.ngZone.run(() => {
        callbackOk(result);
      });
    }, (error) => {
      this.ngZone.run(() => {
        callbackError(error);
      })
    }, "topic");

  }
  //#endregion Argomenti, Lavori, etc della Classroom

  //#region Membri Classroom
  /**
  * Recupero Lista Studenti in una Classroom
  * @param courseId Id della Classroom
  */
  public courseStudentList(courseId, callbackOk = (result: any) => { }, callbackError = (error: any) => { }) {

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + `/courses/${courseId}/students`,
      method: "GET",
      params: {
        courseId: courseId
      }
    };

    this.listPaging(args, "students", (result) => {
      this.ngZone.run(() => {
        callbackOk(result);
      });
    }, (error) => {
      this.ngZone.run(() => {
        callbackError(error);
      })
    }, "students");

  }

  /**
  * Recupero Lista Docenti in una Classroom
  * @param courseId Id della Classroom
  */
  public courseTeacherList(courseId, callbackOk = (result: any) => { }, callbackError = (error: any) => { }) {

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + `/courses/${courseId}/teachers`,
      method: "GET",
      params: {
        courseId: courseId
      }
    };

    this.listPaging(args, "teachers", (result) => {
      this.ngZone.run(() => {
        callbackOk(result);
      });
    }, (error) => {
      this.ngZone.run(() => {
        callbackError(error);
      })
    }, "teachers");

  }
  //#endregion Membri Classroom

  /**
   * Utility per gestire il paging di una chiamata List
   * @param args
   * @param callbackOk
   * @param callbackError
   * @param cachePropertyName Nome della variabile di classe che conserva la cache del risultato
   * @param pageToken
   */
  private listPaging(args, resultPropertyName, callbackOk = (result: any) => { }, callbackError = (error: any) => { }, cachePropertyName = null, previousResultData = null, pageToken = null, callCallbackOkEachPage: boolean = false) {

    if (pageToken) {

      if (!args.params) {
        args.params = {}
      }

      args.params.pageToken = pageToken;
    }

    let request = gapi.client.request(args);

    if (!previousResultData)
      previousResultData = []

    let list = previousResultData;

    request.execute((response) => {
      this.ngZone.run(() => {
        // se c'è un errore lo gestisco e blocco le chiamate
        if (response.error) {
          let res: any = { result: response };
          this.handleError(res, callbackError);
          return;
        }

        if (response[resultPropertyName])
          list = list.concat(response[resultPropertyName]);

        if (callCallbackOkEachPage) {
          let dataCloned = this.utility.cloneData(list);
          callbackOk(dataCloned);
        }

        // se c'è un'altra pagina chiamo ricorsivamente la funzione
        if (response.nextPageToken) {
          this.listPaging(args, resultPropertyName, callbackOk, callbackError, cachePropertyName, list, response.nextPageToken, callCallbackOkEachPage);
        }
        else {
          // solo quando le pagine sono finite chiamo la callback
          let dataCloned = this.utility.cloneData(list);
          callbackOk(dataCloned);
        }
      });
    });
  }

  /**
   * Gestisce l'errore delle chiamate a google api, monitorando l'errore 401 Unoutenticated ed effettua il refresh del token con il backend
   * Solo dopo aver fatto il refresh del token restituisce l'errore
   * @param response
   * @param callbackError
   */
  private handleError(response: gapi.client.HttpRequestRejected, callbackError = (error: any) => { }) {

    if (response.result.error.code == 401) {

      console.warn("Google Access Token scaduto, refresh token...");

      this.accountService.refreshUserData((user) => {

        gapi.client.setToken({ 'access_token': user.google_access_token.token });

        callbackError(response.result.error);

      }, () => {
        callbackError(response.result.error);
      });
    }
    else {
      callbackError(response.result.error);
    }
  }
}
