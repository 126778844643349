import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-tutorial-widget',
  templateUrl: './video-tutorial-widget.component.html',
  styleUrls: ['./video-tutorial-widget.component.scss']
})
export class VideoTutorialWidgetComponent implements OnInit {

  @Input() section: string;
  @Input() video_url: string;
  safeVideoUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {

    if (this.video_url) {
      this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
    }
  }

}
