import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MonitorService } from 'src/app/services/general/monitor.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-monitor-profile-info-card',
  templateUrl: './monitor-profile-info-card.component.html',
  styleUrls: ['./monitor-profile-info-card.component.scss']
})
export class MonitorProfileInfoCardComponent implements OnInit {

  @Input() id: any;

  alfredUrl = environment.alfredUrl;

  monitor: any;
  monitors: any[];
  error: any;

  showInfo = false;

  _loadingData = {
    monitor: false,
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  private _monitorServiceSubscription: Subscription;

  constructor(
    private monitorService: MonitorService) { }

  ngOnInit(): void {

    this.unsubscibeAll();

    this.error = null;
    this._loadingData.monitor = true;
    this._monitorServiceSubscription = this.monitorService.getSubjectToUpdateObservable().subscribe(
      (result) => {
        this._loadingData.monitor = false;

        if (result)
          this.monitors = result;

        this.init();
      });

    this.monitorService.all();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  unsubscibeAll() {
    if (this._monitorServiceSubscription)
      this._monitorServiceSubscription.unsubscribe();
  }

  init() {
    if (this.id) {
      if (this.monitors) {
        this.monitor = this.monitorService.get(this.id);
      } else {
        this.monitorService.all();
      }
    } else {
      this.genericError("monitor");
    }
  }

  genericError(typeError) {
    this.error = typeError;
    this._loadingData[typeError] = false;
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }
}
