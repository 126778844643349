import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { NotificationsService, NotificationType, SimpleNotificationsComponent } from 'angular2-notifications';
import { ToastService } from 'src/app/services/toast.service';

export enum ToastType {
  basic,
  success,
  alert,
  warn,
  info
}

@Component({
  selector: 'toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss']
})
export class ToastNotificationComponent extends SimpleNotificationsComponent {

  @ViewChild('notification') notificationHtml: TemplateRef<any>;

  //  setting per il servizio di notifiche
  notificationOptions = {
    position: ["bottom", "right"],
    showProgressBar: false,
    timeOut: 3000,
    clickToClose: true
  }

  subscription: any;
  currentMessage: any = {};

  constructor(private notify: NotificationsService, private toast: ToastService, private changeDetection: ChangeDetectorRef) {
    super(notify, changeDetection);
  }

  ngOnInit(): void {

    this.subscription = this.toast.onMessage().subscribe(context => {

      if (this.notificationHtml) {

        this.currentMessage = context;

        switch (this.currentMessage.type) {
          case 'success':
            this.currentMessage.color_class = "success";
            break;
          case 'error':
            this.currentMessage.color_class = "danger";
            break;
          case 'alert':
            this.currentMessage.color_class = "primary";
            break;
          case 'warn':
            this.currentMessage.color_class = "warning";
            break;
          case 'info':
            this.currentMessage.color_class = "info";
            break;
          default:
            this.currentMessage.color_class = "secondary";
            break;
        }


        this.notify.html(this.notificationHtml, NotificationType.Bare, null, null, context);
      }

    });
  }


}
