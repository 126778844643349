import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BackendService } from '../backend.service';
import { UserService } from '../general/user.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateAccountDomainService {

  private _subjectToUpdateUser: Subject<any> = new Subject<any>();
  private _subjectToUpdateGroup: Subject<any> = new Subject<any>();

  itemsUser: any[];
  itemsGroup: any[];

  constructor(private backend: BackendService, private userService: UserService, private toast: ToastService, private utilityService: UtilityService) {
    this.reset();
  }

  reset() {
    this.itemsUser = [];
    this.itemsGroup = [];
    this._subjectToUpdateUser.next(this.itemsUser);
    this._subjectToUpdateGroup.next(this.itemsGroup);
  }

  public getSubjectToUpdateUserObservable(): Observable<any> {
    return this._subjectToUpdateUser.asObservable();
  }

  public getSubjectToUpdateGroupObservable(): Observable<any> {
    return this._subjectToUpdateGroup.asObservable();
  }

  addAccount(newItems, type) {

    newItems.forEach(item => {
      if (type == "USER") {
        let itemFound = this.itemsUser.find((element) => element?.id == item?.id);

        if (!itemFound) {
          this.itemsUser.push({ ...item, type: type });
        }
      }

      if (type == "USER_NOT_SYNC") {
        let itemFound = this.itemsUser.find((element) => element?.id == item?.id);

        if (!itemFound) {
          this.itemsUser.push({ ...item, type: "USER" });
        }
      }

      if (type == "GROUP") {
        let itemFound = this.itemsGroup.find((element) => element?.id == item?.id);

        if (!itemFound) {
          this.itemsGroup.push({ ...item, type: type });
        }
      }
    });

    if (type == "USER")
      this._subjectToUpdateUser.next(this.itemsUser);

    if (type == "GROUP")
      this._subjectToUpdateGroup.next(this.itemsGroup);
  }

  deleteItemsAtIndex(indexArray, type) {
    if (type == "USER") {
      indexArray.forEach(idx => {
        this.itemsUser[idx] = null;
      });

      this.itemsUser = this.itemsUser.filter((el) => { return el != null });

      this._subjectToUpdateUser.next(this.itemsUser);
    }

    if (type == "GROUP") {
      indexArray.forEach(idx => {
        this.itemsGroup[idx] = null;
      });

      this.itemsGroup = this.itemsGroup.filter((el) => { return el != null });

      this._subjectToUpdateGroup.next(this.itemsGroup);
    }

  }

  execOperation(domain_user, domain_group, callback = (error) => { }) {

    let members = [];

    this.itemsUser.forEach(element => {
      let checkDomain = element.primaryEmail.split("@")[1];

      if (checkDomain != domain_user) {
        members.push({
          key: element.id,
          type: element.type,
          email: element.primaryEmail.split("@")[0] + "@" + domain_user
        });
      }
    });

    this.itemsGroup.forEach(element => {
      let checkDomain = element.email.split("@")[1];

      if (checkDomain != domain_group) {
        members.push({
          key: element.id,
          type: element.type,
          email: element.email.split("@")[0] + "@" + domain_group
        });
      }
    });

    this.backend.post("op/update-account-domain", { members: members }).subscribe((result) => {
      this.reset();
      this.toast.success('Aggiornamento avviato');
      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }
}
