import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService } from '../services/account.service';
import { CheckVersionService } from '../services/check-version.service';

@Injectable()
export class AccessTokenGuard implements CanActivate {

  constructor(
    private router: Router,
    private accountService: AccountService,
    private checkVersionService: CheckVersionService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.checkVersionService.manutenzione) {
      this.router.navigate(['/manutenzione']);
      return false;
    }

    if (this.accountService.isGoogleWorkspaceAccount()) {

      if (route.data && route.data.permissions && route.data.permissions.length > 0) {

        for (let p in route.data.permissions) {
          let perm = route.data.permissions[p];
          // if (this.accountService.checkPermission(perm))
          //   return true;
        }

        return false;
      }

      // logged in so return true
      return true;
    }

    this.router.navigate(['/'], { queryParams: { returnUrl: state.url.split(";")[0] } }); //returnUrlParams: JSON.stringify(route.params)
    return false;
  }
}
