import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { CacheService, CacheType } from '../cache.service';
import { GoogleDirectoryService } from '../google/google-directory.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  data: any = [];
  indexSelected: any;

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  constructor(
    private backend: BackendService,
    private toast: ToastService,
    private googleDirectoryService: GoogleDirectoryService,
    private cacheService: CacheService,
    private utility: UtilityService,
    private translate: TranslateService
  ) { }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  all(success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    if (this.cacheService.isCacheValid(CacheType.User)) {
      let dataCloned = this.utility.cloneData(this.data);
      this._subjectToUpdate.next(dataCloned);
      success_callback(dataCloned);
      return;
    }

    this.backend.paginate("user/all",
      (result) => {
        this.data = result;
        this.cacheService.updateCacheStatus(CacheType.User, true);
        let dataCloned = this.utility.cloneData(this.data);
        this._subjectToUpdate.next(dataCloned);
        success_callback(dataCloned);
      },
      (error) => {
        this.backend.showErrors(error);
        this._subjectToUpdate.next(null);
        error_callback(error);
      }
    );
  }

  /**
  * Create User
  * @param userInfo Informazioni dell'utente
  * @param toastNotification Notifica tramite toast
  */
  create(userInfo, toastNotification = false, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.backend.post("user", userInfo).subscribe((result) => {
      if (toastNotification) this.toast.success('Utente Aggiunto');
      success_callback(result);
    }, (error) => {
      if (toastNotification) this.toast.error("Errore, ricaricare la pagina se l'errore persiste");
      error_callback(error);
    })
  }


  /**
   * Update User
   * @param userId Id dell'utente
   * @param userInfo Informazioni dell'utente
   * @param toastNotification Notifica tramite toast
   */
  update(userId, userInfo, toastNotification = false, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.backend.put(`user/${userId}`, userInfo).subscribe(
      (result) => {
        if (toastNotification) this.toast.success('Utente Modificato');
        success_callback(result);
      },
      (error) => {
        if (toastNotification) this.toast.error("Errore, ricaricare la pagina se l'errore persiste");
        error_callback(error);
      }
    );
  }

  /**
   * Delete User
   * @param userId Id dell'utente
   * @param toastNotification Notifica tramite toast
   */
  delete(userId, toastNotification = false, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.backend.delete(`user/${userId}/delete`, null).subscribe(
      (result) => {
        if (toastNotification) this.toast.success('Utente Modificato');
        success_callback(result);
      },
      (error) => {
        if (toastNotification) this.toast.error("Errore, ricaricare la pagina se l'errore persiste");
        error_callback(error);
      }
    );
  }

  get(user_id, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    if (this.data) {

      let user = this.data.find(x => x.user_id == user_id);

      if (user) {
        if (user.google_id) {
          let googleUserInfo = this.googleDirectoryService.getUser(user.google_id);

          if (googleUserInfo)
            user.google_user = googleUserInfo;
        }

        if (user.classi) {
          user.classi.sort((data1, data2) => {
            let a = data1.classe ? data1.classe.name : null;
            let b = data2.classe ? data2.classe.name : null;
            let sortRes = 0;

            if (a == null && b != null)
              sortRes = -1;
            else if (a != null && b == null)
              sortRes = 1;
            else if (a == null && b == null)
              sortRes = 0;
            else if (typeof a === 'string' && typeof b === 'string')
              sortRes = a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
            else
              sortRes = (a < b) ? -1 : (a > b) ? 1 : 0;

            return sortRes;
          });
        }


        let dataCloned = this.utility.cloneData(user);
        success_callback(dataCloned);
        return dataCloned;
      }
      else {
        error_callback(null);
        return null;
      }
    } else {
      error_callback(null);
      return null;
    }
  }

  checkUserExistByEmail(email) {

    if (this.data) {

      let user = this.data.find(x => x.user?.email.toLowerCase() == email.toLowerCase());

      if (user)
        return true

      return false;
    }
  }

  getByEmail(email) {

    if (this.data) {

      let user = this.data.find(x => x.user?.email.toLowerCase() == email.toLowerCase());

      if (user)
        return this.get(user.user_id);

      return null;
    }
  }

  getByGoogleId(google_id) {
    if (this.data) {

      let user = this.data.find(x => x.google_id == google_id);

      if (user)
        return this.get(user.user_id);

      return null;
    }
  }

  getByRole(roleName) {
    let filtered = [];

    if (this.data) {

      this.data.forEach(user => {

        user.user.roles.forEach(role => {
          if (role.name == roleName) {
            filtered.push(this.get(user.user_id));
          }
        });
      });
    }

    return filtered;
  }

  excludeByRole(roleName) {
    let filtered = [];

    if (this.data) {

      this.data.forEach(user => {

        user.user.roles.forEach(role => {
          if (role.name != roleName) {
            filtered.push(this.get(user.user_id));
          }
        });
      });
    }

    return filtered;
  }

  getByMateriaAndRole(materiaId, roleName) {

    let filtered_by_role = this.getByRole(roleName);
    let filtered = [];

    filtered_by_role.forEach(user => {

      user.user.materie.forEach(materia => {

        if (materia.materia_id == materiaId) {
          filtered.push(this.get(user.user_id));
        }
      });

    });

    return filtered;
  }

  getByMateria(materiaId) {

    let filtered = [];

    if (this.data) {

      this.data.forEach(user => {

        user.user.materie.forEach(materia => {

          if (materia.materia_id == materiaId) {
            filtered.push(this.get(user.user_id));
          }
        });

      });
    }

    return filtered;
  }

  getByOrganigramma(organigrammaId) {

    let filtered = [];

    if (this.data) {

      this.data.forEach(user => {

        user.user.organigrammi.forEach(organigramma => {
          if (organigramma.id == organigrammaId) {
            filtered.push(this.get(user.user_id));
          }
        });
      });
    }

    return filtered;
  }

  getTable(result, mode = "standard", classe = null, plesso = null) {

    let table = [];

    result.forEach(element => {

      let user = null;

      // Recupero utente geniusuite da id
      if (element.user_id) {
        user = this.get(element.user_id);
      }

      // l'utente non è tracciato in geniusuite, quindi cerco in google
      // userId arriva in classroom
      if (!user) {

        let google_id;

        if (element.userId) {
          google_id = element.userId;
        }
        else if (element.id && element.id.length > 12) {
          google_id = element.id;
        }

        let google_user = this.googleDirectoryService.getUser(google_id);

        if (google_user) {
          // riprovo a recuperare tramite id google
          let geniusuite_user = this.getByGoogleId(google_user.id);

          if (geniusuite_user) {
            user = geniusuite_user;
          }
          else {
            user = {
              google_user: google_user
            }
          }
        }
        // quando un utente google è deleted viene gestito diversamente
        else if (mode == "deleted") {
          user = {
            google_user: element
          }
        }
      }

      // Recupero utente geniusuite da email
      if (!user && element.email) {

        user = this.getByEmail(element.email);

        // provo tramite google
        if (!user) {
          let google_user = this.googleDirectoryService.getUserByEmail(element.email);

          if (google_user) {
            user = {
              google_user: google_user
            }
          }
        }
      }

      // Se non lo trovo su google verifico la presenza di profile (per le classroom arriva)
      if (!user && element.profile) {
        user = {
          google_user: element.profile
        }
      }

      let familyName = null;
      let givenName = null;
      let fullname = null;
      let email = null;
      let codice_identificativo = null;
      let is_google_user = false;
      let is_geniusuite_user = false;
      let organigrammi_list = [];
      let ruoli = [];
      let group_member_type = null;
      let org_unit = null;
      let suspended = null;

      // Dati base
      if (user?.user) {
        familyName = user.user.familyName;
        givenName = user.user.givenName;
        fullname = user.user.familyName + " " + user.user.givenName;
        email = user.user.email;
        is_geniusuite_user = true;
      }

      // Tipo come mebro di un gruppo
      if (element.type) {
        group_member_type = this.translate.instant('GROUP.' + element.type);
      }

      // Unità organizzativa da google
      if (element.orgUnitPath) {
        org_unit = element.orgUnitPath;
      }

      // Ultimo login
      let dateLastLoginTime = null;

      // Cancellazione
      let dateDeleted = null;

      if (element.deletionTime) {
        dateDeleted = new Date(element.deletionTime);
      }

      if (element.suspended) {
        suspended = element.suspended;
      }

      // Organigrammi
      if (user?.user?.organigrammi) {
        user.user.organigrammi.forEach(element => {
          organigrammi_list.push(element.name);
        });
      }

      // Ruoli Geniusuite
      if (user?.user?.roles) {
        user.user.roles.forEach(ruolo => {
          ruoli.push(this.translate.instant('STR.' + ruolo.name));
        });
      }

      // Classi
      let classi_list = null;

      // filtro le classi se viene passato il plesso
      if (plesso) {
        classi_list = (element.classi && element.classi.length > 0) ? element.classi.filter(el => el.classe.plesso_id == plesso.id).map(x => x.classe.name).join(", ") : null;
      }
      else {
        classi_list = (element.classi && element.classi.length > 0) ? element.classi.map(x => x.classe.name).join(", ") : null;
      }

      // Materie
      let materie = null;
      if (element.user && element.user.materie && element.user.materie.length > 0 && classe && element.user.materie.some(el => el.classe_id === classe.id)) {
        materie = (classe && element.user.materie && element.user.materie.length > 0) ? element.user.materie.filter(el => el.classe_id === classe.id).map(x => x.materia_name).join(", ") : null;
      }

      // Dati da Google
      if (user?.google_user) {

        dateLastLoginTime = (user.google_user.lastLoginTime != "1970-01-01T00:00:00.000Z") ? new Date(user.google_user.lastLoginTime) : "Mai connesso";

        if (user.google_user.name) {
          familyName = user.google_user.name?.familyName;
          givenName = user.google_user.name?.givenName;
          fullname = user.google_user.name?.familyName + " " + user.google_user.name?.givenName;
        }

        email = user.google_user.primaryEmail

        if (!email) {
          email = user.google_user.emailAddress;
        }

        // Codice identificativo
        if (user.google_user.externalIds) {
          user.google_user.externalIds.forEach(externalId => {
            if (externalId.type == 'organization') {
              codice_identificativo = externalId.value;
            }
          });
        }

        is_google_user = true;
      }

      // Se non ho trovato dati l'utente sarà stato cancellato
      if (!familyName) {
        familyName = "Utente esterno o cancellato";
        fullname = "Utente esterno o cancellato";
      }

      if (suspended && suspended === true) {
        fullname += " " + '<span class="badge badge-soft-warning">Sospeso</span>';
      }
      else if (dateDeleted) {
        fullname += " " + '<span class="badge badge-soft-danger">Eliminato</span>';
      }

      // Dati per esportazione
      let export_data = {
        cognome: familyName,
        nome: givenName,
        email: email,
        codice_identificativo: codice_identificativo,
        ruoli: ruoli?.join(',') || null,
        organigrammi: organigrammi_list?.join(',') || null,
        classe: classi_list,
        sync_geniusuite: is_geniusuite_user ? "SI" : "NO",
        sync_google: is_google_user ? "SI" : "NO",
        group_member_type: group_member_type,
        org_unit: org_unit,
        ultimo_accesso: dateLastLoginTime,
        data_eliminazione: dateDeleted,
        suspended: suspended ? "SI" : "NO",
      }

      // Dati per menu contestuale tasto destro
      let copy_data_key = "nome";

      let sync = {
        google: is_google_user,
        geniusuite: is_geniusuite_user
      }

      let row_name = {
        "nome": [fullname, email],
        "codice_identificativo": [codice_identificativo]
      };

      let row;

      // Modalità DATATABLE
      if (mode == "classe") {

        row = {
          // "classe": [position], // in una classe non visualizzo le altre classi, crea solo confusione
          "Materie": [materie],
          "ultimo_accesso": [dateLastLoginTime],
        };

      } else if (mode == "classroom") {

        row = {
          "ultimo_accesso": [dateLastLoginTime],
        };

      } else if (mode == "gruppo") {

        row = {
          "type": [group_member_type],
          "ruoli": ruoli,
          "ultimo_accesso": [dateLastLoginTime],
        };

      } else if (mode == "materia" || mode == "plesso") {

        row = {
          "classe": classi_list,
          "ultimo_accesso": [dateLastLoginTime],
        };

      } else if (mode == "orgunit") {

        row = {
          "org_unit": [org_unit]
        };

      } else if (mode == "deleted") {

        row = {
          "data_eliminazione": [dateDeleted]
        };

      } else { // Standard

        row = {
          "organigramma": organigrammi_list,
          "ruoli": ruoli,
          "ultimo_accesso": [dateLastLoginTime],
        };
      }

      let row_end = {
        "sync": sync,
        "_export_data": export_data,
        "_copyDataKey": copy_data_key,
      }

      table.push({ ...row_name, ...row, ...row_end });

    });



    return table;
  }


}
