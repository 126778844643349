import { Injectable } from '@angular/core';
import * as pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { environment } from '../../environments/environment';
import { BackendService } from './backend.service';
import { AccountService } from './account.service';


@Injectable({
  providedIn: 'root'
})
export class PusherService {

  private echo: Echo;

  constructor(private backend: BackendService, private accountService: AccountService) {

    this.echo = new Echo({
      authEndpoint: environment.channelAuthEndpoint,
      broadcaster: 'pusher',
      key: environment.pusherApiKey,
      wsHost: environment.pusherHost,
      wsPort: environment.pusherPort,
      wssPort: environment.pusherPort,
      enabledTransports: ['ws', 'wss'],
      cluster: 'eu',
      forceTLS: (environment.pusherHost == '127.0.0.1' || environment.pusherHost == 'localhost' || environment.pusherHost == 'geniusuite.test') ? false : true,
      disableStats: false,
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            this.backend.post(environment.channelAuthEndpoint, {
              socket_id: socketId,
              channel_name: channel.name,
            }).subscribe(
              (response) => {
                callback(false, response);
              },
              (error) => {
                callback(true, error);
              });
          },
        };
      },
    });
  }

  listenToActivityChannel(eventName1, eventName2, callback: Function) {

    if (!this.accountService.isLoggedIn()) {
      console.error("Pusher service: not logged in");
    }

    let ente_id = this.accountService.data.ente.id;

    if (ente_id && ente_id > 0) {
      this.echo.private("activity." + ente_id).listen(eventName1, callback).listen(eventName2, callback);
    }
    else {
      console.error("Pusher service: ente not defined");
    }
  }

  listenToUserChannel(eventName, callback: Function) {

    if (!this.accountService.isLoggedIn()) {
      console.error("Pusher service: not logged in");
    }

    let user_id = this.accountService.data.id;

    if (user_id && user_id > 0) {
      this.echo.private("user." + user_id).listen(eventName, callback);
    }
    else {
      console.error("Pusher service: user not defined");
    }
  }

}
