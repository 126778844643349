import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleClassroomService } from 'src/app/services/google/google-classroom.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';

@Component({
  selector: 'app-classroom-profile-datatable-card',
  templateUrl: './classroom-profile-datatable-card.component.html',
  styleUrls: ['./classroom-profile-datatable-card.component.scss']
})
export class ClassroomProfileDatatableCardComponent implements OnInit {
  @Input() id: any;
  course: any;
  data = {
    students: [],
    teachers: [],
    courseAnnouncements: [],
    courseTopics: [],
    courseWork: [],
    courseWorkMaterials: []
  };

  dataError = {
    students: null,
    teachers: null,
    courseAnnouncements: null,
    courseWork: null,
    courseWorkMaterials: null
  };

  error: any;

  showInfo = false;

  _loadingData = {
    course: false,
    students: false,
    teachers: false,
    courseAnnouncements: false,
    courseTopics: false,
    courseWork: false,
    courseWorkMaterials: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  private _userServiceSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;
  private _googleServiceClassroomSubscription: Subscription;

  constructor(
    private classroomService: GoogleClassroomService,
    private translate: TranslateService,
    private googleDirectoryService: GoogleDirectoryService,
    private userService: UserService) { }

  ngOnInit(): void {

    this.unsubscibeAll();

    this.course = {
      id: this.id
    };

    this.error = null;

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.init();
      }
    });

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe(
      (result) => {
        if (result) {
          this.userService.all();
        }
      });

    this._googleServiceClassroomSubscription = this.classroomService.getSubjectToUpdateCoursesObservable().subscribe(
      (result) => {
        this.googleDirectoryService.usersList();
      });

    this.googleDirectoryService.usersList();

    this.selectTab(1);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  unsubscibeAll() {
    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();

    if (this._googleServiceClassroomSubscription)
      this._googleServiceClassroomSubscription.unsubscribe();
  }

  init() {
    if (this.id && this.id > 0) {
      this.getData();
    } else {
      this.genericError("course");
    }
  }

  getData() {
    this._loadingData.students = true;
    this.data.students = [];
    this.course.students = [];
    this.classroomService.courseStudentList(this.course.id, (result) => {
      if (result)
        this.course.students = result;

      this.studentsUpdated();
    }, () => {
      this.genericError("students");
    });

    this._loadingData.teachers = true;
    this.data.teachers = [];
    this.course.teachers = [];
    this.classroomService.courseTeacherList(this.course.id, (result) => {
      if (result)
        this.course.teachers = result;

      this.teachersUpdated();
    }, () => {
      this.genericError("teachers");
    });

    this._loadingData.courseWork = true;
    this._loadingData.courseWorkMaterials = true;
    this._loadingData.courseTopics = true;
    this.data.courseTopics = [];
    this.course.courseTopics = [];

    this.classroomService.courseTopicsList(this.course.id, (result) => {
      this._loadingData.courseTopics = false;
      if (result)
        this.course.courseTopics = result;

      // Carico Compiti
      this.data.courseWork = [];
      this.course.courseWork = [];
      this.classroomService.courseWorkList(this.course.id, (courseWork) => {
        if (courseWork)
          this.course.courseWork = courseWork;

        this.data.courseWork = this.parseCourseWorkResult(courseWork);
      }, () => {
        this.genericError("courseWork");
      });

      // Carico i Materiali
      this.data.courseWorkMaterials = [];
      this.course.courseWorkMaterials = [];
      this.classroomService.courseWorkMaterialsList(this.course.id, (courseWorkMaterials) => {
        if (courseWorkMaterials)
          this.course.courseWorkMaterials = courseWorkMaterials;

        this.data.courseWorkMaterials = this.parseCourseWorkMaterialsResult(courseWorkMaterials);
      }, () => {
        this.genericError("courseWorkMaterials");
      });
    }, () => {
      this.genericError("courseWork");
      this.genericError("courseWorkMaterials");
      this.genericError("courseTopics");
    });

    this._loadingData.courseAnnouncements = true;
    this.data.courseAnnouncements = [];
    this.course.courseAnnouncements = [];
    this.classroomService.courseAnnouncementsList(this.course.id, (result) => {
      if (result)
        this.course.courseAnnouncements = result;

      this.data.courseAnnouncements = this.parseCourseAnnouncementsResult(result);
    }, () => {
      this.genericError("courseAnnouncements");
    });

    this._loadingData.course = false;
  }

  genericError(typeError) {
    this.error = typeError;
    this._loadingData[typeError] = false;
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  selectTab(index) {
  }

  rowClickStudente(data) {

  }

  rowClickDocente(data) {

  }

  addClickUser(type) {

  }

  studentsUpdated() {
    this._loadingData.students = true;
    this.data.students = this.userService.getTable(this.course.students, "classroom");
    this._loadingData.students = false;
  }

  teachersUpdated() {
    this._loadingData.teachers = true;
    this.data.teachers = this.userService.getTable(this.course.teachers, "classroom");
    this._loadingData.teachers = false;
  }

  parseCourseAnnouncementsResult(result) {
    let table = [];
    if (result && result.length > 0)
      result.forEach(element => {
        let dateCreation = new Date(element.creationTime);
        let options: any = {
          year: "numeric",
          month: "short",
          day: "numeric"
        };

        let userCreator = this.getInfoUser({ userId: element.creatorUserId });

        let icon = "";
        let text = "";

        switch (element.state.toUpperCase()) {
          case "DELETED":
            icon = "fas fa-trash text-danger";
            break;
          case "DRAFT":
            icon = "fas fa-drafting-compass text-warning";
            break;
          case "PUBLISHED":
            icon = "fas fa-circle text-success";
            break;
          default:
            icon = "fas fa-horizontal-rule"
            break;
        }
        text = this.translate.instant('CLASSROOM.state.' + element.state.toUpperCase());

        let htmlIconState = `<i class="${icon}" alt="${text}" pTooltip="${text}"></i> ${text}`;

        table.push({
          "text": [element.text],
          "userCreator": [userCreator.fullname, userCreator.email],
          "creationTime": [dateCreation.toLocaleDateString('it-IT', options)],
          "state": [htmlIconState],
          "_export_data": {
            text: element.text,
            userCreator: userCreator.fullname,
            userCreatorEmail: userCreator.email,
            creationTime: dateCreation.toLocaleDateString('it-IT', options)
          },
        });
      });

    this._loadingData.courseAnnouncements = false;

    return table;
  }

  parseCourseWorkResult(result) {
    let table = [];
    if (result && result.length > 0)
      result.forEach(element => {

        let dateCreation = new Date(element.creationTime);
        let options: any = {
          year: "numeric",
          month: "short",
          day: "numeric"
        };

        let topic = null;

        if (element?.topicId) {
          topic = this.getInfoTopicTag(element.topicId)
        }

        let icon = "";
        let text = "";

        switch (element.workType.toUpperCase()) {
          case "ASSIGNMENT":
            icon = "fas fa-clipboard-list text-primary";
            break;
          case "SHORT_ANSWER_QUESTION":
            icon = "fas fa-question-square text-body";
            break;
          case "MULTIPLE_CHOICE_QUESTION":
            icon = "fas fa-question-circle text-body";
            break;
          default:
            icon = "fas fa-horizontal-rule"
            break;
        }
        text = this.translate.instant('CLASSROOM.workType.' + element.workType.toUpperCase());

        let htmlWorkType = `<i class="${icon}" alt="${text}"></i> ${text}`;
        let htmlExpand = null;

        if (element?.materials && element.materials.length > 0)
          htmlExpand = this.getParsedHtmlExpandData(element.materials);

        table.push({
          "expandData": htmlExpand,
          "title": [element.title, element.description],
          "workType": [htmlWorkType],
          "topic": [topic?.name],
          "creationTime": [dateCreation.toLocaleDateString('it-IT', options)]
        });

      });

    this._loadingData.courseWork = false;

    return table;
  }

  parseCourseWorkMaterialsResult(result) {

    let table = [];
    if (result && result.length > 0)
      result.forEach(element => {

        let dateCreation = new Date(element.creationTime);

        let options: any = {
          year: "numeric",
          month: "short",
          day: "numeric"
        };

        let topic = null;

        if (element?.topicId) {
          topic = this.getInfoTopicTag(element.topicId)
        }

        let icon = "";
        let text = "";

        switch (element.state.toUpperCase()) {
          case "DELETED":
            icon = "fas fa-trash text-danger";
            break;
          case "DRAFT":
            icon = "fas fa-drafting-compass text-warning";
            break;
          case "PUBLISHED":
            icon = "fas fa-circle text-success";
            break;
          default:
            icon = "fas fa-horizontal-rule"
            break;
        }
        text = this.translate.instant('CLASSROOM.state.' + element.state.toUpperCase());

        let htmlIconState = `<i class="${icon}" alt="${text}" pTooltip="${text}"></i> ${text}`;

        let htmlExpand = null;

        if (element?.materials && element.materials.length > 0)
          htmlExpand = this.getParsedHtmlExpandData(element.materials);

        table.push({
          "expandData": htmlExpand,
          "title": [element.title, element.description],
          "topic": [topic?.name],
          "creationTime": [dateCreation.toLocaleDateString('it-IT', options)],
          "state": [htmlIconState]
        });
      });

    this._loadingData.courseWorkMaterials = false;

    return table;
  }

  getInfoTopic(topicId) {
    let topic = this.course.courseTopics.find(x => x.topicId == topicId);
    return topic;
  }

  getInfoTopicTag(topicId) {
    let topic = this.course.courseTopics.find(x => x.topicId == topicId);
    let res = null;

    if (topic) {
      res = JSON.parse(JSON.stringify(topic));
      res.name = `<span class="badge badge-soft-primary badge-classroom-worktype">${topic.name}</span>`;
    }

    return res;
  }

  getInfoUser(infoUserCourse) {

    let resUser = {
      googleId: infoUserCourse.userId,
      familyName: null,
      givenName: null,
      fullname: null,
      email: null,
      is_google_user: false,
      is_geniusuite_user: false
    };

    let user = this.userService.getByGoogleId(infoUserCourse.userId);

    if (user) {
      // Sincronizzato con Geniusuite
      resUser.familyName = user.user.familyName;
      resUser.givenName = user.user.givenName;

      resUser.fullname = user.user.familyName + " " + user.user.givenName;
      resUser.email = user.user.email;
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = true;

      if (user.google_user) {
        resUser.googleId = infoUserCourse.userId;

        if (user.google_user.name) {
          resUser.familyName = user.google_user.name?.familyName;
          resUser.givenName = user.google_user.name?.givenName;
          resUser.fullname = user.google_user.name?.familyName + " " + user.google_user.name?.givenName;
        }

        resUser.email = user.google_user.primaryEmail
        resUser.is_google_user = true;
      }
    } else if (infoUserCourse?.profile && infoUserCourse.profile.emailAddress) {

      // Non sincronizzato con Geniusuite ma ha già i dati
      resUser.familyName = infoUserCourse.profile.name?.familyName;
      resUser.givenName = infoUserCourse.profile.name?.givenName;
      resUser.fullname = resUser.familyName + " " + resUser.givenName;
      resUser.email = infoUserCourse.profile.emailAddress;
      resUser.is_google_user = true;
      resUser.is_geniusuite_user = false;

    } else {

      let googleUser = this.googleDirectoryService.getUser(infoUserCourse.userId);

      if (googleUser) {
        // Non sincronizzato con Geniusuite ma non ha già i dati caricati
        resUser.familyName = "Utente cancellato";
        resUser.givenName = "";
        resUser.fullname = "Utente cancellato";

        if (googleUser.name) {
          resUser.familyName = googleUser.name?.familyName;
          resUser.givenName = googleUser.name?.givenName;
          resUser.fullname = googleUser.name?.familyName + " " + googleUser.name?.givenName;
        }

        resUser.email = googleUser.primaryEmail;
        resUser.is_google_user = true;
        resUser.is_geniusuite_user = false;

      } else {

        resUser.familyName = "Utente cancellato";
        resUser.givenName = "";
        resUser.fullname = "Utente cancellato";
        resUser.email = null;
        resUser.is_google_user = false;
        resUser.is_geniusuite_user = false;
      }
    }

    return resUser;
  }

  getParsedHtmlExpandData(materials) {
    let mat = '';

    materials.forEach(element => {
      if (element.form && "title" in element.form) {
        mat += `<a href="${element.form.formUrl}" target="_blank" class="ml-1 mr-1 mb-2"><div class="card card-material form"> <div class="row no-gutters"> <div class="col-md-4 card-material-img"> <img class="img-fluid" src="${element.form.thumbnailUrl}"> </div> <div class="col-md-8"> <div class="card-body pl-2 pt-2 pb-2"> <h5 class="card-title text-truncate"> ${element.form.title} </h5> <small class="card-text text-white">Google Forms</small> </div> </div> </div> </div></a>`
      }

      if (element.youtubeVideo && "title" in element.youtubeVideo) {
        mat += `<a href="${element.youtubeVideo.alternateLink}" target="_blank" class="ml-1 mr-1 mb-2"><div class="card card-material youtube"> <div class="row no-gutters"> <div class="col-md-4 card-material-img"> <img class="img-fluid" src="${element.youtubeVideo.thumbnailUrl}"> </div> <div class="col-md-8"> <div class="card-body pl-2 pt-2 pb-2"> <h5 class="card-title text-truncate"> ${element.youtubeVideo.title} </h5> <small class="card-text text-white">Youtube</small> </div> </div> </div> </div></a>`
      }

      if (element.driveFile && element.driveFile.driveFile && "title" in element.driveFile.driveFile) {
        mat += `<a href="${element.driveFile.driveFile.alternateLink}" target="_blank" class="ml-1 mr-1 mb-2"><div class="card card-material drive"> <div class="row no-gutters"> <div class="col-md-4 card-material-img"> <img class="img-fluid" src="${element.driveFile.driveFile.thumbnailUrl}"> </div> <div class="col-md-8"> <div class="card-body pl-2 pt-2 pb-2"> <h5 class="card-title text-truncate"> ${element.driveFile.driveFile.title} </h5> <small class="card-text">Google Drive</small> </div> </div> </div> </div></a>`
      }

      if (element.link && "title" in element.link) {
        mat += `<a href="${element.link.url}" target="_blank" class="ml-1 mr-1 mb-2"><div class="card card-material link"> <div class="row no-gutters"> <div class="col-md-4 card-material-img"> <img class="img-fluid" src="${element.link.thumbnailUrl}"> </div> <div class="col-md-8"> <div class="card-body pl-2 pt-2 pb-2"> <h5 class="card-title text-truncate"> ${element.link.title} </h5> <small class="card-text">Link</small> </div> </div> </div> </div></a>`
      }
    });

    return mat
  }
}
