import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-passaggio-anno-wizard-steps',
  templateUrl: './passaggio-anno-wizard-steps.component.html',
  styleUrls: ['./passaggio-anno-wizard-steps.component.scss']
})
export class PassaggioAnnoWizardStepsComponent implements OnInit {

  steps: any = [
    1, 2, 3, 4, 5
  ];

  @Input() currentStep: number;

  constructor() { }

  ngOnInit() {
  }

}
