import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ParametriService } from 'src/app/services/general/parametri.service';
import { UpdateSettingGroupsService } from 'src/app/services/operations/groups/update-setting-groups.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-update-setting-groups',
  templateUrl: './update-setting-groups.component.html',
  styleUrls: ['./update-setting-groups.component.scss']
})
export class UpdateSettingGroupsComponent implements OnInit {
  error: any;
  group_settings: any;
  showCustomSettings = true;
  _loadingData = {
    groups: false,
    parameter: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  data: any = [];
  groups: any;
  model: any;
  myForm: FormGroup = new FormGroup({});

  operationInProgress: boolean = false;
  errorNoGoogleAccount: boolean = false;

  private _updateSettingGroupsServiceSubscription: Subscription;

  private _parameterServiceSubscription: Subscription;

  constructor(
    private updateSettingGroupsService: UpdateSettingGroupsService,
    private parametriService: ParametriService,
    private syncdataService: SyncdataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initForm();
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.groups = this.updateSettingGroupsService.items;
    this.data = this.parseGroupsResult(this.groups);
  }

  parseGroupsResult(result) {

    let table = [];

    result.forEach(element => {
      table.push({
        "nome": [element.name, element.description],
        "email": [element.email],
        "membri": [element.directMembersCount],
        "_export_data": {
          name: element.name,
          description: element.description,
          email: element.email,
        },
      })
    });

    return table;
  }

  deleteEvent(rows) {
    this.updateSettingGroupsService.deleteItemsAtIndex(rows);
  }

  onSubmit() {
    this.group_settings = this.myForm.get('setting.group_settings').value;

    if (this.myForm.valid) {

      this.operationInProgress = true;

      this.updateSettingGroupsService.execOperation(this.group_settings, (error) => {
        if (!error) {
          this.initForm();
        }
      });
    }
  }

  resetOperation() {
    this.updateSettingGroupsService.reset();
  }

  unsubscibeAll() {


    if (this._updateSettingGroupsServiceSubscription)
      this._updateSettingGroupsServiceSubscription.unsubscribe();

    if (this._parameterServiceSubscription)
      this._parameterServiceSubscription.unsubscribe();


  }

  initForm() {
    this.myForm = new FormGroup({
      default_parameter: new FormControl((this.showCustomSettings || true)),
      setting: new FormGroup({
        group_settings: new FormControl(this.group_settings)
      })
    });
  }

  init() {
    this._parameterServiceSubscription = this.parametriService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.parametriServiceUpdated(result);
    });

    this._loadingData.parameter = true;
    this.parametriService.all();

    this._updateSettingGroupsServiceSubscription = this.updateSettingGroupsService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated();
      }
    });

    this.dataUpdated();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  changeValueSwitch(position, value) {
    this.myForm.get(position).setValue(value);
    this.showCustomSettings = value;
  }

  parametriServiceUpdated(result) {
    this._loadingData.parameter = false;
    this.group_settings = result.group_generic;
  }
}

