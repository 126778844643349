import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActivityService } from '../activity.service';
import { BackendService } from '../backend.service';
import { ToastService } from '../toast.service';

@Injectable({
  providedIn: 'root'
})
export class PassaggioAnnoService {

  _daysStartCheck: number = 120;

  // Configrazione
  config: any;

  // Struttura delle Classi
  struttura: any;

  // Organigramma Studenti Uscenti
  organigramma: any;

  _activity_in_progress: boolean = false;
  _activity_exist: boolean = false;
  _forza_nuovo_passaggio_anno: boolean = false;

  private _activitySubscription: Subscription;

  constructor(private ngZone: NgZone, private router: Router, private activityService: ActivityService, private backend: BackendService, private translate: TranslateService, private toast: ToastService) {

    this.resetConfig();

    this.resetOrganigramma();

    this.resetStruttura();

    this._activitySubscription = this.activityService.getSubjectToUpdateObservable().subscribe((result) => {
      this.checkActivity();
    });

    this.activityService.updateActivityData();
  }

  checkActivity() {

    let end_date = new Date();
    let start_date = new Date(end_date);

    start_date.setDate(start_date.getDate() - this._daysStartCheck);

    if (this.activityService.isActivityExistByRangeDate("operation.passaggioanno", start_date, end_date)) {
      this.setActivityExist(true);

      if (this.activityService.isActivityInProgress("operation.passaggioanno"))
        this.setActivityInProgress(true);
      else
        this.setActivityInProgress(false);

    } else {
      this.setActivityExist(false);
    }
  }

  setActivityExist(activity_exist) {
    this._activity_exist = activity_exist;
  }

  getActivityExist() {

    if (this._forza_nuovo_passaggio_anno)
      return false;

    return this._activity_exist;
  }

  setActivityInProgress(activity_in_progress) {
    this._activity_in_progress = activity_in_progress;
  }

  getActivityInProgress() {
    return this._activity_in_progress;
  }

  forzaNuovoPassaggioAnno() {

    // resetto il service
    this.resetConfig();
    this.resetOrganigramma();
    this.resetStruttura();

    this._forza_nuovo_passaggio_anno = true;
  }


  setOrganigramma(organigramma) {
    this.organigramma = organigramma;
  }

  getOrganigramma() {
    return this.organigramma;
  }

  getOrganigrammaDefault() {
    return {
      id: null,
      name: this.translate.instant('PASSAGGIOANNO.studenti_uscenti.name'),
      description: this.translate.instant('PASSAGGIOANNO.studenti_uscenti.description'),
      google_org_unit: null,
      orgUnitId: null
    };
  }

  setConfig(config) {
    this.config = config;
  }

  getConfig() {
    return this.config;
  }

  setStruttura(plessi) {
    this.struttura = [];

    plessi.forEach(plesso => {

      let struct = {
        isCollapsed: true,
        plesso: plesso,
        classi: [],
        selected: true
      }

      if (plesso?.classi) {
        plesso.classi.forEach(classe => {
          struct.classi.push(
            {
              isCollapsed: true,
              classe: classe,
              next_classe: null,
              next_classi_proposed: null,
              ...this.getConfig()
            }
          );
        });

        struct.classi.sort(function (a, b) {
          if (a.classe.name > b.classe.name)
            return -1;
          if (a.classe.name < b.classe.name)
            return 1;
          return 0;
        });
      }

      this.struttura.push(struct);
    });
  }

  getStruttura() {
    return this.struttura;
  }

  setNextClassRaccomandate() {
    // Algoritmo di Associazione della Classe Successiva
    let regExpNum = /[0-9]+/g;
    let regExpString = /[^0-9]+/g;

    let checker = (arr, target) => target?.some(v => arr?.includes(v));

    this.struttura.forEach((plesso, indexPlesso) => {
      plesso.classi.forEach((classe_struct, indexClasse) => {

        // controllo classe con <num>...
        let numbersArr = classe_struct.classe.name.match(regExpNum)?.map(x => {
          return (parseInt(x) + 1).toString();
        });

        // se non trovo nulla controllo classe con ...<num> invertendo la stringa del nome
        if (!numbersArr) {
          let classeNameReverse = classe_struct.classe.name.split("").reverse().join("");
          numbersArr = classeNameReverse.match(regExpNum)?.map(x => {
            return (parseInt(x) + 1).toString();
          });
        }

        // se non trovo nessun numero, provo a considerare 1 come numero e restituisco 2
        if (!numbersArr) {
          numbersArr = ["2"];
        }

        let sectionArr = classe_struct.classe.name.match(regExpString);

        let proposeClassi = plesso.classi.filter(x => {
          let numbersArrClasse = x.classe.name.match(regExpNum);
          let sectionArrClasse = x.classe.name.match(regExpString);

          // Check se è presente una classe successiva
          if (checker(numbersArrClasse, numbersArr) && checker(sectionArrClasse, sectionArr))
            return x;
        });

        if (!proposeClassi || proposeClassi.length == 0) {
          classe_struct.next_classe = null;
          classe_struct.next_classi_proposed = null;
        } else {
          // Gestione anche delle classi proposte come suggerimento, se l'Algoritmo ne rileva più di una
          classe_struct.next_classe = proposeClassi.length === 1 ? proposeClassi[0].classe : null;
          classe_struct.next_classi_proposed = proposeClassi.map(x => x.classe);
        }
      });
    });
  }

  private getExecData() {
    delete this.organigramma.google_org_unit;

    let dataBody = {
      docenti_migrate: false,
      studenti_migrate: true,
      organigramma: this.organigramma,
      plessi: []
    }

    this.struttura.forEach(element => {
      if (element.selected == true) {
        let plessoData = {
          plesso: {
            id: element.plesso.id
          },
          classi: element.classi.map(x => {
            return {
              classe_id: x.classe.id,
              next_classe_id: x.next_classe ? x.next_classe.id : null,
              orgunit_classe_preserve: x.orgunit_classe_preserve,
              group_classe_preserve: x.group_classe_preserve,
              group_classe_docente_preserve: x.group_classe_docente_preserve,
              group_classe_studente_preserve: x.group_classe_studente_preserve,
              rename_classe_groups: x.rename_classe_groups
            }
          })
        };

        dataBody.plessi.push(plessoData);
      }
    });

    return dataBody;
  }

  execOperation(callback = (error) => { }) {
    let body = this.getExecData();

    this.backend.post("op/passaggio-anno", body).subscribe((result) => {
      this.toast.success('Passaggio anno avviato');

      // resetto un eventuale forzatura del passaggio anno
      this._forza_nuovo_passaggio_anno = false;

      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }

  resetConfig() {

    this.config = {
      orgunit_classe_preserve: false,
      group_classe_preserve: false,
      group_classe_docente_preserve: false,
      group_classe_studente_preserve: false,
      rename_classe_groups: false,
    }
  }

  resetOrganigramma() {
    this.organigramma = this.getOrganigrammaDefault();
  }

  resetStruttura() {
    this.struttura = null;
  }
}
