import { Injectable } from '@angular/core';
import { BackendService } from '../backend.service';
import { ToastService } from '../toast.service';

@Injectable({
  providedIn: 'root'
})
export class ClassiService {

  constructor(private backend: BackendService, private toast: ToastService) { }

  /**
  * Create Classe
  * @param classeInfo Informazioni della Classe
  * @param idPlesso Id del Plesso
  * @param toastNotification Notifica tramite toast
  */
  create(classeInfo, idPlesso, toastNotification = false, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.backend.post(`plesso/${idPlesso}/classi`, classeInfo).subscribe((result) => {

      if (toastNotification)
        this.toast.success('Classe Aggiunta');

      success_callback(result);
    }, (error) => {
      error_callback(error);
    })
  }

  /**
  * Create Classe
  * @param classeInfo Informazioni della Classe
  * @param idClasse Id della Classe
  * @param idPlesso Id del Plesso
  * @param toastNotification Notifica tramite toast
  */
  update(classeInfo, idClasse, idPlesso, toastNotification = false, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.backend.put(`plesso/${idPlesso}/classi/${idClasse}`, classeInfo).subscribe((result) => {

      if (toastNotification)
        this.toast.success('Classe Modificata');

      success_callback(result);
    }, (error) => {
      error_callback(error);
    })
  }

  delete(idClasse, idPlesso, deleteInfo, toastNotification = false, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.backend.delete(`plesso/${idPlesso}/classi/${idClasse}/delete`, deleteInfo).subscribe((result) => {

      if (toastNotification)
        this.toast.success('Classe Eliminata');

      success_callback(result);
    }, (error) => {
      error_callback(error);
    })
  }

}
