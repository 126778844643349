<!-- Card -->
<div class="card">
  <div class="card-body">
    <div class="row align-items-sm-center py-sm-10">
      <div class="col-sm-6">
        <div class="text-center text-sm-right mr-sm-4 mb-5 mb-sm-0">
          <img class="w-60 w-sm-100 mx-auto" src="./assets/theme/svg/illustrations/think.svg" alt="Image Description"
            style="max-width: 15rem;">
        </div>
      </div>

      <div class="col-sm-6 col-md-4 text-center text-sm-left">
        <h1 class="display-1 mb-0">404</h1>
        <p class="lead">Scusa, la pagina che stavi cercando non esiste</p>
        <a class="btn btn-primary" routerLink="/dashboard">Torna alla dashboard</a>
      </div>
    </div>
  </div>
</div>
<!-- End Card -->
