import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActivityService } from 'src/app/services/activity.service';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { RelationInfo } from 'src/app/services/general/relation.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { PassaggioAnnoService } from 'src/app/services/operations/passaggio-anno.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-passaggio-anno-wizard-config',
  templateUrl: './passaggio-anno-wizard-config.component.html',
  styleUrls: ['./passaggio-anno-wizard-config.component.scss']
})
export class PassaggioAnnoWizardConfigComponent implements OnInit {

  config: any;
  organigramma: any;
  organigrammaAllCorrect: any = false;
  loadingUpdOrganigramma: boolean = false;
  editMode: boolean = false;

  showIntegrityErrosAlert: boolean = false;
  showAdvancedSettings: boolean;

  private _organigrammaServiceSubscription: Subscription;
  private _googleServiceOrgUnitsSubscription: Subscription;
  private _activitySubscription: Subscription;
  private _integrityErrorsSubscription: Subscription;

  constructor(
    private router: Router,
    private activityService: ActivityService,
    private googleService: GoogleDirectoryService,
    private organigrammaService: OrganigrammaService,
    private passaggioAnnoService: PassaggioAnnoService,
    private syncdataService: SyncdataService
  ) { }

  ngOnInit(): void {

    this.showAdvancedSettings = false;

    this.passaggioAnnoService.checkActivity();

    if (this.passaggioAnnoService.getActivityExist())
      if (this.passaggioAnnoService.getActivityInProgress())
        this.router.navigate(['/passaggio-anno/wait']);
      else
        this.router.navigate(['/passaggio-anno/finish']);

    this.config = this.passaggioAnnoService.getConfig();
    this.organigramma = this.passaggioAnnoService.getOrganigramma();

    this._organigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.organigrammaUpdated(result);
    });

    this.organigrammaService.all();

    this._googleServiceOrgUnitsSubscription = this.googleService.getSubjectToUpdateOrgUnitsObservable().subscribe((result) => {
      if (result)
        this.organigrammaService.all();
    });

    this._activitySubscription = this.activityService.getSubjectToUpdateObservable().subscribe((result) => {
      this.googleService.orgunitsList();
      this.checkOrganigrammaOperationInProgress();
    });

    this._integrityErrorsSubscription = this.syncdataService.getSubjectToUpdateObservableSyncIntegrity().subscribe((result) => {
      this.checkIntegrityErrors();
    });

    this.checkOrganigrammaOperationInProgress();
  }

  ngOnDestroy() {
    if (this._organigrammaServiceSubscription)
      this._organigrammaServiceSubscription.unsubscribe();
    if (this._googleServiceOrgUnitsSubscription)
      this._googleServiceOrgUnitsSubscription.unsubscribe();
    if (this._activitySubscription)
      this._activitySubscription.unsubscribe();
  }

  checkOrganigrammaOperationInProgress() {

    if (this.activityService.isActivityInProgress("organigramma.update") || this.activityService.isActivityInProgress("organigramma.create")) {
      this.loadingUpdOrganigramma = true;
    }
    else {
      this.loadingUpdOrganigramma = false;
    }
  }

  checkIntegrityErrors() {

    let errors = this.syncdataService.getAllSyncIntegrityErrors();

    this.showIntegrityErrosAlert = (errors.length > 0);
  }

  organigrammaUpdated(result) {

    this.organigramma = null;
    this.organigrammaAllCorrect = false;
    let organigrammaFind = result.find(element => element.relation_info == RelationInfo.RelationInfoOrganigrammaStudentiUscenti && !element.parent_id);
    this.organigramma = this.passaggioAnnoService.getOrganigrammaDefault();

    if (organigrammaFind) {

      this.editMode = true;

      this.organigrammaService.get(organigrammaFind.id, (res) => {

        this.organigramma.id = organigrammaFind.id;
        this.organigramma.name = organigrammaFind.name;
        this.organigramma.description = organigrammaFind.description;
        this.organigramma.google_org_unit = res?.google_org_unit;
        this.organigramma.orgUnitId = res?.google_org_unit?.orgUnitId || null;

        if (this.organigramma.google_org_unit && this.organigramma.orgUnitId) {

          let orgUnit = this.googleService.getOrgUnit(this.organigramma.orgUnitId);

          // Solo se ha un org unit associata ed esiste allora è tutto corretto
          if (orgUnit) {
            this.organigrammaAllCorrect = true;
          }
        }
      });
    } else {
      this.editMode = false;
      let orgUnit = this.googleService.getOrgUnitByPath("/" + this.organigramma.name);
      this.organigramma.orgUnitId = null;

      if (orgUnit) {
        this.organigramma.google_org_unit = orgUnit;
        this.organigramma.orgUnitId = orgUnit.orgUnitId;
      }
    }

    this.passaggioAnnoService.setOrganigramma(this.organigramma);
  }

  createOrUpdateOrganigramma() {

    this.loadingUpdOrganigramma = true;

    if (!this.organigramma.google_org_unit || this.organigramma.google_org_unit.not_found) {

      this.organigramma.orgUnitId = null;

      let orgUnit = this.googleService.getOrgUnitByPath("/" + this.organigramma.name);

      if (orgUnit) {
        this.organigramma.google_org_unit = orgUnit;
        this.organigramma.orgUnitId = orgUnit.orgUnitId;
      }
    }

    let reqBody = {
      name: "Studenti Uscenti",
      description: "Studenti Uscenti Descrizione",
      gestOrgUnit: true,
      newGroupStandard_U: false,
      orgUnitId: this.organigramma.orgUnitId,
      changeNameOrgUnit: false,
      groupKey_all: null,
      relation_info: {
        id: null,
        type: RelationInfo.RelationInfoOrganigrammaStudentiUscenti
      },
      gestGroup: false
    }

    if (this.organigramma.id) {
      // Update
      this.organigrammaService.update(this.organigramma.id, reqBody, true);
    } else {
      // Create
      this.organigrammaService.create(reqBody, true);
    }
  }

  back() {
    this.router.navigate(['/passaggio-anno/backup']);
  }

  next() {
    this.passaggioAnnoService.setConfig(this.config);
    this.passaggioAnnoService.setOrganigramma(this.organigramma);
    this.router.navigate(['/passaggio-anno/struttura']);
  }

  changeStatusCollapse() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }
}
