<ng-container *ngIf="getAccountService().isBootstrapCompleted()">
  <div class="navbar-vertical-container" *ngIf="account">
    <div class="navbar-vertical-footer-offset">
      <!-- Content -->
      <div class="navbar-vertical-content">
        <!-- Navbar Nav -->
        <ul class="navbar-nav navbar-nav-lg card-navbar-nav pt-0">

          <div *ngIf="!menuMini" class="card-header">
            <h4 class="card-header-title">Menu</h4>
            <!-- <small class="text-muted">2 days ago</small> -->
            <a class="btn btn-icon btn-xs btn-ghost-secondary rounded-circle" (click)="toggleMenuSize()">
              <i class="fas fa-compress-alt"></i>
            </a>
          </div>

          <li *ngIf="menuMini" class="nav-item">
            <a class="nav-link" title="Apri e Chiudi il menu" (click)="toggleMenuSize()">
              <i class="fas fa-bars nav-icon"></i>
            </a>
          </li>

          <!-- Dashboards -->
          <li class="nav-item">
            <a class="nav-link " title="Dashboards" [routerLink]="['/dashboard']">
              <!-- <i class="tio-home-vs-1-outlined nav-icon"></i> -->
              <i class="fad fa-home-alt nav-icon red"></i>
              <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Home</span>
            </a>
          </li>
          <!-- End Dashboards -->


          <ng-container *ngIf="!getParametriService().checkParametriConfigurazione()">

            <li class="navbar-vertical-aside-has-menu">
              <a class="nav-link" routerLink="/ente">
                <i class="fad fa-university nav-icon yellow"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{{ account?.ente.name
                }}</span>
                <i class="fal fa-ellipsis-h nav-subtitle-replacer nav-icon"></i>
              </a>
            </li>

          </ng-container>




          <ng-container *ngIf="getParametriService().checkParametriConfigurazione()">

            <li class="nav-item">
              <small class="nav-subtitle">Struttura scolastica</small>
              <i class="fal fa-ellipsis-h nav-subtitle-replacer nav-icon"></i>
            </li>

            <!-- Ente -->
            <li *ngIf="!menuMini" class="navbar-vertical-aside-has-menu">
              <a class="nav-link nav-link-toggle" (click)="isCollapsedEnte = !isCollapsedEnte"
                [attr.aria-expanded]="!isCollapsedEnte" aria-controls="collapseEnte">
                <i class="fad fa-university nav-icon yellow"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Ente</span>
                <i class="fal fa-ellipsis-h nav-subtitle-replacer nav-icon"></i>
              </a>

              <ul class="js-navbar-vertical-aside-submenu nav nav-sub" id="collapseEnte" [collapse]="isCollapsedEnte"
                [isAnimated]="true">
                <li class="nav-item">
                  <a class="nav-link" routerLink="/ente">
                    <span class="tio-circle nav-indicator-icon"></span>
                    <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{{ account?.ente.name
                      }}</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/user">
                    <!-- <i class="fad fa-user-plus font-size-sm mr-2"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Crea nuovo utente</span> -->
                    <button class="btn btn-xs btn-primary">
                      <i class="fas fa-user-plus font-size-sm mr-2"></i> Crea utente
                    </button>
                  </a>
                </li>
              </ul>
            </li>

            <!-- Versione dropdown -->
            <div *ngIf="menuMini" class="navbar-vertical-aside-has-menu aside-mini-menu-dropdown" dropdown
              #dropdown="bs-dropdown" placement="top right" container="body" [isAnimated]="false">
              <a class="nav-link nav-link-toggle" id="collapseEnteButton" aria-controls="collapseEnteDropdown"
                dropdownToggle title="Ente">
                <i class="fad fa-university nav-icon yellow"></i>
              </a>

              <div class="dropdown-menu aside-mini-dropdown-box js-navbar-vertical-aside-submenu nav nav-sub"
                id="collapseEnteDropdown" *dropdownMenu role="menu" aria-labelledby="collapseEnteButton">

                <div class="card-header pt-0">
                  <h5 class="card-header-title">Ente</h5>
                </div>

                <a routerLink="/ente" class="dropdown-item">
                  <span class="text-truncate pr-2">{{ account?.ente.name }}</span>
                </a>

                <a class="dropdown-item" routerLink="/user">
                  <!-- <i class="fad fa-user-plus font-size-sm mr-2"></i> Crea nuovo utente -->
                  <button class="btn btn-xs btn-primary">
                    <i class="fas fa-user-plus font-size-sm mr-2"></i> Crea utente
                  </button>
                </a>

              </div>
            </div>

            <!-- Organigramma -->
            <li *ngIf="!menuMini" class="navbar-vertical-aside-has-menu">
              <a class="nav-link nav-link-toggle" (click)="isCollapsedOrganigramma = !isCollapsedOrganigramma"
                [attr.aria-expanded]="!isCollapsedOrganigramma" aria-controls="collapseOrganigramma"
                title="Organigrammi">
                <i class="fad fa-sitemap nav-icon green"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Organigrammi</span>
                <i class="fal fa-ellipsis-h nav-subtitle-replacer nav-icon"></i>
              </a>

              <ul class="js-navbar-vertical-aside-submenu nav nav-sub" id="collapseOrganigramma"
                [collapse]="isCollapsedOrganigramma" [isAnimated]="true">
                <li class="nav-item">
                  <!-- [Standard] Docenti se non Esiste -->
                  <a *ngIf="!organigrammaDocenti" class="nav-link" [routerLink]="['/organigramma/create/2']">
                    <button class="btn btn-xs btn-primary">
                      <i class="fas fa-plus font-size-sm mr-2"></i> Crea organigramma Docenti
                    </button>
                  </a>

                  <!-- [Standard] Studenti se non Esiste -->
                  <a *ngIf="!organigrammaStudenti" class="nav-link" [routerLink]="['/organigramma/create/1']">
                    <button class="btn btn-xs btn-primary">
                      <i class="fas fa-plus font-size-sm mr-2"></i> Crea organigramma Studenti
                    </button>
                  </a>

                  <a *ngIf="organigrammaDocenti" class="nav-link " title="{{ organigrammaDocenti.name }}"
                    [routerLink]="['/organigramma', organigrammaDocenti.id]">
                    <span class="tio-circle nav-indicator-icon"></span>
                    <span class="text-truncate">{{ organigrammaDocenti.name }}</span>
                  </a>

                  <a *ngIf="organigrammaStudenti" class="nav-link " title="{{ organigrammaStudenti.name }}"
                    [routerLink]="['/organigramma', organigrammaStudenti.id]">
                    <span class="tio-circle nav-indicator-icon"></span>
                    <span class="text-truncate">{{ organigrammaStudenti.name }}</span>
                  </a>
                </li>

                <!-- <li class="nav-item" *ngFor="let element of organigramma">
              <a class="nav-link " title="{{ element.name }}" [routerLink]="['/organigramma', element.id]">
                <span class="tio-circle nav-indicator-icon"></span>
                <span class="text-truncate">{{ element.name }}</span>
              </a>
            </li> -->

                <li class="nav-item">
                  <a class="nav-link" routerLink="/organigramma">
                    <i class="fal fa-sitemap font-size-sm mr-2"></i>
                    <span class="text-truncate">Mappa</span>
                  </a>
                </li>

                <li class="nav-item">
                  <p-tieredMenu #testMenu [model]="organigrammaTreeMenu" [autoDisplay]="true" [popup]="true"
                    appendTo="body" styleClass="tieredMenuSidebar"></p-tieredMenu>
                  <a class="nav-link" (click)="testMenu.toggle($event)">
                    <i class="fal fa-bars font-size-sm mr-2"></i>
                    <span class="text-truncate">Tutti gli organigrammi</span>
                  </a>
                </li>

                <li class="nav-item">
                  <a *ngIf="organigrammaDocenti && organigrammaStudenti" class="nav-link"
                    [routerLink]="['/organigramma/create']">
                    <!-- <span class="tio-circle nav-indicator-icon"></span> -->
                    <button class="btn btn-xs btn-primary">
                      <i class="fas fa-plus font-size-sm mr-2"></i> Crea organigramma
                    </button>
                  </a>
                </li>
              </ul>
            </li>


            <!-- Versione dropdown -->
            <div *ngIf="menuMini" class="navbar-vertical-aside-has-menu aside-mini-menu-dropdown" dropdown
              #dropdown="bs-dropdown" placement="top right" container="body" [isAnimated]="false">
              <a class="nav-link nav-link-toggle" id="collapseOrganigrammiButton"
                aria-controls="collapseOrganigrammiDropdown" dropdownToggle title="Organigrammi">
                <i class="fad fa-sitemap nav-icon green"></i>
              </a>

              <div class="dropdown-menu aside-mini-dropdown-box js-navbar-vertical-aside-submenu nav nav-sub"
                id="collapseOrganigrammiDropdown" *dropdownMenu role="menu"
                aria-labelledby="collapseOrganigrammiButton">

                <div class="card-header pt-0">
                  <h5 class="card-header-title">Organigrammi</h5>
                  <small class="text-muted">{{ organigrammaTotalCount }}</small>
                </div>

                <!-- [Standard] Docenti se non Esiste -->
                <a *ngIf="!organigrammaDocenti" class="dropdown-item" title="Crea nuovo"
                  [routerLink]="['/organigramma/create/2']">
                  <!-- Crea nuovo Docenti -->
                  <button class="btn btn-xs btn-primary">
                    <i class="fas fa-plus font-size-sm mr-2"></i> Crea organigramma Docenti
                  </button>
                </a>

                <!-- [Standard] Studenti se non Esiste -->
                <a *ngIf="!organigrammaStudenti" class="dropdown-item" title="Crea nuovo"
                  [routerLink]="['/organigramma/create/1']">
                  <!-- Crea nuovo Studenti -->
                  <button class="btn btn-xs btn-primary">
                    <i class="fas fa-plus font-size-sm mr-2"></i> Crea organigramma Studenti
                  </button>
                </a>

                <a *ngIf="organigrammaDocenti" class="dropdown-item " title="{{ organigrammaDocenti.name }}"
                  [routerLink]="['/organigramma', organigrammaDocenti.id]">
                  <span class="text-truncate pr-2">{{ organigrammaDocenti.name }}</span>
                </a>

                <a *ngIf="organigrammaStudenti" class="dropdown-item " title="{{ organigrammaStudenti.name }}"
                  [routerLink]="['/organigramma', organigrammaStudenti.id]">
                  <span class="text-truncate pr-2">{{ organigrammaStudenti.name }}</span>
                </a>

                <!-- <ng-container *ngFor="let element of organigramma">
              <a class="dropdown-item" title="{{ element.name }}" [routerLink]="['/organigramma', element.id]">
                <span class="text-truncate pr-2">{{ element.name }}</span>
              </a>
            </ng-container> -->

                <a class="dropdown-item" routerLink="/organigramma">
                  <i class="fal fa-sitemap font-size-sm mr-2"></i>
                  <span class="text-truncate">Mappa</span>
                </a>

                <a *ngIf="organigrammaDocenti && organigrammaStudenti" class="dropdown-item" title="Crea nuovo"
                  [routerLink]="['/organigramma/create']">
                  <!-- Crea nuovo -->
                  <button class="btn btn-xs btn-primary">
                    <i class="fas fa-plus font-size-sm mr-2"></i> Crea organigramma
                  </button>
                </a>

              </div>
            </div>
            <!-- End Organigramma -->

            <!-- Plessi -->
            <!-- Versione larga -->
            <li *ngIf="!menuMini" class="navbar-vertical-aside-has-menu">
              <a class="nav-link nav-link-toggle " (click)="isCollapsedPlesso = !isCollapsedPlesso"
                [attr.aria-expanded]="!isCollapsedPlesso" aria-controls="collapsePlesso" title="Plessi">
                <i class="fad fa-school nav-icon blue"></i>
                <span class="text-truncate">Plessi e Classi</span>
              </a>

              <ul class="js-navbar-vertical-aside-submenu nav nav-sub" id="collapsePlesso"
                [collapse]="isCollapsedPlesso" [isAnimated]="true">
                <li class="nav-item" *ngFor="let element of plessi">
                  <a class="nav-link " title="{{ element.name }}" [routerLink]="['/plesso', element.id]">
                    <span class="tio-circle nav-indicator-icon"></span>
                    <span class="text-truncate">{{ element.name }}</span>
                  </a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" routerLink="/plesso/mappa">
                    <i class="fal fa-sitemap font-size-sm mr-2"></i>
                    <span class="text-truncate">Mappa</span>
                  </a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" title="Crea nuovo" [routerLink]="['/plesso']">
                    <!-- <span class="tio-circle nav-indicator-icon"></span>
                Crea nuovo -->
                    <button class="btn btn-xs btn-primary">
                      <i class="fas fa-plus font-size-sm mr-2"></i> Crea plesso
                    </button>
                  </a>
                </li>
              </ul>
            </li>

            <!-- Versione dropdown -->
            <div *ngIf="menuMini" class="navbar-vertical-aside-has-menu aside-mini-menu-dropdown" dropdown
              #dropdown="bs-dropdown" placement="top right" container="body" [isAnimated]="false">
              <a class="nav-link nav-link-toggle" id="collapsePlessoButton" aria-controls="collapsePlessoDropdown"
                dropdownToggle title="Plessi">
                <i class="fad fa-school nav-icon blue"></i>
              </a>

              <div class="dropdown-menu aside-mini-dropdown-box js-navbar-vertical-aside-submenu nav nav-sub"
                id="collapsePlessoDropdown" *dropdownMenu role="menu" aria-labelledby="collapsePlessoButton">

                <div class="card-header pt-0">
                  <h5 class="card-header-title">Plessi e Classi</h5>
                  <small class="text-muted">{{ plessi?.length }}</small>
                </div>

                <ng-container *ngFor="let element of plessi">
                  <a class="dropdown-item" title="{{ element.name }}" [routerLink]="['/plesso', element.id]">
                    <span class="text-truncate pr-2">{{ element.name }}</span>
                  </a>
                </ng-container>

                <a class="dropdown-item" routerLink="/plesso/mappa">
                  <i class="fal fa-sitemap font-size-sm mr-2"></i>
                  <span class="text-truncate">Mappa</span>
                </a>

                <a class="dropdown-item" [routerLink]="['/plesso']">
                  <!-- <span class="text-truncate pr-2">Crea nuovo</span> -->
                  <button class="btn btn-xs btn-primary">
                    <i class="fas fa-plus font-size-sm mr-2"></i> Crea plesso
                  </button>
                </a>
              </div>
            </div>
            <!-- End Plessi -->

            <!-- <li class="nav-item">
          <a class="nav-link " title="Scuola" [routerLink]="['/materia']">
            <i class="fad fa-books nav-icon"></i>
            <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Materie</span>
          </a>
        </li> -->

            <!-- Materie -->
            <li *ngIf="!menuMini" class="navbar-vertical-aside-has-menu">
              <a class="nav-link nav-link-toggle" (click)="isCollapsedMateria = !isCollapsedMateria"
                [attr.aria-expanded]="!isCollapsedMateria" aria-controls="collapseMateria">
                <i class="fad fa-books nav-icon orange"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Materie</span>
                <i class="fal fa-ellipsis-h nav-subtitle-replacer nav-icon"></i>
              </a>

              <ul class="js-navbar-vertical-aside-submenu nav nav-sub" id="collapseMateria"
                [collapse]="isCollapsedMateria" [isAnimated]="true">
                <li class="nav-item">
                  <a class="nav-link" routerLink="/materia">
                    <span class="tio-circle nav-indicator-icon"></span>
                    <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Vedi tutte le
                      materie</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/materia/create">
                    <button class="btn btn-xs btn-primary">
                      <i class="fas fa-plus font-size-sm mr-2"></i> Crea materia
                    </button>
                  </a>
                </li>
              </ul>
            </li>

            <!-- Versione dropdown -->
            <div *ngIf="menuMini" class="navbar-vertical-aside-has-menu aside-mini-menu-dropdown" dropdown
              #dropdown="bs-dropdown" placement="top right" container="body" [isAnimated]="false">
              <a class="nav-link nav-link-toggle" id="collapseMateriaButton" aria-controls="collapseMateriaDropdown"
                dropdownToggle title="Materia">
                <i class="fad fa-books nav-icon orange"></i>
              </a>

              <div class="dropdown-menu aside-mini-dropdown-box js-navbar-vertical-aside-submenu nav nav-sub"
                id="collapseMateriaDropdown" *dropdownMenu role="menu" aria-labelledby="collapseMateriaButton">

                <div class="card-header pt-0">
                  <h5 class="card-header-title">Materie</h5>
                  <!-- <small class="text-muted">{{ organigrammaTotalCount }}</small> -->
                </div>

                <a class="dropdown-item" routerLink="/materia">
                  <span class="text-truncate pr-2">Vedi tutte le materie</span>
                </a>

                <a class="dropdown-item" routerLink="/materia/create">
                  <button class="btn btn-xs btn-primary">
                    <i class="fas fa-plus font-size-sm mr-2"></i> Crea materia
                  </button>
                </a>

              </div>
            </div>

          </ng-container>

          <app-list-operation [position]="'sidebar'"></app-list-operation>

          <li class="nav-item">
            <div class="nav-divider"></div>
          </li>

          <li class="nav-item">
            <small class="nav-subtitle" title="Strumenti">Strumenti</small>
            <i class="fal fa-ellipsis-h nav-subtitle-replacer nav-icon"></i>
          </li>

          <!-- <li class="nav-item ">
          <a class="nav-link" routerLink="/user">
            <i class="fad fa-user-plus nav-icon"></i>
            <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Crea nuovo utente</span>
          </a>
        </li> -->

          <ng-container *ngIf="getParametriService().checkParametriConfigurazione()">

            <li class="nav-item ">
              <a class="nav-link" routerLink="/workspace-control-system">
                <i class="fad fa-solar-system nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Controlla la
                  Workspace</span>
              </a>
            </li>

            <li class="nav-item ">
              <a class="nav-link" routerLink="/cattedre">
                <i class="fad fa-chalkboard-teacher nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Gestione Cattedre</span>
              </a>
            </li>

          </ng-container>

          <li class="nav-item" *ngIf="getAccountService().isGoogleWorkspaceAccount()">
            <a class="nav-link" routerLink="/classroom">
              <i class="fad fa-users-class nav-icon"></i>
              <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Classroom</span>
            </a>
          </li>

          <ng-container *ngIf="getParametriService().checkParametriConfigurazione()">

            <li class="nav-item ">
              <a class="nav-link" routerLink="/req-change-password">
                <i class="fad fa-user-unlock nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Richieste password</span>
              </a>
            </li>

            <li class="nav-item ">
              <a class="nav-link" routerLink="/passaggio-anno">
                <i class="fad fa-user-graduate nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Passaggio anno</span>
              </a>
            </li>

            <li class="nav-item ">
              <a class="nav-link" routerLink="/setup">
                <i class="fad fa-magic nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Importazione guidata</span>
              </a>
            </li>

            <li class="nav-item ">
              <a class="nav-link" routerLink="/settings/gsuite">
                <i class="fab fa-google nav-icon"></i>
                <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Parametri Workspace</span>
              </a>
            </li>

          </ng-container>

          <li class="nav-item ">
            <a class="nav-link" routerLink="/monitor">
              <!-- <i class="fad fa-tv-retro nav-icon"></i> -->
              <span class="nav-icon"><img width="23" height="23" src="./assets/img/icons/alfred.svg"></span>
              <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Alfred</span>
            </a>
          </li>

          <li class="nav-item ">
            <a class="nav-link" (click)="logout()">
              <i class="fad fa-sign-out nav-icon"></i>
              <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Esci da Geniusuite</span>
            </a>
          </li>

        </ul>
        <!-- End Navbar Nav -->
      </div>
      <!-- End Content -->

      <!-- Footer -->
      <div class="navbar-vertical-footer">
        <ul class="navbar-vertical-footer-list">
          <li class="navbar-vertical-footer-list-item">
            <!-- Unfold -->
            <div class="hs-unfold">
              <a class="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle" routerLink="/settings">
                <i class="far fa-cog nav-icon"></i>
              </a>
            </div>
            <!-- End Unfold -->
          </li>

          <li class="navbar-vertical-footer-list-item">
            <!-- Other Links -->
            <div>
              <a class="btn btn-icon btn-ghost-secondary rounded-circle"
                href="https://geniusuite.com/guide-tutorial-geniusuite" target="_blank">
                <i class="far fa-question-circle nav-icon"></i>
              </a>
            </div>
            <!-- End Other Links -->
          </li>

          <li class="navbar-vertical-footer-list-item">
            <!-- Language -->
            <div class="btn-group" dropdown [isAnimated]="false" placement="top right">
              <a class="btn btn-icon btn-ghost-secondary rounded-circle" id="buttonLanguage" dropdownToggle
                aria-controls="languageDropdown">
                <img class="avatar avatar-xss avatar-circle" src="../assets/theme/flags/1x1/{{currentLang}}.svg">
              </a>

              <ul id="languageDropdown" *dropdownMenu aria-labelledby="buttonLanguage"
                class="dropdown-menu navbar-vertical-footer-dropdown">
                <span class="dropdown-header">Seleziona una lingua</span>
                <a class="dropdown-item" (click)="setLanguage('it')">
                  <img class="avatar avatar-xss avatar-circle mr-2" src="../assets/theme/flags/1x1/it.svg">
                  <span class="text-truncate pr-2" title="Italiano">Italiano</span>
                </a>
                <a class="dropdown-item" (click)="setLanguage('gb')">
                  <img class="avatar avatar-xss avatar-circle mr-2" src="../assets/theme/flags/1x1/gb.svg">
                  <span class="text-truncate pr-2" title="English">English</span>
                </a>
                <a class="dropdown-item" (click)="setLanguage('es')">
                  <img class="avatar avatar-xss avatar-circle mr-2" src="../assets/theme/flags/1x1/es.svg">
                  <span class="text-truncate pr-2" title="Spanish">Español</span>
                </a>
              </ul>
            </div>
            <!-- End Language -->
          </li>
        </ul>
      </div>
      <!-- End Footer -->
    </div>
  </div>
</ng-container>
