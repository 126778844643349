import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ActivityService } from 'src/app/services/activity.service';
import { CacheService } from 'src/app/services/cache.service';
import { RequestChangePasswordService } from 'src/app/services/general/request-change-password.service';
import { UserService } from 'src/app/services/general/user.service';
import { UpdatePasswordService } from 'src/app/services/operations/update-password.service';
import { SyncdataService } from 'src/app/services/syncdata.service';
import { ToastService } from 'src/app/services/toast.service';
import { CardDatatableNgComponent } from 'src/app/utility/card-datatable-ng/card-datatable-ng.component';

@Component({
  selector: 'app-request-change-password-page',
  templateUrl: './request-change-password-page.component.html',
  styleUrls: ['./request-change-password-page.component.scss']
})
export class RequestChangePasswordPageComponent implements OnInit {

  @ViewChild('utentiVerificatiCardDatatable') utentiVerificatiCardDatatable: CardDatatableNgComponent;
  @ViewChild('utentiNonVerificatiCardDatatable') utentiNonVerificatiCardDatatable: CardDatatableNgComponent;

  data_verificati: any = [];
  data_non_verificati: any = [];
  richieste: any;
  model: any;
  link: any;
  last_update: Date;
  isLoadingData: boolean;
  activity_in_progress: boolean;

  showErrorRichiesteNonConfermate: boolean = false;

  currentTab: number;

  private _serviceSubscription: Subscription;
  private _activitySubscription: Subscription;
  private _userServiceSubscription: Subscription;


  constructor(
    private updatePasswordService: UpdatePasswordService,
    private activityService: ActivityService,
    private requestChangePasswordService: RequestChangePasswordService,
    private userService: UserService,
    private translate: TranslateService,
    private clipboardService: ClipboardService,
    private toast: ToastService,
    private syncdataService: SyncdataService,
    private router: Router,
    private cacheService: CacheService
  ) { }

  ngOnInit() {
    this.activity_in_progress = false;

    this._serviceSubscription = this.requestChangePasswordService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.updateUser(result);
    });

    this.requestChangePasswordService.linkForm((data) => {
      if (data)
        this.link = data;
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result && this.richieste) {
        this.updateUser(this.richieste);
      }
    });

    this._activitySubscription = this.activityService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result && this.activityService.isActivityExist("operation.users.update-password"))
        if (this.activityService.isActivityInProgress("operation.users.update-password")) {
          this.activity_in_progress = true;
        } else {
          this.activity_in_progress = false;
          this.forceUpdate();
        }
    });

    this.userService.all();
    this.requestChangePasswordService.all();
  }

  ngOnDestroy() {

    if (this._serviceSubscription)
      this._serviceSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
  }

  onSubmit(myForm: NgForm) {

    if (myForm.valid) {


    }
  }

  updateUser(result) {

    this.richieste = result;
    this.last_update = this.requestChangePasswordService.last_update;
    this.parseUsersResult(this.richieste);
  }

  parseUsersResult(result) {

    this.data_non_verificati = [];
    this.data_verificati = [];

    result.forEach(element => {

      let user = this.userService.getByEmail(element.email);

      let name = element.last_name + " " + element.first_name
      let email = element.email;
      let is_google_user = false;
      let confermata = element.confirmed == 1 ? true : false;
      let status = element.status;

      this.showErrorRichiesteNonConfermate = true;

      let organigrammi_list = [];
      let ruoli = [];

      if (user) {

        user.user.organigrammi.forEach(element => {
          organigrammi_list.push(element.name);
        });

        let element_name = name;

        name = user.user.givenName + " " + user.user.familyName;
        email = user.user.email;

        if (user.google_user) {

          if (user.google_user.name) {
            name = user.google_user.name?.givenName + " " + user.google_user.name?.familyName;
          }

          email = user.google_user.primaryEmail
          is_google_user = true;
        }

        if (user.user.roles) {
          user.user.roles.forEach(ruolo => {
            ruoli.push(this.translate.instant('STR.' + ruolo.name));
          });
        }

        // controllo se il nome della richiesta è diverso da quello dell'utente lo scrivo
        if (name != element_name) {
          name += " (" + element_name + ")";
        }

      }

      let row = {
        "nome": [name, email],
        "organigramma": organigrammi_list,
        "ruoli": ruoli,
        "status": [status],
        "sync": { google: is_google_user,  geniusuite: true },
        "confermata": [confermata],
        "_export_data": {
          name: name,
          email: email,
        },
      };

      if (confermata)
        this.data_verificati.push(row);
      else
        this.data_non_verificati.push(row);

    });
  }

  copyLink() {
    // this.clipboardService.copy(this.link.link);
    this.toast.success('Link copiato negli appunti');
  }

  rowClickUser(data) {

    // recupero l'email dai dati
    let richiesta_email = data.data[0][1];
    let user = this.userService.getByEmail(richiesta_email);

    if (user)
      this.router.navigate(['user/' + user.user_id]);
    else
      this.toast.error("Utente non trovato");
  }

  updatePasswordCommand(utentiCardDatatable: CardDatatableNgComponent) {

    let itemsSelected = utentiCardDatatable.getRowChecked();
    let dataSelected = [];

    itemsSelected.forEach(item => {

      // recupero l'email dai dati selezionati
      let richiesta_email = item.nome[1];

      let user = this.userService.getByEmail(richiesta_email);

      if (user)
        dataSelected.push(user);
    });

    this.updatePasswordService.addUsers(dataSelected);


    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/update-password']);
  }

  deleteEvent(rows) {

    let deleteItems = [];

    rows.forEach(idx => {
      deleteItems.push(this.richieste[idx]);
    });

    this.requestChangePasswordService.delete(deleteItems);
  }

  forceUpdate() {
    this.isLoadingData = true;
    this.requestChangePasswordService.forceUpdate(() => {
      this.isLoadingData = false;
    });
  }

  selectTab(index) {

    this.currentTab = index;

    switch (index) {
      case 1:
      case 2:
        this.userService.all();
        this.requestChangePasswordService.all();
        break;
      default:
        break;
    }
  }

}
