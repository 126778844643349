export const environment = {
  production: true,
  apiUrl: "https://horizon.geniusuite.com/api/",
  channelAuthEndpoint: "https://horizon.geniusuite.com/broadcasting/auth",
  resetPasswordLink: "https://horizon.geniusuite.com/password/reset",
  loginUrlGoogle: "https://horizon.geniusuite.com/login/google",
  loginUrlNotGoogle: "https://horizon.geniusuite.com/login",
  logoutUrl: "https://horizon.geniusuite.com/logout",
  betaUrl: "https://beta.geniusuite.com",
  adminUrl: "https://admin.geniusuite.com",
  alfredUrl: "https://alfred.geniusuite.com/",
  version: "2023.11.6",
  sentry: '',
  checkVersionUrl: 'version.json',
  pusherApiKey: '52a7e90247525332e907',
  pusherHost: 'horizon.geniusuite.com',
  pusherPort: '6001',
};
