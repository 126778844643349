import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AddUsersToOrganigrammaService } from 'src/app/services/operations/add-users-to-organigramma.service';
import { ActiveClassroomService } from 'src/app/services/operations/classroom/active-classroom.service';
import { ArchiveClassroomService } from 'src/app/services/operations/classroom/archive-classroom.service';
import { CreateClassroomService } from 'src/app/services/operations/classroom/create-classroom.service';
import { DeleteClassroomService } from 'src/app/services/operations/classroom/delete-classroom.service';
import { SuspendClassroomService } from 'src/app/services/operations/classroom/suspend-classroom.service';
import { DeleteGroupsService } from 'src/app/services/operations/groups/delete-groups.service';
import { DeleteUsersService } from 'src/app/services/operations/delete-users.service';
import { MoveUsersService } from 'src/app/services/operations/move-users.service';
import { ReactivateSuspendedUsersService } from 'src/app/services/operations/reactivate-suspended-users.service';
import { RemoveMembersFromGroupsService } from 'src/app/services/operations/groups/remove-members-from-groups.service';
import { RemoveUsersFromOrganigrammaService } from 'src/app/services/operations/remove-users-from-organigramma.service';
import { RipristinoUsersService } from 'src/app/services/operations/ripristino-users.service';
import { SuspendUsersService } from 'src/app/services/operations/suspend-users.service';
import { SyncUsersFromGoogleService } from 'src/app/services/operations/sync-users-from-google.service';
import { UpdateAccountDomainService } from 'src/app/services/operations/update-account-domain.service';
import { UpdateMembersFromGroupsService } from 'src/app/services/operations/groups/update-members-from-groups.service';
import { UpdatePasswordService } from 'src/app/services/operations/update-password.service';
import { UpdateSettingGroupsService } from 'src/app/services/operations/groups/update-setting-groups.service';
import { RemoveAllMembersFromGroupsService } from 'src/app/services/operations/groups/remove-all-members-from-groups.service';
import { RemoveMembersFromClassroomsService } from 'src/app/services/operations/classroom/remove-members-from-classrooms.service';
import { CreateSingleClassroomService } from 'src/app/services/operations/classroom/create-single-classroom.service';
import { AddMembersToClassroomService } from 'src/app/services/operations/classroom/add-members-to-classroom.service';
import { RemoveMembersFromClasseService } from 'src/app/services/operations/remove-members-from-classe.service';
import { ProvisionClassroomService } from 'src/app/services/operations/classroom/provision-classroom.service';
import { DeclineClassroomService } from 'src/app/services/operations/classroom/decline-classroom.service';
@Component({
  selector: 'app-list-operation',
  templateUrl: './list-operation.component.html',
  styleUrls: ['./list-operation.component.scss']
})
export class ListOperationComponent implements OnInit {
  @Input() position: any;

  operation = [
    {
      key: "move-users",
      routerLink: "/operation/move-users",
      icon: "fa fa-users-cog",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "moveUsersService",
    },
    {
      key: "add-users-to-organigramma",
      routerLink: "/operation/add-users-to-organigramma",
      icon: "fa fa-users-cog",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "addUsersToOrganigrammaService",
    },
    {
      key: "remove-users-from-organigramma",
      routerLink: "/operation/remove-users-from-organigramma",
      icon: "fa fa-users-cog",
      colorClass: "danger",
      count: 0,
      _subscription: null,
      serviceKey: "removeUsersFromOrganigrammaService",
    },
    {
      key: "remove-members-from-groups",
      routerLink: "/operation/remove-members-from-groups",
      icon: "fa fa-users-cog",
      colorClass: "danger",
      count: 0,
      _subscription: null,
      serviceKey: "removeMembersFromGroupsService",
    },
    {
      key: "ripristino-users",
      routerLink: "/operation/ripristino-users",
      icon: "fa fa-users-cog",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "ripristinoUsersService",
    },
    {
      key: "sync-users-from-google",
      routerLink: "/operation/sync-users-from-google",
      icon: "fa fa-users-cog",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "syncUsersFromGoogleService",
    },
    {
      key: "update-setting-groups",
      routerLink: "/operation/update-setting-groups",
      icon: "fa fa-users-cog",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "updateSettingGroupsService",
    },
    {
      key: "delete-users",
      routerLink: "/operation/delete-users",
      icon: "fa fa-users",
      colorClass: "danger",
      count: 0,
      _subscription: null,
      serviceKey: "deleteUsersService",
    },
    {
      key: "delete-groups",
      routerLink: "/operation/delete-groups",
      icon: "fa fa-users",
      colorClass: "danger",
      count: 0,
      _subscription: null,
      serviceKey: "deleteGroupsService",
    },
    {
      key: "update-password",
      routerLink: "/operation/update-password",
      icon: "fa fa-users",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "updatePasswordService",
    },
    {
      key: "suspend-users",
      routerLink: "/operation/suspend-users",
      icon: "fa fa-users",
      colorClass: "danger",
      count: 0,
      _subscription: null,
      serviceKey: "suspendUsersService",
    },
    {
      key: "reactivate-suspended-users",
      routerLink: "/operation/reactivate-suspended-users",
      icon: "fa fa-users",
      colorClass: "danger",
      count: 0,
      _subscription: null,
      serviceKey: "reactivateSuspendedUsersService",
    },
    {
      key: "update-members-from-groups",
      routerLink: "/operation/update-members-from-groups",
      icon: "fa fa-users",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "updateMembersFromGroupsService",
    },
    {
      key: "update-account-domain",
      routerLink: "/operation/update-account-domain",
      icon: "fa fa-users",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "updateAccountDomainService",
    },
    {
      key: "update-account-domain-group",
      routerLink: "/operation/update-account-domain",
      icon: "fa fa-users",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "updateAccountDomainService",
    },
    {
      key: "active-classroom",
      routerLink: "/operation/active-classroom",
      icon: "fa fa-users-class",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "activeClassroomService",
    },
    {
      key: "archive-classroom",
      routerLink: "/operation/archive-classroom",
      icon: "fa fa-users-class",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "archiveClassroomService",
    },
    {
      key: "suspend-classroom",
      routerLink: "/operation/suspend-classroom",
      icon: "fa fa-users-class",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "suspendClassroomService",
    },
    {
      key: "provision-classroom",
      routerLink: "/operation/provision-classroom",
      icon: "fa fa-users-class",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "provisionClassroomService",
    },
    {
      key: "decline-classroom",
      routerLink: "/operation/decline-classroom",
      icon: "fa fa-users-class",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "declineClassroomService",
    },
    {
      key: "delete-classroom",
      routerLink: "/operation/delete-classroom",
      icon: "fa fa-users-class",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "deleteClassroomService",
    },
    {
      key: "remove-all-members-from-groups",
      routerLink: "/operation/remove-all-members-from-groups",
      icon: "fa fa-users",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "removeAllMembersFromGroupsService",
    },
    {
      key: "create-classroom",
      routerLink: "/operation/create-classroom",
      icon: "fa fa-users-class",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "createClassroomService",
    },
    {
      key: "create-single-classroom",
      routerLink: "/operation/create-classroom/single",
      icon: "fa fa-users-class",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "createSingleClassroomService",
    },
    {
      key: "remove-members-from-classrooms",
      routerLink: "/operation/remove-members-from-classrooms",
      icon: "fa fa-users-class",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "removeMembersFromClassroomsService",
    },
    {
      key: "add-members-to-classroom",
      routerLink: "/operation/add-members-to-classroom",
      icon: "fa fa-users-class",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "addMembersToClassroomService",
    },
    {
      key: "remove-members-from-classe",
      routerLink: "/operation/remove-members-from-classe",
      icon: "fa fa-users",
      colorClass: "primary",
      count: 0,
      _subscription: null,
      serviceKey: "removeMembersFromClasseService",
    }
  ]

  get operationExist(): boolean {
    return Object.values(this.operation).some(x => x.count > 0);
  }

  constructor(
    private translate: TranslateService,
    private moveUsersService: MoveUsersService,
    private addUsersToOrganigrammaService: AddUsersToOrganigrammaService,
    private removeUsersFromOrganigrammaService: RemoveUsersFromOrganigrammaService,
    private removeMembersFromGroupsService: RemoveMembersFromGroupsService,
    private ripristinoUsersService: RipristinoUsersService,
    private syncUsersFromGoogleService: SyncUsersFromGoogleService,
    private updateSettingGroupsService: UpdateSettingGroupsService,
    private deleteUsersService: DeleteUsersService,
    private deleteGroupsService: DeleteGroupsService,
    private updatePasswordService: UpdatePasswordService,
    private suspendUsersService: SuspendUsersService,
    private reactivateSuspendedUsersService: ReactivateSuspendedUsersService,
    private updateMembersFromGroupsService: UpdateMembersFromGroupsService,
    private updateAccountDomainService: UpdateAccountDomainService,
    private activeClassroomService: ActiveClassroomService,
    private archiveClassroomService: ArchiveClassroomService,
    private suspendClassroomService: SuspendClassroomService,
    private provisionClassroomService: ProvisionClassroomService,
    private declineClassroomService: DeclineClassroomService,
    private deleteClassroomService: DeleteClassroomService,
    private createClassroomService: CreateClassroomService,
    private removeAllMembersFromGroupsService: RemoveAllMembersFromGroupsService,
    private removeMembersFromClassroomsService: RemoveMembersFromClassroomsService,
    private createSingleClassroomService: CreateSingleClassroomService,
    private addMembersToClassroomService: AddMembersToClassroomService,
    private removeMembersFromClasseService: RemoveMembersFromClasseService,
  ) { }

  ngOnInit(): void {
    this.operation.forEach(element => {
      switch (element.key) {
        case "update-account-domain":
          element._subscription = this[element.serviceKey].getSubjectToUpdateUserObservable().subscribe((data) => {
            element.count = data ? data.length : 0;
          });
          break;
        case "update-account-domain-group":
          element._subscription = this[element.serviceKey].getSubjectToUpdateGroupObservable().subscribe((data) => {
            element.count = data ? data.length : 0;
          });
          break;
        default:
          element._subscription = this[element.serviceKey].getSubjectToUpdateObservable().subscribe((data) => {
            element.count = data ? data.length : 0;
          });
          break;
      }
    });
  }

  ngOnDestroy() {
    this.operation.forEach(element => {
      if (element._subscription)
        element._subscription.unsubscribe();
    });
  }

  getTitle(key) {
    return this.translate.instant("OPERATIONS." + key)
  }
}
