<app-card-loading *ngIf="loadingGoogle || loadingBackend"></app-card-loading>

<app-card-loading-error *ngIf="!(loadingBackend || loadingGoogle) && error" [error]="error"
  (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<app-video-tutorial-widget video_url="https://www.youtube.com/embed/ACnJOdn5Pqc" section="parametri"></app-video-tutorial-widget>

<div class="row" *ngIf="!loadingBackend && !loadingGoogle && !error">
  <div class="col-md-12 col-sm mb-1">

    <div class="card">
      <!-- Header -->
      <div class="card-header p-1">
        <div class="row justify-content-between align-items-center flex-grow-1">
          <div class="col">
            <tabset [justified]="true" type="pills">
              <tab #tab1="tab" (selectTab)="selectTab(1)">
                <ng-template tabHeading>
                  <i class="fas fa-user-friends nav-icon"></i> {{ 'STR.Utenti' | translate }}
                </ng-template>
              </tab>
              <tab #tab2="tab" (selectTab)="selectTab(2)">
                <ng-template tabHeading>
                  <i class="fas fa-sitemap nav-icon"></i> Organigramma
                </ng-template>
              </tab>
              <tab #tab3="tab" (selectTab)="selectTab(3)">
                <ng-template tabHeading>
                  <i class="fas fa-school nav-icon"></i> Plesso
                </ng-template>
              </tab>
              <tab #tab4="tab" (selectTab)="selectTab(4)">
                <ng-template tabHeading>
                  <i class="fas fa-chalkboard nav-icon"></i> Classe
                </ng-template>
              </tab>
              <tab #tab5="tab" (selectTab)="selectTab(5)">
                <ng-template tabHeading>
                  <i class="fas fa-users nav-icon"></i> Gruppi
                </ng-template>
              </tab>
            </tabset>
          </div>
        </div>
      </div>

      <!-- End Header -->
      <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <div class="card card-hover-shadow border-0 mb-0">

          <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>

          <!--
        .##.....##.########.########.##....##.########.####
        .##.....##....##....##.......###...##....##.....##.
        .##.....##....##....##.......####..##....##.....##.
        .##.....##....##....######...##.##.##....##.....##.
        .##.....##....##....##.......##..####....##.....##.
        .##.....##....##....##.......##...###....##.....##.
        ..#######.....##....########.##....##....##....####
        -->

          <!-- Header -->
          <div [hidden]="!tab1.active" class="card-header" [ngClass]="{'card-is-loading': formLoading}">
            <div class="row justify-content-between align-items-center flex-grow-1">
              <div class="col-9">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-header-title mr-3">Parametri per la creazioni degli utenti</h5>
                </div>
              </div>

              <div class="col-3">
                <!-- Filter -->
                <div class="row align-items-sm-center">
                  <div class="col-md">
                  </div>
                </div>
                <!-- End Filter -->
              </div>
            </div>
          </div>
          <!-- End Header -->

          <div formGroupName="utenti" [hidden]="!tab1.active" class="card-body"
            [ngClass]="{'card-is-loading': formLoading}">

            <h3>Docente</h3>

            <div class="form-group row">
              <div class="col-sm-8">

                <label class="input-label">Formato email
                  <button class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                    (click)="clearInput('utenti.docente_email', 'docente_email_tag')">
                    <i class="fas fa-backspace"></i>
                  </button>
                </label>

                <app-tagsinput [id]="'docente_email_tag'" [defaultValue]="data.docente_email"
                  [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                  [setValueEvent]="setValueEventSubject.asObservable()"
                  [buttonInput]="['$nome$', '$cognome$', '$cf$', '$nome:1$', '$cognome:1$']"
                  (onInputText)="onInputTagInput($event, myForm.get('utenti.docente_email'))"></app-tagsinput>

                <input [hidden]="true" formControlName="docente_email" id="docente_email" type="text">
                <div *ngIf="!myForm.get('utenti.docente_email').valid || data.docente_email_error_ignore"
                  class="mt-1 alert alert-soft-danger" role="alert">
                  <label class="toggle-switch d-flex toggle-switch-sm" for="docente_email_error_ignore">
                    <input type="checkbox" class="toggle-switch-input"
                      [ngClass]="{'is-invalid': data.docente_email_error_ignore}"
                      [(ngModel)]="data.docente_email_error_ignore" id="docente_email_error_ignore"
                      formControlName="docente_email_error_ignore"
                      (change)="changeIgnoreError('utenti.docente_email', 'docente_email_tag')">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">{{ 'PARAMETRI.disabilita_controlli_title' | translate }}</span>
                      <small class="d-block text-muted">{{ 'PARAMETRI.disabilita_controlli_msg' | translate }}</small>
                    </span>
                  </label>
                </div>

                <div class="text-muted font-size-1 mt-1">deve contenere almeno
                  <button type="button" class="btn btn-outline-primary btn-xs badge"
                    (click)="addParamTo('$nome$', 'docente_email_tag')">nome</button> e
                  <button type="button" class="btn btn-outline-primary btn-xs badge"
                    (click)="addParamTo('$cognome$', 'docente_email_tag')">cognome</button> al suo
                  interno<br>

                  <p class="mt-1">oppure un'abbreviazione delle due
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$nome:1$', 'docente_email_tag')">prima lettera del
                      nome</button> o
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$cognome:1$', 'docente_email_tag')">prima lettera del
                      cognome</button>
                  </p>
                  <p class="mt-0">oppure <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$cf$', 'docente_email_tag')">codice identificativo</button>
                  </p>
                </div>

              </div>
              <div class="col-sm-4">
                <label class="input-label">Dominio</label>
                <select class="form-control" formControlName="docente_domain" id="docente_domain"
                  [ngClass]="(myForm.get('utenti.docente_domain').dirty || myForm.get('utenti.docente_domain').touched)? (myForm.get('utenti.docente_domain').valid ? 'is-valid': 'is-invalid') : 'untouched'">
                  <option *ngFor="let el of domains" [value]="el.domainName">@{{ el.domainName }}</option>
                </select>
                <span class="invalid-feedback"
                  *ngIf="myForm.get('utenti.docente_domain').invalid && (myForm.get('utenti.docente_domain').dirty || myForm.get('utenti.docente_domain').touched)">Non
                  valido</span>
                <span class="valid-feedback"
                  *ngIf="myForm.get('utenti.docente_domain').valid && (myForm.get('utenti.docente_domain').dirty || myForm.get('utenti.docente_domain').touched)">Valido</span>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-8">
                <app-switch [(value)]="data.docente_email_accento"
                  (valueChange)="changeValueSwitch('utenti','docente_email_accento', $event)"
                  [id]="'docente_email_accento'" labelText="Inserisci l'apostrofo all'interno dell'email"
                  helpText="Quando nel parametro utilizzato è presente un accento, viene anche inserita all'interno dell'email">
                </app-switch>
                <input type="hidden" id="docente_email_accento" formControlName="docente_email_accento">
              </div>
              <div class="col-sm-4">
                <div class="alert alert-soft-info" role="alert">
                  <span class="text-cap">Anteprima email</span>
                  <h3 class="alert-heading">{{ loadPreviewParameter(1) }}</h3>
                </div>
              </div>
            </div>

            <h3>Studente</h3>

            <div class="form-group row">
              <div class="col-sm-8">
                <label class="input-label">Formato email
                  <button class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                    (click)="clearInput('utenti.studente_email', 'studente_email_tag')">
                    <i class="fas fa-backspace"></i>
                  </button>
                </label>
                <app-tagsinput [id]="'studente_email_tag'" [defaultValue]="data.studente_email"
                  [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                  [setValueEvent]="setValueEventSubject.asObservable()"
                  [buttonInput]="['$nome$', '$cognome$', '$cf$', '$nome:1$', '$cognome:1$']"
                  (onInputText)="onInputTagInput($event, myForm.get('utenti.studente_email'))"></app-tagsinput>

                <input [hidden]="true" formControlName="studente_email" id="studente_email" type="text">
                <div *ngIf="!myForm.get('utenti.studente_email').valid || data.studente_email_error_ignore"
                  class="mt-1 alert alert-soft-danger" role="alert">
                  <label class="toggle-switch d-flex toggle-switch-sm" for="studente_email_error_ignore">
                    <input type="checkbox" class="toggle-switch-input"
                      [ngClass]="{'is-invalid': data.studente_email_error_ignore}"
                      [(ngModel)]="data.studente_email_error_ignore" id="studente_email_error_ignore"
                      formControlName="studente_email_error_ignore"
                      (change)="changeIgnoreError('utenti.studente_email', 'studente_email_tag')">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">{{ 'PARAMETRI.disabilita_controlli_title' | translate }}</span>
                      <small class="d-block text-muted">{{ 'PARAMETRI.disabilita_controlli_msg' | translate }}</small>
                    </span>
                  </label>
                </div>

                <div class="text-muted font-size-1 mt-1">deve contenere almeno
                  <button type="button" class="btn btn-outline-primary btn-xs badge"
                    (click)="addParamTo('$nome$', 'studente_email_tag')">nome</button> e
                  <button type="button" class="btn btn-outline-primary btn-xs badge"
                    (click)="addParamTo('$cognome$', 'studente_email_tag')">cognome</button> al suo
                  interno<br>

                  <p class="mt-1">oppure un'abbreviazione delle due
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$nome:1$', 'studente_email_tag')">prima lettera del
                      nome</button> o
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$cognome:1$', 'studente_email_tag')">prima lettera del
                      cognome</button>
                  </p>
                  <p class="mt-0">oppure <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$cf$', 'studente_email_tag')">codice identificativo</button>
                  </p>
                </div>


              </div>
              <div class="col-sm-4">
                <label class="input-label">Dominio</label>
                <select class="form-control" formControlName="studente_domain" id="studente_domain"
                  [ngClass]="(myForm.get('utenti.studente_domain').dirty || myForm.get('utenti.studente_domain').touched)? (myForm.get('utenti.studente_domain').valid ? 'is-valid': 'is-invalid') : 'untouched'">
                  <option *ngFor="let el of domains" [value]="el.domainName">@{{ el.domainName }}</option>
                </select>
                <span class="invalid-feedback"
                  *ngIf="myForm.get('utenti.studente_domain').invalid && (myForm.get('utenti.studente_domain').dirty || myForm.get('utenti.studente_domain').touched)">Non
                  valido</span>
                <span class="valid-feedback"
                  *ngIf="myForm.get('utenti.studente_domain').valid && (myForm.get('utenti.studente_domain').dirty || myForm.get('utenti.studente_domain').touched)">Valido</span>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-8">
                <app-switch [(value)]="data.studente_email_accento"
                  (valueChange)="changeValueSwitch('utenti','studente_email_accento', $event)"
                  [id]="'studente_email_accento'" labelText="Inserisci l'apostrofo all'interno dell'email"
                  helpText="Quando nel parametro utilizzato è presente un accento, viene anche inserita all'interno dell'email">
                </app-switch>
                <input type="hidden" id="studente_email_accento" formControlName="studente_email_accento">
              </div>
              <div class="col-sm-4">
                <div class="alert alert-soft-info" role="alert">
                  <span class="text-cap">Anteprima email</span>
                  <h3 class="alert-heading">{{ loadPreviewParameter(0) }}</h3>
                </div>
              </div>
            </div>

            <h3>Altro</h3>

            <div class="form-group row">
              <div class="col-sm-8">
                <label class="input-label">Formato email
                  <button class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                    (click)="clearInput('utenti.workers_email', 'workers_email_tag')">
                    <i class="fas fa-backspace"></i>
                  </button>
                </label>
                <app-tagsinput [id]="'workers_email_tag'" [defaultValue]="data.workers_email"
                  [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                  [setValueEvent]="setValueEventSubject.asObservable()"
                  [buttonInput]="['$nome$', '$cognome$', '$cf$', '$nome:1$', '$cognome:1$']"
                  (onInputText)="onInputTagInput($event, myForm.get('utenti.workers_email'))"></app-tagsinput>

                <input [hidden]="true" formControlName="workers_email" id="workers_email" type="text">
                <div *ngIf="!myForm.get('utenti.workers_email').valid || data.workers_email_error_ignore"
                  class="mt-1 alert alert-soft-danger" role="alert">
                  <label class="toggle-switch d-flex toggle-switch-sm" for="workers_email_error_ignore">
                    <input type="checkbox" class="toggle-switch-input"
                      [ngClass]="{'is-invalid': data.workers_email_error_ignore}"
                      [(ngModel)]="data.workers_email_error_ignore" id="workers_email_error_ignore"
                      formControlName="workers_email_error_ignore"
                      (change)="changeIgnoreError('utenti.workers_email', 'workers_email_tag')">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">{{ 'PARAMETRI.disabilita_controlli_title' | translate }}</span>
                      <small class="d-block text-muted">{{ 'PARAMETRI.disabilita_controlli_msg' | translate }}</small>
                    </span>
                  </label>
                </div>

                <div class="text-muted font-size-1 mt-1">deve contenere almeno
                  <button type="button" class="btn btn-outline-primary btn-xs badge"
                    (click)="addParamTo('$nome$','workers_email_tag')">nome</button> e
                  <button type="button" class="btn btn-outline-primary btn-xs badge"
                    (click)="addParamTo('$cognome$','workers_email_tag')">cognome</button> al suo
                  interno<br>

                  <p class="mt-1">oppure un'abbreviazione delle due
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$nome:1$','workers_email_tag')">prima lettera del
                      nome</button> o
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$cognome:1$','workers_email_tag')">prima lettera del
                      cognome</button>
                  </p>
                  <p class="mt-0">oppure <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$cf$','workers_email_tag')">codice identificativo</button>
                  </p>
                </div>

              </div>
              <div class="col-sm-4">
                <label class="input-label">Dominio</label>
                <select class="form-control" formControlName="workers_domain" id="workers_domain"
                  [ngClass]="(myForm.get('utenti.workers_domain').dirty || myForm.get('utenti.workers_domain').touched)? (myForm.get('utenti.workers_domain').valid ? 'is-valid': 'is-invalid') : 'untouched'"
                  required>
                  <option *ngFor="let el of domains" [value]="el.domainName">@{{ el.domainName }}</option>
                </select>
                <span class="invalid-feedback"
                  *ngIf="myForm.get('utenti.workers_domain').invalid && (myForm.get('utenti.workers_domain').dirty || myForm.get('utenti.workers_domain').touched)">Non
                  valido</span>
                <span class="valid-feedback"
                  *ngIf="myForm.get('utenti.workers_domain').valid && (myForm.get('utenti.workers_domain').dirty || myForm.get('utenti.workers_domain').touched)">Valido</span>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-8">
                <app-switch [(value)]="data.workers_email_accento"
                  (valueChange)="changeValueSwitch('utenti','workers_email_accento', $event)"
                  [id]="'workers_email_accento'" labelText="Inserisci l'apostrofo all'interno dell'email"
                  helpText="Quando nel parametro utilizzato è presente un accento, viene anche inserita all'interno dell'email">
                </app-switch>
                <input type="hidden" id="workers_email_accento" formControlName="workers_email_accento">
              </div>
              <div class="col-sm-4">
                <div class="alert alert-soft-info" role="alert">
                  <span class="text-cap">Anteprima email</span>
                  <h3 class="alert-heading">{{ loadPreviewParameter(2) }}</h3>
                </div>
              </div>
            </div>
          </div>

          <!--
        ..#######..########...######......###....##....##.####..######...########.....###....##.....##.##.....##....###...
        .##.....##.##.....##.##....##....##.##...###...##..##..##....##..##.....##...##.##...###...###.###...###...##.##..
        .##.....##.##.....##.##.........##...##..####..##..##..##........##.....##..##...##..####.####.####.####..##...##.
        .##.....##.########..##...####.##.....##.##.##.##..##..##...####.########..##.....##.##.###.##.##.###.##.##.....##
        .##.....##.##...##...##....##..#########.##..####..##..##....##..##...##...#########.##.....##.##.....##.#########
        .##.....##.##....##..##....##..##.....##.##...###..##..##....##..##....##..##.....##.##.....##.##.....##.##.....##
        ..#######..##.....##..######...##.....##.##....##.####..######...##.....##.##.....##.##.....##.##.....##.##.....##
        -->

          <!-- Header -->
          <div [hidden]="!tab2.active" class="card-header" [ngClass]="{'card-is-loading': formLoading}">
            <div class="row justify-content-between align-items-center flex-grow-1">
              <div class="col-9">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-header-title mr-3">Parametri per la creazioni degli organigramma</h5>
                </div>
              </div>

              <div class="col-3">
                <!-- Filter -->
                <div class="row align-items-sm-center">
                  <div class="col-md">
                  </div>
                </div>
                <!-- End Filter -->
              </div>
            </div>
          </div>
          <!-- End Header -->


          <div [hidden]="!tab2.active" class="card-body" [ngClass]="{'card-is-loading': formLoading}">

            <div formGroupName="organigramma">
              <h3>Gruppi Organigramma</h3>

              <div class="form-group row">
                <div class="col-sm-8">
                  <label class="input-label">Formato email per i gruppi dell'organigramma
                    <button class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                      (click)="clearInput('organigramma.group_organigramma_email', 'group_organigramma_email_tag')">
                      <i class="fas fa-backspace"></i>
                    </button>
                  </label>

                  <app-tagsinput [id]="'group_organigramma_email_tag'" [defaultValue]="data.group_organigramma_email"
                    [setValueEvent]="setValueEventSubject.asObservable()"
                    [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                    [buttonInput]="['$name_organigramma$']"
                    (onInputText)="onInputTagInput($event, myForm.get('organigramma.group_organigramma_email'))">
                  </app-tagsinput>

                  <input [hidden]="true" formControlName="group_organigramma_email" id="group_organigramma_email"
                    type="text">
                  <div
                    *ngIf="!myForm.get('organigramma.group_organigramma_email').valid || data.group_organigramma_email_error_ignore"
                    class="mt-1 alert alert-soft-danger" role="alert">
                    <label class="toggle-switch d-flex toggle-switch-sm" for="group_organigramma_email_error_ignore">
                      <input type="checkbox" class="toggle-switch-input"
                        [ngClass]="{'is-invalid': data.group_organigramma_email_error_ignore}"
                        [(ngModel)]="data.group_organigramma_email_error_ignore"
                        id="group_organigramma_email_error_ignore"
                        formControlName="group_organigramma_email_error_ignore"
                        (change)="changeIgnoreError('organigramma.group_organigramma_email', 'group_organigramma_email_tag')">
                      <span class="toggle-switch-label">
                        <span class="toggle-switch-indicator"></span>
                      </span>
                      <span class="toggle-switch-content">
                        <span class="d-block">{{ 'PARAMETRI.disabilita_controlli_title' | translate }}</span>
                        <small class="d-block text-muted">{{ 'PARAMETRI.disabilita_controlli_msg' | translate }}</small>
                      </span>
                    </label>
                  </div>

                  <div class="text-muted font-size-1 mt-1">deve contenere almeno
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$name_organigramma$', 'group_organigramma_email_tag')">nome
                      organigramma</button>
                  </div>


                </div>
                <div class="col-sm-4">
                  <label class="input-label">Dominio</label>
                  <select class="form-control" formControlName="group_organigramma_domain"
                    id="group_organigramma_domain"
                    [ngClass]="(myForm.get('organigramma.group_organigramma_domain').dirty || myForm.get('organigramma.group_organigramma_domain').touched)? (myForm.get('organigramma.group_organigramma_domain').valid ? 'is-valid': 'is-invalid') : 'untouched'"
                    required>
                    <option *ngFor="let el of domains" [value]="el.domainName">@{{ el.domainName }}</option>
                  </select>
                  <span class="invalid-feedback"
                    *ngIf="myForm.get('organigramma.group_organigramma_domain').invalid && (myForm.get('organigramma.group_organigramma_domain').dirty || myForm.get('organigramma.group_organigramma_domain').touched)">Non
                    valido</span>
                  <span class="valid-feedback"
                    *ngIf="myForm.get('organigramma.group_organigramma_domain').valid && (myForm.get('organigramma.group_organigramma_domain').dirty || myForm.get('organigramma.group_organigramma_domain').touched)">Valido</span>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-8">
                  <app-switch [(value)]="data.group_organigramma_email_accento"
                    (valueChange)="changeValueSwitch('organigramma','group_organigramma_email_accento', $event)"
                    [id]="'group_organigramma_email_accento'" labelText="Inserisci l'apostrofo all'interno dell'email"
                    helpText="Quando nel parametro utilizzato è presente un accento, viene anche inserita all'interno dell'email">
                  </app-switch>
                  <input type="hidden" id="group_organigramma_email_accento"
                    formControlName="group_organigramma_email_accento">
                </div>
                <div class="col-sm-4">
                  <button class="btn btn-ghost-primary" type="button" (click)="setGroupSettingCollapse('organigramma');"
                    [attr.aria-expanded]="groupSettingCollapse.organigramma"
                    attr.aria-controls="groupSettingCollapse_organigramma">
                    Impostazioni avanzate gruppo
                    <span class="ml-2" [ngSwitch]="groupSettingCollapse.organigramma">
                      <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                      <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-8">
                  <div class="alert alert-soft-info" role="alert">
                    <span class="text-cap">Anteprima email</span>
                    <h3 class="alert-heading">{{ loadPreviewParameter(11) }}</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" id="groupSettingCollapse_organigramma"
              [collapse]="!groupSettingCollapse.organigramma" [isAnimated]="true">
              <div class="col-12">
                <app-google-group-settings [formGroup]="myForm" [formGroup]="myForm" [id]="'group_organigramma'"
                  [json]="data.group_organigramma">
                </app-google-group-settings>
              </div>
            </div>
          </div>
          <!--
        .########..##.......########..######...######...#######.
        .##.....##.##.......##.......##....##.##....##.##.....##
        .##.....##.##.......##.......##.......##.......##.....##
        .########..##.......######....######...######..##.....##
        .##........##.......##.............##.......##.##.....##
        .##........##.......##.......##....##.##....##.##.....##
        .##........########.########..######...######...#######.
        -->

          <!-- Header -->
          <div [hidden]="!tab3.active" class="card-header" [ngClass]="{'card-is-loading': formLoading}">
            <div class="row justify-content-between align-items-center flex-grow-1">
              <div class="col-9">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-header-title mr-3">Parametri per la creazioni dei plessi</h5>
                </div>
              </div>

              <div class="col-3">
                <!-- Filter -->
                <div class="row align-items-sm-center">
                  <div class="col-md">
                  </div>
                </div>
                <!-- End Filter -->
              </div>
            </div>
          </div>
          <!-- End Header -->


          <div [hidden]="!tab3.active" class="card-body" [ngClass]="{'card-is-loading': formLoading}">

            <div formGroupName="plesso">
              <h3>Gruppo Docenti</h3>
              <p>Il gruppo conterrà tutti gli utenti della tipologia <mark class="text-cap d-inline">Docenti</mark>
              </p>
              <div class="form-group row">
                <div class="col-sm-8">
                  <label class="input-label">Formato email per i gruppi dei plessi per i docenti
                    <button class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                      (click)="clearInput('plesso.group_plesso_docente_email', 'group_plesso_docente_email_tag')">
                      <i class="fas fa-backspace"></i>
                    </button>
                  </label>

                  <app-tagsinput [id]="'group_plesso_docente_email_tag'"
                    [defaultValue]="data.group_plesso_docente_email"
                    [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                    [setValueEvent]="setValueEventSubject.asObservable()"
                    [buttonInput]="['$name_plesso$', '$short_name_plesso$']"
                    (onInputText)="onInputTagInput($event, myForm.get('plesso.group_plesso_docente_email'), 'plesso');">
                  </app-tagsinput>

                  <input [hidden]="true" formControlName="group_plesso_docente_email" id="group_plesso_docente_email"
                    type="text">
                  <div
                    *ngIf="!myForm.get('plesso.group_plesso_docente_email').valid || data.group_plesso_docente_email_error_ignore"
                    class="mt-1 alert alert-soft-danger" role="alert">
                    <label class="toggle-switch d-flex toggle-switch-sm" for="group_plesso_docente_email_error_ignore">
                      <input type="checkbox" class="toggle-switch-input"
                        [ngClass]="{'is-invalid': data.group_plesso_docente_email_error_ignore}"
                        [(ngModel)]="data.group_plesso_docente_email_error_ignore"
                        id="group_plesso_docente_email_error_ignore"
                        formControlName="group_plesso_docente_email_error_ignore"
                        (change)="changeIgnoreError('plesso.group_plesso_docente_email', 'group_plesso_docente_email_tag')">
                      <span class="toggle-switch-label">
                        <span class="toggle-switch-indicator"></span>
                      </span>
                      <span class="toggle-switch-content">
                        <span class="d-block">{{ 'PARAMETRI.disabilita_controlli_title' | translate }}</span>
                        <small class="d-block text-muted">{{ 'PARAMETRI.disabilita_controlli_msg' | translate }}</small>
                      </span>
                    </label>
                  </div>

                  <div class="text-muted font-size-1 mt-1">deve contenere almeno
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$name_plesso$', 'group_plesso_docente_email_tag')">nome
                      plesso</button>
                    o
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$short_name_plesso$', 'group_plesso_docente_email_tag')">short
                      nome
                      plesso</button>
                  </div>

                </div>
                <div class="col-sm-4">
                  <label class="input-label">Dominio</label>
                  <select class="form-control" formControlName="group_plesso_docente_domain"
                    id="group_plesso_docente_domain"
                    [ngClass]="(myForm.get('plesso.group_plesso_docente_domain').dirty || myForm.get('plesso.group_plesso_docente_domain').touched)? (myForm.get('plesso.group_plesso_docente_domain').valid ? 'is-valid': 'is-invalid') : 'untouched'"
                    required>
                    <option *ngFor="let el of domains" [value]="el.domainName">@{{ el.domainName }}</option>
                  </select>
                  <span class="invalid-feedback"
                    *ngIf="myForm.get('plesso.group_plesso_docente_domain').invalid && (myForm.get('plesso.group_plesso_docente_domain').dirty || myForm.get('plesso.group_plesso_docente_domain').touched)">Non
                    valido</span>
                  <span class="valid-feedback"
                    *ngIf="myForm.get('plesso.group_plesso_docente_domain').valid && (myForm.get('plesso.group_plesso_docente_domain').dirty || myForm.get('plesso.group_plesso_docente_domain').touched)">Valido</span>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-8">
                  <app-switch [(value)]="data.group_plesso_docente_email_accento"
                    (valueChange)="changeValueSwitch('plesso','group_plesso_docente_email_accento', $event)"
                    [id]="'group_plesso_docente_email_accento'" labelText="Inserisci l'apostrofo all'interno dell'email"
                    helpText="Quando nel parametro utilizzato è presente un accento, viene anche inserita all'interno dell'email">
                  </app-switch>
                  <input type="hidden" id="group_plesso_docente_email_accento"
                    formControlName="group_plesso_docente_email_accento">
                </div>
                <div class="col-sm-4">
                  <button class="btn btn-ghost-primary" type="button"
                    (click)="setGroupSettingCollapse('plesso_docente');"
                    [attr.aria-expanded]="groupSettingCollapse.plesso_docente"
                    attr.aria-controls="groupSettingCollapse_plesso_docente">
                    Impostazioni avanzate gruppo
                    <span class="ml-2" [ngSwitch]="groupSettingCollapse.plesso_docente">
                      <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                      <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-8">
                  <div class="alert alert-soft-info" role="alert">
                    <span class="text-cap">Anteprima email</span>
                    <h3 class="alert-heading">{{ loadPreviewParameter(5) }}</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" id="groupSettingCollapse_plesso_docente"
              [collapse]="!groupSettingCollapse.plesso_docente" [isAnimated]="true">
              <div class="col-12">
                <app-google-group-settings [formGroup]="myForm" [id]="'group_plesso_docente'"
                  [json]="data.group_plesso_docente">
                </app-google-group-settings>
              </div>
            </div>

            <div formGroupName="plesso">
              <h3>Gruppo Studenti</h3>
              <p>Il gruppo conterrà tutti gli utenti della tipologia <mark class="text-cap d-inline">Studenti</mark>
              </p>
              <div class="form-group row">
                <div class="col-sm-8">
                  <label class="input-label">Formato email per i gruppi dei plessi per gli studenti
                    <button class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                      (click)="clearInput('plesso.group_plesso_studente_email', 'group_plesso_studente_email_tag')">
                      <i class="fas fa-backspace"></i>
                    </button>
                  </label>
                  <app-tagsinput [id]="'group_plesso_studente_email_tag'"
                    [defaultValue]="data.group_plesso_studente_email"
                    [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                    [setValueEvent]="setValueEventSubject.asObservable()"
                    [buttonInput]="['$name_plesso$', '$short_name_plesso$']"
                    (onInputText)="onInputTagInput($event, myForm.get('plesso.group_plesso_studente_email'), 'plesso');">
                  </app-tagsinput>

                  <input [hidden]="true" formControlName="group_plesso_studente_email" id="group_plesso_studente_email"
                    type="text">
                  <div
                    *ngIf="!myForm.get('plesso.group_plesso_studente_email').valid || data.group_plesso_studente_email_error_ignore"
                    class="mt-1 alert alert-soft-danger" role="alert">
                    <label class="toggle-switch d-flex toggle-switch-sm" for="group_plesso_studente_email_error_ignore">
                      <input type="checkbox" class="toggle-switch-input"
                        [ngClass]="{'is-invalid': data.group_plesso_studente_email_error_ignore}"
                        [(ngModel)]="data.group_plesso_studente_email_error_ignore"
                        id="group_plesso_studente_email_error_ignore"
                        formControlName="group_plesso_studente_email_error_ignore"
                        (change)="changeIgnoreError('plesso.group_plesso_studente_email', 'group_plesso_studente_email_tag')">
                      <span class="toggle-switch-label">
                        <span class="toggle-switch-indicator"></span>
                      </span>
                      <span class="toggle-switch-content">
                        <span class="d-block">{{ 'PARAMETRI.disabilita_controlli_title' | translate }}</span>
                        <small class="d-block text-muted">{{ 'PARAMETRI.disabilita_controlli_msg' | translate }}</small>
                      </span>
                    </label>
                  </div>

                  <div class="text-muted font-size-1 mt-1">deve contenere almeno
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$name_plesso$', 'group_plesso_studente_email_tag')">nome
                      plesso</button> o
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$short_name_plesso$', 'group_plesso_studente_email_tag')">short
                      nome
                      plesso</button>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="input-label">Dominio</label>
                  <select class="form-control" formControlName="group_plesso_studente_domain"
                    id="group_plesso_studente_domain"
                    [ngClass]="(myForm.get('plesso.group_plesso_studente_domain').dirty || myForm.get('plesso.group_plesso_studente_domain').touched)? (myForm.get('plesso.group_plesso_studente_domain').valid ? 'is-valid': 'is-invalid') : 'untouched'"
                    required>
                    <option *ngFor="let el of domains" [value]="el.domainName">@{{ el.domainName }}</option>
                  </select>
                  <span class="invalid-feedback"
                    *ngIf="myForm.get('plesso.group_plesso_studente_domain').invalid && (myForm.get('plesso.group_plesso_studente_domain').dirty || myForm.get('plesso.group_plesso_studente_domain').touched)">Non
                    valido</span>
                  <span class="valid-feedback"
                    *ngIf="myForm.get('plesso.group_plesso_studente_domain').valid && (myForm.get('plesso.group_plesso_studente_domain').dirty || myForm.get('plesso.group_plesso_studente_domain').touched)">Valido</span>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-8">
                  <app-switch [(value)]="data.group_plesso_studente_email_accento"
                    (valueChange)="changeValueSwitch('plesso','group_plesso_studente_email_accento', $event)"
                    [id]="'group_plesso_studente_email_accento'"
                    labelText="Inserisci l'apostrofo all'interno dell'email"
                    helpText="Quando nel parametro utilizzato è presente un accento, viene anche inserita all'interno dell'email">
                  </app-switch>
                  <input type="hidden" id="group_plesso_studente_email_accento"
                    formControlName="group_plesso_studente_email_accento">
                </div>
                <div class="col-sm-4">
                  <button class="btn btn-ghost-primary" type="button"
                    (click)="setGroupSettingCollapse('plesso_studente');"
                    [attr.aria-expanded]="groupSettingCollapse.plesso_studente"
                    attr.aria-controls="groupSettingCollapse_plesso_studente">
                    Impostazioni avanzate gruppo
                    <span class="ml-2" [ngSwitch]="groupSettingCollapse.plesso_studente">
                      <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                      <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                    </span>
                  </button>
                </div>
              </div>


              <div class="form-group row">
                <div class="col-sm-8">
                  <div class="alert alert-soft-info" role="alert">
                    <span class="text-cap">Anteprima email</span>
                    <h3 class="alert-heading">{{ loadPreviewParameter(6) }}</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" id="groupSettingCollapse_plesso_studente"
              [collapse]="!groupSettingCollapse.plesso_studente" [isAnimated]="true">
              <div class="col-12">
                <app-google-group-settings [formGroup]="myForm" [id]="'group_plesso_studente'"
                  [json]="data.group_plesso_studente">
                </app-google-group-settings>
              </div>
            </div>

            <div formGroupName="plesso">
              <h3>Gruppo misto Docenti e Studenti del plesso</h3>
              <p>Il gruppo <b>non conterrà utenti</b> ma conterrà i gruppi <mark
                  class="text-cap d-inline">Docenti</mark> e <mark class="text-cap d-inline">Studenti</mark> sopra
                specificati</p>
              <div class="form-group row">
                <div class="col-auto pr-0" style="margin: auto 0px;">
                  <app-switch (valueChange)="changeValueSwitch('plesso','gest_group_plesso', $event)"
                    [(value)]="data.gest_group_plesso" [id]="'gest_group_plesso'"
                    labelText="Abilita la gestione del gruppo docenti e studenti del plesso">
                  </app-switch>
                  <input type="hidden" id="gest_group_plesso" formControlName="gest_group_plesso">
                </div>
              </div>
              <div [hidden]="!data.gest_group_plesso">
                <div class="form-group row">
                  <div class="col-sm-8">
                    <label class="input-label">Formato email per i gruppi dei plessi
                      <button class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                        (click)="clearInput('plesso.group_plesso_email', 'group_plesso_email_tag')">
                        <i class="fas fa-backspace"></i>
                      </button>
                    </label>
                    <app-tagsinput [id]="'group_plesso_email_tag'" [defaultValue]="data.group_plesso_email"
                      [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                      [setValueEvent]="setValueEventSubject.asObservable()"
                      [buttonInput]="['$name_plesso$', '$short_name_plesso$']"
                      (onInputText)="onInputTagInput($event, myForm.get('plesso.group_plesso_email'), 'plesso');">
                    </app-tagsinput>

                    <input [hidden]="true" formControlName="group_plesso_email" id="group_plesso_email" type="text">
                    <div *ngIf="!myForm.get('plesso.group_plesso_email').valid || data.group_plesso_email_error_ignore"
                      class="mt-1 alert alert-soft-danger" role="alert">
                      <label class="toggle-switch d-flex toggle-switch-sm" for="group_plesso_email_error_ignore">
                        <input type="checkbox" class="toggle-switch-input"
                          [ngClass]="{'is-invalid': data.group_plesso_email_error_ignore}"
                          [(ngModel)]="data.group_plesso_email_error_ignore" id="group_plesso_email_error_ignore"
                          formControlName="group_plesso_email_error_ignore"
                          (change)="changeIgnoreError('plesso.group_plesso_email', 'group_plesso_email_tag')">
                        <span class="toggle-switch-label">
                          <span class="toggle-switch-indicator"></span>
                        </span>
                        <span class="toggle-switch-content">
                          <span class="d-block">{{ 'PARAMETRI.disabilita_controlli_title' | translate }}</span>
                          <small class="d-block text-muted">{{ 'PARAMETRI.disabilita_controlli_msg' | translate
                            }}</small>
                        </span>
                      </label>
                    </div>

                    <div class="text-muted font-size-1 mt-1">deve contenere almeno
                      <button type="button" class="btn btn-outline-primary btn-xs badge"
                        (click)="addParamTo('$name_plesso$','group_plesso_email_tag')">nome
                        plesso</button>
                      o
                      <button type="button" class="btn btn-outline-primary btn-xs badge"
                        (click)="addParamTo('$short_name_plesso$','group_plesso_email_tag')">short nome
                        plesso</button>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label class="input-label">Dominio</label>
                    <select class="form-control" formControlName="group_plesso_domain" id="group_plesso_domain"
                      [ngClass]="(myForm.get('plesso.group_plesso_domain').dirty || myForm.get('plesso.group_plesso_domain').touched)? (myForm.get('plesso.group_plesso_domain').valid ? 'is-valid': 'is-invalid') : 'untouched'"
                      required>
                      <option *ngFor="let el of domains" [value]="el.domainName">@{{ el.domainName }}</option>
                    </select>
                    <span class="invalid-feedback"
                      *ngIf="myForm.get('plesso.group_plesso_domain').invalid && (myForm.get('plesso.group_plesso_domain').dirty || myForm.get('plesso.group_plesso_domain').touched)">Non
                      valido</span>
                    <span class="valid-feedback"
                      *ngIf="myForm.get('plesso.group_plesso_domain').valid && (myForm.get('plesso.group_plesso_domain').dirty || myForm.get('plesso.group_plesso_domain').touched)">Valido</span>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-8">
                    <app-switch [(value)]="data.group_plesso_email_accento"
                      (valueChange)="changeValueSwitch('plesso','group_plesso_email_accento', $event)"
                      [id]="'group_plesso_email_accento'" labelText="Inserisci l'apostrofo all'interno dell'email"
                      helpText="Quando nel parametro utilizzato è presente un accento, viene anche inserita all'interno dell'email">
                    </app-switch>
                    <input type="hidden" id="group_plesso_email_accento" formControlName="group_plesso_email_accento">
                  </div>
                  <div class="col-sm-4">
                    <button class="btn btn-ghost-primary" type="button" (click)="setGroupSettingCollapse('plesso');"
                      [attr.aria-expanded]="groupSettingCollapse.plesso"
                      attr.aria-controls="groupSettingCollapse_plesso">
                      Impostazioni avanzate gruppo
                      <span class="ml-2" [ngSwitch]="groupSettingCollapse.plesso">
                        <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                        <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                      </span>
                    </button>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-8">
                    <div class="alert alert-soft-info" role="alert">
                      <span class="text-cap">Anteprima email</span>
                      <h3 class="alert-heading">{{ loadPreviewParameter(4) }}</h3>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="form-group row" id="groupSettingCollapse_plesso" [collapse]="!groupSettingCollapse.plesso"
              [isAnimated]="true">
              <div class="col-12">
                <app-google-group-settings [formGroup]="myForm" [id]="'group_plesso'" [json]="data.group_plesso">
                </app-google-group-settings>
              </div>
            </div>
          </div>

          <!--
        ..######..##..........###.....######...######..####
        .##....##.##.........##.##...##....##.##....##..##.
        .##.......##........##...##..##.......##........##.
        .##.......##.......##.....##..######...######...##.
        .##.......##.......#########.......##.......##..##.
        .##....##.##.......##.....##.##....##.##....##..##.
        ..######..########.##.....##..######...######..####
        -->

          <!-- Header -->
          <div [hidden]="!tab4.active" class="card-header" [ngClass]="{'card-is-loading': formLoading}">
            <div class="row justify-content-between align-items-center flex-grow-1">
              <div class="col-9">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-header-title mr-3">Parametri per la creazioni delle classi</h5>
                </div>
              </div>

              <div class="col-3">
                <!-- Filter -->
                <div class="row align-items-sm-center">
                  <div class="col-md">
                  </div>
                </div>
                <!-- End Filter -->
              </div>
            </div>
          </div>
          <!-- End Header -->


          <div [hidden]="!tab4.active" class="card-body" [ngClass]="{'card-is-loading': formLoading}">

            <div formGroupName="classi">
              <h3>Gruppo Docenti</h3>
              <p>Il gruppo conterrà tutti gli utenti della tipologia <mark class="text-cap d-inline">Docenti</mark>
              </p>
              <div class="form-group row">
                <div class="col-sm-8">
                  <label class="input-label">Formato email per i gruppi delle classi per i docenti
                    <button class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                      (click)="clearInput('classi.group_classe_docente_email', 'group_classe_docente_email_tag')">
                      <i class="fas fa-backspace"></i>
                    </button>
                  </label>
                  <app-tagsinput [id]="'group_classe_docente_email_tag'"
                    [defaultValue]="data.group_classe_docente_email"
                    [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                    [setValueEvent]="setValueEventSubject.asObservable()"
                    [buttonInput]="['$name_plesso$', '$short_name_plesso$', '$name_classe$','$anno_classe$']"
                    (onInputText)="onInputTagInput($event, myForm.get('classi.group_classe_docente_email'), 'classi');">
                  </app-tagsinput>

                  <input [hidden]="true" formControlName="group_classe_docente_email" id="group_classe_docente_email"
                    type="text">
                  <div
                    *ngIf="!myForm.get('classi.group_classe_docente_email').valid || data.group_classe_docente_email_error_ignore"
                    class="mt-1 alert alert-soft-danger" role="alert">
                    <label class="toggle-switch d-flex toggle-switch-sm" for="group_classe_docente_email_error_ignore">
                      <input type="checkbox" class="toggle-switch-input"
                        [ngClass]="{'is-invalid': data.group_classe_docente_email_error_ignore}"
                        [(ngModel)]="data.group_classe_docente_email_error_ignore"
                        id="group_classe_docente_email_error_ignore"
                        formControlName="group_classe_docente_email_error_ignore"
                        (change)="changeIgnoreError('classi.group_classe_docente_email', 'group_classe_docente_email_tag')">
                      <span class="toggle-switch-label">
                        <span class="toggle-switch-indicator"></span>
                      </span>
                      <span class="toggle-switch-content">
                        <span class="d-block">{{ 'PARAMETRI.disabilita_controlli_title' | translate }}</span>
                        <small class="d-block text-muted">{{ 'PARAMETRI.disabilita_controlli_msg' | translate }}</small>
                      </span>
                    </label>
                  </div>

                  <div class="text-muted font-size-1 mt-1">deve contenere almeno
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$name_plesso$', 'group_classe_docente_email_tag')">nome
                      plesso</button>
                    o
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$short_name_plesso$', 'group_classe_docente_email_tag')">short
                      nome
                      plesso</button> e
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$name_classe$', 'group_classe_docente_email_tag')">nome
                      classe</button>
                    e
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$anno_classe$', 'group_classe_docente_email_tag')">anno
                      classe</button>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="input-label">Dominio</label>
                  <select class="form-control" formControlName="group_classe_docente_domain"
                    id="group_classe_docente_domain"
                    [ngClass]="(myForm.get('classi.group_classe_docente_domain').dirty || myForm.get('classi.group_classe_docente_domain').touched)? (myForm.get('classi.group_classe_docente_domain').valid ? 'is-valid': 'is-invalid') : 'untouched'"
                    required>
                    <option *ngFor="let el of domains" [value]="el.domainName">@{{ el.domainName }}</option>
                  </select>
                  <span class="invalid-feedback"
                    *ngIf="myForm.get('classi.group_classe_docente_domain').invalid && (myForm.get('classi.group_classe_docente_domain').dirty || myForm.get('classi.group_classe_docente_domain').touched)">Non
                    valido</span>
                  <span class="valid-feedback"
                    *ngIf="myForm.get('classi.group_classe_docente_domain').valid && (myForm.get('classi.group_classe_docente_domain').dirty || myForm.get('classi.group_classe_docente_domain').touched)">Valido</span>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-8">
                  <app-switch [(value)]="data.group_classe_docente_email_accento"
                    (valueChange)="changeValueSwitch('classi','group_classe_docente_email_accento', $event)"
                    [id]="'group_classe_docente_email_accento'" labelText="Inserisci l'apostrofo all'interno dell'email"
                    helpText="Quando nel parametro utilizzato è presente un accento, viene anche inserita all'interno dell'email">
                  </app-switch>
                  <input type="hidden" id="group_classe_docente_email_accento"
                    formControlName="group_classe_docente_email_accento">
                </div>
                <div class="col-sm-4">
                  <button class="btn btn-ghost-primary" type="button"
                    (click)="setGroupSettingCollapse('classi_docente');"
                    [attr.aria-expanded]="groupSettingCollapse.classi_docente"
                    attr.aria-controls="groupSettingCollapse_classi_docente">
                    Impostazioni avanzate gruppo
                    <span class="ml-2" [ngSwitch]="groupSettingCollapse.classi_docente">
                      <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                      <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-8">
                  <div class="alert alert-soft-info" role="alert">
                    <span class="text-cap">Anteprima email</span>
                    <h3 class="alert-heading">{{ loadPreviewParameter(9) }}</h3>
                  </div>
                </div>
              </div>


            </div>
            <div class="form-group row" id="groupSettingCollapse_classi_docente"
              [collapse]="!groupSettingCollapse.classi_docente" [isAnimated]="true">
              <div class="col-12">
                <app-google-group-settings [formGroup]="myForm" [id]="'group_classe_docente'"
                  [json]="data.group_classe_docente">
                </app-google-group-settings>
              </div>
            </div>

            <div formGroupName="classi">
              <h3>Gruppo Studenti</h3>
              <p>Il gruppo conterrà tutti gli utenti della tipologia <mark class="text-cap d-inline">Studenti</mark>
              </p>
              <div class="form-group row">
                <div class="col-sm-8">
                  <label class="input-label">Formato email per i gruppi delle classi per gli studenti
                    <button class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                      (click)="clearInput('classi.group_classe_studente_email', 'group_classe_studente_email_tag')">
                      <i class="fas fa-backspace"></i>
                    </button>
                  </label>
                  <app-tagsinput [id]="'group_classe_studente_email_tag'"
                    [defaultValue]="data.group_classe_studente_email"
                    [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                    [setValueEvent]="setValueEventSubject.asObservable()"
                    [buttonInput]="['$name_plesso$', '$short_name_plesso$', '$name_classe$','$anno_classe$']"
                    (onInputText)="onInputTagInput($event, myForm.get('classi.group_classe_studente_email'), 'classi');">
                  </app-tagsinput>

                  <input [hidden]="true" formControlName="group_classe_studente_email" id="group_classe_studente_email"
                    type="text">
                  <div
                    *ngIf="!myForm.get('classi.group_classe_studente_email').valid || data.group_classe_studente_email_error_ignore"
                    class="mt-1 alert alert-soft-danger" role="alert">
                    <label class="toggle-switch d-flex toggle-switch-sm" for="group_classe_studente_email_error_ignore">
                      <input type="checkbox" class="toggle-switch-input"
                        [ngClass]="{'is-invalid': data.group_classe_studente_email_error_ignore}"
                        [(ngModel)]="data.group_classe_studente_email_error_ignore"
                        id="group_classe_studente_email_error_ignore"
                        formControlName="group_classe_studente_email_error_ignore"
                        (change)="changeIgnoreError('classi.group_classe_studente_email', 'group_classe_studente_email_tag')">
                      <span class="toggle-switch-label">
                        <span class="toggle-switch-indicator"></span>
                      </span>
                      <span class="toggle-switch-content">
                        <span class="d-block">{{ 'PARAMETRI.disabilita_controlli_title' | translate }}</span>
                        <small class="d-block text-muted">{{ 'PARAMETRI.disabilita_controlli_msg' | translate }}</small>
                      </span>
                    </label>
                  </div>

                  <div class="text-muted font-size-1 mt-1">deve contenere almeno
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$name_plesso$', 'group_classe_studente_email_tag')">nome
                      plesso</button> o
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$short_name_plesso$', 'group_classe_studente_email_tag')">short
                      nome
                      plesso</button> e
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$name_classe$', 'group_classe_studente_email_tag')">nome
                      classe</button> e
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$anno_classe$', 'group_classe_studente_email_tag')">anno
                      classe</button>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="input-label">Dominio</label>
                  <select class="form-control" formControlName="group_classe_studente_domain"
                    id="group_classe_studente_domain"
                    [ngClass]="(myForm.get('classi.group_classe_studente_domain').dirty || myForm.get('classi.group_classe_studente_domain').touched)? (myForm.get('classi.group_classe_studente_domain').valid ? 'is-valid': 'is-invalid') : 'untouched'"
                    required>
                    <option *ngFor="let el of domains" [value]="el.domainName">@{{ el.domainName }}</option>
                  </select>
                  <span class="invalid-feedback"
                    *ngIf="myForm.get('classi.group_classe_studente_domain').invalid && (myForm.get('classi.group_classe_studente_domain').dirty || myForm.get('classi.group_classe_studente_domain').touched)">Non
                    valido</span>
                  <span class="valid-feedback"
                    *ngIf="myForm.get('classi.group_classe_studente_domain').valid && (myForm.get('classi.group_classe_studente_domain').dirty || myForm.get('classi.group_classe_studente_domain').touched)">Valido</span>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-8">
                  <app-switch [(value)]="data.group_classe_studente_email_accento"
                    (valueChange)="changeValueSwitch('classi','group_classe_studente_email_accento', $event)"
                    [id]="'group_classe_studente_email_accento'"
                    labelText="Inserisci l'apostrofo all'interno dell'email"
                    helpText="Quando nel parametro utilizzato è presente un accento, viene anche inserita all'interno dell'email">
                  </app-switch>
                  <input type="hidden" id="group_classe_studente_email_accento"
                    formControlName="group_classe_studente_email_accento">
                </div>
                <div class="col-sm-4">
                  <button class="btn btn-ghost-primary" type="button"
                    (click)="setGroupSettingCollapse('classi_studente');"
                    [attr.aria-expanded]="groupSettingCollapse.classi_studente"
                    attr.aria-controls="groupSettingCollapse_classi_studente">
                    Impostazioni avanzate gruppo
                    <span class="ml-2" [ngSwitch]="groupSettingCollapse.classi_studente">
                      <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                      <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-8">
                  <div class="alert alert-soft-info" role="alert">
                    <span class="text-cap">Anteprima email</span>
                    <h3 class="alert-heading">{{ loadPreviewParameter(10) }}</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" id="groupSettingCollapse_classi_studente"
              [collapse]="!groupSettingCollapse.classi_studente" [isAnimated]="true">
              <div class="col-12">
                <app-google-group-settings [formGroup]="myForm" [id]="'group_classe_studente'"
                  [json]="data.group_classe_studente">
                </app-google-group-settings>
              </div>
            </div>

            <div formGroupName="classi">
              <h3>Gruppo misto Docenti e Studenti della classe</h3>
              <p>Il gruppo <b>non conterrà utenti</b> ma conterrà i gruppi <mark
                  class="text-cap d-inline">Docenti</mark> e <mark class="text-cap d-inline">Studenti</mark> sopra
                specificati</p>
              <div class="form-group row">
                <div class="col-auto pr-0" style="margin: auto 0px;">
                  <app-switch (valueChange)="changeValueSwitch('classi','gest_group_classe', $event)"
                    [(value)]="data.gest_group_classe" [id]="'gest_group_classe'"
                    labelText="Abilita la gestione del gruppo dei docenti e studenti della classe">
                  </app-switch>
                  <input type="hidden" id="gest_group_classe" formControlName="gest_group_classe">
                </div>
              </div>
              <div [hidden]="!data.gest_group_classe">
                <div class="form-group row">
                  <div class="col-sm-8">
                    <label class="input-label">Formato email per i gruppi delle classi
                      <button class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                        (click)="clearInput('classi.group_classe_email', 'group_classe_email_tag')">
                        <i class="fas fa-backspace"></i>
                      </button>
                    </label>
                    <app-tagsinput [id]="'group_classe_email_tag'" [defaultValue]="data.group_classe_email"
                      [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                      [setValueEvent]="setValueEventSubject.asObservable()"
                      [buttonInput]="['$name_plesso$', '$short_name_plesso$', '$name_classe$','$anno_classe$']"
                      (onInputText)="onInputTagInput($event, myForm.get('classi.group_classe_email'), 'classi');">
                    </app-tagsinput>

                    <input [hidden]="true" formControlName="group_classe_email" id="group_classe_email" type="text">
                    <div *ngIf="!myForm.get('classi.group_classe_email').valid || data.group_classe_email_error_ignore"
                      class="mt-1 alert alert-soft-danger" role="alert">

                      <label class="toggle-switch d-flex toggle-switch-sm" for="group_classe_email_error_ignore">
                        <input type="checkbox" class="toggle-switch-input"
                          [ngClass]="{'is-invalid': data.group_classe_email_error_ignore}"
                          [(ngModel)]="data.group_classe_email_error_ignore" id="group_classe_email_error_ignore"
                          formControlName="group_classe_email_error_ignore"
                          (change)="changeIgnoreError('classi.group_classe_email', 'group_classe_email_tag')">
                        <span class="toggle-switch-label">
                          <span class="toggle-switch-indicator"></span>
                        </span>
                        <span class="toggle-switch-content">
                          <span class="d-block">{{ 'PARAMETRI.disabilita_controlli_title' | translate }}</span>
                          <small class="d-block text-muted">{{ 'PARAMETRI.disabilita_controlli_msg' | translate
                            }}</small>
                        </span>
                      </label>
                    </div>

                    <div class="text-muted font-size-1 mt-1">deve contenere almeno
                      <button type="button" class="btn btn-outline-primary btn-xs badge"
                        (click)="addParamTo('$name_plesso$', 'group_classe_email_tag')">nome
                        plesso</button>
                      o
                      <button type="button" class="btn btn-outline-primary btn-xs badge"
                        (click)="addParamTo('$short_name_plesso$', 'group_classe_email_tag')">short nome
                        plesso</button> e
                      <button type="button" class="btn btn-outline-primary btn-xs badge"
                        (click)="addParamTo('$name_classe$', 'group_classe_email_tag')">nome
                        classe</button>
                      e
                      <button type="button" class="btn btn-outline-primary btn-xs badge"
                        (click)="addParamTo('$anno_classe$', 'group_classe_email_tag')">anno
                        classe</button>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label class="input-label">Dominio</label>
                    <select class="form-control" formControlName="group_classe_domain" id="group_classe_domain"
                      [ngClass]="(myForm.get('classi.group_classe_domain').dirty || myForm.get('classi.group_classe_domain').touched)? (myForm.get('classi.group_classe_domain').valid ? 'is-valid': 'is-invalid') : 'untouched'"
                      required>
                      <option *ngFor="let el of domains" [value]="el.domainName">@{{ el.domainName }}</option>
                    </select>
                    <span class="invalid-feedback"
                      *ngIf="myForm.get('classi.group_classe_domain').invalid && (myForm.get('classi.group_classe_domain').dirty || myForm.get('classi.group_classe_domain').touched)">Non
                      valido</span>
                    <span class="valid-feedback"
                      *ngIf="myForm.get('classi.group_classe_domain').valid && (myForm.get('classi.group_classe_domain').dirty || myForm.get('classi.group_classe_domain').touched)">Valido</span>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-8">
                    <app-switch [(value)]="data.group_classe_email_accento"
                      (valueChange)="changeValueSwitch('classi','group_classe_email_accento', $event)"
                      [id]="'group_classe_email_accento'" labelText="Inserisci l'apostrofo all'interno dell'email"
                      helpText="Quando nel parametro utilizzato è presente un accento, viene anche inserita all'interno dell'email">
                    </app-switch>
                    <input type="hidden" id="group_classe_email_accento" formControlName="group_classe_email_accento">
                  </div>
                  <div class="col-sm-4">
                    <button class="btn btn-ghost-primary" type="button" (click)="setGroupSettingCollapse('classi');"
                      [attr.aria-expanded]="groupSettingCollapse.classi"
                      attr.aria-controls="groupSettingCollapse_classi">
                      Impostazioni avanzate gruppo
                      <span class="ml-2" [ngSwitch]="groupSettingCollapse.classi">
                        <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                        <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                      </span>
                    </button>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-8">
                    <div class="alert alert-soft-info" role="alert">
                      <span class="text-cap">Anteprima email</span>
                      <h3 class="alert-heading">{{ loadPreviewParameter(8) }}</h3>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="form-group row" id="groupSettingCollapse_classi" [collapse]="!groupSettingCollapse.classi"
              [isAnimated]="true">
              <div class="col-12">
                <app-google-group-settings [formGroup]="myForm" [id]="'group_classe'" [json]="data.group_classe">
                </app-google-group-settings>
              </div>
            </div>
          </div>


          <!--
        ..######...########..##.....##.########..########..####.....######...########.##....##.########.########..####..######..####
        .##....##..##.....##.##.....##.##.....##.##.....##..##.....##....##..##.......###...##.##.......##.....##..##..##....##..##.
        .##........##.....##.##.....##.##.....##.##.....##..##.....##........##.......####..##.##.......##.....##..##..##........##.
        .##...####.########..##.....##.########..########...##.....##...####.######...##.##.##.######...########...##..##........##.
        .##....##..##...##...##.....##.##........##.........##.....##....##..##.......##..####.##.......##...##....##..##........##.
        .##....##..##....##..##.....##.##........##.........##.....##....##..##.......##...###.##.......##....##...##..##....##..##.
        ..######...##.....##..#######..##........##........####.....######...########.##....##.########.##.....##.####..######..####
        -->

          <!-- Header -->
          <div [hidden]="!tab5.active" class="card-header" [ngClass]="{'card-is-loading': formLoading}">
            <div class="row justify-content-between align-items-center flex-grow-1">
              <div class="col-9">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-header-title mr-3">Parametri per la creazioni di gruppi generici</h5>
                </div>
              </div>

              <div class="col-3">
                <!-- Filter -->
                <div class="row align-items-sm-center">
                  <div class="col-md">
                  </div>
                </div>
                <!-- End Filter -->
              </div>
            </div>
          </div>
          <!-- End Header -->


          <div [hidden]="!tab5.active" class="card-body" [ngClass]="{'card-is-loading': formLoading}">
            <div formGroupName="gruppi_generici">
              <h3>Gruppi Generici</h3>

              <div class="form-group row">
                <div class="col-sm-8">
                  <label class="input-label">Formato email
                    <button class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                      (click)="clearInput('gruppi_generici.groups_email', 'groups_email_tag')">
                      <i class="fas fa-backspace"></i>
                    </button>
                  </label>
                  <app-tagsinput [id]="'groups_email_tag'" [defaultValue]="data.groups_email"
                    [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                    [setValueEvent]="setValueEventSubject.asObservable()" [buttonInput]="['$name_group$']"
                    (onInputText)="onInputTagInput($event, myForm.get('gruppi_generici.groups_email'))">
                  </app-tagsinput>

                  <input [hidden]="true" formControlName="groups_email" id="groups_email" type="text">
                  <div *ngIf="!myForm.get('gruppi_generici.groups_email').valid || data.groups_email_error_ignore"
                    class="mt-1 alert alert-soft-danger" role="alert">
                    <label class="toggle-switch d-flex toggle-switch-sm" for="groups_email_error_ignore">
                      <input type="checkbox" class="toggle-switch-input"
                        [ngClass]="{'is-invalid': data.groups_email_error_ignore}"
                        [(ngModel)]="data.groups_email_error_ignore" id="groups_email_error_ignore"
                        formControlName="groups_email_error_ignore"
                        (change)="changeIgnoreError('gruppi_generici.groups_email', 'groups_email_tag')">
                      <span class="toggle-switch-label">
                        <span class="toggle-switch-indicator"></span>
                      </span>
                      <span class="toggle-switch-content">
                        <span class="d-block">{{ 'PARAMETRI.disabilita_controlli_title' | translate }}</span>
                        <small class="d-block text-muted">{{ 'PARAMETRI.disabilita_controlli_msg' | translate }}</small>
                      </span>
                    </label>
                  </div>

                  <div class="text-muted font-size-1 mt-1">deve contenere almeno
                    <button type="button" class="btn btn-outline-primary btn-xs badge"
                      (click)="addParamTo('$name_group$', 'groups_email_tag')">nome
                      gruppo</button>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="input-label">Dominio</label>
                  <select class="form-control" formControlName="groups_domain" id="groups_domain"
                    [ngClass]="(myForm.get('gruppi_generici.groups_domain').dirty || myForm.get('gruppi_generici.groups_domain').touched)? (myForm.get('gruppi_generici.groups_domain').valid ? 'is-valid': 'is-invalid') : 'untouched'"
                    required>
                    <option *ngFor="let el of domains" [value]="el.domainName">@{{ el.domainName }}</option>
                  </select>
                  <span class="invalid-feedback"
                    *ngIf="myForm.get('gruppi_generici.groups_domain').invalid && (myForm.get('gruppi_generici.groups_domain').dirty || myForm.get('gruppi_generici.groups_domain').touched)">Non
                    valido</span>
                  <span class="valid-feedback"
                    *ngIf="myForm.get('gruppi_generici.groups_domain').valid && (myForm.get('gruppi_generici.groups_domain').dirty || myForm.get('gruppi_generici.groups_domain').touched)">Valido</span>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-8">
                  <app-switch [(value)]="data.groups_email_accento"
                    (valueChange)="changeValueSwitch('gruppi_generici','groups_email_accento', $event)"
                    [id]="'groups_email_accento'" labelText="Inserisci l'apostrofo all'interno dell'email"
                    helpText="Quando nel parametro utilizzato è presente un accento, viene anche inserita all'interno dell'email">
                  </app-switch>
                  <input type="hidden" id="groups_email_accento" formControlName="groups_email_accento">
                </div>
                <div class="col-sm-4">
                  <button class="btn btn-ghost-primary" type="button"
                    (click)="setGroupSettingCollapse('gruppi_generici');"
                    [attr.aria-expanded]="groupSettingCollapse.gruppi_generici"
                    attr.aria-controls="groupSettingCollapse_gruppi_generici">
                    Impostazioni avanzate gruppo
                    <span class="ml-2" [ngSwitch]="groupSettingCollapse.gruppi_generici">
                      <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                      <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-8">
                  <div class="alert alert-soft-info" role="alert">
                    <span class="text-cap">Anteprima email</span>
                    <h3 class="alert-heading">{{ loadPreviewParameter(3) }}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row" id="groupSettingCollapse_gruppi_generici"
              [collapse]="!groupSettingCollapse.gruppi_generici" [isAnimated]="true">
              <div class="col-12">
                <app-google-group-settings [formGroup]="myForm" [id]="'group_generic'" [json]="data.group_generic">
                </app-google-group-settings>
              </div>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-end">
            <div class="alert alert-soft-danger m-0 mr-3" role="alert" *ngIf="!myForm.valid">
              Attenzione sono presenti degli errori nei parametri, assicurarsi che tutti i campi, in tutte le
              schede, siano correttamente configurati
            </div>
            <button type="submit" class="btn" [disabled]="!myForm.valid"
              [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
              Salva <i class="far fa-chevron-right ml-1"></i>
            </button>
          </div>

        </div>

      </form>
    </div>

  </div>

</div>
