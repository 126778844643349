<ng-container *ngIf="operationExist">
    <ng-container *ngIf="position == 'header'">
        <ng-container *ngFor="let item of operation">
            <li class="nav-item d-none d-sm-inline-block mr-2" *ngIf="item.count > 0">
                <a class="btn btn-sm btn-outline-{{item.colorClass}}" [routerLink]="item.routerLink">
                    {{ getTitle(item.key) }}
                    <i class="{{item.icon}} ml-2"></i>
                    <span class="btn-status btn-status btn-status-{{item.colorClass}}">{{item.count}}</span>
                </a>
            </li>
        </ng-container>
    </ng-container>
    
    <ng-container *ngIf="position == 'sidebar'">
        <li class="nav-item">
            <div class="nav-divider"></div>
        </li>
    
        <li class="nav-item">
            <small class="nav-subtitle" title="Strumenti">Operazioni Massive</small>
            <i class="fal fa-ellipsis-h nav-subtitle-replacer nav-icon"></i>
        </li>
        <ng-container *ngFor="let item of operation">
            
            <li class="nav-item" *ngIf="item.count > 0">
                <a class="nav-link" [routerLink]="item.routerLink">
                    <i class="{{item.icon}} nav-icon"></i>
                    <span class="navbar-vertical-aside-mini-mode-hidden-elements text-truncate"> {{ getTitle(item.key) }}</span>
                </a>
            </li>
        </ng-container>
    </ng-container>
</ng-container>