<!-- <div class="alert {{alertType}}" role="alert">
  <div class="spinner-border spinner-border-sm mr-2" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  {{ message }}
</div> -->

<div class="row mb-3">
  <div class="col">
    <div class="card">
      <div class="card-body">
        <h2>{{ message }}</h2>
        <p-progressBar mode="indeterminate" [style]="{'height': '20px'}"></p-progressBar>
      </div>
    </div>
  </div>
</div>
