import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { ParametriService } from 'src/app/services/general/parametri.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { PageService } from 'src/app/services/page.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  id: any;
  id_google: any;
  id_classe: any;
  id_plesso: any;
  id_organigramma: any;
  user_role: any;
  showGoogleInfoCard: boolean;
  showForm: boolean;
  showDelete: boolean;

  private _activeRouteSubscription: Subscription;
  private _userServiceSubscription: Subscription;
  private _googleServiceUserSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private googleDirectoryService: GoogleDirectoryService,
    private router: Router,
    private utilityService: UtilityService,
    private activeRoute: ActivatedRoute,
    private pageService: PageService,
    private plessoService: PlessoService,
    private organigrammaService: OrganigrammaService,
    private accountService: AccountService,
    private parametriService: ParametriService
  ) { }

  ngOnInit() {

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_user;
      this.id_google = routeParams.id_google;
      this.id_plesso = routeParams.id_plesso;
      this.id_classe = routeParams.id_classe;
      this.id_organigramma = routeParams.id_organigramma;
      this.user_role = routeParams.user_role;
      this.init();
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.updateData();
    });

    this._googleServiceUserSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleServiceUserSubscription)
      this._googleServiceUserSubscription.unsubscribe();
  }

  init() {
    this.showForm = false;
    this.showDelete = false;
    this.showGoogleInfoCard = false;

    if (this.id && this.id > 0) {
      this.showForm = this.utilityService.checkRouteActionType(this.route, "edit");
      this.showDelete = this.utilityService.checkRouteActionType(this.route, "delete");
      this.updateData();
    }
    else if (this.id_google && this.id_google > 0) {
      this.showGoogleInfoCard = true;
    } else {
      this.showForm = true;
    }

    this.googleDirectoryService.usersList();
  }

  updateData() {

    if (this.id && this.id > 0) {
      this.userService.get(this.id, (data) => {
        this.pageService.updatePageTitle({ user: data.user.familyName + " " + data.user.givenName });
      });
    }

    if (this.id_plesso && this.id_plesso > 0) {
      this.plessoService.get(this.id_plesso, (data) => {
        this.pageService.updatePageTitle({ plesso: data.name });
      });
    }

    if (this.id_classe && this.id_plesso && this.id_plesso > 0 && this.id_classe > 0) {
      this.plessoService.getClasse(this.id_plesso, this.id_classe, (data) => {
        this.pageService.updatePageTitle({ classe: data.name });
      });
    }

    if (this.id_organigramma && this.id_organigramma > 0) {
      this.organigrammaService.get(this.id_organigramma, (data) => {
        this.pageService.updatePageTitle({ organigramma: data.name });
      });
    }

    if (this.id_google && this.id_google > 0) {
      let googleUser = this.googleDirectoryService.getUser(this.id_google);
      if (googleUser)
        this.pageService.updatePageTitle({ user: googleUser.name?.familyName + " " + googleUser.name?.givenName });
    }

    if (this.user_role) {
      this.pageService.updatePageTitle({ ruolo: this.user_role });
    }

    // l'ente posso sempre impostarlo tanto se non c'è non impatta sul breadcrumbs
    this.pageService.updatePageTitle({ ente: this.accountService.data.ente.name });
  }

  getParametriService() {
    return this.parametriService;
  }

  getAccountService() {
    return this.accountService;
  }

}
