<div class="card card-hover-shadow mb-3  ">
  <!-- Header -->
  <div class="card-header bg-card-header">
    <h4 class="card-header-title text-white"><i class="fas fa-analytics mr-2"></i>Statistiche di utilizzo: Google Meet
    </h4>
    <small class="text-white" *ngIf="!rangeDates">Ultimi 7 giorni</small>
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div class="card-body" *ngIf="!loading && parameterToView">
    <div class="row">
      <div class="col-sm-6 col-lg">
        <!-- Stats -->
        <div class="media align-items-center">
          <i class="fas fa-users mr-3" style="font-size: 1.75rem;"></i>

          <div class="media-body">
            <span class="d-block font-size-sm">Attività Utenti</span>
            <div class="d-flex align-items-center">
              <h3 class="mb-0">{{ parameterToView[0].value || 0}}</h3>

              <span *ngIf="parameterToView[0].rate > 0" class="badge badge-soft-success ml-2">
                <i class="tio-trending-up"></i> {{parameterToView[0].rate}}%
              </span>
              <span *ngIf="parameterToView[0].rate < 0" class="badge badge-soft-danger ml-2">
                <i class="tio-trending-down"></i> {{parameterToView[0].rate}}%
              </span>
            </div>
          </div>
        </div>
        <!-- End Stats -->

        <div class="d-lg-none">
          <hr>
        </div>
      </div>

      <div class="col-sm-6 col-lg column-divider-lg">
        <!-- Stats -->
        <div class="media align-items-center">
          <i class="fas fa-clock mr-3" style="font-size: 1.75rem;"></i>

          <div class="media-body">
            <span class="d-block font-size-sm">Durata <small>(min)</small></span>
            <div class="d-flex align-items-center">
              <h3 class="mb-0">{{ parameterToView[1].value || 0}}</h3>

              <span *ngIf="parameterToView[1].rate > 0" class="badge badge-soft-success ml-2">
                <i class="tio-trending-up"></i> {{parameterToView[1].rate}}%
              </span>
              <span *ngIf="parameterToView[1].rate < 0" class="badge badge-soft-danger ml-2">
                <i class="tio-trending-down"></i> {{parameterToView[1].rate}}%
              </span>
            </div>
          </div>
        </div>
        <!-- End Stats -->

        <div class="d-lg-none">
          <hr>
        </div>
      </div>

      <div class="col-sm-6 col-lg column-divider-lg">
        <!-- Stats -->
        <div class="media align-items-center">
          <i class="fas fa-video mr-3" style="font-size: 1.75rem;"></i>

          <div class="media-body">
            <span class="d-block font-size-sm">Chiamate <small>(min)</small></span>
            <div class="d-flex align-items-center">
              <h3 class="mb-0">{{ parameterToView[2].value || 0}}</h3>

              <span *ngIf="parameterToView[2].rate > 0" class="badge badge-soft-success ml-2">
                <i class="tio-trending-up"></i> {{parameterToView[2].rate}}%
              </span>
              <span *ngIf="parameterToView[2].rate < 0" class="badge badge-soft-danger ml-2">
                <i class="tio-trending-down"></i> {{parameterToView[2].rate}}%
              </span>
            </div>
          </div>
        </div>
        <!-- End Stats -->
      </div>
    </div>
    <!-- End Row -->
  </div>
  <!-- End Body -->

  <div class="card-footer pt-0 pb-0">
    <div class="row justify-content-between align-items-center">
      <div class="col-auto py-1">
        <small class="text-body">Ultimo aggiornamento: {{timePassedSeconds}} fa</small>
      </div>

      <div class="col-auto py-1">
        <button *ngIf="!hideButtonDetails" class="btn btn-ghost-primary btn-xs"
          routerLink="/statistics/google-meet">Dettaglio<i class="far fa-chevron-right ml-1"></i></button>
      </div>
    </div>
  </div>

</div>
