import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ParametriService } from '../services/general/parametri.service';

@Injectable()
export class ParametersGuard implements CanActivate {

  constructor(
    private router: Router,
    private parametriService: ParametriService
    ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.parametriService.checkParametriConfigurazione()) {
      return true;
    }

    this.router.navigate(['/dashboard']);
    return false;
  }
}
