import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-loading-overlay',
  templateUrl: './card-loading-overlay.component.html',
  styleUrls: ['./card-loading-overlay.component.scss']
})
export class CardLoadingOverlayComponent implements OnInit {

  @Input() loading: boolean = false;
  @Input() message: string = "Sto caricando il contenuto, attendere...";

  constructor() { }

  ngOnInit() {
  }

}
