<app-card-loading *ngIf="loadingPlesso">
</app-card-loading>

<app-card-loading-error
  *ngIf="!(loadingPlesso) && error"
  [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card"
  *ngIf="!(loadingPlesso) && !error">
  <!-- Header -->
  <div class="card-header">
    <h5 class="card-header-title"><i class="fad fa-school nav-icon blue mr-1"></i> Plesso: {{plesso.name}}</h5>
    <div class="d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="showInfo = !showInfo">
        <i class="fas fa-info mr-2"></i>{{ 'BTN.Informazioni' | translate }}
      </button>
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" [routerLink]="['/plesso/'+id+'/edit']">
        <i class="fas fa-edit mr-2"></i>{{ 'BTN.Modifica' | translate }}
      </button>
      <button type="button" class="btn btn-outline-danger btn-sm" [routerLink]="['/plesso/'+id+'/delete']">
        <i class="far fa-trash mr-2"></i>{{ 'BTN.cancella_plesso' | translate }}
      </button>
    </div>
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div *ngIf="showInfo"  class="card-body">

    <div class="row mb-3">
      <div class="col">
        <small class="card-subtitle">Descrizione</small>
        <p>{{ plesso?.description || 'Nessuna descrizione'}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Organizzazione</small>
        <p>{{ plesso?.google_org_unit?.orgUnitPath || "Nessuna organizzazione associata"}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Creato il</small>
        <p>{{ plesso?.created_at | date:'medium' }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <small class="card-subtitle">Email gruppo plesso</small>
        <p>{{ groupPlesso?.email || "Nessuna gruppo associato"}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Email gruppo docenti</small>
        <p>{{ groupDocenti?.email || "Nessuna gruppo associato"}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Email gruppo studenti</small>
        <p>{{ groupStudenti?.email || "Nessuna gruppo associato"}}</p>
      </div>
    </div>
  </div>
  <!-- End Body -->

  <!-- Footer -->
  <div class="card-footer text-center">
    <!-- Stats -->
    <div class="row">
      <div class="col">
        <span class="h4" *ngIf="plesso.google_classroom">{{ plesso.google_classroom.length }}</span>
        <span class="h4" *ngIf="!plesso.google_classroom">0</span>
        <span class="d-block font-size-sm">Classroom</span>
      </div>

      <div class="col column-divider">
        <span class="h4" *ngIf="plesso.google_group">{{ plesso.google_group.length }}</span>
        <span class="h4" *ngIf="!plesso.google_group">0</span>
        <span class="d-block font-size-sm">{{ 'STR.gruppi' | translate }}</span>
      </div>

      <div class="col column-divider">
        <span class="h4" *ngIf="plesso.classi">{{ plesso.classi.length }}</span>
        <span class="h4" *ngIf="!plesso.classi">0</span>
        <span class="d-block font-size-sm">Classi</span>
      </div>
    </div>
    <!-- End Stats -->
  </div>
  <!-- End Footer -->
</div>
