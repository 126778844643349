import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BackendService } from '../../backend.service';
import { GoogleClassroomService } from '../../google/google-classroom.service';
import { ToastService } from '../../toast.service';
import { UtilityService } from '../../utility.service';

@Injectable({
  providedIn: 'root'
})
export class RemoveMembersFromClassroomsService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  items: any[];

  constructor(private googleClassroomService: GoogleClassroomService, private backend: BackendService, private toast: ToastService, private utilityService: UtilityService) {
    this.reset();
  }

  reset() {
    this.items = [];
    this._subjectToUpdate.next(this.items);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  addMembers(newItems, courseId, ruolo) {
    newItems.forEach(item => {
      let classroom = this.googleClassroomService.getCourse(courseId);

      if (classroom && item?.profile.id != classroom.ownerId) {
        let itemFound = this.items.find((element) => element?.profile.id == item?.profile.id && element?.courseId == courseId);

        if (!itemFound) {
          this.items.push({
            ...item, ...{
              ruolo: ruolo,
              ownerId: classroom.ownerId
            }
          });
        }
      }
    });

    this._subjectToUpdate.next(this.items);
  }

  deleteItemsAtIndex(indexArray) {

    indexArray.forEach(idx => {
      this.items[idx] = null;
    });

    this.items = this.items.filter((el) => { return el != null });

    this._subjectToUpdate.next(this.items);
  }

  execOperation(body, callback = (error) => { }) {
    let members = [];

    this.items.forEach(element => {
      members.push({
        googleId: element.userId,
        courseId: element.courseId,
        type: element.ruolo
      });
    });

    this.backend.post("op/remove-members-from-classrooms", { members: members }).subscribe((result) => {
      this.reset();
      this.toast.success('Rimozione Membri avviato');
      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }
}