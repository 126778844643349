import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MonitorService } from 'src/app/services/general/monitor.service';
import { PageService } from 'src/app/services/page.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-monitor-profile',
  templateUrl: './monitor-profile.component.html',
  styleUrls: ['./monitor-profile.component.scss'],
})
export class MonitorProfileComponent implements OnInit {

  @Input() id: any;

  alfredUrl = environment.alfredUrl;

  monitors: any;
  monitorsData: any;
  monitorsError: any;
  isLoadingMonitors: boolean;
  showOnlyCreate: boolean;
  checkDasboardMonitors: boolean = true;

  private _activeRouteSubscription: Subscription;
  private _monitorServiceSubscription: Subscription;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private monitorService: MonitorService,
    private utilityService: UtilityService,
    private pageService: PageService
  ) { }

  ngOnInit() {
    this.isLoadingMonitors = false;

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_monitor;
      this.init();
    });

    this._monitorServiceSubscription = this.monitorService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {

        this.monitors = result;

        // Se non hai monitor attivi ti mando alla pagina di richiesta
        if (!this.monitors || this.monitors.length < 1) {
          this.router.navigate(['/alfred']);
          return;
        }

        this.dataUpdated(result);
        if (this.id && this.id.length > 0)
          this.updateData();
      }
    });

    this.showOnlyCreate = this.utilityService.checkRouteActionType(this.activeRoute, "create");
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();

    if (this._monitorServiceSubscription)
      this._monitorServiceSubscription.unsubscribe();
  }

  init() {

    this.isLoadingMonitors = true;

    this.monitorService.all((result) => {
      this.monitors = result;
      this.dataUpdated(result);
    });

    if (this.id && this.id > 0) {
      this.updateData();
    }
  }

  updateData() {
    if (this.id && this.id > 0) {
      this.monitorService.get(this.id, (data) => {
        this.pageService.updatePageTitle({ materia: data.name });
      }, (error) => {
        this.monitorService.all();
      });
    }
  }

  dataUpdated(result) {
    this.monitorsData = this.parseResult(result);
    this.isLoadingMonitors = false;
  }

  parseResult(result) {

    let table = [];

    result.forEach(element => {

      table.push(
        {
          "nome": [element.name, element.description],
          "code": [element.code],
          "status": [element.active ? '<span class="badge badge-success">Attivo</span>' : '<span class="badge badge-danger">Non attivo</span>'],
          "link": ['<a target="_blank" href="' + this.alfredUrl + element.code + '">' + this.alfredUrl + element.code + '</a>'],
          "_export_data": {
            name: element.name,
            code: element.code,
            description: element.description,
            status: element.active,
            link: this.alfredUrl + element.code
          },
        });
    });

    return table;
  }

  rowClick(data) {
    let monitor = this.monitors[data.index];
    this.router.navigate(['/monitor/' + monitor.id]);
  }

  addClick() {
    this.router.navigate(['/monitor/create']);
  }
}
