<ng-container *ngIf="showMap">
  <app-organigramma-profile-tree-card></app-organigramma-profile-tree-card>
</ng-container>

<ng-container *ngIf="showForm  && !deleteMode">
  <app-organigramma-form [id]="id" [relation_info_standard]="relation_info_standard" [editMode]="editMode"
    [createMode]="createMode"></app-organigramma-form>
</ng-container>

<ng-container *ngIf="showForm && deleteMode">
  <app-organigramma-form-delete [id]="id"></app-organigramma-form-delete>
</ng-container>

<ng-container *ngIf="!showForm && !showMap">
  <div class="row mb-3">
    <div class="col-12">
      <app-organigramma-profile-info-card [id]="id"></app-organigramma-profile-info-card>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-organigramma-profile-datatable-card [id]="id"></app-organigramma-profile-datatable-card>
    </div>
  </div>
</ng-container>
