<div class="card">
  <!-- Header -->
  <div class="card-header">
    <h5 class="card-header-title"><i class="fad fa-sitemap nav-icon green mr-1"></i>Organigramma: {{ data?.name }}</h5>
    <div class="d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="showInfo = !showInfo">
        <i class="fas fa-info mr-2"></i>{{ 'BTN.Informazioni' | translate }}
      </button>
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" [routerLink]="['/organigramma/'+id+'/edit']">
        <i class="fas fa-edit mr-2"></i>{{ 'BTN.Modifica' | translate }}
      </button>
      <button type="button" class="btn btn-outline-danger btn-sm" [routerLink]="['/organigramma/'+id+'/delete']">
        <i class="far fa-trash mr-2"></i>{{ 'BTN.cancella_organigramma' | translate }}
      </button>
    </div>
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div *ngIf="showInfo" class="card-body">

    <div class="row">
      <div class="col">
        <small class="card-subtitle">Descrizione</small>
        <p>{{ data?.description || 'Nessuna descrizione'}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Organizzazione</small>
        <p>{{ data?.google_org_unit?.orgUnitPath || "Nessuna organizzazione associata"}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Creato il</small>
        <p>{{ data?.created_at | date:'medium' }}</p>
      </div>
    </div>

  </div>
  <!-- End Body -->

  <!-- Footer -->
  <div class="card-footer text-center">
    <!-- Stats -->
    <div class="row">
      <div class="col">
        <span class="h4">{{ countUtenti }}</span>
        <span class="d-block font-size-sm">{{ 'STR.Utenti' | translate }}</span>
      </div>

      <div class="col column-divider">
        <span class="h4">{{ data?.google_group?.length}}</span>
        <span class="d-block font-size-sm">{{ 'STR.gruppi' | translate }}</span>
      </div>

      <!-- <div class="col column-divider">
        <span class="h4">10</span>
        <span class="d-block font-size-sm">Classi</span>
      </div> -->
    </div>
    <!-- End Stats -->
  </div>
  <!-- End Footer -->
</div>
