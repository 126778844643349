import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: Function;
declare let window: any;
declare let sendinblue: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private productionTag: string = 'G-HSQQ5JYVP4';

  constructor(private router: Router) { }

  public init() {

    if (environment.production) {
      gtag('config', this.productionTag, {
        'app_version': environment.version
      });
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (environment.production) {
          if (typeof gtag == 'function') {
            gtag('config', this.productionTag, {
              'page_path': event.urlAfterRedirects
            });
          }
          if (sendinblue && typeof sendinblue.page == 'function') {
            sendinblue.page(event.urlAfterRedirects, {
              'ma_url': window.location.href,
              // 'ma_title': '',
              'ma_path': event.urlAfterRedirects,
              'app_version': environment.version
            });
          }
        }
      }
    });
  }

  public setUserId(user: any) { // viene passata l'email

    if (environment.production) {
      if (typeof gtag == 'function') {
        gtag('config', this.productionTag, {
          'user_id': user.email
        });
      }
      if (window.sib) {
        window.sib.email_id = user.email;
      }
      if (sendinblue && typeof sendinblue.identify == 'function') {
        sendinblue.identify(user.email, {
          'FIRSTNAME': user.givenName,
          'LASTNAME': user.familyName,
          'id': user.id,
          'ente': user.ente.name,
          'ente_id': user.ente.id,
          'app_version': environment.version,
          'subscription_end': user.ente.subscription_end
        });
      }
    }
  }

  public eventEmitter(eventName: string, eventCategory: string, eventLabel: string = null, eventValue: number = null) {

    if (environment.production) {
      if (typeof gtag == 'function') {
        gtag('event', eventName, {
          eventCategory: eventCategory,
          eventLabel: eventLabel,
          eventValue: eventValue,
          'app_version': environment.version
        })
      }

      // if (sendinblue && typeof sendinblue.track == 'function') {
      //   sendinblue.track(
      //     'cart_created',
      //     {
      //       "FIRSTNAME": "John",
      //       "LASTNAME" : "Doe",
      //       "PLAN" : "Diamond",
      //       "LOCATION" : "San Francisco"
      //     },
      //     {
      //       "id": "a4123c72-c6f7-4d8e-b8cd-4abb8a807891",
      //       "data": {
      //         "products": [
      //           {
      //             "product_id": 1234,
      //             "product_name": "a",
      //             "amount": 1,
      //             "price": 220
      //           },
      //           {
      //             "product_id": 5768,
      //             "product_name": "b",
      //             "amount": 5,
      //             "price": 1058
      //           }
      //         ]
      //       }
      //     },
      //   );
      // }
    }
  }

}
