import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alfred-start',
  templateUrl: './alfred-start.component.html',
  styleUrls: ['./alfred-start.component.scss']
})
export class AlfredStartComponent implements OnInit {

  checkInProgress: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
