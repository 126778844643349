<!-- Card -->
<div class="card">
  <!-- Header -->
  <div class="card-header p-1">
    <div class="row justify-content-between align-items-center flex-grow-1">
      <div class="col">
        <tabset [justified]="true" type="pills">
          <tab #tab1="tab" (selectTab)="selectTab(1)">
            <ng-template tabHeading>
              <i class="fas fa-user-graduate nav-icon"></i>{{ 'STR.studenti' | translate }}
            </ng-template>
          </tab>
          <tab #tab2="tab" (selectTab)="selectTab(2)">
            <ng-template tabHeading>
              <i class="fas fa-chalkboard-teacher nav-icon"></i>{{ 'STR.docenti' | translate }}
            </ng-template>
          </tab>
          <tab #tab3="tab" (selectTab)="selectTab(3)">
            <ng-template tabHeading>
              <i class="fas fa-backpack nav-icon"></i> {{ 'STR.courseWork' | translate }}
            </ng-template>
          </tab>
          <tab #tab4="tab" (selectTab)="selectTab(4)">
            <ng-template tabHeading>
              <i class="fas fa-folder-tree nav-icon"></i> {{ 'STR.courseWorkMaterials' | translate }}
            </ng-template>
          </tab>
          <tab #tab5="tab" (selectTab)="selectTab(5)">
            <ng-template tabHeading>
              <i class="fas fa-users nav-icon"></i> {{ 'STR.courseAnnouncements' | translate }}
            </ng-template>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
  <!-- End Header -->
  <!-- <div class="card-body">

    </div> -->

  <div class="alert alert-soft-info" *ngIf="tab1.active || tab2.active">
    <span class="text-cap d-inline"><i class="fas fa-info-circle mr-2"></i>Suggerimento:</span> per aggiungere docenti/studenti nella classroom, selezionarli nelle classi o nell'ente e usare l'operazione massiva <b>"Aggiungi in una classroom esistente"</b>
  </div>

  <app-card-datatable-ng *ngIf="tab1.active" title="{{ 'STR.studenti' | translate }}" [data]="data.students"
    [rowData]="course.students" [menuType]="'classroom-studenti'" [idRootData]="id"
    [isLoadingData]="_loadingData.students" [showAvatarOnFirstColumn]="true" [error]="dataError.students"
    (rowClickEvent)="rowClickStudente($event)" (addClickEvent)="addClickUser('studente')" [showAddButton]="false"
    [showExportButton]="true">
  </app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab2.active" title="{{ 'STR.docenti' | translate }}" [data]="data.teachers"
    [rowData]="course.teachers" [menuType]="'classroom-docenti'" [idRootData]="id"
    [isLoadingData]="_loadingData.teachers" [showAvatarOnFirstColumn]="true" [error]="dataError.teachers"
    (rowClickEvent)="rowClickDocente($event)" (addClickEvent)="addClickUser('docente')" [showAddButton]="false"
    [showExportButton]="true">
  </app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab3.active" title="{{ 'STR.courseWork' | translate }}" [data]="data.courseWork"
    [rowData]="course.courseWork" [menuType]="'classroom-course-work'" [idRootData]="id"
    [isLoadingData]="_loadingData.courseWork" [showAvatarOnFirstColumn]="false" [showCheckBox]="false"
    [error]="dataError.courseWork" [showAddButton]="false" [rowExpansion]="true">
  </app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab4.active" title="{{ 'STR.courseWorkMaterials' | translate }}"
    [data]="data.courseWorkMaterials" [rowData]="course.courseWorkMaterials"
    [menuType]="'classroom-course-work-materials'" [idRootData]="id" [isLoadingData]="_loadingData.courseWorkMaterials"
    [showAvatarOnFirstColumn]="false" [showCheckBox]="false" [error]="dataError.courseWorkMaterials"
    [showAddButton]="false" [rowExpansion]="true">
  </app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab5.active" title="{{ 'STR.courseAnnouncements' | translate }}"
    [data]="data.courseAnnouncements" [rowData]="course.courseAnnouncements"
    [menuType]="'classroom-course-announcements'" [idRootData]="id" [showCheckBox]="false"
    [isLoadingData]="_loadingData.courseAnnouncements" [showAvatarOnFirstColumn]="false"
    [error]="dataError.courseAnnouncements" [showAddButton]="false" [showExportButton]="true">
  </app-card-datatable-ng>
</div>
<!-- End Card -->
