<div class="row mb-5">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body m-2">
        <app-passaggio-anno-wizard-steps [currentStep]="3"></app-passaggio-anno-wizard-steps>
      </div>
    </div>
  </div>
</div>

<div class="row mt-5" *ngIf="loadingPlessoService">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body">
        <app-card-loading>
        </app-card-loading>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="!loadingPlessoService">
  <div class="row mt-5" *ngFor="let element_plesso of struttura; let index_plesso = index;">
    <div class="col">
      <!-- Card -->
      <div class="card card-hover-shadow">

        <div class="card-header card-btn btn-block" [ngClass]="{'collapsed': element_plesso.isCollapsed}"
          (click)="element_plesso.isCollapsed = !element_plesso.isCollapsed">
          <h2 class="card-header-title">
            <i class="tio-company mr-2"></i>{{element_plesso.plesso.name}} <small>(
              {{'PASSAGGIOANNO.preview_struttura.abbreviezione' | translate}}:
              "{{element_plesso.plesso.short_name}}")</small>

            <h5 class="mt-2">{{ 'STR.org_unit' | translate }}
              <ng-container *ngIf="element_plesso.plesso.google_org_unit">
                {{element_plesso.plesso.google_org_unit.orgUnitPath}}
              </ng-container>
              <ng-container *ngIf="!element_plesso.plesso.google_org_unit">
                {{'PASSAGGIOANNO.preview_struttura.not_found' | translate}}
              </ng-container>
            </h5>
          </h2>

          <span class="card-btn-toggle" [attr.aria-expanded]="!element_plesso.isCollapsed"
            aria-controls="collapsePlesso">
            <span class="btn btn-xs btn-ghost-primary card-btn-toggle-default">
              <i class="fas fa-chalkboard mr-1"></i>
              {{'PASSAGGIOANNO.preview_struttura.show_classi' | translate}}
            </span>
            <span class="btn btn-xs btn-outline-primary card-btn-toggle-active">
              <i class="fas fa-chalkboard mr-1"></i>
              {{'PASSAGGIOANNO.preview_struttura.hide_classi' | translate}}
            </span>
          </span>
        </div>

        <div class="card-body">
          <div class="mb-2">
            <app-switch (valueChange)="enableDisablePlesso(element_plesso, index_plesso, $event)"
              [(value)]="element_plesso.selected" [id]="index_plesso" labelText="Includi il plesso nel passaggio anno">
            </app-switch>
          </div>

          <span [collapse]="element_plesso.isCollapsed" [isAnimated]="true">
            <ng-container>
              <ul class="list-group list-group-lg">
                <li class="list-group-item">
                  <ng-container *ngFor="let element_classe of element_plesso.classi; let index_classe = index;">
                    <div class="row align-items-center my-3 pointer"
                      (click)="element_classe.isCollapsed = !element_classe.isCollapsed">
                      <div class="col-3 align-self-start">
                        <small class="text-cap">{{'PASSAGGIOANNO.preview_struttura.classe' | translate}} </small>
                        <span class="font-weight-bold text-dark lead"><i
                            class="fas fa-chalkboard mr-2"></i>{{element_classe.classe.name}}</span>
                      </div>
                      <div class="col-3 align-self-start">
                        <small class="text-cap">{{'PASSAGGIOANNO.preview_struttura.classe_next' | translate}}</small>
                        <span class="font-weight-bold text-dark lead" *ngIf="element_classe.next_classe"><i
                            class="fas fa-chalkboard mr-2"></i>{{element_classe.next_classe?.name}}</span>
                        <span class="font-weight-bold text-dark lead"
                          *ngIf="!element_classe.next_classe">{{'PASSAGGIOANNO.preview_struttura.not_found' | translate}}</span>
                      </div>
                      <div class="col-3 align-self-start" *ngIf="!element_classe.next_classe && organigramma">
                        <small
                          class="text-cap">{{'PASSAGGIOANNO.preview_struttura.organigramma_destinazione' | translate}}</small>
                        <span class="font-weight-bold text-dark lead"><i
                            class="fas fa-chalkboard mr-2"></i>{{organigramma.name}}</span>
                      </div>
                      <div class="col align-self-center text-danger"
                        *ngIf="element_classe.next_classi_proposed && element_classe.next_classi_proposed.length > 1">
                        <small
                          class="text-cap">{{'PASSAGGIOANNO.preview_struttura.conflitto_classi' | translate}}</small>
                        <span class="font-weight-bold lead">
                          <i class="fas fa-exclamation-circle mr-2"></i><span class="mr-2"
                            *ngFor="let classe_conflitto of element_classe.next_classi_proposed">{{classe_conflitto.name}}</span>
                        </span>
                      </div>
                    </div>
                  </ng-container>
                </li>
              </ul>
            </ng-container>
          </span>
        </div>


      </div>
      <!-- End Card -->
    </div>
  </div>
</ng-container>

<!-- Footer Button Movimento -->
<div class="row mt-5">
  <div class="col">
    <!-- Card -->
    <div class="card card-hover-shadow">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto py-1">
            <button (click)="back()" type="button" class="btn btn-white">
              <i class="far fa-chevron-left mr-1"></i> {{'PASSAGGIOANNO.btn.indietro' | translate}}
            </button>
          </div>
          <div class="col-auto py-1">
            <div class="row justify-content-end">
              <div class="col-auto">
                  <input autocomplete="off" type="text" id="confirmation_avvia" name="confirmation_avvia"
                      #confirmation_avvia="ngModel" [ngModel]="''" class="form-control"
                      [pattern]="regEx" required>
                  <small class="d-block text-muted">Per avviare il passaggio anno, digita
                      <span class="confirmation-phrase">Conferma</span></small>
              </div>
              <div class="col-auto">
                <button [disabled]="!confirmation_avvia.valid" (click)="next()" type="button" class="btn btn-primary">
                  {{'PASSAGGIOANNO.btn.avvia' | translate}} <i class="far fa-chevron-right ml-1"></i>
                </button>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Card -->
  </div>
</div>
<!-- /Footer Button Movimento -->
