import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../../backend.service';
import { CourseState } from '../../google/google-classroom.service';
import { ToastService } from '../../toast.service';
import { UtilityService } from '../../utility.service';

@Injectable({
  providedIn: 'root'
})
export class SuspendClassroomService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  items: any[];

  constructor(private backend: BackendService, private toast: ToastService, private utilityService: UtilityService) {
    this.reset();
  }

  reset() {
    this.items = [];
    this._subjectToUpdate.next(this.items);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  addItems(newItems) {

    newItems.forEach(item => {

      let itemFound = this.items.find((element) => element?.id == item?.id);

      if (!itemFound) {
        let addItem = this.utilityService.cloneData(item);
        addItem.courseId = item.id;
        addItem.courseState = CourseState.SUSPENDED;
        this.items.push(addItem);
      }
    });

    this._subjectToUpdate.next(this.items);
  }

  deleteItemsAtIndex(indexArray) {

    indexArray.forEach(idx => {
      this.items[idx] = null;
    });

    this.items = this.items.filter((el) => { return el != null });

    this._subjectToUpdate.next(this.items);
  }

  execOperation(callback = (error) => { }) {

    let courses = [];

    this.items.forEach(course => {
      course.courseState = CourseState.SUSPENDED;
      courses.push(course);
    });

    this.backend.post("op/update-classroom", { courses: courses }).subscribe((result) => {
      this.reset();
      this.toast.success('Aggiornamento Classroom avviato');
      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }
}
