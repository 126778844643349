<app-card-loading *ngIf="loading">
</app-card-loading>


<app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">
  <div class="row">
    <div class="col-12">
      <app-card-operation-activity [filterActivity]="['operation.classroom.remove-members-from-groups']">
      </app-card-operation-activity>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <div class="card card-hover-shadow">
        <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)">
          <!-- Card -->
          <div class="card card-hover-shadow">
            <div class="card-body">

              <h3 class="card-title">Rimozione Membri dalle Classrooms</h3>
              <p class="card-text">Questa operazione consente di rimuovere in maniera massiva tutti i membri selezionati</p>
              <p class="card-text">I PRORPIETARI non possono essere rimossi, quindi verranno sempre scartati.</p>
            </div>

            <div class="card-footer">
              <div class="row justify-content-between align-items-center">
                <div class="col-auto py-1">
                  <button class="btn btn-outline-danger" (click)="resetOperation()"
                    [disabled]="members.length <= 0 || operationInProgress">
                    <i class="far fa-backspace mr-2"></i>
                    <span>{{'STR.reset_operation' | translate}}</span>
                  </button>
                </div>

                <div class="col-6 py-1">
                  <div class="row justify-content-end">
                    <div class="col-auto">
                      <input autocomplete="off" type="text" id="confirmation_delete" name="confirmation_delete"
                        #confirmation_delete="ngModel" [ngModel]="''" class="form-control" [pattern]="regEx" required>
                      <small class="d-block text-muted">Per confermare, digita
                        <span class="confirmation-phrase">Rimuovi</span></small>
                    </div>
                    <div class="col-auto">
                      <button type="submit" class="btn"
                        [disabled]="!myForm.valid || members.length <= 0 || operationInProgress"
                        [ngClass]="(myForm.dirty || myForm.touched)? 'btn-danger' : 'btn-outline-danger' ">
                        Rimuovi Membri <i class="far fa-chevron-right ml-1"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Card -->
        </form>
      </div>
      <!-- End Card -->
    </div>
  </div>


  <div class="row">
    <div class="col-md-12 col-sm">
      <div class="card">
        <app-card-datatable-ng title="{{ 'STR.utenti_selezionati' | translate }}" [data]="data"
          [showDeleteButton]="true" labelDeleteButton="Rimuovi dall'operazione" iconDeleteButton="user-times"
          (deleteClickEvent)="deleteEvent($event)" [showExportButton]="true"></app-card-datatable-ng>
      </div>
    </div>
  </div>
</ng-container>
