<div class="row mb-5">
  <div class="col">
      <div class="card card-hover-shadow">
          <div class="card-body m-2">
              <app-passaggio-anno-wizard-steps [currentStep]="1"></app-passaggio-anno-wizard-steps>
          </div>
      </div>
  </div>
</div>

<div class="card card-hover-shadow">
  <div class="card-body">
    <div class="row justify-content-sm-center text-center py-3">
      <div class="col-sm-12 col-md-8">
        <h1><i class="fad fa-cloud-download mr-2"></i> {{'PASSAGGIOANNO.backup.title' | translate}}</h1>
        <p [innerHTML]="'PASSAGGIOANNO.backup.sottotitolo' | translate"></p>

        <a href="https://admin.google.com/ac/users" target="_black" type="button" class="btn btn-primary">
          {{'PASSAGGIOANNO.backup.btn' | translate}} <i class="far fa-chevron-right ml-1"></i>
        </a>

        <p class="my-3">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/JX_Qd3d1R-0?start=72" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="row mt-5">
  <div class="col">
      <!-- Card -->
      <div class="card card-hover-shadow">
          <div class="card-body">
              <div class="row justify-content-between align-items-center">
                  <div class="col-auto py-1">
                      <button (click)="back()" type="button" class="btn btn-white">
                          <i class="far fa-chevron-left mr-1"></i> {{'PASSAGGIOANNO.btn.indietro' | translate}}
                      </button>
                  </div>
                  <div class="col-auto py-1">
                      <button (click)="next()" type="button" class="btn btn-primary">
                          {{'PASSAGGIOANNO.btn.avanti' | translate}} <i class="far fa-chevron-right ml-1"></i>
                      </button>
                  </div>
              </div>
          </div>
      </div>
      <!-- End Card -->
  </div>
</div>
