import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as pusher from 'pusher-js';
import { AccountService } from './services/account.service';
import { Subscription } from 'rxjs';
import { ParametriService } from './services/general/parametri.service';
import { SettingsService } from './services/settings.service';
import { AnalyticsService } from './services/analytics.service';
import { PageService } from './services/page.service';
import { CheckVersionService } from './services/check-version.service';

const Pusher = pusher;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  userLoggedId: boolean = false;
  _accountServiceSubscription: Subscription;
  _parametriServiceSubscription: Subscription;
  _settingsServiceSubscription: Subscription;
  _pageErrorSubscription: Subscription;
  showSidebar: boolean;
  showPageError: boolean;
  menuMini: boolean;

  constructor(
    private accountService: AccountService,
    private parametriService: ParametriService,
    private settingsService: SettingsService,
    private analyticsService: AnalyticsService,
    private pageService: PageService,
    private checkVersionService: CheckVersionService
    ) { }

  ngOnInit() {

    this.analyticsService.init();

    this._accountServiceSubscription = this.accountService.getSubjectToUpdateObservable().subscribe((result) => {
      this.accountUpdated(result);
    });
    this._parametriServiceSubscription = this.parametriService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.accountUpdated(result);
    });

    this._settingsServiceSubscription = this.settingsService.getSubjectToUpdateObservable().subscribe(() => {
      this.menuMini = this.settingsService.menuMini;
    });

    this._pageErrorSubscription = this.pageService.getSubjectPageErrorObservable().subscribe((mgs) => {
      this.showPageError = true;
    });

    this.menuMini = this.settingsService.menuMini;

    this.checkVersionService.initVersionCheck();
  }

  accountUpdated(result) {
    if (this.accountService.isLoggedIn() && this.parametriService.checkParametriConfigurazione()) {
      this.userLoggedId = true;
    }
    else {
      this.userLoggedId = false;
    }
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  getUserService() {
    return this.accountService;
  }

  retryClicked() {
    location.reload();
  }

  getAccountService() {
    return this.accountService;
  }

}
