import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';

@Injectable()
export class CheckVersionService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  public newVersionAvailable: boolean = false;
  public newVersion: string = '';
  public manutenzione: any;
  public manutenzione_fine: Date;

  private checkVersionInterval: any;

  constructor(
    private http: HttpClient,
    private toast: ToastService,
    private router: Router
    ) { }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  public initVersionCheck(frequency = 1000 * 60 * 10) {

    if (this.checkVersionInterval) {
      clearInterval(this.checkVersionInterval);
    }

    // la frequenza è in millisencondi, quella di default è ogni 10 minuti (1000 * 60 * 10)
    this.checkVersionInterval = setInterval(() => {
      this.checkVersion(environment.checkVersionUrl);
    }, frequency);

    this.checkVersion(environment.checkVersionUrl);
  }

  private checkVersion(url) {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().getTime())
      .subscribe(
        (response: any) => {
          const ver: any = response.version;
          const versionChanged = environment.version != ver;

          // If new version, do something
          if (versionChanged) {
            this.newVersionAvailable = true;
            this.newVersion = ver;
            this._subjectToUpdate.next(true);
            this.toast.warn('Aggiornamento disponibile', "Un nuovo aggiornamento dell'applicazione è disponibile, ricaricare la pagina")
          }

          this.manutenzione = response.manutenzione;
          this.manutenzione_fine = new Date(response.manutenzione_fine);
        }
      );
  }

}
