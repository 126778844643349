<!-- ========== HEADER ========== -->
<div class="navbar-nav-wrap">
  <div class="navbar-brand-wrapper">
    <!-- Logo -->
    <a class="navbar-brand" [routerLink]="['/']" aria-label="Front">
      <img class="navbar-brand-logo" src="../../assets/theme/svg/logos/geniusuite.svg" alt="Geniusuite">
    </a>
    <!-- End Logo -->
  </div>

  <div class="navbar-nav-wrap-content-left">
    <app-header-search></app-header-search>
  </div>

  <!-- Secondary Content -->
  <div class="navbar-nav-wrap-content-right">
    <!-- Navbar -->
    <ul class="navbar-nav align-items-center flex-row">

      <app-list-operation [position]="'header'"></app-list-operation>

      <li class="nav-item d-none d-sm-inline-block" style="min-width: 180px;" *ngFor="let activity of activityData">
        <a class="btn btn-sm btn-block btn-ghost-primary text-left" (click)="showActivitySidebar()">
          <i class="far fa-clipboard-list-check mr-2"></i>
          <span class="font-size-sm">{{ 'ACTIVITY.' + activity.job_batch_name + '.description' | translate }}</span>
          <p-progressBar [value]="activity.perc_overall" [showValue]="false"  [style]="{'height': '8px'}"></p-progressBar>
        </a>
      </li>

      <li class="nav-item d-none d-sm-inline-block">
        <a class="btn btn-icon rounded-circle" [ngClass]="{'btn-ghost-secondary': !announcementCount || announcementCount <= 0, 'btn-soft-warning': announcementCount && announcementCount > 0}" routerLink="/announcement">
          <i class="far fa-bullhorn"></i>
          <span *ngIf="announcementCount && announcementCount > 0" class="btn-status btn-sm-status btn-status-warning"></span>
        </a>
      </li>

      <li class="nav-item d-none d-sm-inline-block" *ngIf="getAccountService().isGoogleWorkspaceAccount()">
        <a class="btn btn-icon rounded-circle" [ngClass]="{'btn-ghost-secondary': !activityData || activityData.length <= 0, 'btn-soft-warning': activityData && activityData.length > 0}" (click)="showActivitySidebar()">
          <i class="far fa-clipboard-list-check"></i>
          <span *ngIf="activityData && activityData.length > 0" class="btn-status btn-status-warning">{{activityData.length}}</span>
        </a>
      </li>

      <li class="nav-item d-none d-sm-inline-block">
        <a class="btn btn-icon btn-ghost-secondary rounded-circle" (click)="showHelpSidebar()">
          <i class="far fa-question-circle"></i>
        </a>
      </li>

      <li *ngIf="isBetaVersion" class="nav-item">
        <a class="btn btn-sm btn-block btn-warning text-left" (click)="adminVersion()">
          <i class="fas fa-monkey mr-2"></i>
          <span class="font-size-sm">Esci dalla BETA</span>
        </a>
      </li>

      <li class="nav-item">
        <app-header-account></app-header-account>
      </li>
    </ul>
    <!-- End Navbar -->
  </div>
  <!-- End Secondary Content -->
</div>
<!-- ========== END HEADER ========== -->
