import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { linkSync } from 'fs';
import { Subscription } from 'rxjs';
import { MonitorService } from 'src/app/services/general/monitor.service';
import { GoogleDriveService } from 'src/app/services/google/google-drive.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-monitor-form',
  templateUrl: './monitor-form.component.html',
  styleUrls: ['./monitor-form.component.scss']
})
export class MonitorFormComponent implements OnInit {

  @Input() id: any;

  loading = false;
  error = null;
  data: any;
  showModalAddWidget: boolean = false;
  showModalConfigureWidget: boolean = false;

  selectedLayout: any;
  selectedConfiguration: any;
  selectedWidgetPosition: any;
  selectedWidgetPositionConfiguration: any;
  configurations: any;

  configDays: any = [
    { name: "Lunedì", code: "monday" },
    { name: "Martedì", code: "tuesday" },
    { name: "Mercoledì", code: "wednesday" },
    { name: "Giovedì", code: "thursday" },
    { name: "Venerdì", code: "friday" },
    { name: "Sabato", code: "saturday" },
    { name: "Domenica", code: "sunday" },
  ]

  monitorLayouts: any;
  widgetsList: any;

  emptyConfiguration: any = {
    layout: null,
    days: null,
    startTime: null,
    endTime: null,
    widgets: [],
    options: null
  };

  defaultStartTime: Date;
  defaultEndTime: Date;

  private _monitorServiceSubscription: Subscription;

  @ViewChild('myForm') myForm: NgForm;

  constructor(
    private monitorService: MonitorService,
    private router: Router,
    private utility: UtilityService,
    private sanitizer: DomSanitizer,
    private googleDriveService: GoogleDriveService
  ) { }

  ngOnInit(): void {

    this.monitorLayouts = this.utility.cloneData(this.monitorService.monitorLayouts);
    this.widgetsList = this.utility.cloneData(this.monitorService.widgetsList);

    this.loading = true;

    this._monitorServiceSubscription = this.monitorService.getSubjectToUpdateObservable().subscribe(
      (result) => {

        this.loading = false;

        if (result) {
          this.error = null;
          this.init();
        }
      });

    this.monitorService.all();

    // Imposto le info di default sulla configurazione vuota
    this.defaultStartTime = new Date();
    this.defaultEndTime = new Date();
    this.defaultStartTime.setHours(6, 0, 0, 0);
    this.defaultEndTime.setHours(23, 0, 0, 0);
  }

  init() {

    if (this.id) {
      this.data = this.monitorService.get(this.id);
    }

    if (!this.data) {
      this.data = {}
    }

    if (!this.data.configurations || this.data.configurations.length == 0) {

      this.data.configurations = [
        this.utility.cloneData(this.emptyConfiguration)
      ];

      this.configurations = [
        this.utility.cloneData(this.emptyConfiguration)
      ];
    }
    else {
      this.configurations = JSON.parse(this.data.configurations);
      this.data.configurations = JSON.parse(this.data.configurations);
    }

    this.configurations.forEach(configuration => {

      // marco la configurazione come non cambiata, appena faccio qualche modifica questo valore diventa true
      configuration.changed = false;

      if (!configuration.layout) {
        this.selectLayout(this.configurations[0], this.monitorLayouts[0]);
      }

      // sovrascrivo eventuali opzioni di default aggiunte
      configuration.options = { ...configuration.options, ...this.emptyConfiguration.options };
    });
  }

  ngOnDestroy() {
    if (this._monitorServiceSubscription)
      this._monitorServiceSubscription.unsubscribe();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  onSubmit() {

    if (this.myForm.valid) {

      this.loading = true;

      // aggiorno la configurazione prima di inviarla
      this.data.configurations = this.configurations;

      // Copio i dati dell'oggetto senza riferimento
      let reqBody = JSON.parse(JSON.stringify(this.data));

      if (this.id) {
        // Update
        this.monitorService.update(this.id, reqBody, true, (result) => {
          this.monitorService.all();
        }, (error) => {
          this.loading = false;
          this.error = "backend";
        })
      } else {
        // Create
        this.monitorService.create(reqBody, true, (result) => {
          this.monitorService.all();
          this.router.navigate(['/monitor']);
        }, (error) => {
          this.loading = false;
          this.error = "backend";
        })
      }
    }
  }

  selectLayout(configuration, layout) {
    configuration.layout = layout;
    configuration.changed = true;
  }

  configureWidgetPosition(configuration, widget, index) {
    this.selectedWidgetPosition = index;
    this.selectedConfiguration = configuration;
    configuration.widgets[index] = this.utility.cloneData(widget);

    let default_widget_found = null;
    // cerco il widget selezionato nella sua configurazione iniziale
    this.monitorService.widgetsList.forEach(default_widget => {
      if (widget.id == default_widget.id) {
        default_widget_found = this.utility.cloneData(default_widget);
      }
    });

    // fondo i due stili
    configuration.widgets[index].style = { ...configuration.layout.widgets[index].style, ...widget.style };

    let current_widget_config = this.utility.cloneData(configuration.widgets[index].config);
    this.selectedWidgetPositionConfiguration = { ...default_widget_found.config, ...current_widget_config };

    // verifico se ci sono inizializzazioni da fare
    if (widget.id == "testo") {

      if (!this.selectedWidgetPositionConfiguration.lines) {
        this.selectedWidgetPositionConfiguration.lines = [];
      }

      if (this.selectedWidgetPositionConfiguration.lines.length == 0) {
        this.configWidgetTestoAggiungiLinea(this.selectedWidgetPositionConfiguration.lines);
      }
    }

    if (widget.id == "immagine") {

      if (!this.selectedWidgetPositionConfiguration.images) {
        this.selectedWidgetPositionConfiguration.images = [];
      }

      if (this.selectedWidgetPositionConfiguration.images.length == 0) {
        this.configWidgetImmagineAggiungiLinea(this.selectedWidgetPositionConfiguration.images);
      }

      this.selectedWidgetPositionConfiguration.images.forEach(image => {
        this.configWidgetImmagineGetFileInfo(image);
      });
    }

    this.showModalAddWidget = false;
    this.showModalConfigureWidget = true;
  }

  configureEmptyWidgetPosition(configuration, item, index) {
    this.selectedWidgetPosition = index;
    this.selectedConfiguration = configuration;
    this.selectedWidgetPositionConfiguration = null;
    configuration.widgets[index] = this.utility.cloneData(item);
    this.showModalAddWidget = true;
    this.showModalConfigureWidget = false;
  }

  aggiungiConfigurazione() {
    this.configurations.push(this.utility.cloneData(this.emptyConfiguration));
  }

  annullaModalConfigureWidget() {
    this.showModalConfigureWidget = false;
    this.selectedWidgetPositionConfiguration = null;
  }

  salvaModalConfigureWidget(configuration) {
    this.showModalConfigureWidget = false;
    configuration.widgets[this.selectedWidgetPosition].config = this.utility.cloneData(this.selectedWidgetPositionConfiguration);
    // configuration.widgets[this.selectedWidgetPosition].config.url = this.sanitizer.bypassSecurityTrustResourceUrl(configuration.widgets[this.selectedWidgetPosition].config.url);
    configuration.widgets[this.selectedWidgetPosition].config.embedTrustHtml = this.sanitizer.bypassSecurityTrustHtml(configuration.widgets[this.selectedWidgetPosition].config.embedText);
    this.selectedWidgetPositionConfiguration = null;
    configuration.changed = true;
  }

  removeModalConfigureWidget(configuration) {
    configuration.widgets[this.selectedWidgetPosition] = null;
    this.showModalConfigureWidget = false;
    this.selectedWidgetPositionConfiguration = null;
    configuration.changed = true;
  }

  replaceModalConfigureWidget(configuration) {
    let index = this.selectedWidgetPosition;
    this.configureEmptyWidgetPosition(configuration, configuration.widgets[index], index);
  }

  removeConfiguration(index) {
    this.configurations.splice(index, 1);
  }

  resetConfiguration(index) {
    this.selectLayout(this.configurations[index], this.monitorLayouts[0]);
    this.configurations[index].widgets = [];
    this.configurations[index].options = this.utility.cloneData(this.emptyConfiguration.options);
  }

  configWidgetTestoAggiungiLinea(lines) {

    if (!lines) {
      lines = [];
    }

    lines[lines.length] = { text: "" };
  }

  configWidgetTestoRemoveLinea(lines, index) {

    lines.splice(index, 1);

    if (lines.length == 0) {
      this.configWidgetTestoAggiungiLinea(lines);
    }
  }

  configWidgetImmagineAggiungiLinea(images) {

    if (!images) {
      images = [];
    }

    images[images.length] = { url: "", type: "href", id: null, name: null, doc: null, info: null, error: null, loading: false };
  }

  configWidgetImmagineAggiungiLineaGoogleDrive(images) {

    if (!images) {
      images = [];
    }

    this.googleDriveService.showPicker((data) => {

      let selections = this.googleDriveService.parsePickedData(data);

      selections.forEach(doc => {

        let index = images.length;

        images[index] = { url: null, type: "google-drive", id: doc[google.picker.Document.ID], name: doc[google.picker.Document.NAME], doc: doc, info: null, error: null, loading: true };

        this.configWidgetImmagineGetFileInfo(images[index]);
      });
    });
  }

  configWidgetImmagineGetFileInfo(image) {

    if (image.type == "google-drive") {

      image.loading = true;

      this.googleDriveService.getFileInfo(image.id, (info) => {

        console.log(info);

        // Aggiungo le info extra all'immagine
        image.info = info

        // Verifico se l'immagine ha il permesso di accedervi pubblicamente
        if (info.permissions) {
          let public_access = false
          info.permissions.forEach(perm => {
            if (perm.type == "anyone" && perm.id == "anyoneWithLink") {
              public_access = true;
            }
          });

          if (!public_access) {
            image.error = "Attenzione l'immagine non è codivisa pubblicamente, non sarà visibile";
          }
          else {
            image.error = null;
          }
        }

        image.loading = false;
      }, (response) => {
        image.loading = false;
        image.error = "Errore nel recupero delle informazioni da Google Drive";
      });
    }
  }

  configWidgetImmagineRemoveLinea(images, index) {

    images.splice(index, 1);

    if (images.length == 0) {
      this.configWidgetTestoAggiungiLinea(images);
    }
  }


}
