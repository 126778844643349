<div #scrollContainerProfilePage *ngIf="!showForm && !showDelete">

  <div class="row">

    <div class="col-md-12 col-sm mb-1">
      <div class="mb-3">
        <app-classe-profile-info-card [id_plesso]="id_plesso" [id_classe]="id_classe"></app-classe-profile-info-card>
      </div>
    </div>

    <div class="col-md-12 col-sm">
      <app-classe-profile-datatable-card [id_plesso]="id_plesso" [id_classe]="id_classe"></app-classe-profile-datatable-card>
    </div>

  </div>
</div>

<div *ngIf="showDelete && !showForm">
  <app-classe-form-delete [id_plesso]="id_plesso" [id_classe]="id_classe"></app-classe-form-delete>
</div>

<div *ngIf="showForm">
  <app-classe-form [id_plesso]="id_plesso" [id_classe]="id_classe"></app-classe-form>
</div>
