<app-card-loading *ngIf="loading">
</app-card-loading>

<app-card-loading-error *ngIf="!(loading) && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!(loading) && !error">
    <div class="card card-hover-shadow mb-4">

        <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>

        <form #myForm="ngForm" (ngSubmit)="onSubmit()" validate>

            <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">
                <div class="row">
                    <!-- Left -->
                    <div class="col">

                        <!-- Titlte -->
                        <div class="row form-group">
                            <div class="col-12">
                                <h3>Modifica Classroom</h3>
                            </div>
                        </div>

                        <!-- Nome -->
                        <div class="row form-group">
                            <div class="col-12">
                                <label class="input-label" for="name">Nome</label>
                                <input id="name" name="name" #name="ngModel" class="form-control"
                                    [(ngModel)]="data.name"
                                    [ngClass]="(name.dirty || name.touched)? (name.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                                    type="text" required maxlength="255" (input)="changeData()">

                                <div class="invalid-feedback" *ngIf="name.invalid && (name.dirty || name.touched)">
                                    <span>Non valido</span>
                                </div>
                                <span class="valid-feedback"
                                    *ngIf="name.valid && (name.dirty || name.touched)">Valido</span>
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-12">
                                <label class="input-label" for="description">Descrizione</label>
                                <input id="description" name="description" #description="ngModel" class="form-control"
                                    [(ngModel)]="data.description"
                                    [ngClass]="(description.dirty || description.touched)? (description.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                                    type="text" maxlength="255" (input)="changeData()">

                                <div class="invalid-feedback"
                                    *ngIf="description.invalid && (description.dirty || description.touched)">
                                    <span>Non valido</span>
                                </div>
                                <span class="valid-feedback"
                                    *ngIf="description.valid && (description.dirty || description.touched)">Valido</span>
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-12">
                                <label class="input-label" for="section">Sezione</label>
                                <input id="section" name="section" #section="ngModel" class="form-control"
                                    [(ngModel)]="data.section"
                                    [ngClass]="(section.dirty || section.touched)? (section.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                                    type="text" maxlength="255" (input)="changeData()">

                                <div class="invalid-feedback"
                                    *ngIf="section.invalid && (section.dirty || section.touched)">
                                    <span>Non valido</span>
                                </div>
                                <span class="valid-feedback"
                                    *ngIf="section.valid && (section.dirty || section.touched)">Valido</span>
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-12">
                                <label class="input-label" for="room">Stanza</label>
                                <input id="room" name="room" #room="ngModel" class="form-control"
                                    [(ngModel)]="data.room"
                                    [ngClass]="(room.dirty || room.touched)? (room.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                                    type="text" maxlength="255" (input)="changeData()">

                                <div class="invalid-feedback" *ngIf="room.invalid && (room.dirty || room.touched)">
                                    <span>Non valido</span>
                                </div>
                                <span class="valid-feedback"
                                    *ngIf="room.valid && (room.dirty || room.touched)">Valido</span>
                            </div>
                        </div>
                    </div>

                    <!-- Right Edit -->
                    <div class="col-5 p-3 m-3 bg-light rounded-lg">
                        <!-- Titlte -->
                        <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Sincronizzazione con Google Workspace</h4>

                        <div class="row">
                            <div class="col-12">
                                <div class="alert alert-soft-info" role="alert" *ngIf="!dataIsChanged; else elseBlock">
                                    Non ci sono modifiche
                                </div>
                                <ng-template #elseBlock>
                                    <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.name">
                                        La classroom verrà rinominata da {{course.name}} a <b>{{data.name}}</b>
                                    </div>
                                    <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.section">
                                        La sezione della classroom verrà rinominato da {{course.section}} a
                                        <b>{{data.section}}</b>
                                    </div>
                                    <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.description">
                                        La descrizione della classroom verrà rinominato da {{course.description}} a
                                        <b>{{data.description}}</b>
                                    </div>
                                    <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.room">
                                        La stanza della classroom verrà rinominato da {{course.room}} a
                                        <b>{{data.room}}</b>
                                    </div>
                                    <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.ownerId">
                                        Il proprietario della classroom verrà modificato
                                    </div>
                                    <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.students">
                                        Gli studenti della classroom verranno modificati
                                    </div>
                                    <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.teachers">
                                        I docenti della classroom verranno modificati
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer border-0 mx-3">
                <div class="row justify-content-between align-items-center">
                    <div class="col-auto py-1">
                        <button *ngIf="id" [routerLink]="['/classroom']" type="button" class="btn btn-white">
                            <i class="far fa-chevron-left mr-1"></i> Annulla
                        </button>
                    </div>
                    <div class="col-6 py-1">
                        <div class="row justify-content-end">
                            <button type="submit" class="btn" [disabled]="!myForm.form.valid"
                                [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                                <span>Aggiorna</span>
                                <i class="far fa-chevron-right ml-1"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <app-card-loading *ngIf="_loadingData.students || _loadingData.teachers; else loadingUsers">
    </app-card-loading>

    <ng-template #loadingUsers>
        <div class="row">
            <div class="col-md-12 col-sm">
                <div class="card card-hover-shadow">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <span class="d-block h4"><i class="fas fa-chalkboard-teacher mr-2"></i>Docenti <span
                                        class="badge badge-secondary">{{data.teachers?.length || 0}}</span></span>
                                <div class="alert alert-soft-danger" *ngIf="data.teachers?.length == 0">
                                    <b>Attenzione</b> non sono presenti docenti in questa classroom.<br>
                                    Questa classroom non verrà creata.
                                </div>
                                <ul class="list-group list-group-lg w-100" *ngIf="data.teachers?.length > 0">
                                    <ng-container *ngFor="let element_user of data.teachers; let index_utente = index;">
                                        <li class="list-group-item"
                                            [ngClass]="{'alert-soft-danger': element_user.errors?.length > 0}">
                                            <ng-container>
                                                <div class="row">
                                                    <div class="col-12 align-self-start">
                                                        <small class="text-cap"
                                                            [title]="element_user.fullname">{{element_user.fullname}}</small>
                                                        <div [title]="element_user.email" class="text-truncate">
                                                            {{element_user.email}}</div>
                                                    </div>
                                                    <div class="col d-flex justify-content-end align-items-center">
                                                        <button type="button" class="btn btn-xs mt-2"
                                                            [ngClass]="{'btn-outline-primary': data.ownerId != element_user.googleId, 'btn-primary': data.ownerId == element_user.googleId}"
                                                            (click)="setUserAsOwner(element_user);">
                                                            <ng-template
                                                                *ngIf="data.ownerId == element_user.googleId; then isOwner else isNotOwner">
                                                            </ng-template>
                                                            <ng-template #isOwner>
                                                                <i class="fas fa-crown mr-1"></i>
                                                                Proprietario
                                                            </ng-template>
                                                            <ng-template #isNotOwner>
                                                                <i class="fal fa-crown mr-1"></i>
                                                                Imposta come Proprietario
                                                            </ng-template>
                                                        </button>

                                                        <button type="button"
                                                            class="ml-2 btn btn-xs mt-2 btn-outline-danger"
                                                            [disabled]="data.ownerId == element_user.googleId" (click)="removeUserFromClassroom(element_user, 'teachers');">
                                                            <i class="fas fa-trash mr-1"></i>
                                                            Rimuovi
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>

                            <div class="col-6">
                                <span class="d-block h4"><i class="fas fa-user-graduate mr-2"></i>Studenti <span
                                        class="badge badge-secondary">{{data.students?.length || 0}}</span></span>
                                <div class="alert alert-soft-danger" *ngIf="data.students?.length == 0">
                                    <b>Attenzione</b> non sono presenti studenti in questa classroom.
                                </div>
                                <ul class="list-group list-group-lg w-100" *ngIf="data.students?.length > 0">
                                    <ng-container *ngFor="let element_user of data.students; let index_utente = index;">
                                        <li class="list-group-item"
                                            [ngClass]="{'alert-soft-danger': element_user.errors?.length > 0}">
                                            <ng-container>
                                                <div class="row">
                                                    <div class="col-8 align-self-start">
                                                        <small class="text-cap"
                                                            [title]="element_user.fullname">{{element_user.fullname}}</small>
                                                        <div [title]="element_user.email" class="text-truncate">
                                                            {{element_user.email}}</div>
                                                    </div>
                                                    <div class="col d-flex justify-content-end align-items-center">
                                                        <button type="button"
                                                            class="ml-2 btn btn-xs mt-2 btn-outline-danger" (click)="removeUserFromClassroom(element_user, 'students');">
                                                            <i class="fas fa-trash mr-1"></i>
                                                            Rimuovi
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
