import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MateriaService } from 'src/app/services/general/materia.service';
import { ParametriService, ParamType } from 'src/app/services/general/parametri.service';
import { RelationInfo } from 'src/app/services/general/relation.service';
import { SyncdataService } from 'src/app/services/syncdata.service';
import { OrderTypeList, UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-materie-form',
  templateUrl: './materie-form.component.html',
  styleUrls: ['./materie-form.component.scss']
})
export class MaterieFormComponent implements OnInit {
  @Input() id: string;
  @Output() onSubmitForm = new EventEmitter();
  data: any;

  newMaterie: any;
  newMaterie_Cloned: any;

  materia: any;
  existingMaterie: any;
  error: any;
  invalidMaterie: boolean;

  // Loading Pagina
  loadingMateria: boolean;
  loadingMateriaService: boolean;
  loadingParameterService: boolean;
  formLoading: boolean;
  edit: boolean;
  parameterLoaded: boolean;

  private _materiaServiceSubscription: Subscription;
  private _parameterServiceSubscription: Subscription;


  constructor(
    private materiaService: MateriaService,
    private syncdataService: SyncdataService,
  ) { }

  ngOnInit() {

    this.unsubscibeAll();

    this.data = {};
    this.newMaterie = [];
    this.newMaterie_Cloned = [];

    this.loadingMateria = false;
    this.loadingMateriaService = false;
    this.loadingParameterService = false;
    this.formLoading = false;
    this.edit = false;
    this.parameterLoaded = false;
    this.invalidMaterie = false;

    if (this.id && this.id.length > 0) {
      this.edit = true;
      this.getBackendData();
    }

    this._materiaServiceSubscription = this.materiaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError("Materia Service");
      else
        this.materiaServiceUpdated(result);
    })

    this.loadingMateriaService = true;
    this.materiaService.all();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  parametriServiceUpdated(result) {
    this.loadingParameterService = false;
    this.parameterLoaded = true;
  }

  materiaServiceUpdated(result) {
    this.existingMaterie = result;
    this.loadingMateriaService = false;
  }

  unsubscibeAll() {
    if (this._materiaServiceSubscription)
      this._materiaServiceSubscription.unsubscribe();

    if (this._parameterServiceSubscription)
      this._parameterServiceSubscription.unsubscribe();


  }

  getFilterData(campo: string) {
    let arrFilter = this.materiaService.data.map(x => x[campo]);
    let concat = arrFilter.concat(this.newMaterie.map(x => x[campo]));
    return concat;
  }

  getBackendData() {
    this.loadingMateria = true;

    this.materiaService.get(this.id, (result) => {

      this.materia = result;
      this.data.name = this.materia.name;

      this.loadingMateria = false;
    }, (error) => {
      this.materiaService.all();
      this.backendError("Get All Materie");
    })
  }

  backendError(position) {
    this.error = "backend " + position;
    this.formLoading = false;
    this.loadingMateria = false;
  }

  onSubmit(myForm: NgForm) {

    if (this.newMaterie && this.newMaterie.length > 0) {
      this.formLoading = true;

      this.newMaterie.forEach(element => {
        delete element.invalid;
      });

      let reqBody = { materie: this.newMaterie };

      if (this.id && this.id.length > 0) {
        // Update
        this.materiaService.update(this.id, reqBody, true, (result) => {
          this.formLoading = false;
          this.ngOnInit();
          this.onSubmitForm.emit();
        }, (error) => {
          this.backendError("Update");
        })
      } else {
        // Create
        this.materiaService.create(reqBody, true, (result) => {
          this.formLoading = false;
          this.ngOnInit();
          this.onSubmitForm.emit();
        }, (error) => {
          this.backendError("Create");
        })
      }
    }
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  addMateria(myForm) {
    if (this.data.name && myForm.valid) {
      this.newMaterie.push({ name: this.data.name, invalid: false });
      this.newMaterie_Cloned.push({ name: this.data.name, invalid: false });
      this.data.name = null;
    }
  }

  checkTableEditValue(data, index, type) {

    if (type == 'blur') {
      // Data is null
      if (!data) {
        this.newMaterie.splice(index, 1);
        this.newMaterie_Cloned.splice(index, 1);
        return;
      }
    }

    // Errore and Reset Value
    let findExist = this.newMaterie.find((x, _index) => x.name.toLowerCase().trim() === data.toLowerCase().trim() && _index != index);
    let findExistData = this.materiaService.data.find(x => x.name.toLowerCase().trim() === data.toLowerCase().trim());

    if (findExist || findExistData) {
      this.newMaterie[index].invalid = true;

      if (type == 'blur')
        this.newMaterie[index] = this.newMaterie_Cloned[index];
    }
    else
      this.newMaterie[index].invalid = false;

    this.checkErrorPresent();
  }

  checkErrorPresent() {
    if (this.newMaterie.filter(e => e.invalid == true).length > 0) {
      this.invalidMaterie = true;
    } else {
      this.invalidMaterie = false;
    }
  }
}
