import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { ParametriService } from 'src/app/services/general/parametri.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-account',
  templateUrl: './header-account.component.html',
  styleUrls: ['./header-account.component.scss']
})
export class HeaderAccountComponent implements OnInit {

  logoutUrl = environment.logoutUrl;
  version = environment.version;
  account: any;

  isBetaVersion: boolean = false;

  private _accountServiceSubscription: Subscription;

  constructor(
    private accountService: AccountService,
    private parametriService: ParametriService,
    private router: Router) { }

  ngOnInit() {

    this.account = this.accountService.data;

    this._accountServiceSubscription = this.accountService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.accountUpdated(result);
    });

    if (window.location.origin.startsWith(environment.betaUrl)) {
      this.isBetaVersion = true;
    }
  }

  accountUpdated(result) {
    this.account = result;
  }

  ngOnDestroy() {

    if (this._accountServiceSubscription)
      this._accountServiceSubscription.unsubscribe();
  }

  logout() {

    this.accountService.logout(() => {
      this.router.navigate(['/login']);
    });
  }

  betaVersion() {
    window.location.href = environment.betaUrl;
  }

  adminVersion() {
    window.location.href = environment.adminUrl;
  }

  getParametriService() {
    return this.parametriService;
  }

  getAccountService() {
    return this.accountService;
  }

  checkForUpdate() {

  }

}
