import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { CacheService, CacheType } from '../cache.service';
import { GoogleDirectoryService } from '../google/google-directory.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class MonitorService {

  data: any;
  indexSelected: any;
  baseUrlResource: string = "monitor"

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  constructor(private backend: BackendService, private cacheService: CacheService, private toast: ToastService, private userService: UserService, private utility: UtilityService) { }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  all(
    success_callback = (data: any) => { },
    error_callback = (error: any) => { }
  ) {

    if (this.cacheService.isCacheValid(CacheType.Monitor)) {
      let dataCloned = this.utility.cloneData(this.data);
      this._subjectToUpdate.next(dataCloned);
      success_callback(dataCloned);
      return;
    }

    this.backend.get(`${this.baseUrlResource}/all`).subscribe(
      (response) => {
        this.data = response;
        this.cacheService.updateCacheStatus(CacheType.Monitor, true);
        let dataCloned = this.utility.cloneData(this.data);
        this._subjectToUpdate.next(dataCloned);
        success_callback(dataCloned);
      },
      (error) => {
        this._subjectToUpdate.next(null);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Create Materia
   * @param info Array del monitor da aggiungere [{name: "Nome Monitor", code: "XXXX", configuration: "JSON", active: true/false}]
   * @param toastNotification Notifica tramite toast
   */
  create(
    info,
    toastNotification = false,
    success_callback = (data: any) => { },
    error_callback = (error: any) => { }
  ) {
    this.backend.post(`${this.baseUrlResource}`, info).subscribe(
      (result) => {

        this.cacheService.updateCacheStatus(CacheType.Monitor, false);

        if (toastNotification) {
          this.toast.success('Monitor aggiunto');
        }

        success_callback(result);
      },
      (error) => {
        error_callback(error);
      }
    );
  }

  /**
   * Update
   * @param id id
   * @param info Informazioni
   * @param toastNotification Notifica tramite toast
   */
  update(
    id,
    info,
    toastNotification = false,
    success_callback = (data: any) => { },
    error_callback = (error: any) => { }
  ) {
    this.backend
      .put(`${this.baseUrlResource}/${id}`, info)
      .subscribe(
        (result) => {

          this.cacheService.updateCacheStatus(CacheType.Monitor, false);

          if (toastNotification) {
            this.toast.success('Monitor Modificato');
          }

          success_callback(result);
        },
        (error) => {
          error_callback(error);
        }
      );
  }

  /**
   * Delete
   * @param id Id
   * @param toastNotification Notifica tramite toast
   */
  delete(
    id,
    toastNotification = false,
    success_callback = (data: any) => { },
    error_callback = (error: any) => { }
  ) {
    this.backend
      .destroy(`${this.baseUrlResource}/${id}`)
      .subscribe(
        (result) => {

          this.cacheService.updateCacheStatus(CacheType.Monitor, false);

          if (toastNotification) {
            this.toast.success('Monitor Eliminato');
          }

          success_callback(result);
        },
        (error) => {
          error_callback(error);
        }
      );
  }

  /**
   * Recupero
   * @param id Id
   */
  get(id, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    if (this.data) {

      let materia = this.data.find((x) => x.id == id);

      if (materia) {
        let dataCloned = this.utility.cloneData(materia);
        success_callback(dataCloned);
        return dataCloned;
      }
      else {
        error_callback(null);
        return null;
      }
    } else {
      error_callback(null);
      return null;
    }
  }

  getByName(name, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    if (this.data) {

      let materia = this.data.find((x) => x.name == name);

      if (materia) {
        let dataCloned = this.utility.cloneData(materia);
        success_callback(dataCloned);
        return dataCloned;
      }
      else {
        error_callback(null);
        return null;
      }
    } else {
      error_callback(null);
      return null;
    }
  }

  monitorLayouts: any = [
    {
      id: 1,
      style: {
        'grid-template-columns': 'repeat(4, 1fr)',
        'grid-template-areas': '"a a a a" "a a a a" "a a a a" "a a a a"'
      },
      widgets: [
        {
          id: 1,
          style: {
            'grid-area': 'a',
          }
        }
      ]
    },
    {
      id: 2,
      style: {
        'grid-template-columns': 'repeat(4, 1fr)',
        'grid-template-areas': '"a a b b" "a a b b" "c c d d"'
      },
      widgets: [
        {
          id: 1,
          style: {
            'grid-area': 'a'
          }
        },
        {
          id: 2,
          style: {
            'grid-area': 'b'
          }
        },
        {
          id: 3,
          style: {
            'grid-area': 'c'
          }
        },
        {
          id: 4,
          style: {
            'grid-area': 'd'
          }
        }
      ]
    },
    {
      id: 3,
      style: {
        'grid-template-columns': 'repeat(3, 1fr)',
        'grid-template-areas': '"a a b " "a a b " "c c c"'
      },
      widgets: [
        {
          id: 1,
          style: {
            'grid-area': 'a'
          }
        },
        {
          id: 2,
          style: {
            'grid-area': 'b'
          }
        },
        {
          id: 3,
          style: {
            'grid-area': 'c'
          }
        }
      ]
    },
    {
      id: 4,
      style: {
        'grid-template-columns': 'repeat(3, 1fr)',
        'grid-template-areas': '"a b b " "a b b " "c c c"'
      },
      widgets: [
        {
          id: 1,
          style: {
            'grid-area': 'a'
          }
        },
        {
          id: 2,
          style: {
            'grid-area': 'b'
          }
        },
        {
          id: 3,
          style: {
            'grid-area': 'c'
          }
        }
      ]
    },
    {
      id: 5,
      style: {
        'grid-template-columns': 'repeat(4, 1fr)',
        'grid-template-areas': '"a a b b " "a a b b " "c c c c"'
      },
      widgets: [
        {
          id: 1,
          style: {
            'grid-area': 'a'
          }
        },
        {
          id: 2,
          style: {
            'grid-area': 'b'
          }
        },
        {
          id: 3,
          style: {
            'grid-area': 'c'
          }
        }
      ]
    },
  ];

  widgetsList: any = [
    {
      id: "youtube-single-video",
      class: "widget-youtube-video",
      style: {
        'background-image': 'url("./assets/img/monitor-widgets/youtube-video.png")',
        'background-size': 'contain',
        'background-color': 'rgba(229,45,39, 0.5)',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
      },
      config: {
        url: "",
        embedText: "",
        embedTrustHtml: ""
      }
    },
    {
      id: "youtube-playlist-video",
      class: "widget-youtube-playlist-video",
      style: {
        'background-image': 'url("./assets/img/monitor-widgets/youtube-playlist.png")',
        'background-size': 'contain',
        'background-color': 'rgba(0,0,0, 0.5)',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
      },
      config: {
        url: "",
        embedText: "",
        embedTrustHtml: "",
        options: {}
      }
    },
    {
      id: "google-slide",
      class: "widget-google-slide",
      style: {
        'background-image': 'url("./assets/img/monitor-widgets/google-slide.png")',
        'background-size': 'contain',
        'background-color': 'rgba(248,191,9, 0.5)',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
      },
      config: {
        url: "",
        embedText: "",
        embedTrustHtml: "",
        options: {
          interval: 10,
          auto_start: true,
          loop: true
        }
      }
    },
    {
      id: "testo",
      class: "widget-testo",
      style: {
        'background-image': 'url("./assets/img/monitor-widgets/testo.png")',
        'background-size': 'contain',
        'background-color': 'rgba(0, 74, 173, 0.5)',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
      },
      config: {
        url: "",
        embedText: "",
        embedTrustHtml: "",
        lines: [],
        options: {
          interval: 10
        }
      }
    },
    {
      id: "calendario",
      class: "widget-calendario",
      style: {
        'background-image': 'url("./assets/img/monitor-widgets/calendario.png")',
        'background-size': 'contain',
        'background-color': 'rgba(0, 128, 55, 0.5)',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
      },
      config: {
        url: "",
        embedText: "",
        embedTrustHtml: "",
        options: {
          display_max_num: 7,
          // update_interval_sec: 3600
        }
      }
    },
    // {
    //   id: "data-ora",
    //   class: "widget-data-ora",
    //   style: {
    //     'background-image': 'url("./assets/img/monitor-widgets/data-ora.png")',
    //     'background-size': 'contain',
    //     'background-color': 'rgba(3, 153, 158, 0.5)',
    //     'background-repeat': 'no-repeat',
    //     'background-position': 'center',
    //   },
    //   config: {
    //     url: "",
    //     embedText: "",
    //     embedTrustHtml: "",
    // options: {}
    //   }
    // },
    {
      id: "feed",
      class: "widget-feed",
      style: {
        'background-image': 'url("./assets/img/monitor-widgets/feed.png")',
        'background-size': 'contain',
        'background-color': 'rgba(255, 166, 1, 0.5)',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
      },
      config: {
        url: "",
        embedText: "",
        embedTrustHtml: "",
        options: {
          display_max_num: 2,
          // update_interval_sec: 3600
        }
      }
    },
    {
      id: "immagine",
      class: "widget-immagine",
      style: {
        'background-image': 'url("./assets/img/monitor-widgets/immagine.png")',
        'background-size': 'contain',
        'background-color': 'rgba(86, 174, 255, 0.5)',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
      },
      config: {
        url: "",
        embedText: "",
        embedTrustHtml: "",
        images: [],
        options: {
          interval: 10
        }
      }
    },
    // {
    //   id: "meteo",
    //   class: "widget-meteo",
    //   style: {
    //     'background-image': 'url("./assets/img/monitor-widgets/meteo.png")',
    //     'background-size': 'contain',
    //     'background-color': 'rgba(32, 108, 207, 0.5)',
    //     'background-repeat': 'no-repeat',
    //     'background-position': 'center',
    //   },
    //   config: {
    //     url: "",
    //     embedText: "",
    //     embedTrustHtml: "",
    // options: {}
    //   }
    // },
    {
      id: "canva",
      class: "widget-canva",
      style: {
        'background-image': 'url("./assets/img/monitor-widgets/canva.png")',
        'background-size': 'contain',
        'background-color': 'rgba(87, 167, 186, 0.5)',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
      },
      config: {
        url: "",
        embedText: "",
        embedTrustHtml: "",
        options: {
          interval: 5,
          max_slide: 1
        }
      }
    },
    {
      id: "powerpoint",
      class: "widget-powerpoint",
      style: {
        'background-image': 'url("./assets/img/monitor-widgets/powerpoint.png")',
        'background-size': 'contain',
        'background-color': 'rgba(218, 120, 77, 0.5)',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
      },
      config: {
        url: "",
        embedText: "",
        embedTrustHtml: "",
        options: {
          auto_start: true,
        }
      }
    },
  ];
}
