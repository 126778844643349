import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { CreateSingleClassroomService } from 'src/app/services/operations/classroom/create-single-classroom.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-create-single-classroom',
  templateUrl: './create-single-classroom.component.html',
  styleUrls: ['./create-single-classroom.component.scss']
})
export class CreateSingleClassroomComponent implements OnInit, OnDestroy {
  regEx = ConfigService.regExConfirm;
  error: boolean = false;
  _loadingData = {
    studenti: false,
    docenti: false
  };

  showAllSetting: boolean = false;
  formLoading: boolean = false;

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  studenti: any = [];
  docenti: any = [];
  model: any;

  operationInProgress: boolean = false;

  private _createSingleClassroomServiceSubscription: Subscription;
  private _userServiceSubscription: Subscription;
  private _googleUserServiceSubscription: Subscription;

  constructor(
    private createSingleClassroomService: CreateSingleClassroomService,
    private userService: UserService,
    private googleService: GoogleDirectoryService,
    private router: Router,
  ) { }

  ngOnInit() {

    this._createSingleClassroomServiceSubscription = this.createSingleClassroomService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated(data);
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.createSingleClassroomService.getItems();
      }
    });

    this._googleUserServiceSubscription = this.googleService.getSubjectToUpdateUsersObservable().subscribe((result) => {
      if (result) {
        this.userService.all();
      }
    });

    this.googleService.usersList();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated(data) {
    this.error = this.createSingleClassroomService.errors;
    this.model = this.createSingleClassroomService.parameters;
    this.parseMembersResult(data);
  }

  parseUsersResult(result, type) {

    let table = [];

    if (result && result.length > 0) {
      result.forEach(element => {

        let user = this.getInfoUser(element);

        this[type].push(user);

        table.push({
          "nome": [user.fullname, user.email],
          "_export_data": {
            name: user.fullname,
            email: user.email
          },
        });

      });
    }


    return table;
  }

  parseMembersResult(result) {

    this.docenti = [];
    this.studenti = [];

    this.parseUsersResult(result.filter(x => x.type == "google-teacher"), "docenti");
    this.parseUsersResult(result.filter(x => x.type == "google-student"), "studenti");

    this._loadingData.docenti = false;
    this._loadingData.studenti = false;
  }

  deleteEvent(rows) {
    this.createSingleClassroomService.deleteItemByGoogleId(rows);
  }

  onSubmit() {
    this.formLoading = true;

    this.operationInProgress = true;

    this.createSingleClassroomService.execOperation((error) => {
      this.resetOperation();
      this.router.navigate(['/classroom']);
    });
  }

  resetOperation() {
    this.model = null;
    this.createSingleClassroomService.reset();
  }

  unsubscibeAll() {
    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
    if (this._googleUserServiceSubscription)
      this._googleUserServiceSubscription.unsubscribe();
    if (this._createSingleClassroomServiceSubscription)
      this._createSingleClassroomServiceSubscription.unsubscribe();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  getInfoUser(userInfo) {

    let resUser = {
      userId: userInfo.user_id,
      googleId: userInfo.id,
      fullname: null,
      email: null,
      is_google_user: false,
      is_geniusuite_user: false,
      role: userInfo?.role,
      status: userInfo?.status,
      type: userInfo?.type
    };

    let googleUser = this.googleService.getUser(resUser.googleId);

    // Controllo più dettagliato prima che l'email dell'utente sia stata modificata
    let user = googleUser ? this.userService.getByGoogleId(googleUser.id) : this.userService.get(resUser.userId);

    if (user) {
      // Sincronizzato con Geniusuite
      resUser.userId = user.user.id;
      resUser.fullname = user.user.familyName + " " + user.user.givenName;
      resUser.email = user.user.email;
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = true;

      if (user.google_user) {
        resUser.googleId = user.google_user.id;

        if (user.google_user.name) {
          resUser.fullname = user.google_user.name?.familyName + " " + user.google_user.name?.givenName;
        }

        resUser.email = user.google_user.primaryEmail
        resUser.is_google_user = true;
      }
    } else if (googleUser) {
      // Non sincronizzato con Geniusuite ma non ha già i dati caricati
      resUser.fullname = "-";
      resUser.googleId = googleUser.id;

      if (googleUser.name) {
        resUser.fullname = googleUser.name?.familyName + " " + googleUser.name?.givenName;
      }

      resUser.email = googleUser.primaryEmail;
      resUser.is_google_user = true;
      resUser.is_geniusuite_user = false;
    } else {
      resUser.fullname = "Utente Esterno";
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = false;
    }

    return resUser;
  }

  setUserAsOwner(user) {
    this.model.ownerId = user.googleId;
    this.createSingleClassroomService.updateParameter(this.model);
    this.createSingleClassroomService.getItems();
  }

  removeUserFromClassroom(user) {
    let googleId = user.googleId;
    this.createSingleClassroomService.deleteItemByGoogleId(googleId);
  }

  changeStatusCollapse() {
    this.showAllSetting = !this.showAllSetting;
  }
}
