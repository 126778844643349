<app-card-loading
  *ngIf="loadingPlesso || loadingGoogleOrgUnits || loadingGoogleGroups || loadingPlessoService || loadingParameterService || loadingOrganigrammi">
</app-card-loading>

<app-card-loading-error
  *ngIf="!(loadingPlesso || loadingGoogleOrgUnits || loadingGoogleGroups || loadingPlessoService || loadingParameterService || loadingOrganigrammi) && error"
  [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card card-hover-shadow"
  *ngIf="!(loadingPlesso || loadingGoogleOrgUnits || loadingGoogleGroups || loadingPlessoService || loadingParameterService || loadingOrganigrammi) && !error">

  <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>

  <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" validate>
    <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">
      <div class="row">
        <!-- Left -->
        <div class="col">

          <!-- Titlte -->
          <div class="row form-group">
            <div class="col-12">
              <h3 *ngIf="!edit">Crea Plesso</h3>
              <h3 *ngIf="edit">Modifica Plesso</h3>
            </div>
          </div>

          <!-- Nome -->
          <div class="row form-group">
            <div class="col-12">
              <label class="input-label" for="name">Nome</label>
              <input id="name" name="name" #name="ngModel" class="form-control" [(ngModel)]="data.name"
                [ngClass]="(name.dirty || name.touched)? (name.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                type="text" required maxlength="255" [forbiddenString]="listPlessiFilteredName"
                [forbiddenGoogle]="listOrgUnitFilteredName" (input)="inputName()"
                (focusout)="checkGroupSetOnFocusOut()">

              <div class="invalid-feedback" *ngIf="name.invalid && (name.dirty || name.touched)">
                <span>Non valido</span>
                <span *ngIf="name.errors.forbiddenString; else checkOrgUnit">, Plesso già esistente</span>
                <ng-template #checkOrgUnit>
                  <span *ngIf="name.errors.forbiddenGoogle">, Unita Organizzativa già esistente</span>
                </ng-template>
              </div>

              <span class="valid-feedback" *ngIf="name.valid && (name.dirty || name.touched)">Valido</span>
            </div>
          </div>

          <!-- Abbreviazione -->
          <div class="row form-group">
            <div class="col-12">
              <label class="input-label" for="short_name">Abbreviazione</label>
              <input id="short_name" name="short_name" #short_name="ngModel" class="form-control"
                [(ngModel)]="data.short_name"
                [ngClass]="(short_name.dirty || short_name.touched)? (short_name.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                type="text" required maxlength="255" [forbiddenString]="listPlessiFilteredShortName"
                (input)="loadGroupsPreview(myForm)" (focusout)="checkGroupSetOnFocusOut()">

              <div class="invalid-feedback" *ngIf="short_name.invalid && (short_name.dirty || short_name.touched)">
                <span>Non valido</span>
                <span *ngIf="short_name.errors.forbiddenString">, Abbrevizione già esistente</span>
              </div>

              <span class="valid-feedback"
                *ngIf="short_name.valid && (short_name.dirty || short_name.touched)">Valido</span>
            </div>
          </div>

          <!-- Descrizione -->
          <div class="row form-group">
            <div class="col-12">
              <label class="input-label" for="description">Descrizione</label>
              <input id="description" name="description" #description="ngModel" [(ngModel)]="data.description"
                [ngClass]="(description.dirty || description.touched)? (description.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                type="text" class="form-control" maxlength="255" (input)="onInputDescription()">
              <span class="invalid-feedback"
                *ngIf="description.invalid && (description.dirty || description.touched)">Non
                valido</span>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-12">
              <button class="btn btn-outline-primary" type="button" (click)="changeStatusCollapse()"
                [attr.aria-expanded]="showAdvancedSettings" aria-controls="collapseAdvancedSettings">
                Modifica impostazioni standard
                <span class="ml-2" [ngSwitch]="showAdvancedSettings">
                  <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                  <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                </span>
              </button>
            </div>
          </div>

          <div class="row form-group" id="collapseAdvancedSettings" [collapse]="!showAdvancedSettings"
            [isAnimated]="true">
            <div class="col-12">
              <!-- Creare OrgUnit -->
              <div class="row form-group">
                <div class="col-12">
                  <h4>Unità organizzativa</h4>
                  <!-- Nuova org unit standard -->
                  <label class="toggle-switch d-flex" for="gestOrgUnit">
                    <input id="gestOrgUnit" [(ngModel)]="defaultCheckOrgUnit.crea" name="gestOrgUnit"
                      type="checkbox" class="toggle-switch-input" (change)="changeCreateOrgUnit()">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">Crea nuova unità</span>
                      <small class="d-block text-muted">Se si vuole associare un'unità organizzativa
                        esistente,
                        disattivare
                        l'opzione</small>
                    </span>
                  </label>
                </div>
              </div>

              <!-- Associare OrgUnit Esistente -->
              <div *ngIf="!defaultCheckOrgUnit.crea" class="row form-group">
                <div class="col-12">
                  <label class="input-label" for="orgUnitId">Unità organizzativa esistente</label>

                  <app-custom-dropdown-treeview [items]="orgUnitsTreeView" [(value)]="data.orgUnitId"
                    (selectedChange)="onSelectedOrgUnitId($event)" (valueChange)="onValueChangeOrgUnit($event)"
                    [configs]="configTreeView">
                  </app-custom-dropdown-treeview>

                  <input type="hidden" id="orgUnitId" name="orgUnitId" #orgUnitId="ngModel" [(ngModel)]="data.orgUnitId"
                    required>

                  <span class="invalid-feedback" *ngIf="data.orgUnitId">Non
                    valido</span>
                  <span class="valid-feedback" *ngIf="!data.orgUnitId">Valido</span>
                  <span class="text-muted font-size-sm">Scegli l'unità organizzativa da associare al
                    plesso
                  </span>
                </div>

                <!-- Modifica Nome OrgUnit in base ad Entità -->
                <div class="col-12 mt-3" *ngIf="edit">
                  <div class="custom-control custom-checkbox">
                    <input [ngModel]="false" id="changeNameOrgUnit" name="changeNameOrgUnit"
                      #changeNameOrgUnit="ngModel" type="checkbox" class="custom-control-input">
                    <label class="custom-control-label" for="changeNameOrgUnit">Modifica Nome Unità
                      organizzativa</label>
                    <div class="text-muted font-size-1">Cambia il nome dell'Unità organizzativa in base al nome del
                      Plesso
                    </div>
                  </div>
                </div>
              </div>

              <!-- Aggiungere alla Radice OrgUnit Nuova -->
              <div *ngIf="defaultCheckOrgUnit.crea">
                <div class="row form-group">
                  <div class="col-12">
                    <!-- Padre standard radice -->
                    <label class="toggle-switch d-flex" for="parentOrgUnitStandard">
                      <input [(ngModel)]="defaultCheckOrgUnit.radice" id="parentOrgUnitStandard"
                        name="parentOrgUnitStandard" type="checkbox" class="toggle-switch-input"
                        (change)="setDefaultOrganigrammaStudentiOrgUnitParent()">
                      <span class="toggle-switch-label">
                        <span class="toggle-switch-indicator"></span>
                      </span>
                      <span class="toggle-switch-content">
                        <span class="d-block">Aggiungi nella radice</span>
                        <small class="d-block text-muted">La nuova unità organizzativa verrà aggiunta
                          nella
                          radice, disattivando
                          questa opzioni è possibile specificare un'unità organizzativa esistente come
                          padre</small>
                      </span>
                    </label>
                  </div>
                </div>

                <div *ngIf="!defaultCheckOrgUnit.radice" class="row form-group">
                  <div class="col-12">
                    <!-- Padre esistente diverso da radice -->
                    <app-custom-dropdown-treeview [items]="orgUnitsTreeView" [(value)]="data.parentOrgUnitId"
                      (selectedChange)="onSelectedParentOrgUnitId($event)" [configs]="configTreeView">
                    </app-custom-dropdown-treeview>
                    <input type="hidden" id="parentOrgUnitId" name="parentOrgUnitId" #parentOrgUnitId="ngModel"
                      [(ngModel)]="data.parentOrgUnitId" required>
                  </div>
                </div>
              </div>

              <!-- Gestione Gruppi -->
              <div class="row form-group">
                <div class="col-12">
                  <h4>{{ 'STR.gruppi' | translate }}</h4>

                  <!-- Gestione Gruppi -->
                  <label class="toggle-switch d-flex" for="gestGroup">
                    <input id="gestGroup" [(ngModel)]="showGroupSettings" name="gestGroup" #gestGroup="ngModel"
                      type="checkbox" class="toggle-switch-input" (change)="loadGroupsPreview(myForm)">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">Gestisci Gruppi del Plesso</span>
                      <small class="d-block text-muted">Gestione Gruppi del Plesso</small>
                    </span>
                  </label>

                </div>
              </div>

              <!-- Impostazioni Avanzate Gruppi  -->
              <div class="row">
                <div class="mt-1 col-12" id="collapseGroupSettings" [collapse]="!showGroupSettings" [isAnimated]="true">

                  <div class="card card-hover-shadow">
                    <div class="card-body">
                      <!-- Studenti -->
                      <div class="row form-group">
                        <!-- Crea nuovo Gruppo -->
                        <div class="col-12">
                          <h4>Gruppo Studenti</h4>
                          <label class="toggle-switch d-flex" for="newGroupStandard_S">
                            <input [(ngModel)]="defaultCheckGroup.studenti" id="newGroupStandard_S"
                              name="newGroupStandard_S" #newGroupStandard_S="ngModel" type="checkbox"
                              class="toggle-switch-input">
                            <span class="toggle-switch-label">
                              <span class="toggle-switch-indicator"></span>
                            </span>
                            <span class="toggle-switch-content">
                              <span class="d-block">Nuovo gruppo Studenti</span>
                              <small class="d-block text-muted">Verrà creato un nuovo gruppo secondo i
                                parametri
                                scelti</small>
                            </span>
                          </label>
                        </div>
                      </div>

                      <div *ngIf="!defaultCheckGroup.studenti" class="row form-group">
                        <!-- Selziona Gruppo -->
                        <div class="col-12">
                          <label class="input-label" for="groupKey_studenti">Gruppo Studenti Esistente</label>

                          <input type="hidden" id="groupKey_studenti" name="groupKey_studenti"
                            #groupKey_studenti="ngModel" [(ngModel)]="data.groupKey_studenti"
                            [required]="showGroupSettings && !edit">
                          <app-select (valueChange)="selectGroupOnChange($event)" [id]="'select-groupKey_studenti'"
                            [showClear]="true" [filterBy]="'email'" [placeholder]="'Seleziona Gruppo'" [data]="groups"
                            [showValue]="'email'" [resultAttribute]="'id'" [(value)]="data.groupKey_studenti"
                            [optionAttributeShow]="'name, email'"></app-select>

                          <blockquote *ngIf="groupNotExist['groupKey_studenti']" class="blockquote mt-2">
                            <small>Verrà creato il gruppo</small>
                            <p>{{data.groupKey_studenti}}</p>
                          </blockquote>

                        </div>
                      </div>

                      <!-- Docenti -->
                      <div class="row form-group">
                        <!-- Crea nuovo Gruppo -->
                        <div class="col-12">
                          <h4>Gruppo Docenti</h4>
                          <label class="toggle-switch d-flex" for="newGroupStandard_D">
                            <input [(ngModel)]="defaultCheckGroup.docenti" id="newGroupStandard_D"
                              name="newGroupStandard_D" #newGroupStandard_D="ngModel" type="checkbox"
                              class="toggle-switch-input">
                            <span class="toggle-switch-label">
                              <span class="toggle-switch-indicator"></span>
                            </span>
                            <span class="toggle-switch-content">
                              <span class="d-block">Nuovo gruppo Docenti</span>
                              <small class="d-block text-muted">Verrà creato un nuovo gruppo secondo i
                                parametri
                                scelti</small>
                            </span>
                          </label>
                        </div>
                      </div>

                      <div *ngIf="!defaultCheckGroup.docenti" class="row form-group">
                        <!-- Selziona Gruppo -->
                        <div class="col-12">
                          <label class="input-label" for="groupKey_docenti">Gruppo Docenti Esistente</label>

                          <input type="hidden" id="groupKey_docenti" name="groupKey_docenti" #groupKey_docenti="ngModel"
                            [(ngModel)]="data.groupKey_docenti" [required]="showGroupSettings && !edit">
                          <app-select (valueChange)="selectGroupOnChange($event)" [id]="'select-groupKey_docenti'"
                            [showClear]="true" [filterBy]="'email'" [placeholder]="'Seleziona Gruppo'" [data]="groups"
                            [showValue]="'email'" [resultAttribute]="'id'" [(value)]="data.groupKey_docenti"
                            [optionAttributeShow]="'name, email'"></app-select>

                          <blockquote *ngIf="groupNotExist['groupKey_docenti']" class="blockquote mt-2">
                            <small>Verrà creato il gruppo</small>
                            <p>{{data.groupKey_docenti}}</p>
                          </blockquote>
                        </div>
                      </div>

                      <!-- Plesso -->
                      <div class="row form-group mb-0">
                        <!-- Crea nuovo Gruppo -->
                        <div class="col-12">
                          <h4>Gruppo misto Docenti e Studenti
                            <span class="badge badge-soft-warning" *ngIf="parameter && !parameter.gest_group_plesso">
                              Non abilitato
                            </span>
                          </h4>
                          <label class="toggle-switch d-flex" for="newGroupStandard_A" *ngIf="parameter && parameter.gest_group_plesso">
                            <input [(ngModel)]="defaultCheckGroup.all" id="newGroupStandard_A" name="newGroupStandard_A"
                              #newGroupStandard_A="ngModel" type="checkbox" class="toggle-switch-input"
                              [disabled]="parameter && !parameter.gest_group_plesso">
                            <span class="toggle-switch-label">
                              <span class="toggle-switch-indicator"></span>
                            </span>
                            <span class="toggle-switch-content">
                              <span class="d-block">Nuovo gruppo misto Docenti e Studenti</span>
                              <small class="d-block text-muted">Verrà creato un nuovo gruppo secondo i
                                parametri
                                scelti</small>
                            </span>
                          </label>
                        </div>
                      </div>

                      <div *ngIf="!defaultCheckGroup.all && (parameter && parameter.gest_group_plesso)"
                        class="row form-group mb-0">
                        <!-- Selziona Gruppo -->
                        <div class="col-12">
                          <label class="input-label" for="groupKey_all">Gruppo misto Docenti e Studenti Esistente</label>

                          <input type="hidden" id="groupKey_all" name="groupKey_all" #groupKey_all="ngModel"
                            [(ngModel)]="data.groupKey_all">
                          <app-select (valueChange)="selectGroupOnChange($event)" [id]="'select-groupKey_all'"
                            [showClear]="true" [filterBy]="'email'" [placeholder]="'Seleziona Gruppo'" [data]="groups"
                            [showValue]="'email'" [resultAttribute]="'id'" [(value)]="data.groupKey_all"
                            [optionAttributeShow]="'name, email'">
                          </app-select>

                          <span class="text-muted font-size-sm">
                            Lasciandolo vuoto non verrà associato nessun gruppo
                          </span>

                          <blockquote *ngIf="groupNotExist['groupKey_all']" class="blockquote mt-2">
                            <small>Verrà creato il gruppo</small>
                            <p>{{data.groupKey_all}}</p>
                          </blockquote>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Right Create -->
        <div class="col-5 p-3 m-3 bg-light rounded-lg" *ngIf="!edit">
          <!-- Titlte -->
          <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Sincronizzazione con Google Workspace</h4>

          <div class="row">
            <div class="col-12">
              <div *ngIf="defaultCheckOrgUnit.crea" class="alert alert-soft-info" role="alert">
                Il Plesso <b class="text-cap">{{data?.name}}</b> verrà sincronizzato con una <b class="text-cap">nuova
                  Unità Organizzativa</b> con il nome
                <u>{{data?.name || "- scrivi il nome del plesso -"}}</u>
              </div>
              <div *ngIf="!defaultCheckOrgUnit.crea" class="alert alert-soft-info" role="alert">
                Il Plesso <b class="text-cap">{{data?.name}}</b> verrà sincronizzato con<b class="text-cap">l'Unità
                  Organizzativa esistente</b> {{controlNameOrgUnit || "- seleziona unità organizzativa -"}}
              </div>
              <ng-container *ngIf="defaultCheckOrgUnit.crea && data?.name">
                <div *ngIf="!defaultCheckOrgUnit.radice" class="alert alert-soft-info" role="alert">
                  Verrà creato sotto l'Unità organizzativa: {{controlNameOrgUnitParent
                  || "- seleziona unità organizzativa padre-" }}
                </div>
                <div *ngIf="defaultCheckOrgUnit.radice" class="alert alert-soft-info" role="alert">
                  L'unità organizzativa <u>{{data?.name || "- scrivi il nome del plesso -"}}</u> verrà creata nella
                  radice {{controlNameOrgUnitParent || "/" }}
                </div>
              </ng-container>

              <ng-container *ngIf="showGroupSettings">
                <div *ngIf="defaultCheckGroup.studenti && !existErrorGroupPreview.studenti"
                  class="alert alert-soft-info" role="alert">
                  Il Gruppo Studenti del Plesso verrà sincronizzato con un <b class="text-cap">Nuovo Gruppo</b> con
                  email
                  <u>{{groupsPreview?.studenti || " - scrivi il nome del plesso -"}}</u>
                </div>

                <div *ngIf="defaultCheckGroup.studenti && existErrorGroupPreview.studenti"
                  class="alert alert-soft-danger" role="alert">
                  Attenzione! Il Gruppo Studenti del Plesso con email
                  <u>{{groupsPreview?.studenti || " - scrivi il nome del plesso -"}}</u> esiste già
                </div>

                <div *ngIf="!defaultCheckGroup.studenti" class="alert alert-soft-info" role="alert">
                  Il Gruppo Studenti del Plesso verrà sincronizzato con il Gruppo
                  <b class="text-cap">
                    {{previewEmailSelectedGroup(data.groupKey_studenti) || " - seleziona il gruppo -"}}
                  </b>
                </div>

                <div *ngIf="defaultCheckGroup.docenti && !existErrorGroupPreview.docenti" class="alert alert-soft-info"
                  role="alert">
                  Il Gruppo Docenti del Plesso verrà sincronizzato con un <b class="text-cap">Nuovo Gruppo</b> con email
                  <u>{{groupsPreview?.docenti || " - scrivi il nome del plesso -"}}</u>
                </div>

                <div *ngIf="defaultCheckGroup.docenti && existErrorGroupPreview.docenti" class="alert alert-soft-danger"
                  role="alert">
                  Attenzione! Il Gruppo Docenti del Plesso con email
                  <u>{{groupsPreview?.docenti || " - scrivi il nome del plesso -"}}</u> esiste già
                </div>

                <div *ngIf="!defaultCheckGroup.docenti" class="alert alert-soft-info" role="alert">
                  Il Gruppo Docenti del Plesso verrà sincronizzato con il Gruppo
                  <b class="text-cap">
                    {{previewEmailSelectedGroup(data.groupKey_docenti) || " - seleziona il gruppo -"}}
                  </b>
                </div>

                <ng-container *ngIf="parameter && parameter.gest_group_plesso">
                  <div *ngIf="defaultCheckGroup.all && !existErrorGroupPreview.all" class="alert alert-soft-info"
                    role="alert">
                    Il Gruppo misto Docenti e Studenti verrà sincronizzato con un <b class="text-cap">Nuovo Gruppo</b> con email
                    <u>{{groupsPreview?.all || " - scrivi il nome del plesso -"}}</u>
                  </div>

                  <div *ngIf="defaultCheckGroup.all && existErrorGroupPreview.all" class="alert alert-soft-danger"
                    role="alert">
                    Attenzione! Il Gruppo del Plesso con email
                    <u>{{groupsPreview?.all || " - scrivi il nome del plesso -"}}</u> esiste già
                  </div>

                  <div *ngIf="!defaultCheckGroup.all" class="alert alert-soft-info" role="alert">
                    Il Plesso verrà sincronizzato con il Gruppo
                    <b class="text-cap">
                      {{previewEmailSelectedGroup(data.groupKey_all) || " - seleziona il gruppo -"}}
                    </b>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Right Edit -->
        <div class="col-5 p-3 m-3 bg-light rounded-lg" *ngIf="edit">
          <!-- Titlte -->
          <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Sincronizzazione con Google Workspace</h4>

          <div class="row">
            <div class="col-12">
              <div class="alert alert-soft-info" role="alert" *ngIf="!dataIsChanged; else elseBlock">
                Non ci sono modifiche
              </div>
              <ng-template #elseBlock>
                <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.name">
                  Il Plesso verrà rinominato da {{plesso.name}} a <b>{{data.name}}</b>
                </div>
                <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.short_name">
                  L'abbreviazione del Plesso verrà rinominato da {{plesso.short_name}} a <b>{{data.short_name}}</b>
                </div>
                <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.description">
                  La descrizione del Plesso verrà rinominato da {{plesso.description}} a <b>{{data.description}}</b>
                </div>
                <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.org_unit">
                  <ng-container *ngIf="data.orgUnitId; else noOrgUnitAssociate">
                    L'Unita organizzativa del Plesso verrà cambiata in <b>{{controlNameOrgUnit}}</b>
                  </ng-container>
                  <ng-template #noOrgUnitAssociate>
                    Non verrà associata nessuna unita organizzativa al Plesso
                  </ng-template>
                </div>

                <ng-container *ngIf="dataChange.groupKey_all && (parameter && parameter.gest_group_plesso)">
                  <div class="alert alert-soft-info" role="alert" *ngIf="data.groupKey_all; else noGroupAssociate">
                    Il Gruppo del Plesso verrà cambiata in
                    <b>
                      {{previewEmailSelectedGroup(data.groupKey_all) || "GROUP"}}
                    </b>
                  </div>
                  <ng-template #noGroupAssociate>
                    <div class="alert alert-soft-info" role="alert">
                      Non verrà associato nessun Gruppo misto Docenti e Studenti
                    </div>
                  </ng-template>
                </ng-container>

                <ng-container *ngIf="dataChange.groupKey_docenti">
                  <div class="alert alert-soft-info" role="alert" *ngIf="data.groupKey_docenti; else noGroupAssociate">
                    Il Gruppo Docenti del Plesso verrà cambiata in
                    <b>
                      {{previewEmailSelectedGroup(data.groupKey_docenti) || "GROUP"}}
                    </b>
                  </div>
                  <ng-template #noGroupAssociate>
                    <div class="alert alert-soft-info" role="alert">
                      Non verrà associato nessun Gruppo Docenti
                    </div>
                  </ng-template>
                </ng-container>

                <ng-container *ngIf="dataChange.groupKey_studenti">
                  <div class="alert alert-soft-info" role="alert" *ngIf="data.groupKey_studenti; else noGroupAssociate">
                    Il Gruppo Studenti del Plesso verrà cambiata in
                    <b>
                      {{previewEmailSelectedGroup(data.groupKey_studenti) || "GROUP"}}
                    </b>
                  </div>
                  <ng-template #noGroupAssociate>
                    <div class="alert alert-soft-info" role="alert">
                      Non verrà associato nessun Gruppo Studenti
                    </div>
                  </ng-template>
                </ng-container>

              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer border-0 mx-3">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto py-1">
          <button *ngIf="id" [routerLink]="['/plesso/'+id]" type="button" class="btn btn-white">
            <i class="far fa-chevron-left mr-1"></i> Annulla
          </button>
        </div>
        <div class="col-6 py-1">
          <div class="row justify-content-end">
            <button type="submit" class="btn"
              [disabled]="!myForm.form.valid || (showGroupSettings && (existErrorGroupPreview.studenti || existErrorGroupPreview.docenti || existErrorGroupPreview.all))"
              [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
              <span *ngIf="id">Aggiorna</span>
              <span *ngIf="!id">Salva</span>
              <i class="far fa-chevron-right ml-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
