<div class="alert alert-soft-info" role="alert" *ngIf="data">
  <b class="text-cap">Anteprima dati</b>
  <span class="font-size-1" *ngIf="colIndex !== null && colIndex >= 0">
    <ul class="list-inline list-separator">
      <ng-container *ngFor="let row of data | slice:0:11; let index = index">
        <li *ngIf="row[colIndex] != null && ('' + row[colIndex]).length > 0" class="list-inline-item">
          <ng-container *ngIf="split">
            {{row[colIndex] | split:splitChar:splitPos}}
          </ng-container>
          <ng-container *ngIf="!split">
            {{row[colIndex]}}
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </span>
</div>
