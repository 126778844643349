import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { GoogleReportService, ApplicationReportType } from 'src/app/services/google/google-report.service';
import { UtilityService } from 'src/app/services/utility.service';
@Component({
  selector: 'app-google-meet-stat',
  templateUrl: './google-meet-stat.component.html',
  styleUrls: ['./google-meet-stat.component.scss']
})
export class GoogleMeetStatComponent implements OnInit, OnDestroy {
  @Input() hideButtonDetails: boolean = false;
  @Input() rangeDates: any;

  private _googleServiceReportSubscription: Subscription;
  lastCustomerUsage: any;
  nowCustomerUsage: any;
  loading: boolean;
  lastWeek: string;
  date: string;

  parameterToView = [
    null,
    {
      key: "total_call_minutes",
      value: 0,
      lastValue: 0,
      rate: 0
    }, {
      key: "average_meeting_minutes",
      value: 0,
      lastValue: 0,
      rate: 0
    }];
  growthRateToView = [0, 0, 0];

  timerUpdate: any;
  _timerUpdateSubscription: Subscription;
  timePassedSeconds: any;

  constructor(
    private googleReportService: GoogleReportService,
    private utility: UtilityService
  ) { }

  ngOnInit() {
    this.loading = false;
    let yesterday = null;
    let lastday = null;

    if (this.rangeDates && this.rangeDates.length == 2) {
      yesterday = this.rangeDates[0];
      lastday = this.rangeDates[1];
    } else {
      yesterday = new Date(new Date());
      yesterday.setDate(yesterday.getDate() - 5);

      lastday = new Date(yesterday);
      lastday.setDate(lastday.getDate() - 7);
    }

    var diffInTime = yesterday.getTime() - lastday.getTime();
    var diffInDay = Math.abs(diffInTime / (1000 * 3600 * 24));

    if (diffInDay <= 7) {
      this.parameterToView[0] = {
        key: "num_7day_active_users",
        value: 0,
        lastValue: 0,
        rate: 0
      };
    } else {
      this.parameterToView[0] = {
        key: "num_30day_active_users",
        value: 0,
        lastValue: 0,
        rate: 0
      };
    }

    this.date = yesterday.toISOString().split('T')[0];
    this.lastWeek = lastday.toISOString().split('T')[0];

    this.unsubscribeAll();

    this.googleReportService.customerUsageReportsGet(this.date, ApplicationReportType.meet, (result) => {
      this.nowCustomerUsage = result;

      this.googleReportService.customerUsageReportsGet(this.lastWeek, ApplicationReportType.meet, (result) => {
        this.lastCustomerUsage = result;

        this.parameterToView.forEach(element => {
          element.value = this.getParametersValue(element.key, "intValue") || 0;
          let lastWeekValue = this.getLastWeekParametersValue(element.key, "intValue") || 0;
          element.lastValue = lastWeekValue;
          element.rate = this.utility.getPercentageChange(lastWeekValue, element.value) || 0;
        });
      });
    });

    this.timerUpdate = new Date();
    this.timePassedSeconds = this.utility.convertMinutesIntoString(0);

    this._timerUpdateSubscription = timer(0, 1000)
      .pipe(
        map(() => new Date()),
        share()
      )
      .subscribe(time => {
        let dateSecond: number = Number((time.getTime() - this.timerUpdate.getTime()) / 60000);
        this.timePassedSeconds = this.utility.convertMinutesIntoString(dateSecond);
      });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._googleServiceReportSubscription)
      this._googleServiceReportSubscription.unsubscribe();

    if (this._timerUpdateSubscription)
      this._timerUpdateSubscription.unsubscribe();
  }

  getParametersValue(type, objValue) {
    let findElement = this.nowCustomerUsage?.parameters.find(x => x.name.includes(type));
    return findElement ? findElement[objValue] : 0;
  }

  getLastWeekParametersValue(type, objValue) {
    let findElement = this.lastCustomerUsage?.parameters.find(x => x.name.includes(type));
    return findElement ? findElement[objValue] : 0;
  }
}
