<div class="card">
  <!-- Header -->
  <div class="card-header">
    <h5 class="card-header-title"><i class="fad fa-university nav-icon yellow mr-1"></i>Ente: {{ ente.name }}</h5>
    <div class="d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="showInfo = !showInfo">
        <i class="fas fa-info mr-2"></i>{{ 'BTN.Informazioni' | translate }}
      </button>
      <button type="button" class="btn btn-outline-primary btn-sm mr-2"
        [routerLink]="['/ente/edit']">
        <i class="fas fa-edit mr-2"></i>{{ 'BTN.Modifica' | translate }}
      </button>
    </div>
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div *ngIf="showInfo" class="card-body">

    <div class="row">
      <div class="col">
        <small class="card-subtitle">Creato il</small>
        <p>{{ ente?.created_at | date:'medium' }}</p>
      </div>
    </div>

  </div>
  <!-- End Body -->

  <!-- Footer -->
  <div class="card-footer text-center">
    <!-- Stats -->
    <div class="row">

      <div class="col">
        <span class="h4">{{utentiCount}}</span>
        <span class="d-block font-size-sm">{{ 'STR.Utenti' | translate }}</span>
      </div>

      <!-- <div class="col column-divider">
        <span class="h4">{{gruppiCount}}</span>
        <span class="d-block font-size-sm">{{ 'STR.gruppi' | translate }}</span>
      </div> -->

      <div class="col column-divider">
        <span class="h4">{{domainsCount}}</span>
        <span class="d-block font-size-sm">{{ 'STR.Domini' | translate }}</span>
      </div>
    </div>
    <!-- End Stats -->
  </div>
  <!-- End Footer -->
</div>
