import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { RouteConfigLoadEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { MoveUsersService } from 'src/app/services/operations/move-users.service';
import { SyncdataService } from 'src/app/services/syncdata.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-move-users',
  templateUrl: './move-users.component.html',
  styleUrls: ['./move-users.component.scss']
})
export class MoveUsersComponent implements OnInit {

  error: any;
  _loadingData = {
    plessi: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  plessi: any = [];
  data: any = [];
  users: any;
  model: any;
  myForm: FormGroup = new FormGroup({});

  operationInProgress: boolean = false;
  errorNoGoogleAccount: boolean = false;
  roleNotSupported: boolean = false;

  private _moveUsersServiceSubscription: Subscription;
  private _plessoServiceSubscription: Subscription;
  private _userServiceSubscription: Subscription;


  constructor(
    private plessoService: PlessoService,
    private moveUsersService: MoveUsersService,
    private userService: UserService,
    private syncdataService: SyncdataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initForm();
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.users = this.moveUsersService.items;
    this.data = this.userService.getTable(this.users);
  }

  deleteEvent(rows) {
    this.moveUsersService.deleteItemsAtIndex(rows);
  }

  onSubmit() {

    if (this.myForm.valid) {

      this.operationInProgress = true;

      this.moveUsersService.execOperation(this.model.classe.id, (error) => {
        if (!error) {
          this.initForm();
        }
      });
    }
  }

  resetOperation() {
    this.moveUsersService.reset();
  }

  unsubscibeAll() {


    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._moveUsersServiceSubscription)
      this._moveUsersServiceSubscription.unsubscribe();

    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();



    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
  }

  initForm() {
    this.model = {
      plesso: null,
      classe: null
    }

    this.myForm = new FormGroup({
      plesso: new FormControl(this.model.plesso, [Validators.required]),
      classe: new FormControl(this.model.classe, [Validators.required])
    });
  }

  init() {

    this._moveUsersServiceSubscription = this.moveUsersService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.dataUpdated();

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this._loadingData.plessi = false;
        this.plessi = result;
      }
    })

    this._loadingData.plessi = true;
    this.plessoService.all();
  }

  plessoOnSelect($event) {
    this.myForm.get("plesso").setValue($event);
  }

  classiPlessoOnSelect($event) {
    this.myForm.get("classe").setValue($event);
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }
}
