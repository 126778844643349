<div class="gs-treeview" [ngClass]="{'show-menu' : isShow}">
  <div class="input-icon-right">
    <div class="icons" [ngClass]="{'valid-icon': touched}">
      <i class="pi pi-times mr-1" (click)="nodeUnselect()" *ngIf="_selectedNode != null"></i>
      <i class="pi" [ngClass]="[isShow ? 'pi-chevron-up' : 'pi-chevron-down']" (click)="isShow = (isShow) ? false : true"></i>
    </div>

    <input type="text" [(ngModel)]="_label" [placeholder]="'Seleziona'" readonly aria-haspopup="listbox" aria-haspopup="listbox"
      role="listbox" (click)="isShow = (isShow) ? false : true; touched = true;" class="form-control padding-input" 
      [ngClass]="touched ? (_label ? 'is-valid': 'is-invalid') : 'untouched'">
  </div>

  <div class="row col-md-12 gs-treeview-menu shadow bg-white rounded" [ngClass]="{'show-menu' : isShow}">
    <p-tree selectionMode="single" [(selection)]="_selectedNode"  (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect()" [value]="items" [filter]="true"
      filterMode="strict" scrollHeight="300px" [emptyMessage]="_notFoundText"></p-tree>
  </div>
</div>