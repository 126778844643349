import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { UserService } from 'src/app/services/general/user.service';
import { AddUsersToOrganigrammaService } from 'src/app/services/operations/add-users-to-organigramma.service';
import { SyncdataService } from 'src/app/services/syncdata.service';
import { OrderTypeList, UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-add-users-to-organigramma',
  templateUrl: './add-users-to-organigramma.component.html',
  styleUrls: ['./add-users-to-organigramma.component.scss']
})
export class AddUsersToOrganigrammaComponent implements OnInit {

  error: any;
  _loadingData = {
    organgirammi: false
  };

  // Organigramma Destination Update User
  organigrammiTreeView: any;
  disabledOrgUnitAdd: boolean = false;

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  organgirammi: any = [];
  data: any = [];
  users: any;
  model: any;
  myForm: FormGroup = new FormGroup({});

  operationInProgress: boolean = false;
  errorNoGoogleAccount: boolean = false;

  private _addUserToOrganigrammaServiceSubscription: Subscription;
  private _organigrammaServiceSubscription: Subscription;
  private _userServiceSubscription: Subscription;


  constructor(
    private utilityService: UtilityService,
    private organigrammaService: OrganigrammaService,
    private addUserToOrganigrammaService: AddUsersToOrganigrammaService,
    private userService: UserService,
    private syncdataService: SyncdataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initForm();
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.users = this.addUserToOrganigrammaService.items;
    this.data = this.userService.getTable(this.users);

    // se non ha l'account google associato segnalo che verranno ignorati
    this.data.forEach(row => {
      if (row.sync?.google === false) {
        this.errorNoGoogleAccount = true;
      }
    });
  }

  deleteEvent(rows) {
    this.addUserToOrganigrammaService.deleteItemsAtIndex(rows);
  }

  onSubmit() {

    if (this.myForm.valid) {

      this.operationInProgress = true;

      this.addUserToOrganigrammaService.execOperation(this.model.organigramma_id, { aggiungi_gerarchia: this.model.aggiungi_gerarchia, sposta_in_orgunit: this.model.sposta_in_orgunit, remove_from_all_organigrammi: this.model.remove_from_all_organigrammi }, (error) => {
        if (!error) {
          this.initForm();
        }
      });
    }
  }

  resetOperation() {
    this.addUserToOrganigrammaService.reset();
  }

  unsubscibeAll() {

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._addUserToOrganigrammaServiceSubscription)
      this._addUserToOrganigrammaServiceSubscription.unsubscribe();

    if (this._organigrammaServiceSubscription)
      this._organigrammaServiceSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
  }

  initForm() {
    this.disabledOrgUnitAdd = false;
    this.model = {
      organigramma_id: null,
      aggiungi_gerarchia: false,
      sposta_in_orgunit: false,
      remove_from_all_organigrammi: false
    }

    this.myForm = new FormGroup({
      organigramma_id: new FormControl(this.model.organigramma_id, [Validators.required]),
      aggiungi_gerarchia: new FormControl(this.model.aggiungi_gerarchia, [Validators.required]),
      sposta_in_orgunit: new FormControl(this.model.sposta_in_orgunit, [Validators.required]),
      remove_from_all_organigrammi: new FormControl(this.model.remove_from_all_organigrammi, [Validators.required])
    });
  }

  init() {

    this._addUserToOrganigrammaServiceSubscription = this.addUserToOrganigrammaService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.dataUpdated();

    this._organigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this._loadingData.organgirammi = false;
        this.organgirammi = result;
        this.organigrammiTreeView = this.utilityService.covertListToTreeViewItems(this.organgirammi, "id", "parent_id", "name", "id", null, OrderTypeList.Ascending, "name");
      }
    })

    this._loadingData.organgirammi = true;
    this.organigrammaService.all();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  onSelectedOrganigramma($event) {
    this.myForm.get("organigramma_id").setValue($event);
    
    let organgiramma = this.organgirammi.find(x => x.id == $event);

    if (organgiramma.google_org_unit && organgiramma.google_org_unit.orgUnitId){
      this.disabledOrgUnitAdd = false;
      this.myForm.get("sposta_in_orgunit").enable();
    }
    else {
      this.disabledOrgUnitAdd = true;
      this.model.sposta_in_orgunit = false;
      this.myForm.get("sposta_in_orgunit").disable();
    }
  }
}
