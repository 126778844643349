import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss']
})
export class SwitchButtonComponent implements OnInit {

  @Input() id;

  @Input() disabled: boolean = false;
  @Input() value: boolean;
  @Output() valueChange = new EventEmitter<boolean>();
  @Output() valueChanged = new EventEmitter<boolean>();

  @Input() labelTextChecked: string;
  @Input() labelTextNotChecked: string;

  constructor() { }

  ngOnInit() {
  }

  valueChangedEvent($event) {
    if (!this.disabled) {
      this.valueChange.emit(this.value);
      this.valueChanged.emit(this.value);
    }
  }

  checkboxClick() {
    if (!this.disabled)
      this.value = !this.value;

    this.valueChangedEvent(null);
  }
}
