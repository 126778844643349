import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';

export enum ParamType {
  Studente = 0,
  Docente = 1,
  Workers = 2,
  Gruppo = 3,
  PlessoGruppo = 4,
  PlessoDocente = 5,
  PlessoStudente = 6,
  PlessoWorkers = 7,
  ClasseGruppo = 8,
  ClasseDocente = 9,
  ClasseStudente = 10,
  OrganigrammaGruppo = 11,
}

const standardRegExp = /[^a-zA-Z0-9_\-@.]/g;
const accentoRegExp = /[^a-zA-Z0-9_\-\'@.]/g;

@Injectable({
  providedIn: 'root',
})

export class ParametriService {

  data: any;

  private _subjectToUpdate: Subject<any> = new Subject<any>();
  private _subjectToUpdateWhenParameterUpdated: Subject<any> = new Subject<any>();

  constructor(
    private backend: BackendService,
    private toast: ToastService,
    private utility: UtilityService
    ) { }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  public getSubjectToUpdateObservableWhenParameterUpdated(): Observable<any> {
    return this._subjectToUpdateWhenParameterUpdated.asObservable();
  }

  /**
   * Recupero il formato della email in base ai paramteri Gsuite e all'oggetto passato
   * @param type
   * @param objectToDecode
   */
  getEmailParamsFor(type: ParamType, objectToDecode: any) {
    let dataCloned = this.utility.cloneData(this.data);
    return this.getEmailParamsForPreview(type, objectToDecode, dataCloned);
  }

  getEmailParamsForUser(userType: string, objectToDecode: any) {

    let paramType: ParamType;

    switch (userType) {
      case "docente":
        paramType = ParamType.Docente
        break;
      case "studente":
        paramType = ParamType.Studente
        break;
      case "worker":
        paramType = ParamType.Workers
        break;
      default:
        return;
    }

    return this.getEmailParamsFor(paramType, objectToDecode);
  }

  /**
   * Recupero il formato della email di preview dei dati inseriti al momento
   * @param type
   * @param objectToDecode
   * @param dataForm Dati passati del model del form
   */
  getEmailParamsForPreview(type: ParamType, objectToDecode: any, dataForm: any) {

    let regEx = standardRegExp;

    let givenName = objectToDecode.givenName ? this.utility.sostituisciCaratteriAccentati(String(objectToDecode.givenName)) : '';
    let familyName = objectToDecode.familyName ? this.utility.sostituisciCaratteriAccentati(String(objectToDecode.familyName)) : '';
    let cf = objectToDecode.cf ? this.utility.sostituisciCaratteriAccentati(String(objectToDecode.cf)) : '';
    let group_name = objectToDecode.group_name ? this.utility.sostituisciCaratteriAccentati(String(objectToDecode.group_name)) : '';
    let name = objectToDecode.name ? this.utility.sostituisciCaratteriAccentati(String(objectToDecode.name)) : '';
    let short_name = objectToDecode.short_name ? this.utility.sostituisciCaratteriAccentati(String(objectToDecode.short_name)) : '';
    let name_plesso = objectToDecode.name_plesso ? this.utility.sostituisciCaratteriAccentati(String(objectToDecode.name_plesso)) : '';
    let short_name_plesso = objectToDecode.short_name_plesso ? this.utility.sostituisciCaratteriAccentati(String(objectToDecode.short_name_plesso)) : '';
    let name_classe = objectToDecode.name_classe ? this.utility.sostituisciCaratteriAccentati(String(objectToDecode.name_classe)) : '';
    let anno_classe = objectToDecode.anno_classe ? this.utility.sostituisciCaratteriAccentati(String(objectToDecode.anno_classe)) : '';
    let organigramma_name = objectToDecode.organigramma_name ? this.utility.sostituisciCaratteriAccentati(String(objectToDecode.organigramma_name)) : '';

    let resultEmail = "";
    switch (type) {
      case ParamType.Docente:
        resultEmail = `${dataForm.docente_email}@${dataForm.docente_domain}`.replace(
          /\$nome\$|\$cognome\$|\$cf\$|\$nome:1\$|\$cognome:1\$/gi,
          function (matched) {
            let obj = {
              $nome$: givenName,
              $cognome$: familyName,
              $cf$: cf,
              '$nome:1$': givenName.charAt(0),
              '$cognome:1$': familyName.charAt(0),
            }[matched];
            return obj ? obj.toLowerCase() : "";
          }
        );


        if (dataForm.docente_email_accento)
          regEx = accentoRegExp;

        break;

      case ParamType.Studente:
        resultEmail = `${dataForm.studente_email}@${dataForm.studente_domain}`.replace(
          /\$nome\$|\$cognome\$|\$cf\$|\$nome:1\$|\$cognome:1\$/gi,
          function (matched) {
            let obj = {
              $nome$: givenName,
              $cognome$: familyName,
              $cf$: cf,
              '$nome:1$': givenName.charAt(0),
              '$cognome:1$': familyName.charAt(0),
            }[matched];
            return obj ? obj.toLowerCase() : "";
          }
        );

        if (dataForm.studente_email_accento)
          regEx = accentoRegExp;

        break;

      case ParamType.Workers:
        resultEmail = `${dataForm.workers_email}@${dataForm.workers_domain}`.replace(
          /\$nome\$|\$cognome\$|\$cf\$|\$nome:1\$|\$cognome:1\$/gi,
          function (matched) {
            let obj = {
              $nome$: givenName,
              $cognome$: familyName,
              $cf$: cf,
              '$nome:1$': givenName.charAt(0),
              '$cognome:1$': familyName.charAt(0),
            }[matched];
            return obj ? obj.toLowerCase() : "";
          }
        );

        if (dataForm.workers_email_accento)
          regEx = accentoRegExp;

        break;

      case ParamType.Gruppo:
        resultEmail = `${dataForm.groups_email}@${dataForm.groups_domain}`.replace(
          /\$name_group\$/gi,
          function (matched) {
            return { $name_group$: group_name }[
              matched
            ].toLowerCase();
          }
        );

        if (dataForm.groups_email_accento)
          regEx = accentoRegExp;

        break;

      case ParamType.PlessoGruppo:
        resultEmail = `${dataForm.group_plesso_email}@${dataForm.group_plesso_domain}`.replace(
          /\$name_plesso\$|\$short_name_plesso\$/gi,
          function (matched) {
            return {
              $name_plesso$: name,
              $short_name_plesso$: short_name,
            }[matched].toLowerCase();
          }
        );

        if (dataForm.group_plesso_email_accento)
          regEx = accentoRegExp;

        break;

      case ParamType.PlessoDocente:
        resultEmail = `${dataForm.group_plesso_docente_email}@${dataForm.group_plesso_docente_domain}`.replace(
          /\$name_plesso\$|\$short_name_plesso\$/gi,
          function (matched) {
            return {
              $name_plesso$: name,
              $short_name_plesso$: short_name,
            }[matched].toLowerCase();
          }
        );

        if (dataForm.group_plesso_docente_email_accento)
          regEx = accentoRegExp;

        break;

      case ParamType.PlessoStudente:
        resultEmail = `${dataForm.group_plesso_studente_email}@${dataForm.group_plesso_studente_domain}`.replace(
          /\$name_plesso\$|\$short_name_plesso\$/gi,
          function (matched) {
            return {
              $name_plesso$: name,
              $short_name_plesso$: short_name,
            }[matched].toLowerCase();
          }
        );

        if (dataForm.group_plesso_studente_email_accento)
          regEx = accentoRegExp;

        break;

      case ParamType.PlessoWorkers:
        resultEmail = `${dataForm.group_plesso_workers_email}@${dataForm.group_plesso_workers_domain}`.replace(
          /\$name_plesso\$|\$short_name_plesso\$/gi,
          function (matched) {
            return {
              $name_plesso$: name,
              $short_name_plesso$: short_name,
            }[matched].toLowerCase();
          }
        );

        if (dataForm.group_plesso_workers_email_accento)
          regEx = accentoRegExp;


        break;

      case ParamType.ClasseGruppo:
        resultEmail = `${dataForm.group_classe_email}@${dataForm.group_classe_domain}`.replace(
          /\$name_plesso\$|\$short_name_plesso\$|\$name_classe\$|\$anno_classe\$/gi,
          function (matched) {
            return {
              $name_plesso$: name_plesso,
              $short_name_plesso$: short_name_plesso,
              $name_classe$: name_classe,
              $anno_classe$: anno_classe,
            }[matched].toLowerCase();
          }
        );

        if (dataForm.group_classe_email_accento)
          regEx = accentoRegExp;

        break;

      case ParamType.ClasseDocente:
        resultEmail = `${dataForm.group_classe_docente_email}@${dataForm.group_classe_docente_domain}`.replace(
          /\$name_plesso\$|\$short_name_plesso\$|\$name_classe\$|\$anno_classe\$/gi,
          function (matched) {
            return {
              $name_plesso$: name_plesso,
              $short_name_plesso$: short_name_plesso,
              $name_classe$: name_classe,
              $anno_classe$: anno_classe,
            }[matched].toLowerCase();
          }
        );

        if (dataForm.group_classe_docente_email_accento)
          regEx = accentoRegExp;

        break;

      case ParamType.ClasseStudente:
        resultEmail = `${dataForm.group_classe_studente_email}@${dataForm.group_classe_studente_domain}`.replace(
          /\$name_plesso\$|\$short_name_plesso\$|\$name_classe\$|\$anno_classe\$/gi,
          function (matched) {
            return {
              $name_plesso$: name_plesso,
              $short_name_plesso$: short_name_plesso,
              $name_classe$: name_classe,
              $anno_classe$: anno_classe,
            }[matched].toLowerCase();
          }
        );

        if (dataForm.group_classe_studente_email_accento)
          regEx = accentoRegExp;

        break;

      case ParamType.OrganigrammaGruppo:
        resultEmail = `${dataForm.group_organigramma_email}@${dataForm.group_organigramma_domain}`.replace(
          /\$name_organigramma\$/gi,
          function (matched) {
            return { $name_organigramma$: organigramma_name }[
              matched
            ].toLowerCase();
          }
        );

        if (dataForm.group_organigramma_email_accento)
          regEx = accentoRegExp;

        break;

      default:
        break;
    }

    resultEmail = resultEmail.replace(regEx, '');

    return resultEmail.replace(/\s/g, '');
  }

  all(success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    // if (this.cacheService.isCacheValid(CacheType.Parametri)) {
    //   this._subjectToUpdate.next(this.data);
    //   success_callback(this.data)
    //   return;
    // }

    this.backend.get("parameter").subscribe((response) => {
      this.data = response;
      // this.cacheService.updateCacheStatus(CacheType.Parametri, true);
      let dataCloned = this.utility.cloneData(this.data);
      this._subjectToUpdate.next(dataCloned);
      success_callback(dataCloned)
    }, (error) => {
      this.backend.showErrors(error);
      this._subjectToUpdate.next(null);
      error_callback(error);
    }, () => {

    })
  }

  /**
   * Aggiorno i parametri della gsuite
   * @param parameterModel
   * @param success_callback
   * @param error_callback
   */
  update(parameterModel, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    this.backend.post('parameter', parameterModel).subscribe(
      (response) => {
        this.data = response;
        let dataCloned = this.utility.cloneData(this.data);
        this._subjectToUpdate.next(dataCloned);
        this._subjectToUpdateWhenParameterUpdated.next(dataCloned);
        success_callback(dataCloned);
        this.toast.success('Parametri aggiornati');
      },
      (error) => {
        this.backend.showErrors(error);
        this._subjectToUpdate.next(null);
        error_callback(error);
      }
    );
  }

  /**
   * Controlla se esiste salvata una configurazione di default
   */
  checkParametriConfigurazione() {
    if (this.data) {
      for (let key of Object.keys(this.data)) {
        if (new RegExp(['email', 'domain', 'accento'].join("|")).test(key)) {
          //  se non trovo tutti i valori settati allora non vado avanti
          if (!this.data[key] && (typeof this.data[key] !== 'number')) {
            return false;
          }
        }
      }
      return true;
    }
    return false;
  }
}
