<app-card-loading *ngIf="loading">
</app-card-loading>

<app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">
  <div class="row">
    <div class="col-12">
      <app-card-operation-activity [filterActivity]="['operation.users.sync-users-from-google']">
      </app-card-operation-activity>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <div class="card card-hover-shadow">
        <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>
        <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
          <input type="hidden" id="plesso" formControlName="plesso">
          <input type="hidden" id="classe" formControlName="classe">
          <input type="hidden" id="organigramma_id" formControlName="organigramma_id">
          <input type="hidden" id="userType" formControlName="userType">

          <!-- Card -->
          <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">
            <h3 class="card-title">Importa utenti da Google Workspace in Geniusuite</h3>
            <p class="card-text">Questa operazione consente di importare gli utenti da Google Workspace in Geniusuite
              (Non viceversa)</p>

            <!-- Tipologia di utente -->
            <div class="form-group row">
              <div class="col-12">
                <label class="input-label">Tipologia di utente</label>
                <app-select (valueChange)="userTypeOnChange($event)" [id]="'select-userType'"
                  [placeholder]="'STR.Seleziona_Tipologia_Utente'" [data]="userTypeSelect" [showValue]="'value'"
                  [resultAttribute]="'key'" [(value)]="model.userType" [optionAttributeShow]="'value'"
                  [showClear]="false">
                </app-select>
              </div>
            </div>
            <div class="card card-hover-shadow" [hidden]="!model.userType">
              <div class="card-body">
                <div class="row justify-content-between align-items-center flex-grow-1">
                  <div class="col">
                    <tabset #tabset [justified]="true" type="pills">
                      <tab #tabPlesso="tab" [disabled]="model.userType == 'worker'" (selectTab)="selectTab('classe')">
                        <ng-template tabHeading>
                          <i class="fas fa-school nav-icon"></i> Plesso e classe
                        </ng-template>
                      </tab>
                      <tab #tabOrganigramma="tab" [active]="model.userType == 'worker'"
                        (selectTab)="selectTab('organigramma_id')">
                        <ng-template tabHeading>
                          <i class="fas fa-sitemap nav-icon"></i> Organigramma
                        </ng-template>
                      </tab>
                    </tabset>
                  </div>
                </div>

                <div class="pt-2" [hidden]="!tabPlesso.active">
                  <!-- Seleziona Plesso -->
                  <div class="row form-group">

                    <div class="col">
                      <label class="input-label" for="select-plessoSelected">{{"STR.Seleziona_Plesso" |
                        translate}}</label>
                      <app-select (valueChange)="plessoOnSelect($event)" [id]="'select-plessoSelected'"
                        [showClear]="true" [filterBy]="'name'" [placeholder]="'STR.Seleziona_Plesso'" [data]="plessi"
                        [showValue]="'name'" [(value)]="model.plesso" [optionAttributeShow]="'name'">
                      </app-select>
                    </div>
                    <div class="col"
                      *ngIf="model.plesso && (model.plesso?.classi || !model.plesso?.classi) && model.plesso?.classi.length > 0">
                      <label class="input-label" for="select-classi-plesso">{{"STR.Seleziona_Classe" |
                        translate}}</label>
                      <app-select (valueChange)="classiPlessoOnSelect($event)" [id]="'select-classi-plesso'"
                        [showClear]="true" [filterBy]="'name'" [placeholder]="'STR.Seleziona_Classe'" [multiple]="false"
                        [data]="model.plesso?.classi" [showValue]="'name'" [optionAttributeShow]="'name'"
                        [(value)]="model.classe">
                      </app-select>
                    </div>
                  </div>
                  <div class="alert alert-soft-warning media" role="alert"
                    *ngIf="(model.plesso?.classi || !model.plesso?.classi) && model.plesso?.classi.length == 0">
                    <i class="tio-warning mt-1 mr-1"></i>
                    <div class="media-body" role="alert">
                      Seleziona un plesso che abbia delle Classi Associate
                    </div>
                  </div>
                </div>
                <div class="pt-2" [hidden]="!tabOrganigramma.active">
                  <div class="row form-group">
                    <div class="col">
                      <label class="input-label">Organigramma</label>
                      <app-custom-dropdown-treeview [items]="organigrammiTreeView" [(value)]="model.organigramma_id"
                        (valueChange)="organigrammaOnSelect($event)">
                      </app-custom-dropdown-treeview>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="errorSynchronizedAccount" class="alert alert-warning mt-2 mb-0">
              <span class="text-cap d-inline">Attenzione</span> Sono stati selezionati degli utenti già sincronizzati,
              questi utenti non verranno aggiornati
            </div>
          </div>

          <div class="card-footer">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto py-1">
                <button class="btn btn-outline-danger" (click)="resetOperation()"
                  [disabled]="users.length <= 0 || operationInProgress">
                  <i class="far fa-backspace mr-2"></i>
                  <span>{{'STR.reset_operation' | translate}}</span>
                </button>
              </div>

              <div class="col-6 py-1">
                <div class="row justify-content-end">
                  <div class="col-auto">
                    <button type="submit" class="btn"
                      [disabled]="!myForm.valid || users.length <= 0 || operationInProgress"
                      [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                      Importa utenti in Geniusuite <i class="far fa-chevron-right ml-1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
      <!-- End Card -->
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-sm">
      <div class="card">
        <app-card-datatable-ng title="{{ 'STR.utenti_selezionati' | translate }}" [data]="data"
          [showDeleteButton]="true" labelDeleteButton="Rimuovi dall'operazione" iconDeleteButton="user-times"
          (deleteClickEvent)="deleteEvent($event)" [showExportButton]="true"></app-card-datatable-ng>
      </div>
    </div>
  </div>
</ng-container>
