<app-card-loading *ngIf="loading">
</app-card-loading>


<app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">
  <div class="row">
    <div class="col-12">
      <app-card-operation-activity [filterActivity]="['operation.groups.remove-members-from-groups']">
      </app-card-operation-activity>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <div class="card card-hover-shadow">
        <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>
        <form #myForm="ngForm">
          <!-- Card -->
          <div class="card card-hover-shadow">
            <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">

              <h3 class="card-title">Aggiungi Membri dentro la Classroom</h3>
              <p class="card-text">Questa operazione consente di aggiungere in maniera massiva tutti i membri
                selezionati dentro la Classroom selezionati</p>

              <!-- Seleziona Classroom -->
              <div class="row form-group">

                <div class="col">
                  <label class="input-label" for="select-classroomSelected">{{"STR.seleziona_classroom" |
                    translate}}</label>
                  <app-select (valueChange)="classroomOnSelect($event)" [id]="'select-classroom'" [showClear]="true"
                    [filterBy]="'name'" [placeholder]="'STR.seleziona_classroom'" [data]="classrooms"
                    [showValue]="'name'" [(value)]="model.classrooms" [optionAttributeShow]="'name'"
                    [showToggleAll]="true" [multiple]="true">
                  </app-select>
                </div>

              </div>

              <!-- Tipologia di utente -->
              <div class="form-group row">
                <div class="col-12">
                  <label class="input-label">Tipologia di utente</label>
                  <app-select (valueChange)="userTypeOnChange($event)" [id]="'select-userType'"
                    [placeholder]="'STR.Seleziona_Tipologia_Utente'" [data]="userTypeSelect" [showValue]="'value'"
                    [resultAttribute]="'key'" [(value)]="model.userType" [optionAttributeShow]="'value'"
                    [showClear]="false">
                  </app-select>
                </div>
              </div>

              <ng-container *ngIf="errors">
                <div *ngIf="errors.number" class="alert alert-warning mt-2 mb-0">
                  <span class="text-cap d-inline">Attenzione</span> Ci sono troppi utenti selezionati
                </div>

                <div *ngIf="errors.users && errors.users.length > 0" class="alert alert-warning mt-2 mb-0">
                  <span class="text-cap d-inline">Attenzione</span> Ci sono utenti già presenti dentro la Classroom  che verranno ignorati
                </div>
              </ng-container>

            </div>

            <div class="card-footer">
              <div class="row justify-content-between align-items-center">
                <div class="col-auto py-1">
                  <button class="btn btn-outline-danger" (click)="resetOperation()"
                    [disabled]="operationInProgress">
                    <i class="far fa-backspace mr-2"></i>
                    <span>{{'STR.reset_operation' | translate}}</span>
                  </button>
                </div>

                <div class="col-6 py-1">
                  <div class="row justify-content-end">
                    <div class="col-auto">
                      <input autocomplete="off" type="text" id="confirmation_delete" name="confirmation_delete"
                        #confirmation_delete="ngModel" [ngModel]="''" class="form-control" [pattern]="regEx" required>
                      <small class="d-block text-muted">Per confermare, digita
                        <span class="confirmation-phrase">Conferma</span></small>
                    </div>
                    <div class="col-auto">
                      <button class="btn" (click)="onSubmit()"
                        [disabled]="!model.userType || !model.classrooms || model.classrooms.length <= 0 || members.length <= 0 || operationInProgress"
                        [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                        Aggiungi Membri <i class="far fa-chevron-right ml-1"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Card -->
        </form>
      </div>
      <!-- End Card -->
    </div>
  </div>


  <div class="row">
    <div class="col-md-12 col-sm">
      <div class="card">
        <app-card-datatable-ng title="{{ 'STR.utenti_selezionati' | translate }}" [data]="data" [rowData]="members"
          [showDeleteButton]="true" labelDeleteButton="Rimuovi dall'operazione" iconDeleteButton="user-times"
          (deleteClickEvent)="deleteEvent($event)" [showExportButton]="true"></app-card-datatable-ng>
      </div>
    </div>
  </div>
</ng-container>
