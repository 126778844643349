import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { CacheService, CacheType } from '../cache.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class RequestChangePasswordService {

  data: any = [];
  link: any;
  last_update: Date;

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  constructor(private backend: BackendService, private toast: ToastService, private cacheService: CacheService, private utility: UtilityService) { }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  all(success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    if (this.cacheService.isCacheValid(CacheType.RequestChangePassword)) {
      let dataCloned = this.utility.cloneData(this.data);
      this._subjectToUpdate.next(dataCloned);
      success_callback(dataCloned);
      return;
    }

    this.backend.get(`req-change-password/all`).subscribe(
      (response) => {
        this.data = response;
        this.last_update = new Date();
        this.cacheService.updateCacheStatus(CacheType.RequestChangePassword, true);
        let dataCloned = this.utility.cloneData(this.data);
        this._subjectToUpdate.next(dataCloned);
        success_callback(dataCloned);
      }, (error) => {
        this.backend.showErrors(error);
        this._subjectToUpdate.next(null);
        error_callback(error);
      }
    );
  }

  forceUpdate(success_callback = (data: any) => { }, error_callback = (error: any) => { }) {
    this.cacheService.updateCacheStatus(CacheType.RequestChangePassword, false);
    this.all(success_callback, error_callback);
  }

  delete(items, toastNotification = false, success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    this.backend.post(`req-change-password/delete`, {items: items}).subscribe((result) => {

      if (toastNotification)
        this.toast.success('Richieste di cambio password eliminate con successo');

      this.cacheService.updateCacheStatus(CacheType.RequestChangePassword, false);
      this.all();

      success_callback(result);
    }, (error) => {
      this._subjectToUpdate.next(null);
      error_callback(error);
    })
  }

  linkForm(success_callback = (data: any) => { }, error_callback = (error: any) => { }) {

    this.backend.get(`req-change-password/link-form`).subscribe(
      (response) => {
        this.link = response;
        success_callback(this.link);
      }, (error) => {
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

}
