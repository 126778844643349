<app-card-loading *ngIf="loading">
</app-card-loading>


<app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">
  <div class="row">
    <div class="col-12">
      <app-card-operation-activity [filterActivity]="['operation.users.remove-users-from-organigramma']">
      </app-card-operation-activity>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <div class="card card-hover-shadow">
        <div class="card-body">

          <h3 class="card-title">Rimuovi Utenti dagli Organigrammi</h3>
          <p class="card-text">Questa operazione consente di rimovere in maniera massiva tutti gli utenti
            selezionati negli Organigramma</p>

          <div *ngIf="errorNoGoogleAccount" class="alert alert-warning mt-2 mb-0">
            <span class="text-cap d-inline">Attenzione</span> Sono stati selezionati degli utenti senza
            un
            account google associato, questi utenti non verranno spostati
          </div>

          <div class="card-footer">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto py-1">
                <button class="btn btn-outline-danger" (click)="resetOperation()"
                  [disabled]="users.length <= 0 || operationInProgress">
                  <i class="far fa-backspace mr-2"></i>
                  <span>{{'STR.reset_operation' | translate}}</span>
                </button>
              </div>

              <div class="col-6 py-1">
                <form #myForm="ngForm" class="row justify-content-end">
                  <div class="col-auto">
                    <input autocomplete="off" type="text" id="confirmation_delete" name="confirmation_delete"
                      #confirmation_delete="ngModel" [ngModel]="''" class="form-control" [pattern]="regEx" required>
                    <small class="d-block text-muted">Per confermare, digita
                      <span class="confirmation-phrase">Rimuovi</span></small>
                  </div>
                  <div class="col-auto">
                    <button type="submit" class="btn btn-danger"
                      [disabled]="!myForm.valid || users.length <= 0 || operationInProgress"
                      (click)="onSubmit()">
                      Rimovi Utenti dagli Organigrammi <i class="far fa-chevron-right ml-1"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-md-12 col-sm">
      <div class="card">
        <app-card-datatable-ng title="{{ 'STR.utenti_selezionati' | translate }}" [data]="data"
          [showDeleteButton]="true" labelDeleteButton="Rimuovi dall'operazione" iconDeleteButton="user-times"
          (deleteClickEvent)="deleteEvent($event)" [showExportButton]="true"></app-card-datatable-ng>
      </div>
    </div>
  </div>
</ng-container>
