import { Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { ParametriService, ParamType } from 'src/app/services/general/parametri.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { RelationInfo } from 'src/app/services/general/relation.service';
import { UtilityService, OrderTypeList } from 'src/app/services/utility.service';
import { PageService } from 'src/app/services/page.service';
import { SyncdataService } from 'src/app/services/syncdata.service';
import { ConfigService } from 'src/app/services/config.service';
import { MateriaService } from 'src/app/services/general/materia.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-organigramma-form',
  templateUrl: './organigramma-form.component.html',
  styleUrls: ['./organigramma-form.component.scss']
})
export class OrganigrammaFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: string;
  @Input() editMode: boolean;
  @Input() createMode: boolean;
  @Input() relation_info_standard: string;

  @ViewChild('myForm') myForm: NgForm;

  demo = false;
  organigramma: any;
  listOrganigrammi: any;
  listOrganigrammiFiltered: any;
  listOrgUnitFilteredName: any;
  organigrammiTreeView: any;
  data: any;

  dataChange = {
    name: false,
    description: false,
    org_unit: false,
    groupKey_all: false,
    relation_info: false
  };

  formErrors = {
    forbiddenString: null,
    forbiddenGoogle: null
  }

  get dataIsChanged(): boolean {
    return Object.values(this.dataChange).some(x => x == true);
  }

  error: any;
  materie: any;
  plessi: any;
  selectedTags: any;
  resultsTags: string[];

  // Visualizzazione Setting Advanced
  showAdvancedSettings: boolean;
  defaultCheckGroup: any;
  existErrorGroupPreview: any;
  defaultCheckOrgUnit: any;

  // Dati Google e Stuttura di View (TreeView e Preview)
  orgUnits: any;
  orgUnitParentSelected: any;
  orgUnitParentCheck: boolean;
  orgUnitsTreeView: any;
  controlNameOrgUnit: string;
  groups: any;
  groupsPreview: any;
  groupNotExist: any;
  userTypeSelect: any = [];
  tagsSelect: any = [];



  // Loading Pagina
  loadingOrganigramma: boolean;
  loadingOrganigrammaService: boolean;
  loadingParameterService: boolean;
  loadingMaterie: boolean;
  loadingPlessi: boolean;
  loadingGoogleOrgUnits: boolean;
  loadingGoogleGroups: boolean;
  formLoading: boolean;
  edit: boolean;
  parameterLoaded: boolean;

  // Configurazione TreeView
  configTreeView: any = {
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: true
  };

  private _materiaServiceSubscription: Subscription;
  private _googleServiceOrgUnitsSubscription: Subscription;
  private _googleServiceGroupsSubscription: Subscription;
  private _organigrammaServiceSubscription: Subscription;
  private _parameterServiceSubscription: Subscription;

  private _plessoServiceSubscription: Subscription;

  constructor(private zone: NgZone,
    private utilityService: UtilityService,
    private router: Router,
    private organigrammaService: OrganigrammaService,
    private parametriService: ParametriService,
    private googleService: GoogleDirectoryService,
    private pageService: PageService,
    private syncdataService: SyncdataService,
    private materiaService: MateriaService,
    private plessoService: PlessoService,
    private translate: TranslateService
  ) { }

  ngOnInit() {

    this.unsubscibeAll();

    this.data = {};
    this.defaultCheckGroup = {
      all: true
    };

    this.existErrorGroupPreview = {
      all: true
    };

    this.defaultCheckOrgUnit = {
      radice: true,
      crea: true
    };

    //  inizializzo le tipologie di utente disponibili
    this.userTypeSelect = ConfigService.userTypeSelect;

    // Init Tags
    this.tagsSelect = ConfigService.tagsType;
    this.resultsTags = this.tagsSelect;

    this.error = null;
    this.loadingOrganigramma = false;
    this.loadingOrganigrammaService = false;
    this.loadingParameterService = false;
    this.loadingGoogleOrgUnits = false;
    this.loadingGoogleGroups = false;
    this.formLoading = false;
    this.edit = false;
    this.parameterLoaded = false;
    this.showAdvancedSettings = false;
    this.orgUnitParentCheck = true;
    this.controlNameOrgUnit = "";
    this.groupsPreview = {
      user: null
    };
    this.groupNotExist = {
      groupKey_all: false
    };

    if (this.id && this.id.length > 0) {

      if (this.editMode) {
        this.edit = true;
        this.defaultCheckGroup = {
          all: false
        };

        this.existErrorGroupPreview = {
          all: false
        };

        this.defaultCheckOrgUnit = {
          radice: false,
          crea: false
        };

        this.getBackendData();
      }
    } else if (this.relation_info_standard) {
      this.data.relation_info = this.relation_info_standard;

      this.data.name = this.translate.instant('DEFAULT.organigramma.' + this.relation_info_standard + '.name');
      this.data.description = this.translate.instant('DEFAULT.organigramma.' + this.relation_info_standard + '.description');
    }

    this._googleServiceOrgUnitsSubscription = this.googleService.getSubjectToUpdateOrgUnitsObservable().subscribe((result) => {
      if (!result)
        this.googleError();
      else
        this.orgUnitsUpdated(result);
    });

    this._googleServiceGroupsSubscription = this.googleService.getSubjectToUpdateGroupsObservable().subscribe((result) => {
      if (!result)
        this.googleError();
      else
        this.groupsUpdated(result)
    });

    this.getGoogleData();

    this._organigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError("Organigramma Service");
      else
        this.organigrammaServiceUpdated(result);
    })

    this.loadingOrganigrammaService = true;
    this.organigrammaService.all();

    this._parameterServiceSubscription = this.parametriService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError("Parameter Service");
      else
        this.parametriServiceUpdated(result);
    })

    this.loadingParameterService = true;
    this.parametriService.all();


    this._materiaServiceSubscription = this.materiaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.materieServiceUpdate(result);
      }
    });

    this.loadingMaterie = true;
    this.materiaService.all();

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.plessoServiceUpdate(result);
    })

    this.loadingPlessi = true;
    this.plessoService.all();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
    else if (changes && changes.relation_info_standard && changes.relation_info_standard.currentValue && changes.relation_info_standard.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  orgUnitsUpdated(result) {
    this.orgUnits = result;
    this.orgUnitsTreeView = this.utilityService.covertListToTreeViewItems(this.orgUnits, "orgUnitPath", "parentOrgUnitPath", "name", "orgUnitId", "/", OrderTypeList.Ascending, "name");
    this.loadingGoogleOrgUnits = false;

    if (!(this.id && this.id.length > 0) && this.relation_info_standard && !this.data.orgUnitId && !this.edit) {
      // Associa di Default OrgUnit Docenti o Studenti se Esiste
      let orgUnitDefault = this.orgUnits.find(x => x.name.toLowerCase().trim() === this.data.name.toLowerCase().trim());
      this.data.orgUnitId = orgUnitDefault ? orgUnitDefault.orgUnitId : null;

      if (this.data.orgUnitId) {
        this.defaultCheckOrgUnit.crea = false;
      }
    }
  }

  materieServiceUpdate(result) {
    this.loadingMaterie = false;
    this.materie = result;
  }

  plessoServiceUpdate(result) {
    this.loadingPlessi = false;
    this.plessi = result;
  }

  organigrammaServiceUpdated(result) {
    this.listOrganigrammi = result;

    this.loadingOrganigrammaService = false;
    this.organigrammiTreeView = this.utilityService.convertListToTree(result, "id", "parent_id", OrderTypeList.Ascending, "name", true, "name", "id");

    if (!this.listOrganigrammi)
      this.listOrganigrammiFiltered = [''];
    else {
      if (!this.edit)
        this.listOrganigrammiFiltered = this.listOrganigrammi.map(x => x.name);
      else
        this.listOrganigrammiFiltered = this.listOrganigrammi.filter(x => x.name != this.data.name).map(x => x.name);
    }

    if (this.createMode) {
      if (this.id && this.id.length > 0) {
        this.onSelectedRelationInfo({id: Number(this.id)});
      }
    }
  }

  groupsUpdated(result) {
    this.groups = result;

    if (this.edit && this.groups && this.groups.length > 0 && this.data) {
      let groupKey_all = this.organigramma?.google_group?.find(x => x.relation_info == RelationInfo.RelationInfoOrganigramma)?.groupKey;
      this.data.groupKey_all = (groupKey_all && this.groups.find(x => x.id == groupKey_all)) ? this.groups.find(x => x.id == groupKey_all).id : "";
    }

    this.loadingGoogleGroups = false;

    this.loadGroupsPreview();
    this.checkGroupSetOnFocusOut();
  }

  parametriServiceUpdated(result) {
    this.loadingParameterService = false;
    this.parameterLoaded = true;
  }

  unsubscibeAll() {
    if (this._googleServiceOrgUnitsSubscription)
      this._googleServiceOrgUnitsSubscription.unsubscribe();

    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();

    if (this._organigrammaServiceSubscription)
      this._organigrammaServiceSubscription.unsubscribe();

    if (this._parameterServiceSubscription)
      this._parameterServiceSubscription.unsubscribe();



    if (this._materiaServiceSubscription)
      this._materiaServiceSubscription.unsubscribe();

    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();
  }

  getFilterOrgUnitName() {
    if (!this.orgUnits || this.data.orgUnitId)
      this.listOrgUnitFilteredName = [''];

    if (!this.data.orgUnitId) {
      let listFilted = this.orgUnits.filter(x => x.parentOrgUnitPath == "/");

      if (this.controlNameOrgUnit) {
        listFilted = listFilted.filter(x => x.name.toLowerCase().trim() !== this.controlNameOrgUnit.toLowerCase().trim());
      }

      if (!this.edit)
        this.listOrgUnitFilteredName = listFilted.map(x => x.name);
      else if (this.organigramma)
        this.listOrgUnitFilteredName = listFilted.filter(x => x.orgUnitId != this.organigramma.google_org_unit?.orgUnitId).map(x => x.name);
    }
  }

  changeStatusGroupGest() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  loadGroupsPreview() {
    this.existErrorGroupPreview.all = false;

    if (this.myForm && !this.myForm.controls.name.valid)
      return;

    let objParametro = {
      organigramma_name: this.myForm ? this.myForm.controls.name.value : this.data.name
    }

    this.groupsPreview = {
      user: this.parametriService.getEmailParamsFor(ParamType.OrganigrammaGruppo, objParametro)
    }

    if (this.edit)
      this.editDataChange();
  }

  checkGroupSetOnFocusOut() {
    // Faccio il check se esiste il gruppo e lo preseleziono se defaultCheckGroup.type è true
    if (this.defaultCheckGroup.all) {
      this.data.groupKey_all = (this.groups.find((x) => x.email == this.groupsPreview.user)?.id || '');

      if (this.data.groupKey_all) {
        // Errore, esiste già gruppo
        this.existErrorGroupPreview.all = false;
        this.defaultCheckGroup.all = false;
      }
    }
  }

  selectGroupOnChange($event) {
    if (this.edit)
      this.editDataChange();
  }

  onInputDescription() {
    if (this.edit)
      this.editDataChange();
  }

  getBackendData() {

    this.loadingOrganigramma = true;

    this.organigrammaService.get(this.id, (result) => {

      this.organigramma = result;

      this.data.name = this.organigramma.name;
      this.data.description = this.organigramma.description;

      if (this.organigramma?.google_org_unit) {
        this.data.orgUnitId = this.organigramma?.google_org_unit.orgUnitId;
      }

      // if (this.organigramma?.google_group && this.organigramma?.google_group.length > 0) {
      //   this.showAdvancedSettings = true;
      // }

      if (this.organigramma.google_group && this.organigramma.google_group.length > 0) {
        this.existErrorGroupPreview.all = false;
      }
      else {
        this.existErrorGroupPreview.all = true;
      }

      // Relazione Tipo
      if (this.organigramma.parent_id) {
        this.data.relation_info = this.organigramma.parent_id;
      } else if (this.organigramma.relation_info) {
        this.relation_info_standard = this.organigramma.relation_info;
        this.data.relation_info = this.organigramma.relation_info;
      }

      this.loadingOrganigramma = false;
    }, (error) => {
      this.loadingOrganigramma = true;
      this.organigrammaService.all();
      this.backendError("Get Organigramma Service");
      this.error = null;
    })
  }

  getGoogleData() {

    this.loadingGoogleOrgUnits = true;
    this.loadingGoogleGroups = true;

    this.googleService.orgunitsList();
    this.googleService.groupsList();
  }

  googleError() {
    this.error = "google";
    this.loadingGoogleOrgUnits = false;
    this.loadingGoogleGroups = false;
  }

  backendError(position) {
    this.error = "backend " + position;
    this.formLoading = false;
    this.loadingOrganigramma = false;
  }

  onSubmit(myForm: NgForm) {

    if (myForm.valid) {
      this.formLoading = true;

      // Copio i dati dell'oggetto senza riferimento
      let reqBody = JSON.parse(JSON.stringify(myForm.form.value));

      // Relation Save
      reqBody.relation_info = {};
      if (!this.relation_info_standard) {
        reqBody.relation_info.id = myForm.form.value.relation_info;
        reqBody.relation_info.type = null
      } else {
        reqBody.relation_info.id = null;
        reqBody.relation_info.type = this.relation_info_standard
      }

      // Gruppo User
      let existGroup_User = this.googleService.getGroupByGroupKey(myForm.form.value.groupKey_all);
      reqBody.groupKey_all = {};
      reqBody.groupKey_all.groupValue = existGroup_User ? existGroup_User.id : myForm.form.value.groupKey_all;
      reqBody.groupKey_all.create = existGroup_User ? false : true;
      reqBody.gestGroup = true;

      if (this.orgUnitParentSelected && this.orgUnitParentSelected.orgUnitId && !reqBody.parentOrgUnitId) {
        reqBody.parentOrgUnitId = this.orgUnitParentSelected.orgUnitId;
      }

      if (this.editMode) {
        // Update
        this.organigrammaService.update(this.id, reqBody, true, (result) => {
          this.formLoading = false;
          this.navigateAfterSubmit();
        }, (error) => {
          this.backendError("Update");
        })
      } else if (this.createMode) {
        // Create
        this.organigrammaService.create(reqBody, true, (result) => {
          this.formLoading = false;
          this.navigateAfterSubmit();
        }, (error) => {
          this.backendError("Create");
        })
      }
    }
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  onSelectedParentOrgUnitId(value) {
    this.data.parentOrgUnitId = (value?.orgUnitId || null);
    this.orgUnitParentSelected = this.orgUnits.find(x => x.orgUnitId == this.data.parentOrgUnitId);
  }

  onSelectedOrgUnitId(value) {
    this.data.orgUnitId = (value?.orgUnitId || null);
    this.controlNameOrgUnit = (value?.name || "");
    this.data.parentOrgUnitId = null;

    if (this.edit)
      this.editDataChange();
  }

  onValueChangeOrgUnit(value) {
    this.orgUnitErrorCheckForm();
  }

  onSelectedRelationInfo(value) {
    this.data.relation_info = (value?.id || null);
    this.orgUnitParentSelected = null;
    this.setSelectedParentOrgUnitId();
  }

  setSelectedParentOrgUnitId() {
    this.orgUnitParentCheck = this.orgUnitParentCheck ? false : true;

    if (this.orgUnitParentCheck) {
      this.data.parentOrgUnitId = null;

      if (this.data.relation_info) {
        this.orgUnitParentSelected = null;
        // Imposto come radice di default l'organigramma padre
        let organigrammaFilter = this.listOrganigrammi.find(x => x.id == this.data.relation_info);

        // Filtro in base se esiste orgUnit
        if (organigrammaFilter && organigrammaFilter.google_org_unit && organigrammaFilter.google_org_unit.orgUnitId) {
          this.orgUnitParentSelected = this.orgUnits.find(x => x.orgUnitId == organigrammaFilter.google_org_unit.orgUnitId);
        } else {
          this.data.parentOrgUnitId = null;
        }
      }
    }
  }

  noGroupExist(value, name) {
    this.groupNotExist[name] = value;
  }

  navigateAfterSubmit() {
    if (this.id && this.id.length > 0) {
      this.router.navigate([`/organigramma/${this.id}`]);
    }
    else {
      this.pageService.back();
    }
  }

  editDataChange() {
    // Controllo quali dati sono cambiati
    Object.keys(this.data).forEach(key => {
      let groupKey = null;
      switch (key) {
        case 'name':
        case 'description':
          this.dataChange[key] = this.data[key] !== this.organigramma[key] ? true : false;
          break;
        case 'orgUnitId':
          this.dataChange.org_unit = this.data.orgUnitId !== this.organigramma?.google_org_unit?.orgUnitId ? true : false;
          break;
        case 'groupKey_all':
          groupKey = this.organigramma.google_group.find(
            (x) => x.relation_info == RelationInfo.RelationInfoOrganigramma
          )?.groupKey;

          this.dataChange[key] = this.data[key] !== groupKey ? true : false;
          break;
        default:
          break;
      }
    });
  }

  onSelectMateria($event) {
  }

  onSelectPlesso($event) {
    this.data.classiSelected = [];
  }

  onSelectClassi($event) {
  }

  _mapAndJoinView(data, attribute) {
    if (data && data.length > 0)
      return data.map(x => x[attribute]).sort().join(',');
    return;
  }

  tagsFiltered($event) {
    this.resultsTags = this.tagsSelect.filter(function (x) {
      return x.name.toLowerCase().trim().includes($event.query.toLowerCase().trim());
    });
  }

  previewEmailSelectedGroup(groupKey) {
    return (this.groups.find((x) => x.id == groupKey)?.email || null);
  }

  orgUnitErrorCheckForm() {
    this.myForm.controls.name.setErrors(null);

    if (this.formErrors.forbiddenGoogle && !this.data.orgUnitId)
      this.myForm.controls.name.setErrors({
        'forbiddenGoogle': true
      });


    if (this.formErrors.forbiddenString)
      this.myForm.controls.name.setErrors({
        'forbiddenString': true
      });
  }

  inputName() {
    let errorGoogle = this.myForm.controls.name.getError("forbiddenGoogle");
    let errorString = this.myForm.controls.name.getError("forbiddenString");

    this.formErrors.forbiddenGoogle = errorGoogle ? errorGoogle : null;
    this.formErrors.forbiddenString = errorString ? errorString : null;
    this.getFilterOrgUnitName();
    this.loadGroupsPreview();
  }

  changeCreateOrgUnit() {
    if (this.defaultCheckOrgUnit.crea) {
      this.myForm.controls.name.setErrors(null);

      if (this.formErrors.forbiddenGoogle)
        this.myForm.controls.name.setErrors({
          'forbiddenGoogle': true
        });


      if (this.formErrors.forbiddenString)
        this.myForm.controls.name.setErrors({
          'forbiddenString': true
        });
    }
  }
}

