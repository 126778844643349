<ng-container *ngIf="showData">

  <div class="row">

    <div class="col-md-12 col-sm mb-1">
      <div class="mb-3">
        <app-plesso-profile-info-card [id]="id"></app-plesso-profile-info-card>
      </div>
    </div>

    <div class="col-md-12 col-sm">
      <app-plesso-profile-datatable-card [id]="id"></app-plesso-profile-datatable-card>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="showDelete && !showForm">
  <app-plesso-form-delete [id]="id"></app-plesso-form-delete>
</ng-container>

<ng-container *ngIf="showForm">
  <app-plesso-form [id]="id"></app-plesso-form>
</ng-container>

<ng-container *ngIf="showMappa">
  <app-plesso-profile-tree-card></app-plesso-profile-tree-card>
</ng-container>
