<app-card-loading *ngIf="loading">
</app-card-loading>

<app-card-loading-error *ngIf="!(loading) && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card" *ngIf="!(loading) && !error">
  <!-- Header -->
  <div class="card-header">
    <h5 class="card-header-title"><i class="fad fa-users nav-icon blue mr-1"></i> Gruppo: {{group.name}}</h5>
    <div class="d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="showInfo = !showInfo">
        <i class="fas fa-info mr-2"></i>{{ 'BTN.Informazioni' | translate }}
      </button>
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" [routerLink]="['edit']">
        <i class="fas fa-edit mr-2"></i>{{ 'BTN.Modifica' | translate }}
      </button>
    </div>
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div *ngIf="showInfo" class="card-body">

    <div class="row mb-3">
      <div class="col">
        <small class="card-subtitle">Nome</small>
        <p>{{ group?.name || '-'}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Descrizione</small>
        <p>{{ group?.description || 'Nessuna descrizione'}}</p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <small class="card-subtitle">Email di gruppo</small>
        <p>{{ group?.email || '-'}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Alias</small>
        <ng-container *ngFor="let email of group?.aliases">
          <p>{{ email }}</p>
        </ng-container>
        <ng-container *ngFor="let email of group?.nonEditableAliases">
          <p>{{ email }}</p>
        </ng-container>
      </div>
    </div>

  </div>
  <!-- End Body -->

  <!-- Footer -->
  <div class="card-footer text-center">
    <div class="row">

      <div class="col">
        <span class="h4">{{ group?.directMembersCount || '-'}}</span>
        <span class="d-block font-size-sm">Membri diretti</span>
      </div>
    </div>
  </div>
  <!-- End Footer -->
</div>
