<div *ngIf="!showForm">

  <div class="row">

    <div class="col-md-12 col-sm mb-1">
      <div class="mb-3">
        <app-ente-profile-info-card></app-ente-profile-info-card>
      </div>
    </div>

    <div class="col-md-12 col-sm">
      <app-ente-profile-datatable-card></app-ente-profile-datatable-card>
    </div>

  </div>
</div>


<div *ngIf="showForm">
  <app-ente-form></app-ente-form>
</div>
