<div class="card card-hover-shadow mb-3 border-0">
  <!-- Header -->
  <div class="card-header bg-warning">
    <h4 class="card-header-title text-white">
      <i class="fa-regular fa-sensor-triangle-exclamation mr-2"></i>Operazione non disponbile
    </h4>
  </div>

  <div class="card-body">
    <p>Questa operazione non è ancora disponibile per il tuo account.</p>
    <p>Se pensi invece che ci sia un problema con il tuo account <a href="https://geniusuite.com/assistenza-geniusuite/?utm_source=geniusuite&utm_medium=link&utm_campaign=assistenza_geniusuite" target="_blank" class="btn btn-xs btn-info ml-3">Contatta l'assistenza <i class="fa-solid fa-headphones ml-2"></i></a></p>
  </div>

</div>
