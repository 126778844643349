<app-card-loading *ngIf="loading">
</app-card-loading>


<app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">
  <div class="row">
    <div class="col-12">
      <app-card-operation-activity [filterActivity]="['operation.groups.update-members-from-groups']">
      </app-card-operation-activity>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <div class="card card-hover-shadow">
        <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
          <input type="hidden" id="memberRole" formControlName="memberRole">
          <!-- Card -->
          <div class="card card-hover-shadow">
            <div class="card-body">

              <h3 class="card-title">Modifica Ruolo dei Membri nei Gruppi</h3>
              <p class="card-text">Questa operazione consente di modificare in maniera massiva il ruolo nei relativi
                gruppi di tutti i
                membri
                selezionati</p>

              <div class="row form-group">
                <div class="col-12">
                  <label class="input-label" for="select-memberRole">{{"STR.Seleziona_Ruolo_Membro" |
                    translate}}</label>
                  <app-select (valueChange)="onSelectedMemberRole($event)" [id]="'select-memberRole'"
                    [placeholder]="'STR.Seleziona_Ruolo_Membro'" [data]="memberRole" [showValue]="'value'"
                    [resultAttribute]="'key'" [(value)]="model.memberRole" [optionAttributeShow]="'value'"
                    [showClear]="false">
                  </app-select>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row justify-content-between align-items-center">
                <div class="col-auto py-1">
                  <button class="btn btn-outline-danger" (click)="resetOperation()"
                    [disabled]="members.length <= 0 || operationInProgress">
                    <i class="far fa-backspace mr-2"></i>
                    <span>{{'STR.reset_operation' | translate}}</span>
                  </button>
                </div>
                <div class="col-6 py-1">
                  <div class="row justify-content-end">
                    <button type="submit" class="btn"
                      [disabled]="!myForm.valid || members.length <= 0 || operationInProgress"
                      [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                      <span>Modifica Membri</span>
                      <i class="far fa-chevron-right ml-1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <!-- End Card -->
        </form>
      </div>
      <!-- End Card -->
    </div>
  </div>


  <div class="row">
    <div class="col-md-12 col-sm">
      <div class="card">
        <app-card-datatable-ng title="{{ 'STR.utenti_selezionati' | translate }}" [data]="data"
          [showDeleteButton]="true" labelDeleteButton="Rimuovi dall'operazione" iconDeleteButton="user-times"
          (deleteClickEvent)="deleteEvent($event)" [showExportButton]="true"></app-card-datatable-ng>
      </div>
    </div>
  </div>
</ng-container>
