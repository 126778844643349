import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActivityService } from 'src/app/services/activity.service';
import { ConfigService } from 'src/app/services/config.service';
import { FileimportService } from 'src/app/services/fileimport.service';

type AOA = any[][];

@Component({
  selector: 'app-setup-wizard-config-col-users',
  templateUrl: './setup-wizard-config-col-users.component.html',
  styleUrls: ['./setup-wizard-config-col-users.component.scss']
})
export class SetupWizardConfigColUsersComponent implements OnInit, OnDestroy {

  uploadedFileCompleted: boolean;
  fileSize: string;
  fileName: string;
  fileLastModified: number;

  data: AOA;
  headerRow: any;
  headerRowIndex: number;

  givenNameColIndex: number;
  familyNameColIndex: number;
  emailColIndex: number;
  cfColIndex: number;
  paramOvewriteRecoveryEmail: boolean;
  paramOvewriteCf: boolean;
  passwordColIndex: number;
  emailRecoveryColIndex: number;

  userTypeSelect: any = [];
  userTypeSelected: any;
  userTypeSelectedValue: any;

  // Espressioni regolari per match automatico dei nomi delle colonne
  private nameColRegExp = /first.*name|(?<!cog)nome|givenName/i;
  private surnameColRegExp = /last.*name|cognome|familyName/i;
  private emailColRegExp = /mail/i;
  private cfColRegExp = /codice.*fisca|cf|c\.f/i;
  private passwordColRegExp = /password|pwd/i;
  private emailRecoveryColRegExp = /recove|recu|second/i;


  private _fileimportServiceSubscription: Subscription;

  // Nome, cognome, email, codice fiscale, password, cambia password primo login, ruolo, classe, plesso, organigramma?,
  // codice sidi, data nascita, anno corso, sezione, sesso, indirizzo

  constructor(private router: Router, private fileimportService: FileimportService, private activity: ActivityService) { }

  ngOnInit() {

    if (this.activity.isActivityInProgress("importazione")) {
      this.router.navigate(['/setup']);
      return;
    }

    if (this.fileimportService.hasImportProcessStarted()) {
      this.router.navigate(['/setup']);
      return;
    }

    this.userTypeSelect = [];
    this.userTypeSelect.push(ConfigService.getUserTypeObjectByKey("docente"));
    this.userTypeSelect.push(ConfigService.getUserTypeObjectByKey("studente"));

    if (this.fileimportService.hasFile()) {
      this.uploadedFileCompleted = true;
      this.parseFileInfo(this.fileimportService.getFile());
      this.onParseFileCompleted(this.fileimportService.getFilteredData());
    }
    else {
      this.router.navigate(['/setup/config']);
      return;
    }

    if (!this.fileimportService.hasHeaderRow()) {
      this.router.navigate(['/setup/config']);
      return;
    }

    this.headerRow = this.fileimportService.getHeaderRow();
    this.headerRowIndex = this.fileimportService.getHeaderIndex();

    this._fileimportServiceSubscription = this.fileimportService.onParseCompleted().subscribe((data: AOA) => {
      this.onParseFileCompleted(data);
    });

    this.findAutomaticMatch();

    if (this.fileimportService.givenNameColIndex)
      this.givenNameColIndex = this.fileimportService.givenNameColIndex;

    if (this.fileimportService.familyNameColIndex)
      this.familyNameColIndex = this.fileimportService.familyNameColIndex;

    if (this.fileimportService.emailColIndex)
      this.emailColIndex = this.fileimportService.emailColIndex;

    if (this.fileimportService.paramOvewriteRecoveryEmail)
      this.paramOvewriteRecoveryEmail = this.fileimportService.paramOvewriteRecoveryEmail;

    if (this.fileimportService.cfColIndex)
      this.cfColIndex = this.fileimportService.cfColIndex;

    if (this.fileimportService.paramOvewriteCf)
      this.paramOvewriteCf = this.fileimportService.paramOvewriteCf;

    if (this.fileimportService.passwordColIndex)
      this.passwordColIndex = this.fileimportService.passwordColIndex;

    if (this.fileimportService.userTypeSelected) {
      this.userTypeSelected = this.fileimportService.userTypeSelected;

      let userType: any = ConfigService.getUserTypeObjectByKey(this.userTypeSelected);
      this.userTypeSelectedValue = userType.value;
    }

    if (this.fileimportService.emailRecoveryColIndex)
      this.emailRecoveryColIndex = this.fileimportService.emailRecoveryColIndex;
  }

  ngOnDestroy(): void {

    if (this._fileimportServiceSubscription)
      this._fileimportServiceSubscription.unsubscribe();
  }

  parseFileInfo(file: File) {
    this.fileName = file.name;
    this.fileSize = Math.round(file.size / 1024) + " Kbyte";
    this.fileLastModified = file.lastModified;
  }

  findAutomaticMatch() {

    // Nome
    for (let i = 0; i < this.headerRow.length; i++) {
      if (this.nameColRegExp.test(this.headerRow[i])) {
        this.givenNameColIndex = i;
        break;
      }
    }

    // Cognome
    for (let i = 0; i < this.headerRow.length; i++) {
      if (this.surnameColRegExp.test(this.headerRow[i])) {
        this.familyNameColIndex = i;
        break;
      }
    }

    // Email
    for (let i = 0; i < this.headerRow.length; i++) {
      if (this.emailColRegExp.test(this.headerRow[i])) {
        this.emailColIndex = i;
        break;
      }
    }

    // Codice Fiscale
    for (let i = 0; i < this.headerRow.length; i++) {
      if (this.cfColRegExp.test(this.headerRow[i])) {
        this.cfColIndex = i;
        break;
      }
    }

    // Password
    // Non mi convince selezionarla automaticamente, perché non è obbligatoria come colonna e spesso nel file di esportazione di google c'è ma non ha dei valori sensati
    // for (let i = 0; i < this.headerRow.length; i++) {
    //   if (this.passwordColRegExp.test(this.headerRow[i])) {
    //     this.passwordColIndex = i;
    //     break;
    //   }
    // }

    // Email Recovery
    for (let i = 0; i < this.headerRow.length; i++) {
      if (this.emailRecoveryColRegExp.test(this.headerRow[i])) {
        // Solo se diversa da quella trovata per l'email la seleziono
        if (i != this.emailColIndex) {
          this.emailRecoveryColIndex = i;
          break;
        }
      }
    }
  }

  onSubmit(form: NgForm) {
  }

  onParseFileCompleted(data: AOA) {

    this.data = data;

    if (!this.data && this.fileimportService.hasFile()) {
      this.fileimportService.parseFile();
    }
  }

  onSelectparamOvewriteCf() {

  }

  onSelectparamOvewriteRecoveryEmail() {

  }

  onSelectEmailColIndex() {

    if (!this.emailColIndex) {
      this.paramOvewriteCf = null;
      this.paramOvewriteRecoveryEmail = null;
    }
  }

  removedFile() {
    this.fileimportService.deleteFile();
    this.router.navigate(['/setup/config']);
  }

  next() {

    this.fileimportService.saveUserColsPreference(
      this.givenNameColIndex,
      this.familyNameColIndex,
      this.emailColIndex,
      this.paramOvewriteRecoveryEmail,
      this.cfColIndex,
      this.paramOvewriteCf,
      this.passwordColIndex,
      this.userTypeSelected,
      this.emailRecoveryColIndex
    );

    this.router.navigate(['/setup/preview/users']);
  }

  back() {

    if (!this.fileimportService.selectedPlesso) {
      this.router.navigate(['/setup/config/struttura']);
      return;
    }

    this.router.navigate(['/setup/preview/struttura']);
  }

}
