import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-workspace-autorizzazioni',
  templateUrl: './workspace-autorizzazioni.component.html',
  styleUrls: ['./workspace-autorizzazioni.component.scss']
})
export class WorkspaceAutorizzazioniComponent {

  constructor(
    private router: Router,
    private accountService: AccountService
  ) {}

  ngOnInit() {

  }

  retryClicked() {
    this.router.navigate(['/']);
  }

  logout() {

    this.accountService.logout(() => {
      this.router.navigate(['/login']);
    });
  }

}
