import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Observable, Subject } from 'rxjs';
import { SyncdataService } from './syncdata.service';
import { ToastService } from './toast.service';
import { TranslateService } from '@ngx-translate/core';
import { PusherService } from './pusher.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();
  private _subjectToUpdateForEachJob: Subject<any> = new Subject<any>();

  public showSidebar: boolean;

  private data: any;

  private failedJobs: any;

  constructor(
    private backend: BackendService,
    private pusher: PusherService,
    private syncdataService: SyncdataService,
    private toast: ToastService,
    private translate: TranslateService,
    private utility: UtilityService
  ) {

    this.showSidebar = false;
  }

  public init() {

    this.pusher.listenToActivityChannel("BatchUpdated", "BatchCreated", (e) => {
      this.findAndUpdateBatch(e.activity);
      this.handleBatchStatus(e.activity);
      this.updateActivityBatchStatus(e.activity);
    });

    this.updateActivityData();
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  public getSubjectToUpdateObservableForEachJob(): Observable<any> {
    return this._subjectToUpdateForEachJob.asObservable();
  }

  public updateActivityData() {

    this.backend.get("activity/all").subscribe((result) => {
      this.data = this.parseActivityData(result);
      this._subjectToUpdate.next(this.data);
    }, (error) => {
      console.error(error);
    });
  }

  cancelActivity(activity) {

    activity.cancelled_at = (new Date()).toDateString();

    this.backend.delete(`activity/${activity.batch_id}/delete`, activity).subscribe((result) => {
      this.toast.success('Attività cancellata');
      this.updateActivityData();
    });
  }

  private handleBatchStatus(batch) {

    this.syncdataService.handleBatchStatus(batch);

    // se completo
    if (batch.finished_at) {

      if (batch.failed_jobs <= 0) {
        this.translate.get(['ACTIVITY.' + batch.job_batch_name + '.description']).subscribe((res) => {
          this.translate.get(["ACTIVITY.notifications.success.msg", "ACTIVITY.notifications.success.title"], { job_batch_name: res['ACTIVITY.' + batch.job_batch_name + '.description'], user_name: batch.user_name }).subscribe((res) => {
            this.toast.success(res["ACTIVITY.notifications.success.title"], res["ACTIVITY.notifications.success.msg"]);
          });
        })
      }
      else {
        this.translate.get(['ACTIVITY.' + batch.job_batch_name + '.description']).subscribe((res) => {
          this.translate.get(["ACTIVITY.notifications.error.msg", "ACTIVITY.notifications.error.title"], { job_batch_name: res['ACTIVITY.' + batch.job_batch_name + '.description'], user_name: batch.user_name }).subscribe((res) => {
            this.toast.warn(res["ACTIVITY.notifications.error.title"], res["ACTIVITY.notifications.error.msg"]);
          });
        })
      }
    }
    else if (batch.job_completed <= 0 && batch.failed_jobs <= 0) {
      this.translate.get(['ACTIVITY.' + batch.job_batch_name + '.description']).subscribe((res) => {
        this.translate.get(["ACTIVITY.notifications.start.msg", "ACTIVITY.notifications.start.title"], { job_batch_name: res['ACTIVITY.' + batch.job_batch_name + '.description'], user_name: batch.user_name }).subscribe((res) => {
          this.toast.info(res["ACTIVITY.notifications.start.title"], res["ACTIVITY.notifications.start.msg"]);
        });
      })
    }
  }

  private updateActivityBatchStatus(batch) {

    // se completo un batch aggiorno tutta la lista
    if (batch.finished_at) {
      this.updateActivityData();
    }
  }

  public findBatch(batch_id) {

    if (!this.data)
      return;

    let batch = this.data.find((el) => el.batch_id == batch_id);

    return this.utility.cloneData(batch);
  }

  public findFailedJobs(batch, callback) {

    if (!this.failedJobs)
      this.failedJobs = [];

    if (!this.failedJobs[batch.batch_id]) {
      this.failedJobs[batch.batch_id] = [];

      // recupero i jobs falliti
      this.backend.get("activity/" + batch.batch_id + "/failed").subscribe((result) => {
        this.failedJobs[batch.batch_id] = result;
        callback(this.failedJobs[batch.batch_id]);
      }, (error) => {
        console.error(error);
      });
    }
    else {
      callback(this.failedJobs[batch.batch_id]);
    }

  }

  private findAndUpdateBatch(batch) {

    let found = false;

    batch.last_update = Date.now();

    for (let index in this.data) {
      let element = this.data[index];

      if (element.batch_id == batch.batch_id) {
        let elementUpdated = Object.assign({}, this.data[index], batch);
        this.data[index] = this.parseSingleActivityData(elementUpdated);
        found = true;
        break;
      }
    }

    if (!found) {
      let elementNew = this.parseSingleActivityData(batch);
      this.data.unshift(elementNew);
    }

    this._subjectToUpdateForEachJob.next(this.data);
  }

  private parseActivityData(data) {

    data.forEach(element => {
      element = this.parseSingleActivityData(element);
    });

    return data;
  }

  private parseSingleActivityData(element) {

    // calcolo lo status
    element.status = "progress";

    if (element.finished_at != null && element.cancelled_at == null) {
      element.status = "completed";
    }
    else if (element.finished_at != null && element.cancelled_at != null) {
      element.status = "cancelled";
    }

    // calcolo la percentuale e gli indicatori
    let perc_completed = (element.total_jobs - element.pending_jobs - element.failed_jobs) / element.total_jobs;

    if (perc_completed < 0)
      perc_completed = 0;

    // pezza per risolvere il problema della numerazione che ancora si presenta
    if (perc_completed >= 1) {
      perc_completed = 1;
      element.status = "completed";
    }

    if (element.failed_jobs > 0) {
      element.status = "cancelled";
    }

    let perc_failed = element.failed_jobs / element.total_jobs;

    element.perc_completed = (Math.floor(perc_completed * 100)).toFixed(0);
    element.perc_failed = (Math.floor(perc_failed * 100)).toFixed(0);
    element.perc_overall = (Math.floor((perc_completed + perc_failed) * 100)).toFixed(0);
    element.job_completed = element.total_jobs - element.pending_jobs;

    if (isNaN(element.finished_at)) {
      if (element.finished_at.indexOf("T") !== -1) element.finished_at_parsed = new Date(element.finished_at);
    }
    else
      element.finished_at_parsed = new Date(element.finished_at * 1000);

    if (isNaN(element.cancelled_at)) {
      if (element.cancelled_at.indexOf("T") !== -1) element.cancelled_at_parsed = new Date(element.cancelled_at);
    }
    else
      element.cancelled_at_parsed = new Date(element.cancelled_at * 1000);

    if (isNaN(element.created_at)) {
      if (element.created_at.indexOf("T") !== -1) element.created_at_parsed = new Date(element.created_at);
    }
    else
      element.created_at_parsed = new Date(element.created_at * 1000);

    return element;
  }

  isActivityInProgress(job_batch_name) {

    let found = false;

    this.data.forEach(activity => {
      if (activity.job_batch_name == job_batch_name && activity.status == "progress")
        found = true;
    });

    return found;
  }

  isActivityInStatus(job_batch_name, status) {

    let found = false;

    this.data.forEach(activity => {
      if (activity.job_batch_name == job_batch_name && activity.status == status)
        found = true;
    });

    return found;
  }

  isActivityExist(job_batch_name) {
    let res = false;

    this.data.forEach(activity => {
      if (activity.job_batch_name == job_batch_name)
        res = true;
    });

    return res;
  }

  isActivityExistByRangeDate(job_batch_name, date_start: any, date_end: any) {
    let res = false;

    this.data.forEach(activity => {
      if (activity.job_batch_name == job_batch_name && activity.created_at_parsed.getTime() >= date_start.getTime() && activity.created_at_parsed.getTime() <= date_end.getTime())
        res = true;
    });

    return res;
  }
}
