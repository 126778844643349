import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { MateriaService } from 'src/app/services/general/materia.service';

@Component({
  selector: 'app-materia-profile-info-card',
  templateUrl: './materia-profile-info-card.component.html',
  styleUrls: ['./materia-profile-info-card.component.scss']
})
export class MateriaProfileInfoCardComponent implements OnInit {
  regEx = ConfigService.regExDelete;
  @Input() id: any;
  materia: any;
  error: any;
  showInfo = false;

  edit = false;
  delete = false;
  nameMateria: string;

  studentiCount: number = 0;
  docentiCount: number = 0;
  listMaterie: [] = [];

  editForm: FormGroup = new FormGroup({});
  deleteForm: FormGroup = new FormGroup({});
  blockedLoaderHeader: boolean = false;

  // Loading Pagina
  loadingMateria: boolean;
  private _materiaServiceSubscription: Subscription;

  constructor(private materiaService: MateriaService, private router: Router) { }

  ngOnInit(): void {
    this.editForm = new FormGroup({
      name: new FormControl(this.nameMateria, [Validators.required])
    });

    this.deleteForm = new FormGroup({
      confirmation_delete: new FormControl('', [Validators.required])
    });
    
    this.unsubscibeAll();
    this.nameMateria = '';
    this.materia = {};
    this.error = null;
    this.loadingMateria = false;
    this.showInfo = false;

    this._materiaServiceSubscription = this.materiaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError();
      else {
        this.listMaterie = result;
        this.materiaServiceUpdated();
      }
    });

    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  unsubscibeAll() {
    if (this._materiaServiceSubscription)
      this._materiaServiceSubscription.unsubscribe();
  }

  init() {
    this.materiaService.all();
    this.materiaServiceUpdated();
  }

  materiaServiceUpdated() {
    this.loadingMateria = true;
    this.getBackendData();
  }

  getBackendData() {
    if (this.id && this.id > 0) {
      this.materiaService.getWithUsers(this.id, (result) => {
        this.materia = result;
        this.nameMateria = this.materia.name;

        if (this.nameMateria)
          this.editForm.get("name").setValue(this.nameMateria);

        this.studentiCount = this.materia.studenti.length;
        this.docentiCount = this.materia.docenti.length;
        this.loadingMateria = false;
      }, (error) => {
        this.loadingMateria = true;
        this.materiaService.all();
      });
    } else {
      this.loadingMateria = false;
    }
  }

  backendError() {
    this.error = "backend";
    this.loadingMateria = false;
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  saveEdit() {
    if (this.editForm.valid) {
      this.loadingMateria = true;

      let reqBody = this.editForm.value;
      this.materiaService.update(this.id, reqBody, true, (result) => {

        // Update
        this.materia.name = this.editForm.get("name").value;
        this.onClickEdit();

        if (this.id && this.id.length > 0) {
          this.router.navigate([`/materia/${this.id}`]);
        }
        this.loadingMateria = false;
      }, (error) => {
        this.backendError();
      });
    }
  }

  saveDelete() {
    if (this.deleteForm.valid) {
      this.loadingMateria = true;

      this.materiaService.delete(this.id, true, (result) => {
        this.router.navigate([`/materia`]);
      }, (error) => {
        this.backendError();
      });
    }
  }

  onClickEdit() {
    this.edit = this.edit ? false : true;
    this.nameMateria = this.materia.name;
  }

  onClickDelete() {
    this.delete = this.delete ? false : true;
    this.nameMateria = this.materia.name;
  }

  getFilterData(campo: string) {
    if (this.edit) {
      let filtered = this.materiaService.data.filter(x => x[campo].toLowerCase().trim() !== this.materia[campo].toLowerCase().trim());
      return filtered.map(x => x[campo]);
    }
  }
}
