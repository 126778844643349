import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { BackendService } from 'src/app/services/backend.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { UtilityService } from 'src/app/services/utility.service';
import { EnteTypes } from '../../ente/ente-form/ente-form.component';

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss']
})
export class GroupFormComponent implements OnInit {
  @Input() id: any;
  groups: any[];
  domains: any = [];
  data: any;
  error = null;

  checkEmailExist = {
    exist_email: false,
    exist_alias_email: false
  }
  showAdvancedSettings: boolean = false;
  formLoading: boolean;

  _loadingData = {
    group: false,
    domain: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  private _googleServiceGroupsSubscription: Subscription;
  private _googleServiceDomainsSubscription: Subscription;

  @ViewChild('myForm') myForm: NgForm;

  constructor(
    private googleService: GoogleDirectoryService,
    private router: Router, private backend: BackendService) { }

  ngOnInit(): void {
    this.unsubscibeAll();

    if (!this.id)
      this.router.navigate(['/**']);

    this._googleServiceGroupsSubscription = this.googleService.getSubjectToUpdateGroupsObservable().subscribe((result) => {
      if (result) {
        this.groups = result;
        this.getGroupData();
      }
    }, (error) => {
      this.googleError();
    });

    this._googleServiceGroupsSubscription = this.googleService.getSubjectToUpdateDomainsObservable().subscribe(
      (result) => {
        this.domains = result;
        this._loadingData.group = true;
        this._loadingData.domain = false;
        this.googleService.groupsList();
      },
      (error) => {
        this.googleError();
      });

    this.init();
  }

  init() {
    this._loadingData.domain = true;
    this.googleService.domainsList();
  }

  onChangeEmail() {
    let email = this.data.email_first?.toLowerCase().replace(/\s/g, '');
    let domain = this.data.email_domain?.toLowerCase().replace(/\s/g, '');

    this.data.email_first = email;
    this.data.email_domain = domain;
    this.data.email = this.data.email_first + "@" + this.data.email_domain;
    let checkGroupExist = this.googleService.getGroupByEmail(this.data.email);

    this.myForm.controls.email_first.setErrors(null);
    this.checkEmailExist.exist_email = false;

    if (checkGroupExist && checkGroupExist.id != this.id) {
      // Email Già esistente, genero nel message una email di conferma
      this.checkEmailExist.exist_email = true;

      this.myForm.controls.email_first.setErrors({
        'invalid': true
      });
    }
  }

  onChangeAliasEmail() {
    let email = this.data.alias_email_first?.toLowerCase().replace(/\s/g, '');
    let domain = this.data.alias_email_domain?.toLowerCase().replace(/\s/g, '');

    this.data.alias_email_first = email;
    this.data.alias_email_domain = domain;
    this.data.alias_email = this.data.alias_email_first + "@" + this.data.alias_email_domain;
    let checkGroupExist = this.googleService.getGroupByEmail(this.data.alias_email);
    let existAlias = this.data.aliases ? this.data.aliases.findIndex(x => x == this.data.alias_email) : -1;

    this.myForm.controls.alias_email_first.setErrors(null);
    this.checkEmailExist.exist_alias_email = false;

    if ((checkGroupExist && checkGroupExist.id != this.id) || existAlias != -1 || this.data.alias_email == this.data.email) {
      // Email Già esistente, genero nel message una email di conferma
      this.checkEmailExist.exist_alias_email = true;

      this.myForm.controls.alias_email_first.setErrors({
        'invalid': true
      });
    }

  }

  saveAliasEmail() {
    if (this.myForm.controls.alias_email_first.valid && this.data.alias_email) {
      this.data.aliases.push(this.data.alias_email);
      this.data.alias_email = null;
      this.data.alias_email_first = null;
      this.data.alias_email_domain = this.domains[0].domainName;
    }
  }

  getGroupData() {

    this.data = this.googleService.getGroupByGroupKey(this.id);

    if (!this.data) {
      return;
    }

    this.data.email_first = this.data.email.split('@')[0];
    this.data.email_domain = this.data.email.split('@')[1];

    this.data.alias_email = null;
    this.data.alias_email_first = null;
    this.data.alias_email_domain = this.domains[0].domainName;

    this._loadingData.group = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  unsubscibeAll() {
    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();

    if (this._googleServiceDomainsSubscription)
      this._googleServiceDomainsSubscription.unsubscribe();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  onSubmit() {
    if (this.myForm.valid) {
      this.formLoading = true;

      // Copio i dati dell'oggetto senza riferimento
      let reqBody = JSON.parse(JSON.stringify(this.data));

      // Update
      this.backend.put(`group/${this.id}`, reqBody).subscribe((result) => {
        this.formLoading = false;
        this.router.navigate(['/group/' + this.id]);
      }, (error) => {
        this.formLoading = false;
        this.backendError();
      });
    }
  }

  backendError() {
    this.error = 'backend';

    Object.keys(this._loadingData).forEach(element => {
      this._loadingData[element] = false;
    });
  }

  googleError() {
    this.error = 'google';

    Object.keys(this._loadingData).forEach(element => {
      this._loadingData[element] = false;
    });
  }

  removeAliasEmail(index) {
    this.data.aliases.splice(index, 1);
    this.onChangeAliasEmail();
  }
}

