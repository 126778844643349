import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
@Component({
  selector: 'app-ente-profile-datatable-card',
  templateUrl: './ente-profile-datatable-card.component.html',
  styleUrls: ['./ente-profile-datatable-card.component.scss']
})
export class EnteProfileDatatableCardComponent implements OnInit, OnDestroy {

  ente: any;
  domainsData: any;
  domains: any;
  domainsError: any;
  usersData: any;
  users: any;
  googleUsersData: any;
  usersError: any;

  usersDataDeleted: any;
  usersDeleted: any;
  usersDeletedError: any;

  usersNotSyncError: any;
  usersDataNotSync: any;
  usersNotSync: any;

  usersSuspendedError: any;
  usersDataSuspended: any;
  usersSuspended: any;

  groups: any;
  groupsData: any;
  groupsError: any;
  classroom: any;
  classroomError: any;

  isLoadingDomains: boolean;
  isLoadingUsers: boolean;
  isLoadingUsersDeleted: boolean;
  isLoadingUsersNotSync: boolean;
  isLoadingUsersSuspended: boolean;
  isLoadingGroups: boolean;
  isLoadingClassroom: boolean;

  currentTab: number;

  private _accountServiceSubscription: Subscription;
  private _userServiceSubscription: Subscription;
  private _googleServiceDomainsSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;
  private _googleServiceUsersDeletedSubscription: Subscription;
  private _googleServiceGroupsSubscription: Subscription;
  private _googleServiceClassroomSubscription: Subscription;

  isGoogleWorkspaceAccount: boolean;


  constructor(
    private accountService: AccountService,
    private googleDirectoryService: GoogleDirectoryService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit() {

    this.ente = this.accountService.data.ente;

    this.isGoogleWorkspaceAccount = this.accountService.isGoogleWorkspaceAccount();

    this._accountServiceSubscription = this.accountService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.accountUpdated(result);
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.usersUpdated(result);
      }
      this.isLoadingUsers = false;
      this.isLoadingUsersNotSync = false;
      this.isLoadingUsersSuspended = false;
    });

    this._googleServiceDomainsSubscription = this.googleDirectoryService.getSubjectToUpdateDomainsObservable().subscribe(
      (result) => {
        if (result)
          this.domainsUpdated(result);
        this.isLoadingDomains = false;
      });

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe(
      (result) => {
        if (result) {
          this.googleUsersData = result;
        }
        this.userService.all();
      });

    this._googleServiceUsersDeletedSubscription = this.googleDirectoryService.getSubjectToUpdateUsersDeletedObservable().subscribe(
      (result) => {
        if (result)
          this.usersDeletedUpdated(result);

        this.isLoadingUsersDeleted = false;
      });

    this._googleServiceGroupsSubscription = this.googleDirectoryService.getSubjectToUpdateGroupsObservable().subscribe(
      (result) => {
        if (result)
          this.groupsUpdated(result);
        this.isLoadingGroups = false;
      });

    this.selectTab(1);
  }

  ngOnDestroy() {

    if (this._accountServiceSubscription)
      this._accountServiceSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleServiceDomainsSubscription)
      this._googleServiceDomainsSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();

    if (this._googleServiceUsersDeletedSubscription)
      this._googleServiceUsersSubscription.unsubscribe();

    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();

    if (this._googleServiceClassroomSubscription)
      this._googleServiceClassroomSubscription.unsubscribe();


  }

  selectTab(index) {

    this.currentTab = index;

    switch (index) {
      case 1:
        this.isLoadingUsers = true;
        this.isLoadingUsersNotSync = true;
        this.isLoadingUsersSuspended = true;
        this.googleDirectoryService.usersList();
        break;
      case 2:
        this.isLoadingGroups = true;
        this.googleDirectoryService.groupsList();
        break;
      case 3:
        this.isLoadingDomains = true;
        this.googleDirectoryService.domainsList();
        break;
      case 4:
        this.isLoadingUsersDeleted = true;
        this.googleDirectoryService.usersDeletedList();
        break;
      default:
        break;
    }
  }

  accountUpdated(result) {
    this.ente = result.ente;
  }

  domainsUpdated(result) {
    this.domainsData = result;
    this.domains = this.parseDomainsResult(result);
  }

  rowClickGroup(data) {
    let group = this.groupsData[data.index];
    this.router.navigate(['group/' + group.id]);
  }

  usersUpdated(result) {
    this.usersData = result.filter(a => {
      if (a.google_user) {
        if (a.google_user.suspended == false) {
          return a;
        }
      } else {
        return a;
      }
    });

    this.users = this.userService.getTable(this.usersData);

    if (this.googleUsersData) {
      this.usersDataNotSync = this.googleUsersData.filter(a => !this.userService.getByGoogleId(a.id));
      this.usersNotSync = this.userService.getTable(this.usersDataNotSync, "orgunit");

      this.usersDataSuspended = this.googleUsersData.filter(a => a.suspended == true);
      this.usersSuspended = this.userService.getTable(this.usersDataSuspended, "classe");
    }
  }

  usersDeletedUpdated(result) {
    this.usersDataDeleted = result;
    this.usersDeleted = this.userService.getTable(result, "deleted");
  }

  groupsUpdated(result) {
    this.groupsData = result;
    this.groups = this.parseGroupsResult(result);
  }

  classroomUpdated(result) {
    this.classroom = this.parseClassroomResult(result);
  }

  parseDomainsResult(result) {
    let table = [];

    result.forEach(element => {

      // let dataCreazione = new Date(element.creationTime).toDateString();

      table.push({
        "nome_dominio": [element.domainName],
        "primario": [element.isPrimary],
        "verificato": [element.verified],
        "_export_data": {
          domainName: element.domainName,
          isPrimary: element.isPrimary,
          verified: element.verified
        },
      })
    });

    return table;
  }

  parseGroupsResult(result) {

    let table = [];

    result.forEach(element => {

      table.push({
        "nome": [element.name, element.description],
        "email": [element.email],
        "membri": [element.directMembersCount],
        "_export_data": {
          name: element.name,
          description: element.description,
          email: element.email,
          directMembersCount: element.directMembersCount,
        },
      })
    });

    return table;
  }

  parseClassroomResult(result) {
    return;
  }

  rowClickUser(data) {
    let user = this.usersData[data.index];
    this.router.navigate(['user/' + user.user_id], { relativeTo: this.route });
  }

  rowClickUserSuspended(data) {
    let userGoogle = this.usersDataSuspended[data.index];
    this.router.navigate(['user/google/' + userGoogle.id], { relativeTo: this.route });
  }

  rowClickUserDeleted(data) {
    // TODO_ENRICO per abilitare il clic sugli utenti cancellati va modificata la scheda di visualizzazione di google per recuperare anche gli utenti cancellati
    // let userGoogle = this.usersDataDeleted[data.index];
    // this.router.navigate(['user/google/' + userGoogle.id], { relativeTo: this.route });
  }

  rowClickUserGoogle(data) {
    let userGoogle = this.usersDataNotSync[data.index];
    this.router.navigate(['user/google/' + userGoogle.id], { relativeTo: this.route });
  }

  addClickUser() {
    this.router.navigate(['user'], { relativeTo: this.route });
  }
}
