import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-classroom-wizard-steps',
  templateUrl: './create-classroom-wizard-steps.component.html',
  styleUrls: ['./create-classroom-wizard-steps.component.scss']
})
export class CreateClassroomWizardStepsComponent implements OnInit {

  steps: any = [
    1, 2
  ];

  @Input() currentStep: number;

  constructor() { }

  ngOnInit() {
  }

}
