import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BackendService } from '../../backend.service';
import { ToastService } from '../../toast.service';
import { UtilityService } from '../../utility.service';

@Injectable({
  providedIn: 'root'
})
export class RemoveMembersFromGroupsService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  items: any[];

  constructor(private backend: BackendService, private toast: ToastService, private utilityService: UtilityService) {
    this.reset();
  }

  reset() {
    this.items = [];
    this._subjectToUpdate.next(this.items);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  addMembers(newItems, groupKey) {

    newItems.forEach(item => {
      
      let itemFound = this.items.find((element) => element?.id == item?.id && element?.groupKeyRemove == groupKey);

      if (!itemFound) {
        this.items.push(item);
      }
    });

    this._subjectToUpdate.next(this.items);
  }

  deleteItemsAtIndex(indexArray) {

    indexArray.forEach(idx => {
      this.items[idx] = null;
    });

    this.items = this.items.filter((el) => { return el != null });

    this._subjectToUpdate.next(this.items);
  }

  execOperation(body, callback = (error) => { }) {
    let members = [];

    this.items.forEach(element => {
      members.push({
        email: element.email,
        groupKeyRemove: element.groupKeyRemove,
        type: element.type
      });
    });

    this.backend.post("op/remove-members-from-groups", { members: members }).subscribe((result) => {
      this.reset();
      this.toast.success('Rimozione Membri avviato');
      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }
}
