import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FileimportService } from 'src/app/services/fileimport.service';

@Component({
  selector: 'app-setup-wizard-end',
  templateUrl: './setup-wizard-end.component.html',
  styleUrls: ['./setup-wizard-end.component.scss']
})
export class SetupWizardEndComponent implements OnInit {

  constructor(private router: Router, private fileimportService: FileimportService) { }

  ngOnInit() {

    if (!this.fileimportService.hasFile()) {
      this.router.navigate(['/setup']);
      return;
    }
  }

  saveFile () {
    this.fileimportService.saveUsersDataFile();
  }

  deleteFile() {
    this.fileimportService.deleteFile();
    this.router.navigate(['/setup']);
  }

}
