import { Component, Input, OnInit } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-ente-profile',
  templateUrl: './ente-profile.component.html',
  styleUrls: ['./ente-profile.component.scss']
})
export class EnteProfileComponent implements OnInit {

  @Input() showForm: boolean;

  constructor(private route: ActivatedRoute, private router: Router, private utilityService: UtilityService) { }

  ngOnInit() {
    this.showForm = false;

    // controllo l'action se presente nel path
    if (this.utilityService.checkRouteActionType(this.route, "edit"))
      this.showForm = true;
  }

}
