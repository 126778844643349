import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { paramsValidator } from 'src/app/forms/helpers/params.validator';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { CreateClassroomService } from 'src/app/services/operations/classroom/create-classroom.service';

@Component({
  selector: 'app-create-classroom',
  templateUrl: './create-classroom.component.html',
  styleUrls: ['./create-classroom.component.scss']
})
export class CreateClassroomComponent implements OnInit {
  showAllSetting = false;
  error: any;
  classiSelected: any;
  users: any = [];
  usersData: any = [];
  resultsTags: string[];
  struttura: any;

  menuSelection: any = [{
    id: 1,
    title: "Classe -> Classroom", // lo lasciamo il titolo qui per capire noi a cosa corrispondono ma viene usata la traduzione
    error: false, // serve per far diventare disable la card se tipo non si sono materie ecc
    standardClassroomName: "$name_classe$",
    standardClassroomDescription: "$anno_classe$",
  },
  {
    id: 3,
    title: "Classe + Docenti -> Classroom",
    error: false, // serve per far diventare disable la card se tipo non si sono materie ecc
    standardClassroomName: "$name_classe$ $anno_classe$",
    standardClassroomDescription: "Docente: $proprietario$",
  },
  {
    id: 2,
    title: "Classe + Docenti + Materia -> Classroom",
    error: false, // serve per far diventare disable la card se tipo non si sono materie ecc
    standardClassroomName: "$name_classe$ $anno_classe$",
    standardClassroomDescription: "Docente: $proprietario$ Materia: $name_materia$",
  }];

  currentStep = 1;

  classi: any = [];
  classiSelect: any = [];
  classiPreSelect: any = [];

  model: any;

  _loadingData = {
    plessi: false,
    users: false,
    google_users: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  myForm: FormGroup = new FormGroup({});
  private _userServiceSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;
  private _plessoServiceSubscription: Subscription;
  private _createClassroomServiceSubscription: Subscription;
  validateEventSubject: Subject<any> = new Subject<any>();
  setValueEventSubject: Subject<any> = new Subject<any>();
  eventsSubject: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private createClassroomService: CreateClassroomService,
    private plessoService: PlessoService, 
    private userService: UserService, 
    private googleDirectoryService: GoogleDirectoryService
  ) { }

  ngOnInit(): void {
    this._loadingData.users = true;
    this._loadingData.google_users = true;

    this.ngOnDestroy();

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError();
      else
        this.plessiDataUpdated(result);
    });

    this._createClassroomServiceSubscription = this.createClassroomService.getSubjectToUpdateObservable().subscribe((result) => {
      this.classiPreSelect = result;
      this.loadClassiPreSelected();
    });

    this.getBackendPlessiData();

    this.model = this.createClassroomService.parameters;

    // se model.creationType è null significa che è la prima volta che apro la schermata e quindi seleziono il primo tipo
    if (!this.model.creationType) {
      this.model.creationType = this.menuSelection[0].id;
      this.model.classroomName = this.menuSelection[0].standardClassroomName;
      this.model.classroomDescription = this.menuSelection[0].standardClassroomDescription;
      this.updateParameter();
    }

    this.initForm();

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      let data = [];
      if (result)
        data = this.userService.excludeByRole("google-student");

      this.parseUserData(data);
    });

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe(
      (result) => {
        if (result) {
          this.userService.all();
        }
        this._loadingData.google_users = false;
      });

    this.googleDirectoryService.usersList();
  }

  loadClassiPreSelected() {
    let idClassSelected = this.classiPreSelect.map(x => x.id);
    let _classiSelected = [];

    this.classiSelect.forEach(x => {
      this.classiSelected = x.items;
      x.items.forEach(element => {
        if (idClassSelected.includes(element.id)) {
          _classiSelected.push(element);
        }
      });
    });
    this.classiSelected = _classiSelected;
  }

  selectCardMenu(cardMenu: any) {

    this.model.creationType = cardMenu.id;
    this.model.classroomName = cardMenu.standardClassroomName;
    this.model.classroomDescription = cardMenu.standardClassroomDescription;

    this.setInput('classroom.classroomName', 'classroomName_tag', this.model.classroomName);
    this.setInput('classroom.classroomDescription', 'classroomDescription_tag', this.model.classroomDescription);

    this.updateParameter();
  }

  ngOnDestroy() {
    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();

    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();

    if (this._createClassroomServiceSubscription)
      this._createClassroomServiceSubscription.unsubscribe();
  }

  initForm() {
    this.showAllSetting = false;

    this.myForm = new FormGroup({
      classroom: new FormGroup({
        classroomName: new FormControl(this.model.classroomName, [Validators.required, paramsValidator('$name_plesso$|$short_name_plesso$|$name_classe$')]),
        classroomSection: new FormControl(this.model.classroomSection),
        classroomRoom: new FormControl(this.model.classroomRoom),
        classroomDescription: new FormControl(this.model.classroomDescription),
        allOwnerId: new FormControl(this.model.allOwnerId),
        classroomName_error_ignore: new FormControl(this.model.classroomName_error_ignore),
        creationType: new FormControl(this.model.creationType),
        classi: new FormControl(this.model.classi)
      })
    })
  }

  changeIgnoreError(inputId, tagId) {
    let inputCheckBox = this.myForm.get(inputId + "_error_ignore");

    if (inputCheckBox.value) {
      this.myForm.get(inputId).clearValidators();
      this.myForm.get(inputId).updateValueAndValidity();
      this.myForm.get(inputId).setValidators([Validators.required]);
      this.myForm.get(inputId).updateValueAndValidity();
    } else {
      this.myForm.get(inputId).clearValidators();
      this.myForm.get(inputId).updateValueAndValidity();
      this.myForm.get(inputId).setValidators([Validators.required, paramsValidator(this.getPatternValidator(inputId))]);
      this.myForm.get(inputId).updateValueAndValidity();
    }

    this.validateEventSubject.next({
      value: this.myForm.get(inputId).valid,
      tagId: tagId
    });
  }

  getPatternValidator(inputId) {
    let typeFormGroup = inputId.split(".")[0];
    let pattern = "";
    switch (typeFormGroup) {
      case "classroom":
        pattern = '$name_plesso$|$short_name_plesso$|$name_classe$';
        break;
    }

    return pattern;
  }

  /**
 * OnUpdate Data
 */
  plessiDataUpdated(result) {

    if (result) {

      this.classiSelect = [];

      result.forEach(element => {

        let objSelect = {
          label: element.name, value: element.id,
          items: []
        }

        if (element.classi && element.classi.length > 0) {

          element.classi.forEach(cls => {
            let objPlesso = {
              plesso: element
            };

            objSelect.items.push({ ...cls, ...objPlesso })
          });

          this.classiSelect.push(objSelect);
        }
      });

      this.createClassroomService.getItems();
    }

    this._loadingData.plessi = false;
  }

  clearInput(position, tagId) {

    this.myForm.get(position).setValue("");

    this.setValueEventSubject.next({
      value: "",
      tagId: tagId
    });
  }

  setInput(position, tagId, value) {

    this.myForm.get(position).setValue(value);

    this.setValueEventSubject.next({
      value: value,
      tagId: tagId
    });
  }

  /**
     * Aggiunge una string ad un determinato parametro
     * @param stringToAdd
     * @param param
     */
  addParamTo(stringToAdd: string, tagsIdAdd) {
    // Evento mandato al Child per l'aggiunta del tag, valorizzazione esterna al child ma tramite parent
    this.eventsSubject.next({
      name: stringToAdd,
      tagId: tagsIdAdd
    });
  }

  onInputTagInput($event, param: FormControl) {

    param.setValue($event.value);

    // Evento mandato al Child per la Validazione
    this.validateEventSubject.next({
      value: param.valid,
      tagId: $event.tagId
    });

    this.updateParameter();
  }

  onSubmit(myForm: NgForm) {

    if (myForm.valid) {


    }
  }

  changeStatusCollapse() {
    this.showAllSetting = !this.showAllSetting;
  }

  getBackendPlessiData() {
    this._loadingData.plessi = true;
    this.plessoService.all();
  }

  resetOperation() {
    this.createClassroomService.reset();
    this.ngOnInit();
  }

  back() {
    this.currentStep = 1;
  }

  next() {
    this.currentStep = 2;
    this.router.navigate(['/operation/create-classroom/preview']);
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  backendError() {
    this.error = "backend";
  }

  classiOnSelect($event) {
    this.createClassroomService.updateItems($event);
  }

  updateParameter() {
    this.createClassroomService.updateParameter(this.model);
  }

  tagsFiltered($event) {
    if ($event) {
      this.resultsTags = this.usersData.filter(function (x) {
        return x.email.toLowerCase().trim().includes($event.query.toLowerCase().trim()) || x.fullname.toLowerCase().trim().includes($event.query.toLowerCase().trim());
      });
    } else {
      this.resultsTags = this.usersData;
    }
  }
  
  selectedUser($event) {
    this.model.allOwnerId = $event;
    this.model.ownerData = $event.userData;
    this.updateParameter();
  }

  clearSelection($event) {
    this.model.allOwnerId = null;
    this.model.ownerData = null;
    this.updateParameter();
  }

  parseUserData(result) {
    this.usersData = [];
    this.users = result;
    result.forEach(element => {
      let user = this.userService.get(element.user_id);

      let userDataView = {
        id: element.user_id,
        google_id: null,
        fullname: user?.user.givenName + " " + user?.user.familyName,
        email: null,
        avatar: null,
        initials: user?.user.givenName[0] + user?.user.familyName,
        userData: user
      };

      if (user?.google_user) {
        userDataView.fullname = user.google_user.name?.givenName + " " + user.google_user.name?.familyName;
        userDataView.initials = user.google_user.name?.givenName[0] + user.google_user.name?.familyName[0];
        userDataView.email = user.google_user.primaryEmail;
        userDataView.google_id = user.google_user.id;
        userDataView.avatar = user.google_user?.thumbnailPhotoUrl;
        userDataView.userData.google_user.google_id = user.google_user.id
      }

      if (userDataView.email)
        this.usersData.push(userDataView);
    });

    this.resultsTags = this.usersData;

    this._loadingData.users = false;
  }
}
