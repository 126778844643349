<app-card-loading *ngIf="loading">
</app-card-loading>


<app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">

  <div class="row mb-5">
    <div class="col">
      <div class="card card-hover-shadow">
        <div class="card-body m-2">
          <app-create-classroom-wizard-steps [currentStep]="currentStep"></app-create-classroom-wizard-steps>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
    <span formGroupName="classroom">

      <div class="row mb-5">
        <div class="col">

          <!-- Card -->
          <div class="card card-hover-shadow">
            <div class="card-body">

              <h1 class="card-title">{{'CLASSROOM.create.title' | translate}}</h1>
              <p class="card-text" [innerHTML]="'CLASSROOM.create.subtitle' | translate"></p>

              <div class="row mt-5">

                <input [hidden]="true" formControlName="creationType" id="creationType" type="text">

                <div *ngFor="let cardMenu of menuSelection" class="col mb-3 mb-sm-0">
                  <!-- Card -->
                  <div (click)="selectCardMenu(cardMenu)"
                    class="js-custom-checkbox-card card custom-checkbox-card custom-checkbox-card-lg"
                    [ngClass]="{checked: model.creationType == cardMenu.id}">
                    <!-- Header -->
                    <div class="card-header d-block text-center">
                      <!-- Checkbox -->
                      <input type="radio" [id]="cardMenu.id" name="checkboxCardPricingRadioEg"
                        class="js-custom-checkbox-card-input custom-checkbox-card-input"
                        [checked]="model.creationType == cardMenu.id">
                      <label class="custom-checkbox-card-label custom-checkbox-card-label-stretched"
                        [for]="cardMenu.id"></label>
                      <!-- End Checkbox -->

                      <small class="card-subtitle"
                        [innerHTML]="'CLASSROOM.create.sottotitolo_tipo_' + cardMenu.id | translate"></small>
                      <div class="mb-3">
                        <span class="card-title display-3">
                          <i *ngIf="cardMenu.id == 1" class="fad fa-chalkboard"></i>
                          <i *ngIf="cardMenu.id == 3" class="fad fa-chalkboard-teacher"></i>
                          <i *ngIf="cardMenu.id == 2" class="fad fa-user-chart"></i>
                        </span>
                      </div>

                      <h4 [innerHTML]="'CLASSROOM.create.titolo_tipo_' + cardMenu.id | translate"></h4>
                    </div>

                    <!-- <div class="card-body" [innerHTML]="'CLASSROOM.create.descrizione_tipo_' + cardMenu.id | translate"></div> -->

                    <div class="card-body-stretched">
                      <button type="button" class="btn btn-block btn-white mt-2"
                        [ngClass]="{'custom-checkbox-card-btn': model.creationType == cardMenu.id}">Seleziona</button>
                    </div>
                  </div>
                  <!-- End Card -->
                </div>

              </div>

              <div class="alert alert-soft-info mt-3 mb-0">
                <b>Suggerimento</b> per creare una Classroom non collegata ad una classe, selezionare un utente in geniusuite ed utilizzare l'operazione massiva "Aggiungi docente in nuova classroom".
              </div>

            </div>
          </div>


        </div>
      </div>


      <div class="row mb-5">
        <div class="col">

          <div class="card card-hover-shadow">
            <div class="card-body">

              <h1 class="card-title">{{'CLASSROOM.create.title_plesso_classi' | translate}}</h1>
              <p class="card-text" [innerHTML]="'CLASSROOM.create.subtitle_plesso_classi' | translate"></p>

              <div class="row form-group mt-2">
                <div class="col-sm-12">
                  <label class="input-label" for="select-classiSelected">{{"STR.Seleziona_Plesso" |
                    translate}}</label>

                  <app-select (valueChange)="classiOnSelect($event)" [multiple]="true" [group]="true"
                    [id]="'select-classiSelected'" [showClear]="true" [filterBy]="'name'" [virtualScroll]="false"
                    [placeholder]="'STR.seleziona_plesso_classi'" [data]="classiSelect" [showValue]="'name'"
                    [(value)]="classiSelected" [optionAttributeShow]="'name'" [group]="true" [showToggleAll]="true">
                  </app-select>

                  <input [hidden]="true" formControlName="classi" id="classi" type="text">
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row mb-5" *ngIf="classiSelected && classiSelected.length > 0">
        <div class="col">

          <div class="card card-hover-shadow">
            <div class="card-body">

              <h1 class="card-title">{{'CLASSROOM.create.title_parametri' | translate}}</h1>
              <p class="card-text" [innerHTML]="'CLASSROOM.create.subtitle_parametri' | translate"></p>

              <div class="row form-group">
                <div class="col">

                  <label class="input-label">Nome
                    <button type="button" class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                      (click)="clearInput('classroom.classroomName', 'classroomName_tag')">
                      <i class="fas fa-backspace"></i>
                    </button>
                  </label>

                  <app-tagsinput [ignoreTrim]="true" [id]="'classroomName_tag'" [defaultValue]="model.classroomName"
                    [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                    [setValueEvent]="setValueEventSubject.asObservable()"
                    [buttonInput]="['$name_plesso$', '$short_name_plesso$', '$name_classe$', '$anno_classe$', '$proprietario$', '$name_materia$']"
                    (onInputText)="onInputTagInput($event, myForm.get('classroom.classroomName'))">
                  </app-tagsinput>

                  <input [hidden]="true" [(ngModel)]="model.classroomName" formControlName="classroomName"
                    id="classroomName" type="text">
                  <div *ngIf="!myForm.get('classroom.classroomName').valid || model.classroomName_error_ignore"
                    class="mt-1 alert alert-soft-danger" role="alert">
                    <label class="toggle-switch d-flex toggle-switch-sm" for="classroomName_error_ignore">
                      <input type="checkbox" class="toggle-switch-input"
                        [ngClass]="{'is-invalid': model.classroomName_error_ignore}"
                        [(ngModel)]="model.classroomName_error_ignore" id="classroomName_error_ignore"
                        formControlName="classroomName_error_ignore"
                        (change)="changeIgnoreError('classroom.classroomName', 'classroomName_tag')">
                      <span class="toggle-switch-label">
                        <span class="toggle-switch-indicator"></span>
                      </span>
                      <span class="toggle-switch-content">
                        <span class="d-block">{{ 'CLASSROOM.disabilita_controlli_title' |
                          translate }}</span>
                        <small class="d-block text-muted">{{
                          'CLASSROOM.disabilita_controlli_msg' | translate }}</small>
                      </span>
                    </label>
                  </div>

                  <div class="text-muted font-size-1 mt-1">deve contenere almeno
                    <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                      (click)="addParamTo('$name_plesso$', 'classroomName_tag')">nome
                      plesso</button>
                    <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                      (click)="addParamTo('$short_name_plesso$', 'classroomName_tag')">short
                      nome
                      plesso</button>
                    <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                      (click)="addParamTo('$name_classe$', 'classroomName_tag')">nome
                      classe</button>
                    <p class="mt-1">oppure
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        (click)="addParamTo('$anno_classe$', 'classroomName_tag')">anno
                        classe</button>
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        *ngIf="model.creationType == 2" (click)="addParamTo('$name_materia$', 'classroomName_tag')">nome
                        materia</button>
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        (click)="addParamTo('$proprietario$', 'classroomName_tag')">proprietario</button>
                    </p>
                  </div>
                </div>
              </div>

              <!-- Descrizione -->

              <div class="row form-group">
                <div class="col">

                  <label class="input-label">Descrizione
                    <button type="button" class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                      (click)="clearInput('classroom.classroomDescription', 'classroomDescription_tag')">
                      <i class="fas fa-backspace"></i>
                    </button>
                  </label>

                  <app-tagsinput [ignoreTrim]="true" [id]="'classroomDescription_tag'"
                    [defaultValue]="model.classroomDescription" [validateEvent]="validateEventSubject.asObservable()"
                    [events]="eventsSubject.asObservable()" [setValueEvent]="setValueEventSubject.asObservable()"
                    [buttonInput]="['$name_plesso$', '$short_name_plesso$', '$name_classe$', '$anno_classe$', '$proprietario$', '$name_materia$']"
                    (onInputText)="onInputTagInput($event, myForm.get('classroom.classroomDescription'))">
                  </app-tagsinput>

                  <input [hidden]="true" [(ngModel)]="model.classroomDescription" formControlName="classroomDescription"
                    id="classroomDescription" type="text">

                  <div class="text-muted font-size-1 mt-1">deve contenere almeno
                    <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                      (click)="addParamTo('$name_plesso$', 'classroomDescription_tag')">nome
                      plesso</button>
                    <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                      (click)="addParamTo('$short_name_plesso$', 'classroomDescription_tag')">short
                      nome
                      plesso</button>
                    <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                      (click)="addParamTo('$name_classe$', 'classroomDescription_tag')">nome
                      classe</button>
                    <p class="mt-1">oppure
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        (click)="addParamTo('$anno_classe$', 'classroomDescription_tag')">anno
                        classe</button>
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        *ngIf="model.creationType == 2" (click)="addParamTo('$name_materia$', 'classroomDescription_tag')">nome
                        materia</button>
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        (click)="addParamTo('$proprietario$', 'classroomDescription_tag')">proprietario</button>
                    </p>
                  </div>
                </div>
              </div>
              <!-- /Descrizione -->

              <div class="row form-group">
                <div class="col-12">
                  <button class="btn btn-outline-primary" type="button" (click)="changeStatusCollapse()"
                    [attr.aria-expanded]="showAllSetting" aria-controls="collapseAdvancedSettings">
                    Configura sezione, stanza e proprietario
                    <span class="ml-2" [ngSwitch]="showAllSetting">
                      <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                      <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="form-group" id="collapseAdvancedSettings" [collapse]="!showAllSetting" [isAnimated]="true">

                <!-- Sezione -->

                <div class="row form-group">
                  <div class="col">

                    <label class="input-label">Sezione
                      <button type="button" class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                        (click)="clearInput('classroom.classroomSection', 'classroomSection_tag')">
                        <i class="fas fa-backspace"></i>
                      </button>
                    </label>

                    <app-tagsinput [ignoreTrim]="true" [id]="'classroomSection_tag'"
                      [defaultValue]="model.classroomSection" [validateEvent]="validateEventSubject.asObservable()"
                      [events]="eventsSubject.asObservable()" [setValueEvent]="setValueEventSubject.asObservable()"
                      [buttonInput]="['$name_plesso$', '$short_name_plesso$', '$name_classe$', '$anno_classe$', '$name_materia$']"
                      (onInputText)="onInputTagInput($event, myForm.get('classroom.classroomSection'))">
                    </app-tagsinput>

                    <input [hidden]="true" [(ngModel)]="model.classroomSection" formControlName="classroomSection"
                      id="classroomSection" type="text">

                    <div class="text-muted font-size-1 mt-1">puoi inserire
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        (click)="addParamTo('$name_plesso$', 'classroomSection_tag')">nome
                        plesso</button>
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        (click)="addParamTo('$short_name_plesso$', 'classroomSection_tag')">short
                        nome
                        plesso</button>
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        (click)="addParamTo('$name_classe$', 'classroomSection_tag')">nome
                        classe</button>
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        (click)="addParamTo('$anno_classe$', 'classroomSection_tag')">anno
                        classe</button>
                      <p class="mt-0">oppure
                        <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                          *ngIf="model.creationType == 2" (click)="addParamTo('$name_materia$', 'classroomSection_tag')">nome
                          materia</button>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- /Sezione -->

                <!-- Stanza -->
                <div class="row form-group">
                  <div class="col">

                    <label class="input-label">Stanza
                      <button type="button" class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1"
                        (click)="clearInput('classroom.classroomRoom', 'classroomRoom_tag')">
                        <i class="fas fa-backspace"></i>
                      </button>
                    </label>

                    <app-tagsinput [ignoreTrim]="true" [id]="'classroomRoom_tag'" [defaultValue]="model.classroomRoom"
                      [validateEvent]="validateEventSubject.asObservable()" [events]="eventsSubject.asObservable()"
                      [setValueEvent]="setValueEventSubject.asObservable()"
                      [buttonInput]="['$name_plesso$', '$short_name_plesso$', '$name_classe$', '$anno_classe$', '$name_materia$']"
                      (onInputText)="onInputTagInput($event, myForm.get('classroom.classroomRoom'))">
                    </app-tagsinput>

                    <input [hidden]="true" [(ngModel)]="model.classroomRoom" formControlName="classroomRoom"
                      id="classroomRoom" type="text">

                    <div class="text-muted font-size-1 mt-1">puoi inserire
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        (click)="addParamTo('$name_plesso$', 'classroomRoom_tag')">nome
                        plesso</button>
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        (click)="addParamTo('$short_name_plesso$', 'classroomRoom_tag')">short
                        nome
                        plesso</button>
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        (click)="addParamTo('$name_classe$', 'classroomRoom_tag')">nome
                        classe</button>
                      <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                        (click)="addParamTo('$anno_classe$', 'classroomRoom_tag')">anno
                        classe</button>
                      <p class="mt-0">oppure
                        <button type="button" class="btn btn-outline-primary btn-xs badge mr-2"
                          *ngIf="model.creationType == 2" (click)="addParamTo('$name_materia$', 'classroomRoom_tag')">nome
                          materia</button>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- /Stanza -->

                <!-- Proprietario -->
                <div class="row form-group">
                  <div class="col">
                    <label class="input-label" for="allOwnerId">Assegna un proprietario a tutte le Classroom</label>
                    <span class="p-fluid">
                      <p-autoComplete [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'" formControlName="allOwnerId" id="allOwnerId" name="allOwnerId"
                        [(ngModel)]="model.allOwnerId" [suggestions]="resultsTags" (completeMethod)="tagsFiltered($event)"
                        (onSelect)="selectedUser($event)" (onClear)="clearSelection($event)" [multiple]="false"
                        [forceSelection]="true" placeholder="Cerca Utente" field="email">

                        <ng-template let-tags pTemplate="item">
                          <div class="media align-items-center">
                            <div class="avatar avatar-sm avatar-soft-primary avatar-circle mr-3">
                              <span class="avatar-initials" *ngIf="!tags.avatar">{{tags.initials}}</span>
                              <img class="avatar-img" *ngIf="tags.avatar" src="{{tags.avatar}}">
                            </div>
                            <div class="media-body">
                              <span class="d-block h5 text-hover-primary mb-0">{{tags.fullname}}</span>
                              <span class="d-block font-size-sm text-body">{{tags.email}}</span>
                            </div>
                          </div>
                        </ng-template>
                      </p-autoComplete>
                    </span>
                  </div>
                </div>
                <!-- /Proprietario -->
              </div>


            </div>
          </div>
        </div>
      </div>

    </span>
    <!-- End Card -->
  </form>


  <div class="row mb-5">
    <div class="col">
      <div class="card card-hover-shadow">
        <div class="card-body">

          <div class="row justify-content-between align-items-center">
            <div class="col-auto py-1">
              <button type="button" class="btn btn-outline-danger" (click)="resetOperation()">
                <i class="far fa-backspace mr-2"></i>
                <span>{{'STR.reset_operation' | translate}}</span>
              </button>
            </div>

            <div class="col-6 py-1">
              <div class="row justify-content-end">
                <div class="col-auto">
                  <button type="button" class="btn" (click)="next()" [disabled]="!myForm.valid || classiSelected?.length == 0"
                    [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                    Rivedi e avvia <i class="far fa-chevron-right ml-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-container>
