<app-card-loading *ngIf="loadingMateria || loadingMateriaService || loadingParameterService">
</app-card-loading>

<app-card-loading-error *ngIf="!(loadingMateria || loadingParameterService) && error" [error]="error"
    (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<!-- Template Data List -->
<ng-template #tableEditList let-list="list">
    <p-table [value]="list" [scrollable]="true" scrollHeight="200px">
        <!-- <ng-template pTemplate="header" let-columns>
            <tr>
                <th>name</th>
            </tr>
        </ng-template> -->
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
            <tr>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="form-control" [class.is-invalid]="rowData.invalid" type="text"
                                (blur)="checkTableEditValue(rowData.name, rowIndex, 'blur')"
                                (keyup)="checkTableEditValue(rowData.name, rowIndex, 'keyup')"
                                [(ngModel)]="rowData.name">
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span [class.text-danger]="rowData.invalid">{{rowData.name}}</span>
                        </ng-template>
                    </p-cellEditor>
                </td>
            </tr>
        </ng-template>
    </p-table>
</ng-template>
<!-- /Template Data List -->

<div class="card card-hover-shadow"
    *ngIf="!(loadingMateria || loadingMateriaService || loadingParameterService) && !error">

    <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>

    <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">

        <form #myForm="ngForm" validate>

            <div class="row">
                <!-- Left -->
                <div class="col-12">

                    <!-- Titlte -->
                    <div class="row">
                        <div class="col-12">
                            <h4>Aggiungi nuove materie</h4>
                            <p>Scrivi e premi invio ad ogni materia, puoi aggiungerne più di una e poi salvare.</p>
                            <p>Se hai sbagliato a scrivere puoi cliccare sul nome e modificarlo prima di salvare.</p>
                        </div>
                    </div>

                    <!-- Nome -->
                    <div class="row align-items-center">
                        <div class="col" style="min-height: 100px;">
                            <label class="input-label" for="name">Nome</label>
                            <input id="name" name="name" #name="ngModel" class="form-control" [(ngModel)]="data.name"
                                [ngClass]="(name.dirty || name.touched)? (name.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                                type="text" required maxlength="255" [forbiddenString]="getFilterData('name')"
                                (keyup.enter)="addMateria(myForm)" autocomplete="none">

                            <div class="invalid-feedback" *ngIf="name.invalid && (name.dirty || name.touched)">
                                <span>Non valido</span>
                                <span *ngIf="name.errors.forbiddenString">, Materia già esistente</span>
                            </div>

                            <span class="valid-feedback"
                                *ngIf="name.valid && (name.dirty || name.touched)">Valido</span>
                        </div>
                        <div class="col-auto" style="vertical-align: middle;">
                            <button [disabled]="!name.valid" type="button" class="btn btn-outline-primary btn-sm mr-2"
                                (click)="addMateria(myForm)">
                                <i class="fas fa-plus mr-2"></i>Aggiungi Materia
                            </button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <ng-container *ngTemplateOutlet="tableEditList; context:{ list: newMaterie }">
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card-footer">
                <div class="row justify-content-between align-items-center">
                    <div class="col-auto py-1">
                        <!-- <button (click)="onCloseEvent.emit()" type="button" class="btn btn-white">
                            <i class="far fa-chevron-left mr-1"></i> Annulla
                        </button> -->
                    </div>
                    <div class="col-6 py-1">
                        <div class="row justify-content-end">
                            <button (click)="onSubmit(myForm)" type="button" class="btn"
                                [disabled]="!newMaterie || newMaterie.length == 0 || invalidMaterie"
                                [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                                <span *ngIf="id">Aggiorna</span>
                                <span *ngIf="!id">Salva</span>
                                <i class="far fa-chevron-right ml-1"></i>
                            </button>
                        </div>
                        <div class="row justify-content-end" *ngIf="invalidMaterie">
                            <p-message severity="error" text="Ci sono Materie già esistenti"></p-message>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>
</div>
