<app-card-loading *ngIf="loadingBackend">
</app-card-loading>

<app-card-loading-error *ngIf="!(loadingBackend) && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card" *ngIf="!(loadingBackend) && !error">
  <!-- Header -->
  <div class="card-header">
    <h5 class="card-header-title"><i class="fad fa-chalkboard nav-icon blue mr-1"></i> Classe: {{classe.name}}</h5>
    <div class="d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-outline-primary btn-sm mr-2" (click)="showInfo = !showInfo">
        <i class="fas fa-info mr-2"></i>{{ 'BTN.Informazioni' | translate }}
      </button>
      <button type="button" class="btn btn-outline-primary btn-sm mr-2"
        [routerLink]="['/plesso/'+ classe.plesso_id +'/classe/'+ classe.id +'/edit']">
        <i class="fas fa-edit mr-2"></i>{{ 'BTN.Modifica' | translate }}
      </button>
      <button type="button" class="btn btn-outline-danger btn-sm"
        [routerLink]="['/plesso/'+ classe.plesso_id +'/classe/'+ classe.id +'/delete']">
        <i class="far fa-trash mr-2"></i>{{ 'BTN.cancella_classe' | translate }}
      </button>

      <!-- <div class="dropdown" dropdown [isAnimated]="false">
        <button class="btn btn-outline-primary btn-sm dropdown" type="button"
        id="dropdownMenuButton" dropdownToggle aria-controls="dropdownMenu">
        <i class="fas fa-edit mr-2"></i>Operazioni<i class="fas fa-chevron-down ml-2"></i>
        </button>
        <div class="dropdown-menu" *dropdownMenu id="dropdownMenu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="showInfo = !showInfo"><i class="fas fa-info mr-2"></i>{{ 'BTN.Informazioni' | translate }}</a>
          <a class="dropdown-item" [routerLink]="['/plesso/'+ classe.plesso_id +'/classe/'+ classe.id +'/edit']"><i class="fas fa-edit mr-2"></i>{{ 'BTN.Modifica' | translate }}</a>
          <a class="dropdown-item text-danger" [routerLink]="['/plesso/'+ classe.plesso_id +'/classe/'+ classe.id +'/delete']"><i class="far fa-trash mr-2"></i>{{ 'BTN.cancella_classe' | translate }}</a>
        </div>
      </div> -->
    </div>
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div *ngIf="showInfo" class="card-body">

    <div class="row mb-3">
      <div class="col">
        <small class="card-subtitle">Anno</small>
        <p>{{ classe?.anno || 'Nessun anno'}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Organizzazione</small>
        <p>{{ classe?.google_org_unit?.orgUnitPath || "Nessuna organizzazione associata"}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Creato il</small>
        <p>{{ classe?.created_at | date:'medium' }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <small class="card-subtitle">Email gruppo classe</small>
        <p>{{ groupClasse?.email || "Nessuna gruppo associato"}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Email gruppo docenti</small>
        <p>{{ groupDocenti?.email || "Nessuna gruppo associato"}}</p>
      </div>
      <div class="col">
        <small class="card-subtitle">Email gruppo studenti</small>
        <p>{{ groupStudenti?.email || "Nessuna gruppo associato"}}</p>
      </div>
    </div>

  </div>
  <!-- End Body -->

  <!-- Footer -->
  <div class="card-footer text-center">
    <!-- Stats -->
    <div class="row">

      <div class="col">
        <span class="h4" *ngIf="classe.google_group">{{ classe.google_group.length }}</span>
        <span class="h4" *ngIf="!classe.google_group">0</span>
        <span class="d-block font-size-sm">{{ 'STR.gruppi' | translate }}</span>
      </div>

      <div class="col column-divider">
        <span class="h4" *ngIf="classe.classi">{{ classe.classi.length }}</span>
        <span class="h4" *ngIf="!classe.classi">0</span>
        <span class="d-block font-size-sm">Classi</span>
      </div>
    </div>
    <!-- End Stats -->
  </div>
  <!-- End Footer -->
</div>
