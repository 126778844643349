<p-sidebar *ngIf="getActivityService().showSidebar" [(visible)]="getActivityService().showSidebar" position="right">
  <div class="sidebar sidebar-bordered sidebar-box-shadow">
    <div class="card card-lg sidebar-card sidebar-scrollbar">
      <div class="card-header">
        <h4 class="card-header-title">
          Attività
        </h4>

        <a class="btn btn-icon btn-xs btn-ghost-dark ml-2" (click)="closeSidebar()">
          <i class="fas fa-times"></i>
        </a>
      </div>

      <div class="card-body sidebar-body">

        <!-- Step -->
        <ul class="step">

          <!-- Divisore che indica la data -->
          <li class="step-item">
            <div class="step-content-wrapper d-flex justify-content-between align-items-center">
              <small class="step-divider">{{'ACTIVITY.sidebar.title' | translate}}</small>
              <a class="btn btn-icon btn-sm btn-ghost-primary" (click)="refresh()">
                <i class="fas fa-sync-alt"></i>
              </a>
            </div>
          </li>
          <!-- End Divisore che indica la data -->

          <!-- Step Item -->
          <li class="step-item" *ngFor="let activity of data">
            <div class="step-content-wrapper">
              <div class="step-avatar">
                <!-- <img class="step-avatar-img" src="../assets/theme/img/160x160/img9.jpg"> -->
                <span class="avatar avatar-soft-primary avatar-circle">
                  <span class="avatar-initials">{{ activity.user_name | slice:0:1 }}</span>
                </span>
              </div>

              <div class="step-content">
                <h5 class="mb-1">
                  <a class="text-dark">{{ activity.user_name }}</a>
                </h5>

                <p class="font-size-sm">
                  <span *ngIf="activity.status == 'progress'" class="badge badge-soft-primary badge-pill"><span
                      class="legend-indicator bg-primary"></span>{{'ACTIVITY.sidebar.status.progress.badge' |
                    translate}}</span>
                  <span *ngIf="activity.status == 'cancelled'" class="badge badge-soft-danger badge-pill"><span
                      class="legend-indicator bg-danger"></span>{{'ACTIVITY.sidebar.status.cancelled.badge' |
                    translate}}</span>
                  <span *ngIf="activity.status == 'completed'" class="badge badge-soft-success badge-pill"><span
                      class="legend-indicator bg-success"></span>{{'ACTIVITY.sidebar.status.completed.badge' |
                    translate}}</span>
                </p>

                <p class="font-size-sm">{{ 'ACTIVITY.' + activity.job_batch_name + '.description' | translate }}</p>

                <div class="card my-3 alert-danger" *ngIf="activity.failed_jobs > 0">
                  <div class="card-header">
                    <p class="card-header-title text-danger"
                      [innerHTML]="'STR.errore_google_generico_header' | translate"></p>
                  </div>
                  <div class="card-body" [innerHTML]="'STR.errore_google_generico_body' | translate"></div>
                </div>

                <ul class="list-group">
                  <!-- List Item -->
                  <li class="list-group-item list-group-item-light">
                    <div class="row gx-1">
                      <div class="col">
                        <div class="d-flex align-items-center">
                          <span class="font-size-sm mr-2">{{ activity.perc_overall }}%</span>
                          <!-- Progress -->
                          <div class="progress table-progress">
                            <div class="progress-bar" role="progressbar" [style.width.%]="activity.perc_completed"
                              aria-valuenow="activity.perc_completed" aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="progress-bar bg-danger" role="progressbar"
                              [style.width.%]="activity.perc_failed" aria-valuenow="activity.perc_failed"
                              aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div class="row mt-1 font-size-sm">
                          <div class="col-auto">
                            <span class="legend-indicator bg-primary"></span> {{ activity.job_completed }}/
                            {{ activity.total_jobs }}
                          </div>
                          <div class="col-auto">
                            <span class="legend-indicator bg-danger"></span> {{ activity.failed_jobs }} errori
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row gx-1 mt-3" *ngIf="activity.status == 'progress' && activity.last_update">
                      <div class="col">
                        <div class="media">
                          <span class="mt-2 mr-2">
                            <div class="spinner-grow spinner-grow-sm text-primary" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </span>
                          <div class="media-body text-truncate">
                            <span
                              class="d-block font-size-sm text-dark text-truncate">{{'ACTIVITY.sidebar.status.progress.last_update'
                              | translate}}</span>
                            <small class="d-block text-muted">{{ activity.last_update | date:"medium" }}</small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row gx-1 mt-3" *ngIf="activity.status != 'progress' && activity.failed_jobs > 0">
                      <div class="col">
                        <div class="media">
                          <span class="mt-2 mr-2">
                            <span class="icon icon-xs icon-danger icon-circle">
                              <i class="tio-warning-outlined"></i>
                            </span>
                          </span>
                          <div class="media-body text-truncate">
                            <span
                              class="d-block font-size-sm text-dark text-truncate">{{'ACTIVITY.sidebar.status.progress.failed_jobs'
                              | translate}}</span>
                            <small *ngIf="activity.finished_at_parsed" class="d-block text-muted">{{
                              activity.finished_at_parsed | date:"medium" }}</small>
                            <small *ngIf="activity.cancelled_at_parsed && !activity.finished_at_parsed" class="d-block text-muted">{{
                              activity.cancelled_at_parsed | date:"medium" }}</small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row gx-1 mt-3" *ngIf="activity.status == 'completed' && activity.failed_jobs == 0">
                      <div class="col">
                        <div class="media">
                          <span class="mt-2 mr-2">
                            <span class="icon icon-xs icon-success icon-circle">
                              <i class="tio-done"></i>
                            </span>
                          </span>
                          <div class="media-body text-truncate" *ngIf="activity.finished_at">
                            <span
                              class="d-block font-size-sm text-dark text-truncate">{{'ACTIVITY.sidebar.status.completed.msg'
                              | translate}}</span>
                            <small class="d-block text-muted">{{ activity.finished_at_parsed | date:"medium" }}</small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row gx-1 mt-3" *ngIf="activity.failed_jobs > 0">
                      <div class="col">
                        <button (click)="showActivity(activity)" class="btn btn-xs btn-block btn-outline-danger"><i
                            class="fas fa-bug mr-2"></i> Mostra errori</button>
                      </div>
                    </div>

                    <div class="row gx-1 mt-3" *ngIf="activity.failed_jobs == 0">
                      <div class="col">
                        <button (click)="showActivity(activity)" class="btn btn-xs btn-block btn-outline-primary"><i
                            class="fas fa-magnifying-glass mr-2"></i> Mostra dettagli</button>
                      </div>
                    </div>

                    <div class="row gx-1 mt-3" *ngIf="activity.status == 'progress'">
                      <div class="col">
                        <button (click)="cancelActivity(activity)" class="btn btn-xs btn-block btn-outline-danger"><i
                            class="fas fa-stop-circle mr-2"></i> Annulla l'attività</button>
                      </div>
                    </div>
                  </li>
                  <!-- End List Item -->
                </ul>
              </div>
            </div>
          </li>
          <!-- End Step Item -->

        </ul>
        <!-- End Step -->

      </div>
    </div>
  </div>
</p-sidebar>