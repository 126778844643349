import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { UserService } from 'src/app/services/general/user.service';
import { RemoveUsersFromOrganigrammaService } from 'src/app/services/operations/remove-users-from-organigramma.service';
import { SyncdataService } from 'src/app/services/syncdata.service';
import { OrderTypeList, UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-remove-users-from-organigramma',
  templateUrl: './remove-users-from-organigramma.component.html',
  styleUrls: ['./remove-users-from-organigramma.component.scss']
})
export class RemoveUsersFromOrganigrammaComponent implements OnInit {
  regEx = ConfigService.regExRemove;
  error: any;
  _loadingData = {
    organgirammi: false
  };

  // Organigramma Destination Update User
  organigrammiTreeView: any;

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  organgirammi: any = [];
  data: any = [];
  users: any;

  operationInProgress: boolean = false;
  errorNoGoogleAccount: boolean = false;

  private _removeUserFromOrganigrammaServiceSubscription: Subscription;
  private _organigrammaServiceSubscription: Subscription;
  private _userServiceSubscription: Subscription;


  constructor(
    private utilityService: UtilityService,
    private organigrammaService: OrganigrammaService,
    private removeUserFromOrganigrammaService: RemoveUsersFromOrganigrammaService,
    private userService: UserService,
    private syncdataService: SyncdataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.users = this.removeUserFromOrganigrammaService.items;
    this.data = this.userService.getTable(this.users);
  }

  deleteEvent(rows) {
    this.removeUserFromOrganigrammaService.deleteItemsAtIndex(rows);
  }

  onSubmit() {
    this.operationInProgress = true;

    this.removeUserFromOrganigrammaService.execOperation((error) => {
      if (!error) {
        this.operationInProgress = false;
      }
    });
  }

  resetOperation() {
    this.removeUserFromOrganigrammaService.reset();
  }

  unsubscibeAll() {


    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._removeUserFromOrganigrammaServiceSubscription)
      this._removeUserFromOrganigrammaServiceSubscription.unsubscribe();

    if (this._organigrammaServiceSubscription)
      this._organigrammaServiceSubscription.unsubscribe();



    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
  }

  init() {

    this._removeUserFromOrganigrammaServiceSubscription = this.removeUserFromOrganigrammaService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.dataUpdated();

    this._organigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this._loadingData.organgirammi = false;
        this.organgirammi = result;
        let filtered = result.filter(x => x.google_org_unit && x.google_org_unit.orgUnitId);
        this.organigrammiTreeView = this.utilityService.covertListToTreeViewItems(filtered, "id", "parent_id", "name", "id", null, OrderTypeList.Ascending, "name");
      }
    })

    this._loadingData.organgirammi = true;
    this.organigrammaService.all();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }
}
