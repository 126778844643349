import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { AccountService } from 'src/app/services/account.service';
import { ActivityService } from 'src/app/services/activity.service';
import { AnnouncementService } from 'src/app/services/general/announcement.service';
import { ParametriService } from 'src/app/services/general/parametri.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class HeaderComponent implements OnInit {

  activityData: any;

  isBetaVersion: boolean = false;

  announcementCount: any;

  constructor(
    private activity: ActivityService,
    private changeDetector: ChangeDetectorRef,
    private utility: UtilityService,
    private announcementService: AnnouncementService,
    private accountService: AccountService,
    private parametriService: ParametriService,
  ) { }

  ngOnInit() {

    this.activity.getSubjectToUpdateObservableForEachJob().subscribe((result) => {
      if (result)
        this.updateActivityData(result);
    });

    this.activity.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.updateActivityData(result);
    });

    this.announcementService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.announcementCount = 0;
        result.forEach(announcement => {
          if (announcement.type.includes('danger') || announcement.type.includes('warning')){
            this.announcementCount ++;
          }
        });
      }
      else {
        this.announcementCount = null;
      }
    });

    this.activity.updateActivityData();

    if (window.location.origin.startsWith(environment.betaUrl)) {
      this.isBetaVersion = true;
    }
  }

  ngOnDestroy() {
  }

  updateActivityData(activity) {

    this.activityData = [];

    activity.forEach(element => {
      if (element.status == "progress") {
        this.activityData.push(element);
      }
    });

    this.changeDetector.detectChanges();
  }

  adminVersion() {
    window.location.href = environment.adminUrl;
  }

  showActivitySidebar() {
    this.activity.showSidebar = true;
  }

  showHelpSidebar() {
    this.utility.showHelpSidebar = true;
  }

  getParametriService() {
    return this.parametriService;
  }

  getAccountService() {
    return this.accountService;
  }
}
