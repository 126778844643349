import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @Input() id;

  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();

  @Input() labelText: string;
  @Input() helpText: string;

  constructor() { }

  ngOnInit() {
  }

}
