import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { RelationInfo } from 'src/app/services/general/relation.service';
import { PageService } from 'src/app/services/page.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-organigramma-profile',
  templateUrl: './organigramma-profile.component.html',
  styleUrls: ['./organigramma-profile.component.scss']
})
export class OrganigrammaProfileComponent implements OnInit, OnDestroy {

  id: any;
  id_parent: any;
  showForm: boolean;
  editMode: boolean;
  deleteMode: boolean;
  createMode: boolean;
  type: any;
  relation_info_standard: string;
  showMap: boolean;

  private _activeRouteSubscription: Subscription;
  private _oganigrammaServiceSubscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private utilityService: UtilityService, private activeRoute: ActivatedRoute, private organigrammaService: OrganigrammaService, private pageService: PageService) { }

  ngOnInit() {

    this.unsubscribeAll();

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {

      this.id = routeParams.id_organigramma;

      if (routeParams.id_organigramma_child) {
        this.id = routeParams.id_organigramma_child;
        this.id_parent = routeParams.id_organigramma;
      }

      this.type = routeParams.type;
      this.init();
    });

    this._oganigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.updateData();
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();

    if (this._oganigrammaServiceSubscription)
      this._oganigrammaServiceSubscription.unsubscribe();
  }

  init() {

    this.showMap = false;
    this.showForm = false;
    this.editMode = false;
    this.createMode = false;
    this.deleteMode = false;

    if (this.id && this.id > 0) {

      this.updateData();

      if (this.utilityService.checkRouteActionType(this.route, "edit")) {
        this.showForm = true;
        this.editMode = true;
      }

      if (this.utilityService.checkRouteActionType(this.route, "create")) {
        this.showForm = true;
        this.createMode = true;
      }

      if (this.utilityService.checkRouteActionType(this.route, "delete")) {
        this.showForm = true;
        this.deleteMode = true;
      }
    }
    else {

      if (this.type) {
        switch (this.type) {
          case "1":
            this.relation_info_standard = RelationInfo.RelationInfoStudenti;
            break;
          case "2":
            this.relation_info_standard = RelationInfo.RelationInfoDocenti;
            break;
          default:
            this.relation_info_standard = null;
            break;
        }

        this.showForm = true;
        this.createMode = true;
      }
      else {

        if (this.utilityService.checkRouteActionType(this.route, "create")) {
          this.showForm = true;
          this.createMode = true;
        }
        else {
          this.showMap = true;
        }
      }
    }
  }

  updateData() {

    if (this.id && this.id > 0) {
      this.organigrammaService.get(this.id, (data) => {
        if (!this.id_parent)
          this.pageService.updatePageTitle({ organigramma: data.name });
        else
          this.pageService.updatePageTitle({ organigramma_child: data.name });
      }, (error) => {
        this.organigrammaService.all();
      });
    }

    if (this.id_parent && this.id_parent != this.id && this.id_parent > 0) {
      this.organigrammaService.get(this.id_parent, (data) => {
        this.pageService.updatePageTitle({ organigramma: data.name });
      }, (error) => {
        this.organigrammaService.all();
      });
    }
  }

}
