import { Injectable, NgZone } from '@angular/core';
import { type } from 'os';
import { Observable, Subject, zip } from 'rxjs';
import { AccountService } from '../account.service';
import { CacheService, CacheType } from '../cache.service';
import { UtilityService } from '../utility.service';
import { NgGapiClientConfig } from 'ng-gapi';
@Injectable({
  providedIn: 'root'
})
export class GoogleDirectoryService {

  private baseApiPath = "/admin/directory/v1";
  private domains = [];
  private orgUnits = [];
  private users = [];
  private usersDeleted = [];
  private groups = [];

  private _subjectToUpdateDomains: Subject<any> = new Subject<any>();
  private _subjectToUpdateOrgUnits: Subject<any> = new Subject<any>();
  private _subjectToUpdateUsers: Subject<any> = new Subject<any>();
  private _subjectToUpdateUsersDeleted: Subject<any> = new Subject<any>();
  private _subjectToUpdateGroups: Subject<any> = new Subject<any>();
  private _subjectToUpdateGroupMembers: Subject<any> = new Subject<any>();

  // Non servono per il login ma questo viene usato per verificare che il token (autotenticato con il server horizon) abbia tutti i token necessari
  public scopes_required = [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/admin.directory.group",
    "https://www.googleapis.com/auth/admin.directory.group.member",
    "https://www.googleapis.com/auth/admin.directory.user",
    "https://www.googleapis.com/auth/admin.directory.user.alias",
    "https://www.googleapis.com/auth/admin.directory.user.security",
    "https://www.googleapis.com/auth/admin.directory.userschema",
    "https://www.googleapis.com/auth/admin.directory.domain",
    "https://www.googleapis.com/auth/admin.directory.orgunit",
    "https://www.googleapis.com/auth/classroom.courses",
    "https://www.googleapis.com/auth/classroom.guardianlinks.students",
    "https://www.googleapis.com/auth/classroom.profile.emails",
    "https://www.googleapis.com/auth/classroom.rosters",
    "https://www.googleapis.com/auth/classroom.topics",
    "https://www.googleapis.com/auth/identitytoolkit",
    "https://www.googleapis.com/auth/classroom.coursework.students",
    "https://www.googleapis.com/auth/classroom.coursework.me",
    "https://www.googleapis.com/auth/classroom.courseworkmaterials",
    "https://www.googleapis.com/auth/admin.reports.audit.readonly",
    "https://www.googleapis.com/auth/classroom.announcements",
    "https://www.googleapis.com/auth/apps.groups.settings",
    "https://www.googleapis.com/auth/admin.reports.usage.readonly",
    // "https://www.googleapis.com/auth/drive.file", // Non sono attualmente ancora abilitati ma lo saranno per Alfred
    // "https://www.googleapis.com/auth/drive.metadata.readonly"
  ];

  constructor(
    private accountService: AccountService,
    private ngZone: NgZone,
    private cacheService: CacheService,
    private utility: UtilityService
    ) { }

  public getSubjectToUpdateDomainsObservable(): Observable<any> {
    return this._subjectToUpdateDomains.asObservable();
  }

  public getSubjectToUpdateOrgUnitsObservable(): Observable<any> {
    return this._subjectToUpdateOrgUnits.asObservable();
  }

  public getSubjectToUpdateUsersObservable(): Observable<any> {
    return this._subjectToUpdateUsers.asObservable();
  }

  public getSubjectToUpdateUsersDeletedObservable(): Observable<any> {
    return this._subjectToUpdateUsersDeleted.asObservable();
  }

  public getSubjectToUpdateGroupsObservable(): Observable<any> {
    return this._subjectToUpdateGroups.asObservable();
  }

  public getSubjectToUpdateGroupMembersObservable(): Observable<any> {
    return this._subjectToUpdateGroupMembers.asObservable();
  }

  public tokenInfo(userKey: any, apiConfig: NgGapiClientConfig, callbackOk = (result: any) => { }, callbackError = (error: any) => { }) {

    gapi.client.request({
      path: this.baseApiPath + "/users/userKey/tokens/clientId",
      method: "GET",
      params: {
        userKey: userKey,
        clientId: apiConfig.client_id
      },
    }).then((response) => {
      this.ngZone.run(() => {
        callbackOk(response.result);
      });
    }, (response) => {
      this.ngZone.run(() => {
        this.handleError(response, callbackError);
      });
    });
  }

  public domainsList(callbackOk = (result: any) => { }, callbackError = (error: any) => { }) {

    if (this.cacheService.isCacheValid(CacheType.GoogleDomains)) {
      let dataCloned = this.utility.cloneData(this.domains);
      this._subjectToUpdateDomains.next(dataCloned);
      callbackOk(dataCloned);
      return;
    }

    if (!this.accountService.isGoogleWorkspaceAccount()){
      this._subjectToUpdateDomains.next([]);
      callbackOk([]);
      return;
    }

    gapi.client.request({
      path: this.baseApiPath + "/customer/customer/domains",
      method: "GET",
      params: {
        customer: "my_customer"
      },
    }).then((response) => {
      this.ngZone.run(() => {
        this.domains = response.result.domains;
        this.cacheService.updateCacheStatus(CacheType.GoogleDomains, true);
        let dataCloned = this.utility.cloneData(this.domains);
        this._subjectToUpdateDomains.next(dataCloned);
        callbackOk(dataCloned);
      });
    }, (response) => {
      this.ngZone.run(() => {
        this._subjectToUpdateDomains.next(null);
        this.handleError(response, callbackError);
      });
    });
  }

  checkEmailDomains(email) {

    if (!email)
      return false;

    let domain = email.split('@')[1];

    if (domain) {
      for (let index = 0; index < this.domains.length; index++) {
        if (domain == this.domains[index].domainName)
          return true;
      }
    }

    return false;
  }

  /**
  * Recupera OrgUnits
  */
  public orgunitsList(callbackOk = (result: any) => { }, callbackError = (error: any) => { }) {

    if (this.cacheService.isCacheValid(CacheType.GoogleOrgUnit)) {
      let dataCloned = this.utility.cloneData(this.orgUnits);
      this._subjectToUpdateOrgUnits.next(dataCloned);
      callbackOk(dataCloned);
      return;
    }

    if (!this.accountService.isGoogleWorkspaceAccount()){
      this._subjectToUpdateOrgUnits.next([]);
      callbackOk([]);
      return;
    }

    gapi.client.request({
      path: this.baseApiPath + "/customer/customerId/orgunits",
      method: "GET",
      params: {
        customerId: "my_customer",
        type: "all"
      },
    }).then((response) => {
      this.ngZone.run(() => {
        this.orgUnits = response.result.organizationUnits;

        // eventuali attivazioni vuote devo sempre verificare che sia un array vuoto
        if (!this.orgUnits) {
          this.orgUnits = [];
        }

        this.cacheService.updateCacheStatus(CacheType.GoogleOrgUnit, true);
        let dataCloned = this.utility.cloneData(this.orgUnits);
        this._subjectToUpdateOrgUnits.next(dataCloned);
        callbackOk(dataCloned);
      });
    }, (response) => {
      this.ngZone.run(() => {
        this._subjectToUpdateOrgUnits.next(null);
        this.handleError(response, callbackError);
      });
    });
  }

  /**
   * Recupero OrgUnit by orgUnitId
   * @param orgUnitId
   */
  public getOrgUnit(orgUnitId: string) {
    let orgUnit = this.orgUnits.find(x => x.orgUnitId == orgUnitId);
    let dataCloned = this.utility.cloneData(orgUnit);
    return dataCloned;
  }

  public getOrgUnitByPath(orgUnitPath: string) {
    let orgUnit = this.orgUnits.find(x => x.orgUnitPath.toLowerCase() == orgUnitPath.toLowerCase());
    let dataCloned = this.utility.cloneData(orgUnit);
    return dataCloned;
  }

  /**
   * Controlla se negli utenti attuali esiste un utente con la mail data
   * @param email
   */
  public checkUserExistByEmail(email: string, google_id = null) {
    let user = this.users.find(x => x.primaryEmail.toLowerCase() == email.toLowerCase());
    if (typeof user == "undefined")
      return false;

    if (user && google_id && user.id == google_id)
      return false;

    return true;
  }

  /**
   * Controlla se negli utenti attuali esiste un utente con la mail data
   * @param email
   */
  public checkUserExistById(id: string) {
    if (typeof this.users.find(x => x.id == id) != "undefined")
      return true;
    return false;
  }

  public checkUserHasExternalIdValue(user, externalIdValue: string) {
    if (user.externalIds) {
      for (let index = 0; index < user.externalIds.length; index++) {
        let element = user.externalIds[index];
        if (element.value == externalIdValue) {
          return true;
        }
      }
    }
    return false;
  }

  public usersList(callbackOk = (result: any) => { }, callbackError = (error: any) => { }, callCallbackOkEachPage: boolean = false) {

    if (this.cacheService.isCacheValid(CacheType.GoogleUsers)) {
      let dataCloned = this.utility.cloneData(this.users);
      this._subjectToUpdateUsers.next(dataCloned);
      callbackOk(dataCloned);
      return;
    }

    if (!this.accountService.isGoogleWorkspaceAccount()){
      this._subjectToUpdateUsers.next([]);
      callbackOk([]);
      return;
    }

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + "/users",
      method: "GET",
      params: {
        customer: "my_customer",
        maxResults: 500
      },
    };

    this.listPaging(args, "users", (result) => {
      this.ngZone.run(() => {
        this.users = result;
        this.cacheService.updateCacheStatus(CacheType.GoogleUsers, true);
        let dataCloned = this.utility.cloneData(this.users);
        this._subjectToUpdateUsers.next(dataCloned);
        callbackOk(dataCloned);
      });
    }, (error) => {
      this.ngZone.run(() => {
        this._subjectToUpdateUsers.next(null);
        callbackError(error);
      });
    }, "users", null, callCallbackOkEachPage);
  }

  public usersDeletedList(callbackOk = (result: any) => { }, callbackError = (error: any) => { }, callCallbackOkEachPage: boolean = false) {

    if (this.cacheService.isCacheValid(CacheType.GoogleUsersDeleted)) {
      let dataCloned = this.utility.cloneData(this.usersDeleted);
      this._subjectToUpdateUsersDeleted.next(dataCloned);
      callbackOk(dataCloned);
      return;
    }

    if (!this.accountService.isGoogleWorkspaceAccount()){
      this._subjectToUpdateUsersDeleted.next([]);
      callbackOk([]);
      return;
    }

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + "/users",
      method: "GET",
      params: {
        customer: "my_customer",
        maxResults: 500,
        showDeleted: "true"
      },
    };

    this.listPaging(args, "users", (result) => {
      this.ngZone.run(() => {
        this.usersDeleted = result;
        this.cacheService.updateCacheStatus(CacheType.GoogleUsersDeleted, true);
        let dataCloned = this.utility.cloneData(this.usersDeleted);
        this._subjectToUpdateUsersDeleted.next(dataCloned);
        callbackOk(dataCloned);
      });
    }, (error) => {
      this.ngZone.run(() => {
        this._subjectToUpdateUsersDeleted.next(null);
        callbackError(error);
      });
    }, "users", null, null, callCallbackOkEachPage);
  }

  /**
   * Recupera uno specifico utente Google
   * @param userKey
   * @param callbackOk
   * @param callbackError
   */
  public usersGet(userKey, callbackOk = (result: any) => { }, callbackError = (error: any) => { }) {

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + `/users/${userKey}`,
      method: "GET",
      params: {
        customer: "my_customer",
      }
    };

    let request = gapi.client.request(args);
    request.execute((response) => {
      this.ngZone.run(() => {
        // se c'è un errore lo gestisco e blocco le chiamate
        if (response.error) {
          let res: any = { result: response };
          this.handleError(res, callbackError);
          return;
        }

        let userFindedIndex = this.users.findIndex(x => x.id == response.id);
        if (userFindedIndex >= 0) {
          this.users[userFindedIndex] = response;
        } else {
          this.users.push(response);
        }

        let dataCloned = this.utility.cloneData(this.users);
        this._subjectToUpdateUsers.next(dataCloned);
        let dataCloned2 = this.utility.cloneData(response);
        callbackOk(dataCloned2);
      });

    });

  }

  public getUser(google_id: string) {
    let u = this.users.find(x => x.id == google_id);
    let dataCloned = this.utility.cloneData(u);
    return dataCloned;
  }

  public getUserByEmail(primaryEmail: string) {
    let u = this.users.find(x => x.primaryEmail.toLowerCase() == primaryEmail.toLowerCase());
    let dataCloned = this.utility.cloneData(u);
    return dataCloned;
  }

  public getUsersByExternalIdValue(externalIdValue: string) {
    let users = this.users.filter((value, idx) => {
      if (value.externalIds) {
        for (let index = 0; index < value.externalIds.length; index++) {
          let element = value.externalIds[index];
          if (element.value == externalIdValue) {
            return true;
          }
        }
      }
    })
    let dataCloned = this.utility.cloneData(users);
    return dataCloned;
  }

  public getUserExternalIdValue(google_user: any) {

    if (google_user.externalIds) {
      for (let index = 0; index < google_user.externalIds.length; index++) {
        let element = google_user.externalIds[index];
        if (element.type == 'organization') {
          return element.value;
        }
      }
    }

    return null;
  }

  public getUsersByOrgUnitPath(orgUnitPath: string) {
    let users = this.users.filter((value, idx) => {
      if (value.orgUnitPath == orgUnitPath) {
        return true;
      }
    })
    let dataCloned = this.utility.cloneData(users);
    return dataCloned;
  }

  public getUsersByOrgUnitPathStartWith(orgUnitPath: string) {
    let users = this.users.filter((value, idx) => {
      if (value.orgUnitPath == orgUnitPath || value.orgUnitPath.startsWith(orgUnitPath + '/')) {
        return true;
      }
    })
    let dataCloned = this.utility.cloneData(users);
    return dataCloned;
  }

  public groupsList(callbackOk = (result: any) => { }, callbackError = (error: any) => { }, callCallbackOkEachPage: boolean = false) {

    if (this.cacheService.isCacheValid(CacheType.GoogleGroups)) {
      let dataCloned = this.utility.cloneData(this.groups);
      this._subjectToUpdateGroups.next(dataCloned);
      callbackOk(dataCloned);
      return;
    }

    if (!this.accountService.isGoogleWorkspaceAccount()){
      this._subjectToUpdateGroups.next([]);
      callbackOk([]);
      return;
    }

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + "/groups",
      method: "GET",
      params: {
        customer: "my_customer",
        maxResults: 200
      },
    };

    this.listPaging(args, "groups", (result) => {
      this.ngZone.run(() => {
        this.groups = result;
        this.cacheService.updateCacheStatus(CacheType.GoogleGroups, true);
        let dataCloned = this.utility.cloneData(this.groups);
        this._subjectToUpdateGroups.next(dataCloned);
        callbackOk(dataCloned);
      });
    }, (error) => {
      this.ngZone.run(() => {
        this._subjectToUpdateGroups.next(null);
        callbackError(error);
      });
    }, "groups", null, null, callCallbackOkEachPage);
  }

  public userGroupsList(userKey, callbackOk = (result: any) => { }, callbackError = (error: any) => { }, callCallbackOkEachPage: boolean = false) {

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + "/groups",
      method: "GET",
      params: {
        //  Bug di Google
        // customer: "my_customer",
        userKey: userKey,
        maxResults: 200
      },
    };

    this.listPaging(args, "groups", (result) => {
      this.ngZone.run(() => {
        callbackOk(result);
      });
    }, (error) => {
      this.ngZone.run(() => {
        callbackError(error);
      });
    }, null, null, null, callCallbackOkEachPage);
  }

  public groupMembersList(groupKey, callbackOk = (result: any) => { }, callbackError = (error: any) => { }, callCallbackOkEachPage: boolean = false) {

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + "/groups/groupKey/members",
      method: "GET",
      params: {
        groupKey: groupKey,
        maxResults: 200
      },
    };

    this.listPaging(args, "members", (result) => {
      this.ngZone.run(() => {
        this._subjectToUpdateGroupMembers.next(result);
        callbackOk(result);
      });
    }, (error) => {
      this.ngZone.run(() => {
        this._subjectToUpdateGroupMembers.next(null);
        callbackError(error);
      });
    }, "members", null, null, callCallbackOkEachPage);
  }

  /**
   * Recupero Gruppo by Email
   * @param email
   */
  public getGroupByEmail(email: string) {
    let g = this.groups.find(x => x.email.toLowerCase() == email.toLowerCase());

    if (!g) {
      g = this.groups.find(x => {
        if (x.aliases?.includes(email.toLowerCase()))
          return x;
      });
    }

    let dataCloned = this.utility.cloneData(g);
    return dataCloned;
  }

  /**
   * Recupero Gruppo by groupKey
   * @param groupKey
   */
  public getGroupByGroupKey(groupKey: string) {
    let g = this.groups.find(x => x.id == groupKey);
    let dataCloned = this.utility.cloneData(g);
    return dataCloned;
  }

  /**
   * Utility per gestire il paging di una chiamata List
   * @param args
   * @param callbackOk
   * @param callbackError
   * @param cachePropertyName Nome della variabile di classe che conserva la cache del risultato
   * @param pageToken
   */
  private listPaging(args, resultPropertyName, callbackOk = (result: any) => { }, callbackError = (error: any) => { }, cachePropertyName = null, previousResultData = null, pageToken = null, callCallbackOkEachPage: boolean = false) {
    if (pageToken)
      args.params.pageToken = pageToken;

    let request = gapi.client.request(args);

    if (!previousResultData)
      previousResultData = []

    let list = previousResultData;

    request.execute((response) => {
      this.ngZone.run(() => {
        // se c'è un errore lo gestisco e blocco le chiamate
        if (response.error) {
          let res: any = { result: response };
          this.handleError(res, callbackError);
          return;
        }

        if (response[resultPropertyName])
          list = list.concat(response[resultPropertyName]);

        if (callCallbackOkEachPage) {
          let dataCloned = this.utility.cloneData(list);
          callbackOk(dataCloned);
        }

        // se c'è un'altra pagina chiamo ricorsivamente la funzione
        if (response.nextPageToken) {
          this.listPaging(args, resultPropertyName, callbackOk, callbackError, cachePropertyName, list, response.nextPageToken, callCallbackOkEachPage);
        }
        else {
          // solo quando le pagine sono finite chiamo la callback
          let dataCloned = this.utility.cloneData(list);
          callbackOk(dataCloned);
        }
      });
    });
  }

  /**
   * Gestisce l'errore delle chiamate a google api, monitorando l'errore 401 Unoutenticated ed effettua il refresh del token con il backend
   * Solo dopo aver fatto il refresh del token restituisce l'errore
   * @param response
   * @param callbackError
   */
  private handleError(response: gapi.client.HttpRequestRejected, callbackError = (error: any) => { }) {

    if (response.result.error.code == 401) {

      console.error("Google Access Token scaduto, refresh token...");

      this.accountService.refreshUserData((user) => {

        gapi.client.setToken({ 'access_token': user.google_access_token.token });

        callbackError(response.result.error);

      }, () => {
        callbackError(response.result.error);
      });
    }
    else {
      callbackError(response.result.error);
    }
  }

}
