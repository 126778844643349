<div class="row mb-5">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body m-2">
        <app-setup-wizard-steps [currentStep]="1"></app-setup-wizard-steps>
      </div>
    </div>
  </div>
</div>

<div class="row mb-5" [hidden]="uploadedFileCompleted">
  <div class="col">
    <!-- Card -->
    <div class="card card-hover-shadow">
      <div class="card-body card-body-centered card-dashed-body">
        <p-fileUpload #fileUpload name="file" [url]="fileUploadConfig.url" chooseLabel="Carica un file"
          [maxFileSize]="1000000" [fileLimit]="1" [multiple]="false"
          [accept]="'.csv, .xls, .xlsx, text/csv, text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
          [auto]="true" [withCredentials]="true" (onSelect)="onSelectFile($event)" (onError)="onUploadError($event)">
          <ng-template pTemplate="content" let-files>
            <div style="text-align: center;" *ngIf="files.length == 0">
              <img class="avatar avatar-xl avatar-4by3 mb-3" src="../assets/theme/svg/illustrations/browse.svg"
                alt="Image Description">
              <h5>Trascina qui un file</h5>
            </div>
          </ng-template>
        </p-fileUpload>
      </div>
    </div>
    <!-- End Card -->
  </div>
</div>

<div class="row" *ngIf="fileName && uploadedFileCompleted">
  <div class="col">
    <!-- Card -->
    <div class="card card-hover-shadow">
      <div class="card-body">

        <div class="row">
          <div class="col">
            <ul class="list-group">
              <!-- List Item -->
              <li class="list-group-item">
                <div class="row align-items-center gx-2">
                  <div class="col-auto">
                    <img class="avatar avatar-xs avatar-4by3" src="./assets/theme/svg/brands/google-sheets.svg">
                  </div>

                  <div class="col">
                    <h5 class="mb-0">
                      <a class="text-dark" href="#">{{ fileName }}</a>
                    </h5>
                    <ul class="list-inline list-separator small">
                      <li class="list-inline-item">Ultima modifica: {{ fileLastModified | date }}</li>
                      <li class="list-inline-item">Dimensione file: {{ fileSize }}</li>
                    </ul>
                  </div>

                  <div class="col-auto">
                    <button class="btn btn-sm btn-outline-danger mr-2" (click)="removedFile()">
                      <i class="far fa-trash mr-2"></i>{{ 'BTN.Cancella' | translate }}
                    </button>
                    <button class="btn btn-sm btn-outline-primary" (click)="saveFile()">
                      Salva JSON file
                    </button>
                  </div>
                </div>
                <!-- End Row -->
              </li>
              <!-- End List Item -->
            </ul>
          </div>
        </div>

        <ng-container *ngIf="headerSelectionData">

          <div class="row mt-5">
            <div class="col">
              <h2>Seleziona la riga di intestazione del foglio, quella che riporta i nomi delle colonne</h2>
              <h4>Scegli il numero della riga aiutandoti con l'anteprima a destra</h4>
            </div>
          </div>

          <div class="form-group row mt-5">
            <div class="col">
              <label class="input-label" for="selectHeaderRow">Riga di intestazione</label>
              <select id="selectHeaderRow" name="selectHeaderRow" class="form-control custom-select"
                [(ngModel)]="selectedHeader" (change)="selectedHeaderChange()">
                <option *ngFor="let val of headerSelectionData; let i = index;" [value]="i">Riga {{i+1}}</option>
              </select>
            </div>
            <div class="col-7">
              <div class="alert alert-soft-info" role="alert">
                <b class="text-cap">Anteprima riga</b>
                <span class="font-size-1" *ngIf="selectedHeader >= 0">
                  <ul class="list-inline list-separator">
                    <li *ngFor="let headerVal of headerSelectionData[selectedHeader] | slice:0:13"
                      class="list-inline-item">
                      {{headerVal}}
                    </li>
                    <li class="list-inline-item">
                      ...
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>

          <div class="form-group row mt-5">
            <div class="col">
              <label class="input-label">{{'SETUP.users.label_tipologia' | translate}}</label>
              <select #userType="ngModel" id="userType" name="userType" class="form-control custom-select"
                [(ngModel)]="userTypeSelected" required [ngClass]="userTypeSelected ? 'is-valid': 'is-invalid'">
                <option *ngFor="let val of userTypeSelect; let i = index;" [value]="val.key">{{val.value}}</option>
              </select>
              <div class="valid-feedback" *ngIf="userTypeSelected">Valido</div>
              <div class="text-muted font-size-1 mt-1">{{'SETUP.users.msg_tipologia' | translate}}</div>
            </div>
            <div class="col-7">
              <div class="alert alert-soft-info" role="alert">
                <b class="text-cap">Info</b>
                <span [innerHTML]="'SETUP.users.msg_info_tipologia' | translate"></span>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col">
              <h2>Filtra le righe del file</h2>
              <p>Seleziona la colonna e deseleziona i valori da escludere, quelle righe non verranno elaborate</p>
            </div>
          </div>

          <!-- Filtro -->
          <div class="form-group row mt-2">
            <div class="col">
              <label class="input-label">{{'SETUP.config.label_filtro' | translate}}
                <button *ngIf="filtroColIndex !== null && filtroColIndex >= 0"
                  class="ml-1 btn btn-ghost-danger btn-xs float-right mb-1" (click)="filtroColIndex = null"><i
                    class="fas fa-backspace"></i></button>
              </label>
              <select id="selectFiltroCol" [disabled]="selectedHeader === null" name="selectFiltroCol"
                class="form-control custom-select" [(ngModel)]="filtroColIndex" #filtroCol="ngModel"
                (change)="filtroColIndexChange()">
                <option>-- Nessun filtro</option>
                <option *ngFor="let val of headerRow; let i = index;" [value]="i">{{val}}</option>
              </select>
              <div class="valid-feedback" *ngIf="userTypeSelected">Valido</div>
              <div class="text-muted font-size-1 mt-1">{{'SETUP.config.msg_filtro' | translate}}</div>

              <button *ngIf="filtroColIndex !== null && filtroColIndex >= 0" class="btn btn-outline-danger btn-xs mt-2"
                (click)="filtroColIndex = null"><i class="fas fa-backspace mr-2"></i> Cancella il filtro</button>
            </div>
            <div class="col-7">
              <div class="alert alert-soft-info" role="alert" *ngIf="filtroColIndex !== null && filtroColIndex >= 0">
                <b class="text-cap">Anteprima valori colonna</b>
                <span class="font-size-1">
                  <ul class="list-inline list-separator">
                    <ng-container *ngFor="let row of data | slice:selectedHeader+1:5; let index = index">
                      <li *ngIf="row[filtroColIndex] != null && ('' + row[filtroColIndex]).length > 0"
                        class="list-inline-item">
                        {{row[filtroColIndex]}}
                      </li>
                    </ng-container>
                  </ul>
                </span>
              </div>
            </div>
          </div>

          <div class="form-group row mt-2" *ngIf="filtroColIndex != null">
            <div class="col">
              <label class="input-label">{{'SETUP.config.label_filtro_value' | translate}}</label>
              <p-multiSelect [style]="{width: '100%'}" [options]="paramFiltroColOptions" [resetFilterOnHide]="true"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="paramFiltroColOptionsSelected" optionLabel="name"
                maxSelectedLabels="0" selectedItemsLabel="{0} elementi selezionati"></p-multiSelect>
            </div>
            <div class="col-7">
              <div class="alert alert-soft-warning" role="alert" *ngIf="paramFiltroColOptionsSelected.length > 0">
                <b class="text-cap">Attenzione</b>
                <span [innerHTML]="'SETUP.config.msg_info_filtro' | translate"></span>
              </div>
              <div class="alert alert-soft-danger" role="alert" *ngIf="paramFiltroColOptionsSelected.length == 0">
                <b class="text-cap">Attenzione</b>
                <span [innerHTML]="'SETUP.config.msg_error_filtro' | translate"></span>
              </div>
            </div>
          </div>

        </ng-container>

      </div>
      <div class="card-footer">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto py-1">
            <button (click)="back()" type="button" class="btn btn-white" [hidden]="fileName && uploadedFileCompleted">
              <i class="far fa-chevron-left mr-1"></i> Indietro
            </button>
          </div>
          <div class="col-auto py-1">
            <button
              [disabled]="selectedHeader == null || selectedHeader < 0 || !userTypeSelected || (filtroColIndex != null && paramFiltroColOptionsSelected.length == 0)"
              (click)="next()" type="button" class="btn btn-primary">
              Avanti <i class="far fa-chevron-right ml-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Card -->
  </div>
</div>
