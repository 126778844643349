import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../backend.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';
import * as XLSX from 'xlsx';
import { GoogleDirectoryService } from '../google/google-directory.service';

@Injectable({
  providedIn: 'root'
})
export class UpdatePasswordService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  items: any[];
  lastItems: any[];

  constructor(
    private backend: BackendService,
    private toast: ToastService,
    private utilityService: UtilityService,
    private googleDirectoryService: GoogleDirectoryService
    ) {
    this.reset();
  }

  reset() {
    this.items = [];
    this._subjectToUpdate.next(this.items);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  addUsers(newItems) {

    newItems.forEach(item => {
      let itemFound = this.items.find((element) => element.user?.id == item.user?.id);

      if (!itemFound) {
        this.items.push(item);
      }
    });

    this._subjectToUpdate.next(this.items);
  }

  deleteItemsAtIndex(indexArray) {

    indexArray.forEach(idx => {
      this.items[idx] = null;
    });

    this.items = this.items.filter((el) => { return el != null });

    this._subjectToUpdate.next(this.items);
  }

  execOperation(info, callback = (error) => { }) {

    let users = [];
    this.lastItems = [];

    this.items.forEach(user => {

      // aggiorno l'utente prima di inviarlo
      if (user.google_id) {
        user.google_user = this.googleDirectoryService.getUser(user.google_id);
      }

      // aggiungo solo quelli che hanno un google user altrimenti il job potrebbe andare in errore
      if (user.google_user) {

        let newPassword = null;

        if (info.gestPassword) {
          newPassword = info.password;

          if (info.generatePassword)
            newPassword = this.utilityService.generateRandomPassword(12);
        }

        users.push({
          id: user.user_id,
          password: newPassword,
          changePasswordAtNextLogin: info.changePasswordAtNextLogin
        });

        this.lastItems.push({
          id: user.user_id,
          google_id: user.google_user.id,
          orgUnitPath: user.google_user.orgUnitPath,
          nome: user.google_user.name.givenName,
          cognome: user.google_user.name.familyName,
          email: user.google_user.primaryEmail,
          recoveryEmail: user.google_user.recoveryEmail,
          recoveryPhone: user.google_user.recoveryPhone,
          password: newPassword
        });
      }
    });

    this.backend.post("op/update-users-password", { users: users }).subscribe((result) => {

      this.reset();

      this.toast.success('Aggiornamento password avviato');

      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }

  downloadLastItems() {

    let filename = "password_aggiornate_" + (new Date().getTime());

    let wb = XLSX.utils.book_new();
    let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.lastItems);
    XLSX.utils.book_append_sheet(wb, ws, "Geniusuite");
    XLSX.writeFile(wb, filename + ".xlsx");
  }

}
