import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
@Component({
  selector: 'app-organigramma-profile-datatable-card',
  templateUrl: './organigramma-profile-datatable-card.component.html',
  styleUrls: ['./organigramma-profile-datatable-card.component.scss']
})
export class OrganigrammaProfileDatatableCardComponent implements OnInit {
  @Input() id: any;

  organigramma: any;
  dataOrganigrammi: any;

  usersData: any;
  users: any;
  usersError: any;
  groups: any;
  groupsData: any;
  groupsError: any;
  classroom: any;
  classroomError: any;
  organigrammi: any;
  organigrammiError: any;
  usersOrgUnitData: any;
  usersOrgUnit: any;
  usersOrgUnitError: any;

  isLoadingUsers: boolean;
  isLoadingGroups: boolean;
  isLoadingClassroom: boolean;
  isLoadingOrganigramma: boolean;
  isLoadingOrganigrammaChildren: boolean;
  isLoadingUsersOrgUnit: boolean;

  currentTab: number;

  private _userServiceSubscription: Subscription;
  private _googleServiceGroupsSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;
  private _oganigrammaServiceSubscription: Subscription;


  constructor(
    private organigrammaService: OrganigrammaService,
    private googleDirectoryService: GoogleDirectoryService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.unsubscribeAll();
    this.users = [];
    this.organigrammi = [];
    this.groups = [];
    this.usersOrgUnit = [];

    this._oganigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.getBackendData();
    });

    if (this.id) {
      this.isLoadingOrganigramma = true;
      this.isLoadingOrganigrammaChildren = true;
      this.isLoadingGroups = true;
      this.getBackendData();
    }

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.usersUpdated();
      }
    });

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe(
      (result) => {
        if (result) {
          this.userService.all();
          this.usersOrgUnitUpdated();
        }
      });

    this._googleServiceGroupsSubscription = this.googleDirectoryService.getSubjectToUpdateGroupsObservable().subscribe(
      (result) => {
        if (result)
          this.organigrammaService.all();
      });

    this.selectTab(1);
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  getBackendData() {

    if (this.id && this.id > 0) {

      this.organigrammaService.get(this.id, (data) => {
        this.isLoadingOrganigrammaChildren = true;
        this.isLoadingGroups = true;
        this.organigramma = data;
        this.organigrammaChildredUpdated(this.organigramma.children);
        this.groupsUpdated(this.organigramma.google_group);
        this.usersOrgUnitUpdated();
        this.isLoadingOrganigramma = false;
      }, (error) => {
        this.organigrammaService.all();
      });
    }
  }

  selectTab(index) {

    this.currentTab = index;

    switch (index) {
      case 1:
        this.isLoadingUsers = true;
        this.userService.all();
        break;
      case 2:
        this.isLoadingGroups = true;
        this.organigrammaService.all();
        break;
      case 3:
        this.isLoadingOrganigrammaChildren = true;
        this.organigrammaService.all();
        break;
      default:
        break;
    }
  }

  usersUpdated() {
    this.usersData = this.userService.getByOrganigramma(this.id);
    this.users = this.userService.getTable(this.usersData);
    this.isLoadingUsers = false;
  }

  usersOrgUnitUpdated() {

    if (this.organigramma.google_org_unit && this.organigramma.google_org_unit.orgUnitPath) {
      this.usersOrgUnitData = this.googleDirectoryService.getUsersByOrgUnitPath(this.organigramma.google_org_unit.orgUnitPath);
      this.usersOrgUnit = this.userService.getTable(this.usersOrgUnitData, "orgunit");
    }
    else {
      this.usersOrgUnitData = [];
      this.usersOrgUnit = [];
    }

    this.isLoadingUsersOrgUnit = false;
  }

  groupsUpdated(result) {
    this.groupsData = result;
    this.groups = this.parseGroupsResult(result);
    this.isLoadingGroups = false;
  }

  organigrammaChildredUpdated(result) {
    this.dataOrganigrammi = result;
    this.organigrammi = this.parseOrganigrammiChildrenResult(this.dataOrganigrammi);
    this.isLoadingOrganigrammaChildren = false;
  }

  classroomUpdated(result) {
    this.classroom = this.parseClassroomResult(result);
  }

  parseGroupsResult(result) {

    let table = [];

    result.forEach(element => {

      table.push({
        "nome": [element.name, element.description],
        "email": [element.email],
        "membri": [element.directMembersCount],
        "_export_data": {
          name: element.name,
          description: element.description,
          email: element.email,
        },
      })
    });

    return table;
  }

  parseOrganigrammiChildrenResult(result) {
    let table = [];

    result.forEach(element => {

      let groups = [];

      if (element.google_group?.length > 0) {

        groups.push(element.google_group.length + " gruppi associati");

        element.google_group.forEach(group => {
          switch (group.relation_info) {
            default:
              groups.push("<b>Gruppo: </b>" + group.email);
              break;
          }
        });
      }

      table.push({
        "Nome organigramma": [element.name, element.description],
        "gruppi": groups,
        "org_unit": [element.google_org_unit?.name, element.google_org_unit?.orgUnitPath],
        "_export_data": {
          name: element.name,
          description: element.description
        },
      })
    });

    return table;
  }

  parseClassroomResult(result) {
    return;
  }

  unsubscribeAll() {
    if (this._oganigrammaServiceSubscription)
      this._oganigrammaServiceSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();

    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();
  }

  rowClickUser(data) {
    let user = this.usersData[data.index];
    this.router.navigate(['user/' + user.user_id], { relativeTo: this.route });
  }

  addClickUser() {

    let role = "worker";
    let role_organigramma = this.organigrammaService.getRoot(this.organigramma.id);

    if (role_organigramma.relation_info == "docenti") {
      role = "docente";
    }

    if (role_organigramma.relation_info == "studenti") {
      role = "studente";
    }

    this.router.navigate(['user/add/' + role], { relativeTo: this.route });
  }

  rowClickGroup(data) {
    let group = this.organigramma.google_group[data.index];
    this.router.navigate(['group/' + group.groupKey]);
  }

  rowClickUserOrgUnit(data) {
    let userOrgUnit = this.usersOrgUnitData[data.index];
    let user = this.userService.getByGoogleId(userOrgUnit.id);

    if (user) {
      this.router.navigate(['user/' + user.user_id], { relativeTo: this.route });
    }
    else {
      this.router.navigate(['user/google/' + userOrgUnit.id], { relativeTo: this.route });
    }
  }

  rowClickOrganigramma(data) {
    let organigramma = this.dataOrganigrammi[data.index];

    if (organigramma.parent_id)
      this.router.navigate(['organigramma/' + organigramma.parent_id + '/organigramma/' + organigramma.id]);
    else
      this.router.navigate(['organigramma/' + organigramma.id]);
  }

  addClickOrganigramma() {
    this.router.navigate(['/organigramma/' + this.organigramma.id + '/organigramma/create']);
  }
}
