import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { ParametriService } from 'src/app/services/general/parametri.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  showBannerCompleteProfile: boolean = false;

  constructor(
    private parametriService: ParametriService,
    private accountService: AccountService
  ) { }

  ngOnInit() {

    this.showBannerCompleteProfile = !this.accountService.isProfileCompleted();
  }

  getParametriService() {
    return this.parametriService;
  }

  getAccountService() {
    return this.accountService;
  }

}
