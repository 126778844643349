<div class="row mb-3">
  <div class="col-12">
    <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" validate>
      <!-- Card -->
      <div class="card card-hover-shadow">
        <div class="card-body">

          <h3 class="card-title"><i
              class="fas fa-user-unlock mr-2"></i>{{ 'STR.richieste_cambio_password' | translate }}</h3>
          <p class="card-text">Qui sono raccolte le richieste di cambio password effettuate dagli utenti.</p>

          <p *ngIf="link?.link">
            <button type="button" class="btn btn-outline-primary mr-3" ngxClipboard [cbContent]="link.link"
              (click)="copyLink()">
              <i class="fas fa-copy mr-2"></i>{{link.link}}
            </button>
            <a class="btn btn-outline-primary" [href]="link.link" target="_blank">
              <i class="fas fa-external-link mr-2"></i>Apri
            </a>
          </p>

          <div *ngIf="!link" class="alert alert-soft-warning mt-2 mb-0">
            <span class="text-cap d-block">Funzionalità non abilitata</span>
            Puoi richiedere l'abilitazione scrivendo una mail a info@geniusuite.com o nella chat in basso.
          </div>

        </div>

        <div class="card-footer">
          <div class="row justify-content-between align-items-center">
            <div class="col-auto py-1">
              <small class="text-body">Ultimo aggiornamento: {{ last_update | date:"medium" }}</small>
            </div>

            <div class="col-auto py-1">
              <button (click)="forceUpdate()" class="btn btn-xs btn-outline-primary">Aggiorna la lista</button>
            </div>
          </div>
        </div>
      </div>
      <!-- End Card -->
    </form>
  </div>
</div>


<div class="row">
  <div class="col-md-12 col-sm">

    <div class="card">
      <!-- Header -->
      <div class="card-header p-1">
        <div class="row justify-content-between align-items-center flex-grow-1">
          <div class="col">
            <tabset [justified]="true" type="pills">
              <tab #tab1="tab" (selectTab)="selectTab(1)">
                <ng-template tabHeading>
                  <i class="fas fa-check-circle nav-icon"></i> {{ 'STR.richieste_cambio_password_verificate' | translate }}
                </ng-template>
              </tab>
              <tab #tab2="tab" (selectTab)="selectTab(2)">
                <ng-template tabHeading>
                  <i class="fas fa-exclamation-circle nav-icon"></i> {{ 'STR.richieste_cambio_password_non_verificate' | translate }}
                </ng-template>
              </tab>
            </tabset>
          </div>
        </div>
      </div>

      <app-card-datatable-ng *ngIf="tab1.active" #utentiVerificatiCardDatatable
        title="{{ 'STR.richieste_da_evadere' | translate }}" [data]="data_verificati"
        [isLoadingData]="isLoadingData" [showDeleteButton]="true" labelDeleteButton="Cancella richieste" [showPrimaryButton]="true" labelPrimaryButton="Aggiorna password" iconPrimaryButton="key"
        iconDeleteButton="user-times" (rowClickEvent)="rowClickUser($event)" (deleteClickEvent)="deleteEvent($event)" (primaryClickEvent)="updatePasswordCommand(utentiVerificatiCardDatatable)" [showExportButton]="true">
      </app-card-datatable-ng>

      <app-card-datatable-ng *ngIf="tab2.active" #utentiNonVerificatiCardDatatable
        title="{{ 'STR.richieste_da_evadere' | translate }}" [data]="data_non_verificati"
        [isLoadingData]="isLoadingData" [showDeleteButton]="true" labelDeleteButton="Cancella richieste" [showPrimaryButton]="true" labelPrimaryButton="Aggiorna password" iconPrimaryButton="key"
        iconDeleteButton="user-times" (rowClickEvent)="rowClickUser($event)" (deleteClickEvent)="deleteEvent($event)" (primaryClickEvent)="updatePasswordCommand(utentiNonVerificatiCardDatatable)" [showExportButton]="true">
      </app-card-datatable-ng>
    </div>
  </div>
</div>
