<app-card-loading-error *ngIf="this.error" (retryClicked)="retryAfterLoadingError()"></app-card-loading-error>

<!-- Card -->
<div class="card">
    <!-- Header -->
    <div class="card-header p-1">
      <div class="row justify-content-between align-items-center flex-grow-1">
        <div class="col">
          <tabset [justified]="true" type="pills">
            <tab #tab1="tab" (selectTab)="selectTab(1)">
              <ng-template tabHeading>
                  <i class="fas fa-user nav-icon"></i>{{ 'STR.member_user' | translate }}
              </ng-template>
            </tab>
            <tab #tab2="tab" (selectTab)="selectTab(2)">
                <ng-template tabHeading>
                    <i class="fas fa-users nav-icon"></i>{{ 'STR.subgroups' | translate }}
                </ng-template>
              </tab>
          </tabset>
        </div>
      </div>
    </div>
    <!-- End Header -->
    <!-- <div class="card-body">

    </div> -->

    <app-card-datatable-ng *ngIf="tab1.active" title="{{ 'STR.member_user' | translate }}"
    [data]="data.memberUser" [rowData]="memberUser" [idRootData]="id" [menuType]="'group-members|group-members-users'"
    [isLoadingData]="_loadingData.memberUser" [showAvatarOnFirstColumn]="true" [error]="dataError.memberUser" (rowClickEvent)="rowClickUser($event)" 
    [showAddButton]="false" [showExportButton]="true">
    </app-card-datatable-ng>

    <app-card-datatable-ng *ngIf="tab2.active" title="{{ 'STR.subgroups' | translate }}"
    [data]="data.memberGroup" [rowData]="memberGroup" [idRootData]="id" [menuType]="'group-members'"
    [isLoadingData]="_loadingData.memberGroup" [showAvatarOnFirstColumn]="true"
      [error]="dataError.memberGroup" (rowClickEvent)="rowClickGroup($event)" [showAddButton]="false" [showExportButton]="true">
    </app-card-datatable-ng>
  </div>
  <!-- End Card -->
