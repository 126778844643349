import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';

@Component({
  selector: 'app-group-profile-info-card',
  templateUrl: './group-profile-info-card.component.html',
  styleUrls: ['./group-profile-info-card.component.scss']
})
export class GroupProfileInfoCardComponent implements OnInit {
  @Input() id: any;
  group: any;
  // ownerCourse: any;
  groups: any[];
  error: any;

  showInfo = false;

  _loadingData = {
    group: false,
    memeber: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  private _googleServiceGroupsSubscription: Subscription;

  constructor(
    private googleDirectoryService: GoogleDirectoryService) { }

  ngOnInit(): void {
    this.unsubscibeAll();

    this.error = null;
    this._loadingData.group = true;
    this._googleServiceGroupsSubscription = this.googleDirectoryService.getSubjectToUpdateGroupsObservable().subscribe(
      (result) => {
        this._loadingData.group = false;

        if (result)
          this.groups = result;

        this.init();
      });

      this.googleDirectoryService.groupsList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  unsubscibeAll() {
    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();
  }

  init() {
    if (this.id) {
      if (this.groups) {
        this.group = this.googleDirectoryService.getGroupByGroupKey(this.id);
      } else {
        this.googleDirectoryService.groupsList();
      }
    } else {
      this.genericError("group");
    }
  }

  genericError(typeError) {
    this.error = typeError;
    this._loadingData[typeError] = false;
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }
}