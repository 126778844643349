import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGConfig, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CacheService, CacheType } from 'src/app/services/cache.service';
import { UserService } from 'src/app/services/general/user.service';
import { CourseState, GoogleClassroomService } from 'src/app/services/google/google-classroom.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { PageService } from 'src/app/services/page.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-classroom-profile',
  templateUrl: './classroom-profile.component.html',
  styleUrls: ['./classroom-profile.component.scss']
})
export class ClassroomProfileComponent implements OnInit {
  @Input() id: any;

  showForm: boolean;

  error: any;

  get loading(): boolean {
    return Object.values(this.isLoading).some(x => x == true);
  }

  isLoading = {
    classroom: false,
    active: false,
    archived: false,
    suspended: false,
    provisioned: false,
    declined: false,
  };

  classroom: any[];
  classroomsFiltered = {
    active: [],
    archived: [],
    suspended: [],
    provisioned: [],
    declined: []
  }

  classroomsFilteredData = {
    active: [],
    archived: [],
    suspended: [],
    provisioned: [],
    declined: []
  }

  dataError = {
    active: [],
    archived: [],
    suspended: [],
    provisioned: [],
    declined: []
  };

  classroomError: any;

  sortOptions: SelectItem[];

  sortOrder: number;

  sortField: string;
  sortKey: any;

  private _activeRouteSubscription: Subscription;
  private _googleServiceClassroomSubscription: Subscription;

  constructor(
    private classroomService: GoogleClassroomService,
    private pageService: PageService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private primengConfig: PrimeNGConfig,
    private cacheService: CacheService,
    private googleDirectoryService: GoogleDirectoryService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  aggiornaDati() {
    this.cacheService.updateCacheStatus(CacheType.GoogleClassroom, false);
    this.classroom = null;
    this.ngOnInit();
  }

  ngOnInit(): void {

    this.unsubscibeAll();

    this.error = null;

    this.isLoading.classroom = false;

    this._googleServiceClassroomSubscription = this.classroomService.getSubjectToUpdateCoursesObservable().subscribe(
      (result) => {
        this.isLoading.classroom = false;
        if (result) {
          this.updateData(result);
          this.getClassroomCourseData();
        }
      });

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_course;
      this.init();
    });

    this.sortOptions = [
      { label: 'Più recenti', value: '!creationTime' },
      { label: 'Meno recenti', value: 'creationTime' }
    ];

    this.primengConfig.ripple = true;
  }

  init() {

    this.showForm = false;
    this.isLoading.classroom = true;
    this.classroomService.coursesList();

    if (this.id && this.id > 0) {
      this.showForm = this.utilityService.checkRouteActionType(this.activeRoute, "edit");
      this.getClassroomCourseData();
    }
    else {
      this.showForm = false;
    }
  }

  getClassroomCourseData() {
    if (this.id && this.id > 0 && this.classroom) {
      let course = this.classroom.find(x => x.id == this.id);

      if (course)
        this.pageService.updatePageTitle({ course: course.name });
    }
  }

  updateData(classroom) {
    this.classroom = classroom;

    this.isLoading.active = true;
    this.isLoading.archived = true;
    this.isLoading.suspended = true;
    this.isLoading.provisioned = true;
    this.isLoading.declined = true;

    this.classroomsFiltered.active = this.classroom.filter(x => x.courseState == CourseState.ACTIVE);
    this.classroomsFiltered.archived = this.classroom.filter(x => x.courseState == CourseState.ARCHIVED);
    this.classroomsFiltered.suspended = this.classroom.filter(x => x.courseState == CourseState.SUSPENDED);
    this.classroomsFiltered.provisioned = this.classroom.filter(x => x.courseState == CourseState.PROVISIONED);
    this.classroomsFiltered.declined = this.classroom.filter(x => x.courseState == CourseState.DECLINED);

    this.classroomsFilteredData.active = this.parseClassroomResult(this.classroomsFiltered.active);
    this.classroomsFilteredData.archived = this.parseClassroomResult(this.classroomsFiltered.archived);
    this.classroomsFilteredData.suspended = this.parseClassroomResult(this.classroomsFiltered.suspended);
    this.classroomsFilteredData.provisioned = this.parseClassroomResult(this.classroomsFiltered.provisioned);
    this.classroomsFilteredData.declined = this.parseClassroomResult(this.classroomsFiltered.declined);

    this.isLoading.active = false;
    this.isLoading.archived = false;
    this.isLoading.suspended = false;
    this.isLoading.provisioned = false;
    this.isLoading.declined = false;
  }

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  unsubscibeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();

    if (this._googleServiceClassroomSubscription)
      this._googleServiceClassroomSubscription.unsubscribe();
  }

  getRemainingCountAvatar(lenghtMax, countFix) {
    return lenghtMax - countFix;
  }

  parseClassroomResult(result) {

    let table = [];

    result.forEach(element => {

      let proprietario = []

      let sotto_nome = (element.section ? element.section : "") + " " + (element.room ? "(" + element.room + ")" : "");

      if (element.ownerId) {
        let googleuser_proprietario = this.googleDirectoryService.getUser(element.ownerId);

        if (googleuser_proprietario) {
          proprietario = [
            googleuser_proprietario.name.familyName + " " + googleuser_proprietario.name.givenName,
            googleuser_proprietario.primaryEmail
          ]
        }
      }

      table.push(
        {
          "nome": [element.name, sotto_nome],
          "description": [element.descriptionHeading, element.description],
          "proprietario": proprietario,
          "_export_data": {
            name: element.name,
            section: element.section,
            descriptionHeading: element.descriptionHeading,
            description: element.description,
            room: element.room,
            proprietarioName: proprietario[0] ? proprietario[0] : "",
            proprietarioEmail: proprietario[1] ? proprietario[1] : "",
            ownerId: element.ownerId,
            link: element.alternateLink,
            courseGroupEmail: element.courseGroupEmail,
            teacherGroupEmail: element.teacherGroupEmail,
            creationTime: element.creationTime,
            updateTime: element.updateTime
          },
        });
    });

    return table;
  }

  rowClickCourse(data, state) {
    let course = this.classroomsFiltered[state][data.index];
    this.router.navigate(['/classroom/' + course.id]);
  }

  selectTab(id) {

  }
}
