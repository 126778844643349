<p-sidebar *ngIf="getUtilityService().showHelpSidebar" [(visible)]="getUtilityService().showHelpSidebar" position="right">
  <div class="sidebar sidebar-bordered sidebar-box-shadow">
    <div class="card card-lg sidebar-card sidebar-scrollbar">
      <div class="card-header">
        <h4 class="card-header-title">
          Guida
        </h4>

        <a class="btn btn-icon btn-xs btn-ghost-dark ml-2" (click)="closeSidebar()">
          <i class="fas fa-times"></i>
        </a>
      </div>

      <div class="card-body sidebar-body">

        <iframe src="https://geniusuite.com/geniusuite-tutorial/" width="100%" height="100%"></iframe>

      </div>
    </div>
  </div>
</p-sidebar>
