import { Injectable } from '@angular/core';

export enum CacheType {
  Parametri,
  Organigramma,
  Materia,
  Plesso,
  Classi,
  User,
  GoogleDomains,
  GoogleOrgUnit,
  GoogleGroups,
  GoogleUsers,
  GoogleUsersDeleted,
  GoogleClassroom,
  RequestChangePassword,
  Announcement,
  Monitor,
}

@Injectable({
  providedIn: 'root'
})

export class CacheService {

  cacheInfo: any[CacheType];

  constructor() { }

  isCacheValid(type: CacheType) {
    if (this.cacheInfo && this.cacheInfo[type] && this.cacheInfo[type].valid)
      return true;
    return false;
  }

  updateCacheStatus(type: CacheType, valid: boolean) {

    if (!this.cacheInfo)
      this.cacheInfo = {};

    if (!this.cacheInfo[type])
      this.cacheInfo[type] = {};

    this.cacheInfo[type].valid = valid;

    if (valid)
      this.cacheInfo[type].last_valid_update = new Date();
    else
      this.cacheInfo[type].last_notvalid_update = new Date();
  }

  updateAllCacheStatus(valid: boolean) {

    let keys = Object.keys(this.cacheInfo);

    keys.forEach((type: any) => {
      this.updateCacheStatus(type, valid);
    });
  }

  isAllCacheValid() {

    let keys = Object.keys(this.cacheInfo);

    let valid = false;

    keys.forEach((type: any) => {
      valid = this.isCacheValid(type);
    });

    return valid;
  }

}
