import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActivityService } from 'src/app/services/activity.service';
import { FileimportService } from 'src/app/services/fileimport.service';
import { environment } from '../../../../environments/environment';
import { ConfigService } from 'src/app/services/config.service';
import { FileUpload } from 'primeng/fileupload';

type AOA = any[][];

@Component({
  selector: 'app-setup-wizard-config',
  templateUrl: './setup-wizard-config.component.html',
  styleUrls: ['./setup-wizard-config.component.scss']
})
export class SetupWizardConfigComponent implements OnInit, OnDestroy {

  uploadedFileAdded: boolean;
  uploadedFileCompleted: boolean;
  fileSize: string;
  fileName: string;
  fileLastModified: number;

  selectedHeader: any;
  headerSelectionData: AOA;
  headerRow: any;

  userTypeSelect: any = [];
  userTypeSelected: any;

  data: AOA;

  filtroColIndex: number;
  paramFiltroColOptions: any[];
  paramFiltroColOptionsSelected: any[];

  private _fileimportServiceSubscription: Subscription;

  @ViewChild('fileUpload') fileUpload: FileUpload;

  fileUploadConfig: any = {
    url: environment.apiUrl + "upload",
  }

  constructor(private router: Router, private fileimportService: FileimportService, private activity: ActivityService) { }

  ngOnInit() {

    if (this.activity.isActivityInProgress("importazione")) {
      this.router.navigate(['/setup']);
      return;
    }

    if (this.fileimportService.hasImportProcessStarted()) {
      this.router.navigate(['/setup']);
      return;
    }

    this.userTypeSelect.push(ConfigService.getUserTypeObjectByKey("docente"));
    this.userTypeSelect.push(ConfigService.getUserTypeObjectByKey("studente"));
    this.userTypeSelect.push(ConfigService.getUserTypeObjectByKey("worker"));

    if (this.fileimportService.hasFile()) {
      this.uploadedFileAdded = true;
      this.uploadedFileCompleted = true;
      this.selectedHeader = this.fileimportService.getHeaderIndex();
      this.selectedHeaderChange();

      if (this.fileimportService.userTypeSelected)
        this.userTypeSelected = this.fileimportService.userTypeSelected;

      if (this.fileimportService.filtroColIndex) {

        this.filtroColIndex = this.fileimportService.filtroColIndex;

        if (this.fileimportService.paramFiltroColOptions)
          this.paramFiltroColOptions = this.fileimportService.paramFiltroColOptions;

        if (this.fileimportService.paramFiltroColOptionsSelected)
          this.paramFiltroColOptionsSelected = this.fileimportService.paramFiltroColOptionsSelected;
      }

      this.parseFileInfo(this.fileimportService.getFile());
      this.onParseFileCompleted(this.fileimportService.getData());
    }
    else {
      this.uploadedFileAdded = false;
      this.uploadedFileCompleted = false;
    }

    this._fileimportServiceSubscription = this.fileimportService.onParseCompleted().subscribe((data: AOA) => {
      this.onParseFileCompleted(data);
    });
  }

  ngOnDestroy() {

    if (this._fileimportServiceSubscription)
      this._fileimportServiceSubscription.unsubscribe();
  }

  onUploadError(event) {
    this.uploadedFileAdded = false;
  }

  onSelectFile(event) {
    this.setFile(event.files[0]);
    this.parseFileInfo(event.files[0]);
    this.uploadedFileCompleted = true;
  }

  setFile(file: File) {
    this.fileimportService.setFile(file);
  }

  saveFile() {
    this.fileimportService.saveFileAsJSON();
  }

  parseFileInfo(file: File) {
    this.fileName = file.name;
    this.fileSize = Math.round(file.size / 1024) + " Kbyte";
    this.fileLastModified = file.lastModified;
  }

  onParseFileCompleted(data: AOA) {

    if (data) {

      this.data = data;
      this.headerSelectionData = [];

      for (let i = 0; i < 30; i++) {
        let row = data[i];
        this.headerSelectionData.push(row);
      }

      if (!this.selectedHeader) {
        this.selectedHeader = 0;
        this.selectedHeaderChange();
      }
    }

    if (!data && this.fileimportService.hasFile()) {
      this.fileimportService.parseFile();
    }
  }

  addedFile(file) {
    if (file && file.accepted === true) {
      this.uploadedFileAdded = true;
    }
  }

  removedFile() {
    this.fileimportService.deleteFile();
    this.uploadedFileAdded = false;
    this.uploadedFileCompleted = false;
    this.fileName = null;
    this.fileSize = null;
    this.fileLastModified = null;
    this.selectedHeader = null;
    this.fileUpload.clear();
  }

  selectedHeaderChange() {
    this.fileimportService.saveHeaderIndex(this.selectedHeader);
    this.headerRow = this.fileimportService.getHeaderRow();
    this.filtroColIndex = null;
  }

  filtroColIndexChange() {

    this.paramFiltroColOptions = [];
    this.paramFiltroColOptionsSelected = [];

    if (this.filtroColIndex != null && this.data) {
      this.data.forEach((row, index) => {
        // skippo i valori prima dell'header
        if (index > this.selectedHeader) {
          let foundIndex = this.paramFiltroColOptions.findIndex((value) => { return value.name == row[this.filtroColIndex] })
          if (foundIndex == -1) {
            let obj = {
              name: row[this.filtroColIndex]
            };
            this.paramFiltroColOptions.push(obj);
            this.paramFiltroColOptionsSelected.push(obj);
          }
        }
      });
    }
  }

  next() {
    this.fileimportService.saveHeaderIndex(this.selectedHeader);
    this.fileimportService.saveUserTypeSelected(this.userTypeSelected);
    this.fileimportService.saveFilterRow(this.filtroColIndex, this.paramFiltroColOptions, this.paramFiltroColOptionsSelected);
    this.router.navigate(['/setup/config/struttura']);
  }

  back() {
    this.router.navigate(['/setup']);
  }

}
