import { Component, EventEmitter, Input, OnInit, Output, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface ConfigSelect {
  [key: string]: any
}
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  // Unificazione del Componente DropDown e MultiSelect per gestire il multi e single select
  // Ricordati che se specifichi un attributo prima di Value questo sarà undefined e non caricaera i value preselected, bestemmie!!
  selectedValue: any;
  selectedMultiValue: any;
  _notFoundText: string;

  config: ConfigSelect = {
    virtualScroll: true,
    itemSize: 32
  };

  /** [array] Dati da Gestire */
  @Input() data = [];

  /** [string] Id del Componente */
  @Input('id') id: any;

  /** [boolean] Selezionamento multipo o singolo */
  @Input('multiple') multiple: any;

  /** [boolean] Suddivisione per Gruppi */
  @Input('group') group: any;

  /** [string] Placeholder */
  @Input('placeholder') placeholder: any;

  /** [string] Label del input di Select */
  @Input('label') label: any;

  /** [boolean] Visualizzare Button Icona di Clear dell'Input */
  @Input('showClear') showClear: any;

  /** [boolean] Visualizzare il Primo Elemento già selezionato */
  @Input('firstSelected') firstSelected: any;

  /** [string] Definendo per cosa filtrare */
  @Input('filterBy') filterBy: any;

  /** [array] Campi da visuliazzare del Data nella Lista. Esempio: nome, cognome, classe */
  @Input('optionAttributeShow') optionAttributeShow: any;

  /** [string] Attributo del valore selezionato, se non specificato ritorno tutto l'oggetto */
  @Input('resultAttribute') resultAttribute: any;

  /** [boolean] MultiSelezionamento valori */
  @Input('showToggleAll') showToggleAll: any;

  /** [string] Attributo del valore selezionato da visulaizzare, se non specificato ritorno tutto l'oggetto */
  @Input('showValue') showValue: any;

  /** [boolean] MultiSelezionamento valori */
  @Input('virtualScroll') virtualScroll: any;

  @Input('disabled') disabled: any = false;

  /** [object] funzionamento ugale a [(ngModel)] */
  @Input('value') set setValue(value) {
    if (!this.multiple)
      this.selectedValue = this.resultAttribute ? (this.data.find(x => x[this.resultAttribute] == value) || null) : (this.data.find(x => x == value) || null);
    else {
      this.selectedMultiValue = this.resultAttribute ? (value.map(x => x[this.resultAttribute]) || null) : (value || null);
    }
  }
  /** Evento di Binding come [(ngModel)], vedi attributo 'value' */
  @Output('valueChange') emitter: EventEmitter<any> = new EventEmitter<any>();

  @Output('onChange') emitterOnChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService) {
    this._notFoundText = this.translate.instant("STR.not_found");
  }

  ngOnInit() {
    this.config.id = (this.id || null);
    this.config.title = (this.label || null);
    this.config.type = (this.multiple ? true : false);
    this.config.group = (this.group ? true : false);
    this.config.showLabel = (this.label ? true : false);
    this.config.showClear = (this.showClear || false);
    this.config.placeholder = (this.placeholder || null);
    this.config.optionLabel = (this.showValue || null);
    this.config.optionAttributeShow = (this.optionAttributeShow.replace(/\s/g, '').split(',') || null);
    this.config.filterBy = (this.filterBy || null);
    this.config.filter = (this.filterBy ? true : false);
    this.config.scrollHeight = this.getHeightScroll(); // default PrimeNg 200px
    this.config.showToggleAll = (this.showToggleAll ? true : false);
    this.config.virtualScroll = (this.virtualScroll ? true : false);

    this.data = this.data ? this.data : [];

    if (this.firstSelected && this.data && this.data.length > 0)
      this.setValue(this.data[0]);

    this.config.placeholder = this.config.placeholder ? this.translate.instant(this.config.placeholder) : null;
  }

  onChange(event) {
    this.selectedValue = event.value;
    this.emitter.emit(this.selectedValue ? (this.selectedValue[this.resultAttribute] || this.selectedValue) : null);
    this.emitterOnChange.emit(this.selectedValue ? (this.selectedValue[this.resultAttribute] || this.selectedValue) : null);
  }

  onChangeMulti(event) {
    let resVal = this.selectedMultiValue.slice();

    if (this.resultAttribute)
      resVal = this.selectedMultiValue.map(x => x[this.resultAttribute]);

    this.emitter.emit(this.selectedMultiValue ? (resVal || this.selectedMultiValue) : null);
    this.emitterOnChange.emit(this.selectedValue ? (this.selectedValue[this.resultAttribute] || this.selectedValue) : null);
  }

  selectedShowValue() {
    return this.selectedValue ? (this.selectedValue[this.showValue] || this.selectedValue[this.showValue]) : null;
  }

  selectedShowValueMulti() {
    let showVal = this.selectedMultiValue.slice();

    if (this.showValue)
      showVal = this.selectedMultiValue.map(x => x[this.showValue]).sort();

    return this.selectedMultiValue ? (showVal.join(',') || showVal) : null;
  }

  getHeightScroll() {
    if (!this.data || this.data.length == 0) {
      return "10px";
    } else {
      let heightMin = 41; // Default Height One Element
      let heightTotal = 1;

      if (this.data.length <= 5) {
        heightTotal = this.data.length * heightMin;
      } else {
        heightTotal = 220;
      }

      return heightTotal + "px";
    }
  }
}
