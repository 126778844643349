import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { Subscription } from 'rxjs';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { AccountService } from 'src/app/services/account.service';
import { SettingsService } from 'src/app/services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { OrderTypeList, UtilityService } from 'src/app/services/utility.service';
import { ParametriService } from 'src/app/services/general/parametri.service';

@Component({
  selector: 'aside',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class SidebarComponent implements OnInit, OnDestroy {

  isCollapsedDashboard = true;
  isCollapsedEnte = true;
  isCollapsedOrganigramma = true;
  isCollapsedPlesso = true;
  isCollapsedMateria = true;

  currentLang: string;


  private _userAccountSubscription: Subscription;
  private _plessoServiceSubscription: Subscription;
  private _organigrammaServiceSubscription: Subscription;
  private _settingsServiceSubscription: Subscription;

  plessi: any;
  organigramma: any;
  organigrammaDocenti: any;
  organigrammaStudenti: any;
  organigrammaCount: any;
  organigrammaTotalCount: any;
  account: any;

  menuMini: boolean;

  organigrammaTreeMenu: MenuItem[];

  constructor(
    private plessoService: PlessoService,
    private organigrammaService: OrganigrammaService,
    private accountService: AccountService,
    private utilityService: UtilityService,
    private settingsService: SettingsService,
    private translate: TranslateService,
    private parametriService: ParametriService,
    private router: Router
  ) { }

  ngOnInit() {

    this.currentLang = this.translate.currentLang;

    if (!this.currentLang) {
      this.currentLang = this.translate.defaultLang;
    }

    this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
    })

    this._userAccountSubscription = this.accountService.getSubjectToUpdateObservable().subscribe((result) => {
      this.userUpdated(result);
    });

    this.userUpdated(this.accountService.getData());

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.plessoUpdated(result);
    });

    this._organigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.organigrammaUpdated(result);
    });

    this._settingsServiceSubscription = this.settingsService.getSubjectToUpdateObservable().subscribe(() => {
      this.menuMini = this.settingsService.menuMini;
    });

    this.menuMini = this.settingsService.menuMini;

    this.plessoService.all();
    this.organigrammaService.all();
  }

  ngOnDestroy() {
    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();

    if (this._organigrammaServiceSubscription)
      this._organigrammaServiceSubscription.unsubscribe();

    if (this._userAccountSubscription)
      this._userAccountSubscription.unsubscribe();



    if (this._settingsServiceSubscription)
      this._settingsServiceSubscription.unsubscribe();
  }

  userUpdated(result) {
    this.account = result;
  }

  plessoUpdated(result) {
    this.plessi = this.parsePlessi(result);
  }

  organigrammaUpdated(result) {
    this.organigramma = this.parseOrganigramma(result);
  }

  parsePlessi(result) {

    return result.sort((el1, el2) => {
      if (el1.name < el2.name)
        return -1;
      if (el1.name > el2.name)
        return 1;
      return 0;
    });
  }

  parseOrganigramma(result) {

    this.organigrammaTreeMenu = this.parseOrganigrammaTreeMenu(result, true);

    let list = [];
    this.organigrammaCount = [];
    this.organigrammaTotalCount = 0;
    this.organigrammaDocenti = null;
    this.organigrammaStudenti = null;

    result.forEach(element => {

      this.organigrammaTotalCount++;

      if (element.relation_info == 'docenti' && !element.parent_id) {
        this.organigrammaDocenti = element;
      }
      else if (element.relation_info == 'studenti' && !element.parent_id) {
        this.organigrammaStudenti = element;
      }
      else if (!element.parent_id) {
        list.push(element);
      }
      else {
        if (this.organigrammaCount[element.parent_id])
          this.organigrammaCount[element.parent_id]++;
        else
          this.organigrammaCount[element.parent_id] = 1;
      }

    });

    return list;
  }

  parseOrganigrammaTreeMenu(list: any, root: boolean) {

    let menu: MenuItem[] = [];

    let selfRouter = this.router;
    list.forEach(organigramma => {
      let menu_item = {
        id: organigramma.id,
        parent_id: organigramma.parent_id,
        label: organigramma.name,
        items: null,
        command: function () {
          selfRouter.navigate(["/organigramma/" + organigramma.id]);
        }
      };

      menu.push(menu_item);
    });

    return this.utilityService.convertListToTree(menu, "id", "parent_id", OrderTypeList.Ascending, "label", false, null, null, "items");
  }

  logout() {

    this.accountService.logout(() => {
      this.router.navigate(['/login']);
    });
  }

  toggleMenuSize() {
    this.settingsService.toggleMenu();
  }

  setLanguage(lang) {
    this.translate.use(lang);
  }

  getAccountService() {
    return this.accountService;
  }

  getParametriService() {
    return this.parametriService;
  }

}
