import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivityService } from 'src/app/services/activity.service';
import { PassaggioAnnoService } from 'src/app/services/operations/passaggio-anno.service';

@Component({
  selector: 'app-passaggio-anno-backup',
  templateUrl: './passaggio-anno-backup.component.html',
  styleUrls: ['./passaggio-anno-backup.component.scss']
})
export class PassaggioAnnoBackupComponent implements OnInit {

  constructor(private router: Router, private passaggioAnnoService: PassaggioAnnoService, private activity: ActivityService) { }

  ngOnInit() {
    this.passaggioAnnoService.checkActivity();

    if (this.passaggioAnnoService.getActivityExist())
      if (this.passaggioAnnoService.getActivityInProgress())
        this.router.navigate(['/passaggio-anno/wait']);
      else
        this.router.navigate(['/passaggio-anno/finish']);
  }

  back() {
    this.router.navigate(['/passaggio-anno']);
  }

  next() {
    this.router.navigate(['/passaggio-anno/config']);
  }

  showSidebar() {
    this.activity.showSidebar = true;
  }
}