import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { forbiddenStringValidator } from './name.validator';

@Directive({
  selector: '[forbiddenString]',
  providers: [{provide: NG_VALIDATORS, useExisting: ForbiddenValidatorDirective, multi: true}]
})
export class ForbiddenValidatorDirective implements Validator {
  @Input('forbiddenString') arrValue: string[];

  validate(control: AbstractControl): {[key: string]: any} | null {
    return this.arrValue ? forbiddenStringValidator(this.arrValue.map(function(x){ 
      return x.toLowerCase().trim(); 
    }))(control): null;
  }
}