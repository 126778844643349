import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AnnouncementService } from 'src/app/services/general/announcement.service';

@Component({
  selector: 'app-announcement-widget',
  templateUrl: './announcement-widget.component.html',
  styleUrls: ['./announcement-widget.component.scss']
})
export class AnnoucementWidgetComponent implements OnInit {

  private _serviceSubscription: Subscription;

  @Input() showByChannel: string;
  @Input() hideByChannel: string;

  data: any;
  last_update: Date;

  private _activitySubscription: Subscription;

  constructor(
    private announcementService: AnnouncementService
  ) { }

  ngOnInit() {

    this._serviceSubscription = this.announcementService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.updateData(result);
    });

    this.announcementService.all();
  }

  ngOnDestroy() {
    if (this._activitySubscription)
      this._activitySubscription.unsubscribe();

    if (this._serviceSubscription)
      this._serviceSubscription.unsubscribe();
  }

  updateData(result) {

    this.data = [];

    if (this.showByChannel) {
      result.forEach(res => {
        if (res.channel && res.channel == this.showByChannel) {
          this.data.push(res);
        }
      });
    }
    else {
      this.data = result;
    }

    this.last_update = this.announcementService.last_update;
  }

  dismiss(item) {
    this.announcementService.dismiss(item);
  }

}
