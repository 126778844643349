<!-- Card -->
<div class="card">
  <!-- Header -->
  <div class="card-header p-1">
    <div class="row justify-content-between align-items-center flex-grow-1">
      <div class="col">
        <tabset [justified]="true" type="pills">
          <tab #tab1="tab" (selectTab)="selectTab(1)">
            <ng-template tabHeading>
              <i class="fas fa-user-friends nav-icon"></i> {{ 'STR.Utenti' | translate }}
            </ng-template>
          </tab>
          <tab #tab2="tab" (selectTab)="selectTab(2)">
            <ng-template tabHeading>
              <i class="fas fa-users nav-icon"></i> {{ 'STR.gruppi' | translate }}
            </ng-template>
          </tab>
          <tab #tab3="tab" (selectTab)="selectTab(3)">
            <ng-template tabHeading>
              <i class="fas fa-user-friends nav-icon"></i> {{ 'STR.org_unit' | translate }}
            </ng-template>
          </tab>
          <tab #tab4="tab" (selectTab)="selectTab(4)">
            <ng-template tabHeading>
              <i class="fas fa-sitemap nav-icon"></i> Organigrammi
            </ng-template>
          </tab>
        </tabset>
      </div>
    </div>
  </div>

  <app-card-datatable-ng *ngIf="tab1.active" title="{{ 'STR.Utenti' | translate }}" [data]="users" [rowData]="usersData" [idRootData]="id"
    [menuType]="'users|users-organigramma'" [isLoadingData]="isLoadingUsers" [showAvatarOnFirstColumn]="true" [error]="usersError"
    (rowClickEvent)="rowClickUser($event)" (addClickEvent)="addClickUser()" [showAddButton]="true" [showExportButton]="true">
  </app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab2.active" title="{{ 'STR.gruppi' | translate }}" [data]="groups" [rowData]="groupsData" [idRootData]="id"
  [menuType]="'groups'" (rowClickEvent)="rowClickGroup($event)"  [isLoadingData]="isLoadingGroups" [error]="groupsError" [showExportButton]="true"></app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab3.active" title="{{ 'STR.org_unit' | translate }}" [data]="usersOrgUnit" [rowData]="usersOrgUnitData" [idRootData]="id"
  [menuType]="'users-org-unit'" (rowClickEvent)="rowClickUserOrgUnit($event)" [isLoadingData]="isLoadingUsersOrgUnit" [error]="usersOrgUnitError" [showExportButton]="true"></app-card-datatable-ng>

  <app-card-datatable-ng *ngIf="tab4.active" title="Organigrammi figli" [data]="organigrammi" [rowData]="dataOrganigrammi" [idRootData]="id" [menuType]="'organigrammi'"
    [isLoadingData]="isLoadingOrganigrammaChildren" [error]="organigrammiError" (rowClickEvent)="rowClickOrganigramma($event)"
    (addClickEvent)="addClickOrganigramma()" [showAddButton]="true" [showExportButton]="true">
  </app-card-datatable-ng>
</div>
<!-- End Card -->
