import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { UpdateAccountDomainService } from 'src/app/services/operations/update-account-domain.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-update-account-domain',
  templateUrl: './update-account-domain.component.html',
  styleUrls: ['./update-account-domain.component.scss']
})
export class UpdateAccountDomainComponent implements OnInit {
  regEx = ConfigService.regExUpdate;
  error: any;
  _loadingData = {
    domains: false,
    users: false,
    groups: false
  };

  formLoading: boolean = false;
  tabSelected: string;

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  domains: any = [];
  usersData: any = [];
  groupsData: any = [];
  users: any;
  groups: any;
  model: any;
  myForm: FormGroup = new FormGroup({});

  operationInProgress: boolean = false;
  errorUserAccount: boolean = false;
  errorGroupAccount: boolean = false;

  private _updateAccountDomainUserSubscription: Subscription;
  private _updateAccountDomainGroupSubscription: Subscription;
  private _userServiceSubscription: Subscription;
  private _googleServiceDomainsSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;
  private _googleServiceGroupsSubscription: Subscription;

  constructor(
    private updateAccountDomain: UpdateAccountDomainService,
    private googleService: GoogleDirectoryService,
    private userService: UserService,
    private utilityService: UtilityService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdatedUser() {
    this._loadingData.users = true;
    this.users = this.updateAccountDomain.itemsUser;
    this.usersData = this.userService.getTable(this.users);

    setTimeout(() => {
      this._loadingData.users = false;
    }, 250);
  }

  dataUpdatedGroup() {
    this._loadingData.groups = true;
    this.groups = this.updateAccountDomain.itemsGroup;
    this.groupsData = this.parseGroupsResult(this.groups);
  }

  parseGroupsResult(result) {
    this.errorGroupAccount = false;

    let table = [];

    result.forEach(element => {

      let checkDomain = element.email.split("@")[1];

      if (checkDomain == this.model.domain_group) {
        this.errorGroupAccount = true;
      }

      table.push({
        "nome": [element.name, element.description],
        "email": [element.email],
        "membri": [element.directMembersCount],
        "_export_data": {
          name: element.name,
          description: element.description,
          email: element.email,
          directMembersCount: element.directMembersCount,
        },
      })
    });

    setTimeout(() => {
      this._loadingData.groups = false;
    }, 250);
    return table;
  }

  deleteUserEvent(rows) {
    this.updateAccountDomain.deleteItemsAtIndex(rows, "USER");
    this.dataUpdatedUser();
  }

  deleteGroupEvent(rows) {
    this.updateAccountDomain.deleteItemsAtIndex(rows, "GROUP");
    this.dataUpdatedGroup();
  }

  onSubmit() {
    if (this.myForm.valid) {
      this.formLoading = true;

      this.operationInProgress = true;

      this.updateAccountDomain.execOperation(this.model.domain_user ? this.model.domain_user.domainName : null, this.model.domain_group  ? this.model.domain_group.domainName : null, (error) => {
        this.formLoading = false;
        this.operationInProgress = false;
        if (!error) {
          this.initForm();
        }
      });
    }
  }

  resetOperation() {
    this.updateAccountDomain.reset();
  }

  unsubscibeAll() {
    if (this._updateAccountDomainUserSubscription)
      this._updateAccountDomainUserSubscription.unsubscribe();

    if (this._updateAccountDomainGroupSubscription)
      this._updateAccountDomainGroupSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleServiceDomainsSubscription)
      this._googleServiceDomainsSubscription.unsubscribe();

    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();
  }

  initForm() {
    this.tabSelected = "user";
    this.formLoading = false;
    this.operationInProgress = false;

    this.model = {
      domain_user: null,
      domain_group: null
    }

    this.myForm = new FormGroup({
      confirmation_delete: new FormControl(),
      domain_user: new FormControl(this.model.domain_user),
      domain_group: new FormControl(this.model.domain_group),
    });
  }

  init() {

    this._updateAccountDomainUserSubscription = this.updateAccountDomain.getSubjectToUpdateUserObservable().subscribe((data) => {
      if (data) {
        this.dataUpdatedUser();
      }
    });

    this._updateAccountDomainGroupSubscription = this.updateAccountDomain.getSubjectToUpdateGroupObservable().subscribe((data) => {
      if (data) {
        this.dataUpdatedGroup();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      this.googleService.groupsList();
    });

    this._googleServiceGroupsSubscription = this.googleService.getSubjectToUpdateGroupsObservable().subscribe((result) => {
      this.dataUpdatedGroup();
      this.dataUpdatedUser();
    });

    this._googleServiceDomainsSubscription = this.googleService.getSubjectToUpdateDomainsObservable().subscribe((result) => {
      if (result) {
        this.domains = result;
      }
      this._loadingData.domains = false;
    });

    this._googleServiceUsersSubscription = this.googleService.getSubjectToUpdateUsersObservable().subscribe((result) => {
      this.userService.all();
    });

    this._loadingData.groups = true;
    this._loadingData.users = true;
    this._loadingData.domains = true;
    this.googleService.domainsList();
    this.googleService.usersList();
  }

  domainOnSelect($event, id) {
    let domainName = $event ? $event.domainName : null;

    this.myForm.get(id).setValue(domainName);
    this.model[id] = domainName;

    if (id == "domain_group")
      this.dataUpdatedGroup();

    if (id == "domain_user")
      this.dataUpdatedUser();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  selectTab($event) {
    this.tabSelected = $event;
  }
}
