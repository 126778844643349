import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setup-wizard-steps',
  templateUrl: './setup-wizard-steps.component.html',
  styleUrls: ['./setup-wizard-steps.component.scss']
})
export class SetupWizardStepsComponent implements OnInit {

  steps: any = [
    1, 2, 3, 4, 5
  ];

  @Input() currentStep: number;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToStep(num) {

    if (this.currentStep != null && num < this.currentStep) {

      switch (num) {
        case 1:
          this.router.navigate(['/setup/config']);
          break;
        case 2:
          this.router.navigate(['/setup/config/struttura']);
          break;
        case 3:
          this.router.navigate(['/setup/preview/struttura']);
          break;
        case 4:
          this.router.navigate(['/setup/config/users']);
          break;
        case 5:
          this.router.navigate(['/setup/preview/users']);
          break;

        default:
          break;
      }
    }
  }

}
