<app-card-loading *ngIf="loading">
</app-card-loading>

<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col-12">
      <app-card-operation-activity [filterActivity]="['operation.classroom.create-classroom']">
      </app-card-operation-activity>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <div class="card card-hover-shadow">
        <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)">
          <!-- Card -->
          <div class="card card-hover-shadow">
            <div class="card-body">
              <h3 class="card-title">Crea Classroom</h3>
              <p class="card-text">Questa operazione consente di creare una singola Classroom</p>

              <div class="row form-group">
                <div class="col-6">
                  <label class="input-label">Nome</label>
                  <input [(ngModel)]="model.classroomName" class="form-control" name="name" #name="ngModel" type="text"
                    [ngClass]="(name.dirty || name.touched)? (name.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                    required autocomplete="none">
                  <span class="invalid-feedback" *ngIf="name.invalid && (name.dirty || name.touched)">Non
                    valido</span>
                  <span class="valid-feedback" *ngIf="name.valid && (name.dirty || name.touched)">Valido</span>
                </div>

                <div class="col-6">
                  <label class="input-label">Descrizione</label>
                  <input [(ngModel)]="model.classroomDescription" class="form-control" name="description"
                    #description="ngModel" type="text"
                    [ngClass]="(description.dirty || description.touched)? (description.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                    autocomplete="none">
                  <span class="invalid-feedback"
                    *ngIf="description.invalid && (description.dirty || description.touched)">Non
                    valido</span>
                  <span class="valid-feedback"
                    *ngIf="description.valid && (description.dirty || description.touched)">Valido</span>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-12">
                  <button class="btn btn-outline-primary" type="button" (click)="changeStatusCollapse()"
                    [attr.aria-expanded]="showAllSetting" aria-controls="collapseAdvancedSettings">
                    Configura sezione e stanza
                    <span class="ml-2" [ngSwitch]="showAllSetting">
                      <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                      <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="form-group" id="collapseAdvancedSettings" [collapse]="!showAllSetting" [isAnimated]="true">
                <!-- Sezione -->
                <div class="row form-group">
                  <div class="col-6">
                    <label class="input-label">Sezione</label>
                    <input [(ngModel)]="model.classroomSection" class="form-control" name="section" #section="ngModel"
                      type="text"
                      [ngClass]="(section.dirty || section.touched)? (section.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                      autocomplete="none">
                    <span class="invalid-feedback" *ngIf="section.invalid && (section.dirty || section.touched)">Non
                      valido</span>
                    <span class="valid-feedback"
                      *ngIf="section.valid && (section.dirty || section.touched)">Valido</span>
                  </div>

                  <div class="col-6">
                    <label class="input-label">Stanza</label>
                    <input [(ngModel)]="model.classroomRoom" class="form-control" name="room" #room="ngModel"
                      type="text"
                      [ngClass]="(room.dirty || room.touched)? (room.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                      autocomplete="none">
                    <span class="invalid-feedback" *ngIf="room.invalid && (room.dirty || room.touched)">Non
                      valido</span>
                    <span class="valid-feedback" *ngIf="room.valid && (room.dirty || room.touched)">Valido</span>
                  </div>
                </div>
                <!-- /Stanza -->
              </div>

              <div *ngIf="!model.ownerId; else errorOwner" class="alert alert-warning mt-2 mb-0">
                <span class="text-cap d-inline">Attenzione</span> Seleziona un proprietario prima di
                procedere
              </div>

              <ng-template #errorOwner>
                <div *ngIf="error" class="alert alert-danger mt-2 mb-0">
                  <span class="text-cap d-inline">Attenzione</span>
                  <ng-container *ngIf="docenti != null && docenti.length > 20; else blockGenericError">
                    Il numero massimo di Docenti per classe è 20 utenti
                  </ng-container>
                  <ng-template #blockGenericError>
                    Ci sono errori
                  </ng-template>
                </div>
              </ng-template>
            </div>

            <div class="card-footer">
              <div class="row justify-content-between align-items-center">
                <div class="col-auto py-1">
                  <button class="btn btn-outline-danger" (click)="resetOperation()"
                    [disabled]="(docenti.length <= 0 && studenti.length <= 0) || operationInProgress">
                    <i class="far fa-backspace mr-2"></i>
                    <span>{{'STR.reset_operation' | translate}}</span>
                  </button>
                </div>

                <div class="col-6 py-1">
                  <div class="row justify-content-end">
                    <div class="col-auto">
                      <input autocomplete="off" type="text" id="confirmation_delete" name="confirmation_delete"
                        #confirmation_delete="ngModel" [ngModel]="''" class="form-control" [pattern]="regEx" required>
                      <small class="d-block text-muted">Per confermare, digita
                        <span class="confirmation-phrase">Conferma</span></small>
                    </div>
                    <div class="col-auto">
                      <button type="submit" class="btn"
                        [disabled]="error || !myForm.valid || docenti.length <= 0 || operationInProgress || !model.ownerId"
                        [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                        Crea Classroom <i class="far fa-chevron-right ml-1"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Card -->
        </form>
      </div>
      <!-- End Card -->
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-sm">
      <div class="card">
        <div class="card-header d-block">
          <div class="row align-items-center pointer">
            <div class="col-5">
              <span class="font-weight-bold text-dark lead"><i class="fas fa-users-class mr-2"></i>{{model.classroomName
                || "- scrivi il nome della classroom -"}}</span>
            </div>
            <div class="col-5 align-self-start">
              <small class="text-cap">Descrizione</small>
              <span class="font-weight-bold text-dark lead">{{model.classroomDescription || "-"}}</span>
            </div>
            <div class="col-1 align-self-start">
              <small class="text-cap">{{ 'STR.docenti' | translate }}</small>
              <span class="font-weight-bold text-dark lead">{{docenti.length}}</span>
            </div>
            <div class="col-1 align-self-start">
              <small class="text-cap">{{ 'STR.studenti' | translate }}</small>
              <span class="font-weight-bold text-dark lead">{{studenti.length}}</span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <span class="d-block h4"><i class="fas fa-chalkboard-teacher mr-2"></i>Docenti <span class="badge badge-secondary">{{docenti.length}}</span></span>
              <div class="alert alert-soft-danger" *ngIf="docenti?.length == 0">
                <b>Attenzione</b> non sono presenti docenti in questa classroom.<br>
                Questa classroom non può essere creata senza almeno un docente.
              </div>
              <div class="alert alert-soft-info" *ngIf="docenti?.length == 0">
                <b>Suggerimento</b> per aggiungere docenti, selezionarli in una classe o un plesso ed utilizzare l'operazione massiva "Aggiungi docente in nuova classroom".
              </div>
              <ul class="list-group list-group-lg w-100" *ngIf="docenti?.length > 0">
                <ng-container *ngFor="let element_user of docenti; let index_utente = index;">
                  <li class="list-group-item" [ngClass]="{'alert-soft-danger': element_user.errors?.length > 0}">
                    <ng-container>
                      <div class="row">
                        <div class="col-12 align-self-start">
                          <small class="text-cap"
                            [title]="element_user.fullname">{{element_user.fullname}}</small>
                          <div [title]="element_user.email" class="text-truncate">
                            {{element_user.email}}</div>
                        </div>
                        <div class="col d-flex justify-content-end align-items-center">
                          <button type="button" class="btn btn-xs mt-2"
                            [ngClass]="{'btn-outline-primary': model.ownerId != element_user.googleId, 'btn-primary': model.ownerId == element_user.googleId}"
                            (click)="setUserAsOwner(element_user);">
                            <ng-template *ngIf="model.ownerId == element_user.googleId; then isOwner else isNotOwner">
                            </ng-template>
                            <ng-template #isOwner>
                              <i class="fas fa-crown mr-1"></i>
                              Proprietario
                            </ng-template>
                            <ng-template #isNotOwner>
                              <i class="fal fa-crown mr-1"></i>
                              Imposta come Proprietario
                            </ng-template>
                          </button>

                          <button type="button" class="ml-2 btn btn-xs mt-2 btn-outline-danger"
                            [disabled]="model.ownerId == element_user.googleId"
                            (click)="removeUserFromClassroom(element_user);">
                            <i class="fas fa-trash mr-1"></i>
                            Rimuovi
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </li>
                </ng-container>
              </ul>
            </div>

            <div class="col-6">
              <span class="d-block h4"><i class="fas fa-user-graduate mr-2"></i>Studenti <span class="badge badge-secondary">{{studenti.length}}</span></span>
              <div class="alert alert-soft-danger" *ngIf="studenti?.length == 0">
                <b>Attenzione</b> non sono presenti studenti in questa classroom.
              </div>
              <div class="alert alert-soft-info" *ngIf="docenti?.length == 0">
                <b>Suggerimento</b> per aggiungere studenti, selezionarli in una classe o un plesso ed utilizzare l'operazione massiva "Aggiungi studente in nuova classroom".
              </div>
              <ul class="list-group list-group-lg w-100" *ngIf="studenti?.length > 0">
                <ng-container *ngFor="let element_user of studenti; let index_utente = index;">
                  <li class="list-group-item" [ngClass]="{'alert-soft-danger': element_user.errors?.length > 0}">
                    <ng-container>
                      <div class="row">
                        <div class="col-8 align-self-start">
                          <small class="text-cap"
                            [title]="element_user.fullname">{{element_user.fullname}}</small>
                          <div [title]="element_user.email" class="text-truncate">
                            {{element_user.email}}</div>
                        </div>
                        <div class="col d-flex justify-content-end align-items-center">
                          <button type="button" class="ml-2 btn btn-xs mt-2 btn-outline-danger"
                            [disabled]="model.ownerId == element_user.googleId"
                            (click)="removeUserFromClassroom(element_user);">
                            <i class="fas fa-trash mr-1"></i>
                            Rimuovi
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
