import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
@Component({
  selector: 'app-plesso-profile-datatable-card',
  templateUrl: './plesso-profile-datatable-card.component.html',
  styleUrls: ['./plesso-profile-datatable-card.component.scss']
})
export class PlessoProfileDatatableCardComponent implements OnInit {

  @Input() id: any;

  plesso: any;
  reloadData: boolean;

  classi: any;
  classiData: any;
  classiError: any;

  groups: any;
  groupsData: any;
  groupsError: any;

  classroom: any;
  classroomData: any;
  classroomError: any;

  studentiData: any;
  studenti: any;
  studentiError: any;

  docentiData: any;
  docenti: any;
  docentiError: any;

  usersOrgUnitData: any;
  usersOrgUnit: any;
  usersOrgUnitError: any;

  isLoading = {
    classi: false,
    users: false,
    utenti: false,
    groups: false,
    classroom: false,
    studenti: false,
    docenti: false,
    usersOrgUnit: false,
  }

  private _plessoServiceClassiSubscription: Subscription;
  private _userServiceSubscription: Subscription;

  private _googleServiceOrgunitsSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;
  private _googleServiceGroupsSubscription: Subscription;
  private _googleServiceClassroomSubscription: Subscription;

  constructor(
    private router: Router,
    private googleDirectoryService: GoogleDirectoryService,
    private userService: UserService,
    private plessoService: PlessoService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

    this.unsubscribeAll();

    this._plessoServiceClassiSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });

    this.userService.all();

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe(
      (result) => {
        if (result) {
          this.updateData();
        }
      });

    this._googleServiceOrgunitsSubscription = this.googleDirectoryService.getSubjectToUpdateOrgUnitsObservable().subscribe(
      (result) => {
        if (result) {
          this.updateData();
        }
      });

    this._googleServiceGroupsSubscription = this.googleDirectoryService.getSubjectToUpdateGroupsObservable().subscribe(
      (result) => {
        if (result) {
          this.updateData();
        }
      });

    this.googleData();

    this.selectTab(1);
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  googleData() {
    this.googleDirectoryService.orgunitsList();
    this.googleDirectoryService.groupsList();
    this.googleDirectoryService.usersList();
  }

  setLoading(loading = false) {
    // Loading All
    Object.keys(this.isLoading).forEach(element => {
      this.isLoading[element] = loading;
    });
  }

  updateData() {
    if (this.id && this.id > 0) {
      this.isLoading.classi = true;
      this.isLoading.classroom = true;
      this.isLoading.groups = true;
      this.isLoading.usersOrgUnit = true;

      this.plessoService.get(this.id, (data) => {

        this.plesso = data;

        this.classroomUpdated(this.plesso.google_classroom);
        this.groupsUpdated(this.plesso.google_group);
        this.classiUpdated(this.plesso.classi);
        this.studentiUpdated(this.plesso.classi);
        this.docentiUpdated(this.plesso.classi);
        this.usersOrgUnitUpdated(this.plesso.google_org_unit);

      }, (error) => {
        this.plessoService.all();
      });
    }
  }

  unsubscribeAll() {
    if (this._plessoServiceClassiSubscription)
      this._plessoServiceClassiSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();

    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();

    if (this._googleServiceOrgunitsSubscription)
      this._googleServiceOrgunitsSubscription.unsubscribe();

    if (this._googleServiceClassroomSubscription)
      this._googleServiceClassroomSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
  }

  selectTab(index) {
    switch (index) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        // Carico Struttura Classi Plesso
        this.setLoading(true);
        this.updateData();
        break;
    }
  }

  classiUpdated(result) {
    this.classiData = result;
    this.classi = this.parseClassiResult(result);
    this.isLoading.classi = false;
  }

  studentiUpdated(classi) {
    this.isLoading.studenti = true;

    this.studentiData = [];

    classi.forEach(classe => {
      this.studentiData = [...this.studentiData, ...classe.studenti];
    });

    this.studenti = this.userService.getTable(this.studentiData, "plesso", null, this.plesso);
    this.isLoading.studenti = false;
  }

  docentiUpdated(classi) {
    this.isLoading.docenti = true;

    this.docentiData = [];

    classi.forEach(classe => {
      classe.docenti.forEach(docente => {

        let nuovo = true;

        this.docentiData.forEach(doc => {
          if (doc.id == docente.id) {
            nuovo = false;
          }
        });

        if (nuovo) {
          this.docentiData.push(docente);
        }
      });
    });

    this.docenti = this.userService.getTable(this.docentiData, "plesso", null, this.plesso);
    this.isLoading.docenti = false;
  }

  groupsUpdated(result) {
    this.groupsData = result;
    this.groups = this.parseGroupsResult(result);
    this.isLoading.groups = false;
  }

  classroomUpdated(result) {
    this.classroom = result;
    this.classroomData = this.parseClassroomResult(result);
    this.isLoading.classroom = false;
  }

  usersOrgUnitUpdated(google_org_unit) {

    if (google_org_unit && google_org_unit.orgUnitPath) {
      this.usersOrgUnitData = this.googleDirectoryService.getUsersByOrgUnitPathStartWith(google_org_unit.orgUnitPath);
      this.usersOrgUnit = this.userService.getTable(this.usersOrgUnitData, "orgunit");
    }
    else {
      this.usersOrgUnitData = [];
      this.usersOrgUnit = [];
    }

    this.isLoading.usersOrgUnit = false;
  }

  parseClassiResult(result) {

    let table = [];

    result.forEach(element => {

      let groups = [];

      if (element.google_group.length > 0) {

        groups.push(element.google_group.length + " gruppi associati");

        // cerco i gruppi con relazioni
        element.google_group.forEach(group => {
          switch (group.relation_info) {
            case "docenti":
              groups.push("<b>Docenti: </b>" + group.email + "<i class=\"fas fa-users ml-2 mr-1\"></i>" + group.directMembersCount + "");
              break;
            default:
              break;
          }
        });

        element.google_group.forEach(group => {
          switch (group.relation_info) {
            case "studenti":
              groups.push("<b>Studenti: </b>" + group.email + "<i class=\"fas fa-users ml-2 mr-1\"></i>" + group.directMembersCount + "");
              break;
            default:
              break;
          }
        });

        element.google_group.forEach(group => {
          switch (group.relation_info) {
            case "classe":
              groups.push("<b>Classe: </b>" + group.email + "<i class=\"fas fa-users ml-2 mr-1\"></i>" + group.directMembersCount + "");
              break;
            default:
              break;
          }
        });
      }

      table.push(
        {
          "nome": [element.name, element.anno],
          "gruppi": groups,
          "org_unit": [element.google_org_unit?.name, element.google_org_unit?.orgUnitPath],
          "docenti": [element.docenti.length],
          "studenti": [element.studenti.length],
          "_export_data": {
            name: element.name,
            anno: element.anno,
            orgUnitName: element.google_org_unit?.name,
            orgUnitPath: element.google_org_unit?.orgUnitPath
          },
        });
    });

    return table;
  }

  parseGroupsResult(result) {

    let table = [];

    result.forEach(element => {
      table.push({
        "nome": [element.name, element.description],
        "email": [element.email],
        "membri": [element.directMembersCount],
        "_export_data": {
          name: element.name,
          description: element.description,
          email: element.email,
        },
      })
    });

    return table;
  }

  parseClassroomResult(result) {

    let table = [];

    result.forEach(element => {

      let proprietario = []

      let sotto_nome = (element.section ? element.section : "") + " " + (element.room ? "(" + element.room + ")" : "");

      if (element.ownerId) {
        let googleuser_proprietario = this.googleDirectoryService.getUser(element.ownerId);

        if (googleuser_proprietario) {
          proprietario = [
            googleuser_proprietario.name.familyName + " " + googleuser_proprietario.name.givenName,
            googleuser_proprietario.primaryEmail
          ]
        }
      }

      table.push(
        {
          "nome": [element.name, sotto_nome],
          "description": [element.descriptionHeading, element.description],
          "proprietario": proprietario,
          "_export_data": {
            name: element.name,
            section: element.section,
            descriptionHeading: element.descriptionHeading,
            description: element.description,
            room: element.room,
            proprietarioName: proprietario[0] ? proprietario[0] : "",
            proprietarioEmail: proprietario[1] ? proprietario[1] : "",
            ownerId: element.ownerId,
            link: element.alternateLink,
            courseGroupEmail: element.courseGroupEmail,
            teacherGroupEmail: element.teacherGroupEmail,
            creationTime: element.creationTime,
            updateTime: element.updateTime
          },
        });
    });

    return table;
  }

  rowClickClasse(data) {
    let classeGet = this.plesso.classi[data.index];
    this.router.navigate(['/plesso/' + this.id + '/classe/' + classeGet.id]);
  }

  addClickClass() {
    this.router.navigate(['/plesso/' + this.id + '/classe']);
  }

  rowClickGroup(data) {
    let group = this.plesso.google_group[data.index];
    this.router.navigate(['group/' + group.groupKey]);
  }

  addClickGroup() {
    return;
    //TODO  Creare Form Aggiungere Group
  }

  rowClickClassroom(data) {
    let classroom = this.plesso.google_classroom[data.index];
  }

  addClickClassroom() {
    return;
  }

  rowClickStudente(data) {
    let user = this.studentiData[data.index];
    this.router.navigate(['user/' + user.id], { relativeTo: this.route });
  }

  rowClickDocente(data) {
    let user = this.docentiData[data.index];
    this.router.navigate(['user/' + user.id], { relativeTo: this.route });
  }

  addClickUser(role: any) {
    this.router.navigate(['user/add/' + role], { relativeTo: this.route });
  }

  rowClickUserOrgUnit(data) {
    let userOrgUnit = this.usersOrgUnitData[data.index];
    let user = this.userService.getByGoogleId(userOrgUnit.id);

    if (user) {
      this.router.navigate(['user/' + user.user_id], { relativeTo: this.route });
    }
    else {
      this.router.navigate(['user/google/' + userOrgUnit.id], { relativeTo: this.route });
    }
  }
}
