<!-- Search Form -->
<div class="d-none d-lg-block" *ngIf="getAccountService().isGoogleWorkspaceAccount()">
  <form class="position-relative">
    <!-- Input Group -->
    <div class="input-group input-group-merge navbar-input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <!-- <i class="tio-search"></i> -->
          <i class="fas fa-search"></i>
        </div>
      </div>
      <input autocomplete="off" id="headerSearchInput" #headerSearchInput type="search" class="form-control"
        placeholder="Ricerca in Geniusuite" (focus)="searchFocus()" (keyup)="search(headerSearchInput.value)">
      <a class="input-group-append" (click)="cancellaRicerca()">
        <span class="input-group-text">
          <i id="clearSearchResultsIcon" class="far fa-times" *ngIf="headerSearchInput.value?.length > 0"></i>
        </span>
      </a>
    </div>
    <!-- End Input Group -->

    <!-- Card Search Content -->
    <div id="searchDropdownMenu" *ngIf="showSearchDropdownMenu"
      class="showSearchDropdownMenu card dropdown-menu dropdown-card overflow-hidden">
      <!-- Body -->
      <div class="card-body-height py-3">

        <ng-container *ngIf="!searchResult">

          <ng-container *ngIf="ricercheRecenti?.length > 0">

          <small class="dropdown-header mb-n2">Ricerche recenti</small>

          <div class="dropdown-item bg-transparent text-wrap my-2">
            <span class="h4 mr-1" *ngFor="let searchStringa of ricercheRecenti">
              <a class="btn btn-xs btn-soft-dark btn-pill" (click)="ricercaRecenteClick(searchStringa)">
                {{searchStringa}} <i class="fas fa-search ml-1"></i>
              </a>
            </span>
          </div>

          <div class="dropdown-divider my-3"></div>

        </ng-container>

          <small class="dropdown-header mb-n2">Guide e Tutorial</small>

          <a class="dropdown-item my-2" href="https://www.youtube.com/watch?v=yej0Mh3wsFc&list=PLCt3ItsLXF5yEoBSrcxgUC0tVgDbQJHhK" target="_blank">
            <div class="media align-items-center">
              <span class="icon icon-xs icon-soft-dark icon-circle mr-2">
                <i class="fab fa-youtube"></i>
              </span>

              <div class="media-body text-truncate">
                <span>Guarda tutti i tutorial sul canale YouTube di Geniusuite</span>
              </div>
            </div>
          </a>

          <a class="dropdown-item my-2"  href="https://geniusuite.com/guide-tutorial-geniusuite" target="_blank">
            <div class="media align-items-center">
              <span class="icon icon-xs icon-soft-dark icon-circle mr-2">
                <i class="fas fa-question-circle"></i>
              </span>

              <div class="media-body text-truncate">
                <span>Leggi tutte le guide tutorial sul sito geniusuite.com</span>
              </div>
            </div>
          </a>

          <a class="dropdown-item my-2"  href="https://geniusuite.com/assistenza-geniusuite/?utm_source=geniusuite&utm_medium=link&utm_campaign=assistenza_geniusuite" target="_blank">
            <div class="media align-items-center">
              <span class="icon icon-xs icon-soft-dark icon-circle mr-2">
                <i class="fa-solid fa-headphones"></i>
              </span>

              <div class="media-body text-truncate">
                <span>Contatti e assistenza</span>
              </div>
            </div>
          </a>

        </ng-container>

        <ng-container *ngIf="searchResult && searchResult.length == 0">

          <small class="dropdown-header mb-n2">Nessun risultato</small>

        </ng-container>

        <ng-container *ngIf="searchResult && searchResult.length > 0">

          <small class="dropdown-header mb-n2">Risultati</small>

          <ng-container *ngFor="let item of searchResult">

            <a class="dropdown-item my-2" (click)="openSearchResult(item)">
              <div class="media">
                <div class="avatar avatar-sm avatar-soft-dark avatar-circle mr-3">
                  <span class="avatar-initials">
                    <i class="fas fa-user" *ngIf="item.resourceType == 'user'"></i>
                    <i class="fas fa-school" *ngIf="item.resourceType == 'plesso'"></i>
                    <i class="fas fa-chalkboard" *ngIf="item.resourceType == 'classe'"></i>
                    <i class="fas fa-sitemap" *ngIf="item.resourceType == 'organigramma'"></i>
                    <i class="fab fa-google" *ngIf="item.resourceType == 'google-user'"></i>
                    <i class="fab fa-google" *ngIf="item.resourceType == 'google-group'"></i>
                  </span>
                </div>

                <div class="media-body">
                  <div class="row">
                    <div class="col-9 order-1">
                      <h5 class="mb-0">{{item.primaryField}}</h5>
                      <small class="d-block searchIndexDataMatch"
                        *ngFor="let match of item.searchIndexDataMatch" [innerHtml]="match"></small>
                    </div>

                    <div class="col order-2">
                      <span class="badge badge-soft-info badge-pill"
                        *ngFor="let info of item.additionalInfo">{{info}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>

          </ng-container>

        </ng-container>

      </div>
      <!-- End Body -->

      <!-- Footer -->
      <div class="card-footer text-center" *ngIf="searchResult && searchResult.length > 0">
        {{searchResult?.length}} risultati
        <!-- <i class="fas fa-chevron-right"></i> -->
      </div>
      <a class="card-footer text-center" (click)="chiudiPopup()">
        <small>Chiudi<i class="fas fa-times ml-2"></i></small>
      </a>
      <!-- End Footer -->
    </div>
    <!-- End Card Search Content -->
  </form>
</div>
<!-- End Search Form -->
