import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-rinnovo-licenza',
  templateUrl: './rinnovo-licenza.component.html',
  styleUrls: ['./rinnovo-licenza.component.scss']
})
export class RinnovoLicenzaComponent implements OnInit {


  constructor(
    private accountService: AccountService,
    private router: Router
  ) {

    this.accountService.getUserData((user) => {

      if (user.ente.subscription_active == 1 && !user.ente.subscription_expired) {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnInit() {

  }

  getAccountService() {
    return this.accountService;
  }

}
