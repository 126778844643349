<app-card-loading *ngIf="loading">
</app-card-loading>

<app-card-loading-error *ngIf="!(loading) && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card card-hover-shadow" *ngIf="!(loading) && !error">

    <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>

    <form #myForm="ngForm" (ngSubmit)="onSubmit()" validate>

        <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">
            <div class="row">
                <!-- Left -->
                <div class="col">

                    <!-- Titlte -->
                    <div class="row form-group">
                        <div class="col-12">
                            <h3>Modifica Gruppo</h3>
                        </div>
                    </div>

                    <!-- Nome -->
                    <div class="row form-group">
                        <div class="col-12">
                            <label class="input-label" for="name">Nome</label>
                            <input id="name" name="name" #name="ngModel" class="form-control" [(ngModel)]="data.name"
                                [ngClass]="(name.dirty || name.touched)? (name.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                                type="text" required maxlength="255">

                            <div class="invalid-feedback" *ngIf="name.invalid && (name.dirty || name.touched)">
                                <span>Non valido</span>
                            </div>
                            <span class="valid-feedback"
                                *ngIf="name.valid && (name.dirty || name.touched)">Valido</span>
                        </div>
                    </div>

                    <!-- Descrizione -->
                    <div class="row form-group">
                        <div class="col-12">
                            <label class="input-label" for="description">Descrizione</label>
                            <input id="description" name="description" #description="ngModel" class="form-control"
                                [(ngModel)]="data.description"
                                [ngClass]="(description.dirty || description.touched)? (description.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                                type="text" maxlength="255">

                            <div class="invalid-feedback"
                                *ngIf="description.invalid && (description.dirty || description.touched)">
                                <span>Non valido</span>
                            </div>
                            <span class="valid-feedback"
                                *ngIf="description.valid && (description.dirty || description.touched)">Valido</span>
                        </div>
                    </div>

                    <!-- Email principale -->
                    <div class="form-group row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6">
                                    <label class="input-label">Email Principale</label>
                                    <input [(ngModel)]="data.email_first" class="form-control" name="email_first"
                                        #email_first="ngModel" type="text"
                                        [ngClass]="(email_first.dirty || email_first.touched)? (email_first.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                                        required autocomplete="none" (input)="onChangeEmail()">
                                    <span class="invalid-feedback"
                                        *ngIf="email_first.invalid && (email_first.dirty || email_first.touched)">Non
                                        valido</span>
                                    <span class="valid-feedback"
                                        *ngIf="email_first.valid && (email_first.dirty || email_first.touched)">Valido</span>
                                </div>
                                <div class="col-6">
                                    <label class="input-label">Dominio</label>

                                    <input type="hidden" id="email_domain" name="email_domain" #email_domain="ngModel"
                                        [(ngModel)]="data.email_domain">
                                    <app-select [id]="'select-email_domain'"
                                        [placeholder]="'STR.Seleziona_Dominio_Utente'" [data]="domains"
                                        [showValue]="'domainName'" [resultAttribute]="'domainName'"
                                        [(value)]="data.email_domain" [optionAttributeShow]="'domainName'"
                                        (onChange)="onChangeEmail()"></app-select>

                                    <span class="invalid-feedback"
                                        *ngIf="email_domain.invalid && (email_domain.dirty || email_domain.touched)">Non
                                        valido</span>
                                    <span class="valid-feedback"
                                        *ngIf="email_domain.valid && (email_domain.dirty || email_domain.touched)">Valido</span>
                                </div>

                                <div class="col-12 mt-2" *ngIf="checkEmailExist.exist_email">
                                    <div class="alert alert-soft-danger" role="alert">
                                        <div class="text-muted font-size-1 mt-1">Gruppo con questa Email già esistente
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- Impostazioni Avanzate Utente -->
                    <div class="row form-group">
                        <div class="col-12 text-center">
                            <button class="btn btn-ghost-primary" type="button"
                                (click)="showAdvancedSettings = !showAdvancedSettings"
                                [attr.aria-expanded]="showAdvancedSettings" aria-controls="collapseAdvancedSettings">
                                Impostazioni avanzate
                                <span class="ml-2" [ngSwitch]="showAdvancedSettings">
                                    <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                                    <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                                </span>
                            </button>
                        </div>
                    </div>

                    <div id="collapseAdvancedSettings" [collapse]="!showAdvancedSettings" [isAnimated]="true">

                        <div class="card card-hover-shadow">
                            <div class="card-body">
                                <h3 class="card-title">Alias</h3>
                                <div class="card-text">
                                    <div class="form-group row mb-0">
                                        <div class="col-12">
                                            <blockquote class="blockquote mt-2 mb-2" *ngIf="data.aliases?.length > 0">
                                                <div class="row pb-1" *ngFor="let email of data.aliases; let i = index">
                                                    <div class="col-10 pt-2">{{email}}</div>
                                                    <div class="col-2" style="text-align: center;">
                                                        <button type="button" class="btn btn-soft-danger btn-sm"
                                                            (click)="removeAliasEmail(i)">
                                                            Rimuovi
                                                        </button>
                                                    </div>
                                                </div>
                                            </blockquote>

                                            <div class="row mt-1">
                                                <div class="col-5">
                                                    <label class="input-label">Email Principale</label>
                                                    <input [(ngModel)]="data.alias_email_first" class="form-control"
                                                        name="alias_email_first" #alias_email_first="ngModel"
                                                        type="text"
                                                        [ngClass]="(alias_email_first.dirty || alias_email_first.touched)? (alias_email_first.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                                                         autocomplete="none" (input)="onChangeAliasEmail()">
                                                    <span class="invalid-feedback"
                                                        *ngIf="alias_email_first.invalid && (alias_email_first.dirty || alias_email_first.touched)">Non
                                                        valido</span>
                                                    <span class="valid-feedback"
                                                        *ngIf="alias_email_first.valid && (alias_email_first.dirty || alias_email_first.touched)">Valido</span>
                                                </div>
                                                <div class="col-5">
                                                    <label class="input-label">Dominio</label>

                                                    <input type="hidden" id="alias_email_domain"
                                                        name="alias_email_domain" #alias_email_domain="ngModel"
                                                        [(ngModel)]="data.alias_email_domain">
                                                    <app-select [id]="'select-alias_email_domain'"
                                                        [placeholder]="'STR.Seleziona_Dominio_Utente'" [data]="domains"
                                                        [showValue]="'domainName'" [resultAttribute]="'domainName'"
                                                        [(value)]="data.alias_email_domain"
                                                        [optionAttributeShow]="'domainName'"
                                                        (onChange)="onChangeAliasEmail()"></app-select>

                                                    <span class="invalid-feedback"
                                                        *ngIf="alias_email_domain.invalid && (alias_email_domain.dirty || alias_email_domain.touched)">Non
                                                        valido</span>
                                                    <span class="valid-feedback"
                                                        *ngIf="alias_email_domain.valid && (alias_email_domain.dirty || alias_email_domain.touched)">Valido</span>
                                                </div>
                                                <div class="col-2" style="margin-top: 34px;text-align: center;">
                                                    <button type="button" class="btn btn-soft-primary btn-sm" [disabled]="alias_email_first.invalid"
                                                        (click)="saveAliasEmail()">
                                                        Aggiungi
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="checkEmailExist.exist_email">
                                                <div class="col-12 mt-2">
                                                    <div class="alert alert-soft-danger" role="alert">
                                                        <div class="text-muted font-size-1 mt-1">Gruppo con questa Email
                                                            già
                                                            esistente
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right Edit -->
                <div class="col-5 p-3 mt-3 mr-3 ml-3 bg-light rounded-lg">
                    <!-- Titlte -->
                    <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Sincronizzazione con Google Workspace</h4>

                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-soft-info" role="alert">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at felis sodales, imperdiet eros posuere, facilisis arcu.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-footer border-0 mx-3">
            <div class="row justify-content-between align-items-center">
                <div class="col-auto pl-0">
                    <button *ngIf="id" [routerLink]="['/group/'+id]" type="button" class="btn btn-white">
                        <i class="far fa-chevron-left mr-1"></i> Annulla
                    </button>
                </div>
                <div class="col-6 py-1">
                    <div class="row justify-content-end">
                        <button type="submit" class="btn" [disabled]="!myForm.form.valid"
                            [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                            <span>Aggiorna</span>
                            <i class="far fa-chevron-right ml-1"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
