import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { DeleteGroupsService } from 'src/app/services/operations/groups/delete-groups.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-delete-groups',
  templateUrl: './delete-groups.component.html',
  styleUrls: ['./delete-groups.component.scss']
})
export class DeleteGroupsComponent implements OnInit {
  regEx = ConfigService.regExDelete;

  error: any;
  _loadingData = {
    groups: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  data: any = [];
  groups: any;
  model: any;

  operationInProgress: boolean = false;

  private _deleteGroupsServiceSubscription: Subscription;
  private _googleDirectoryServiceSubscription: Subscription;


  constructor(
    private deleteGroupsService: DeleteGroupsService,
    private googleDirectoryService: GoogleDirectoryService,
    private syncdataService: SyncdataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.unsubscibeAll();
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.groups = this.deleteGroupsService.items;
    this.data = this.parseGroupsResult(this.groups);
  }

  parseGroupsResult(result) {

    let table = [];

    result.forEach(element => {
      let group = this.getInfoGroup(element);

      table.push({
        "nome": [group.name, group.description],
        "email": [group.email],
        "membri": [group.directMembersCount],
        "_export_data": {
          name: group.name,
          description: group.description,
          email: group.email,
        },
      })
    });

    return table;
  }

  deleteEvent(rows) {
    this.deleteGroupsService.deleteItemsAtIndex(rows);
  }

  onSubmit(myForm: NgForm) {

    if (myForm.valid) {

      this.operationInProgress = true;

      this.deleteGroupsService.execOperation((error) => {
        if (!error) {
          this.ngOnInit();
        }
      });
    }
  }

  resetOperation() {
    this.deleteGroupsService.reset();
  }

  unsubscibeAll() {


    if (this._deleteGroupsServiceSubscription)
      this._deleteGroupsServiceSubscription.unsubscribe();


  }

  init() {
    this._deleteGroupsServiceSubscription = this.deleteGroupsService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated();
      }
    });

    this._googleDirectoryServiceSubscription = this.googleDirectoryService.getSubjectToUpdateGroupsObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.googleDirectoryService.groupsList();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  getInfoGroup(userInfo) {
    let resUser = {
      id: null,
      name: null,
      description: null,
      email: userInfo.email,
      directMembersCount: 0
    };


    let googleGroup = this.googleDirectoryService.getGroupByEmail(resUser.email);
    if (googleGroup) {
      // Non sincronizzato con Geniusuite ma non ha già i dati caricati
      resUser.id = googleGroup.id ? googleGroup.id : null;
      resUser.name = googleGroup.name ? googleGroup.name : "-";
      resUser.directMembersCount = googleGroup.directMembersCount ? googleGroup.directMembersCount : 0;
      resUser.description = googleGroup.description ? googleGroup.description : "-";
      resUser.email = googleGroup.email;
    } else {
      resUser.name = "-";
      resUser.email = "-";
    }

    return resUser;
  }
}
