<div #scrollContainerProfilePage *ngIf="!showForm">
    <div class="row">
        <div class="col-md-12 col-sm mb-1">
            <div class="mb-3">
                <app-group-profile-info-card [id]="id"></app-group-profile-info-card>
            </div>
        </div>

        <div class="col-md-12 col-sm">
            <app-group-profile-datatable-card [id]="id"></app-group-profile-datatable-card>
        </div>
    </div>
</div>

<div *ngIf="showForm">
    <app-group-form [id]="id"></app-group-form>
</div>
