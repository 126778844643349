<app-card-loading *ngIf="loading">
</app-card-loading>


<app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">
  <div class="row">
    <div class="col-12">
      <app-card-operation-activity [filterActivity]="['operation.classroom.update-classroom']">
      </app-card-operation-activity>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <div class="card card-hover-shadow">
        <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>
        <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" validate>
          <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">
            <h3 class="card-title">Sospendi Classroom</h3>
            <p class="card-text">Questa operazione consente di sospendere in maniera massiva tutti le
              classroom selezionati</p>
          </div>

          <div class="card-footer">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto py-1">
                <button class="btn btn-outline-primary" type="reset" (click)="resetOperation()"
                  [disabled]="item?.length <= 0 || operationInProgress">
                  <i class="far fa-backspace mr-2"></i>
                  <span>{{'STR.reset_operation' | translate}}</span>
                </button>
              </div>

              <div class="col-6 py-1">
                <div class="row justify-content-end">
                  <div class="col-auto">
                    <input autocomplete="off" type="text" id="confirmation_delete" name="confirmation_delete"
                      #confirmation_delete="ngModel" [ngModel]="''" class="form-control" [pattern]="regEx" required>
                    <small class="d-block text-muted">Per sospendere, digita
                      <span class="confirmation-phrase">Sospendi</span></small>
                  </div>
                  <div class="col-auto">
                    <button type="submit" class="btn"
                      [disabled]="!myForm.valid || item?.length <= 0 || operationInProgress"
                      [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary'">
                      Sospendi classroom <i class="far fa-chevron-right ml-1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-sm">
      <div class="card">
        <app-card-datatable-ng title="{{ 'STR.classroom_selezionate' | translate }}" [data]="data"
          [showDeleteButton]="true" labelDeleteButton="Rimuovi dall'operazione" iconDeleteButton="times"
          (deleteClickEvent)="deleteEvent($event)" [showExportButton]="true"></app-card-datatable-ng>
      </div>
    </div>
  </div>
</ng-container>
