import { Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { ParametriService, ParamType } from 'src/app/services/general/parametri.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { RelationInfo } from 'src/app/services/general/relation.service';
import { UtilityService, OrderTypeList } from 'src/app/services/utility.service';
import { PageService } from 'src/app/services/page.service';
import { SyncdataService } from 'src/app/services/syncdata.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-organigramma-form-delete',
  templateUrl: './organigramma-form-delete.component.html',
  styleUrls: ['./organigramma-form-delete.component.scss']
})
export class OrganigrammaFormDeleteComponent implements OnInit, OnChanges, OnDestroy {
  regEx = ConfigService.regExDelete;
  @Input() id: string;

  organigramma: any;
  organigrammaParent: any;
  dataDelete: any;
  listOrganigrammi: any;
  organigrammiTreeView: any;
  error: any;

  // Visualizzazione Setting Advanced
  showAdvancedSettings: boolean;

  // Dati Google e Stuttura di View (TreeView e Preview)
  orgUnits: any;
  orgUnitParentSelected: any;
  orgUnitParentCheck: boolean;
  orgUnitsTreeView: any;
  controlNameOrgUnit: string;
  groups: any;
  groupsPreview: any;
  groupNotExist: any;

  // Loading Pagina
  loadingOrganigramma: boolean;
  loadingOrganigrammaService: boolean;
  loadingParameterService: boolean;
  loadingGoogleOrgUnits: boolean;
  loadingGoogleGroups: boolean;
  formLoading: boolean;
  edit: boolean;
  parameterLoaded: boolean;

  // Configurazione TreeView
  configTreeView: any = {
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: true
  };

  private _googleServiceOrgUnitsSubscription: Subscription;
  private _googleServiceGroupsSubscription: Subscription;
  private _organigrammaServiceSubscription: Subscription;
  private _parameterServiceSubscription: Subscription;


  constructor(private zone: NgZone,
    private utilityService: UtilityService,
    private router: Router,
    private organigrammaService: OrganigrammaService,
    private parametriService: ParametriService,
    private googleService: GoogleDirectoryService,
    private pageService: PageService,
    private syncdataService: SyncdataService,
  ) { }

  ngOnInit() {

    this.unsubscibeAll();
    this.dataDelete = {
      orgUnit: false,
      orgUnitUser: false,
      organigrammaUserDestination: {
        id: null,
        name: null,
        orgUnitPath: null,
      }, // Destinazione Utenti se orgUnitUser è true
      group: false,
      subOrganigramma: false,
      classroom: false,
    };

    this.organigramma = {};
    this.organigrammaParent = null;

    this.error = null;
    this.loadingOrganigramma = false;
    this.loadingOrganigrammaService = false;
    this.loadingParameterService = false;
    this.loadingGoogleOrgUnits = false;
    this.loadingGoogleGroups = false;
    this.formLoading = false;
    this.edit = false;
    this.parameterLoaded = false;
    this.showAdvancedSettings = false;
    this.orgUnitParentCheck = true;
    this.controlNameOrgUnit = "";

    this.groupNotExist = {
      groupKey_all: false
    };

    if (this.id && this.id.length > 0) {

      this.getBackendData();
    }

    this._googleServiceOrgUnitsSubscription = this.googleService.getSubjectToUpdateOrgUnitsObservable().subscribe((result) => {
      if (!result)
        this.googleError();
      else
        this.orgUnitsUpdated(result);
    });

    this._googleServiceGroupsSubscription = this.googleService.getSubjectToUpdateGroupsObservable().subscribe((result) => {
      if (!result)
        this.googleError();
      else
        this.groupsUpdated(result)
    });

    this.getGoogleData();

    this._organigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError("Organigramma Service");
      else
        this.organigrammaServiceUpdated(result);
    })

    this.loadingOrganigrammaService = true;
    this.organigrammaService.all();

    this._parameterServiceSubscription = this.parametriService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError("Parameter Service");
      else
        this.parametriServiceUpdated(result);
    })

    this.loadingParameterService = true;
    this.parametriService.all();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
    else if (changes && changes.relation_info_standard && changes.relation_info_standard.currentValue && changes.relation_info_standard.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  orgUnitsUpdated(result) {
    this.orgUnits = result;
    this.orgUnitsTreeView = this.utilityService.covertListToTreeViewItems(this.orgUnits, "orgUnitPath", "parentOrgUnitPath", "name", "orgUnitId", "/", OrderTypeList.Ascending, "name");
    this.loadingGoogleOrgUnits = false;
  }

  organigrammaServiceUpdated(result) {
    this.listOrganigrammi = result;
    this.loadingOrganigrammaService = false;

    if (this.listOrganigrammi) {
      let filtered = this.listOrganigrammi.filter(x => x.google_org_unit && x.google_org_unit.orgUnitId);
      this.organigrammiTreeView = this.utilityService.covertListToTreeViewItems(filtered, "id", "parent_id", "name", "id", null, OrderTypeList.Ascending, "name");
    }

    this.getBackendData();
  }

  groupsUpdated(result) {
    this.groups = result;
    this.loadingGoogleGroups = false;
  }

  parametriServiceUpdated(result) {
    this.loadingParameterService = false;
    this.parameterLoaded = true;
  }

  unsubscibeAll() {
    if (this._googleServiceOrgUnitsSubscription)
      this._googleServiceOrgUnitsSubscription.unsubscribe();

    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();

    if (this._organigrammaServiceSubscription)
      this._organigrammaServiceSubscription.unsubscribe();

    if (this._parameterServiceSubscription)
      this._parameterServiceSubscription.unsubscribe();


  }

  changeStatusGroupGest() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  changeDataValue(type) {
    this.dataDelete[type] = !this.dataDelete[type];
  }

  getBackendData() {

    this.loadingOrganigramma = true;

    this.organigrammaService.get(this.id, (result) => {

      this.organigramma = result;
      this.loadingOrganigramma = false;

      if (this.organigramma.parent_id && this.organigramma.parent_id != this.id) {
        this.organigrammaService.get(this.organigramma.parent_id, (result) => {
          this.organigrammaParent = result;
        }, (error) => {
          this.organigrammaParent = null;
        });
      }
      else {
        this.organigrammaParent = null;
      }
    }, (error) => {
      this.loadingOrganigramma = true;
      this.organigrammaService.all();
      this.backendError("Get Organigramma Service");
      this.error = null;
    });
  }

  getGoogleData() {

    this.loadingGoogleOrgUnits = true;
    this.loadingGoogleGroups = true;

    this.googleService.orgunitsList();
    this.googleService.groupsList();
  }

  googleError() {
    this.error = "google";
    this.loadingGoogleOrgUnits = false;
    this.loadingGoogleGroups = false;
  }

  backendError(position) {
    this.error = "backend " + position;
    this.formLoading = false;
    this.loadingOrganigramma = false;
  }

  onSubmit(myForm: NgForm) {

    if (myForm.valid) {
      this.formLoading = true;

      // Copio i dati dell'oggetto senza riferimento
      let reqBody = JSON.parse(JSON.stringify(this.dataDelete));

      // Delete
      this.organigrammaService.delete(this.id, reqBody, true, (result) => {
        this.navigateAfterSubmit();
        this.formLoading = false;
      }, (error) => this.backendError("Submit"))
    }
  }
  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  navigateAfterSubmit() {
    if (this.id && this.id.length > 0) {
      this.router.navigate([`/organigramma`]);
    }
    else {
      this.pageService.back();
    }
  }

  onSelectedChangeRelationInfo(value) {
    this.dataDelete.organigrammaUserDestination = {
      id: null,
      orgUnitPath: null,
      name: null,
    };

    if (value) {
      this.dataDelete.organigrammaUserDestination = {
        id: value.id,
        orgUnitPath: value.google_org_unit.orgUnitPath,
        name: value.name,
      };
    }
  }
}

