import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { SyncUsersFromGoogleService } from 'src/app/services/operations/sync-users-from-google.service';
import { OrderTypeList, UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-sync-users-from-google',
  templateUrl: './sync-users-from-google.component.html',
  styleUrls: ['./sync-users-from-google.component.scss']
})
export class SyncUsersFromGoogleComponent implements OnInit {
  error: any;
  _loadingData = {
    plessi: false,
    organigrammi: false
  };

  formLoading: boolean = false;

  tabSelected: string;
  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }
  organigrammi: any;
  organigrammiTreeView: any;
  userTypeSelect: any = [];
  plessi: any = [];
  data: any = [];
  users: any;
  model: any;
  myForm: FormGroup = new FormGroup({});

  operationInProgress: boolean = false;
  errorSynchronizedAccount: boolean = false;

  private _syncUsersFromGoogleSubscription: Subscription;
  private _userServiceSubscription: Subscription;

  private _plessoServiceSubscription: Subscription;
  private _oganigrammaServiceSubscription: Subscription;

  constructor(
    private syncUsersFromGoogle: SyncUsersFromGoogleService,
    private userService: UserService,
    private directoryService: GoogleDirectoryService,
    private plessoService: PlessoService,
    private utilityService: UtilityService,
    private organigrammaService: OrganigrammaService
  ) { }

  ngOnInit() {
    this.initForm();
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.errorSynchronizedAccount = false;
    this.users = this.syncUsersFromGoogle.items;
    this.data = this.userService.getTable(this.users, "orgunit");

    this.data.forEach(row => {
      if (row.sync.geniusuite) {
        this.errorSynchronizedAccount = true;
      }
    });
  }

  deleteEvent(rows) {
    this.syncUsersFromGoogle.deleteItemsAtIndex(rows);
    this.dataUpdated();
  }

  onSubmit() {
    if (this.myForm.valid) {
      this.formLoading = true;
      let reqObj = {
        userType: this.model.userType,
        classe_id: this.tabSelected == "classe" ? this.model.classe.id : null,
        organigramma_id: this.tabSelected == "organigramma_id" ? this.model.organigramma_id : null
      }

      this.operationInProgress = true;

      this.syncUsersFromGoogle.execOperation(reqObj, (error) => {
        this.formLoading = false;
        this.operationInProgress = false;
        if (!error) {
          this.initForm();
        }
      });
    }
  }

  resetOperation() {
    this.syncUsersFromGoogle.reset();
  }

  unsubscibeAll() {


    if (this._syncUsersFromGoogleSubscription)
      this._syncUsersFromGoogleSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();

    if (this._oganigrammaServiceSubscription)
      this._oganigrammaServiceSubscription.unsubscribe();
  }

  initForm() {
    //  inizializzo le tipologie di utente disponibili
    this.userTypeSelect = ConfigService.userTypeSelect;
    this.tabSelected = "classe";
    this.formLoading = false;
    this.operationInProgress = false;
    this.errorSynchronizedAccount = false;

    this.model = {
      organigramma_id: null,
      plesso: null,
      classe: null,
      userType: null
    }

    this.myForm = new FormGroup({
      plesso: new FormControl(this.model.plesso),
      classe: new FormControl(this.model.classe, [Validators.required]),
      organigramma_id: new FormControl(this.model.organigramma_id),
      userType: new FormControl(this.model.userType, [Validators.required])
    });
  }

  init() {

    this._syncUsersFromGoogleSubscription = this.syncUsersFromGoogle.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.dataUpdated();

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this._loadingData.plessi = false;
        this.plessi = result;
      }
    })

    this._oganigrammaServiceSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.onOrganigrammaUpdated(result);
    });
    this.organigrammaService.all();

    this._loadingData.plessi = true;
    this.plessoService.all();
  }

  plessoOnSelect($event) {
    this.myForm.get("plesso").setValue($event);
  }

  classiPlessoOnSelect($event) {
    this.myForm.get("classe").setValue($event);
  }

  organigrammaOnSelect($event) {
    this.myForm.get("organigramma_id").setValue($event);
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  userTypeOnChange($event) {
    this.myForm.get("userType").setValue($event);

    if (this.model.userType == 'worker') {
      this.selectTab('organigramma_id');
    }
  }

  onOrganigrammaUpdated(result) {
    this.organigrammi = result;
    this.organigrammiTreeView = this.utilityService.convertListToTree(result, "id", "parent_id", OrderTypeList.Ascending, "name", true, "name", "id");
  }

  setRequiredForm(type) {
    if (type != "organigramma_id") {
      this.myForm.get("organigramma_id").clearValidators();
      this.myForm.get("organigramma_id").updateValueAndValidity();
      this.myForm.get("organigramma_id").setValidators(null);
      this.myForm.get("organigramma_id").updateValueAndValidity();
    } else {
      this.myForm.get("classe").clearValidators();
      this.myForm.get("classe").updateValueAndValidity();
      this.myForm.get("classe").setValidators(null);
      this.myForm.get("classe").updateValueAndValidity();
    }

    this.myForm.get(type).clearValidators();
    this.myForm.get(type).updateValueAndValidity();
    this.myForm.get(type).setValidators([Validators.required]);
    this.myForm.get(type).updateValueAndValidity();
  }

  selectTab($event) {
    this.tabSelected = $event;
    this.setRequiredForm($event);
  }
}
