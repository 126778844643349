import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { ActivityService } from 'src/app/services/activity.service';
import { PassaggioAnnoService } from 'src/app/services/operations/passaggio-anno.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-passaggio-anno-wizard-start',
  templateUrl: './passaggio-anno-wizard-start.component.html',
  styleUrls: ['./passaggio-anno-wizard-start.component.scss']
})
export class PassaggioAnnoWizardStartComponent implements OnInit {

  syncIntegrityErrors: any;
  checkInProgress: boolean = true;

  private _syncdataServiceSubscription: Subscription;

  constructor(
    private router: Router,
    private passaggioAnnoService: PassaggioAnnoService,
    private activity: ActivityService,
    private syncdataService: SyncdataService
  ) { }

  ngOnInit() {
    this.passaggioAnnoService.checkActivity();
    this.passaggioAnnoService.resetOrganigramma();

    if (this.passaggioAnnoService.getActivityExist())
      if (this.passaggioAnnoService.getActivityInProgress())
        this.router.navigate(['/passaggio-anno/wait']);
      else
        this.router.navigate(['/passaggio-anno/finish']);

    this._syncdataServiceSubscription = this.syncdataService.getSubjectToUpdateObservableSyncIntegrity().subscribe((errors) => {
      this.syncIntegrityErrors = errors;
      this.checkInProgress = false;
    });

    this.syncdataService.updateSystemDataAndCheckIntegrity();
  }

  ngOnDestroy() {

    if (this._syncdataServiceSubscription) {
      this._syncdataServiceSubscription.unsubscribe();
    }
  }

  next() {
    this.router.navigate(['/passaggio-anno/backup']);
  }

  showSidebar() {
    this.activity.showSidebar = true;
  }

}
