<div class="card card-hover-shadow mb-3 border-0">
  <!-- Header -->
  <div class="card-header bg-card-header">
    <h4 class="card-header-title text-white"><i
        class="fas fa-user-unlock mr-2"></i>{{ 'STR.richieste_cambio_password' | translate }}</h4>
    <small class="text-white">{{ richieste?.length }} richieste da gestire</small>
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div class="card-body" style="min-height: 100px;">

    <ul class="list-group list-group-flush list-group-no-gutters" *ngIf="users?.length > 0">
      <li class="list-group-item" *ngFor="let item of users">
        <a class="media align-items-center">
          <div class="avatar avatar-soft-primary avatar-circle mr-3">
            <span class="avatar-initials">{{ item.last_name | slice:0:1 }}{{ item.first_name | slice:0:1 }}</span>
          </div>
          <div class="media-body">
            <span class="d-block h5 text-hover-primary mb-0">{{item.last_name}} {{ item.first_name }}</span>
            <span class="d-block text-body font-size-sm">{{item.email}}</span>
          </div>
        </a>
      </li>
    </ul>

    <div *ngIf="link && (!users || users.length == 0)" class="text-center">
      <img class="mb-2" src="./assets/theme/svg/illustrations/hi-five.svg" style="width: 5rem;">
      <p class="mb-0">Non ci sono richieste</p>
    </div>

    <div *ngIf="!link" class="alert alert-soft-warning mt-0 mb-0">
      <span class="text-cap d-block">Funzionalità non abilitata</span>
      Puoi richiedere l'abilitazione scrivendo una mail a info@geniusuite.com o nella chat in basso.
    </div>
  </div>
  <!-- End Body -->

  <div class="card-footer">
    <div class="row justify-content-between align-items-center">
      <div class="col-auto py-1">
        <small class="text-body">Ultimo aggiornamento: {{ last_update | date:"medium" }}</small>
      </div>

      <div class="col-auto py-1">
        <button [disabled]="!link" routerLink="/req-change-password" class="btn btn-xs btn-primary">Visualizza tutte</button>
      </div>
    </div>
  </div>


</div>
