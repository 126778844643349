import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(text: string, by: string, index: number = 1): any {
    let arr = text.split(by);
    if (index < 0)
      return arr.join(' • ');
    return arr[index];
  }

}
