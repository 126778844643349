<app-card-loading
  *ngIf="loadingOrganigramma || loadingGoogleOrgUnits || loadingGoogleGroups || loadingOrganigrammaService || loadingParameterService || loadingMaterie || loadingPlessi">
</app-card-loading>

<app-card-loading-error
  *ngIf="!(loadingOrganigramma || loadingGoogleOrgUnits || loadingGoogleGroups || loadingOrganigrammaService || loadingParameterService || loadingMaterie || loadingPlessi) && error"
  [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card card-hover-shadow"
  *ngIf="!(loadingOrganigramma || loadingGoogleOrgUnits || loadingGoogleGroups || loadingOrganigrammaService || loadingParameterService || loadingMaterie || loadingPlessi) && !error">

  <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>

  <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" validate>

    <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">
      <div class="row">
        <!-- Left -->
        <div class="col">

          <!-- Titlte -->
          <div class="row form-group">
            <div class="col-12">
              <h3 *ngIf="!edit">Crea Organigramma</h3>
              <h3 *ngIf="edit">Modifica Organigramma</h3>
            </div>
          </div>

          <!-- Nome -->
          <div class="row form-group">
            <div class="col-12">
              <label class="input-label" for="name">Nome</label>
              <input id="name" name="name" #name="ngModel" class="form-control" [(ngModel)]="data.name"
                [ngClass]="(name.dirty || name.touched)? (name.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                type="text" required maxlength="255" [forbiddenString]="listOrganigrammiFiltered"
                [forbiddenGoogle]="listOrgUnitFilteredName" (input)="inputName()"  (focusout)="checkGroupSetOnFocusOut()">

              <div class="invalid-feedback" *ngIf="name.invalid && (name.dirty || name.touched)">
                <span>Non valido</span>
                <span *ngIf="name.errors.forbiddenString; else checkOrgUnit">, Organigramma già esistente</span>
                <ng-template #checkOrgUnit>
                  <span *ngIf="name.errors.forbiddenGoogle">, Unita Organizzativa già esistente</span>
                </ng-template>
              </div>

              <span class="valid-feedback" *ngIf="name.valid && (name.dirty || name.touched)">Valido</span>
            </div>
          </div>

          <!-- Descrizione -->
          <div class="row form-group">
            <div class="col-12">
              <label class="input-label" for="description">Descrizione</label>
              <input id="description" name="description" #description="ngModel" [(ngModel)]="data.description"
                [ngClass]="(description.dirty || description.touched)? (description.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                type="text" class="form-control" maxlength="255" (input)="onInputDescription()">
              <span class="invalid-feedback"
                *ngIf="description.invalid && (description.dirty || description.touched)">Non
                valido</span>
            </div>
          </div>

          <!-- Tipo Relazione Selzione -->
          <div class="row form-group" *ngIf="!relation_info_standard">
            <div class="col-12">
              <label class="input-label" for="relation_info">Organigramma padre</label>

              <app-custom-dropdown-treeview [items]="organigrammiTreeView"
                (selectedChange)="onSelectedRelationInfo($event)" [(value)]="data.relation_info"
                [configs]="configTreeView">
              </app-custom-dropdown-treeview>

              <input type="hidden" id="relation_info" name="relation_info" #relation_info="ngModel"
                [(ngModel)]="data.relation_info">

              <span class="invalid-feedback" *ngIf="data.relation_info">Non
                valido</span>
              <span class="valid-feedback" *ngIf="!data.relation_info">Valido</span>
              <span class="text-muted font-size-sm">Scegli il tipo di Organigramma</span>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-12">
              <button class="btn btn-outline-primary" type="button"
                (click)="changeStatusGroupGest(); loadGroupsPreview()" [attr.aria-expanded]="showAdvancedSettings"
                aria-controls="collapseAdvancedSettings">
                Modifica impostazioni standard
                <span class="ml-2" [ngSwitch]="showAdvancedSettings">
                  <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                  <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                </span>
              </button>
            </div>
          </div>

          <div class="row form-group" id="collapseAdvancedSettings" [collapse]="!showAdvancedSettings"
            [isAnimated]="true">
            <div class="col-12">

              <!-- Creare OrgUnit -->
              <div class="row form-group">
                <div class="col-12">
                  <h4>Unità organizzativa</h4>
                  <!-- Nuova org unit standard -->
                  <label class="toggle-switch d-flex" for="gestOrgUnit">
                    <input id="gestOrgUnit" [(ngModel)]="defaultCheckOrgUnit.crea"
                      (change)="changeCreateOrgUnit()" name="gestOrgUnit"
                      type="checkbox" class="toggle-switch-input">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">Crea nuova unità</span>
                      <small class="d-block text-muted">Se si vuole associare un'unità organizzativa esistente,
                        disattivare l'opzione</small>
                    </span>
                  </label>
                </div>
              </div>

              <!-- Associare OrgUnit Esistente -->
              <div *ngIf="!defaultCheckOrgUnit.crea" class="row form-group">
                <div class="col-12">
                  <label class="input-label" for="orgUnitId">Unità organizzativa esistente <span *ngIf="this.relation_info_standard">(opzionale)</span></label>

                  <app-custom-dropdown-treeview [items]="orgUnitsTreeView" [(value)]="data.orgUnitId"
                    (selectedChange)="onSelectedOrgUnitId($event)" (valueChange)="onValueChangeOrgUnit($event)"
                    [configs]="configTreeView">
                  </app-custom-dropdown-treeview>

                  <input type="hidden" id="orgUnitId" name="orgUnitId" #orgUnitId="ngModel" [(ngModel)]="data.orgUnitId" [required]="this.relation_info_standard">

                  <span class="invalid-feedback" *ngIf="data.orgUnitId">Non
                    valido</span>
                  <span class="valid-feedback" *ngIf="!data.orgUnitId">Valido</span>
                  <span class="text-muted font-size-sm">Scegli l'unità organizzativa da associare al plesso</span>
                </div>

                <!-- Modifica Nome OrgUnit in base ad Entità -->
                <div class="col-12 mt-3" *ngIf="edit">
                  <div class="custom-control custom-checkbox">
                    <input [ngModel]="false" id="changeNameOrgUnit" name="changeNameOrgUnit"
                      #changeNameOrgUnit="ngModel" type="checkbox" class="custom-control-input">
                    <label class="custom-control-label" for="changeNameOrgUnit">Modifica Nome Unità
                      organizzativa</label>
                    <div class="text-muted font-size-1">Cambia il nome dell'Unità organizzativa in base al nome
                      dell'Organigramma
                    </div>
                  </div>
                </div>
              </div>

              <!-- Aggiungere alla Radice OrgUnit Nuova -->
              <div *ngIf="defaultCheckOrgUnit.crea">
                <div class="row form-group">
                  <div class="col-12">
                    <!-- Padre standard radice -->
                    <label class="toggle-switch d-flex" for="parentOrgUnitStandard">
                      <input [ngModel]="true" id="parentOrgUnitStandard" name="parentOrgUnitStandard"
                        #parentOrgUnitStandard="ngModel" type="checkbox" class="toggle-switch-input"
                        (change)="setSelectedParentOrgUnitId()">
                      <span class="toggle-switch-label">
                        <span class="toggle-switch-indicator"></span>
                      </span>
                      <span class="toggle-switch-content">
                        <span class="d-block">Aggiungi nella radice</span>
                        <small class="d-block text-muted">La nuova unità organizzativa verrà aggiunta
                          nella
                          radice, disattivando
                          questa opzioni è possibile specificare un'unità organizzativa esistente come
                          padre</small>
                      </span>
                    </label>
                  </div>
                </div>

                <div *ngIf="orgUnitParentSelected && parentOrgUnitStandard.value">
                  <div class="row form-group mb-2">
                    <div class="col-12">
                      <small>Verrà creato sotto l'Unità organizzativa:</small>
                      <blockquote class="blockquote mt-2">
                        <p>{{orgUnitParentSelected.name}}</p>
                      </blockquote>
                    </div>
                  </div>
                </div>

                <div *ngIf="!parentOrgUnitStandard.value" class="row form-group">
                  <div class="col-12">
                    <!-- Padre esistente diverso da radice -->
                    <label class="input-label" for="parentOrgUnitId">Unità organizzativa padre</label>
                    <app-custom-dropdown-treeview [items]="orgUnitsTreeView" [(value)]="data.parentOrgUnitId"
                      (selectedChange)="onSelectedParentOrgUnitId($event)" [configs]="configTreeView">
                    </app-custom-dropdown-treeview>

                    <input type="hidden" id="parentOrgUnitId" name="parentOrgUnitId" #parentOrgUnitId="ngModel"
                      [(ngModel)]="data.parentOrgUnitId" required>

                    <span class="invalid-feedback"
                      *ngIf="parentOrgUnitId.invalid && (parentOrgUnitId.dirty || parentOrgUnitId.touched)">Non
                      valido</span>
                    <span class="valid-feedback"
                      *ngIf="parentOrgUnitId.valid && (parentOrgUnitId.dirty || parentOrgUnitId.touched)">Valido</span>
                    <span class="text-muted font-size-sm">Scegli l'unità organizzativa padre</span>
                  </div>
                </div>
              </div>

              <!-- Gestione Gruppi -->
              <div class="row form-group">
                <div class="col-12">
                  <div class="mt-1">
                    <!-- Studenti -->
                    <div class="row form-group" [hidden]="edit">
                      <!-- Crea nuovo Gruppo -->
                      <div class="col-12">
                        <h4>Gruppo Utenti</h4>
                        <label class="toggle-switch d-flex" for="newGroupStandard_U">
                          <input [(ngModel)]="defaultCheckGroup.all" id="newGroupStandard_U" name="newGroupStandard_U"
                           type="checkbox" class="toggle-switch-input">
                          <span class="toggle-switch-label">
                            <span class="toggle-switch-indicator"></span>
                          </span>
                          <span class="toggle-switch-content">
                            <span class="d-block">Nuovo gruppo Utenti</span>
                            <small class="d-block text-muted">Verrà creato un nuovo gruppo secondo i
                              parametri
                              scelti</small>
                          </span>
                        </label>
                        <blockquote *ngIf="defaultCheckGroup.all && groupsPreview" class="blockquote mt-2">
                          <small>Anteprima Gruppo Utenti</small>
                          <p>{{groupsPreview.user}}</p>
                        </blockquote>
                      </div>
                    </div>

                    <div *ngIf="!defaultCheckGroup.all || edit" class="row form-group">
                      <!-- Selziona Gruppo -->
                      <div class="col-12">
                        <label class="input-label" for="groupKey_all">Gruppo Organigramma Esistente</label>

                        <input type="hidden" id="groupKey_all" name="groupKey_all" #groupKey_all="ngModel"
                          [(ngModel)]="data.groupKey_all" [required]="!edit">
                        <app-select (valueChange)="selectGroupOnChange($event)" [id]="'select-groupKey_all'"
                          [showClear]="true" [filterBy]="'email'" [placeholder]="'Seleziona Gruppo'" [data]="groups"
                          [showValue]="'email'" [resultAttribute]="'id'" [(value)]="data.groupKey_all"
                          [optionAttributeShow]="'name, email'">
                        </app-select>

                        <blockquote *ngIf="groupNotExist['groupKey_all']" class="blockquote mt-2">
                          <small>Verrà creato il gruppo</small>
                          <p>{{data.groupKey_all}}</p>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="demo">
            <div class="row form-group">
              <div class="col-12">
                <h3>Aggiungi Regola</h3>
                <p>Nell'organigramma verranno aggiunti automaticamente gli utenti con le seguenti caratteristiche</p>
              </div>
            </div>

            <!-- Tipologia di utente -->
            <div class="form-group row">
              <div class="col-12">
                <label class="input-label">Tipologia utente</label>
                <input type="hidden" id="userType" name="userType" #userType="ngModel" [(ngModel)]="data.userType">
                <app-select [id]="'select-userType'" [placeholder]="'STR.Seleziona_Tipologia_Utente'"
                  [data]="userTypeSelect" [showValue]="'value'" [resultAttribute]="'key'" [(value)]="data.userType"
                  [optionAttributeShow]="'value'" [showClear]="false"></app-select>
              </div>
            </div>

            <!-- Materie -->
            <div class="row form-group">
              <div class="col-12">
                <label class="input-label" for="materieSelected">Materie</label>
                <input type="hidden" id="materieSelected" name="materieSelected" #materieSelected="ngModel"
                  [(ngModel)]="data.materie">

                <app-select (valueChange)="onSelectMateria($event)" [id]="'select-materie'" [showClear]="true"
                  [filterBy]="'name'" [placeholder]="'STR.Seleziona_Materie'" [multiple]="true" [data]="materie"
                  [showValue]="'name'" [optionAttributeShow]="'name'" [(value)]="data.materie">
                </app-select>
              </div>
            </div>

            <!-- Seleziona Plesso -->
            <div class="row form-group">
              <div class="col-sm-12">
                <label class="input-label" for="select-plessoSelected">{{"STR.plesso" |
                  translate}}</label>
                <app-select (valueChange)="onSelectPlesso($event)" [id]="'select-plessoSelected'" [showClear]="true"
                  [filterBy]="'name'" [placeholder]="'STR.Seleziona_Plesso'" [data]="plessi" [showValue]="'name'"
                  [(value)]="data.plessoSelected" [optionAttributeShow]="'name'">
                </app-select>
              </div>
            </div>

            <!-- Seleziona Classi/Classe -->
            <div class="row form-group"
              *ngIf="data.plessoSelected && (data.plessoSelected?.classi || !data.plessoSelected?.classi) && data.plessoSelected?.classi.length > 0">
              <div class="col-sm-12">
                <label class="input-label" for="select-classi-plesso">{{'STR.classi' | translate}}</label>
                <app-select (valueChange)="onSelectClassi($event)" [id]="'select-classi-plesso'" [showClear]="true"
                  [filterBy]="'name'" [placeholder]="'STR.Seleziona_Classi'" [multiple]="true"
                  [data]="data.plessoSelected?.classi" [showValue]="'name'" [optionAttributeShow]="'name'"
                  [(value)]="data.classiSelected">
                </app-select>
              </div>
            </div>

            <!-- Tag -->
            <div class="row form-group">
              <div class="col-12">
                <label class="input-label" for="select-tags">Ruoli</label>
                <span class="p-fluid">
                  <p-autoComplete name="select-tags" [(ngModel)]="selectedTags" [suggestions]="resultsTags"
                    (completeMethod)="tagsFiltered($event)" field="name" [multiple]="true" placeholder="Seleziona Tags">
                  </p-autoComplete>
                </span>
              </div>
            </div>

            <!-- Tipo Relazione Standard -->
            <div class="row form-group" *ngIf="relation_info_standard">
              <div class="col-12">
                <label class="input-label" for="relation_info">Tipo Relazione</label>
                <input id="relation_info" name="relation_info" #relation_info="ngModel" class="form-control"
                  [(ngModel)]="data.relation_info"
                  [ngClass]="(relation_info.dirty || relation_info.touched)? (relation_info.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                  type="text" required maxlength="255" [attr.disabled]="true">

                <div class="invalid-feedback"
                  *ngIf="relation_info.invalid && (relation_info.dirty || relation_info.touched)">
                  <span>Non valido</span>
                  <span *ngIf="relation_info.errors.forbiddenString">, Organigramma già esistente</span>
                </div>

                <span class="valid-feedback"
                  *ngIf="relation_info.valid && (relation_info.dirty || relation_info.touched)">Valido</span>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- Right Create -->
        <div class="col-5 p-3 m-3 bg-light rounded-lg" *ngIf="!edit">
          <!-- Titlte -->
          <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Sincronizzazione con Google Workspace</h4>

          <div class="row">
            <div class="col-12">
              <div *ngIf="defaultCheckOrgUnit.crea && data?.name" class="alert alert-soft-info" role="alert">
                L'organigramma <b class="text-cap">{{data?.name}}</b> verrà sincronizzato con una <b
                  class="text-cap">nuova Unità Organizzativa</b> con il nome
                <u>{{data?.name || "- scrivi il nome dell'organigramma -"}}</u>
              </div>
              <div *ngIf="!defaultCheckOrgUnit.crea" class="alert alert-soft-info" role="alert">
                L'organigramma <b class="text-cap">{{data?.name}}</b> verrà sincronizzato con<b class="text-cap">l'Unità
                  Organizzativa esistente</b> {{controlNameOrgUnit}}
              </div>
              <div *ngIf="orgUnitParentSelected" class="alert alert-soft-info" role="alert">
                Verrà creato sotto l'Unità organizzativa: {{orgUnitParentSelected?.name}}
              </div>
              <div *ngIf="!orgUnitParentSelected" class="alert alert-soft-info" role="alert">
                L'unità organizzativa <u>{{data?.name || "- scrivi il nome dell'organigramma -"}}</u> verrà creata nella
                radice "/"
              </div>
              <div *ngIf="defaultCheckGroup.all && !existErrorGroupPreview.all" class="alert alert-soft-info"
                role="alert">
                All'organigramma verrà sincronizzato con un <b class="text-cap">nuovo Google Group</b> con email
                <u>{{groupsPreview?.user || " - scrivi il nome dell'organigramma -"}}</u>
              </div>

              <div *ngIf="defaultCheckGroup.all && existErrorGroupPreview.all" class="alert alert-soft-danger"
                role="alert">
                Attenzione! Il Gruppo con email
                <u>{{groupsPreview?.user || " - scrivi il nome dell'organigramma -"}}</u> esiste già
              </div>

              <div *ngIf="!defaultCheckGroup.all" class="alert alert-soft-info" role="alert">
                L'organigramma verrà sincronizzato con il <b class="text-cap">Google Group</b> selezionato
              </div>
              <div class="alert alert-soft-info" role="alert" *ngIf="data.materie && data.materie.length > 0">
                L'organigramma verrà sincronizzato con le materie
                <b> {{ _mapAndJoinView(data.materie, 'name') }} </b>
              </div>
              <div class="alert alert-soft-info" role="alert" *ngIf="data.plessoSelected">
                L'organigramma verrà sincronizzato con il Plesso <b>{{ data.plessoSelected.name }}</b>
                <ng-container *ngIf="data.classiSelected && data.classiSelected.length > 0">
                  e le Classi <b> {{ _mapAndJoinView(data.classiSelected, 'name') }} </b>
                </ng-container>
              </div>
              <div class="alert alert-soft-info" role="alert" *ngIf="selectedTags && selectedTags.length > 0">
                L'organigramma verrà assegnato con i <b> {{ _mapAndJoinView(selectedTags, 'name') }} </b>
              </div>
            </div>
          </div>
        </div>

        <!-- Right Edit -->
        <div class="col-5 p-3 m-3 bg-light rounded-lg" *ngIf="edit">
          <!-- Titlte -->
          <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Sincronizzazione con Google Workspace</h4>

          <div class="row">
            <div class="col-12">
              <div class="alert alert-soft-info" role="alert" *ngIf="!dataIsChanged; else elseBlock">
                Non ci sono modifiche
              </div>
              <ng-template #elseBlock>
                <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.name">
                  L'Organigramma verrà rinominato da {{organigramma.name}} a <b>{{data.name}}</b>
                </div>
                <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.description">
                  La descrizione del organigramma verrà rinominato da {{organigramma.description}} a
                  <b>{{data.description}}</b>
                </div>
                <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.org_unit">
                  <ng-container *ngIf="data.orgUnitId; else noOrgUnitAssociate">
                    L'Unita organizzativa del organigramma verrà cambiata in <b>{{controlNameOrgUnit}}</b>
                  </ng-container>
                  <ng-template #noOrgUnitAssociate>
                    Non verrà associata nessuna unita organizzativa al organigramma
                  </ng-template>
                </div>

                <ng-container *ngIf="dataChange.groupKey_all">
                  <div class="alert alert-soft-info" role="alert" *ngIf="data.groupKey_all; else noGroupAssociate">
                    Il Gruppo del organigramma verrà cambiata in
                    <b>
                      {{previewEmailSelectedGroup(data.groupKey_all) || "GROUP"}}
                    </b>
                  </div>
                  <ng-template #noGroupAssociate>
                    <div class="alert alert-soft-info" role="alert">
                      Non verrà associato nessun Gruppo organigramma
                    </div>
                  </ng-template>
                </ng-container>

              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer border-0 mx-3">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto py-1">
          <button *ngIf="id" [routerLink]="['/organigramma/'+id]" type="button" class="btn btn-white">
            <i class="far fa-chevron-left mr-1"></i> Annulla
          </button>
        </div>
        <div class="col-6 py-1">
          <div class="row justify-content-end">
            <button type="submit" class="btn" [disabled]="!myForm.form.valid || existErrorGroupPreview.all"
              [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
              <span *ngIf="edit">Aggiorna</span>
              <span *ngIf="!edit">Salva</span>
              <i class="far fa-chevron-right ml-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
