<div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12">
        <label *ngIf="config.showLabel" [for]="config.id" class="full-width">{{config.title}}</label>
        <ng-container [ngSwitch]="config.type">
            <ng-container *ngSwitchCase="true">
                <p-multiSelect [ngModelOptions]="{standalone: true}" [emptyFilterMessage]="_notFoundText" [disabled]="disabled"
                    (onChange)="onChangeMulti($event)" class="full-width" [id]="config.id" [options]="data"
                    [(ngModel)]="selectedMultiValue" [optionLabel]="config.optionLabel" [filterBy]="config.filterBy"
                    [placeholder]="config.placeholder" [filter]="config.filter" [showToggleAll]="config.showToggleAll"
                    [virtualScroll]="config.virtualScroll" [itemSize]="config.itemSize" [group]="config.group">
                    <ng-template let-value pTemplate="selectedItems">
                        <div class="data-item data-item-value">
                            <div *ngIf="selectedMultiValue && selectedMultiValue.length > 0; else elseBlock">
                                {{selectedShowValueMulti()}}
                            </div>
                            <ng-template #elseBlock>
                                {{config.placeholder}}
                            </ng-template>
                        </div>
                    </ng-template>
                    <ng-template let-group pTemplate="group">
                        <div class="p-d-flex p-ai-center">
                            <span>{{group.label}}</span>
                        </div>
                    </ng-template>
                    <ng-template let-data pTemplate="item">
                        <div class="data-item" *ngIf="config.optionAttributeShow">
                            <div *ngFor="let attribute of config.optionAttributeShow">{{data[attribute]}}</div>
                        </div>
                        <div class="data-item" *ngIf="!config.optionAttributeShow">
                            <div>{{data}}</div>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </ng-container>

            <ng-container *ngSwitchCase="false">
                <p-dropdown [ngModelOptions]="{standalone: true}" [scrollHeight]="config.scrollHeight" [disabled]="disabled"
                    [emptyFilterMessage]="_notFoundText" (onChange)="onChange($event)" class="full-width"
                    [id]="config.id" [options]="data" [(ngModel)]="selectedValue" [optionLabel]="config.optionLabel"
                    [filterBy]="config.filterBy" [placeholder]="config.placeholder" [showClear]="config.showClear"
                    [filter]="config.filter" [virtualScroll]="config.virtualScroll" [itemSize]="config.itemSize">
                    <ng-template pTemplate="selectedItem">
                        <div class="data-item data-item-value" *ngIf="selectedValue">
                            <div>{{selectedShowValue()}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-data pTemplate="item">
                        <div class="data-item" *ngIf="config.optionAttributeShow">
                            <div *ngFor="let attribute of config.optionAttributeShow">{{data[attribute]}}</div>
                        </div>
                        <div class="data-item" *ngIf="!config.optionAttributeShow">
                            <div>{{data}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </ng-container>
        </ng-container>
    </div>
</div>
