<app-card-loading *ngIf="loading">
</app-card-loading>

<app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">
  <div class="row">
    <div class="col-12">
      <app-card-operation-activity [filterActivity]="['operation.user.cattedre']">
      </app-card-operation-activity>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <!-- Card -->
      <div class="card card-hover-shadow">
        <div class="card-body">

          <h3 class="card-title">Gestione Cattedre</h3>
          <p class="card-text">Questa operazione consente di gestire una cattedra velocemente, assegnando plessi, classi
            e materie ad un docente.
          </p>
          <div class="row form-group">
            <div class="col-12">
              <label class="input-label" for="select-tags">Seleziona utente</label>
              <span class="p-fluid">
                <p-autoComplete [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'" name="select-tags"
                  [(ngModel)]="userSelected" [suggestions]="resultsTags" (completeMethod)="tagsFiltered($event)"
                  (onSelect)="selectedUser($event)" (onClear)="clearSelection($event)" [multiple]="false"
                  [forceSelection]="true" placeholder="Cerca Utente" field="email">

                  <ng-template let-tags pTemplate="item">
                    <div class="media align-items-center">
                      <div class="avatar avatar-sm avatar-soft-primary avatar-circle mr-3">
                        <span class="avatar-initials" *ngIf="!tags.avatar">{{tags.initials}}</span>
                        <img class="avatar-img" *ngIf="tags.avatar" src="{{tags.avatar}}">
                      </div>
                      <div class="media-body">
                        <span class="d-block h5 text-hover-primary mb-0">{{tags.fullname}}</span>
                        <span class="d-block font-size-sm text-body">{{tags.email}}</span>
                      </div>
                    </div>
                  </ng-template>
                </p-autoComplete>
              </span>
            </div>
          </div>


          <div *ngIf="userSelected && !userSelected.google_id" class="alert alert-warning mt-2 mb-0">
            <pre>{{userSelected | json}}</pre>
            <span class="text-cap d-inline">Attenzione</span> l'utente selezionato non ha un account google
            associato
          </div>
        </div>
      </div>
      <!-- End Card -->
    </div>
  </div>

  <ng-container *ngIf="userIdSelected">
    <app-card-loading *ngIf="_loadingChangeSelectionUser">
    </app-card-loading>
    <ng-container *ngIf="!_loadingChangeSelectionUser">
      <div class="row mb-3">
        <div class="col-md-12 col-sm mb-1">
          <app-user-profile-info-card [id]="userIdSelected" [disableButton]="true" [showInfoClassi]="true">
          </app-user-profile-info-card>
        </div>
      </div>
      <div *ngIf="!_loadingPlesso">
        <div class="card card-hover-shadow">
          <app-card-loading-overlay [loading]="_loadingSubmit"></app-card-loading-overlay>
          <div class="card-body" [ngClass]="{'card-is-loading': _loadingSubmit}">
            <div class="row">
              <div class="col-7 p-0">
                <app-gestione-scolastica-user [border]="false" (onChange)="onChangePlessoClassiSelectedData($event)"
                  [materieSelectionClassi]="true" [multiSelectionClassi]="true" [multiInsert]="true"
                  [classiPreSelected]="classiPreSelected" [idPlessoPreSelected]="idPlessoPreSelected"
                  [materieClassiPreSelected]="materieClassiPreSelected">
                </app-gestione-scolastica-user>
              </div>
              <!-- Right -->
              <div class="col-5 p-3 bg-light rounded-lg">
                <!-- Titlte -->
                <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Plesso</h4>

                <div class="row" *ngIf="!(idPlessoPreSelected); else elseBlock">
                  <div class="col-12">
                    <div class="alert alert-soft-info" role="alert">
                      Qui visualizzerai la struttrua associata
                    </div>
                  </div>
                </div>
                <ng-template #elseBlock>
                  <div class="row" *ngIf="getSelectedStruct() as struttura">
                    <div class="col-12">
                      <div class="alert alert-soft-info" role="alert"
                        *ngIf="(struttura.classi && struttura.classi.length > 0) || (struttura.materieClassi && struttura.materieClassi.length > 0); else elseBlockNoClassi">
                        Verrà aggiunto nel Plesso
                        <b class="text-cap"> {{struttura.plesso.name}} </b>
                        <ng-container *ngIf="struttura.classi && struttura.classi.length > 0">
                          <span *ngIf="struttura.classi.length == 1">e nella Classe</span>
                          <span *ngIf="struttura.classi.length > 1">e nelle Classi</span>
                          <b class="text-cap"> {{ _mapAndJoinView(struttura.classi, 'name') }}
                          </b>
                        </ng-container>
                        <ng-container *ngIf="struttura.materieClassi && struttura.materieClassi.length > 0">e assegante
                          le Materie nelle relative Classi
                          <b class="text-cap" *ngFor="let strutturaMat of struttura.materieClassi"> {{
                            _mapAndJoinViewMaterie(strutturaMat, 'name') }} </b>
                        </ng-container>
                      </div>
                      <ng-template #elseBlockNoClassi>
                        <div class="alert alert-soft-info" role="alert">
                          Seleziona delle Classi o Materie da associare all'Utente nel Plesso
                          <b class="text-cap"> {{struttura.plesso.name}} </b>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="card-footer pt-0 border-0">
            <div class="row justify-content-between align-items-center pb-0 pt-0 pr-3">
              <div class="col-auto">
              </div>
              <div class="col-6">
                <div class="row justify-content-end">
                  <button type="button" class="btn btn-primary" (click)="saveStruct()">
                    <span>Salva</span>
                    <i class="far fa-chevron-right ml-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
