import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { AddMembersToGroupsService } from 'src/app/services/operations/groups/add-members-to-groups.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-add-members-to-groups',
  templateUrl: './add-members-to-groups.component.html',
  styleUrls: ['./add-members-to-groups.component.scss']
})
export class AddMembersToGroupsComponent implements OnInit {
  regEx = ConfigService.regExConfirm;
  error: any;
  _loadingData = {
    members: false,
    memberUser: false,
    memberGroup: false
  };

  formLoading: boolean = false;

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  data: any = [];
  members: any = [];
  model: any;

  operationInProgress: boolean = false;
  errorNoGoogleAccount: boolean = false;


  private _addMembersToGroupsSubscription: Subscription;
  private _userServiceSubscription: Subscription;
  private _googleUserServiceSubscription: Subscription;
  private _googleGroupServiceSubscription: Subscription;

  constructor(
    private syncdataService: SyncdataService,
    private translate: TranslateService,
    private addMembersToGroupsService: AddMembersToGroupsService,
    private userService: UserService,
    private googleService: GoogleDirectoryService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.members = this.addMembersToGroupsService.items;
    this.data = this.parseMembersResult(this.members);
  }

  parseUsersResult(result) {
    let table = [];
    if (result && result.length > 0){
      result.forEach(element => {
        let user = this.getInfoUser(element);

        let groupAdd = this.getInfoGroup({id: element.groupKey})

        table.push({
          "nome": [user.fullname, user.email],
          "type": [this.translate.instant('GROUP.' + user.type)],
          "gruppo": [groupAdd.email,groupAdd.name],
          "_export_data": {
            name: user.fullname,
            email: user.email
          },
        });

      });
    }
    this._loadingData.memberUser = false;

    return table;
  }

  parseGroupsResult(result) {
    let table = [];
    if (result && result.length > 0)
      result.forEach(element => {
        let group = this.getInfoGroup(element);
        let groupAdd = this.getInfoGroup({id: element.groupKey})

        table.push({
          "nome": [group.name, group.email],
          "type": [this.translate.instant('GROUP.' + group.type)],
          "gruppo": [groupAdd.email,groupAdd.name],
          "_export_data": {
            name: group.name,
            email: group.email
          },
        })
      });

    this._loadingData.memberGroup = false;

    return table;
  }

  parseMembersResult(result) {
    let memberUser = result.filter(x => x.type == "USER");
    let memberGroup = result.filter(x => x.type == "GROUP");

    if (!memberUser)
      memberUser = [];

    if (!memberGroup)
      memberGroup = [];

    let memberUserData = this.parseUsersResult(memberUser);
    let memberGroupsData = this.parseGroupsResult(memberGroup);

    let table = [...memberUserData, ...memberGroupsData];

    return table;
  }

  deleteEvent(rows) {
    this.addMembersToGroupsService.deleteItemsAtIndex(rows);
  }

  onSubmit() {
    this.formLoading = true;

    this.operationInProgress = true;

    this.addMembersToGroupsService.execOperation(this.members, (error) => {
      this.formLoading = false;
      if (!error) {
        this.initForm();
      }
    });
  }

  resetOperation() {
    this.addMembersToGroupsService.reset();
  }

  unsubscibeAll() {
    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
    if (this._googleGroupServiceSubscription)
      this._googleGroupServiceSubscription.unsubscribe();
    if (this._googleUserServiceSubscription)
      this._googleUserServiceSubscription.unsubscribe();
    if (this._addMembersToGroupsSubscription)
      this._addMembersToGroupsSubscription.unsubscribe();
  }

  initForm() {

  }

  init() {
    this._addMembersToGroupsSubscription = this.addMembersToGroupsService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.userService.all();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.googleService.groupsList();
      }
    });

    this._googleUserServiceSubscription = this.googleService.getSubjectToUpdateUsersObservable().subscribe((result) => {
      if (result) {
        this.userService.all();
      }
    });

    this._googleGroupServiceSubscription = this.googleService.getSubjectToUpdateGroupsObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.googleService.usersList();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  selectTab($event) {
  }

  getInfoUser(userInfo) {
    let resUser = {
      userId: userInfo.user_id,
      googleId: userInfo.id,
      fullname: null,
      email: null,
      is_google_user: false,
      is_geniusuite_user: false,
      role: userInfo?.role,
      status: userInfo?.status,
      type: userInfo?.type
    };

    let googleUser = this.googleService.getUser(resUser.googleId);

    // Controllo più dettagliato prima che l'email dell'utente sia stata modificata
    let user = googleUser ? this.userService.getByGoogleId(googleUser.id) : this.userService.get(resUser.userId);

    if (user) {
      // Sincronizzato con Geniusuite
      resUser.userId = user.user.id;
      resUser.fullname = user.user.familyName + " " + user.user.givenName;
      resUser.email = user.user.email;
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = true;

      if (user.google_user) {
        resUser.googleId = user.google_user.id;

        if (user.google_user.name) {
          resUser.fullname = user.google_user.name?.familyName + " " + user.google_user.name?.givenName;
        }

        resUser.email = user.google_user.primaryEmail
        resUser.is_google_user = true;
      }
    } else if (googleUser) {
      // Non sincronizzato con Geniusuite ma non ha già i dati caricati
      resUser.fullname = "-";
      resUser.googleId = googleUser.id;

      if (googleUser.name) {
        resUser.fullname = googleUser.name?.familyName + " " + googleUser.name?.givenName;
      }

      resUser.email = googleUser.primaryEmail;
      resUser.is_google_user = true;
      resUser.is_geniusuite_user = false;
    } else {
      resUser.fullname = "Utente Esterno";
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = false;
    }

    return resUser;
  }

  getInfoGroup(userInfo) {
    let resUser = {
      id: userInfo?.id,
      name: null,
      description: null,
      email: userInfo?.email,
      directMembersCount: 0,
      role: userInfo?.role,
      status: userInfo?.status,
      type: userInfo?.type
    };

    let googleGroup = resUser.email ? this.googleService.getGroupByEmail(resUser.email) : this.googleService.getGroupByGroupKey(resUser.id);

    if (googleGroup) {
      // Non sincronizzato con Geniusuite ma non ha già i dati caricati
      resUser.id = googleGroup.id ? googleGroup.id : null;
      resUser.name = googleGroup.name ? googleGroup.name : "-";
      resUser.directMembersCount = googleGroup.directMembersCount ? googleGroup.directMembersCount : 0;
      resUser.description = googleGroup.description ? googleGroup.description : "-";
      resUser.email = googleGroup.email;
    } else {
      resUser.name = "Gruppo Esterno";
    }

    return resUser;
  }
}
