<app-card-loading *ngIf="checkInProgressErrors"></app-card-loading>

<ng-container
  *ngIf="!checkInProgressErrors && syncIntegrityErrors && syncIntegrityErrors.length <= 0 && syncIntegrityErrorsDeleteUsers && syncIntegrityErrorsDeleteUsers.length <= 0">
  <div class="card card-hover-shadow mb-3">
    <!-- Header -->
    <a class="card-header alert-success card-btn btn-block" [ngClass]="{'collapsed': syncIntegrityErrorsIsCollapsed}"
      (click)="syncIntegrityErrorsIsCollapsed = !syncIntegrityErrorsIsCollapsed">
      <h4 class="card-header-title text-white">La tua Google Workspace non presenta problemi</h4>
    </a>

    <div class="card-body">
      <button class="btn btn-outline-primary" [disabled]="checkInProgressErrors"
        (click)="updateSystemDataAndCheckIntegrity()"><i class="fad fa-solar-system mr-2"></i>Effettua un
        nuovo controllo</button>
    </div>
  </div>
</ng-container>

<!--
  .########.########..########...#######..########..####
  .##.......##.....##.##.....##.##.....##.##.....##..##.
  .##.......##.....##.##.....##.##.....##.##.....##..##.
  .######...########..########..##.....##.########...##.
  .##.......##...##...##...##...##.....##.##...##....##.
  .##.......##....##..##....##..##.....##.##....##...##.
  .########.##.....##.##.....##..#######..##.....##.####
  -->
<ng-container
  *ngIf="(syncIntegrityErrors && syncIntegrityErrors.length > 0) || (syncIntegrityErrorsDeleteUsers && syncIntegrityErrorsDeleteUsers.length > 0)">
  <div class="card card-hover-shadow mb-3">
    <!-- Header -->
    <a class="card-header alert-danger card-btn btn-block" [ngClass]="{'collapsed': syncIntegrityErrorsIsCollapsed}"
      (click)="syncIntegrityErrorsIsCollapsed = !syncIntegrityErrorsIsCollapsed">
      <h4 class="card-header-title text-white">Sono presenti errori in Google Workspace</h4>

      <span class="card-btn-toggle" [attr.aria-expanded]="!syncIntegrityErrorsIsCollapsed"
        aria-controls="collapseActivityDataWithErrors">
        <span class="btn btn-xs btn-soft-light card-btn-toggle-default">
          <i class="far fa-angle-down mr-1"></i>
          Mostra dettagli
        </span>
        <span class="btn btn-xs btn-soft-light card-btn-toggle-active">
          <i class="far fa-angle-up mr-1"></i>
          Nascondi dettagli
        </span>
      </span>
    </a>
    <div class="card-body" [collapse]="syncIntegrityErrorsIsCollapsed" [isAnimated]="true">
      <div class="row">
        <div class="col">
          <div class="alert alert-soft-danger" role="alert">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto py-1">
                <div [innerHTML]="'STR.integrity_errors_card_alert' | translate">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="alert alert-soft-warning" role="alert">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto py-1">
                <div [innerHTML]="'STR.integrity_warning_card_alert' | translate">
                </div>
                <button class="btn btn-outline-warning" [disabled]="checkInProgressErrors"
                  (click)="updateSystemDataAndCheckIntegrity()"><i class="fad fa-solar-system mr-2"></i>Effettua un
                  nuovo controllo</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--
        .##.....##.########.########.##....##.########.####....########.....###........######.....###....##....##..######..########.##.......##..........###....########..########
        .##.....##....##....##.......###...##....##.....##.....##.....##...##.##......##....##...##.##...###...##.##....##.##.......##.......##.........##.##...##.....##.##......
        .##.....##....##....##.......####..##....##.....##.....##.....##..##...##.....##........##...##..####..##.##.......##.......##.......##........##...##..##.....##.##......
        .##.....##....##....######...##.##.##....##.....##.....##.....##.##.....##....##.......##.....##.##.##.##.##.......######...##.......##.......##.....##.########..######..
        .##.....##....##....##.......##..####....##.....##.....##.....##.#########....##.......#########.##..####.##.......##.......##.......##.......#########.##...##...##......
        .##.....##....##....##.......##...###....##.....##.....##.....##.##.....##....##....##.##.....##.##...###.##....##.##.......##.......##.......##.....##.##....##..##......
        ..#######.....##....########.##....##....##....####....########..##.....##.....######..##.....##.##....##..######..########.########.########.##.....##.##.....##.########
        -->
      <ng-container *ngIf="syncIntegrityErrorsDeleteUsers && syncIntegrityErrorsDeleteUsers.length > 0">
        <ul class="list-group list-group-flush list-group-lg list-group-no-gutters">
          <li class="list-group-item d-flex align-items-center">
            <i class="fas fa-exclamation-circle mr-1 list-group-icon text-danger"></i>
            <p class="m-0">
              <b [innerHTML]="'STR.missing_google_user' | translate"></b><br>
              <small>Utenti da cancellare: {{ syncIntegrityErrorsDeleteUsers.length }}</small>
            </p>
            <span class="btn btn btn-soft-success ml-auto" (click)="deleteUsersCommand()">
              <i class="fas fa-hammer mr-1"></i>
              Correggi e cancella tutti gli utenti
            </span>
          </li>
        </ul>
        <div class="row">
          <ul class="list-group w-100">
            <ng-container *ngFor="let error of syncIntegrityErrorsDeleteUsers">
              <li class="list-group-item d-flex align-items-center">
                <i class="fas fa-exclamation-circle mr-1 list-group-icon text-danger"></i>
                <p class="m-0">
                  <b>{{error.entity.name || error.entity.email || error.entity.user.email}}</b> <small class="ml-2">ID:
                    {{error.entity.id}}</small> <small class="ml-2">TIPO: {{ error.type }} </small><br>
                  <small [innerHTML]="error.error | translate"></small>
                </p>
                <span class="btn btn-xs btn-soft-success ml-auto" (click)="fixSyncIntegrityErrors(error)">
                  <i class="fas fa-hammer mr-1"></i>
                  Modifica e correggi manualmente
                </span>
              </li>
            </ng-container>
          </ul>
        </div>
      </ng-container>
      <!--
        ....###....##.......########.########..####....########.########..########...#######..########..####
        ...##.##...##..........##....##.....##..##.....##.......##.....##.##.....##.##.....##.##.....##..##.
        ..##...##..##..........##....##.....##..##.....##.......##.....##.##.....##.##.....##.##.....##..##.
        .##.....##.##..........##....########...##.....######...########..########..##.....##.########...##.
        .#########.##..........##....##...##....##.....##.......##...##...##...##...##.....##.##...##....##.
        .##.....##.##..........##....##....##...##.....##.......##....##..##....##..##.....##.##....##...##.
        .##.....##.########....##....##.....##.####....########.##.....##.##.....##..#######..##.....##.####
        -->
      <ul class="list-group list-group-flush list-group-no-gutters">
        <ng-container *ngFor="let error of syncIntegrityErrors">
          <li class="list-group-item d-flex align-items-center">
            <i class="fas fa-exclamation-circle mr-1 list-group-icon text-danger"></i>
            <p class="m-0">
              <b>{{error.entity.name || error.entity.email || error.entity.user.email}}</b> <small class="ml-2">ID:
                {{error.entity.id}}</small> <small class="ml-2">TIPO: {{ error.type }} </small><br>
              <small [innerHTML]="error.error | translate"></small>
            </p>
            <span class="btn btn-xs btn-soft-success ml-auto" (click)="fixSyncIntegrityErrors(error)">
              <i class="fas fa-hammer mr-1"></i>
              Modifica e correggi manualmente
            </span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</ng-container>

<!--
..######...########..##.....##.########..########..####
.##....##..##.....##.##.....##.##.....##.##.....##..##.
.##........##.....##.##.....##.##.....##.##.....##..##.
.##...####.########..##.....##.########..########...##.
.##....##..##...##...##.....##.##........##.........##.
.##....##..##....##..##.....##.##........##.........##.
..######...##.....##..#######..##........##........####
-->
<div class="card card-hover-shadow mb-3">
  <!-- Header -->
  <a class="card-header card-btn btn-block" [ngClass]="{'collapsed': groupsIntegrityErrorsIsCollapsed}"
    (click)="groupsIntegrityErrorsIsCollapsed = !groupsIntegrityErrorsIsCollapsed">
    <h4 class="card-header-title">Disallineamenti nei gruppi Google</h4>

    <span class="card-btn-toggle" [attr.aria-expanded]="!groupsIntegrityErrorsIsCollapsed"
      aria-controls="collapseActivityDataWithErrors">
      <span class="btn btn-xs btn-light card-btn-toggle-default">
        <i class="far fa-angle-down mr-1"></i>
        Mostra dettagli
      </span>
      <span class="btn btn-xs btn-light card-btn-toggle-active">
        <i class="far fa-angle-up mr-1"></i>
        Nascondi dettagli
      </span>
    </span>
  </a>
  <div class="card-body" [collapse]="groupsIntegrityErrorsIsCollapsed" [isAnimated]="true">
    <div class="row mb-3">
      <div class="col">
        <div class="alert alert-soft-info" role="alert" [innerHTML]="'STR.integrity_groups_card_alert' | translate">
        </div>
        <button class="btn btn-outline-primary" [disabled]="groupsIntegrityProgress !== null || checkInProgressErrors"
          (click)="checkGroupsIntegrity()">
          <i class="fad fa-solar-system mr-2"></i>Effettua un nuovo controllo</button>
      </div>
    </div>

    <p-progressBar *ngIf="groupsIntegrityProgress !== null" [value]="groupsIntegrityProgress" [showValue]="false"
      [style]="{'height': '20px'}"></p-progressBar>

    <ng-container *ngIf="groupsIntegrityErrors && groupsIntegrityErrors.length > 0">

      <ul class="list-group list-group-flush list-group-no-gutters">
        <li class="list-group-item d-flex align-items-center">
          <i class="fas fa-exclamation-circle mr-1 list-group-icon text-warning"></i>
          <p class="m-0">
            <b>Questi utenti dovrebbero essere all'interno dei rispettivi gruppi</b><br>
            <small>Gruppi con incongruenze: {{ groupsIntegrityErrors.length }}</small>
          </p>
          <button type="button" class="btn btn btn-success ml-auto"
            [disabled]="groupsIntegrityProgress !== null || checkInProgressErrors"
            (click)="fixGroupsIntegrityCommand()">
            <i class="fas fa-hammer mr-1"></i>
            Correggi automaticamente tutti i gruppi
          </button>
        </li>
        <ng-container *ngFor="let error of groupsIntegrityErrors">
          <li class="list-group-item d-flex align-items-center">
            <i class="fas fa-exclamation-circle mr-1 list-group-icon text-warning"></i>
            <p class="m-0">
              <b class="mr-2">{{error.user.familyName || error.user.user.familyName}} {{error.user.givenName ||
                error.user.user.givenName}}</b>
              <small class="mr-2">EMAIL: {{error.user.email}}</small>
              <small class="">ID: {{error.user.id}}</small>
              <br>
              <small class="mr-2">{{error.type}}</small>
              <b class="mr-2">{{error.entity.name}}</b>
              <br>
              <small [innerHTML]="error.error | translate:error.group"></small>
            </p>
            <button class="btn btn-xs btn-soft-success ml-auto"
              [disabled]="groupsIntegrityProgress !== null || checkInProgressErrors"
              (click)="fixGroupsIntegrityErrors(error)">
              <i class="fas fa-hammer mr-1"></i>
              Modifica e correggi manualmente
            </button>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>
</div>

<!--
..######..##..........###.....######...######..########...#######...#######..##.....##
.##....##.##.........##.##...##....##.##....##.##.....##.##.....##.##.....##.###...###
.##.......##........##...##..##.......##.......##.....##.##.....##.##.....##.####.####
.##.......##.......##.....##..######...######..########..##.....##.##.....##.##.###.##
.##.......##.......#########.......##.......##.##...##...##.....##.##.....##.##.....##
.##....##.##.......##.....##.##....##.##....##.##....##..##.....##.##.....##.##.....##
..######..########.##.....##..######...######..##.....##..#######...#######..##.....##
-->

<div class="card card-hover-shadow mb-3">
  <!-- Header -->
  <a class="card-header card-btn btn-block" [ngClass]="{'collapsed': classroomIntegrityErrorsIsCollapsed}"
    (click)="classroomIntegrityErrorsIsCollapsed = !classroomIntegrityErrorsIsCollapsed">
    <h4 class="card-header-title">Disallineamenti nelle Classroom</h4>

    <span class="card-btn-toggle" [attr.aria-expanded]="!classroomIntegrityErrorsIsCollapsed"
      aria-controls="collapseActivityDataWithErrors">
      <span class="btn btn-xs btn-light card-btn-toggle-default">
        <i class="far fa-angle-down mr-1"></i>
        Mostra dettagli
      </span>
      <span class="btn btn-xs btn-light card-btn-toggle-active">
        <i class="far fa-angle-up mr-1"></i>
        Nascondi dettagli
      </span>
    </span>
  </a>
  <div class="card-body" [collapse]="classroomIntegrityErrorsIsCollapsed" [isAnimated]="true">
    <div class="row mb-3">
      <div class="col">
        <div class="alert alert-soft-info" role="alert" [innerHTML]="'STR.integrity_classroom_card_alert' | translate">
        </div>
        <button class="btn btn-outline-primary"
          [disabled]="classroomIntegrityProgress !== null || checkInProgressErrors" (click)="checkClassroomIntegrity()">
          <i class="fad fa-solar-system mr-2"></i>Effettua un nuovo controllo</button>
      </div>
    </div>

    <p-progressBar *ngIf="classroomIntegrityProgress !== null" [value]="classroomIntegrityProgress" [showValue]="false"
      [style]="{'height': '20px'}"></p-progressBar>

    <ng-container *ngIf="classroomIntegrityErrors && classroomIntegrityErrors.length > 0">

      <ul class="list-group list-group-flush list-group-no-gutters">
        <li class="list-group-item d-flex align-items-center">
          <i class="fas fa-exclamation-circle mr-1 list-group-icon text-warning"></i>
          <p class="m-0">
            <b>Queste classroom presentano delle incongruenze</b><br>
            <small>Incongruenze: {{ classroomIntegrityErrors.length }}</small>
          </p>
          <!-- <button type="button" class="btn btn btn-success ml-auto"
            [disabled]="classroomIntegrityProgress !== null || checkInProgressErrors"
            (click)="fixClassroomIntegrityCommand()">
            <i class="fas fa-hammer mr-1"></i>
            Correggi automaticamente tutte le classroom
          </button> -->
        </li>
        <ng-container *ngFor="let error of classroomIntegrityErrors">
          <li class="list-group-item d-flex align-items-center">
            <i class="fas fa-exclamation-circle mr-1 list-group-icon text-warning"></i>
            <p class="m-0">
              <b class="mr-2">{{error.user.familyName || error.user.user?.familyName ||
                error.user.profile?.name.familyName}} {{error.user.givenName || error.user.user?.givenName ||
                error.user.profile?.name.givenName}}</b>
              <small class="mr-2">EMAIL: {{error.user.email || error.user.profile?.emailAddress}}</small>
              <small class="">ID: {{error.user.id || error.user.profile?.id}}</small>
              <br>
              <small class="mr-2">classe</small>
              <b class="mr-2">{{error.entity.name}}</b>
              <i class="fas fa-exchange mr-2"></i>
              <small class="mr-2">classroom</small>
              <b class="mr-2">{{error.info.name}}</b>
            </p>
            <p class="m-0 ml-auto text-center">
              <button class="btn btn-xs btn-soft-success"
                [disabled]="classroomIntegrityProgress !== null || checkInProgressErrors"
                (click)="fixClassroomIntegrityErrors(error)">
                <i class="fas fa-hammer mr-1"></i>
                Correggi manualmente
              </button>
              <br>
              <small [innerHTML]="error.error | translate:error.classroom"></small>
            </p>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>
</div>


<!--
.########.########..######..########
....##....##.......##....##....##...
....##....##.......##..........##...
....##....######....######.....##...
....##....##.............##....##...
....##....##.......##....##....##...
....##....########..######.....##...
-->

<div class="card card-hover-shadow mb-3" *ngIf="false">
  <!-- Header -->
  <a class="card-header card-btn btn-block" [ngClass]="{'collapsed': workspaceIntegrityErrorsIsCollapsed}"
    (click)="workspaceIntegrityErrorsIsCollapsed = !workspaceIntegrityErrorsIsCollapsed">
    <h4 class="card-header-title">Disallineamenti nelle workspace</h4>

    <span class="card-btn-toggle" [attr.aria-expanded]="!workspaceIntegrityErrorsIsCollapsed"
      aria-controls="collapseActivityDataWithErrors">
      <span class="btn btn-xs btn-light card-btn-toggle-default">
        <i class="far fa-angle-down mr-1"></i>
        Mostra dettagli
      </span>
      <span class="btn btn-xs btn-light card-btn-toggle-active">
        <i class="far fa-angle-up mr-1"></i>
        Nascondi dettagli
      </span>
    </span>
  </a>
  <div class="card-body" [collapse]="workspaceIntegrityErrorsIsCollapsed" [isAnimated]="true">
    <div class="row mb-3">
      <div class="col">
        <div class="alert alert-soft-info" role="alert" [innerHTML]="'STR.integrity_workspace_card_alert' | translate">
        </div>
        <button class="btn btn-outline-primary"
          [disabled]="workspaceIntegrityProgress !== null || checkInProgressErrors" (click)="checkWorkspaceIntegrity()">
          <i class="fad fa-solar-system mr-2"></i>Effettua un nuovo controllo</button>
      </div>
    </div>

    <p-progressBar *ngIf="workspaceIntegrityProgress !== null" [value]="workspaceIntegrityProgress" [showValue]="false"
      [style]="{'height': '20px'}"></p-progressBar>

    <ng-container *ngIf="workspaceIntegrityErrors && workspaceIntegrityErrors.length > 0">

      <ul class="list-group list-group-flush list-group-no-gutters">
        <li class="list-group-item d-flex align-items-center">
          <i class="fas fa-exclamation-circle mr-1 list-group-icon text-warning"></i>
          <p class="m-0">
            <b>Queste workspace presentano delle incongruenze</b><br>
            <small>Incongruenze: {{ workspaceIntegrityErrors.length }}</small>
          </p>
        </li>
        <ng-container *ngFor="let error of workspaceIntegrityErrors">
          <li class="list-group-item d-flex align-items-center">
            <i class="fas fa-exclamation-circle mr-1 list-group-icon text-warning"></i>
            <p class="m-0">
              <b class="mr-2">{{error.user.familyName || error.user.user?.familyName ||
                error.user.profile?.name.familyName}} {{error.user.givenName || error.user.user?.givenName ||
                error.user.profile?.name.givenName}}</b>
              <small class="mr-2">EMAIL: {{error.user.email || error.user.profile?.emailAddress}}</small>
              <small class="">ID: {{error.user.id || error.user.profile?.id}}</small>
              <br>
              <small class="mr-2">classe</small>
              <b class="mr-2">{{error.entity.name}}</b>
              <i class="fas fa-exchange mr-2"></i>
              <small class="mr-2">workspace</small>
              <b class="mr-2">{{error.info.name}}</b>
            </p>
            <p class="m-0 ml-auto text-center">
              <button class="btn btn-xs btn-soft-success"
                [disabled]="workspaceIntegrityProgress !== null || checkInProgressErrors"
                (click)="fixworkspaceIntegrityErrors(error)">
                <i class="fas fa-hammer mr-1"></i>
                Correggi manualmente
              </button>
              <br>
              <small [innerHTML]="error.error | translate:error.workspace"></small>
            </p>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>
</div>
