import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ActivityService } from 'src/app/services/activity.service';
import { CheckVersionService } from 'src/app/services/check-version.service';
import { OrganigrammaService } from 'src/app/services/general/organigramma.service';
import { ParametriService } from 'src/app/services/general/parametri.service';

@Component({
  selector: 'app-card-important-activity',
  templateUrl: './card-important-activity.component.html',
  styleUrls: ['./card-important-activity.component.scss']
})
export class CardImportantActivityComponent implements OnInit {

  activityData: any;
  activityDataWithErrors: any;

  newVersionAvailable: boolean = false;
  newVersion: string = '';

  geniusuiteNonAttivata: boolean = false;

  subscriptionScaduta: boolean = false;
  showSubscriptionWidget: boolean = true;

  filterActivity: any = [
    "operation.passaggioanno",
    "importazione"
  ];

  filterActivityWithErrors: any = [
    "operation.passaggioanno",
  ];

  constructor(
    private activity: ActivityService,
    private changeDetector: ChangeDetectorRef,
    private accountService: AccountService,
    private checkVersionService: CheckVersionService,
    private organigrammaService: OrganigrammaService,
    private parametriService: ParametriService
  ) { }

  ngOnInit() {

    this.activity.getSubjectToUpdateObservableForEachJob().subscribe((result) => {
      if (result)
        this.updateActivityData(result);
    });

    this.activity.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.updateActivityData(result);
    });

    this.checkVersionService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.newVersionAvailable = this.checkVersionService.newVersionAvailable;
        this.newVersion = this.checkVersionService.newVersion;
      }
    });

    this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result && result.length > 0) {
        this.geniusuiteNonAttivata = false;
      } else {
        // solo se ho già configurato i parametri mostro l'avviso che la configurazione non è ancora stata completata perché mancano gli organigrammi
        if (this.parametriService.checkParametriConfigurazione()) {
          this.geniusuiteNonAttivata = true;
        }
      }
    });

    this.activity.updateActivityData();
  }

  ngOnDestroy() {

  }

  updateActivityData(activity) {

    this.activityData = [];
    this.activityDataWithErrors = [];

    activity.forEach(element => {

      // mostro solo quelle in progress
      if (element.status == "progress") {
        // mostro solo quelle più importanti
        if (this.filterActivity.includes(element.job_batch_name)) {
          element.isCollapsed = false;
          this.activityData.push(element);
        }
      }
    });

    // Cerco se nelle attività c'è un'operazione che risulta l'ultima eseguita e contiene degli errori
    // in quel caso mostro la card di errore
    this.filterActivityWithErrors.forEach(activityName => {

      let foundWithoutErrors = false;

      // scorro tutte le attività e la scelgo se
      // - non ne ho trovata già una senza errori
      // - se in gnerale ho aggiunto già un'attività con errori all'elenco, perché ne visualizzo una sola
      activity.forEach(element => {

        if (!foundWithoutErrors && this.activityDataWithErrors.length == 0 && activityName == element.job_batch_name) {

          if (element.failed_jobs <= 0) {
            foundWithoutErrors = true;
          }
          else {
            element.isCollapsed = true;
            this.activityDataWithErrors.push(element);
          }
        }
      });

    });

    this.changeDetector.detectChanges();
  }



  showActivitySidebar() {
    this.activity.showSidebar = true;
  }

  reloadPage() {
    location.reload();
  }

  getAccountService() {
    return this.accountService;
  }

  hideSuibscriptionWidgetForSession() {
    this.showSubscriptionWidget = false;
  }

}
