import { Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { ParametriService, ParamType } from 'src/app/services/general/parametri.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { RelationInfo, RelationService } from 'src/app/services/general/relation.service';
import { OrderTypeList, UtilityService } from 'src/app/services/utility.service';
import { ClassiService } from 'src/app/services/general/classi.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { PageService } from 'src/app/services/page.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-classe-form',
  templateUrl: './classe-form.component.html',
  styleUrls: ['./classe-form.component.scss']
})
export class ClasseFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() id_plesso: string;
  @Input() id_classe: string;

  @ViewChild('myForm') myForm: NgForm;

  plesso: any;
  classiPlesso: any;
  listClassiFilteredName: any;
  listOrgUnitFilteredName: any;
  classe: any;
  data: any;

  dataChange = {
    name: false,
    anno: false,
    description: false,
    org_unit: false,
    groupKey_all: false,
    groupKey_docenti: false,
    groupKey_studenti: false
  };

  formErrors = {
    forbiddenString: null,
    forbiddenGoogle: null
  }

  get dataIsChanged(): boolean {
    return Object.values(this.dataChange).some(x => x == true);
  }

  error: any;

  // Visualizzazione Setting Advanced
  showGroupSettings: boolean;
  showAdvancedSettings: boolean;
  defaultCheckGroup: any;
  existErrorGroupPreview: any;
  defaultCheckOrgUnit: any;

  // Dati Google e Stuttura di View (TreeView e Preview)
  orgUnits: any;
  orgUnitsTreeView: any;
  controlNameOrgUnit: string;
  groups: any;
  groupsPreview: any;
  groupNotExist: any;

  // Loading Pagina
  loadingPlesso: boolean;
  loadingClasse: boolean;
  loadingGoogleOrgUnits: boolean;
  loadingGoogleGroups: boolean;
  loadingParameterService: boolean;
  formLoading: boolean;
  edit: boolean;
  parameterLoaded: boolean;
  parameter: any;

  // Configurazione TreeView
  configTreeView: any = {
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: true
  };

  private _googleServiceOrgUnitsSubscription: Subscription;
  private _googleServiceGroupsSubscription: Subscription;
  private _plessoServiceSubscription: Subscription;
  private _parameterServiceSubscription: Subscription;
  private _relationServiceSubscription: Subscription;


  constructor(private zone: NgZone,
    private utilityService: UtilityService,
    private router: Router,
    private relationService: RelationService,
    private classeService: ClassiService,
    private plessoService: PlessoService,
    private parametriService: ParametriService,
    private googleService: GoogleDirectoryService,
    private pageService: PageService,
    private syncdataService: SyncdataService,
  ) { }

  ngOnInit() {

    this.unsubscibeAll();

    this.data = {};
    this.defaultCheckGroup = {
      all: true,
      docenti: true,
      studenti: true
    };

    this.existErrorGroupPreview = {
      all: false,
      docenti: false,
      studenti: false
    };

    this.defaultCheckOrgUnit = {
      radice: true,
      crea: true
    };

    this.loadingPlesso = false;
    this.loadingClasse = false;
    this.loadingParameterService = false;
    this.loadingGoogleOrgUnits = false;
    this.loadingGoogleGroups = false;
    this.formLoading = false;
    this.edit = false;
    this.parameterLoaded = false;
    this.showGroupSettings = true;
    this.showAdvancedSettings = false;
    this.controlNameOrgUnit = "";

    this.groupNotExist = {
      groupKey_all: false,
      groupKey_docenti: false,
      groupKey_studenti: false
    };

    if (this.id_classe && this.id_classe.length > 0) {
      this.edit = true;
      this.defaultCheckGroup = {
        all: false,
        docenti: false,
        studenti: false
      };

      this.existErrorGroupPreview = {
        all: false,
        docenti: false,
        studenti: false
      };

      this.defaultCheckOrgUnit = {
        radice: false,
        crea: false
      };
    }

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError();
      else
        this.plessoServiceUpdated();
    });

    this.plessoService.all();

    this._googleServiceOrgUnitsSubscription = this.googleService.getSubjectToUpdateOrgUnitsObservable().subscribe((result) => {
      if (!result)
        this.googleError();
      else
        this.orgUnitsUpdated(result);
    });

    this._googleServiceGroupsSubscription = this.googleService.getSubjectToUpdateGroupsObservable().subscribe((result) => {
      if (!result)
        this.googleError();
      else
        this.groupsUpdated(result);
    });

    this.getGoogleData();

    this._parameterServiceSubscription = this.parametriService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError();
      else
        this.parametriServiceUpdated(result);
    });

    this.loadingParameterService = true;
    this.parametriService.all();

    this._relationServiceSubscription = this.relationService.getSubjectToUpdateObservable().subscribe((result) => {
      this.relationServiceUpdated();
    });
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id_classe && changes.id_classe.currentValue && changes.id_classe.previousValue !== undefined) {
      this.ngOnInit();
    } else if (changes && changes.id_plesso && changes.id_plesso.currentValue && changes.id_plesso.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  unsubscibeAll() {
    if (this._googleServiceOrgUnitsSubscription)
      this._googleServiceOrgUnitsSubscription.unsubscribe();

    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();

    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();

    if (this._parameterServiceSubscription)
      this._parameterServiceSubscription.unsubscribe();

    if (this._relationServiceSubscription)
      this._relationServiceSubscription.unsubscribe();
  }

  plessoServiceUpdated() {
    this.loadingPlesso = true;
    this.getPlesso();
  }

  parametriServiceUpdated(result) {
    this.parameter = result;
    this.loadingParameterService = false;
    this.parameterLoaded = true;
  }

  relationServiceUpdated() {
    this.relationService.setDisabledOrgUnit(this.orgUnits);
    this.relationService.setDisabledGroups(this.groups)
  }

  orgUnitsUpdated(result) {
    this.orgUnits = result;
    // TODO_ZORAN Non Filtrare le orgUnit ma Disabilitare tutte quelle da non selezionare
    this.orgUnitsTreeView = this.utilityService.covertListToTreeViewItems(this.orgUnits, "orgUnitPath", "parentOrgUnitPath", "name", "orgUnitId", "/", OrderTypeList.Ascending, "name");

    this.loadingGoogleOrgUnits = false;
  }

  groupsUpdated(result) {
    this.groups = result;

    if (this.edit && this.groups && this.groups.length > 0 && this.data && this.classe) {
      let groupKey_studenti = this.classe.google_group.find(
        (x) => x.relation_info == RelationInfo.RelationInfoStudenti
      )?.groupKey;

      let groupKey_docenti = this.classe.google_group.find(
        (x) => x.relation_info == RelationInfo.RelationInfoDocenti
      )?.groupKey;

      let groupKey_all = this.classe.google_group.find(
        (x) => x.relation_info == RelationInfo.RelationInfoClasse
      )?.groupKey;

      this.data.groupKey_studenti = (this.googleService.getGroupByGroupKey(groupKey_studenti)?.id || '');

      this.data.groupKey_docenti = (this.googleService.getGroupByGroupKey(groupKey_docenti)?.id || '');

      this.data.groupKey_all = (this.googleService.getGroupByGroupKey(groupKey_all)?.id || '');
    }

    this.loadingGoogleGroups = false;
  }

  changeStatusGroupGest() {
    this.showGroupSettings = !this.showGroupSettings;
  }

  changeStatusCollapse() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  getPlesso() {
    this.loadingPlesso = true;

    this.plessoService.get(this.id_plesso, (result) => {
      this.plesso = result;

      this.loadingPlesso = false;
      this.classiPlesso = this.plesso.classi;

      if (this.edit)
        this.getClasse();
      else
        this.pageService.updatePageTitle({ plesso: this.plesso.name });

    }, (error) => {
      this.loadingPlesso = true;
      this.plessoService.all();
    })
  }

  getFilterListClassi(campo: string) {
    if (!this.classiPlesso) {
      this.listClassiFilteredName = [''];
    } else {
      if (!this.edit) {
        this.listClassiFilteredName = this.classiPlesso.map(x => x.name);
      }
      else {
        this.listClassiFilteredName = this.classiPlesso.filter(x => x.name.toLowerCase().trim() != this.data.name.toLowerCase().trim()).map(x => x.name);
      }
    }
  }

  getFilterOrgUnitName() {
    if (!this.orgUnits || this.data.orgUnitId)
      this.listOrgUnitFilteredName = [''];

    if (!this.data.orgUnitId) {
      let listFilted = this.orgUnits.filter(x => x.parentOrgUnitId == this.plesso.google_org_unit.orgUnitId);

      if (this.controlNameOrgUnit) {
        listFilted = listFilted.filter(x => x.name.toLowerCase().trim() !== this.controlNameOrgUnit.toLowerCase().trim());
      }

      if (!this.edit)
        this.listOrgUnitFilteredName = listFilted.map(x => x.name);
      else
        this.listOrgUnitFilteredName = listFilted.filter(x => x.orgUnitId != this.classe.google_org_unit.orgUnitId).map(x => x.name);
    }
  }

  inputNameClasse(myForm) {
    let errorGoogle = this.myForm.controls.name.getError("forbiddenGoogle");
    let errorString = this.myForm.controls.name.getError("forbiddenString");

    this.formErrors.forbiddenGoogle = errorGoogle ? errorGoogle : null;
    this.formErrors.forbiddenString = errorString ? errorString : null;

    this.forceUppercase('name');
    this.loadGroupsPreview(myForm);
  }

  orgUnitErrorCheckForm() {
    this.myForm.controls.name.setErrors(null);

    if (this.formErrors.forbiddenGoogle && !this.data.orgUnitId) {
      this.myForm.controls.name.setErrors({
        'forbiddenGoogle': true
      });
    }


    if (this.formErrors.forbiddenString) {
      this.myForm.controls.name.setErrors({
        'forbiddenString': true
      });
    }
  }

  forceUppercase(campo: string) {
    this.data[campo] = this.data[campo].toUpperCase();
    this.getFilterOrgUnitName();
    this.getFilterListClassi("name");
  }

  changeCreateOrgUnit() {
    if (this.defaultCheckOrgUnit.crea) {
      this.myForm.controls.name.setErrors(null);

      if (this.formErrors.forbiddenGoogle)
        this.myForm.controls.name.setErrors({
          'forbiddenGoogle': true
        });


      if (this.formErrors.forbiddenString)
        this.myForm.controls.name.setErrors({
          'forbiddenString': true
        });
    }
  }

  loadGroupsPreview(myForm: NgForm) {
    this.existErrorGroupPreview.studenti = false;
    this.existErrorGroupPreview.docenti = false;
    this.existErrorGroupPreview.all = false;

    if (this.showGroupSettings) {
      let objParametro = {
        name_plesso: this.plesso.name,
        short_name_plesso: this.plesso.short_name,
        name_classe: myForm.controls.name.value,
        anno_classe: myForm.controls.anno.value
      }

      this.groupsPreview = {
        all: this.parametriService.getEmailParamsFor(ParamType.ClasseGruppo, objParametro),
        docenti: this.parametriService.getEmailParamsFor(ParamType.ClasseDocente, objParametro),
        studenti: this.parametriService.getEmailParamsFor(ParamType.ClasseStudente, objParametro)
      }
    }

    if (this.edit) {
      this.editDataChange();
    }
  }

  checkGroupSetOnFocusOut() {
    // Faccio il check se esiste il gruppo e lo preseleziono se defaultCheckGroup.type è true
    if (this.defaultCheckGroup.studenti) {
      this.data.groupKey_studenti = (this.googleService.getGroupByEmail(this.groupsPreview.studenti)?.id || '');

      if (this.data.groupKey_studenti) {
        // Errore, esiste già gruppo
        this.existErrorGroupPreview.studenti = true;
        this.defaultCheckGroup.studenti = false;
      }
    }

    if (this.defaultCheckGroup.docenti) {
      this.data.groupKey_docenti = (this.googleService.getGroupByEmail(this.groupsPreview.docenti)?.id || '');

      if (this.data.groupKey_docenti) {
        // Errore, esiste già gruppo
        this.existErrorGroupPreview.docenti = true;
        this.defaultCheckGroup.docenti = false;
      }
    }

    if (this.defaultCheckGroup.all && (this.parameter && this.parameter.gest_group_classe)) {
      this.data.groupKey_all = (this.googleService.getGroupByEmail(this.groupsPreview.all)?.id || '');

      if (this.data.groupKey_all) {
        // Errore, esiste già gruppo
        this.existErrorGroupPreview.all = true;
        this.defaultCheckGroup.all = false;
      }
    }
  }

  editDataChange() {
    // Controllo quali dati sono cambiati
    Object.keys(this.data).forEach(key => {
      let groupKey = null;
      switch (key) {
        case 'name':
        case 'anno':
        case 'description':
          this.dataChange[key] = this.data[key] !== this.classe[key] ? true : false;
          break;
        case 'orgUnitId':
          this.dataChange.org_unit = this.data.orgUnitId !== this.classe?.google_org_unit?.orgUnitId ? true : false;
          break;
        case 'groupKey_all':

          groupKey = this.classe.google_group.find(
            (x) => x.relation_info == RelationInfo.RelationInfoClasse
          )?.groupKey;

          this.dataChange[key] = this.data[key] !== groupKey ? true : false;
          break;
        case 'groupKey_docenti':

          groupKey = this.classe.google_group.find(
            (x) => x.relation_info == RelationInfo.RelationInfoDocenti
          )?.groupKey;

          this.dataChange[key] = this.data[key] !== groupKey ? true : false;
          break;
        case 'groupKey_studenti':

          groupKey = this.classe.google_group.find(
            (x) => x.relation_info == RelationInfo.RelationInfoStudenti
          )?.groupKey;

          this.dataChange[key] = this.data[key] !== groupKey ? true : false;
          break;
        default:
          break;
      }
    });
  }

  selectGroupOnChange($event) {
    if (this.edit)
      this.editDataChange();
  }

  getClasse() {
    this.loadingClasse = true;
    this.plessoService.getClasse(this.id_plesso, this.id_classe, (cls) => {
      this.classe = cls;
      this.data.name = this.classe.name;
      this.data.anno = this.classe.anno;

      if (this.classe.google_org_unit) {

        this.data.orgUnitId = this.classe.google_org_unit.orgUnitId;
      }

      if (this.classe.google_group && this.classe.google_group.length > 0) {
        this.showGroupSettings = true;
        this.defaultCheckGroup.studenti = false;
        this.defaultCheckGroup.docenti = false;
        this.existErrorGroupPreview.all = false;
      }
      else {
        this.showGroupSettings = false;
        this.defaultCheckGroup.studenti = true;
        this.defaultCheckGroup.docenti = true;
        this.existErrorGroupPreview.all = true;
      }

      this.loadingClasse = false;

      if (!this.classiPlesso) {
        this.listClassiFilteredName = [''];
      } else {
        if (!this.edit) {
          this.listClassiFilteredName = this.classiPlesso.map(x => x.name);
        }
        else {
          this.listClassiFilteredName = this.classiPlesso.filter(x => x.name.toLowerCase().trim() != this.data.name.toLowerCase().trim()).map(x => x.name);
        }
      }

      this.getGoogleData();

    }, (error) => {
      this.plessoService.all();
      this.backendError();
    });
  }

  getGoogleData() {
    this.googleService.orgunitsList();
    this.googleService.groupsList();
  }

  googleError() {
    this.error = "google";
    this.formLoading = false;
    this.loadingGoogleOrgUnits = false;
    this.loadingGoogleGroups = false;
  }

  backendError() {
    this.error = "backend";
    this.formLoading = false;
    this.loadingClasse = false;
    this.loadingPlesso = false;
  }

  onSubmit(myForm: NgForm) {

    if (myForm.valid) {
      this.formLoading = true;
      // Copio i dati dell'oggetto senza riferimento
      let reqBody = JSON.parse(JSON.stringify(myForm.form.value));

      // Gruppo All
      let creatGroup_All = false;
      let groupKey_all_Exist = null;
      let groupKey_all_Value = null;

      if (myForm.form.value.groupKey_all) {
        groupKey_all_Exist = this.googleService.getGroupByGroupKey(myForm.form.value.groupKey_all);

        if (groupKey_all_Exist) {
          creatGroup_All = false;
          groupKey_all_Value = groupKey_all_Exist.id;
        }
        else {
          creatGroup_All = true;
          groupKey_all_Value = myForm.form.value.groupKey_all;
        }
      } else {
        groupKey_all_Exist = null;
        groupKey_all_Value = null;
        creatGroup_All = (myForm.form.value.newGroupStandard_A || false);
      }

      reqBody.groupKey_all = {};
      reqBody.groupKey_all.groupValue = groupKey_all_Value;
      reqBody.groupKey_all.create = creatGroup_All;

      // Gruppo Docenti
      let existGroup_Docenti = this.googleService.getGroupByGroupKey(myForm.form.value.groupKey_docenti);
      reqBody.groupKey_docenti = {};
      reqBody.groupKey_docenti.groupValue = existGroup_Docenti ? existGroup_Docenti.id : myForm.form.value.groupKey_docenti;
      reqBody.groupKey_docenti.create = (existGroup_Docenti || !this.defaultCheckGroup.docenti) ? false : true;

      // Gruppo Studenti
      let existGroup_Studenti = this.googleService.getGroupByGroupKey(myForm.form.value.groupKey_studenti);
      reqBody.groupKey_studenti = {};
      reqBody.groupKey_studenti.groupValue = existGroup_Studenti ? existGroup_Studenti.id : myForm.form.value.groupKey_studenti;
      reqBody.groupKey_studenti.create = (existGroup_Studenti || !this.defaultCheckGroup.studenti) ? false : true;

      // Imposto come ParentOrgUnitId di Creazione l'id del plesso OrgUnit
      reqBody.parentOrgUnitId = this.plesso.google_org_unit.orgUnitId;

      if (this.id_classe && this.id_classe.length > 0) {
        // Update
        this.classeService.update(reqBody, this.id_classe, this.id_plesso, true, (result) => {
          this.formLoading = false;
          this.navigateAfterSubmit();
        }, (error) => this.backendError())
      } else {
        // Create
        this.classeService.create(reqBody, this.id_plesso, true, (result) => {
          this.formLoading = false;
          this.navigateAfterSubmit();
        }, (error) => this.backendError())
      }
    }
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  onValueChangeOrgUnit(value) {
    this.data.orgUnitId = value;

    if (this.edit)
      this.editDataChange();

    this.orgUnitErrorCheckForm();
  }

  onSelectedOrgUnitId(value) {
    this.controlNameOrgUnit = (value?.name || "");
    if (!this.edit && !this.data.name) {
      this.data.name = (value?.name.toUpperCase() || "");
    }

    if (!this.edit) {
      // Rimuovo questa funzionalità in attesa di una revisione o rimozione completa
      // this.data.importStudentFromOrgUnit = true;
    }

    this.selectGroupOnChange(null);
  }

  noGroupExist(value, name) {
    this.groupNotExist[name] = value;
  }

  navigateAfterSubmit() {
    if (this.id_classe && this.id_classe.length > 0) {
      this.router.navigate([`/plesso/${this.id_plesso}/classe/${this.id_classe}`]);
    }
    else {
      this.router.navigate([`/plesso/${this.id_plesso}`]);
      // this.pageService.back();
    }
  }

  previewEmailSelectedGroup(groupKey) {
    return (this.googleService.getGroupByGroupKey(groupKey)?.email || null);
  }
}

