
import { trigger, style, transition, animate, state } from '@angular/animations';
import { Component, Input, Output, EventEmitter, OnChanges, OnInit, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
@Component({
  selector: 'app-custom-dropdown-treeview',
  templateUrl: './custom-dropdown-treeview.component.html',
  styleUrls: ['./custom-dropdown-treeview.component.scss'],
  animations: [
    trigger('toggleHeight', [
      state('hide', style({
        height: '0px',
        opacity: '0',
        overflow: 'hidden',
        // display: 'none'
      })),
      state('show', style({
        height: '*',
        opacity: '1',
        // display: 'block'
      })),
      transition('hide => show', animate('200ms ease-in')),
      transition('show => hide', animate('200ms ease-out'))
    ])
  ],
  host: {
    '(document:click)': 'onClickOut($event)',
  },
})
export class CustomDropdownTreeViewComponent implements OnInit, OnChanges {

  filterText: any;

  @Input() items: TreeNode[];
  @Input() configs: any;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @Output() selectedChange = new EventEmitter<any>();

  _selectedNode: any;
  _label = '';
  _notFoundText: string;

  isShow = false;
  touched = false;

  constructor(private _eref: ElementRef, private translate: TranslateService) {
    this._notFoundText = this.translate.instant("STR.not_found");
  }

  onClickOut(event) {
    if (!this._eref.nativeElement.contains(event.target))
      this.isShow = false;
  }

  ngOnInit() {
    this.touched = false;

    if (this.value && (typeof this.value == 'string' || typeof this.value == 'number')) {
      this.ngOnChanges();
    }
  }

  searchTree(element, matchingKey) {
    if (element.key == matchingKey) {
      return element;
    } else if (element.children != null) {
      let result = null;
      for (let i = 0; result == null && i < element.children.length; i++) {
        result = this.searchTree(element.children[i], matchingKey);
      }
      return result;
    }
    return null;
  }

  ngOnChanges(): void {
    this.updateSelectedItem();
  }

  private updateSelectedItem(): void {
    if (this.items) { // TODO_ENRICO verificare se funziona correttamente, prima veniva usata la funzione !isNil(this.items) di lodash

      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        this._selectedNode = this.searchTree(element, this.value);

        if (this._selectedNode) {
          this._label = this._selectedNode.label;
          break
        };
      }

      if (!this._selectedNode) {
        this._label = '';
      }

    }
  }

  private selectItem(item: TreeNode): void {
    if (item) {

      this.selectedChange.emit(item.data);

      if (this.value !== item.key) {
        this.value = item.key;
        this.valueChange.emit(item.key);
      }
    } else {
      this.selectedChange.emit(null);
      this.valueChange.emit(null);
      this._label = '';
    }
  }

  nodeSelect(event) {
    this.selectItem(event.node);
    this._label = event.node.label;
    this.isShow = false;
  }

  nodeUnselect() {
    this.selectItem(null);
    this._label = '';
  }
}
