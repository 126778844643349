<div class="row mb-3">
  <div class="col" *ngIf="safeVideoUrl">
    <iframe width="100%" height="100%" [src]="safeVideoUrl" style="border-radius: 0.75rem; min-height: 200px;"
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
  <div class="col" *ngIf="section">
    <div class="card" style="height: 100%;">
      <div class="card-header">
        <h4 class="card-header-title" [innerHTML]="'TUTORIAL.'+section+'.title' | translate"></h4>
      </div>
      <div class="card-body" [innerHTML]="'TUTORIAL.'+section+'.message' | translate"></div>
    </div>
  </div>
</div>
