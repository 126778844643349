<div class="card card-hover-shadow mb-5">
  <div class="card-header">
    <h3 class="card-header-title">Licenza</h3>
  </div>
  <div class="card-body">
    <div class="alert alert-success" role="alert"
      *ngIf="account.ente.subscription_active == 1 && !account.ente.subscription_end">
      La licenza è attiva
    </div>
    <div class="alert alert-success" role="alert"
      *ngIf="account.ente.subscription_active == 1 && account.ente.subscription_end && !account.ente.subscription_expired">
      La licenza scade il {{ account.ente.subscription_end | date:'medium' }}
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="account.ente.subscription_active == 0">
      La licenza non è attiva
    </div>
    <div class="alert alert-soft-danger" role="alert"
      *ngIf="account.ente.subscription_active == 1  && account.ente.subscription_expired">
      La licenza è scaduta il {{ account.ente.subscription_end | date:'medium' }}
    </div>
  </div>
</div>

<div class="card card-hover-shadow mb-5">
  <div class="card-header">
    <h3 class="card-header-title">Informazioni Account</h3>
  </div>
  <div class="card-body">
    <h4>Ente: {{ account.ente.name }}</h4>
    <span class="card-text">{{ account.givenName }} {{ account.familyName }}</span>
    <span class="card-text">{{ account.email }}</span>

    <ng-container *ngFor="let indirizzo of account.ente.addresses">
      <h4 class="mt-3">Indirizzo</h4>
      <span class="card-text">{{ indirizzo.email }}</span>
      <span class="card-text">{{ indirizzo.indirizzo }}</span>
      <span class="card-text">{{ indirizzo.citta }}, {{ indirizzo.nazione }}</span>
      <span class="card-text">{{ indirizzo.telefono }}</span>
    </ng-container>

    <button routerLink="/ente/edit" class="btn btn-outline-primary btn-xs mt-3">
      <i class="fad fa-id-card mr-2"></i>Premi qui per modificare il profilo
    </button>
  </div>
</div>

<div class="card card-hover-shadow mb-5">
  <div class="card-header">
    <h3 class="card-header-title">Ultimi accessi</h3>
  </div>
  <div class="card-body">
    <div class="row mb-3" *ngFor="let log of authenticationLogs">
      <div class="col-3">
        <h5 class="mb-0" *ngIf="log.login_at">{{ log.login_at | date:'medium' }}</h5>
        <h5 class="mb-0" *ngIf="log.logout_at">{{ log.logout_at | date:'medium' }}</h5>
        <span class="badge badge-pill" [class]="log.login_successful ? 'badge-soft-success' : 'badge-soft-danger'"
          *ngIf="log.login_at">Login <span *ngIf="!log.login_successful">fallito</span></span>
        <span class="badge badge-pill badge-soft-secondary" *ngIf="log.logout_at">Logout</span>
      </div>

      <div class="col-auto">
        <h5 class="font-size-sm mb-0">{{ log.user_agent }}</h5>
        <span class="font-size-sm">{{ log.ip_address }}</span>
      </div>

    </div>
  </div>
</div>
