import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CacheService, CacheType } from 'src/app/services/cache.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleClassroomService } from 'src/app/services/google/google-classroom.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';

@Component({
  selector: 'app-classroom-profile-info-card',
  templateUrl: './classroom-profile-info-card.component.html',
  styleUrls: ['./classroom-profile-info-card.component.scss']
})
export class ClassroomProfileInfoCardComponent implements OnInit {
  @Input() id: any;
  course: any;
  ownerCourse: any;
  classroom: any[];
  error: any;

  showInfo = false;

  _loadingData = {
    course: false,
    students: false,
    teachers: false,
    announcements: false,
    courseWork: false,
    courseWorkMaterials: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  private _googleServiceClassroomSubscription: Subscription;
  private _userServiceSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;

  constructor(
    private classroomService: GoogleClassroomService,
    private googleDirectoryService: GoogleDirectoryService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private cacheService: CacheService,
  ) { }

  ngOnInit(): void {

    this.unsubscibeAll();
    this.ownerCourse = {};
    this.course = {};
    this.error = null;

    this._loadingData.course = true;

    this._googleServiceClassroomSubscription = this.classroomService.getSubjectToUpdateCoursesObservable().subscribe(
      (result) => {
        this._loadingData.course = false;

        if (result)
          this.classroom = result;

        this.init();
      });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.getInfoOwnerCourse();
      }
    });

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe(
      (result) => {
        if (result) {
          this.getInfoOwnerCourse();
        }
      });

    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  aggiornaDati() {
    this.cacheService.updateCacheStatus(CacheType.GoogleClassroom, false);
    this.classroom = null;
    this.ngOnInit();
  }

  unsubscibeAll() {
    if (this._googleServiceClassroomSubscription)
      this._googleServiceClassroomSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
  }

  init() {
    if (this.id && this.id > 0) {
      if (this.classroom) {
        this.course = this.classroom.find(x => x.id == this.id);
        this._loadingData.course = false;
        if (!this.ownerCourse)
          this.getInfoOwnerCourse();
      } else {
        this.classroomService.coursesList();
      }
    } else {
      this.genericError("course");
    }
  }

  genericError(typeError) {
    this.error = typeError;
    this._loadingData[typeError] = false;
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  getInfoOwnerCourse() {
    let resUser = {
      geniusuiteId: null,
      googleId: this.course.ownerId,
      familyName: null,
      givenName: null,
      fullname: null,
      email: null,
      is_google_user: false,
      is_geniusuite_user: false
    };
    let user = this.userService.getByGoogleId(this.course.ownerId);
    let googleUser = this.googleDirectoryService.getUser(this.course.ownerId);

    if (user) {
      // Sincronizzato con Geniusuite
      resUser.familyName = user.user.familyName;
      resUser.givenName = user.user.givenName;
      resUser.geniusuiteId = user.user.id;
      resUser.fullname = user.user.familyName + " " + user.user.givenName;
      resUser.email = user.user.email;
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = true;

      if (user.google_user) {
        resUser.googleId = this.course.ownerId;

        if (user.google_user.name) {
          resUser.familyName = user.google_user.name?.familyName;
          resUser.givenName = user.google_user.name?.givenName;
          resUser.fullname = user.google_user.name?.familyName + " " + user.google_user.name?.givenName;
        }

        resUser.email = user.google_user.primaryEmail
        resUser.is_google_user = true;
      }
    } else if (googleUser) {
      // Non sincronizzato con Geniusuite ma non ha già i dati caricati
      resUser.familyName = "Not found";
      resUser.givenName = "Not found";
      resUser.fullname = "Not found";

      if (googleUser.name) {
        resUser.familyName = googleUser.name?.familyName;
        resUser.givenName = googleUser.name?.givenName;
        resUser.fullname = googleUser.name?.familyName + " " + googleUser.name?.givenName;
      }

      resUser.email = googleUser.primaryEmail;
      resUser.is_google_user = true;
      resUser.is_geniusuite_user = false;
    } else {
      resUser.familyName = "Not found";
      resUser.givenName = "Not found";
      resUser.fullname = "Not found";
      resUser.email = "Not found";
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = false;
    }

    this.ownerCourse = resUser;
  }

  goToUserInfo() {
    if (this.ownerCourse.is_geniusuite_user)
      this.router.navigate(['user/' + this.ownerCourse.geniusuiteId]);
  }
}
