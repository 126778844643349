import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { PlessoService } from 'src/app/services/general/plesso.service';

@Component({
  selector: 'app-plesso-profile-tree-card',
  templateUrl: './plesso-profile-tree-card.component.html',
  styleUrls: ['./plesso-profile-tree-card.component.scss']
})
export class PlessoProfileTreeCardComponent implements OnInit {

  private _organigrammaServiceSubscription: Subscription;

  plessoTree: TreeNode[];

  selectedNode: TreeNode;

  constructor(
    private router: Router,
    private plessoService: PlessoService,
    private accountService: AccountService,
  ) { }

  ngOnInit() {

    this._organigrammaServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.plessoUpdated(result);
    });

    this.plessoService.all();
  }

  ngOnDestroy() {

    if (this._organigrammaServiceSubscription)
      this._organigrammaServiceSubscription.unsubscribe();
  }

  plessoUpdated(result) {
    this.plessoTree = [{
      label: this.accountService.data.ente.name,
      children: this.parsePlessoTree(result),
      expanded: true,
      icon: "university"
    }]
  }

  parsePlessoTree(list: any): TreeNode[] {

    let menu: TreeNode[] = [];

    list.forEach(plesso => {

      let menu_item = {
        label: plesso.name,
        children: [{
          label: "Classi",
          plesso: plesso,
          classi: plesso.classi,
          icon: "chalkboard",
          type: "classi"
        }],
        expanded: true,
        plesso: plesso,
        icon: "school"
      };

      // let classi_tree = [];

      // plesso.classi.forEach(classe => {

      //   let menu_item = {
      //     label: classe.name,
      //     children: null,
      //     expanded: true,
      //     plesso: plesso,
      //     classe: classe,
      //     icon: "chalkboard"
      //   };

      //   classi_tree.push(menu_item);
      // });

      // if (classi_tree.length > 0)
      //   menu_item.children = classi_tree;

      menu.push(menu_item);
    });

    return menu;
  }

  onNodeSelect(event) {

    let plesso = event.node.plesso;

    if (plesso) {
      this.router.navigate(["/plesso/" + plesso.id]);
    }
  }

}
