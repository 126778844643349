import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MateriaService } from 'src/app/services/general/materia.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { UserService } from 'src/app/services/general/user.service';
import { CourseState, GoogleClassroomService } from 'src/app/services/google/google-classroom.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';

@Component({
  selector: 'app-classe-profile-datatable-card',
  templateUrl: './classe-profile-datatable-card.component.html',
  styleUrls: ['./classe-profile-datatable-card.component.scss']
})
export class ClasseProfileDatatableCardComponent implements OnInit {

  @Input() id_plesso: any;
  @Input() id_classe: any;

  classe: any;
  reloadData: boolean;

  groupsData: any;
  groups: any;
  groupsError: any;

  classroom: any;
  classroomData: any;
  classroomError: any;

  studentiData: any;
  studenti: any;
  studentiError: any;

  docentiData: any;
  docenti: any;
  docentiError: any;

  usersOrgUnitData: any;
  usersOrgUnit: any;
  usersOrgUnitError: any;

  isLoading = {
    users: false,
    utenti: false,
    groups: false,
    classroom: false,
    studenti: false,
    docenti: false,
    usersOrgUnit: false,
  }

  private _plessoServiceClassiSubscription: Subscription;
  private _userServiceSubscription: Subscription;
  private _materiaServiceSubscription: Subscription;

  private _googleServiceOrgunitsSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;
  private _googleServiceGroupsSubscription: Subscription;
  private _googleServiceClassroomSubscription: Subscription;

  private optionsDateFormat: any = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  };

  constructor(
    private router: Router,
    private googleDirectoryService: GoogleDirectoryService,
    private userService: UserService,
    private plessoService: PlessoService,
    private route: ActivatedRoute,
    private googleClassroom: GoogleClassroomService,
    private materiaService: MateriaService,
  ) { }

  ngOnInit() {

    this.unsubscribeAll();

    this._plessoServiceClassiSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });

    this.userService.all();

    this._materiaServiceSubscription = this.materiaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });

    this.materiaService.all();

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe(
      (result) => {
        if (result) {
          this.updateData();
        }
      });

    this._googleServiceOrgunitsSubscription = this.googleDirectoryService.getSubjectToUpdateOrgUnitsObservable().subscribe(
      (result) => {
        if (result) {
          this.updateData();
        }
      });

    this._googleServiceGroupsSubscription = this.googleDirectoryService.getSubjectToUpdateGroupsObservable().subscribe(
      (result) => {
        if (result) {
          this.updateData();
        }
      });

    this._googleServiceClassroomSubscription = this.googleClassroom.getSubjectToUpdateCoursesObservable().subscribe(
      (result) => {
        if (result) {
          this.updateData();
        }
      });

    this.googleData();

    this.selectTab(1);
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id_plesso && changes.id_plesso.currentValue && changes.id_plesso.previousValue !== undefined) {
      this.ngOnInit();
    }
    else if (changes && changes.id_classe && changes.id_classe.currentValue && changes.id_classe.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  googleData() {
    this.googleDirectoryService.orgunitsList();
    this.googleDirectoryService.groupsList();
    this.googleDirectoryService.usersList();
    this.googleClassroom.coursesList();
  }

  setLoading(loading = false) {
    // Loading All
    Object.keys(this.isLoading).forEach(element => {
      this.isLoading[element] = loading;
    });
  }

  updateData() {
    if (this.id_classe && this.id_plesso && this.id_plesso > 0 && this.id_classe > 0) {

      this.isLoading.classroom = true;
      this.isLoading.groups = true;
      this.isLoading.usersOrgUnit = true;

      this.plessoService.getClasse(this.id_plesso, this.id_classe, (data) => {

        this.classe = data;

        this.classroomUpdated(this.classe.google_classroom);
        this.groupsUpdated(this.classe.google_group);
        this.studentiUpdated(this.classe);
        this.docentiUpdated(this.classe);
        this.usersOrgUnitUpdated(this.classe.google_org_unit);

      }, (error) => {
        this.plessoService.all();
      });
    }
  }

  unsubscribeAll() {
    if (this._plessoServiceClassiSubscription)
      this._plessoServiceClassiSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();

    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();

    if (this._googleServiceOrgunitsSubscription)
      this._googleServiceOrgunitsSubscription.unsubscribe();

    if (this._googleServiceClassroomSubscription)
      this._googleServiceClassroomSubscription.unsubscribe();

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._materiaServiceSubscription)
      this._materiaServiceSubscription.unsubscribe();
  }

  selectTab(index) {
    switch (index) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        // Carico Struttura Classi Plesso
        this.setLoading(true);
        this.updateData();
        break;
    }
  }

  studentiUpdated(classe) {
    this.isLoading.studenti = true;

    this.studentiData = [];

    this.studentiData = [...this.studentiData, ...classe.studenti];

    this.studenti = this.userService.getTable(this.studentiData, "classe", this.classe);
    this.isLoading.studenti = false;
  }

  docentiUpdated(classe) {
    this.isLoading.docenti = true;

    this.docentiData = [];

    this.docentiData = [...this.docentiData, ...classe.docenti];

    this.docenti = this.userService.getTable(this.docentiData, "classe", this.classe);
    this.isLoading.docenti = false;
  }

  groupsUpdated(result) {
    this.groupsData = result;
    this.groups = this.parseGroupsResult(result);
    this.isLoading.groups = false;
  }

  classroomUpdated(result) {
    this.classroomData = result;
    this.classroom = this.parseClassroomResult(result);
    this.isLoading.classroom = false;
  }

  usersOrgUnitUpdated(google_org_unit) {

    if (google_org_unit && google_org_unit.orgUnitPath) {
      this.usersOrgUnitData = this.googleDirectoryService.getUsersByOrgUnitPathStartWith(google_org_unit.orgUnitPath);
      this.usersOrgUnit = this.userService.getTable(this.usersOrgUnitData, "orgunit");
    }
    else {
      this.usersOrgUnitData = [];
      this.usersOrgUnit = [];
    }

    this.isLoading.usersOrgUnit = false;
  }

  parseGroupsResult(result) {

    let table = [];

    result.forEach(element => {
      table.push({
        "nome": [element.name, element.description],
        "email": [element.email],
        "membri": [element.directMembersCount],
        "_export_data": {
          name: element.name,
          description: element.description,
          email: element.email,
          directMembersCount: element.directMembersCount,
        },
      })
    });

    return table;
  }

  parseClassroomResult(result) {

    let table = [];

    result.forEach(google_classroom => {

      let element = this.googleClassroom.getCourse(google_classroom.courseId);

      if (element) {

        let proprietario = []

        let sotto_nome = (element.section ? element.section : "") + " " + (element.room ? "(" + element.room + ")" : "");

        if (element.ownerId) {
          let googleuser_proprietario = this.googleDirectoryService.getUser(element.ownerId);

          if (googleuser_proprietario) {
            proprietario = [
              googleuser_proprietario.name.familyName + " " + googleuser_proprietario.name.givenName,
              googleuser_proprietario.primaryEmail
            ]
          }
        }

        let classroomState = "";
        if (element.courseState) {
          if (element.courseState != CourseState.ARCHIVED && element.courseState != CourseState.SUSPENDED) {
            classroomState = "Attiva";
          }
          else if (element.courseState == CourseState.ARCHIVED) {
            classroomState = "Archiviata";
          }
          else if (element.courseState == CourseState.SUSPENDED) {
            classroomState = "Sospesa";
          }
        }

        let materia_name = "";
        if (google_classroom.materia_id) {
          let materia = this.materiaService.get(google_classroom.materia_id);
          materia_name = materia.name;
        }

        table.push(
          {
            "nome": [element.name, sotto_nome],
            "materia": [materia_name],
            "sezione": [element.section],
            "stanza": [element.room],
            "description": [element.descriptionHeading, element.description],
            "creationTime": [new Date(element.creationTime).toLocaleDateString('it-IT', this.optionsDateFormat)],
            "proprietario": proprietario,
            "state": [classroomState],
            "_export_data": {
              name: element.name,
              section: element.section,
              descriptionHeading: element.descriptionHeading,
              description: element.description,
              room: element.room,
              proprietarioName: proprietario[0] ? proprietario[0] : "",
              proprietarioEmail: proprietario[1] ? proprietario[1] : "",
              ownerId: element.ownerId,
              link: element.alternateLink,
              courseGroupEmail: element.courseGroupEmail,
              teacherGroupEmail: element.teacherGroupEmail,
              creationTime: new Date(element.creationTime).toLocaleDateString('it-IT', this.optionsDateFormat),
              updateTime: element.updateTime,
              state: element.courseState,
            },
          });
      }
    });

    return table;
  }

  rowClickGroup(data) {
    let group = this.classe.google_group[data.index];
    this.router.navigate(['group/' + group.groupKey]);
  }

  addClickGroup() {
    return;
    //TODO  Creare Form Aggiungere Group
  }

  rowClickClassroom(data) {
    let classroom = this.classe.google_classroom[data.index];
    this.router.navigate(['classroom/' + classroom.courseId]);
  }

  addClickClassroom() {
    return;
  }

  rowClickStudente(data) {
    let user = this.studentiData[data.index];
    this.router.navigate(['user/' + user.id], { relativeTo: this.route });
  }

  rowClickDocente(data) {
    let user = this.docentiData[data.index];
    this.router.navigate(['user/' + user.id], { relativeTo: this.route });
  }

  addClickUser(role: any) {
    this.router.navigate(['user/add/' + role, { role: role }], { relativeTo: this.route });
  }

  rowClickUserOrgUnit(data) {
    let userOrgUnit = this.usersOrgUnitData[data.index];
    let user = this.userService.getByGoogleId(userOrgUnit.id);

    if (user) {
      this.router.navigate(['user/' + user.user_id], { relativeTo: this.route });
    }
    else {
      this.router.navigate(['user/google/' + userOrgUnit.id], { relativeTo: this.route });
    }
  }
}
