<app-card-loading *ngIf="loading">
</app-card-loading>

<app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">
  <div class="row">
    <div class="col-12">
      <app-card-operation-activity [filterActivity]="['operation.groups.update-setting-groups']">
      </app-card-operation-activity>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <!-- Card -->
        <div class="card card-hover-shadow">
          <div class="card-body">

            <h3 class="card-title">Modifica Parametri Gruppi</h3>
            <p class="card-text">Questa operazione consente di modificare in maniera massiva tutti i gruppi
              selezionati</p>

            <div class="row form-group">
              <div class="col-12">

                <h4>Parametro di Default</h4>
                <app-switch [(value)]="showCustomSettings"
                  (valueChange)="changeValueSwitch('default_parameter', $event)" [id]="'switch_default_parameter'"
                  labelText="Utilizza i valori di default"
                  helpText="Puoi modificare manualmente i parametri disattivando questa opzione oppure puoi cambiare quelli di default nella sezione Parametri Workspace">
                </app-switch>
                <input type="hidden" id="default_parameter" formControlName="default_parameter">
              </div>
            </div>

            <div class="form-group row" id="showCustomSettings" [collapse]="showCustomSettings" [isAnimated]="true">
              <div class="col-12">
                <app-google-group-settings [formGroup]="myForm" [id]="'group_settings'" [json]="group_settings">
                </app-google-group-settings>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="row justify-content-between align-items-center">
              <div class="col-auto py-1">
                <button class="btn btn-outline-danger" (click)="resetOperation()"
                  [disabled]="groups.length <= 0 || operationInProgress">
                  <i class="far fa-backspace mr-2"></i>
                  <span>{{'STR.reset_operation' | translate}}</span>
                </button>
              </div>
              <div class="col-6 py-1">
                <div class="row justify-content-end">
                  <button type="submit" class="btn"
                    [disabled]="!myForm.valid || groups.length <= 0 || operationInProgress"
                    [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                    <span>Avvia spostamento</span>
                    <i class="far fa-chevron-right ml-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Card -->
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-sm">
      <div class="card">
        <app-card-datatable-ng title="{{ 'STR.utenti_selezionati' | translate }}" [data]="data"
          [showDeleteButton]="true" labelDeleteButton="Rimuovi dall'operazione" iconDeleteButton="user-times"
          (deleteClickEvent)="deleteEvent($event)" [showExportButton]="true"></app-card-datatable-ng>
      </div>
    </div>
  </div>
</ng-container>
