import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem, SortEvent } from 'primeng/api';
import { Subscription } from 'rxjs/internal/Subscription';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-datatable-ng',
  templateUrl: './datatable-ng.component.html',
  styleUrls: ['./datatable-ng.component.scss']
})
export class DatatableNgComponent implements OnInit {

  @Output() rowClickEvent = new EventEmitter<any>();
  @Output() addClickEvent = new EventEmitter<any>();
  @Output() deleteClickEvent = new EventEmitter<any>();
  @Output() primaryClickEvent = new EventEmitter<any>();

  @Output() rowCheckedAllEvent = new EventEmitter<boolean>();
  @Output() rowCheckedEvent = new EventEmitter<any>();

  @Input() headerTableData: any;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() data: any;
  @Input() menuItems: MenuItem[];

  // Button
  @Input() labelDeleteButton: string = "Cancella";
  @Input() iconDeleteButton: string = "trash";
  @Input() showDeleteButton: boolean = false;

  @Input() labelPrimaryButton: string = "Aggiorna";
  @Input() iconPrimaryButton: string = "check-square";
  @Input() showPrimaryButton: boolean = false;
  @Input() showAvatarOnFirstColumn: boolean = false;
  @Input() showExportButton: boolean = false;
  @Input() showAddButton: boolean = false;
  @Input() showCheckBox: boolean = true;
  @Input() rowExpansion: boolean = false;

  optionsDateFormat: any = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  };

  globalFilterFields: any = [];

  // Ordinamento Oggetto keyvalue
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  selectedItems: any = [];
  selectedItemCopyData: any;
  itemsMenuCopy: MenuItem[];

  countSelectedRow = 0;
  rowsPerPage = 25;
  first = 0;

  private _activeRouteSubscription: Subscription;

  constructor(private activeRoute: ActivatedRoute, private settingsService: SettingsService) { }

  ngOnInit(): void {

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.selectedItems = [];
    });

    this.globalFilterFields = this.headerTableData;
    this.itemsMenuCopy = [
      { label: 'Copia il nome negli appunti', icon: 'fas fa-copy', command: () => this.copyData(this.selectedItemCopyData) }
    ];

    this.first = 0;

    this.rowsPerPage = this.settingsService.datatableRowsPerPage;
  }

  ngOnDestroy() {

    this.settingsService.updateDatatableRowsPerPage(this.rowsPerPage);

    if (this._activeRouteSubscription) {
      this._activeRouteSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && changes.data.currentValue && changes.data.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  deleteSelected() {
    this.deleteClickEvent.emit(this.selectedItems);
    this.selectedItems = [];
  }

  rowCheckedAll($event: Event) {
    this.rowCheckedEvent.emit(this.selectedItems);
  }

  rowChecked($event: Event, row, index) {
    this.rowCheckedEvent.emit(this.selectedItems);
  }

  copyData(selectedData) {
    if (selectedData && selectedData._copyDataKey) {
      let dataSelected = "";

      if (this.checkIsArray(selectedData[selectedData._copyDataKey])) {
        dataSelected = selectedData[selectedData._copyDataKey].join(' ');
      } else {
        dataSelected = selectedData[selectedData._copyDataKey];
      }

      navigator.clipboard.writeText(dataSelected).then(function () {
      });
    }
  }

  primarySelected() {
    this.primaryClickEvent.emit(this.selectedItems);
  }

  checkIsArray(val) {
    return Array.isArray(val);
  }

  checkIsBoolean(val) {
    return typeof val === "boolean";
  }

  checkIsDate(val) {
    return val instanceof Date;
  }

  checkKeyExistInHeader(key) {
    return this.headerTableData.includes(key);
  }

  rowClicked($event: Event, data: any[] | Object, index: number) {
    this.rowClickEvent.emit({ event: $event, data: data, index: index });
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let a = data1[event.field];
      let b = data2[event.field];
      let result = 0;

      let aIsArray = Array.isArray(a);
      let bIsArray = Array.isArray(a);

      if (aIsArray && !bIsArray)
        result = this.checkValue(a[0], b);

      if (!aIsArray && bIsArray)
        result = this.checkValue(a, b[0]);

      if (!aIsArray && !bIsArray)
        result = this.checkValue(a, b);

      if (aIsArray && bIsArray)
        result = this.checkValue(a[0], b[0]);

      return (event.order * result);
    });
  }

  checkValue(a: any, b: any) {
    let result = 0;
    if (a == null && b != null)
      result = -1;
    else if (a != null && b == null)
      result = 1;
    else if (a == null && b == null)
      result = 0;
    else if (a instanceof Date && typeof b === 'string')
      result = 1;
    else if (typeof a === 'string' && b instanceof Date)
      result = -1;
    else if (typeof a === 'string' && typeof b === 'string')
      result = a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
    else
      result = (a < b) ? -1 : (a > b) ? 1 : 0;

    return result;
  }
}
