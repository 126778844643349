<p-contextMenu #menuCopy [model]="itemsMenuCopy" appendTo="body"></p-contextMenu>

<p-table #dt1 [value]="data" [(rows)]="rowsPerPage" [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10,25,50,100,250,500]" [loading]="false" [rowHover]="true"
  styleClass="p-datatable-custom table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
  [paginator]="true" currentPageReportTemplate="Stai visualizzando: {first} su {last} di {totalRecords} elementi"
  [globalFilterFields]="globalFilterFields" [(contextMenuSelection)]="selectedItemCopyData" [contextMenu]="menuCopy"
  [(selection)]="selectedItems" dataKey="index_col" (sortFunction)="customSort($event)" [customSort]="true"
  [(first)]="first">

  <ng-template pTemplate="caption">
    <div class="row justify-content-between align-items-center flex-grow-1">
      <div class="col-9">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="card-header-title mr-3">{{ (title) || "Dati" }}</h5>

          <!-- Datatable Info -->
          <div class="d-flex card-datatable-menubar">
            <div class="d-flex align-items-center mr-3">
              <span class="font-size-sm mr-3" *ngIf="selectedItems && selectedItems.length > 0">
                {{selectedItems.length }} righe selezionate
              </span>
              <span class="font-size-sm mr-3" *ngIf="!selectedItems || selectedItems.length == 0">
                Seleziona per utilizzare le operazioni
              </span>
              <p-menubar *ngIf="menuItems && menuItems[0]" [model]="menuItems" styleClass="btn-selected-row-datatable"
                [ngClass]="{'disabled': !selectedItems || selectedItems.length == 0}"></p-menubar>
            </div>
            <button *ngIf="showPrimaryButton && selectedItems && selectedItems.length > 0"
              class="btn btn-sm btn-outline-primary mr-2" (click)="primarySelected()">
              <i class="mr-1 fas fa-{{iconPrimaryButton}}"></i>
              {{labelPrimaryButton}}
            </button>
            <button *ngIf="showDeleteButton && selectedItems && selectedItems.length > 0"
              class="btn btn-sm btn-outline-danger mr-2" (click)="deleteSelected()">
              <i class="mr-1 fas fa-{{iconDeleteButton}}"></i>
              {{labelDeleteButton}}
            </button>
            <button *ngIf="showAddButton && selectedItems && selectedItems.length == 0" type="button"
              class="btn btn-outline-primary btn-sm" (click)="addClickEvent.emit()">
              <i class="mr-1 fas fa-plus"></i>
              Aggiungi nuovo
            </button>
            <button *ngIf="showAddButton && selectedItems && selectedItems.length > 0" type="button"
              class="btn btn-outline-primary btn-sm btn-icon" (click)="addClickEvent.emit()">
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <!-- End Datatable Info -->
        </div>
      </div>

      <div class="col-3">
        <!-- Filter -->
        <div class="row align-items-sm-center">
          <div class="col-md">
            <!-- Search -->
            <div class="input-group input-group-merge input-group-flush">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="tio-search"></i>
                </div>
              </div>
              <input name="searchFilterDatatable" type="search" class="form-control" placeholder="Ricerca..."
                aria-label="Ricerca" (input)="dt1.filterGlobal($event.target.value, 'contains')">
            </div>
            <!-- End Search -->
          </div>
        </div>
        <!-- End Filter -->
      </div>

      <div *ngIf="subTitle && subTitle.length > 0" class="col-12">
        <div class="alert alert-soft-info mt-3 mb-0">{{ subTitle }}</div>
      </div>
    </div>

    <!-- <div class="p-d-flex">
                        <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
                            (click)="clear(dt1)"></button>
                        <span class="p-input-icon-left p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search keyword" />
                        </span>
                    </div> -->
  </ng-template>

  <ng-template pTemplate="header" *ngIf="headerTableData && headerTableData && headerTableData.length > 0">
    <tr>
      <th scope="col" style="width: 40px;" class="p-2 text-center" *ngIf="showCheckBox">
        <div style="transform: scale(0.78);">
          <p-tableHeaderCheckbox (click)="rowCheckedAll($event)"></p-tableHeaderCheckbox>
        </div>
      </th>

      <ng-container *ngFor="let c of headerTableData; let first = first; let index = index;">
        <th *ngIf="rowExpansion && c == 'expandData'; else elseBlockHeader" style="width: 3rem"></th>
        <ng-template #elseBlockHeader>
          <th colspan="1" pSortableColumn="{{c}}" scope="col" class="p-2" [ngClass]="{'pl-3': !showCheckBox }"
            [ngStyle]="{'width': (first) ? '45%' : 'auto'}">
            <div class="p-d-flex p-jc-between p-ai-center">
              {{ 'STR.' + c | translate }}
              <p-sortIcon field="{{c}}"></p-sortIcon>
            </div>
          </th>
        </ng-template>
      </ng-container>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-row let-rowIndex="rowIndex" let-columns="columns" let-expanded="expanded">
    <tr>
      <td scope="row" style="width: 40px;" class="p-2 text-center" *ngIf="showCheckBox">
        <div style="transform: scale(0.70);">
          <p-tableCheckbox [value]="row" [index]="rowIndex" (click)="rowChecked($event, row, rowIndex)">
          </p-tableCheckbox>
        </div>
      </td>
      <ng-container *ngFor="let item of row | keyvalue: originalOrder; let first = first">

        <ng-container>
          <ng-container [ngSwitch]="item.key">
            <ng-container *ngSwitchCase="'expandData'">
              <td *ngIf="rowExpansion">
                <button *ngIf="item.value" type="button" pButton pRipple [pRowToggler]="row"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              </td>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <td [pContextMenuRow]="item" colspan="1" class="p-2" *ngIf="checkKeyExistInHeader(item.key)"
                [ngClass]="{'pl-3': !showCheckBox }">
                <a (click)="rowClicked($event, row, row.index_col)">
                  <div *ngIf="first && showAvatarOnFirstColumn" class="media align-items-center">
                    <div class="avatar avatar-soft-primary avatar-circle mr-2">
                      <span style="width: 2.625rem;" class="avatar-initials">{{ item.value[0] |
                        slice:0:1
                        }}</span>
                    </div>
                    <div class="media-body">
                      <span *ngFor="let col of item.value; let firstValue = first" class="d-block"
                        [ngClass]="{'h5 text-hover-primary mb-0': firstValue, 'text-body font-size-sm': !firstValue}"
                        [innerHTML]="col"></span>
                    </div>
                  </div>

                  <div *ngIf="first && !showAvatarOnFirstColumn">
                    <span *ngFor="let col of item.value; let firstValue = first" class="d-block"
                      [ngClass]="{'h5 text-hover-primary mb-0': firstValue, 'text-body font-size-sm': !firstValue}"
                      [innerHTML]="col"></span>
                  </div>
                </a>

                <div *ngIf="!first">
                  <ng-container *ngIf="checkIsArray(item.value); else notArrayBlock">
                    <ng-container *ngFor="let col of item.value">
                      <ng-container *ngTemplateOutlet="dataShow; context:{ value: col}">
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-template #notArrayBlock>
                    <div [ngSwitch]="item.key">
                      <ng-container *ngSwitchCase="'sync'">
                        <ng-container *ngTemplateOutlet="syncPosition; context:{ value: item.value}">
                        </ng-container>
                      </ng-container>
                      <div *ngSwitchDefault>
                        <ng-container *ngTemplateOutlet="dataShow; context:{ value: item.value}">
                        </ng-container>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-row>
    <tr>
      <td colspan="20"
        style="background-color: #f9fafc !important;border-top: 1px solid #efefef !important;border-bottom: 1px solid #efefef!important;">
        <div class="pl-3 pr-3 pt-1 row" [innerHTML]="row.expandData">
        </div>
      </td>
    </tr>
  </ng-template>

  <!-- <ng-container *ngIf="showExportButton">
    <ng-template pTemplate="paginatorleft">
    </ng-template>
    <ng-template pTemplate="paginatorright">
      <button (click)="exportExcel()" type="button" class="btn btn-soft-primary">Esporta in XLS</button>
    </ng-template>
  </ng-container> -->

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="100%">
        <div class="text-center p-4"><img class="mb-3" src="./assets/theme/svg/illustrations/sorry.svg"
            style="width: 7rem;">
          <p class="mb-0">Non ci sono dati da mostrare</p>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<ng-template #dataShow let-value="value">
  <ng-container *ngIf="checkIsBoolean(value);">
    <ng-container [ngSwitch]="value">
      <ng-container *ngSwitchCase="true">
        <i class="fas fa-check-circle text-success"></i>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <i class="fas fa-exclamation-circle text-warning"></i>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="checkIsDate(value);">
    <span class="text-body d-block font-size-sm" [innerHTML]="value.toLocaleDateString('it-IT', optionsDateFormat)"></span>
  </ng-container>
  <ng-container *ngIf="!checkIsDate(value) && !checkIsBoolean(value)">
    <span class="text-body d-block font-size-sm" [innerHTML]="value"></span>
  </ng-container>

</ng-template>

<ng-template #syncPosition let-value="value">
  <ng-container>
    <div class="avatar-group avatar-group-xs pr-1" style="text-align: end; z-index: 0;">
      <span *ngIf="!value.geniusuite" class="avatar avatar-primary avatar-circle shadow-sync-button p-1">
        <img class="avatar-img" src="./assets/img/icon-disabled.png" alt="Image Description">
      </span>
      <span *ngIf="!value.google" class="avatar avatar-primary avatar-circle shadow-sync-button ">
        <img class="avatar-img" src="./assets/Google Image/logo-g-disabled.png" alt="Image Description">
      </span>
      <span *ngIf="value.geniusuite" class="avatar avatar-primary avatar-circle shadow-sync-button p-1">
        <img class="avatar-img" src="./assets/img/icon.png" alt="Image Description">
      </span>
      <span *ngIf="value.google" class="avatar avatar-primary avatar-circle shadow-sync-button ">
        <img class="avatar-img" src="./assets/Google Image/logo-g.png" alt="Image Description">
      </span>
    </div>
  </ng-container>

</ng-template>
