import { Injectable, NgZone } from '@angular/core';
import { AccountService } from '../account.service';
import { UtilityService } from '../utility.service';
import { Observable, Subject } from 'rxjs';
import { CacheReportService } from '../cachereport.service';
import { CacheService } from '../cache.service';

export enum ApplicationReportType {
  access_transparency = "access_transparency",
  admin = "admin",
  calendar = "calendar",
  classroom = "classroom",
  chat = "chat",
  chrome = "chrome",
  drive = "drive",
  gcp = "gcp",
  gplus = "gplus",
  groups = "groups",
  groups_enterprise = "groups_enterprise",
  jamboard = "jamboard",
  login = "login",
  meet = "meet",
  mobile = "mobile",
  rules = "rules",
  saml = "saml",
  token = "token",
  user_accounts = "user_accounts",
  gmail = "gmail"
}
@Injectable({
  providedIn: 'root'
})
export class GoogleReportService {

  private baseApiPath = "https://www.googleapis.com/admin/reports/v1";
  private activities = {
    access_transparency: [],
    admin: [],
    calendar: [],
    classroom: [],
    chat: [],
    chrome: [],
    drive: [],
    gcp: [],
    gplus: [],
    groups: [],
    groups_enterprise: [],
    jamboard: [],
    login: [],
    meet: [],
    mobile: [],
    rules: [],
    saml: [],
    token: [],
    user_accounts: [],
    gmail: []
  };

  private customerUsageReports = {
    access_transparency: [],
    admin: [],
    calendar: [],
    classroom: [],
    chat: [],
    chrome: [],
    drive: [],
    gcp: [],
    gplus: [],
    groups: [],
    groups_enterprise: [],
    jamboard: [],
    login: [],
    meet: [],
    mobile: [],
    rules: [],
    saml: [],
    token: [],
    user_accounts: [],
    gmail: []
  };

  private _subjectToUpdateActivities: Subject<any> = new Subject<any>();
  private _subjectToUpdateCustomerUsage: Subject<any> = new Subject<any>();

  constructor(private accountService: AccountService, private cacheService: CacheService, private ngZone: NgZone, private utility: UtilityService) { }

  public getSubjectToUpdateActivitiesObservable(): Observable<any> {
    return this._subjectToUpdateActivities.asObservable();
  }

  public getSubjectToUpdateCustomerUsageObservable(): Observable<any> {
    return this._subjectToUpdateCustomerUsage.asObservable();
  }

  /**
   * Recuperop Activities
   * @param userKey se è null recupero tutte le attiviata (All)
   * @param applicationName Informazioni in base alla Applicazione
   * @param callbackOk
   * @param callbackError
   */
  public activitiesList(userKey = null, applicationName, startTime = null, endTime = null, callbackOk = (result: any) => { }, callbackError = (error: any) => { }, callCallbackOkEachPage: boolean = false) {
    if (this.cacheService.isCacheValid(applicationName)) {
      let dataCloned = this.utility.cloneData(this.activities[applicationName]);
      this._subjectToUpdateActivities.next(dataCloned);
      callbackOk(dataCloned);
      return;
    }

    if (!userKey)
      userKey = "all";

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + `/activity/users/${userKey}/applications/${applicationName}`,
      method: "GET",
      params: {},
    };

    if (startTime && endTime) {
      args.params = {
        "startTime": startTime,
        "endTime": endTime
      }
    }

    this.activities[applicationName] = [];

    this.listPaging(args, "items", (result) => {
      this.ngZone.run(() => {
        this.activities[applicationName] = result;
        this.cacheService.updateCacheStatus(applicationName, true);
        let dataCloned = this.utility.cloneData(this.activities[applicationName]);
        this._subjectToUpdateActivities.next(dataCloned);
        callbackOk(dataCloned);
      });
    }, (error) => {
      this.ngZone.run(() => {
        this._subjectToUpdateActivities.next(null);
        callbackError(error);
      });
    }, "items", null, callCallbackOkEachPage);
  }

  /**
   * Recuperop Activities
   * @param userKey se è null recupero tutte le attiviata (All)
   * @param applicationName Informazioni in base alla Applicazione
   * @param callbackOk
   * @param callbackError
   */
  public customerUsageReportsGet(date, applicationName, callbackOk = (result: any) => { }, callbackError = (error: any) => { }, callCallbackOkEachPage: boolean = false) {
    let indexExist = this.customerUsageReports[applicationName].findIndex(x => x.date == date);
    if (indexExist !== (-1)) {
      let dataCloned = this.utility.cloneData(this.customerUsageReports[applicationName][indexExist]);
      this._subjectToUpdateCustomerUsage.next(dataCloned);
      callbackOk(dataCloned);
      return;
    }

    let args: gapi.client.RequestOptions = {
      path: this.baseApiPath + `/usage/dates/${date}`,
      method: "GET",
      params: {},
    };

    if (!this.customerUsageReports[applicationName] || this.customerUsageReports[applicationName].length == 0)
      this.customerUsageReports[applicationName] = [];

    this.listPaging(args, "usageReports", (result) => {
      this.ngZone.run(() => {
        result.forEach(element => {
          Object.keys(this.customerUsageReports).forEach(appName => {
            let indexDataExist = this.customerUsageReports[appName].findIndex(x => x.date == element.date);
            let parametersFilterByAppName = element.parameters.filter(x => {
              if (x.name.includes(appName))
                return x;
            });

            // Non Esiste nella data di ricerca
            if (indexDataExist === (-1)) {
              this.customerUsageReports[appName].push({
                date: element.date,
                parameters: parametersFilterByAppName
              })
            } else {
              this.customerUsageReports[appName][indexDataExist] = {
                date: element.date,
                parameters: parametersFilterByAppName
              }
            }
          });
        });

        let dataCloned = this.utility.cloneData(this.customerUsageReports[applicationName].find(x => x.date == date) || null);
        this._subjectToUpdateCustomerUsage.next(dataCloned);
        callbackOk(dataCloned);
      });
    }, (error) => {
      this.ngZone.run(() => {
        this._subjectToUpdateCustomerUsage.next(null);
        callbackError(error);
      });
    }, "usageReports", null, callCallbackOkEachPage);
  }

  /**
   * Utility per gestire il paging di una chiamata List
   * @param args
   * @param callbackOk
   * @param callbackError
   * @param cachePropertyName Nome della variabile di classe che conserva la cache del risultato
   * @param pageToken
   */
  private listPaging(args, resultPropertyName, callbackOk = (result: any) => { }, callbackError = (error: any) => { }, cachePropertyName = null, previousResultData = null, pageToken = null, callCallbackOkEachPage: boolean = false) {
    if (pageToken)
      args.params.pageToken = pageToken;

    let request = gapi.client.request(args);

    if (!previousResultData)
      previousResultData = []

    let list = previousResultData;

    request.execute((response) => {
      this.ngZone.run(() => {
        // se c'è un errore lo gestisco e blocco le chiamate
        if (response.error) {
          let res: any = { result: response };
          this.handleError(res, callbackError);
          return;
        }

        if (response[resultPropertyName])
          list = list.concat(response[resultPropertyName]);

        if (callCallbackOkEachPage) {
          let dataCloned = this.utility.cloneData(list);
          callbackOk(dataCloned);
        }

        // se c'è un'altra pagina chiamo ricorsivamente la funzione
        if (response.nextPageToken) {
          this.listPaging(args, resultPropertyName, callbackOk, callbackError, cachePropertyName, list, response.nextPageToken, callCallbackOkEachPage);
        }
        else {
          // solo quando le pagine sono finite chiamo la callback
          let dataCloned = this.utility.cloneData(list);
          callbackOk(dataCloned);
        }
      });
    });
  }

  /**
   * Gestisce l'errore delle chiamate a google api, monitorando l'errore 401 Unoutenticated ed effettua il refresh del token con il backend
   * Solo dopo aver fatto il refresh del token restituisce l'errore
   * @param response
   * @param callbackError
   */
  private handleError(response: gapi.client.HttpRequestRejected, callbackError = (error: any) => { }) {

    if (response.result.error.code == 401) {

      console.warn("Google Access Token scaduto, refresh token...");

      this.accountService.refreshUserData((user) => {

        gapi.client.setToken({ 'access_token': user.google_access_token.token });

        callbackError(response.result.error);

      }, () => {
        callbackError(response.result.error);
      });
    }
    else {
      callbackError(response.result.error);
    }
  }
}
