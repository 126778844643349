import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleApiService } from 'ng-gapi';
import { ActivityService } from 'src/app/services/activity.service';
import { AccountService } from 'src/app/services/account.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { RelationService } from 'src/app/services/general/relation.service';
import { ParametriService } from 'src/app/services/general/parametri.service';
import { ToastService } from 'src/app/services/toast.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { SearchService } from 'src/app/services/search.service';
import { UserService } from 'src/app/services/general/user.service';
import { SyncdataService } from 'src/app/services/syncdata.service';
import { GoogleDriveService } from 'src/app/services/google/google-drive.service';
@Component({
  selector: 'app-bootstrap-page',
  templateUrl: './bootstrap-page.component.html',
  styleUrls: ['./bootstrap-page.component.scss'],
  providers: []
})
export class BootstrapPageComponent implements OnInit {

  loadCompleteDefaultPath = "/dashboard";
  returnUrl: any;
  returnUrlParams: any;

  loadingValue = 0;
  loadingMaxValue = 100;
  loadingStep = 10;
  loadingError: boolean = false;
  error: any;
  retryOnError: any = 1;

  constructor(private accountService: AccountService,
    private gapiService: GoogleApiService,
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private activityService: ActivityService,
    private relationService: RelationService,
    private googleDirectoryService: GoogleDirectoryService,
    private parametriService: ParametriService,
    private toast: ToastService,
    private analyticsService: AnalyticsService,
    private searchService: SearchService,
    private userService: UserService,
    private syncdataService: SyncdataService,
    private googleDriveService: GoogleDriveService
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;

    this.error = null;
    this.loadingError = false;
    this.loadingValue = 0;

    this.accountService.getUserData((user) => {

      this.analyticsService.setUserId(user);

      if (user.ente && (user.ente.subscription_active == 0 || (user.ente.subscription_expired && user.ente.subscription_expiring < -(14 * 60 * 60 * 24)))) {
        this.router.navigate(['/rinnovo-licenza']);
      }
      else {
        this.incrementLoading();
        this.bootApp();
      }
    },
      (error) => {
        if (error.status == 401) {
          this.router.navigate(['/login']);
        }
        else if (error.status == 0) {
          this.router.navigate(['/manutenzione']);
        }
        else {
          this.handleError(error);
        }
      });
  }

  bootApp() {

    if (this.accountService.isGoogleWorkspaceAccount()) {
      this.bootAppWithGoogleAccount();
    }
    else {
      this.bootAppWithoutGoogleAccount();
    }
  }

  incrementLoading() {
    this.ngZone.run(() => {
      let newValue = this.loadingMaxValue / (this.loadingStep + 1);
      this.loadingValue += newValue;
    });
  }

  bootAppWithGoogleAccount() {

    this.gapiService.onLoad().subscribe(() => {

      this.incrementLoading();

      gapi.load('client', () => {

        this.incrementLoading();

        gapi.client.init(this.gapiService.getConfig().getClientConfig()).then(
          (response) => {

            this.incrementLoading();

            gapi.client.setToken({ 'access_token': this.accountService.data.google_access_token.token });

            this.checkTokenScopes();
          },
          (error) => {
            this.handleError(error);
          });
      });
    });
  }

  bootAppWithoutGoogleAccount() {

    this.incrementLoading();
    this.downloadBaseInitData();
  }

  downloadBaseInitData() {

    this.incrementLoading();

    this.ngZone.run(() => {

      this.activityService.init();
      this.searchService.init();

      this.incrementLoading();

      this.userService.all((result) => {

        this.loadComplete();

      }, (error) => {
        this.handleError(error);
      });

    });
  }

  checkTokenScopes() {

    this.incrementLoading();

    this.ngZone.run(() => {

      this.googleDirectoryService.tokenInfo(this.accountService.getData().google_access_token.google_id, this.gapiService.getConfig().getClientConfig(),
        (result) => {

          this.incrementLoading();

          var scopes = result.scopes;
          var scope_missing = false;
          this.googleDirectoryService.scopes_required.forEach(scope_required => {
            var found = false;
            scopes.forEach(token_scope => {
              if (token_scope == scope_required) {
                found = true;
              }
            });

            if (!found) {
              console.error("Scope not found: " + scope_required);
              scope_missing = true;
            }

          });

          if (scope_missing) {
            this.router.navigate(['/workspace-autorizzazioni']);
            return;
          }

          this.downloadAllInitData();
        }, (error) => {
          this.handleError(error);
        }
      );

    });
  }

  downloadAllInitData() {

    this.incrementLoading();

    this.ngZone.run(() => {

      this.activityService.init();
      this.relationService.init();
      this.searchService.init();
      this.googleDriveService.init();

      this.parametriService.all((result) => {
        this.incrementLoading();
        this.googleDirectoryService.orgunitsList((result) => {
          this.incrementLoading();
          this.googleDirectoryService.groupsList((result) => {
            this.incrementLoading();
            this.googleDirectoryService.domainsList((result) => {
              this.incrementLoading();
              this.googleDirectoryService.usersList((result) => {
                this.incrementLoading();
                this.userService.all((result) => {

                  this.incrementLoading();
                  this.loadComplete();

                }, (error) => {
                  this.handleError(error);
                });
              }, (error) => {
                this.handleError(error);
              });
            }, (error) => {
              this.handleError(error);
            });
          }, (error) => {
            this.handleError(error);
          });
        }, (error) => {
          this.handleError(error);
        });

      }, (error) => {
        this.handleError(error);
      });

    });
  }

  handleError(error) {

    this.retryOnError--;

    if (this.retryOnError >= 0) {
      this.retryClicked();
    }
    else {
      this.error = error;
      this.loadingError = true;
    }
  }

  retryClicked() {
    this.ngOnInit();
  }

  loadComplete() {

    this.accountService.setBootstrapComplete();

    this.toast.info("Geniusuite", "Il servizio è pronto per essere utilizzato");

    this.ngZone.run(() => {

      this.loadingValue = this.loadingMaxValue;

      if (this.returnUrl)
        this.router.navigate([this.returnUrl]);
      else
        this.router.navigate([this.loadCompleteDefaultPath]);
    });
  }

}
