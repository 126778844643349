<app-card-loading *ngIf="loadingClasse || loadingGoogleOrgUnits || loadingGoogleGroups || loadingParameterService">
</app-card-loading>

<app-card-loading-error
    *ngIf="!(loadingClasse || loadingGoogleOrgUnits || loadingGoogleGroups || loadingParameterService) && error"
    [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card card-hover-shadow"
    *ngIf="!(loadingClasse || loadingGoogleOrgUnits || loadingGoogleGroups || loadingParameterService) && !error">

    <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>

    <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" [ngClass]="{'card-is-loading': formLoading}">
        <div class="card-body">

            <div class="row">
                <!-- Left -->
                <div class="col">

                    <!-- Titlte -->
                    <div class="row form-group">
                        <div class="col-12">
                            <h3>Classe</h3>
                            <small>Informazioni sulla Classe che verrà eliminata</small>
                        </div>
                    </div>

                    <!-- Info Card -->
                    <div class="row form-group">
                        <div class="col-12">
                            <ul class="list-unstyled list-unstyled-py-3 text-dark mb-3">
                                <li class="py-0">
                                    <small class="card-subtitle">Nome</small>
                                </li>

                                <li *ngIf="classe.name">
                                    {{classe.name }}
                                </li>

                                <li class="py-0">
                                    <small class="card-subtitle">Descrizione</small>
                                </li>

                                <li *ngIf="classe.description">
                                    {{classe.description }}
                                </li>

                                <li *ngIf="!classe.description">
                                    Nessuna descrizione
                                </li>

                                <li class="py-0">
                                    <small class="card-subtitle">Organizzazione</small>
                                </li>

                                <li *ngIf="classe.google_org_unit">
                                    <span
                                        *ngIf="!classe.google_org_unit.not_found">{{classe.google_org_unit.name}}</span>
                                    <span *ngIf="classe.google_org_unit.not_found">Non trovata su Google
                                        Workspace</span>
                                </li>

                                <li *ngIf="!classe.google_org_unit">
                                    Nessuna Organizzazione Associata
                                </li>

                                <li class="py-0">
                                    <small class="card-subtitle">Creata il</small>
                                </li>

                                <li>
                                    {{classe.created_at | date:"medium" }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Impostazioni Avanzate -->
                    <div class="row form-group">
                        <div class="col-12">
                            <button class="btn btn-outline-primary" type="button" (click)="changeStatusCollapse()"
                                [attr.aria-expanded]="showAdvancedSettings" aria-controls="collapseAdvancedSettings">
                                Modifica impostazioni standard
                                <span class="ml-2" [ngSwitch]="showAdvancedSettings">
                                    <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                                    <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="row form-group" id="collapseAdvancedSettings" [collapse]="!showAdvancedSettings"
                        [isAnimated]="true">
                        <div class="col-12">
                            <!-- Studenti-->
                            <div class="row form-group">
                                <div class="col-12">
                                    <h4>Elimina Studenti</h4>

                                    <!-- Gestione Gruppo -->
                                    <label class="toggle-switch d-flex" for="deleteStudenti">
                                        <input id="deleteStudenti" name="deleteStudenti"
                                            [(ngModel)]="data.deleteStudenti" type="checkbox"
                                            class="toggle-switch-input">
                                        <span class="toggle-switch-label">
                                            <span class="toggle-switch-indicator"></span>
                                        </span>
                                        <span class="toggle-switch-content">
                                            <span class="d-block">Eliminazione degli Studenti</span>
                                            <small class="d-block text-muted">Verranno eliminati tutti gli Studenti
                                                nella Classe</small>
                                        </span>
                                    </label>
                                </div>
                            </div>

                            <div *ngIf="!data.deleteStudenti" class="row form-group">
                                <div class="col-12">
                                    <label class="input-label" for="organigrammaStudenti">Sposta Studenti in un
                                        Organigramma
                                        specifico</label>

                                    <app-custom-dropdown-treeview [items]="organigrammiTreeView"
                                        [(value)]="data.organigrammaStudenti.id"
                                        (valueChange)="onSelectedRelationInfo($event, 'organigrammaStudenti')"
                                        [configs]="configTreeView">
                                    </app-custom-dropdown-treeview>

                                    <input type="hidden" id="organigrammaStudenti" name="organigrammaStudenti"
                                        [(ngModel)]="data.organigrammaStudenti" required>

                                    <span class="invalid-feedback" *ngIf="data.organigrammaStudenti.id">Non
                                        valido</span>
                                    <span class="valid-feedback" *ngIf="!data.organigrammaStudenti.id">Valido</span>
                                    <span class="text-muted font-size-sm">
                                        Scegli l'Organigramma in cui spostare gli Studenti
                                    </span>
                                </div>
                            </div>

                            <ng-container *ngIf="orgUnitClasseId">
                                <!-- Unità organizzative da Cancellare se Effettivamente esiste orgUnitclasseId-->
                                <div class="row form-group">
                                    <div class="col-12">
                                        <h4>Unità organizzative</h4>

                                        <!-- Gestione Gruppo -->
                                        <label class="toggle-switch d-flex" for="deleteOrgUnit">
                                            <input id="deleteOrgUnit" [(ngModel)]="data.deleteOrgUnit"
                                                name="deleteOrgUnit" type="checkbox" class="toggle-switch-input">
                                            <span class="toggle-switch-label">
                                                <span class="toggle-switch-indicator"></span>
                                            </span>
                                            <span class="toggle-switch-content">
                                                <span class="d-block">Eliminazione delle Unità organizzative</span>
                                                <small class="d-block text-muted">Verranno eliminate le Unità
                                                    organizzative del
                                                    classe e delle relative Classi</small>
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <!-- Elimina Utenti -->
                                <div class="row form-group"  *ngIf="data.deleteOrgUnit">
                                    <div class="col-12">
                                        <h4>Elimina Utenti</h4>

                                        <!-- Gestione Eliminazione -->
                                        <label class="toggle-switch d-flex" for="deleteUser">
                                            <input id="deleteUser" name="deleteUser" [(ngModel)]="data.deleteUser"
                                                type="checkbox" class="toggle-switch-input">
                                            <span class="toggle-switch-label">
                                                <span class="toggle-switch-indicator"></span>
                                            </span>
                                            <span class="toggle-switch-content">
                                                <span class="d-block">Eliminazione degli Utenti</span>
                                                <small class="d-block text-muted">Verranno eliminati gli Utenti nelle
                                                    Unità
                                                    organizzative che non sono Docenti <span
                                                        *ngIf="!data.deleteStudenti">e/o
                                                        Studenti</span></small>
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <!-- Associare Organigramma Esistente a Utenti Generici -->
                                <div *ngIf="!data.deleteUser && data.deleteOrgUnit" class="row form-group">
                                    <div class="col-12">
                                        <label class="input-label" for="organigrammaUser">Sposta Utenti in un
                                            Organigramma
                                            specifico</label>

                                        <app-custom-dropdown-treeview [items]="organigrammiTreeView"
                                            [(value)]="data.organigrammaUser.id"
                                            (valueChange)="onSelectedRelationInfo($event, 'organigrammaUser')"
                                            [configs]="configTreeView">
                                        </app-custom-dropdown-treeview>

                                        <input type="hidden" id="organigrammaUser" name="organigrammaUser"
                                            [(ngModel)]="data.organigrammaUser" required>

                                        <span class="invalid-feedback" *ngIf="data.organigrammaUser.id">Non
                                            valido</span>
                                        <span class="valid-feedback" *ngIf="!data.organigrammaUser.id">Valido</span>
                                        <span class="text-muted font-size-sm">
                                            Scegli l'Organigramma in cui spostare gli Utenti
                                        </span>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Gruppi Studenti-->
                            <div class="row form-group">
                                <div class="col-12">
                                    <h4>

                                        Gruppi Studenti
                                    </h4>

                                    <!-- Gestione Gruppo -->
                                    <label class="toggle-switch d-flex" for="deleteGroupStudenti">
                                        <input id="deleteGroupStudenti" [ngModel]="false" name="deleteGroupStudenti"
                                            #deleteGroupStudenti="ngModel" type="checkbox" class="toggle-switch-input">
                                        <span class="toggle-switch-label">
                                            <span class="toggle-switch-indicator"></span>
                                        </span>
                                        <span class="toggle-switch-content">
                                            <span class="d-block">Eliminazione dei Gruppi Studenti</span>
                                            <small class="d-block text-muted">Verranno eliminati i gruppi della Classe
                                            </small>
                                        </span>
                                    </label>
                                </div>
                            </div>


                            <!-- Gruppi Docenti-->
                            <div class="row form-group">
                                <div class="col-12">
                                    <h4>
                                        Gruppi Docenti
                                    </h4>

                                    <!-- Gestione Gruppo -->
                                    <label class="toggle-switch d-flex" for="deleteGroupDocenti">
                                        <input id="deleteGroupDocenti" [ngModel]="false" name="deleteGroupDocenti"
                                            #deleteGroupDocenti="ngModel" type="checkbox" class="toggle-switch-input">
                                        <span class="toggle-switch-label">
                                            <span class="toggle-switch-indicator"></span>
                                        </span>
                                        <span class="toggle-switch-content">
                                            <span class="d-block">Eliminazione dei Gruppi Docenti</span>
                                            <small class="d-block text-muted">Verranno eliminati i gruppi della Classe
                                            </small>
                                        </span>
                                    </label>

                                </div>
                            </div>

                            <!-- Gruppi Plessi Classe -->
                            <div class="row form-group">
                                <div class="col-12">
                                    <h4>
                                        Gruppi classe e Classi
                                    </h4>
                                    <!-- Gestione Gruppo -->
                                    <label class="toggle-switch d-flex" for="deleteGroup">
                                        <input id="deleteGroup" [ngModel]="false" name="deleteGroup"
                                            #deleteGroup="ngModel" type="checkbox" class="toggle-switch-input">
                                        <span class="toggle-switch-label">
                                            <span class="toggle-switch-indicator"></span>
                                        </span>
                                        <span class="toggle-switch-content">
                                            <span class="d-block">Eliminazione dei Gruppi classe e Classi</span>
                                            <small class="d-block text-muted">Verranno eliminati i gruppi della Classe
                                            </small>
                                        </span>
                                    </label>
                                </div>
                            </div>

                            <!-- Gruppi Associati a Plessi Classe -->
                            <div class="row form-group">
                                <div class="col-12">
                                    <h4>

                                        Altri Gruppi Associati a classe e Classi
                                    </h4>

                                    <!-- Gestione Gruppo -->
                                    <label class="toggle-switch d-flex" for="deleteGroupAssociate">
                                        <input id="deleteGroupAssociate" [ngModel]="false" name="deleteGroupAssociate"
                                            #deleteGroupAssociate="ngModel" type="checkbox" class="toggle-switch-input">
                                        <span class="toggle-switch-label">
                                            <span class="toggle-switch-indicator"></span>
                                        </span>
                                        <span class="toggle-switch-content">
                                            <span class="d-block">Eliminazione degli altri Gruppi classe e Classi</span>
                                            <small class="d-block text-muted">Verranno eliminati gli altri gruppi
                                                associati alla Classe</small>
                                        </span>
                                    </label>
                                </div>
                            </div>

                            <!-- Classroom Associate a Plessi Classe -->
                            <div class="row form-group">
                                <div class="col-12">
                                    <h4>

                                        Classroom Associati a classe e Classi
                                    </h4>

                                    <!-- Gestione Gruppo -->
                                    <label class="toggle-switch d-flex" for="deleteClassroom">
                                        <input id="deleteClassroom" [ngModel]="false" name="deleteClassroom"
                                            #deleteClassroom="ngModel" type="checkbox" class="toggle-switch-input">
                                        <span class="toggle-switch-label">
                                            <span class="toggle-switch-indicator"></span>
                                        </span>
                                        <span class="toggle-switch-content">
                                            <span class="d-block">Eliminazione delle Classroom classe e Classi</span>
                                            <small class="d-block text-muted">Verranno eliminati le classroom associate
                                                alla Classe</small>
                                        </span>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <!-- Right -->
                <div class="col-5 p-3 m-3 bg-light rounded-lg">
                    <!-- Titlte -->
                    <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Sincronizzazione con Google Workspace</h4>


                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-soft-danger" role="alert">
                                La Classe <b class="text-cap">{{classe.name}}</b> verrà eliminata <b>definitivamente</b>
                            </div>

                            <div class="alert alert-soft-danger" role="alert" *ngIf="data.deleteStudenti">
                                Gli <b>Studenti</b> al suo interno verranno
                                cancellati
                            </div>
                            <div class="alert alert-soft-danger" role="alert" *ngIf="!data.deleteStudenti">
                                Gli <b>Studenti</b> verranno spostati dentro l'Organigramma
                                <b class="text-cap">{{data.organigrammaStudenti.name}}</b>
                            </div>

                            <div class="alert alert-soft-danger" role="alert" *ngIf="data.deleteOrgUnit">
                                <small>Unità organizzative che verranno eliminate</small>
                                <div style="overflow-y: auto; max-height: 250px;">
                                    <ng-container
                                        *ngTemplateOutlet="recursiveTreeTmpl; context:{ list: orgUnitsTreeView }">
                                    </ng-container>
                                </div>

                                <div>
                                    <span *ngIf="data.deleteUser">Gli <b>Utenti</b> al suo interno verranno
                                        cancellati</span>
                                    <span *ngIf="!data.deleteUser && data.organigrammaUser.id">
                                        Gli <b>Utenti</b> verranno spostati dentro l'Organigramma
                                        <b class="text-cap">{{data.organigrammaUser.name}}</b>
                                    </span>
                                </div>
                            </div>

                            <div class="alert alert-soft-danger" role="alert"
                                *ngIf="deleteGroupStudenti.value && classe.google_group && classe.google_group.length > 0 && filterGroupsByType(classe.google_group, 'studenti').length > 0">
                                <small>Gruppi <b>Studenti</b> che verranno eliminati</small>
                                <div style="overflow-y: auto; max-height: 250px;">
                                    <ng-container
                                        *ngTemplateOutlet="groupDeletePreview; context:{ info: classe, groups: filterGroupsByType(classe.google_group, 'studenti') }">
                                    </ng-container>

                                    <ng-container *ngIf="classe.classi && classe.classi.length > 0">
                                        <ng-container *ngFor="let classe of classe.classi">
                                            <div
                                                *ngIf="classe.google_group && classe.google_group.length > 0  && filterGroupsByType(classe.google_group, 'studenti').length > 0; then thenClasseBlock">
                                            </div>
                                            <ng-template #thenClasseBlock>
                                                <ng-container
                                                    *ngTemplateOutlet="groupDeletePreview; context:{ info: classe, groups: filterGroupsByType(classe.google_group, 'studenti') }">
                                                </ng-container>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="alert alert-soft-danger" role="alert"
                                *ngIf="deleteGroupDocenti.value && classe.google_group && classe.google_group.length > 0 && filterGroupsByType(classe.google_group, 'docenti').length > 0">
                                <small>Gruppi <b>Docenti</b> che verranno eliminati</small>
                                <div style="overflow-y: auto; max-height: 250px;">
                                    <ng-container
                                        *ngTemplateOutlet="groupDeletePreview; context:{ info: classe, groups: filterGroupsByType(classe.google_group, 'docenti') }">
                                    </ng-container>

                                    <ng-container *ngIf="classe.classi && classe.classi.length > 0">
                                        <ng-container *ngFor="let classe of classe.classi">
                                            <div
                                                *ngIf="classe.google_group && classe.google_group.length > 0  && filterGroupsByType(classe.google_group, 'docenti').length > 0; then thenClasseBlock">
                                            </div>
                                            <ng-template #thenClasseBlock>
                                                <ng-container
                                                    *ngTemplateOutlet="groupDeletePreview; context:{ info: classe, groups: filterGroupsByType(classe.google_group, 'docenti') }">
                                                </ng-container>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="alert alert-soft-danger" role="alert"
                                *ngIf="deleteGroup.value && classe.google_group && classe.google_group.length > 0 && filterGroupsByType(classe.google_group, 'classe').length > 0">
                                <small>Gruppi <b>Stuttura</b> che verranno eliminati</small>
                                <div style="overflow-y: auto; max-height: 250px;">
                                    <ng-container
                                        *ngTemplateOutlet="groupDeletePreview; context:{ info: classe, groups: filterGroupsByType(classe.google_group, 'classe') }">
                                    </ng-container>

                                    <ng-container *ngIf="classe.classi && classe.classi.length > 0">
                                        <ng-container *ngFor="let classe of classe.classi">
                                            <div
                                                *ngIf="classe.google_group && classe.google_group.length > 0  && filterGroupsByType(classe.google_group, 'classe').length > 0; then thenClasseBlock">
                                            </div>
                                            <ng-template #thenClasseBlock>
                                                <ng-container
                                                    *ngTemplateOutlet="groupDeletePreview; context:{ info: classe, groups: filterGroupsByType(classe.google_group, 'classe') }">
                                                </ng-container>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="alert alert-soft-danger" role="alert"
                                *ngIf="deleteGroupAssociate.value && classe.google_group && classe.google_group.length > 0 && filterGroupsByType(classe.google_group, null).length > 0">
                                <small>Vari Gruppi <b>Associati</b> che verranno eliminati</small>
                                <div style="overflow-y: auto; max-height: 250px;">
                                    <ng-container
                                        *ngTemplateOutlet="groupDeletePreview; context:{ info: classe, groups: filterGroupsByType(classe.google_group, null) }">
                                    </ng-container>

                                    <ng-container *ngIf="classe.classi && classe.classi.length > 0">
                                        <ng-container *ngFor="let classe of classe.classi">
                                            <div
                                                *ngIf="classe.google_group && classe.google_group.length > 0  && filterGroupsByType(classe.google_group, null).length > 0; then thenClasseBlock">
                                            </div>
                                            <ng-template #thenClasseBlock>
                                                <ng-container
                                                    *ngTemplateOutlet="groupDeletePreview; context:{ info: classe, groups: filterGroupsByType(classe.google_group, null) }">
                                                </ng-container>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="alert alert-soft-danger" role="alert"
                                *ngIf="deleteClassroom.value && classe.google_classroom && classe.google_classroom.length > 0">
                                <small>Classroom che verranno eliminate</small>
                                <div style="overflow-y: auto; max-height: 250px;">
                                    <ng-container
                                        *ngTemplateOutlet="groupDeletePreview; context:{ info: classe, groups: classe.google_classroom }">
                                    </ng-container>

                                    <ng-container *ngIf="classe.classi && classe.classi.length > 0">
                                        <ng-container *ngFor="let classe of classe.classi">
                                            <div
                                                *ngIf="classe.google_classroom && classe.google_classroom.length > 0; then thenClasseBlock">
                                            </div>
                                            <ng-template #thenClasseBlock>
                                                <ng-container
                                                    *ngTemplateOutlet="groupDeletePreview; context:{ info: classe, groups: classe.google_classroom }">
                                                </ng-container>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-footer">
            <div class="row justify-content-between align-items-center">
                <div class="col-auto py-1">
                    <button [routerLink]="['/plesso/'+ id_plesso + '/classe/' + id_classe]" type="button"
                        class="btn btn-white">
                        <i class="far fa-chevron-left mr-1"></i> Annulla
                    </button>
                </div>
                <div class="col-6 py-1">
                    <div class="row justify-content-end">
                        <div class="col-auto">
                            <input autocomplete="off" type="text" id="confirmation_delete" name="confirmation_delete"
                                #confirmation_delete="ngModel" [ngModel]="''" class="form-control"
                                [pattern]="regEx" required>
                            <small class="d-block text-muted">Per eliminare, digita <span
                                    class="confirmation-phrase">Elimina</span></small>
                        </div>
                        <div class="col-auto">
                            <button type="submit" class="btn"
                                [disabled]="!myForm.valid"
                                [ngClass]="(myForm.dirty || myForm.touched)? 'btn-danger' : 'btn-outline-danger' ">
                                Elimina <i class="far fa-chevron-right ml-1"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>


<!-- Template Preview Delete Group -->
<ng-template #groupDeletePreview let-info="info" let-groups="groups">
    <details>
        <summary>
            <strong>{{info.name}} <span class="badge badge-soft-secondary">{{groups.length}}</span> </strong>
        </summary>

        <ul class="list-unstyled pl-4 mb-2">
            <li *ngFor="let google_group of groups">
                <strong>{{google_group.email}}</strong>
                <span class="text-secondary" *ngIf="google_group.name"> -
                    {{google_group.name}}</span>
            </li>
        </ul>
    </details>
</ng-template>
<!-- /Template Preview Delete Group -->

<!-- Template Preview OrgUnit -->
<ng-template #recursiveTreeTmpl let-list="list">
    <ng-container *ngFor="let item of list">

        <li *ngIf="!item.children || item.children.length == 0">
            <strong>{{item.data.name}}</strong>
        </li>

        <ng-container *ngIf="item.children && item.children.length > 0">
            <details>
                <summary>
                    <strong>{{item.data.name}}</strong>
                </summary>

                <ul class="list-unstyled pl-4">
                    <li *ngFor="let children of item.children">
                        <ng-container *ngTemplateOutlet="recursiveTreeTmpl; context:{ list: [children] }">
                        </ng-container>
                    </li>
                </ul>
            </details>
        </ng-container>

    </ng-container>
</ng-template>
<!-- /Template Preview OrgUnit -->
