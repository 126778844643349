<app-card-loading-error *ngIf="loadingError" (retryClicked)="retryClicked()"></app-card-loading-error>

<!-- Card -->
<div class="card">
  <div class="card-body">
    <h2>Caricamento in corso...</h2>
    <p-progressBar [value]="loadingValue" [showValue]="false"  [style]="{'height': '20px'}"></p-progressBar>
  </div>
</div>
<!-- End Card -->
