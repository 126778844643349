import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { RelationInfo } from 'src/app/services/general/relation.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';

@Component({
  selector: 'app-plesso-profile-info-card',
  templateUrl: './plesso-profile-info-card.component.html',
  styleUrls: ['./plesso-profile-info-card.component.scss']
})
export class PlessoProfileInfoCardComponent implements OnInit {

  @Input() id: any;
  plesso: any;
  error: any;

  groupPlesso: any;
  groupDocenti: any;
  groupStudenti: any;

  showInfo = false;

  // Loading Pagina
  loadingPlesso: boolean;
  private _plessoServiceSubscription: Subscription;

  constructor(private plessiService: PlessoService) { }

  ngOnInit() {

    this.unsubscibeAll();

    this.plesso = {};
    this.groupPlesso = null;
    this.groupDocenti= null;
    this.groupStudenti= null;

    this.error = null;

    this.loadingPlesso = false;

    this._plessoServiceSubscription = this.plessiService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError();
      else
        this.plessoServiceUpdated();
    });

    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  unsubscibeAll() {
    if (this._plessoServiceSubscription)
      this._plessoServiceSubscription.unsubscribe();
  }

  init() {
    if (this.id && this.id > 0) {
      this.plessoServiceUpdated();
    }
    else {
      this.backendError()
    }
  }

  plessoServiceUpdated() {
    this.loadingPlesso = true;
    this.getBackendData();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  getBackendData() {
    this.plessiService.get(this.id, (result) => {
      this.plesso = result;

      if (this.plesso.google_group && this.plesso.google_group.length > 0) {
        this.groupPlesso = this.plesso.google_group.find(x => x.relation_info == RelationInfo.RelationInfoPlesso);
        this.groupDocenti = this.plesso.google_group.find(x => x.relation_info == RelationInfo.RelationInfoDocenti);
        this.groupStudenti = this.plesso.google_group.find(x => x.relation_info == RelationInfo.RelationInfoStudenti);
      }

      this.loadingPlesso = false;
    }, (error) => {
      this.loadingPlesso = true;
      this.plessiService.all();
    });
  }

  backendError() {
    this.error = "backend";
    this.loadingPlesso = false;
  }
}
