import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { forbiddenGoogleValidator } from './google.validator';

@Directive({
  selector: '[forbiddenGoogle]',
  providers: [{provide: NG_VALIDATORS, useExisting: GoogleCheckValidatorDirective, multi: true}]
})
export class GoogleCheckValidatorDirective implements Validator {
  @Input('forbiddenGoogle') arrValue: string[];

  validate(control: AbstractControl): {[key: string]: any} | null {
    return this.arrValue ? forbiddenGoogleValidator(this.arrValue.map(function(x){ 
      return x.toLowerCase().trim(); 
    }))(control): null;
  }
}