import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { GoogleClassroomService } from 'src/app/services/google/google-classroom.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { SuspendClassroomService } from 'src/app/services/operations/classroom/suspend-classroom.service';

@Component({
  selector: 'app-suspend-classroom',
  templateUrl: './suspend-classroom.component.html',
  styleUrls: ['./suspend-classroom.component.scss']
})
export class SuspendClassroomComponent implements OnInit {
  regEx = ConfigService.regExSuspend;

  formLoading: boolean = false;

  error: any;
  _loadingData = {
    item: false
  };

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  data: any = [];
  item: any;
  model: any;

  operationInProgress: boolean = false;

  private _suspendClassroomServiceSubscription: Subscription;
  private _googleClassroomServiceSubscription: Subscription;

  constructor(
    private suspendClassroomService: SuspendClassroomService,
    private googleClassroomService: GoogleClassroomService,
    private googleDirectoryService: GoogleDirectoryService,
  ) { }

  ngOnInit() {
    this.unsubscibeAll();
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.item = this.suspendClassroomService.items;
    this.data = this.parseClassroomResult(this.item);
  }

  parseClassroomResult(result) {

    let table = [];

    result.forEach(element => {

      let proprietario = []

      let sotto_nome = (element.section ? element.section : "") + " " + (element.room ? "(" + element.room + ")" : "");

      if (element.ownerId) {
        let googleuser_proprietario = this.googleDirectoryService.getUser(element.ownerId);

        if (googleuser_proprietario) {
          proprietario = [
            googleuser_proprietario.name.familyName + " " + googleuser_proprietario.name.givenName,
            googleuser_proprietario.primaryEmail
          ]
        }
      }

      table.push(
        {
          "nome": [element.name, sotto_nome],
          "description": [element.descriptionHeading, element.description],
          "proprietario": proprietario,
          "_export_data": {
            name: element.name,
            section: element.section,
            descriptionHeading: element.descriptionHeading,
            description: element.description,
            room: element.room,
            proprietarioName: proprietario[0] ? proprietario[0] : "",
            proprietarioEmail: proprietario[1] ? proprietario[1] : "",
            ownerId: element.ownerId,
            link: element.alternateLink,
            courseGroupEmail: element.courseGroupEmail,
            teacherGroupEmail: element.teacherGroupEmail,
            creationTime: element.creationTime,
            updateTime: element.updateTime
          },
        });
    });

    return table;
  }

  deleteEvent(rows) {
    this.suspendClassroomService.deleteItemsAtIndex(rows);
  }

  onSubmit(myForm: NgForm) {
    if (myForm.valid) {
      this.formLoading = true;
      this.operationInProgress = true;

      this.suspendClassroomService.execOperation((error) => {
        this.formLoading = false;
        this.operationInProgress = false;
        if (!error) {
          this.ngOnInit();
        }
      });
    }
  }

  resetOperation() {
    this.suspendClassroomService.reset();
  }

  unsubscibeAll() {
    if (this._suspendClassroomServiceSubscription)
      this._suspendClassroomServiceSubscription.unsubscribe();

    if (this._googleClassroomServiceSubscription)
      this._googleClassroomServiceSubscription.unsubscribe();
  }

  init() {
    this._suspendClassroomServiceSubscription = this.suspendClassroomService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.dataUpdated();
      }
    });

    this._googleClassroomServiceSubscription = this.googleClassroomService.getSubjectToUpdateCoursesObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.googleClassroomService.coursesList();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }
}
