<div class="card mb-3 alert-danger" *ngIf="activity.failed_jobs > 0">
  <div class="card-header">
    <h2 class="card-header-title text-danger" [innerHTML]="'STR.errore_google_generico_header' | translate"></h2>
  </div>
  <div class="card-body" [innerHTML]="'STR.errore_google_generico_body' | translate"></div>
</div>

<div class="card mb-3 card-hover-shadow">
  <div class="card-body">
    <h5 class="mb-1">
      <a class="text-dark">{{ 'ACTIVITY.' + activity.job_batch_name + '.description' | translate }}</a>
    </h5>

    <p class="font-size-sm">
      <span *ngIf="activity.status == 'progress'" class="badge badge-soft-primary badge-pill"><span
          class="legend-indicator bg-primary"></span>{{'ACTIVITY.sidebar.status.progress.badge' | translate}}</span>
      <span *ngIf="activity.status == 'cancelled'" class="badge badge-soft-danger badge-pill"><span
          class="legend-indicator bg-danger"></span>{{'ACTIVITY.sidebar.status.cancelled.badge' | translate}}</span>
      <span *ngIf="activity.status == 'completed'" class="badge badge-soft-success badge-pill"><span
          class="legend-indicator bg-success"></span>{{'ACTIVITY.sidebar.status.completed.badge' | translate}}</span>
    </p>

    <p class="font-size-sm"><i class="fas fa-user mr-2"></i>{{ activity.user_name }}</p>
    <p class="font-size-sm"><i class="fas fa-fingerprint mr-2"></i>{{ activity.batch_id }}</p>

    <ul class="list-group">
      <!-- List Item -->
      <li class="list-group-item list-group-item-light">
        <div class="row gx-1">
          <div class="col">
            <div class="d-flex align-items-center">
              <span class="font-size-sm mr-2">{{ activity.perc_overall }}%</span>
              <!-- Progress -->
              <div class="progress table-progress">
                <div class="progress-bar" role="progressbar" [style.width.%]="activity.perc_completed"
                  aria-valuenow="activity.perc_completed" aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg-danger" role="progressbar" [style.width.%]="activity.perc_failed"
                  aria-valuenow="activity.perc_failed" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <div class="row mt-1 font-size-sm">
              <div class="col-auto">
                <span class="legend-indicator bg-primary"></span> {{ activity.job_completed }}/
                {{ activity.total_jobs }}
              </div>
              <div class="col-auto">
                <span class="legend-indicator bg-danger"></span> {{ activity.failed_jobs }} errori
              </div>
            </div>
          </div>
        </div>
        <div class="row gx-1 mt-3" *ngIf="activity.status == 'progress' && activity.last_update">
          <div class="col">
            <div class="media">
              <span class="mt-2 mr-2">
                <div class="spinner-grow spinner-grow-sm text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </span>
              <div class="media-body text-truncate">
                <span
                  class="d-block font-size-sm text-dark text-truncate">{{'ACTIVITY.sidebar.status.progress.last_update'
                  | translate}}</span>
                <small class="d-block text-muted">{{ activity.last_update | date:"medium" }}</small>
              </div>
            </div>
          </div>
        </div>

        <div class="row gx-1 mt-3" *ngIf="activity.status != 'progress' && activity.failed_jobs > 0">
          <div class="col">
            <div class="media">
              <span class="mt-2 mr-2">
                <span class="icon icon-xs icon-danger icon-circle">
                  <i class="tio-warning-outlined"></i>
                </span>
              </span>
              <div class="media-body text-truncate">
                <span
                  class="d-block font-size-sm text-dark text-truncate">{{'ACTIVITY.sidebar.status.progress.failed_jobs'
                  | translate}}</span>
                <small class="d-block text-muted">{{ activity.finished_at_parsed | date:"medium" }}</small>
              </div>
            </div>
          </div>
        </div>

        <div class="row gx-1 mt-3" *ngIf="activity.status == 'completed' && activity.failed_jobs == 0">
          <div class="col">
            <div class="media">
              <span class="mt-2 mr-2">
                <span class="icon icon-xs icon-success icon-circle">
                  <i class="tio-done"></i>
                </span>
              </span>
              <div class="media-body text-truncate" *ngIf="activity.finished_at">
                <span class="d-block font-size-sm text-dark text-truncate">{{'ACTIVITY.sidebar.status.completed.msg' |
                  translate}}</span>
                <small class="d-block text-muted">{{ activity.finished_at_parsed | date:"medium" }}</small>
              </div>
            </div>
          </div>
        </div>
      </li>
      <!-- End List Item -->
    </ul>
  </div>
</div>
<!-- End Card -->

<div class="card mb-3 alert-soft-danger" *ngIf="failed_jobs_reasons && failed_jobs_reasons.length > 0">
  <div class="card-header card-btn btn-block"  [ngClass]="{'collapsed': !allJobNotCollapsed}" (click)="toggleAllJopCollapsed()">
    <h2 class="card-header-title text-danger">
      <i class="fas fa-bug mr-2"></i>Errori principali
    </h2>

    <span class="card-btn-toggle" [attr.aria-expanded]="!allJobNotCollapsed">
      <span class="btn btn-xs btn-danger card-btn-toggle-default">Mostra tutti</span>
      <span class="btn btn-xs btn-danger card-btn-toggle-active">Nascondi tutti</span>
    </span>
  </div>
  <div class="card-body alert-light">
    <p class="mb-1" *ngFor="let reason of failed_jobs_reasons"><span class="badge bg-danger text-white mr-2">{{reason.counter}}</span>{{reason.text}}</p>
  </div>
</div>

<div class="card mb-3 alert-soft-danger" *ngFor="let job of failed_jobs">
  <div class="card-header card-btn btn-block" [ngClass]="{'collapsed': !job.notCollapsed}"
    (click)="job.notCollapsed = !job.notCollapsed">
    <h2 class="card-header-title text-danger">
      <i class="fas fa-bug mr-2"></i>Errore
      <p class="font-size-sm mt-2"><i class="fas fa-fingerprint mr-2"></i>batch {{ activity.batch_id }} / job
        (telescope) {{ job.payload?.telescope_uuid }}</p>
      <p class="font-size-sm mt-2">{{ job.payload?.displayName }}</p>
    </h2>

    <span class="card-btn-toggle" [attr.aria-expanded]="!job.notCollapsed">
      <span class="btn btn-xs btn-danger card-btn-toggle-default">Mostra</span>
      <span class="btn btn-xs btn-danger card-btn-toggle-active">Nascondi</span>
    </span>
  </div>
  <ng-container *ngIf="job.telescope_data">
    <div class="card-body alert-soft-info" [collapse]="!job.notCollapsed" [isAnimated]="true">
      <pre class="small"><code style="display: block;">{{job.telescope_data | json}}</code></pre>
    </div>
  </ng-container>
  <ng-container *ngIf="job.batch_activities_errors">
    <div *ngFor="let error of job.batch_activities_errors" class="card-body alert-soft-info"
      [collapse]="!job.notCollapsed" [isAnimated]="true">
      <p class="font-size-sm text-dark">
        Tentativo num. {{error.attempt}}<br>
        {{ error.job_class }}<br>
        {{ error.code }} {{ error.message }}
      </p>
      <pre class="small"><code style="display: block;">{{error.trace | json}}</code></pre>
    </div>
  </ng-container>
  <ng-container *ngIf="job.exception">
    <div class="card-body" [collapse]="!job.notCollapsed" [isAnimated]="true">
      <pre
        class="small"><ng-container *ngFor="let line of job.exception"><code style="display: block;">{{line}}</code></ng-container></pre>
    </div>
  </ng-container>
</div>