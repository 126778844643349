<app-card-loading *ngIf="loading">
</app-card-loading>


<app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">
  <div class="row">
    <div class="col-12">
      <app-card-operation-activity [filterActivity]="['operation.users.add-users-to-organigramma']"></app-card-operation-activity>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <input type="hidden" id="organigramma_id" formControlName="organigramma_id">
        <!-- Card -->
        <div class="card card-hover-shadow">
          <div class="card-body">

            <h3 class="card-title">Spostamento Utenti</h3>
            <p class="card-text">Questa operazione consente di spostare in maniera massiva tutti gli utenti
              selezionati nell'Organigramma selezionato</p>

            <!-- Seleziona Plesso -->
            <div class="row form-group">

              <div class="col-12">
                <label class="input-label" for="organigrammaStudenti">{{"STR.Seleziona_Organigramma" |
                  translate}}</label>

                <app-custom-dropdown-treeview [items]="organigrammiTreeView" [(value)]="model.organigramma_id"
                  (valueChange)="onSelectedOrganigramma($event)">
                </app-custom-dropdown-treeview>
              </div>

              <div class="col-12 mt-2">
                <label class="toggle-switch d-flex" for="aggiungi_gerarchia">
                  <input id="aggiungi_gerarchia" formControlName="aggiungi_gerarchia" [(ngModel)]="model.aggiungi_gerarchia" name="aggiungi_gerarchia" type="checkbox" class="toggle-switch-input">
                  <span class="toggle-switch-label">
                    <span class="toggle-switch-indicator"></span>
                  </span>
                  <span class="toggle-switch-content">
                    <span class="d-block">Aggiungi anche negli Organigrammi Padre</span>
                    <small class="d-block text-muted">Attiva questa opzione per aggiungere gli utenti anche negli orgniagrammi padre</small>
                  </span>
                </label>
              </div>

              <div class="col-12 mt-2">
                <label class="toggle-switch d-flex" for="sposta_in_orgunit">
                  <input id="sposta_in_orgunit" formControlName="sposta_in_orgunit" [(ngModel)]="model.sposta_in_orgunit" name="sposta_in_orgunit" type="checkbox" class="toggle-switch-input" [disabled]="disabledOrgUnitAdd">
                  <span class="toggle-switch-label">
                    <span class="toggle-switch-indicator"></span>
                  </span>
                  <span class="toggle-switch-content">
                    <span class="d-block">Sposta nella Unità Organizzativa</span>
                    <small class="d-block text-muted">Attiva questa opzione per aggiungere gli utenti dentro la Unità Organizzativa se associata all'Organigramma</small>
                  </span>
                </label>
              </div>

              <div class="col-12 mt-2">
                <label class="toggle-switch d-flex" for="remove_from_all_organigrammi">
                  <input id="remove_from_all_organigrammi" formControlName="remove_from_all_organigrammi" [(ngModel)]="model.remove_from_all_organigrammi" name="remove_from_all_organigrammi" type="checkbox" class="toggle-switch-input">
                  <span class="toggle-switch-label">
                    <span class="toggle-switch-indicator"></span>
                  </span>
                  <span class="toggle-switch-content">
                    <span class="d-block">Rimuovi utenti dagli altri organigrammi</span>
                    <small class="d-block text-muted">Attiva questa opzione per rimuovere gli utenti da tutti gli Organigrammi associati</small>
                  </span>
                </label>
              </div>

            </div>

            <div *ngIf="errorNoGoogleAccount" class="alert alert-warning mt-2 mb-0">
              <span class="text-cap d-inline">Attenzione</span> Sono stati selezionati degli utenti senza
              un
              account google associato, questi utenti non verranno spostati
            </div>

            <div class="card-footer">
              <div class="row justify-content-between align-items-center">
                <div class="col-auto py-1">
                  <button class="btn btn-outline-danger" (click)="resetOperation()"
                    [disabled]="users.length <= 0 || operationInProgress">
                    <i class="far fa-backspace mr-2"></i>
                    <span>{{'STR.reset_operation' | translate}}</span>
                  </button>
                </div>
                <div class="col-6 py-1">
                  <div class="row justify-content-end">
                    <button type="submit" class="btn"
                      [disabled]="!myForm.valid || users.length <= 0 || operationInProgress"
                      [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                      <span>Aggiungi nell'Organigramma</span>
                      <i class="far fa-chevron-right ml-1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- End Card -->
      </form>
    </div>
  </div>


  <div class="row">
    <div class="col-md-12 col-sm">
      <div class="card">
        <app-card-datatable-ng title="{{ 'STR.utenti_selezionati' | translate }}" [data]="data"
          [showDeleteButton]="true" labelDeleteButton="Rimuovi dall'operazione" iconDeleteButton="user-times"
          (deleteClickEvent)="deleteEvent($event)" [showExportButton]="true"></app-card-datatable-ng>
      </div>
    </div>
  </div>
</ng-container>
