<app-card-loading *ngIf="loading">
</app-card-loading>


<app-card-loading-error *ngIf="!loading && error" [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<ng-container *ngIf="!loading && !error">
  <div class="row">
    <div class="col-12">
      <app-card-operation-activity [filterActivity]="['operation.users.move-users']">
      </app-card-operation-activity>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <input type="hidden" id="plesso" formControlName="plesso">
        <input type="hidden" id="classe" formControlName="classe">
        <!-- Card -->
        <div class="card card-hover-shadow">
          <div class="card-body">

            <h3 class="card-title">Aggiungi o sposta utenti</h3>
            <p class="card-text">Questa operazione consente di spostare o aggiungere in maniera massiva tutti gli utenti
              selezionato nella Classe selezionata</p>

            <div class="alert alert-soft-info">
              <span class="text-cap d-inline">Attenzione</span> i docenti verranno <b>AGGIUNTI</b> nella classe selezionata, mentre gli studenti verranno <b>SPOSTATI</b>
            </div>

            <!-- Seleziona Plesso -->
            <div class="row form-group">

              <div class="col">
                <label class="input-label" for="select-plessoSelected">{{"STR.Seleziona_Plesso" |
                  translate}}</label>
                <app-select (valueChange)="plessoOnSelect($event)" [id]="'select-plessoSelected'" [showClear]="true"
                  [filterBy]="'name'" [placeholder]="'STR.Seleziona_Plesso'" [data]="plessi" [showValue]="'name'"
                  [(value)]="model.plesso" [optionAttributeShow]="'name'">
                </app-select>
              </div>
              <div class="col"
                *ngIf="model.plesso && (model.plesso?.classi || !model.plesso?.classi) && model.plesso?.classi.length > 0">
                <label class="input-label" for="select-classi-plesso">{{"STR.Seleziona_Classe" |
                  translate}}</label>
                <app-select (valueChange)="classiPlessoOnSelect($event)" [id]="'select-classi-plesso'"
                  [showClear]="true" [filterBy]="'name'" [placeholder]="'STR.Seleziona_Classe'" [multiple]="false"
                  [data]="model.plesso?.classi" [showValue]="'name'" [optionAttributeShow]="'name'"
                  [(value)]="model.classe">
                </app-select>
              </div>
            </div>
            <div class="alert alert-soft-warning media" role="alert"
              *ngIf="(model.plesso?.classi || !model.plesso?.classi) && model.plesso?.classi.length == 0">
              <i class="tio-warning mt-1 mr-1"></i>
              <div class="media-body" role="alert">
                Seleziona un plesso che abbia delle Classi Associate
              </div>
            </div>

            <div *ngIf="errorNoGoogleAccount" class="alert alert-warning mt-2 mb-0">
              <span class="text-cap d-inline">Attenzione</span> Sono stati selezionati degli utenti senza
              un
              account google associato, questi utenti non verranno spostati
            </div>

            <div *ngIf="roleNotSupported" class="alert alert-warning mt-2 mb-0">
              <span class="text-cap d-inline">Attenzione</span> ci sono utenti con ruolo diverso da studente e docente,
              verranno ignorati
            </div>

            <div class="card-footer">
              <div class="row justify-content-between align-items-center">
                <div class="col-auto py-1">
                  <button class="btn btn-outline-danger" (click)="resetOperation()"
                    [disabled]="users.length <= 0 || operationInProgress">
                    <i class="far fa-backspace mr-2"></i>
                    <span>{{'STR.reset_operation' | translate}}</span>
                  </button>
                </div>
                <div class="col-6 py-1">
                  <div class="row justify-content-end">
                    <button type="submit" class="btn"
                      [disabled]="!myForm.valid || users.length <= 0 || operationInProgress"
                      [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
                      <span>Avvia spostamento</span>
                      <i class="far fa-chevron-right ml-1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- End Card -->
      </form>
    </div>
  </div>


  <div class="row">
    <div class="col-md-12 col-sm">
      <div class="card">
        <app-card-datatable-ng title="{{ 'STR.utenti_selezionati' | translate }}" [data]="data"
          [showDeleteButton]="true" labelDeleteButton="Rimuovi dall'operazione" iconDeleteButton="user-times"
          (deleteClickEvent)="deleteEvent($event)" [showExportButton]="true"></app-card-datatable-ng>
      </div>
    </div>
  </div>
</ng-container>
