import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BackendService } from '../backend.service';
import { ToastService } from '../toast.service';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class SuspendUsersService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  items: any[];
  lastItems: any[];

  constructor(private backend: BackendService, private toast: ToastService, private utilityService: UtilityService) {
    this.reset();
  }


  reset() {
    this.items = [];
    this._subjectToUpdate.next(this.items);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  addUsers(newItems) {
    newItems.forEach(item => {
      let itemFound = this.items.find((element) => element.id == item.id);

      if (!itemFound) {
        this.items.push(item);
      }
    });

    this._subjectToUpdate.next(this.items);
  }

  deleteItemsAtIndex(indexArray) {

    indexArray.forEach(idx => {
      this.items[idx] = null;
    });

    this.items = this.items.filter((el) => { return el != null });

    this._subjectToUpdate.next(this.items);
  }

  execOperation(callback = (error) => { }) {
    let users = [];

    this.items.forEach(user => {
      if(!user.isAdmin){
        users.push({
          id: user.id,
          givenName: user.name?.givenName,
          familyName: user.name?.familyName,
          email: user.primaryEmail
        });
      }     
    });

    this.backend.post("op/suspend-users", { users: users }).subscribe((result) => {

      this.reset();

      this.toast.success('Riattiva Utenti avviato');

      callback(null);
    }, (error) => {
      this.backend.showErrors(error);
      callback(error);
    })
  }
}
