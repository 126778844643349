import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleClassroomService } from 'src/app/services/google/google-classroom.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { RemoveMembersFromClassroomsService } from 'src/app/services/operations/classroom/remove-members-from-classrooms.service';
import { SyncdataService } from 'src/app/services/syncdata.service';

@Component({
  selector: 'app-remove-members-from-classrooms',
  templateUrl: './remove-members-from-classrooms.component.html',
  styleUrls: ['./remove-members-from-classrooms.component.scss']
})
export class RemoveMembersFromClassroomsComponent implements OnInit {
  regEx = ConfigService.regExRemove;
  error: any;
  _loadingData = {
    classrooms: false,
    users: false
  };

  formLoading: boolean = false;

  get loading(): boolean {
    return Object.values(this._loadingData).some(x => x == true);
  }

  data: any = [];
  members: any = [];
  model: any;

  operationInProgress: boolean = false;
  errorNoGoogleAccount: boolean = false;


  private _removeMembersFromClassroomsSubscription: Subscription;
  private _userServiceSubscription: Subscription;
  private _googleUserServiceSubscription: Subscription;
  private _googleClassroomServiceSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private removeMembersFromClassroomsService: RemoveMembersFromClassroomsService,
    private userService: UserService,
    private googleService: GoogleDirectoryService,
    private googleClassroomService: GoogleClassroomService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  dataUpdated() {
    this.members = this.removeMembersFromClassroomsService.items;
    this.data = this.parseMembersResult(this.members);
  }

  parseUsersResult(result) {
    let table = [];
    if (result && result.length > 0) {
      result.forEach(element => {
        let user = this.getInfoUser(element);

        let classroom = this.getInfoClassroom({ id: element.courseId })

        table.push({
          "nome": [user.fullname, user.email],
          "classroom": [classroom.name],
          "ruolo": [this.translate.instant('STR.' + element.ruolo)],
          "_export_data": {
            name: user.fullname,
            email: user.email
          },
        });

      });
    }

    this._loadingData.users = false;

    return table;
  }

  parseMembersResult(result) {
    let memberUserData = this.parseUsersResult(result);

    let table = memberUserData;

    return table;
  }

  deleteEvent(rows) {
    this.removeMembersFromClassroomsService.deleteItemsAtIndex(rows);
  }

  onSubmit() {
    this.formLoading = true;

    this.operationInProgress = true;

    this.removeMembersFromClassroomsService.execOperation(this.members, (error) => {
      this.formLoading = false;
      if (!error) {
        this.resetOperation();
        this.router.navigate(['/classroom']);
      }
    });
  }

  resetOperation() {
    this.removeMembersFromClassroomsService.reset();
  }

  unsubscibeAll() {
    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();
    if (this._googleClassroomServiceSubscription)
      this._googleClassroomServiceSubscription.unsubscribe();
    if (this._googleUserServiceSubscription)
      this._googleUserServiceSubscription.unsubscribe();
    if (this._removeMembersFromClassroomsSubscription)
      this._removeMembersFromClassroomsSubscription.unsubscribe();
  }

  init() {
    this._removeMembersFromClassroomsSubscription = this.removeMembersFromClassroomsService.getSubjectToUpdateObservable().subscribe((data) => {
      if (data) {
        this.userService.all();
      }
    });

    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result) {
        this.googleClassroomService.coursesList();
      }
    });

    this._googleUserServiceSubscription = this.googleService.getSubjectToUpdateUsersObservable().subscribe((result) => {
      if (result) {
        this.userService.all();
      }
    });

    this._googleClassroomServiceSubscription = this.googleClassroomService.getSubjectToUpdateCoursesObservable().subscribe((result) => {
      if (result) {
        this.dataUpdated();
      }
    });

    this.googleService.usersList();
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  selectTab($event) {
  }

  getInfoUser(userInfo) {
    let resUser = {
      userId: userInfo?.userId,
      googleId: null,
      fullname: null,
      email: userInfo?.profile.emailAddress,
      is_google_user: false,
      is_geniusuite_user: false,
      role:null,
      status: null,
      type: null
    };

    let googleUser = this.googleService.getUserByEmail(resUser.email);

    // Controllo più dettagliato prima che l'email dell'utente sia stata modificata
    let user = googleUser ? this.userService.getByGoogleId(googleUser.id) : this.userService.getByEmail(resUser.email);

    if (user) {
      // Sincronizzato con Geniusuite
      resUser.userId = user.user.id;
      resUser.fullname = user.user.familyName + " " + user.user.givenName;
      resUser.email = user.user.email;
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = true;

      if (user.google_user) {
        resUser.googleId = user.google_user.id;

        if (user.google_user.name) {
          resUser.fullname = user.google_user.name?.familyName + " " + user.google_user.name?.givenName;
        }

        resUser.email = user.google_user.primaryEmail
        resUser.is_google_user = true;
      }
    } else if (googleUser) {
      // Non sincronizzato con Geniusuite ma non ha già i dati caricati
      resUser.fullname = "-";
      resUser.googleId = googleUser.id;

      if (googleUser.name) {
        resUser.fullname = googleUser.name?.familyName + " " + googleUser.name?.givenName;
      }

      resUser.email = googleUser.primaryEmail;
      resUser.is_google_user = true;
      resUser.is_geniusuite_user = false;
    } else {
      resUser.fullname = "Utente Esterno";
      resUser.is_google_user = false;
      resUser.is_geniusuite_user = false;
    }

    return resUser;
  }

  getInfoClassroom(info) {
    let resCls = {
      id: info?.id,
      name: null,
      description: null
    };

    let classroom = this.googleClassroomService.getCourse(resCls.id);

    if (classroom) {
      // Non sincronizzato con Geniusuite ma non ha già i dati caricati
      resCls.id = classroom.id ? classroom.id : null;
      resCls.name = classroom.name ? classroom.name : "-";
      resCls.description = classroom.description ? classroom.description : "-";
    }

    return resCls;
  }
}
