<ng-template #notification let-title="title" let-content="content">
  <div class="toast card-border-left border-{{currentMessage.color_class}}" style="opacity: 1;" role="alert">
    <div class="toast-header">
      <h5 class="mb-0" [innerHtml]="title | translate"></h5>
      <small class="ml-auto"></small>
      <i *ngIf="currentMessage.type == 'success'" class="tio-checkmark-circle-outlined tio-lg text-{{currentMessage.color_class}}"></i>
      <i *ngIf="currentMessage.type == 'error'" class="tio-clear-circle-outlined tio-lg text-{{currentMessage.color_class}}"></i>
      <i *ngIf="currentMessage.type == 'alert'" class="tio-alarm tio-lg text-{{currentMessage.color_class}}"></i>
      <i *ngIf="currentMessage.type == 'warn'" class="tio-warning-outlined tio-lg text-{{currentMessage.color_class}}"></i>
      <i *ngIf="currentMessage.type == 'info'" class="tio-info-outined tio-lg text-{{currentMessage.color_class}}"></i>
    </div>
    <div *ngIf="content" class="toast-body text-dark alert-soft-{{currentMessage.color_class}}" [innerHtml]="content | translate"></div>
  </div>
</ng-template>

<simple-notifications [options]="notificationOptions"></simple-notifications>
