<div class="row">

  <div class="col-md-12 col-sm mb-3">
    <app-card-loading *ngIf="isLoading.plessi">
    </app-card-loading>
    <div class="card card-hover-shadow" *ngIf="!(isLoading.plessi)">
      <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">

        <div class="row form-group">
          <div class="col-12">
            <h3><i class="fas fa-analytics mr-2"></i>Statistiche di Google Classroom</h3>
            <p>Attraverso questi campi puoi filtrare le statistiche scegliendo un intervallo temporale diverso, oppure
              visualizzare solo un plesso e una specifica classe.</p>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-4">
            <label class="input-label" for="range">Scegli un intervallo di date</label>
            <p-calendar [(ngModel)]="rangeDates" dateFormat="dd/mm/yy" selectionMode="range" [readonlyInput]="true"
              inputId="range" inputStyleClass="js-daterangepicker form-control daterangepicker-custom-input w-100"
              styleClass="w-100" [maxDate]="maxDate" (onClose)="dateRangeOnSelect($event)">
            </p-calendar>
          </div>
          <div class="col">
            <label class="input-label" for="select-plesso">Filtra per un Plesso</label>
            <app-select (valueChange)="plessoOnSelect($event)" [id]="'select-plesso'" [showClear]="true"
              [filterBy]="'name'" [placeholder]="'STR.Seleziona_Plesso'" [data]="plessi" [showValue]="'name'"
              [(value)]="plesso" [optionAttributeShow]="'name'">
            </app-select>
          </div>
          <div class="col" *ngIf="plesso && classi.length > 0">
            <label class="input-label" for="select-userClasseSelected">Filtra per una classe</label>
            <app-select (valueChange)="classeOnSelect($event)" [id]="'select-userClasseSelected'" [showClear]="true"
              [filterBy]="'name'" [placeholder]="'STR.Seleziona_Classi'" [data]="classi" [showValue]="'name'"
              [(value)]="classe" [optionAttributeShow]="'name'">
            </app-select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 col-sm mb-3">

    <app-card-loading *ngIf="isLoading.widget">
    </app-card-loading>

    <ng-container *ngIf="!isLoading.widget">
      <app-google-classroom-stat [hideButtonDetails]="true" [rangeDates]="rangeDates"></app-google-classroom-stat>
    </ng-container>
  </div>

  <div class="col-md-12 col-sm mb-3">
    <!-- Card -->
    <div class="card">
      <!-- Header -->
      <div class="card-header p-1">

        <div class="row justify-content-between align-items-center flex-grow-1">
          <div class="col">
            <tabset [justified]="true" type="pills">
              <tab #tab1="tab" (selectTab)="selectTab(1)">
                <ng-template tabHeading>
                  <i class="fas fa-users-class nav-icon"></i> {{ 'STR.google_classroom' | translate }}
                </ng-template>
              </tab>
              <tab #tab2="tab" (selectTab)="selectTab(2)">
                <ng-template tabHeading>
                  <i class="fas fa-user-graduate nav-icon"></i>{{ 'STR.studenti' | translate }}
                </ng-template>
              </tab>
              <tab #tab3="tab" (selectTab)="selectTab(3)">
                <ng-template tabHeading>
                  <i class="fas fa-chalkboard-teacher nav-icon"></i>{{ 'STR.docenti' | translate }}
                </ng-template>
              </tab>
            </tabset>
          </div>
        </div>
      </div>

      <app-card-datatable-ng *ngIf="tab1.active" title="{{ 'STR.google_classroom' | translate }}"
        [showExportButton]="false" [data]="classroomData" [isLoadingData]="isLoading.classroom"
        [showAvatarOnFirstColumn]="false" [showCheckBox]="false" [error]="classroomError"
        (rowClickEvent)="rowClickCourse($event)" [showExportButton]="true">
      </app-card-datatable-ng>

      <app-card-datatable-ng *ngIf="tab2.active" title="{{ 'STR.studenti' | translate }}" 
        [data]="studentiData" [isLoadingData]="isLoading.studenti" [showCheckBox]="false" [rowExpansion]="true"
        [error]="studentiError" (rowClickEvent)="rowClickStudente($event)" [showExportButton]="true">
      </app-card-datatable-ng>

      <app-card-datatable-ng *ngIf="tab3.active" title="{{ 'STR.docenti' | translate }}" 
        [data]="docentiData" [isLoadingData]="isLoading.docenti" [showCheckBox]="false" [rowExpansion]="true"
        [error]="docentiError" (rowClickEvent)="rowClickDocente($event)" [showExportButton]="true">
      </app-card-datatable-ng>
    </div>
    <!-- End Card -->

  </div>

</div>