<div class="bg-light header-background-image" [style.background-image]="'url(' + currentBackgroundUrl + ')'"
  [style.opacity]="backgroundOpacity">
  <div class="content container-fluid" style="height: 20rem;">
    <div class="page-header page-header-light page-header-reset">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="page-header-title">
            <a routerLink="/" class="text-white mr-3"><i class="fas fa-home-alt"></i></a>
            <ng-container *ngFor="let bread of breadcrumbsElements;">
              <ng-container *ngIf="bread.label.indexOf('\{\{') == -1 && !bread.label.startsWith('ROUTES.')">
                <i class="fas fa-angle-right mr-3"></i>
                <a routerLink="{{bread.url}}" class="mr-3 text-white">{{ bread.label }}</a>
              </ng-container>
            </ng-container>
          </h3>
        </div>
        <!-- <div class="col-auto">
          <a class="btn btn-primary" href="https://www.youtube.com/channel/UCAmusJDqFiPpigjt59_5S9w" target="_blank">
            <i class="fab fa-youtube mr-2"></i>Tutorial
          </a>
        </div>
        <div class="col-auto">
          <a class="btn btn-primary" href="https://geniusuite.com/help" target="_blank">
            <i class="fas fa-question-circle mr-2"></i>Guida
          </a>
        </div> -->
      </div>
    </div>
  </div>
</div>
