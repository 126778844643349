import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { OrganigrammaService } from './general/organigramma.service';
import { PlessoService } from './general/plesso.service';
import { UserService } from './general/user.service';
import { GoogleDirectoryService } from './google/google-directory.service';

export interface SearchIndexData {
  resourceType: string,
  resourceId: any,
  primaryField: string,
  routeLink: string,
  additionalInfo: string[],
  searchableString: string,
  sortRank: number
}

export interface SearchResult {
  resourceType: string,
  resourceId: any,
  primaryField: string,
  routeLink: string,
  additionalInfo: string[],
  searchIndexDataMatch: any,
  searchIndexData: SearchIndexData[],
  stringToSearch: string,
  sortRank: number
}

@Injectable({
  providedIn: 'root'
})

export class SearchService {

  _dataGoogleUsers: SearchIndexData[];
  _dataGoogleGroups: SearchIndexData[];
  _dataUsers: SearchIndexData[];
  _dataPlesso: SearchIndexData[];
  _dataOrganigramma: SearchIndexData[];

  _searchResult: SearchResult[];
  _latestOpenedSearchResult: SearchResult[];

  private _userServiceSubscription: Subscription;
  private _googleServiceUsersSubscription: Subscription;
  private _googleServiceGroupsSubscription: Subscription;
  private _plessoServiceClassiSubscription: Subscription;
  private _organigrammaServiceClassiSubscription: Subscription;

  constructor(
    private googleDirectoryService: GoogleDirectoryService,
    private userService: UserService,
    private plessoService: PlessoService,
    private organigrammaService: OrganigrammaService,
    private translate: TranslateService,
    private router: Router
  ) { }

  init() {

    this._searchResult = [];
    this._latestOpenedSearchResult = [];

    this._dataGoogleUsers = [];
    this._dataGoogleGroups = [];
    this._dataUsers = [];
    this._dataPlesso = [];
    this._dataOrganigramma = [];


    this._userServiceSubscription = this.userService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.usersUpdated(result);
    });

    this._plessoServiceClassiSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.plessoUpdated(result);
    });

    this._organigrammaServiceClassiSubscription = this.organigrammaService.getSubjectToUpdateObservable().subscribe((result) => {
      if (result)
        this.organigrammaUpdated(result);
    });

    this._googleServiceUsersSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe((result) => {
      if (result)
        this.googleUsersUpdated(result);
    });

    this._googleServiceGroupsSubscription = this.googleDirectoryService.getSubjectToUpdateGroupsObservable().subscribe((result) => {
      if (result)
        this.groupsUpdated(result);
    });

  }

  buildIndexData() {

    this.userService.all();
    this.plessoService.all();
    this.organigrammaService.all();
    this.googleDirectoryService.usersList();
    this.googleDirectoryService.groupsList();
  }

  search(stringa: string): SearchResult[] {

    this._searchResult = [];

    let stringToSearch = stringa.trim();

    this._dataUsers.forEach((sid: SearchIndexData) => {
      this.searchIfMatchIndexData(stringToSearch, sid);
    });

    this._dataGoogleUsers.forEach((sid: SearchIndexData) => {
      this.searchIfMatchIndexData(stringToSearch, sid);
    });

    this._dataPlesso.forEach((sid: SearchIndexData) => {
      this.searchIfMatchIndexData(stringToSearch, sid);
    });

    this._dataOrganigramma.forEach((sid: SearchIndexData) => {
      this.searchIfMatchIndexData(stringToSearch, sid);
    });

    this._dataGoogleGroups.forEach((sid: SearchIndexData) => {
      this.searchIfMatchIndexData(stringToSearch, sid);
    });

    return this.sortSearchResultByRank();
  }

  searchIfMatchIndexData(stringa, sid: SearchIndexData) {

    // a volte può capitare che dei dati non siano ancora aggiornati e ci siano dei valori null negli array di ricerca
    // vedi su Asana: Malfunzionamento della ricerca https://app.asana.com/0/1200956325112393/1203055320919330/f
    if (!sid || !sid.searchableString)
      return;

    var regExp = new RegExp(stringa, 'i');

    let match = sid.searchableString.match(regExp);

    if (match !== null) {

      let searchResultKey = this.getSearchResultKey(sid);

      // aggiunto il match evidenziato
      let matchBold = sid.searchableString.replace(regExp, '<b>' + stringa + '</b>');

      let searchResult = {
        resourceType: sid.resourceType,
        resourceId: sid.resourceId,
        primaryField: sid.primaryField,
        routeLink: sid.routeLink,
        additionalInfo: sid.additionalInfo,
        searchIndexDataMatch: [matchBold],
        searchIndexData: [sid],
        stringToSearch: stringa,
        sortRank: sid.sortRank
      };

      if (typeof this._searchResult[searchResultKey] != 'undefined') {
        this._searchResult[searchResultKey].searchIndexDataMatch.concat(searchResult.searchIndexDataMatch);
        this._searchResult[searchResultKey].searchIndexData.concat(searchResult.searchIndexData);
        this._searchResult[searchResultKey].sortRank += searchResult.sortRank;
      }
      else {
        this._searchResult[searchResultKey] = searchResult;
      }
    }
  }

  sortSearchResultByRank() {

    let result = Object.values(this._searchResult);

    if (result && result.length > 0) {
      result.sort(function (a, b) {
        return b.sortRank - a.sortRank;
      });
    }

    let cleanResult = []

    // cerco e rimuovo tutti i duplicati
    if (result && result.length > 0) {

      result.forEach((res) => {

        let trovatoDuplicato = false;

        // Per ogni utente google cerco un possibile match uguale tra gli utenti
        // gli utenti google che hanno anche un utente nei risultati con stessa email
        if (res.resourceType == 'google-user') {

          // cerco tra gli utenti un match uguale
          result.forEach((resUser) => {
            if (resUser.resourceType == 'user') {
              res.searchIndexDataMatch.forEach(matchGoogle => {
                resUser.searchIndexDataMatch.forEach(matchUser => {
                  if (matchGoogle == matchUser) {
                    trovatoDuplicato = true;
                  }
                });
              });
            }
          });
        }

        // se non trovo un duplicato allora lo aggiungo
        if (!trovatoDuplicato) {
          cleanResult.push(res);
        }
      });
    }

    return cleanResult;
  }

  getSearchResultKey(sid: SearchIndexData) {
    return sid.resourceType + "#" + sid.resourceId;
  }

  usersUpdated(result) {

    this._dataUsers = [];

    result.forEach(user => {

      if (!user || !user.user) {
        return;
      }

      let fullName = user.user.familyName + " " + user.user.givenName;

      // dati comuni
      let resourceType = 'user';
      let resourceId = user.id;
      let primaryField = fullName;
      let routeLink = '/ente/user/' + user.user_id;
      let additionalInfo = ['Utente Geniusuite'];

      // aggiungo i ruoli come info addizionali
      user.user.roles.forEach(ruolo => {
        additionalInfo.push(this.translate.instant('STR.' + ruolo.name))
      });

      // user.email
      this._dataUsers.push(this.getSearchIndexData(user.user.email, 5, resourceType, resourceId, primaryField, routeLink, additionalInfo));

      // fullName
      this._dataUsers.push(this.getSearchIndexData(fullName, 10, resourceType, resourceId, primaryField, routeLink, additionalInfo));

      if (user.google_user) {
        // ExternalID
        let externalId = this.googleDirectoryService.getUserExternalIdValue(user.google_user);
        if (externalId) {
          this._dataUsers.push(this.getSearchIndexData(externalId, 10, resourceType, resourceId, primaryField, routeLink, additionalInfo));
        }
      }

    });
  }

  googleUsersUpdated(result) {

    this._dataGoogleUsers = [];

    result.forEach(user => {

      let fullName = user.name?.familyName + " " + user.name?.givenName;

      // dati comuni
      let resourceType = 'google-user';
      let resourceId = user.id;
      let primaryField = fullName;
      let routeLink = '/user/google/' + user.id;
      let additionalInfo = ['Utente Google'];

      // primaryEmail
      this._dataGoogleUsers.push(this.getSearchIndexData(user.primaryEmail, 2, resourceType, resourceId, primaryField, routeLink, additionalInfo));

      // fullName
      this._dataGoogleUsers.push(this.getSearchIndexData(fullName, 2, resourceType, resourceId, primaryField, routeLink, additionalInfo));

      // ExternalID
      let externalId = this.googleDirectoryService.getUserExternalIdValue(user);
      if (externalId) {
        this._dataGoogleUsers.push(this.getSearchIndexData(externalId, 3, resourceType, resourceId, primaryField, routeLink, additionalInfo));
      }

      // orgUnitPath
      this._dataGoogleUsers.push(this.getSearchIndexData(user.orgUnitPath, 1, resourceType, resourceId, primaryField, routeLink, additionalInfo));

    });
  }

  groupsUpdated(result) {

    this._dataGoogleGroups = [];

    result.forEach(group => {

      // dati comuni
      let resourceType = 'google-group';
      let resourceId = group.id;
      let primaryField = group.name;
      let routeLink = '/group/' + group.id;
      let additionalInfo = ['Gruppo Google'];

      // primaryEmail
      this._dataGoogleGroups.push(this.getSearchIndexData(group.email, 3, resourceType, resourceId, primaryField, routeLink, additionalInfo));

      // fullName
      this._dataGoogleGroups.push(this.getSearchIndexData(group.name, 3, resourceType, resourceId, primaryField, routeLink, additionalInfo));

      // Alias
      group.aliases?.forEach(alias => {
        this._dataGoogleGroups.push(this.getSearchIndexData(alias, 3, resourceType, resourceId, primaryField, routeLink, ['Gruppo Google', 'Alias']));
      });

    });
  }

  plessoUpdated(result) {

    this._dataPlesso = [];

    result.forEach(plesso => {

      // dati comuni
      let resourceType = 'plesso';
      let resourceId = plesso.id;
      let primaryField = plesso.name;
      let routeLink = '/plesso/' + plesso.id;
      let additionalInfo = ['Plesso'];

      // name
      this._dataPlesso.push(this.getSearchIndexData(plesso.name, 10, resourceType, resourceId, primaryField, routeLink, additionalInfo));

      this.plessoService.get(plesso.id, (data) => {

        // orgUnitPath
        if (plesso.google_org_unit && plesso.google_org_unit.orgUnitPath) {
          this._dataPlesso.push(this.getSearchIndexData(plesso.google_org_unit.orgUnitPath, 3, resourceType, resourceId, primaryField, routeLink, additionalInfo));
        }

        // group email
        if (plesso.google_group) {
          plesso.google_group.forEach(group => {
            if (group.email) {
              this._dataPlesso.push(this.getSearchIndexData(group.email, 3, resourceType, resourceId, primaryField, routeLink, additionalInfo));
            }
          });
        }

        // Classi
        if (plesso.classi) {
          plesso.classi.forEach(classe => {

            this._dataPlesso.push(this.getSearchIndexData(classe.name, 10, 'classe', resourceId, primaryField, routeLink + '/classe/' + classe.id, ['Classe']));

            // orgUnitPath
            if (classe.google_org_unit && classe.google_org_unit.orgUnitPath) {
              this._dataPlesso.push(this.getSearchIndexData(classe.google_org_unit.orgUnitPath, 3, 'classe', resourceId, primaryField, routeLink + '/classe/' + classe.id, ['Classe']));
            }

            // group email
            if (classe.google_group) {
              classe.google_group.forEach(group => {
                if (group.email) {
                  this._dataPlesso.push(this.getSearchIndexData(group.email, 3, 'classe', resourceId, primaryField, routeLink + '/classe/' + classe.id, ['Classe']));
                }
              });
            }
          });
        }

      })

    });
  }

  organigrammaUpdated(result) {

    this._dataOrganigramma = [];

    result.forEach(organigramma => {

      // dati comuni
      let resourceType = 'organigramma';
      let resourceId = organigramma.id;
      let primaryField = organigramma.name;
      let routeLink = '/organigramma/' + organigramma.id;
      let additionalInfo = ['Organigramma'];

      // user.email
      this._dataOrganigramma.push(this.getSearchIndexData(organigramma.name, 10, resourceType, resourceId, primaryField, routeLink, additionalInfo));

      this.organigrammaService.get(organigramma.id, (data) => {

        // orgUnitPath
        if (data.google_org_unit && data.google_org_unit.orgUnitPath) {
          this._dataOrganigramma.push(this.getSearchIndexData(data.google_org_unit.orgUnitPath, 3, resourceType, resourceId, primaryField, routeLink, additionalInfo));
        }

        // group email
        if (data.google_group) {
          data.google_group.forEach(group => {
            if (group.email) {
              this._dataOrganigramma.push(this.getSearchIndexData(group.email, 3, resourceType, resourceId, primaryField, routeLink, additionalInfo));
            }
          });
        }

      });

    });
  }

  getSearchIndexData(searchableString, sortRank, resourceType, resourceId, primaryField, routeLink, additionalInfo) {
    return {
      resourceType: resourceType,
      resourceId: resourceId,
      primaryField: primaryField,
      routeLink: routeLink,
      additionalInfo: additionalInfo,
      searchableString: searchableString,
      sortRank: sortRank
    };
  }

  openSearchResult(searchResult: SearchResult) {

    // salvo il risultato aperto per conservare le ultime ricerche usate
    let sid = searchResult.searchIndexData[0];
    let searchResultKey = this.getSearchResultKey(sid);
    this._latestOpenedSearchResult[searchResultKey] = searchResult;

    this.router.navigate([searchResult.routeLink]);
  }

  getLatestSearchTerms() {

    let ricercheRecenti = [];

    let latest = Object.values(this._latestOpenedSearchResult);

    latest.forEach(sr => {

      if (typeof ricercheRecenti[sr.stringToSearch] == 'undefined')
        ricercheRecenti[sr.stringToSearch] = 0;

      ricercheRecenti[sr.stringToSearch]++;
    });

    ricercheRecenti.sort(function (a, b) { return a - b });

    return Object.keys(ricercheRecenti);
  }

  ngOnDestroy() {

    if (this._userServiceSubscription)
      this._userServiceSubscription.unsubscribe();

    if (this._googleServiceUsersSubscription)
      this._googleServiceUsersSubscription.unsubscribe();

    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();

    if (this._organigrammaServiceClassiSubscription)
      this._organigrammaServiceClassiSubscription.unsubscribe();

    if (this._plessoServiceClassiSubscription)
      this._plessoServiceClassiSubscription.unsubscribe();
  }

}
