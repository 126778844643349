<div class="card min-h-50 card-hover-shadow mb-3 border-0" *ngIf="monitors && monitors.length > 0">
  <!-- Header -->
  <div class="card-header bg-card-header">
    <h4 class="card-header-title text-white"><i class="far fa-tv-retro nav-icon mr-2"></i>Alfred</h4>
    <small class="text-white">{{ monitors.length }} monitor</small>
  </div>
  <!-- End Header -->

  <!-- Body -->
  <div class="card-body">
    <div class="row">
      <div class="col-md-4 mb-3" *ngFor="let monitor of monitors">
        <div class="card card-sm">
          <div class="card-body">
            <span class="card-subtitle"><img style="margin-bottom: 3px;" width="15" height="15" src="./assets/img/icons/alfred.svg"> {{ monitor.code }}</span>
            <h3 class="card-title">{{ monitor.name }}</h3>
            <p class="card-text">{{ monitor.description }}</p>
            <a class="card-link" [routerLink]="['/monitor/' + monitor.id]">Modifica</a>
            <a class="card-link" target="_blank" href="{{ alfredUrl }}{{ monitor.code }}">Visualizza</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
