import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { GsuiteParametersComponent } from './pages/settings/gsuite-parameters/gsuite-parameters.component';
import { SetupWizardConfigComponent } from './pages/setup-wizard/setup-wizard-config/setup-wizard-config.component';
import { SetupWizardStartComponent } from './pages/setup-wizard/setup-wizard-start/setup-wizard-start.component';
import { AuthGuard } from './guards/auth.guard';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { OrganigrammaProfileComponent } from './pages/organigramma-profile/organigramma-profile.component';
import { ClasseProfileComponent } from './pages/classe-profile/classe-profile.component';
import { PlessoProfileComponent } from './pages/plesso-profile/plesso-profile.component';
import { GeniusuiteSettingsComponent } from './pages/settings/geniusuite-settings/geniusuite-settings.component';
import { AccountPageComponent } from './pages/account-page/account-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { Error404Component } from './pages/system/error-404/error-404.component';
import { EnteProfileComponent } from './pages/ente-profile/ente-profile.component';
import { SetupWizardConfigColUsersComponent } from './pages/setup-wizard/setup-wizard-config-col-users/setup-wizard-config-col-users.component';
import { SetupWizardConfigColPlessoClasseComponent } from './pages/setup-wizard/setup-wizard-config-col-plessoclasse/setup-wizard-config-col-plessoclasse.component';
import { SetupWizardPreviewStrutturaComponent } from './pages/setup-wizard/setup-wizard-preview-struttura/setup-wizard-preview-struttura.component';
import { BootstrapPageComponent } from './pages/bootstrap-page/bootstrap-page.component';
import { UpdatePasswordComponent } from './pages/operations/update-password/update-password.component';
import { MateriaProfileComponent } from './pages/materia-profile/materia-profile.component';
import { GoogleClassroomStatPageComponent } from './pages/statistics/google-classroom-stat-page/google-classroom-stat-page.component';
import { SetupWizardPreviewUsersComponent } from './pages/setup-wizard/setup-wizard-preview-users/setup-wizard-preview-users.component';
import { SetupWizardEndComponent } from './pages/setup-wizard/setup-wizard-end/setup-wizard-end.component';
import { ClassroomProfileComponent } from './pages/classroom-profile/classroom-profile.component';
import { MoveUsersComponent } from './pages/operations/move-users/move-users.component';
import { GestioneCattedreComponent } from './pages/operations/gestione-cattedre/gestione-cattedre.component';
import { RequestChangePasswordPageComponent } from './pages/request-change-password-page/request-change-password-page.component';
import { PassaggioAnnoWizardStartComponent } from './pages/passaggio-anno-wizard/passaggio-anno-wizard-start/passaggio-anno-wizard-start.component';
import { PassaggioAnnoWizardConfigComponent } from './pages/passaggio-anno-wizard/passaggio-anno-wizard-config/passaggio-anno-wizard-config.component';
import { PassaggioAnnoWizardStrutturaComponent } from './pages/passaggio-anno-wizard/passaggio-anno-wizard-struttura/passaggio-anno-wizard-struttura.component';
import { PassaggioAnnoWizardEndComponent } from './pages/passaggio-anno-wizard/passaggio-anno-wizard-end/passaggio-anno-wizard-end.component';
import { DeleteUsersComponent } from './pages/operations/delete-users/delete-users.component';
import { RipristinoUsersComponent } from './pages/operations/ripristino-users/ripristino-users.component';
import { UpdateSettingGroupsComponent } from './pages/operations/groups/update-setting-groups/update-setting-groups.component';
import { PassaggioAnnoWizardWaitComponent } from './pages/passaggio-anno-wizard/passaggio-anno-wizard-wait/passaggio-anno-wizard-wait.component';
import { ActivityPageComponent } from './pages/activity-page/activity-page.component';
import { AddUsersToOrganigrammaComponent } from './pages/operations/add-users-to-organigramma/add-users-to-organigramma.component';
import { RemoveUsersFromOrganigrammaComponent } from './pages/operations/remove-users-from-organigramma/remove-users-from-organigramma.component';
import { SyncUsersFromGoogleComponent } from './pages/operations/sync-users-from-google/sync-users-from-google.component';
import { GoogleMeetStatPageComponent } from './pages/statistics/google-meet-stat-page/google-meet-stat-page.component';
import { GroupProfileComponent } from './pages/group-profile/group-profile.component';
import { RemoveMembersFromGroupsComponent } from './pages/operations/groups/remove-members-from-groups/remove-members-from-groups.component';
import { DeleteGroupsComponent } from './pages/operations/groups/delete-groups/delete-groups.component';
import { UpdateMembersFromGroupsComponent } from './pages/operations/groups/update-members-from-groups/update-members-from-groups.component';
import { SuspendUsersComponent } from './pages/operations/suspend-users/suspend-users.component';
import { ReactivateSuspendedUsersComponent } from './pages/operations/reactivate-suspended-users/reactivate-suspended-users.component';
import { PassaggioAnnoBackupComponent } from './pages/passaggio-anno-wizard/passaggio-anno-backup/passaggio-anno-backup.component';
import { UpdateAccountDomainComponent } from './pages/operations/update-account-domain/update-account-domain.component';
import { ActiveClassroomComponent } from './pages/operations/classroom/active-classroom/active-classroom.component';
import { SuspendClassroomComponent } from './pages/operations/classroom/suspend-classroom/suspend-classroom.component';
import { DeleteClassroomComponent } from './pages/operations/classroom/delete-classroom/delete-classroom.component';
import { ArchiveClassroomComponent } from './pages/operations/classroom/archive-classroom/archive-classroom.component';
import { CreateClassroomComponent } from './pages/operations/classroom/smart-creation-classroom/create-classroom/create-classroom.component';
import { RemoveAllMembersFromGroupsComponent } from './pages/operations/groups/remove-all-members-from-groups/remove-all-members-from-groups.component';
import { WorkspaceControlSystemComponent } from './pages/workspace-control-system/workspace-control-system.component';
import { ManutenzioneComponent } from './pages/manutenzione/manutenzione.component';
import { CreateClassroomPreviewComponent } from './pages/operations/classroom/smart-creation-classroom/create-classroom-preview/create-classroom-preview.component';
import { AddMembersToGroupsComponent } from './pages/operations/groups/add-members-to-groups/add-members-to-groups.component';
import { CreateSingleClassroomComponent } from './pages/operations/classroom/create-single-classroom/create-single-classroom.component';
import { RemoveMembersFromClassroomsComponent } from './pages/operations/classroom/remove-members-from-classrooms/remove-members-from-classrooms.component';
import { AddMembersToClassroomComponent } from './pages/operations/classroom/add-members-to-classroom/add-members-to-classroom.component';
import { RemoveMembersFromClasseComponent } from './pages/operations/remove-members-from-classe/remove-members-from-classe.component';
import { RinnovoLicenzaComponent } from './pages/rinnovo-licenza/rinnovo-licenza.component';
import { AnnouncementPageComponent } from './pages/announcement-page/announcement-page.component';
import { ParametersGuard } from './guards/parameters.guard';
import { AccessTokenGuard } from './guards/accesstoken.guard';
import { AlfredStartComponent } from './pages/alfred/alfred-start/alfred-start.component';
import { AlfredConfigComponent } from './pages/alfred/alfred-config/alfred-config.component';
import { MonitorProfileComponent } from './pages/monitor-profile/monitor-profile.component';
import { ProvisionClassroomComponent } from './pages/operations/classroom/provision-classroom/provision-classroom.component';
import { DeclineClassroomComponent } from './pages/operations/classroom/decline-classroom/decline-classroom.component';
import { WorkspaceAutorizzazioniComponent } from './pages/workspace-autorizzazioni/workspace-autorizzazioni.component';

const user_routes = {
  path: 'user', data: { title: '' },
  children: [
    { path: '', component: UserProfileComponent, canActivate: [AuthGuard], data: { title: 'ROUTES.user.default', background: 'utente.svg' } },
    { path: 'add/:user_role', component: UserProfileComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.user.add_with_role', background: 'utente.svg' } },
    { path: 'google/:id_google', component: UserProfileComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.user.id_google', background: 'utente.svg' } },
    { path: ':id_user', component: UserProfileComponent, canActivate: [AuthGuard], data: { title: 'ROUTES.user.id_user', background: 'utente.svg' } },
    { path: ':id_user/edit', component: UserProfileComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.user.edit', background: 'utente.svg' } },
    { path: ':id_user/delete', component: UserProfileComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.user.delete', background: 'utente.svg' } },
  ]
};

const organigramma_child_routes = {
  path: 'organigramma', data: { title: '' },
  children: [
    { path: 'create', component: OrganigrammaProfileComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.organigramma.id_organigramma.child_new', background: 'organigramma.svg' } },
    {
      path: ':id_organigramma_child', data: { title: 'ROUTES.organigramma.id_organigramma.id_organigramma_child' },
      children: [
        { path: '', component: OrganigrammaProfileComponent, canActivate: [AccessTokenGuard], data: { title: '', background: 'organigramma.svg' } },
        user_routes,
      ]
    },
  ]
};

const routes: Routes = [
  { path: '', component: BootstrapPageComponent, data: { title: 'ROUTES.default', background: '' } },
  { path: 'login', component: LoginPageComponent, data: { title: 'ROUTES.login', background: 'login.svg' } },
  { path: 'manutenzione', component: ManutenzioneComponent, data: { title: 'ROUTES.manutenzione', background: 'login.svg' } },
  { path: 'rinnovo-licenza', component: RinnovoLicenzaComponent, data: { title: 'ROUTES.rinnovo_licenza', background: 'login.svg' } },
  { path: 'workspace-autorizzazioni', component: WorkspaceAutorizzazioniComponent, data: { title: 'ROUTES.workspace_autorizzazioni', background: 'login.svg' } },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { title: 'ROUTES.dashboard', background: 'dashboard.svg' } },
  { path: 'announcement', component: AnnouncementPageComponent, canActivate: [AuthGuard], data: { title: 'ROUTES.announcement', background: 'dashboard.svg' } },
  { path: 'account', component: AccountPageComponent, canActivate: [AuthGuard], data: { title: 'ROUTES.account', background: '' } },
  {
    path: 'classroom', data: { title: '' },
    children: [
      { path: '', component: ClassroomProfileComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.classroom.default', background: 'ente.svg' } },
      { path: 'create', component: ClassroomProfileComponent, canActivate: [AccessTokenGuard], data: { title: '', background: 'ente.svg' } },
      {
        path: ':id_course', data: { title: 'ROUTES.classroom.id_course' },
        children: [
          { path: '', component: ClassroomProfileComponent, canActivate: [AccessTokenGuard], data: { title: '', background: 'ente.svg' } },
          { path: 'edit', component: ClassroomProfileComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.classroom.edit', background: 'ente.svg' } },
          user_routes,
        ]
      }
    ]
  },
  { path: 'workspace-control-system', component: WorkspaceControlSystemComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.workspace_control_system', background: 'wizard.svg' } },
  { path: 'cattedre', component: GestioneCattedreComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.gestione_cattedre', background: 'wizard.svg' } },
  // { path: 'logout', component: AccountPageComponent, canActivate: [AccessTokenGuard], data: { title: 'Logout' } },
  {
    path: 'activity', data: { title: 'ROUTES.activity.default', background: '' },
    children: [
      { path: ':id_activity', component: ActivityPageComponent, canActivate: [AuthGuard], data: { title: 'ROUTES.activity.id_activity', background: '' } },
    ]
  },
  {
    path: 'settings', data: { title: 'ROUTES.settings.default' },
    children: [
      { path: '', component: GeniusuiteSettingsComponent, canActivate: [AuthGuard], data: { title: 'ROUTES.settings.geniusuite', background: 'parametri.svg' } },
      { path: 'gsuite', component: GsuiteParametersComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.settings.gsuite', background: 'parametri.svg' } },
    ]
  },
  {
    path: 'setup', data: { title: 'ROUTES.setup.default' },
    children: [
      { path: '', component: SetupWizardStartComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: '', background: 'wizard.svg' } },
      { path: 'config', component: SetupWizardConfigComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.setup.config', background: 'wizard.svg' } },
      { path: 'config/struttura', component: SetupWizardConfigColPlessoClasseComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.setup.config_struttura', background: 'wizard.svg' } },
      { path: 'preview/struttura', component: SetupWizardPreviewStrutturaComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.setup.preview_struttura', background: 'wizard.svg' } },
      { path: 'config/users', component: SetupWizardConfigColUsersComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.setup.config_users', background: 'wizard.svg' } },
      { path: 'preview/users', component: SetupWizardPreviewUsersComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.setup.preview_users', background: 'wizard.svg' } },
      { path: 'finish', component: SetupWizardEndComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: '', background: 'wizard.svg' } },
    ]
  },
  {
    path: 'passaggio-anno', data: { title: 'ROUTES.passaggioanno.default' },
    children: [
      { path: '', component: PassaggioAnnoWizardStartComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: '', background: 'passaggioanno.svg' } },
      { path: 'backup', component: PassaggioAnnoBackupComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: '', background: 'passaggioanno.svg' } },
      { path: 'config', component: PassaggioAnnoWizardConfigComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: '', background: 'passaggioanno.svg' } },
      { path: 'struttura', component: PassaggioAnnoWizardStrutturaComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: '', background: 'passaggioanno.svg' } },
      { path: 'wait', component: PassaggioAnnoWizardWaitComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: '', background: 'passaggioanno.svg' } },
      { path: 'finish', component: PassaggioAnnoWizardEndComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: '', background: 'passaggioanno.svg' } },
    ]
  },
  {
    path: 'alfred', data: { title: 'ROUTES.alfred.default' },
    children: [
      { path: '', component: AlfredStartComponent, canActivate: [AuthGuard], data: { title: '', background: 'wizard.svg' } },
      { path: 'config', component: AlfredConfigComponent, canActivate: [AuthGuard], data: { title: 'ROUTES.alfred.config', background: 'wizard.svg' } },
    ]
  },
  {
    path: 'monitor', data: { title: 'ROUTES.monitor.default' },
    children: [
      { path: '', component: MonitorProfileComponent, canActivate: [AuthGuard], data: { title: '', background: 'monitor.svg' } },
      { path: 'create', component: MonitorProfileComponent, canActivate: [AuthGuard], data: { title: '', background: 'monitor.svg' } },
      {
        path: ':id_monitor', data: { title: 'ROUTES.materia.id_monitor.default' },
        children: [
          { path: '', component: MonitorProfileComponent, canActivate: [AuthGuard], data: { title: '', background: 'monitor.svg' } },
        ]
      }
    ]
  },
  {
    path: 'ente', data: { title: 'ROUTES.ente.default' },
    children: [
      { path: '', component: EnteProfileComponent, canActivate: [AuthGuard], data: { title: '', background: 'ente.svg' } },
      { path: 'edit', component: EnteProfileComponent, canActivate: [AuthGuard], data: { title: 'ROUTES.ente.edit', background: 'ente.svg' } },
      user_routes,
    ]
  },
  {
    path: 'group', data: { title: '' },
    children: [
      { path: '', redirectTo: '/**', pathMatch: 'full' },
      {
        path: ':id_group', data: { title: 'ROUTES.group.id_group' },
        children: [
          { path: '', component: GroupProfileComponent, canActivate: [AccessTokenGuard], data: { title: '', background: 'ente.svg' } },
          { path: 'edit', component: GroupProfileComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.group.edit', background: 'ente.svg' } },
          user_routes,
        ]
      }
    ]
  },
  {
    path: 'materia', data: { title: 'ROUTES.materia.default' },
    children: [
      { path: '', component: MateriaProfileComponent, canActivate: [AccessTokenGuard], data: { title: '', background: 'materia.svg' } },
      { path: 'create', component: MateriaProfileComponent, canActivate: [AccessTokenGuard], data: { title: '', background: 'materia.svg' } },
      {
        path: ':id_materia', data: { title: 'ROUTES.materia.id_materia.default' },
        children: [
          { path: '', component: MateriaProfileComponent, canActivate: [AccessTokenGuard], data: { title: '', background: 'materia.svg' } },
          { path: 'edit', component: MateriaProfileComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.materia.id_materia.edit', background: 'materia.svg' } },
          user_routes,
        ]
      }
    ]
  },
  {
    path: 'plesso', data: { title: '' },
    children: [
      { path: '', redirectTo: 'create', pathMatch: 'full' },
      { path: 'create', component: PlessoProfileComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.plesso.default', background: 'plesso.svg' } },
      { path: 'mappa', component: PlessoProfileComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.plesso.mappa', background: 'plesso.svg' } },
      {
        path: ':id_plesso', data: { title: 'ROUTES.plesso.id_plesso.default' },
        children: [
          { path: '', component: PlessoProfileComponent, canActivate: [AccessTokenGuard], data: { title: '', background: 'plesso.svg' } },
          { path: 'edit', component: PlessoProfileComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.plesso.id_plesso.edit', background: 'plesso.svg' } },
          { path: 'delete', component: PlessoProfileComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.plesso.id_plesso.delete', background: 'plesso.svg' } },
          user_routes,
          {
            path: 'classe', data: { title: '' },
            children: [
              { path: '', component: ClasseProfileComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.classe.default', background: 'classe.svg' } },
              {
                path: ':id_classe', data: { title: 'ROUTES.classe.id_classe.default' },
                children: [
                  { path: '', component: ClasseProfileComponent, canActivate: [AccessTokenGuard], data: { title: '', background: 'classe.svg' } },
                  { path: 'edit', component: ClasseProfileComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.classe.id_classe.edit', background: 'classe.svg' } },
                  { path: 'delete', component: ClasseProfileComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.classe.id_classe.delete', background: 'classe.svg' } },
                  user_routes
                ]
              },

            ]
          }
        ]
      }
    ]
  },
  {
    path: 'organigramma', data: { title: 'ROUTES.organigramma.default' },
    children: [
      { path: '', component: OrganigrammaProfileComponent, canActivate: [AccessTokenGuard], data: { title: '', background: 'organigramma.svg' } },
      { path: 'create', component: OrganigrammaProfileComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.organigramma.create', background: 'organigramma.svg' } },
      { path: 'create/:type', component: OrganigrammaProfileComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.organigramma.create', background: 'organigramma.svg' } },
      {
        path: ':id_organigramma', data: { title: 'ROUTES.organigramma.id_organigramma.default' },
        children: [
          { path: '', component: OrganigrammaProfileComponent, canActivate: [AccessTokenGuard], data: { title: '', background: 'organigramma.svg' } },
          { path: 'edit', component: OrganigrammaProfileComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.organigramma.id_organigramma.edit', background: 'organigramma.svg' } },
          { path: 'delete', component: OrganigrammaProfileComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.organigramma.id_organigramma.delete', background: 'organigramma.svg' } },
          user_routes,
          organigramma_child_routes,
        ]
      },
    ]
  },
  user_routes,
  {
    path: 'operation', data: { title: '' },
    children: [
      { path: 'update-password', component: UpdatePasswordComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.update_password', background: 'utente.svg' } },
      { path: 'update-account-domain', component: UpdateAccountDomainComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.update-account-domain', background: 'utente.svg' } },
      { path: 'ripristino-users', component: RipristinoUsersComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.riprisitno_users', background: 'utente.svg' } },
      { path: 'sync-users-from-google', component: SyncUsersFromGoogleComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.operations.sync_users_from_google', background: 'utente.svg' } },
      { path: 'suspend-users', component: SuspendUsersComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.suspend_users', background: 'utente.svg' } },
      { path: 'reactivate-suspended-users', component: ReactivateSuspendedUsersComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.reactivate_suspended_users', background: 'utente.svg' } },
      { path: 'add-users-to-organigramma', component: AddUsersToOrganigrammaComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.operations.add_users_to_organigramma', background: 'utente.svg' } },
      { path: 'remove-users-from-organigramma', component: RemoveUsersFromOrganigrammaComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.operations.remove_users_from_organigramma', background: 'utente.svg' } },
      { path: 'move-users', component: MoveUsersComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.operations.move_users', background: 'utente.svg' } },
      { path: 'update-setting-groups', component: UpdateSettingGroupsComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.operations.update_setting_groups', background: 'utente.svg' } },
      { path: 'delete-users', component: DeleteUsersComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.delete_users', background: 'utente.svg' } },
      { path: 'delete-groups', component: DeleteGroupsComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.delete_groups', background: 'utente.svg' } },
      { path: 'remove-all-members-from-groups', component: RemoveAllMembersFromGroupsComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.delete_groups', background: 'utente.svg' } },
      { path: 'remove-members-from-groups', component: RemoveMembersFromGroupsComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.remove-all-members-from-groups', background: 'utente.svg' } },
      { path: 'add-members-to-groups', component: AddMembersToGroupsComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.add-members-to-groups', background: 'utente.svg' } },
      { path: 'update-members-from-groups', component: UpdateMembersFromGroupsComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.update_members_from_groups', background: 'utente.svg' } },
      {
        path: 'create-classroom', data: { title: 'ROUTES.operations.create-classroom' },
        children: [
          { path: '', component: CreateClassroomComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.create-classroom', background: 'classe.svg' } },
          { path: 'single', component: CreateSingleClassroomComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.create-classroom', background: 'classe.svg' } },
          { path: 'preview', component: CreateClassroomPreviewComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.create-classroom', background: 'wizard.svg' } },
        ]
      },
      { path: 'active-classroom', component: ActiveClassroomComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.active-classroom', background: 'classe.svg' } },
      { path: 'suspend-classroom', component: SuspendClassroomComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.suspend-classroom', background: 'classe.svg' } },
      { path: 'provision-classroom', component: ProvisionClassroomComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.provision-classroom', background: 'classe.svg' } },
      { path: 'decline-classroom', component: DeclineClassroomComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.decline-classroom', background: 'classe.svg' } },
      { path: 'delete-classroom', component: DeleteClassroomComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.delete-classroom', background: 'classe.svg' } },
      { path: 'archive-classroom', component: ArchiveClassroomComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.archive-classroom', background: 'classe.svg' } },
      { path: 'remove-members-from-classrooms', component: RemoveMembersFromClassroomsComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.remove-members-from-classrooms', background: 'utente.svg' } },
      { path: 'add-members-to-classroom', component: AddMembersToClassroomComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.add-members-to-classroom', background: 'utente.svg' } },
      { path: 'remove-members-from-classe', component: RemoveMembersFromClasseComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.operations.remove-members-from-classe', background: 'utente.svg' } },
    ]
  },
  {
    path: 'statistics', data: { title: 'ROUTES.statistics.default' },
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'google-classroom', component: GoogleClassroomStatPageComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.statistics.google_classroom', background: 'statistiche.svg' } },
      { path: 'google-meet', component: GoogleMeetStatPageComponent, canActivate: [AccessTokenGuard], data: { title: 'ROUTES.statistics.google_meet', background: 'statistiche.svg' } }
    ]
  },
  { path: 'req-change-password', component: RequestChangePasswordPageComponent, canActivate: [AccessTokenGuard, ParametersGuard], data: { title: 'ROUTES.req_change_password', background: 'login.svg' } },
  { path: '**', component: Error404Component, data: { title: 'ROUTES.not_found', background: '' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
