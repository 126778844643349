import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { ToastService } from './toast.service';
import { UtilityService } from './utility.service';

// import { saveAs } from 'file-saver';

@Injectable()
export class BackendService {

  constructor(
    private http: HttpClient,
    private toast: ToastService,
    private utility: UtilityService
  ) {
  }

  setupAuthHttpHeaders(searchParams: HttpParams = new HttpParams()) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // headers = headers.set("Authorization", "Bearer " + localStorage.getItem("Api-Token"));

    let options: any = { headers: headers, responseType: 'json', observe: 'body', params: searchParams, withCredentials: true };

    return options;
  }

  get(resource: string, searchParams: HttpParams = new HttpParams()) {

    let options = this.setupAuthHttpHeaders(searchParams);

    if (resource.startsWith("http://") || resource.startsWith("https://"))
      return this.http.get(resource, options);

    return this.http.get(environment.apiUrl + resource, options);
  }

  post(resource: string, body: any, searchParams: HttpParams = new HttpParams()) {

    let options = this.setupAuthHttpHeaders(searchParams);

    if (resource.startsWith("http://") || resource.startsWith("https://"))
      return this.http.post(resource, JSON.stringify(body), options);

    return this.http.post(environment.apiUrl + resource, JSON.stringify(body), options);
  }

  delete(resource: string, body: any, searchParams: HttpParams = new HttpParams()) {
    // TODO [Zoran] Confrontarsi con gli altri se è giusto perchè nella form di delete delle enttita ho bisogno di passare dati
    let options = this.setupAuthHttpHeaders(searchParams);

    if (resource.startsWith("http://") || resource.startsWith("https://"))
      return this.http.post(resource, JSON.stringify(body), options);

    return this.http.post(environment.apiUrl + resource, JSON.stringify(body), options);
  }

  destroy(resource: string) {

    let options = this.setupAuthHttpHeaders();

    if (resource.startsWith("http://") || resource.startsWith("https://"))
      return this.http.delete(resource, options);

    return this.http.delete(environment.apiUrl + resource, options);
  }

  put(resource: string, body: any) {

    let options = this.setupAuthHttpHeaders();

    if (resource.startsWith("http://") || resource.startsWith("https://"))
      return this.http.put(resource, JSON.stringify(body), options);

    return this.http.put(environment.apiUrl + resource, JSON.stringify(body), options);
  }

  download(resource: string, filename: string) {

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.set("Authorization", "Bearer " + localStorage.getItem("Api-Token"));

    let options: any = { headers: headers, responseType: 'blob' };

    this.http.get(environment.apiUrl + resource, options).subscribe(
      (response: any) => {
        var blob = new Blob([response], { type: response.type });
        // saveAs(blob, filename);
      },
      (error) => {
        this.showErrors(error);
      }
    );
  }

  paginate(resource: string, callbackOk = (result: any) => { }, callbackError = (error: any) => { }, searchParams: HttpParams = new HttpParams(), previousResultData = null, nextCursor = null, callCallbackOkEachPage: boolean = false) {

    if (!searchParams) {
      searchParams = new HttpParams();
    }

    if (nextCursor) {
      searchParams = searchParams.set("cursor", nextCursor);
    }

    if (!previousResultData)
      previousResultData = [];

    this.get(resource, searchParams).subscribe(
      (response: any) => {

        if (response.data) {
          previousResultData = previousResultData.concat(response.data);
        }

        if (response.next_cursor) {
          this.paginate(resource, callbackOk, callbackError, searchParams, previousResultData, response.next_cursor, callCallbackOkEachPage);
        } else {
          // solo quando le pagine sono finite chiamo la callback
          let dataCloned = this.utility.cloneData(previousResultData);
          callbackOk(dataCloned);
        }
      }, (error) => {
        callbackError(error);
      }
    );
  }

  showErrors(error: any) {

    if (error == null || !error.status) {
      this.toast.error("Backend ERROR", "No error message, please contact the support.");
      return;
    }

    // controllo se è un errore di validazione
    if (error.status == 422) {

      var errorJson = error;

      this.toast.error("ERROR", errorJson.message);

      if (errorJson.error && errorJson.error.message) {
        this.toast.error("ERROR", errorJson.error.message);
      }

      for (var key in errorJson.errors) {
        var value = errorJson.errors[key];
        this.toast.error(key.toUpperCase(), value);
      }
    }
    else if (error.status == 404) {
      this.toast.warn("Resource not found", "what you were looking for was not found");
    }
    else if (error.status == 401 || (error.message && error.message.indexOf("Unauthenticated")) > 0) {
      this.toast.warn("Unauthorized", "Session expired or there is a new update available, try to login again.");
      // this.router.navigate(["/login"]);
    }
    else {
      console.error("Backend error", error);
      this.toast.error("Error " + error.status + " " + error.statusText, "backend error, check the console for debug information");
    }

  }
}
