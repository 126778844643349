import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/general/user.service';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { SyncUsersFromGoogleService } from 'src/app/services/operations/sync-users-from-google.service';
import { SyncdataService } from 'src/app/services/syncdata.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-user-google-profile-info-card',
  templateUrl: './user-google-profile-info-card.component.html',
  styleUrls: ['./user-google-profile-info-card.component.scss']
})
export class UserGoogleProfileInfoCardComponent implements OnInit {

  @Input() id: any;
  @Input() disableButton = false;

  data: any;
  user: any;

  showInfo = false;

  private _googleServiceUserSubscription: Subscription;



  constructor(
    private router: Router,
    private toast: ToastService,
    private syncUsersFromGoogleService: SyncUsersFromGoogleService,
    private userService: UserService,
    private googleDirectoryService: GoogleDirectoryService,
  ) { }

  ngOnInit() {

    this.unsubscribeAll();

    this._googleServiceUserSubscription = this.googleDirectoryService.getSubjectToUpdateUsersObservable().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });

    this.googleDirectoryService.usersList();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.id && changes.id.currentValue && changes.id.previousValue !== undefined) {
      this.ngOnInit();
    }
  }

  unsubscribeAll() {
    if (this._googleServiceUserSubscription)
      this._googleServiceUserSubscription.unsubscribe();
  }

  updateData() {
    // Google Data
    this.data = this.googleDirectoryService.getUser(this.id);
    this.user = this.userService.getByGoogleId(this.id);
  }

  syncUsersCommand() {
    this.syncUsersFromGoogleService.addUsers([this.data]);

    this.toast.success("TOAST.success.add_selection.title", "TOAST.success.add_selection.msg");
    this.router.navigate(['operation/sync-users-from-google']);
  }
}
