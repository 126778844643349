<app-card-loading
  *ngIf="loadingPlesso || loadingClasse || loadingGoogleOrgUnits || loadingGoogleGroups || loadingParameterService">
</app-card-loading>
<app-card-loading-error
  *ngIf="!(loadingPlesso || loadingClasse || loadingGoogleOrgUnits || loadingGoogleGroups || loadingParameterService) && error"
  [error]="error" (retryClicked)="retryAfterLoadingError()">
</app-card-loading-error>

<div class="card card-hover-shadow"
  *ngIf="!(loadingPlesso || loadingClasse || loadingGoogleOrgUnits || loadingGoogleGroups || loadingParameterService) && !error">

  <app-card-loading-overlay [loading]="formLoading"></app-card-loading-overlay>

  <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" validate>
    <div class="card-body" [ngClass]="{'card-is-loading': formLoading}">
      <div class="row">
        <!-- Left -->
        <div class="col">

          <!-- Titlte -->
          <div class="row form-group">
            <div class="col-12">
              <h3 *ngIf="!edit">Crea Classe</h3>
              <h3 *ngIf="edit">Modifica Classe</h3>
            </div>
          </div>

          <!-- Nome -->
          <div class="row form-group">
            <div class="col-12">
              <label class="input-label" for="name">Nome</label>
              <input id="name" name="name" #name="ngModel" class="form-control" [(ngModel)]="data.name"
                [ngClass]="(name.dirty || name.touched)? (name.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                type="text" required maxlength="255" [forbiddenString]="listClassiFilteredName"
                [forbiddenGoogle]="listOrgUnitFilteredName" (input)="inputNameClasse(myForm)"
                (focusout)="checkGroupSetOnFocusOut()">

              <div class="invalid-feedback" *ngIf="name.invalid && (name.dirty || name.touched)">
                <span>Non valido</span>
                <span *ngIf="name.errors.forbiddenString; else checkOrgUnit">, Classe già
                  esistente</span>
                <ng-template #checkOrgUnit>
                  <span *ngIf="name.errors.forbiddenGoogle">, Unita Organizzativa già esistente</span>
                </ng-template>
              </div>

              <span class="valid-feedback" *ngIf="name.valid && (name.dirty || name.touched)">Valido</span>
            </div>
          </div>

          <!-- Anno -->
          <div class="row form-group">
            <div class="col-12">
              <label class="input-label" for="anno">Anno</label>
              <input id="anno" name="anno" #anno="ngModel" [(ngModel)]="data.anno"
                [ngClass]="(anno.dirty || anno.touched)? (anno.valid ? 'is-valid': 'is-invalid') : 'untouched'"
                type="text" pattern="\d*" maxlength="4" class="form-control" (input)="loadGroupsPreview(myForm)"
                (focusout)="checkGroupSetOnFocusOut()">
              <span class="invalid-feedback" *ngIf="anno.invalid && (anno.dirty || anno.touched)">Non
                valido</span>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-12">
              <button class="btn btn-outline-primary" type="button" (click)="changeStatusCollapse()"
                [attr.aria-expanded]="showAdvancedSettings" aria-controls="collapseAdvancedSettings">
                Modifica impostazioni standard
                <span class="ml-2" [ngSwitch]="showAdvancedSettings">
                  <i class="far fa-chevron-down" *ngSwitchCase="false"></i>
                  <i class="far fa-chevron-up" *ngSwitchCase="true"></i>
                </span>
              </button>
            </div>
          </div>

          <div class="row form-group" id="collapseAdvancedSettings" [collapse]="!showAdvancedSettings"
            [isAnimated]="true">
            <div class="col-12">

              <!-- Creare OrgUnit -->
              <div class="row form-group">
                <div class="col-12">
                  <h4>Unità organizzativa</h4>
                  <!-- Nuova org unit standard -->
                  <label class="toggle-switch d-flex" for="gestOrgUnit">
                    <input id="gestOrgUnit" [(ngModel)]="defaultCheckOrgUnit.crea"
                      (change)="changeCreateOrgUnit()" name="gestOrgUnit" type="checkbox"
                      class="toggle-switch-input">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">Crea nuova unità</span>
                      <small class="d-block text-muted">Se si vuole associare un'unità organizzativa
                        esistente,
                        disattivare
                        l'opzione</small>
                    </span>
                  </label>
                </div>
              </div>

              <!-- Associare OrgUnit Esistente -->
              <div *ngIf="!defaultCheckOrgUnit.crea" class="row form-group mb-2">
                <div class="col-12">
                  <label class="input-label" for="orgUnitId">Unità organizzativa esistente</label>

                  <app-custom-dropdown-treeview [items]="orgUnitsTreeView" [(value)]="data.orgUnitId"
                    (selectedChange)="onSelectedOrgUnitId($event)" (valueChange)="onValueChangeOrgUnit($event)"
                    [configs]="configTreeView">
                  </app-custom-dropdown-treeview>

                  <input type="hidden" id="orgUnitId" name="orgUnitId" #orgUnitId="ngModel" [(ngModel)]="data.orgUnitId"
                    required>

                  <span class="invalid-feedback" *ngIf="data.orgUnitId">Non valido</span>
                  <span class="valid-feedback" *ngIf="!data.orgUnitId">Valido</span>
                  <span class="text-muted font-size-sm">Scegli l'unità organizzativa da associare alla classe</span>
                </div>

                <!-- Abilito l'importazione degli utenti dall'unità organizzativa solo quando sto creando una nuova classe -->
                <!-- <div class="col-12 mt-1" *ngIf="orgUnitId.valid && !edit">
                  <label class="toggle-switch d-flex" for="importStudentFromOrgUnit">
                    <input id="importStudentFromOrgUnit" [(ngModel)]="data.importStudentFromOrgUnit"
                      name="importStudentFromOrgUnit" #importStudentFromOrgUnit="ngModel" type="checkbox"
                      class="toggle-switch-input">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">Importa utenti come studenti</span>
                      <small class="d-block text-muted">Se attiva, tutti gli utenti presenti nell'unità organizzativa e
                        nelle unità organizzative figlie saranno importati e mappati in Geniusuite come
                        <b>Studenti</b></small>
                    </span>
                  </label>
                </div> -->

                <!-- Modifica Nome OrgUnit in base ad Entità -->
                <div class="col-12 mt-3" *ngIf="edit">
                  <div class="custom-control custom-checkbox">
                    <input [ngModel]="false" id="changeNameOrgUnit" name="changeNameOrgUnit"
                      #changeNameOrgUnit="ngModel" type="checkbox" class="custom-control-input">
                    <label class="custom-control-label" for="changeNameOrgUnit">Modifica Nome Unità
                      organizzativa</label>
                    <div class="text-muted font-size-1">Cambia il nome dell'Unità organizzativa in base al
                      nome dell'Organigramma
                    </div>
                  </div>
                </div>
              </div>

              <!-- Gestione Gruppi -->
              <div class="row form-group">
                <div class="col-12">
                  <h4>{{ 'STR.gruppi' | translate }}</h4>

                  <!-- Gestione Gruppi -->
                  <label class="toggle-switch d-flex" for="gestGroup">
                    <input id="gestGroup" [(ngModel)]="showGroupSettings" name="gestGroup" #gestGroup="ngModel"
                      type="checkbox" class="toggle-switch-input" (change)="loadGroupsPreview(myForm)">
                    <span class="toggle-switch-label">
                      <span class="toggle-switch-indicator"></span>
                    </span>
                    <span class="toggle-switch-content">
                      <span class="d-block">Gestisci Gruppi della Classe</span>
                      <small class="d-block text-muted">Gestione Gruppi della Classe</small>
                    </span>
                  </label>

                </div>
              </div>
              <!-- Impostazioni Avanzate Gruppi  -->
              <div class="row">
                <div class="mt-1 col-12" id="collapseGroupSettings" [collapse]="!showGroupSettings" [isAnimated]="true">

                  <div class="card card-hover-shadow">
                    <div class="card-body">
                      <!-- Studenti -->
                      <div class="row form-group">
                        <!-- Crea nuovo Gruppo -->
                        <div class="col-12">
                          <h4>Gruppo Studenti</h4>
                          <label class="toggle-switch d-flex" for="newGroupStandard_S">
                            <input [(ngModel)]="defaultCheckGroup.studenti" id="newGroupStandard_S"
                              name="newGroupStandard_S" #newGroupStandard_S="ngModel" type="checkbox"
                              class="toggle-switch-input">
                            <span class="toggle-switch-label">
                              <span class="toggle-switch-indicator"></span>
                            </span>
                            <span class="toggle-switch-content">
                              <span class="d-block">Nuovo gruppo Studenti</span>
                              <small class="d-block text-muted">Verrà creato un nuovo gruppo secondo i
                                parametri
                                scelti</small>
                            </span>
                          </label>
                        </div>
                      </div>

                      <div *ngIf="!defaultCheckGroup.studenti" class="row form-group">
                        <!-- Selziona Gruppo -->
                        <div class="col-12">
                          <label class="input-label" for="groupKey_studenti">Gruppo Studenti
                            Esistente</label>

                          <input type="hidden" id="groupKey_studenti" name="groupKey_studenti"
                            #groupKey_studenti="ngModel" [(ngModel)]="data.groupKey_studenti"
                            [required]="showGroupSettings && !edit">
                          <app-select (valueChange)="selectGroupOnChange($event)" [id]="'select-groupKey_studenti'"
                            [showClear]="true" [filterBy]="'email'" [placeholder]="'Seleziona Gruppo'" [data]="groups"
                            [showValue]="'email'" [resultAttribute]="'id'" [(value)]="data.groupKey_studenti"
                            [optionAttributeShow]="'name, email'"></app-select>

                          <blockquote *ngIf="groupNotExist['groupKey_studenti']" class="blockquote mt-2">
                            <small>Verrà creato il gruppo</small>
                            <p>{{data.groupKey_studenti}}</p>
                          </blockquote>

                        </div>
                      </div>

                      <!-- Docenti -->
                      <div class="row form-group">
                        <!-- Crea nuovo Gruppo -->
                        <div class="col-12">
                          <h4>Gruppo Docenti</h4>
                          <label class="toggle-switch d-flex" for="newGroupStandard_D">
                            <input [(ngModel)]="defaultCheckGroup.docenti" id="newGroupStandard_D"
                              name="newGroupStandard_D" #newGroupStandard_D="ngModel" type="checkbox"
                              class="toggle-switch-input">
                            <span class="toggle-switch-label">
                              <span class="toggle-switch-indicator"></span>
                            </span>
                            <span class="toggle-switch-content">
                              <span class="d-block">Nuovo gruppo Docenti</span>
                              <small class="d-block text-muted">Verrà creato un nuovo gruppo secondo i
                                parametri
                                scelti</small>
                            </span>
                          </label>
                        </div>
                      </div>

                      <div *ngIf="!defaultCheckGroup.docenti" class="row form-group">
                        <!-- Selziona Gruppo -->
                        <div class="col-12">
                          <label class="input-label" for="groupKey_docenti">Gruppo Docenti Esistente</label>

                          <input type="hidden" id="groupKey_docenti" name="groupKey_docenti" #groupKey_docenti="ngModel"
                            [(ngModel)]="data.groupKey_docenti" [required]="showGroupSettings && !edit">
                          <app-select (valueChange)="selectGroupOnChange($event)" [id]="'select-groupKey_docenti'"
                            [showClear]="true" [filterBy]="'email'" [placeholder]="'Seleziona Gruppo'" [data]="groups"
                            [showValue]="'email'" [resultAttribute]="'id'" [(value)]="data.groupKey_docenti"
                            [optionAttributeShow]="'name, email'"></app-select>

                          <blockquote *ngIf="groupNotExist['groupKey_docenti']" class="blockquote mt-2">
                            <small>Verrà creato il gruppo</small>
                            <p>{{data.groupKey_docenti}}</p>
                          </blockquote>
                        </div>
                      </div>

                      <!-- Classe -->
                      <div class="row form-group mb-0">
                        <!-- Crea nuovo Gruppo -->
                        <div class="col-12">
                          <h4>Gruppo misto Docenti e Studenti
                            <span class="badge badge-soft-warning" *ngIf="parameter && !parameter.gest_group_classe">
                              Non abilitato
                            </span>
                          </h4>
                          <label class="toggle-switch d-flex" for="newGroupStandard_A" *ngIf="parameter && parameter.gest_group_classe">
                            <input [(ngModel)]="defaultCheckGroup.all" id="newGroupStandard_A" name="newGroupStandard_A"
                              #newGroupStandard_A="ngModel" type="checkbox" class="toggle-switch-input"
                              [disabled]="parameter && !parameter.gest_group_classe">
                            <span class="toggle-switch-label">
                              <span class="toggle-switch-indicator"></span>
                            </span>
                            <span class="toggle-switch-content">
                              <span class="d-block">Nuovo Gruppo misto Docenti e Studenti</span>
                              <small class="d-block text-muted">Verrà creato un nuovo gruppo secondo i
                                parametri
                                scelti</small>
                            </span>
                          </label>
                        </div>
                      </div>

                      <div *ngIf="!defaultCheckGroup.all && (parameter && parameter.gest_group_classe)"
                        class="row form-group  mb-0">
                        <!-- Selziona Gruppo -->
                        <div class="col-12">
                          <label class="input-label" for="groupKey_all">Gruppo misto Docenti e Studenti Esistente</label>

                          <input type="hidden" id="groupKey_all" name="groupKey_all" #groupKey_all="ngModel"
                            [(ngModel)]="data.groupKey_all">
                          <app-select (valueChange)="selectGroupOnChange($event)" [id]="'select-groupKey_all'"
                            [showClear]="true" [filterBy]="'email'" [placeholder]="'Seleziona Gruppo'" [data]="groups"
                            [showValue]="'email'" [resultAttribute]="'id'" [(value)]="data.groupKey_all"
                            [optionAttributeShow]="'name, email'">
                          </app-select>

                          <span class="text-muted font-size-sm">
                            Lasciandolo vuoto non verrà creato nessun gruppo
                          </span>

                          <blockquote *ngIf="groupNotExist['groupKey_all']" class="blockquote mt-2">
                            <small>Verrà creato il gruppo</small>
                            <p>{{data.groupKey_all}}</p>
                          </blockquote>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Right -->
        <div class="col-5 p-3 m-3 bg-light rounded-lg">
          <!-- Titlte -->
          <h4 class="mb-3"><i class="fad fa-sync-alt mr-2"></i>Sincronizzazione con Google Workspace</h4>

          <div class="row" *ngIf="!edit">
            <div class="col-12">
              <div *ngIf="defaultCheckOrgUnit.crea" class="alert alert-soft-info" role="alert">
                La Classe <b class="text-cap">{{data?.name}}</b> verrà sincronizzata con una <b class="text-cap">nuova
                  Unità Organizzativa</b> con il nome
                <u>{{data?.name || "- scrivi il nome della classe -"}}</u>
              </div>
              <div *ngIf="!defaultCheckOrgUnit.crea" class="alert alert-soft-info" role="alert">
                La Classe <b class="text-cap">{{data?.name}}</b> verrà sincronizzata con<b class="text-cap">l'Unità
                  Organizzativa esistente</b> {{controlNameOrgUnit || "- seleziona unità organizzativa -"}}
              </div>
              <ng-container *ngIf="defaultCheckOrgUnit.crea && data?.name">
                <div class="alert alert-soft-info" role="alert">
                  Verrà creata sotto l'Unità organizzativa:
                  {{plesso.google_org_unit.name || "- errore unità organizzativa -" }}
                </div>
              </ng-container>

            </div>
          </div>

          <ng-container *ngIf="showGroupSettings">

            <div *ngIf="defaultCheckGroup.studenti && !existErrorGroupPreview.studenti"
              class="alert alert-soft-info" role="alert">
              Il Gruppo Studenti della classe verrà sincronizzato con un <b class="text-cap">Nuovo Gruppo</b> con
              email
              <u>{{groupsPreview?.studenti || " - scrivi il nome della classe -"}}</u>
            </div>

            <div *ngIf="defaultCheckGroup.studenti  && existErrorGroupPreview.studenti"
              class="alert alert-soft-danger" role="alert"> Attenzione! Il Gruppo Studenti della classe con email
              <u>{{groupsPreview?.studenti}}</u> esiste già
            </div>

            <div *ngIf="!defaultCheckGroup.studenti" class="alert alert-soft-info" role="alert">
              Il Gruppo Studenti della classe verrà sincronizzato con il Gruppo
              <b class="text-cap">
                {{previewEmailSelectedGroup(data.groupKey_studenti) || " - seleziona il gruppo -"}}
              </b>
            </div>

            <div *ngIf="defaultCheckGroup.docenti && !existErrorGroupPreview.docenti" class="alert alert-soft-info"
              role="alert">
              Il Gruppo Docenti della classe verrà sincronizzato con un <b class="text-cap">Nuovo Gruppo</b> con
              email
              <u>{{groupsPreview?.docenti || " - scrivi il nome della classe -"}}</u>
            </div>

            <div *ngIf="defaultCheckGroup.docenti && existErrorGroupPreview.docenti" class="alert alert-soft-info"
              role="alert">
              Attenzione! Il Gruppo Docenti della classe con email
              <u>{{groupsPreview?.docenti}}</u> esiste già
            </div>

            <div *ngIf="!defaultCheckGroup.docenti" class="alert alert-soft-info" role="alert">
              Il Gruppo Docenti della classe verrà sincronizzato con il Gruppo
              <b class="text-cap">
                {{previewEmailSelectedGroup(data.groupKey_docenti) || " - seleziona il gruppo -"}}
              </b>
            </div>

            <ng-container *ngIf="parameter && parameter.gest_group_classe">
              <div *ngIf="defaultCheckGroup.all && !existErrorGroupPreview.all" class="alert alert-soft-info"
                role="alert">
                Il Gruppo misto Docenti e Studenti verrà sincronizzato con un <b class="text-cap">Nuovo Gruppo</b> con email
                <u>{{groupsPreview?.all || " - scrivi il nome della classe -"}}</u>
              </div>

              <div *ngIf="defaultCheckGroup.all && existErrorGroupPreview.all" class="alert alert-soft-danger"
                role="alert">
                Attenzione! Il Gruppo misto Docenti e Studenti verrà sincronizzato con email
                <u>{{groupsPreview?.all || " - scrivi il nome della classe -"}}</u> esiste già
              </div>

              <div *ngIf="!defaultCheckGroup.all" class="alert alert-soft-info" role="alert">
                La Classe verrà sincronizzato con il Gruppo
                <b class="text-cap">
                  {{previewEmailSelectedGroup(data.groupKey_all) || " - seleziona il gruppo -"}}
                </b>
              </div>
            </ng-container>
          </ng-container>

          <div class="row" *ngIf="edit">
            <div class="col-12">
              <div class="alert alert-soft-info" role="alert" *ngIf="!dataIsChanged; else elseBlock">
                Non ci sono modifiche
              </div>
              <ng-template #elseBlock>
                <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.name">
                  Il classe verrà rinominato da {{classe.name}} a <b>{{data.name}}</b>
                </div>
                <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.anno">
                  L'abbreviazione del classe verrà rinominato da {{classe.anno}} a <b>{{data.anno}}</b>
                </div>
                <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.description">
                  La descrizione del classe verrà rinominato da {{classe.description}} a <b>{{data.description}}</b>
                </div>
                <div class="alert alert-soft-info" role="alert" *ngIf="dataChange.org_unit">
                  <ng-container *ngIf="data.orgUnitId; else noOrgUnitAssociate">
                    L'Unita organizzativa del classe verrà cambiata in <b>{{controlNameOrgUnit}}</b>
                  </ng-container>
                  <ng-template #noOrgUnitAssociate>
                    Non verrà associata nessuna unita organizzativa al classe
                  </ng-template>
                </div>

                <ng-container *ngIf="dataChange.groupKey_all && (parameter && parameter.gest_group_classe)">
                  <div class="alert alert-soft-info" role="alert" *ngIf="data.groupKey_all">
                    Il Gruppo del classe verrà cambiata in
                    <b>
                      {{previewEmailSelectedGroup(data.groupKey_all) || "GROUP"}}
                    </b>
                  </div>
                </ng-container>

                <ng-container *ngIf="dataChange.groupKey_docenti">
                  <div class="alert alert-soft-info" role="alert" *ngIf="data.groupKey_docenti">
                    Il Gruppo Docenti del classe verrà cambiata in
                    <b>
                      {{previewEmailSelectedGroup(data.groupKey_docenti) || "GROUP"}}
                    </b>
                  </div>
                </ng-container>

                <ng-container *ngIf="dataChange.groupKey_studenti">
                  <div class="alert alert-soft-info" role="alert" *ngIf="data.groupKey_studenti">
                    Il Gruppo Studenti del classe verrà cambiata in
                    <b>
                      {{previewEmailSelectedGroup(data.groupKey_studenti) || "GROUP"}}
                    </b>
                  </div>
                </ng-container>

              </ng-template>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="card-footer border-0 mx-3">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto py-1">
          <button *ngIf="id_classe" [routerLink]="['/plesso/'+id_plesso+'/classe/'+id_classe]" type="button"
            class="btn btn-white">
            <i class="far fa-chevron-left mr-1"></i> Annulla
          </button>
          <button *ngIf="!id_classe" [routerLink]="['/plesso/'+id_plesso]" type="button" class="btn btn-white">
            <i class="far fa-chevron-left mr-1"></i> Annulla
          </button>
        </div>
        <div class="col-6 py-1">
          <div class="row justify-content-end">
            <button type="submit" class="btn"
              [disabled]="!myForm.form.valid || existErrorGroupPreview.studenti || existErrorGroupPreview.docenti || existErrorGroupPreview.all"
              [ngClass]="(myForm.dirty || myForm.touched)? 'btn-primary' : 'btn-outline-primary' ">
              <span *ngIf="id_classe">Aggiorna</span>
              <span *ngIf="!id_classe">Salva</span>
              <i class="far fa-chevron-right ml-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
