<!-- Abilitare quando ci sono novità e si vuole lasciare un messaggio in dashboard -->
<!-- <div class="row">
  <div class="col-12">
    <app-changelog-widget></app-changelog-widget>
  </div>
</div> -->

<!-- <app-card-loading-error *ngIf="true"></app-card-loading-error> -->

<app-announcement-widget showByChannel="public-dashboard"></app-announcement-widget>


<ng-container *ngIf="!getParametriService().checkParametriConfigurazione() && getAccountService().isGoogleWorkspaceAccount()">
  <app-video-tutorial-widget section="onboarding" video_url="https://www.youtube.com/embed/kbmGHWvWvjI">
  </app-video-tutorial-widget>
</ng-container>

<ng-container *ngIf="!getParametriService().checkParametriConfigurazione() && !getAccountService().isGoogleWorkspaceAccount()">
  <app-video-tutorial-widget section="onboarding" video_url="https://www.youtube.com/embed/foq0s5CM2eI">
  </app-video-tutorial-widget>
</ng-container>

<ng-container *ngIf="showBannerCompleteProfile">
  <div class="card card-hover-shadow mb-3">
    <div class="card-body">
      <p class="card-text"
        [innerHTML]="'STR.completa_profilo_card_alert' | translate">
      </p>
      <button routerLink="/ente/edit" class="btn btn-outline-primary btn-xs">
        <i class="fad fa-id-card mr-2"></i>Premi qui per completare il profilo
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="getParametriService().checkParametriConfigurazione() && getAccountService().isGoogleWorkspaceAccount()">
  <div class="row">
    <div class="col-12">
      <app-reset-password-widget></app-reset-password-widget>
    </div>
    <!-- <div class="col-6">
    </div>
    <app-todo-widget></app-todo-widget>
  </div> -->
  </div>
</ng-container>

<div class="row">
  <div class="col-12">
    <app-alfred-widget></app-alfred-widget>
  </div>
</div>

<div class="row" *ngIf="getAccountService().isGoogleWorkspaceAccount()">
  <div class="col-md-6 col-sm-12">
    <app-google-meet-stat></app-google-meet-stat>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-google-classroom-stat></app-google-classroom-stat>
  </div>
</div>

<div class="row" *ngIf="getAccountService().isGoogleWorkspaceAccount()">
  <div class="col-md-6 col-sm-12">
    <app-google-drive-stat></app-google-drive-stat>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-google-gmail-stat></app-google-gmail-stat>
  </div>
</div>
