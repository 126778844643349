import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { PlessoService } from 'src/app/services/general/plesso.service';
import { PassaggioAnnoService } from 'src/app/services/operations/passaggio-anno.service';

@Component({
  selector: 'app-passaggio-anno-wizard-struttura',
  templateUrl: './passaggio-anno-wizard-struttura.component.html',
  styleUrls: ['./passaggio-anno-wizard-struttura.component.scss']
})
export class PassaggioAnnoWizardStrutturaComponent implements OnInit {

  regEx = ConfigService.regExConfirm;
  plessi: any = [];
  struttura: any;
  organigramma: any;
  error: any;
  loadingPlessoService: boolean = false;
  private _plessoServiceSubscription: Subscription;

  constructor(private plessoService: PlessoService, private router: Router, private passaggioAnnoService: PassaggioAnnoService) { }

  ngOnInit(): void {

    this.passaggioAnnoService.checkActivity();

    if (this.passaggioAnnoService.getActivityExist()) {
      if (this.passaggioAnnoService.getActivityInProgress())
        this.router.navigate(['/passaggio-anno/wait']);
      else
        this.router.navigate(['/passaggio-anno/finish']);
    }

    this.loadingPlessoService = false;
    this.error = null;

    this._plessoServiceSubscription = this.plessoService.getSubjectToUpdateObservable().subscribe((result) => {
      if (!result)
        this.backendError("Plesso Service");
      else
        this.plessoServiceUpdated(result);
    })

    this.loadingPlessoService = true;
    this.plessoService.all();
  }

  plessoServiceUpdated(result) {
    this.plessi = result;
    this.setStutturaPlessi();
    this.loadingPlessoService = false;
    this.organigramma = this.passaggioAnnoService.getOrganigramma();
  }

  backendError(position) {
    this.error = "backend " + position;
    this.loadingPlessoService = false;
  }

  back() {
    this.router.navigate(['/passaggio-anno/config']);
  }

  setStutturaPlessi() {

    this.struttura = this.passaggioAnnoService.getStruttura();

    if (!this.struttura) {
      this.passaggioAnnoService.setStruttura(this.plessi);
      this.passaggioAnnoService.setNextClassRaccomandate();
      this.struttura = this.passaggioAnnoService.getStruttura();
    }
  }

  enableDisablePlesso(element_plesso, index_plesso, includi) {

  }

  next() {
    this.passaggioAnnoService.execOperation();
    this.router.navigate(['/passaggio-anno/wait']);
  }
}
