import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  menuMini = false;

  datatableRowsPerPage = 10;

  constructor(translate: TranslateService) {
    // translate.use("it");
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  toggleMenu() {
    this.menuMini = !this.menuMini;
    this._subjectToUpdate.next(null);
  }

  updateDatatableRowsPerPage(num) {
    this.datatableRowsPerPage = num;
  }

}
