<div class="container py-5 py-sm-7">
  <a class="d-flex justify-content-center mb-5">
    <img class="z-index-2" src="./assets/theme/svg/logos/geniusuite_partner.svg" alt="Geniusuite" style="width: 30rem;">
  </a>

  <div class="row justify-content-center">
    <div class="col-md-7 col-lg-5">

      <ng-container
        *ngIf="getAccountService().data.ente.subscription_active == 1  && getAccountService().data.ente.subscription_expired">
        <div class="card card-hover-shadow mb-3">
          <a class="card-header alert-danger">
            <h4 class="card-header-title text-white"
              [innerHTML]="'STR.geniusuite_subscription_expired_title' | translate">
            </h4>
          </a>
          <div class="card-body">
            <div class="alert alert-soft-danger" role="alert"
              [innerHTML]="'STR.geniusuite_subscription_expired_msg' | translate">
            </div>
            <span class="card-text">La licenza è scaduta il {{ getAccountService().data.ente.subscription_end |
              date:'medium' }}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="getAccountService().data.ente.subscription_active == 0">
        <div class="card card-hover-shadow mb-3">
          <a class="card-header alert-danger">
            <h4 class="card-header-title text-white"
              [innerHTML]="'STR.geniusuite_subscription_notactive_title' | translate">
            </h4>
          </a>
          <div class="card-body">
            <div class="alert alert-soft-danger" role="alert"
              [innerHTML]="'STR.geniusuite_subscription_notactive_msg' | translate">
            </div>
          </div>
        </div>
      </ng-container>

      <!-- <a href="https://www.geniusuite.com" class="btn btn-lg btn-block btn-primary">Rinnova la licenza</a> -->

    </div>
  </div>
</div>
