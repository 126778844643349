import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackendService } from '../../backend.service';
import { PlessoService } from '../../general/plesso.service';
import { UserService } from '../../general/user.service';
import { ToastService } from '../../toast.service';
import { UtilityService } from '../../utility.service';
import { CreationClassroomTypes } from './create-classroom.service';

@Injectable({
  providedIn: 'root'
})
export class CreateSingleClassroomService {

  private _subjectToUpdate: Subject<any> = new Subject<any>();

  parameters: any = {
    creationType: null,
    classroomName: "",
    classroomSection: null,
    classroomDescription: null,
    classroomRoom: null,
    ownerId: null
  };

  items: any[];

  course: any;

  errors: boolean = false;

  constructor(
    private backend: BackendService,
    private toast: ToastService,
    private utilityService: UtilityService,
  ) {
    this.reset();
  }

  reset() {
    this.items = [];
    this.parameters.ownerId = null;
    this._subjectToUpdate.next(this.items);
  }

  public getSubjectToUpdateObservable(): Observable<any> {
    return this._subjectToUpdate.asObservable();
  }

  getItems() {
    this._subjectToUpdate.next(this.items ? this.items : []);
    return;
  }

  addItems(newItems, roleUser) {

    newItems.forEach(item => {

      let itemFound = this.items.find((element) => element?.id == item?.id);

      if (!itemFound) {
        let addItem = this.utilityService.cloneData(item);
        addItem.type = roleUser;
        this.items.push(addItem);
      }
    });

    this._subjectToUpdate.next(this.items);

    this.getStrutturaJson();
  }

  deleteItemByGoogleId(googleId) {
    let idx = this.items.findIndex(x => x.id == googleId);
    this.items[idx] = null;

    this.items = this.items.filter((el) => { return el != null });

    this._subjectToUpdate.next(this.items);

    this.getStrutturaJson();
  }


  execOperation(callback = (error) => { }) {
    let courses = [this.getStrutturaJson()];

    this.backend.post("op/create-classroom", { courses: courses }).subscribe((result) => {
      this.reset();
      this.toast.success('Creazione Classroom avviato');
      callback(null);
    }, (error) => {
      callback(error);
    })
  }

  getStrutturaJson() {
    let students = [];
    let teachers = [];

    let _teachers = this.items.filter(x => x.type == "google-teacher");
    let _students = this.items.filter(x => x.type == "google-student");

    if (_teachers != null && _teachers.length > 0) {
      _teachers.forEach(user => {
        teachers.push({
          google_id: user.id
        })
      });
    }

    if (_students != null && _students.length > 0) {
      _students.forEach(user => {
        students.push({
          google_id: user.id
        })
      });
    }

    let objCourse = {
      course: {
        ownerId: this.parameters.ownerId,
        name: this.parameters.classroomName,
        description: this.parameters.classroomDescription,
        room: this.parameters.classroomRoom,
        section: this.parameters.classroomSection,
        students: students,
        teachers: teachers
      },
      holderInfo: {
        classe_id: null,
        parameters: null,
        user_id: null,
        materia_id:null
      }
    }

    this.course = objCourse;
    this.checkError();

    return objCourse;
  }

  updateParameter(_parameters) {
    this.parameters = _parameters;
  }

  checkError() {
    this.errors = false;

    if (this.course.course.teachers == null || this.course.course.teachers.length == 0) {
      this.errors = true;
      return;
    } else if (this.course.course.teachers.length > 20) {
      this.errors = true;
      return;
    }

    this.errors = false;
    return false;
  }
}
