import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { GoogleDirectoryService } from 'src/app/services/google/google-directory.service';
import { PageService } from 'src/app/services/page.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-group-profile',
  templateUrl: './group-profile.component.html',
  styleUrls: ['./group-profile.component.scss']
})
export class GroupProfileComponent implements OnInit {
  id: any;

  showForm: boolean;

  error: any;
  loading = false;

  groups: any[];
  groupsData: any[];
  groupsError: any;

  private _activeRouteSubscription: Subscription;
  private _googleServiceGroupsSubscription: Subscription;

  constructor(
    private googleService: GoogleDirectoryService,
    private pageService: PageService,
    private router: Router,
    private utilityService: UtilityService,
    private activeRoute: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.unsubscibeAll();
  }

  ngOnInit(): void {
    this.unsubscibeAll();

    this.error = null;
    this.groups = [];
    this.groupsData = [];
    this.groupsError = [];
    this.loading = false;
    this._googleServiceGroupsSubscription = this.googleService.getSubjectToUpdateGroupsObservable().subscribe((result) => {
      if (result) {
        this.updateData(result);
        this.getGroupData();
      }
    });

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_group;
      this.init();
    });

    this.showForm = this.utilityService.checkRouteActionType(this.activeRoute, "edit");
  }

  init() {
    this.showForm = false;
    this.loading = true;
    this.googleService.groupsList();

    if (this.id) {
      this.getGroupData();
    } else {
      this.router.navigate(['/**']);
    }
  }

  getGroupData() {
    if (this.groups) {

      let group = this.googleService.getGroupByGroupKey(this.id);

      if (group)
        this.pageService.updatePageTitle({ group: group.name });
      else
        this.router.navigate(['/**']);
    }
  }

  updateData(groups) {
    this.groups = groups;
    this.groupsData = this.parseGroupsResult(this.groups);
  }

  retryAfterLoadingError() {
    this.error = null;
    this.ngOnInit();
  }

  unsubscibeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();

    if (this._googleServiceGroupsSubscription)
      this._googleServiceGroupsSubscription.unsubscribe();
  }

  getRemainingCountAvatar(lenghtMax, countFix) {
    return lenghtMax - countFix;
  }

  parseGroupsResult(result) {

    let table = [];

    result.forEach(element => {
      table.push(
        {
          "nome": [element.name, element.section],
          "description": [element.descriptionHeading]
        });
    });

    return table;
  }

  rowClickCourse(data) {
    let group = this.groups[data.index];
    this.router.navigate(['/group/' + group.id]);
  }
}
