<div class="row mb-5">
  <div class="col">
    <div class="card card-hover-shadow">
      <div class="card-body m-2">
        <app-setup-wizard-steps [currentStep]="3"></app-setup-wizard-steps>
      </div>
    </div>
  </div>
</div>

<form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" validate>

  <div class="row mt-5" *ngFor="let element_plesso of struttura; let index_plesso = index;">
    <div class="col">
      <!-- Card -->
      <div class="card card-hover-shadow">

        <div class="card-header card-btn btn-block border-bottom-0" [ngClass]="{'collapsed': element_plesso.isCollapsed}"
          (click)="element_plesso.isCollapsed = !element_plesso.isCollapsed">
          <h2 class="card-header-title">
            <i class="tio-company mr-2"></i>{{element_plesso.plesso.name}}
            <span *ngIf="!element_plesso.plesso.id" class="badge badge-soft-primary ml-2">Nuovo</span>
            <span *ngIf="element_plesso.plesso.id" class="badge badge-soft-success ml-2">Esistente</span>
            <span *ngIf="element_plesso.errors?.length > 0" class="badge badge-danger ml-2">Errori</span>
            <span *ngIf="element_plesso.warnings?.length > 0" class="badge badge-danger ml-2">Classi con errori</span>
            <a *ngIf="element_plesso.plesso.id" class="ml-2 btn-xs btn-icon btn-ghost-primary"
              routerLink="/plesso/{{element_plesso.plesso.id}}">
              <i class="far fa-external-link"></i>
            </a>
            <h5 class="mt-2">{{ 'STR.org_unit' | translate }}
              <ng-container *ngIf="element_plesso.plesso.google_org_unit_new">
                {{element_plesso.plesso.google_org_unit_new.orgUnitPath}}
                <span *ngIf="!element_plesso.plesso.google_org_unit_new.orgUnitId"
                  class="badge badge-soft-primary ml-2">Nuova</span>
                <span *ngIf="element_plesso.plesso.google_org_unit_new.orgUnitId"
                  class="badge badge-soft-primary ml-2">Esistente</span>
              </ng-container>
              <ng-container *ngIf="element_plesso.plesso.google_org_unit">
                {{element_plesso.plesso.google_org_unit.orgUnitPath}}
                <span *ngIf="!element_plesso.plesso.google_org_unit.orgUnitId"
                  class="badge badge-soft-success ml-2">Nuova</span>
                <span *ngIf="element_plesso.plesso.google_org_unit.orgUnitId"
                  class="badge badge-soft-success ml-2">Esistente</span>
              </ng-container>
            </h5>
          </h2>

          <span class="card-btn-toggle" [attr.aria-expanded]="!element_plesso.isCollapsed"
            aria-controls="collapsePlesso">
            <span class="btn btn-xs btn-ghost-primary card-btn-toggle-default">
              <i class="fas fa-chalkboard mr-1"></i>
              Mostra le classi
            </span>
            <span class="btn btn-xs btn-ghost-primary card-btn-toggle-active">
              <i class="fas fa-chalkboard mr-1"></i>
              Nascondi le classi
            </span>
          </span>
        </div>

        <!-- Server per creare uno scope per la variabile dell'ngModel -->
        <ng-container *ngIf="!element_plesso.plesso.id">
          <div class="card-body pb-0">
            <div class="row">
              <div class="col col-md-6">
                <div class="form-group">
                  <label class="input-label" for="abbreviazioneNomePlesso{{index_plesso}}Input">Abbreviazione nome
                    plesso</label>
                  <input [(ngModel)]="element_plesso.plesso.short_name" type="text"
                    name="abbreviazioneNomePlesso{{index_plesso}}Input"
                    id="abbreviazioneNomePlesso{{index_plesso}}Input" #abbreviazioneNomePlessoInput="ngModel"
                    class="form-control" (change)="OnChangeShortNamePlesso($event, element_plesso)"
                    [ngClass]="abbreviazioneNomePlessoInput.valid ? 'is-valid': 'is-invalid'" required
                    pattern="[A-Za-z0-9_.-]*" autocomplete="none">
                  <span class="invalid-feedback" *ngIf="abbreviazioneNomePlessoInput.invalid">Non valido</span>
                  <span class="valid-feedback" *ngIf="abbreviazioneNomePlessoInput.valid">Valido</span>
                  <div class="text-muted font-size-1 mt-2">
                    {{'SETUP.plessoclasse.msg_plesso_short_name_custom' | translate}}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="card-body py-0">

          <div class="alert alert-soft-danger" *ngFor="let err of element_plesso.errors">
            <span class="text-cap d-inline">Errore</span> {{ err.type | translate }}
          </div>

          <div class="alert alert-soft-warning" *ngFor="let err of element_plesso.warnings">
            <span class="text-cap d-inline">Attenzione, classi con errori</span> {{ err.type | translate }}
          </div>

          <span [collapse]="element_plesso.isCollapsed" [isAnimated]="true">

            <ng-container>

              <div class="row justify-content-between mb-2">
                <div class="col">
                  <div class="custom-control custom-checkbox my-2">
                    <input type="checkbox" id="checkboxSelectPlesso{{index_plesso}}" class="custom-control-input"
                      [checked]="element_plesso.plesso.selected" [disabled]="element_plesso.errors?.length > 0"
                      (change)="OnCheckboxPlessoChanged($event, element_plesso)">
                    <label class="custom-control-label" for="checkboxSelectPlesso{{index_plesso}}">Tutte le classi del
                      plesso</label>
                  </div>
                </div>
                <div class="col text-right">
                  <button class="btn btn-xs btn-ghost-primary"
                    (click)="element_plesso.showGroups = !element_plesso.showGroups">
                    <ng-template *ngIf="element_plesso.showGroups; then nascondi else mostra"></ng-template>
                    <ng-template #mostra>
                      <i class="fas fa-eye mr-1"></i>
                      Mostra i gruppi
                    </ng-template>
                    <ng-template #nascondi>
                      <i class="fas fa-eye-slash mr-1"></i>
                      Nascondi i gruppi
                    </ng-template>
                  </button>
                </div>
              </div>



              <!-- Gruppi plesso -->
              <div class="row mb-3" *ngIf="element_plesso.showGroups">
                <ng-container *ngFor="let group of element_plesso.plesso.google_group">
                  <div class="col-4" *ngIf="group.email">
                    <small class="text-cap">Gruppo {{group.relation_info}}
                      <span *ngIf="!group.groupKey && !group.id && group.email"
                        class="badge badge-soft-primary ml-2">Nuovo</span>
                      <span *ngIf="group.groupKey || group.id" class="badge badge-soft-success ml-2">Esistente</span>
                    </small>
                    <div [title]="group.email" class="text-truncate">
                      {{group.email}}</div>
                  </div>
                </ng-container>
              </div>





              <!-- List Group -->
              <ul class="list-group list-group-lg mb-3">
                <li class="list-group-item">
                  <ng-container *ngFor="let element_classe of element_plesso.classi; let index_classe = index;">

                    <div class="row align-items-center my-3">
                      <div class="col-2 align-self-start">
                        <div class="row">
                          <div class="col-auto">
                            <div class="form-group mb-0">
                              <!-- Checkbox -->
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" id="checkboxSelectPlesso{{index_plesso}}Classe{{index_classe}}"
                                  class="custom-control-input" [checked]="element_classe.classe.selected"
                                  [disabled]="element_plesso.errors?.length > 0 || element_classe.errors?.length > 0"
                                  (change)="OnCheckboxClasseChanged($event, element_plesso, element_classe)">
                                <label class="custom-control-label"
                                  for="checkboxSelectPlesso{{index_plesso}}Classe{{index_classe}}"></label>
                              </div>
                              <!-- End Checkbox -->
                            </div>
                          </div>
                          <div class="col">
                            <i class="fas fa-chalkboard mr-1"></i>{{element_classe.classe.name}}<br>
                            <span *ngIf="element_classe.errors?.length > 0" class="badge badge-danger">Errori</span>
                            <ng-container *ngIf="element_classe.classe.selected">
                              <span *ngIf="!element_classe.classe.id" class="badge badge-soft-primary">Nuova</span>
                              <span *ngIf="element_classe.classe.id" class="badge badge-soft-success">Esistente</span>
                            </ng-container>
                            <a *ngIf="element_plesso.plesso.id && element_classe.classe.id"
                              class="ml-2 btn-xs btn-icon btn-ghost-primary"
                              routerLink="/plesso/{{element_plesso.plesso.id}}/classe/{{element_classe.classe.id}}">
                              <i class="far fa-external-link"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 align-self-start">
                        <small class="text-cap">{{ 'STR.Utenti' | translate }}</small>
                        <span class="font-weight-bold text-dark lead">{{element_classe.rows.length}}</span>
                      </div>
                      <div class="col-2 align-self-start" *ngIf="element_classe.materie">
                        <small class="text-cap">Materie</small>
                        <span class="font-weight-bold text-dark lead">{{element_classe.materie.length}}</span>
                      </div>
                      <div class="col-3 align-self-start" *ngIf="element_classe.classe.selected">
                        <small class="text-cap">{{ 'STR.org_unit' | translate }}</small>
                        <ng-container *ngIf="element_classe.classe.google_org_unit_new">
                          {{element_classe.classe.google_org_unit_new.orgUnitPath}}
                          <span *ngIf="!element_classe.classe.google_org_unit_new.orgUnitId"
                            class="badge badge-soft-primary ml-2">Nuova</span>
                          <span *ngIf="element_classe.classe.google_org_unit_new.orgUnitId"
                            class="badge badge-soft-success ml-2">Esistente</span>
                        </ng-container>
                        <ng-container *ngIf="element_classe.classe.google_org_unit">
                          {{element_classe.classe.google_org_unit.orgUnitPath}}
                          <span *ngIf="!element_classe.classe.google_org_unit.orgUnitId"
                            class="badge badge-soft-primary ml-2">Nuova</span>
                          <span *ngIf="element_classe.classe.google_org_unit.orgUnitId"
                            class="badge badge-soft-success ml-2">Esistente</span>
                        </ng-container>
                      </div>
                      <!-- Mostro gli errori sulle classi solo nel caso in cui il plesso non abbia errori -->
                      <div
                        *ngIf="(!element_plesso.errors || element_plesso.errors?.length == 0) && element_classe.errors?.length > 0"
                        class="col align-self-start">
                        <div class="alert alert-soft-danger" *ngFor="let err of element_classe.errors">
                          <span class="text-cap d-inline">Errore</span> {{ err.type | translate }}
                        </div>
                      </div>
                    </div>

                    <!-- Gruppi classe -->
                    <div class="row align-items-center my-3"
                      *ngIf="element_classe.classe.selected && element_plesso.showGroups">
                      <div class="col-2"></div>
                      <ng-container *ngFor="let group of element_classe.classe.google_group">
                        <div class="col-3" *ngIf="group.relation_info">
                          <small class="text-cap">Gruppo {{group.relation_info}}
                            <span *ngIf="!group.groupKey && !group.id && group.email"
                              class="badge badge-soft-primary ml-2">Nuovo</span>
                            <span *ngIf="group.groupKey || group.id"
                              class="badge badge-soft-success ml-2">Esistente</span>
                          </small>
                          <div [title]="group.email" class="text-truncate">
                            {{group.email || "Non gestito in questa importazione"}}</div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </li>
              </ul>
              <!-- End List Group -->

            </ng-container>
          </span>
        </div>


      </div>
      <!-- End Card -->
    </div>
  </div>

</form>

<div class="row mt-5">
  <div class="col">
    <!-- Card -->
    <div class="card card-hover-shadow">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto py-1">
            <button (click)="back()" type="button" class="btn btn-white">
              <i class="far fa-chevron-left mr-1"></i> Indietro
            </button>
          </div>
          <div class="col-auto py-1">
            <button (click)="next()" [disabled]="!myForm.valid || !struttura || struttura.length == 0" type="button" class="btn btn-primary">
              Avanti <i class="far fa-chevron-right ml-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Card -->
  </div>
</div>
