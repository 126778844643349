<div class="custom-control custom-checkbox-switch" (click)="checkboxClick()" style="width: 100%;">
  <input type="checkbox" [disabled]="disabled" [ngModel]="value" (ngModelChange)="valueChangedEvent($event)" [id]="id" class="custom-control-input custom-checkbox-switch-input"  >
  <label class="custom-checkbox-switch-label btn" [for]="id" style="width: 100%;">
    <span class="custom-checkbox-switch-default">
      {{labelTextNotChecked}}&nbsp;
    </span>
    <span class="custom-checkbox-switch-active">
      <i class="fas fa-check"></i> {{labelTextChecked}}
    </span>
  </label>
</div>
